import React from 'react';
import { useSelector } from 'react-redux';
import { AvatarImage } from '../../../../../components/avatar-image';
import { selectMatchData } from '../../../../../slices/matchSlice';
import { MatchResultCircle } from '../../../../match/components';
export function GaMdGames(
  {
    // nr
  },
) {
  const matchData = useSelector(selectMatchData);
  return (
    <div className='w-full  gap-2 rounded-xl flex flex-col'>
      <h2 className='font-bold'>Games</h2>
      <div className='flex m-auto w-full justify-center flex-wrap gap-2'>
        {matchData?.MatchGame?.map((g: any, i: number) => (
          // <GameCard
          //   key={g.id}
          //   htImage={matchData.home_team[0].image}
          //   date='TBD'
          //   atImage={matchData.home_team[0].image}
          //   game={(i + 1).toString()}
          // />
          <GameCards 
          key={g.id}
          ht_score={g.ht_result}
          at_score={g.aw_result}
          />
        ))}
      </div>
    </div>
  );
}
function GameCard({
  htImage,
  game,
  date,
  atImage,
}: {
  htImage: string;
  game: string;
  date: string;
  atImage: string;
}) {
  return (
    <div className='flex justify-between gap-4 px-4 p-8 main-gr-bg-color sm:w-[200px] aspect-video items-center rounded-md w-full'>
      {/* <img
        src="https://www.seekpng.com/png/full/1008-10083987_cs-go-avr-vs-teams-logo-esports-logo.png"
        className="w-[20%] object-contain"
      /> */}
      <AvatarImage source={htImage} />
      <div className='flex flex-col justify-between items-center h-full'>
        <span className='text-white uppercase text-xs'>Game {game}</span>
        <span className='text-white uppercase text-xs'>TBD</span>
      </div>

      <AvatarImage source={atImage} />
    </div>
  );
}
function GameCards({
  ht_score,
  game,
  at_score
}:{
  ht_score:number,
  game?:string,
  at_score:number
}){
  return (
    
    <div className='flex justify-between gap-4 px-4 p-8 main-gr-bg-color sm:w-[200px] aspect-video items-center rounded-md w-full'>

    <MatchResultCircle result={ht_score} />
      
      <div className='flex flex-col justify-between items-center h-full'>
        <span className='text-white uppercase text-xs'>Game {game}</span>
      </div>

    <MatchResultCircle result={at_score} />
     
    </div>
    )
}