import { apiSlice } from '../api/apiSlice';
export const matchFormatApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMatchFormatTypes: builder.query({
      query: () => ({
        url: 'match-format/types',
        method: 'GET',
      }),
      providesTags: ['MatchFormatsTypes'],
    }),
    createMatchFormat: builder.mutation({
      query: (body: { name: string; value: number; type: string }) => ({
        url: 'match-format/create',
        method: 'Post',
        body,
      }),
      invalidatesTags: ['MatchFormats'],
    }),
    searchMatchFormat: builder.query({
      query: (params: { game?: string }) => ({
        url: 'match-format/all',
        method: 'GET',
        params,
      }),
      providesTags: ['MatchFormats'],
    }),
  }),
});
export const {
  useCreateMatchFormatMutation,
  useLazySearchMatchFormatQuery,
  useGetMatchFormatTypesQuery,
} = matchFormatApiSlice;
