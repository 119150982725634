import React, { useEffect, useRef, useState } from 'react';
import { StudentDashCoachRow } from './student-dash-coach-row';
import useIntersectionObserver from '../../../hooks/use-intersection-observer';
import { useSelector } from 'react-redux';
import { selectDashboardCoaches } from '../../../slices/userSlice';
import { useGetOrgCoachesQuery } from '../../../slices/organizationApiSlice';
import { OrganizationCoachModel } from '../../../model/organization-coach.model';
export function StudentDashboardCoaches({}) {
  const [fetch, setFetch] = useState(false);
  const elementRef = useRef(null);
  const [page, setPage] = useState<number>();
  const entry = useIntersectionObserver(elementRef, {});
  const dashboardCoaches = useSelector(selectDashboardCoaches);
  const isVisible = !!entry?.isIntersecting;
  const hasIOSupport = !!window.IntersectionObserver;
  const { data, isLoading, isSuccess } = useGetOrgCoachesQuery({ id: '0', page });
  useEffect(() => {
    if (isSuccess) console.log('isVisible', fetch);
    if (!isVisible || !fetch || isLoading || !data || !data.meta.next) return;
    setPage(data.meta.next);
  }, [isVisible, data?.meta, fetch]);

  useEffect(() => {
    console.log('isLoading', isLoading, fetch);
    let timeout: NodeJS.Timeout;
    if (isLoading) setFetch(false);
    else timeout = setTimeout(() => setFetch(true), 2000);
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [isLoading]);
  if (isLoading) return <p>Loading...</p>;
  return (
    <div className='flex flex-col items-center gap-5 realtive w-full sm:w-[100%] lg:w-[30%] border-1 rounded-xl border-slate-300'>
      <h2 className='font-bold text-lg pt-5'>Your Coaches</h2>
      <div className='flex flex-col w-full bg-white rounded-xl max-w-[100%] min-h-[300px] h-[300px] overflow-auto'>
        {dashboardCoaches.map((d, i: number) => (
          <StudentDashCoachRow key={i} model={new OrganizationCoachModel(d)} />
        ))}
        {isLoading ? (
          <div>Loading</div>
        ) : (
          <div ref={elementRef} className='flex flex-1 w-full min-h-[10px]'></div>
        )}
        {!hasIOSupport && data.meta.next && <span>Load more</span>}
      </div>
    </div>
  );
}
