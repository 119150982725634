import { useSelector } from 'react-redux';
import { selectUser } from '../../slices/auth';
import { useGetIndividualInvoicesQuery } from '../../slices/invoicesApiSlice';

const StudentSubscriptions = ({ isGuardian }: { isGuardian?: Boolean }) => {
  const user = useSelector(selectUser);
  const { data, isLoading, isError } = useGetIndividualInvoicesQuery({
    id: user.id,
    params: { isGuardian },
  });
  if (isLoading) return <p>Loading...</p>;
  // TODO: PAYMENT: REMOVE THIS RETURN TO USE PAYMENT
  return <p></p>;
  // if (!data || !data?.length)
  //   return (
  //     <div className='flex flex-col w-full h-full justify-center items-center font-bold'>
  //       <TbMoodEmpty size={40} />
  //       <p>No Subscriptions!</p>
  //     </div>
  //   );
  // return (
  //   <div className='listViewContainer h-[calc(100vh-85px)] overflow-auto flex flex-col'>
  //     <div className='flex flex-col'>
  //       <h1 className='text-2xl ml-3 font-semibold tracking-tight'>Subscriptions</h1>
  //       <p className='text-s mt-3 ml-3 font-semibold tracking-tight'>Manage your subscriptions</p>
  //     </div>
  //     <div className='h-[1px]  second-gr-bg-color w-full' />
  //     <div className='flex flex-wrap gap-2 justify-center'>
  //       {data.map((invoice: any) => (
  //         <StudentSubscriptionCard
  //           key={invoice.id}
  //           {...new StudentSubscriptionCardModel(invoice)}
  //         />
  //       ))}
  //     </div>
  //     <Outlet />
  //   </div>
  // );
};

export default StudentSubscriptions;
