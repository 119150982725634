import React from 'react'
import { Navigate, useParams } from 'react-router';
import ModalUnarchive from '../archived/components/unarchive-modal';
import { useUnArchiveSubcategoryMutation } from '../../slices/supportSubcategoryApiSlice';

const UnarchiveSubcategoryModal = () => {
    const param = useParams();
    const [unarchive, { isLoading, isError, isSuccess }] = useUnArchiveSubcategoryMutation();

    const onSubmit = () => {
        unarchive(param?.id);
    };

    if (isSuccess) return <Navigate to='../' />;

    return <ModalUnarchive onSubmit={onSubmit} isLoading={isLoading} name={param.name ?? ''} />;
};

export default UnarchiveSubcategoryModal;