import React, { useEffect, useState } from 'react';
import { MdDone } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { useGetSingleTournamentInfoQuery } from '../../../slices/tournamentApiSlice';
export function TournReviewTimeline({ }) {
  const param = useParams();
  const { data } = useGetSingleTournamentInfoQuery(param.id);

  const [status, setStatus] = useState({
    first: true,
    second: false,
    third: false,
    fourth: false,
  });

  useEffect(() => {
    // if (data.teams.length && data.Match.length) {
    //   setStatus({ ...status, second: true, third: true });
    // }
    if (!data) return;
    if (data?.teams?.length) {
      setStatus((s) => ({ ...s, second: true }));
    }

    if (data?.TournamentRound[0]?.match?.length) {
      setStatus((s) => ({ ...s, third: true }));
    }
    if (new Date(data.start_date) <= new Date()) {
      setStatus((s) => ({ ...s, fourth: true }));
    }
  }, [data]);

  // useEffect(() => {

  // }, [data]);
  const timeline = [
    {
      name: 'Create Tournament',
      condition: status.first,
    },
    {
      name: 'Add Teams',
      condition: status.second,
    },
    {
      name: 'Generate Bracket',
      condition: status.third,
    },
    {
      name: 'Start Tournament',
      condition: status.fourth,
    },
  ];

  return (
    <div className='flex flex-col gap-4'>
      <h2 className='font-bold text-xl'>Timeline</h2>
      <ol className='items-center justify-center min-w-fit w-full relative whitespace-nowrap sm:flex border border-gray-200 rounded p-5'>
        {timeline.map((t: any, i: number) => (
          <li key={i} className='relative mb-6 sm:mb-0'>
            <div className='flex  items-center'>
              <div
                className={` flex items-center justify-center w-6 h-6 ${t.condition ? 'bg-green-400' : 'bg-white'
                  } rounded-full border-2 ring-0 ring-white dark:bg-green-900 sm:ring-8 dark:ring-gray-900 shrink-0`}
              >
                <MdDone color='white' />
              </div>
              {i !== timeline.length - 1 && (
                <div className='hidden sm:flex flex-1 min-w-[20vh] bg-gray-200 h-0.5 dark:bg-gray-700'></div>
              )}
            </div>
            <div className='mt-3 sm:pr-8'>
              <h3 className='text-sm font-semibold text-gray-900 dark:text-white'>{t.name}</h3>
            </div>
          </li>
        ))}
      </ol>
    </div>
  );
}
