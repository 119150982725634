export class LessonModel {
  content = [];
  title = '';
  description = '';
  id: number = 0;
  constructor(d: any) {
    if (d.hasOwnProperty('id')) this.id = d.id;
    if (d.hasOwnProperty('name')) this.title = d.name;
    if (d.hasOwnProperty('description')) this.description = d.description;
    if (d.hasOwnProperty('content')) this.content = d.content;
  }
}
