import { useDroppable } from '@dnd-kit/core';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectMatchData } from '../../../slices/matchSlice';
import { MatchMemberRow } from '../components/match-member-row';
import { MatchMemberRowAway } from '../components/match-member-row-away';
export function SubstitudesMatchAway({ editMode }: any) {
  const matchData = useSelector(selectMatchData);

  const { isOver, setNodeRef } = useDroppable({ id: 'substitudes' });

  return (
    <>
      {matchData &&
        matchData.away_team?.[0]?.User
          // .filter(data.away_team?.[0]?.User.map((u: any) => u.id !== data.away_team?.[0]?.activeMembers.map((id: any) => id)))
          // .sort((a: any, b: any) => b.isCaptain - a.isCaptain)
          .map((u: any, i: any) => (
            <React.Fragment key={i}>
              {matchData.away_team_sub.includes(u.id) ? (
                <MatchMemberRowAway
                  editMode={editMode}
                  id={u.id}
                  key={i}
                  {...u}
                  gameId={matchData.away_team?.[0]?.game_id}
                  InGameName={u.InGameName}
                  isCaptain={u.id === matchData.away_team?.[0]?.captain}
                  isCheckedIn={matchData?.checked_in_students.includes(u?.id)}
                />
              ) : null}
            </React.Fragment>
          ))}
    </>
  );
}
