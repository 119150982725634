import React, { useState } from 'react';
import {
  MdClear,
  MdDone,
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowRight,
} from 'react-icons/md';
import { StudentCoachHoursRequestModel } from '../model/student-coach-hours-request.model';
import { useResponseToRequestMutation } from '../../../slices/classroomApiSlice';
import { ScheduledHoursStatus } from '../../../utils/constants';
import { getDuration, getTimeComponents } from '../../../utils/utils';
import { CustomError } from '../../../utils/custom-types';
export function HoursRequestRow({
  school,
  requests,
}: {
  school: { id: number; name: string; hours: number };
  requests: StudentCoachHoursRequestModel[];
}) {
  const [show, setShow] = useState(false);
  const [
    responseToRequest,
    { data, isLoading: reqLoading, isSuccess: reqSuccess, isError: reqIsError, error: reqError },
  ] = useResponseToRequestMutation();
  function responseHandler(id: number, response: number, hours: number) {
    responseToRequest({ id, body: { hours, response, school_id: school.id } });
  }
  return (
    <div className='flex flex-col'>
      <div
        req-count={requests.filter((r) => r.school === school.name).length}
        onClick={() => setShow(!show)}
        className={`schoolRequest__header ${show ? 'active' : ''}`}
      >
        {show ? <MdOutlineKeyboardArrowDown /> : <MdOutlineKeyboardArrowRight />}
        {school.name}
      </div>
      {show && (
        <p className='text-xs '>
          Available Hours:{' '}
          <b>
            {getTimeComponents(school.hours).hr}hr, {getTimeComponents(school.hours).min} min,{' '}
            {getTimeComponents(school.hours).sec} sec
          </b>
        </p>
      )}
      {show &&
        requests
          .filter((r) => r.school === school.name)
          .map((r) => {
            const { hours, minutes, seconds } = getDuration(
              new Date(r.raw_start_date ?? ''),
              new Date(r.raw_end_date ?? ''),
            );
            return (
              <div className='flex flex-col shadow-sm p-2'>
                <h2 className='text-xs'>
                  Classroom: <b>{r.classroom}</b>
                </h2>
                <div className='text-xs flex flex-col'>
                  <span className='flex justify-between'>
                    Start date:
                    <b> {r.start_date}</b>
                  </span>
                  <span className='flex justify-between'>
                    End date:
                    <b> {r.end_date}</b>
                  </span>
                </div>

                <div key={r.id} className='flex justify-between items-center'>
                  <p className='text-xs'>
                    <b>{r.username} </b>
                    requested{' '}
                    <b>
                      {' '}
                      {hours}hrs, {minutes}min, {seconds}sec
                    </b>
                  </p>
                  <div
                    onClick={() => responseHandler(r.id, ScheduledHoursStatus.Approved, r.hours)}
                    className='flex p-2 justify-center items-center shadow-md rounded-lg hover:bg-slate-50 cursor-pointer'
                  >
                    <MdDone color='green' />
                  </div>
                  <div
                    className='flex p-2 justify-center items-center shadow-md rounded-lg  hover:bg-slate-50 cursor-pointer'
                    onClick={() => responseHandler(r.id, ScheduledHoursStatus.NotApproved, r.hours)}
                  >
                    <MdClear color='red' />
                  </div>
                </div>
                {reqIsError && (
                  <p className='text-red-700 text-sm'>{(reqError as CustomError)?.data?.message}</p>
                )}
              </div>
            );
          })}
    </div>
  );
}
