import * as component from './components';
import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useParams, Link } from 'react-router-dom';
import {
  useCreateGameMatchMutation,
  useGetAllMatchesByTournamentRoundQuery,
  useGetAllReschedulesByMatchIdQuery,
  useLazyGetSingleMatchInfoQuery,
} from '../../slices/matchApiSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useChangeUsersMatchTeamRoleMutation } from '../../slices/userApiSlice';
import { selectUser } from '../../slices/auth';
import {
  initChatMessages,
  selectDisputes,
  selectMatchData,
  selectReschedules,
  selectSocketState,
  setMatchData,
} from '../../slices/matchSlice';
import { DndContext } from '@dnd-kit/core';
import {
  MatchStatus,
  SeasonProgressStatus,
  SocketActions,
  TournamentProgressStatus,
  UserRoles,
} from '../../utils/constants';
import CanIView from '../../components/can-i-view/can-i-view';
import { CustomError } from '../../utils/custom-types';
import useChatTabs from '../../hooks/use-chat-tabs';
import useMatchData from '../../hooks/use-match-data';
import { useStateContext } from '../../contexts/ContextProvider';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getTypeOfMatchFormat } from '../../utils/utils';
import { FaPlus } from 'react-icons/fa';
import useMatchStudentTeamId from '../../hooks/use-match-student-team-id';
import AddGameAccount from '../games/overview/add-game/AddGameAccount';
import useOgmaBelongsToTeam from '../../hooks/use-ogma-belongs-to-team';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../../components/ui/tooltip/Tooltip';

function ViewMatch() {
  const [tempChat, setTempChat] = useState('');
  const [showChat, setShowChat] = useState(true);
  const [canWeForfeit, setCanWeForfeit] = useState(0);
  const param = useParams();
  const [getMatchInfo, { error, data, isLoading, isError }] = useLazyGetSingleMatchInfoQuery();
  const { isScreenshoot, setIsScreenshoot } = useStateContext();
  const [ogmaCheck, setOgmaCheck] = useState(false);
  const notify = () => {
    toast('Success!', {
      autoClose: 2000,
      style: {
        background: 'white',
      },
      progressStyle: {
        background: 'green',
      },
      onClose: () => {
        if (isScreenshoot) {
          setIsScreenshoot(false);
        }
      },
    });
  };
  useEffect(() => {
    if (isScreenshoot) {
      notify();
    }
  }, [isScreenshoot]);
  const user = useSelector(selectUser);
  const matchData = useSelector(selectMatchData);

  const { data: tournamentRoundData } = useGetAllMatchesByTournamentRoundQuery(matchData?.id, {
    skip: !matchData?.id,
  });
  const isOgmaTeam = useOgmaBelongsToTeam([
    matchData?.home_team?.[0]?.id,
    matchData?.away_team?.[0]?.id,
  ]);
  useEffect(() => {
    if (matchData.tournament_round_id) {
      setCanWeForfeit(0);
      tournamentRoundData?.match?.forEach((m: any) => {
        if (m.status === MatchStatus.Cancelled || m.status === MatchStatus.DoubleForfeit) {
          setCanWeForfeit((prevCount) => prevCount + 1);
        }
      });
    }
  }, [tournamentRoundData]);

  const [tabs, rooms, partOfTeam] = useChatTabs();
  const checkInStudentRoom = useMatchStudentTeamId();
  useEffect(() => {
    if (!partOfTeam?.length || !Object.keys(matchData)?.length) return;
    const homeTeam = matchData?.home_team?.[0];
    const awayTeam = matchData?.away_team?.[0];

    const homeTeamActiveAndSub = [
      ...(homeTeam?.activeMembers ?? []),
      ...(homeTeam?.subMembers ?? []),
    ];
    const awayTeamActiveAndSub = [
      ...(awayTeam?.activeMembers ?? []),
      ...(awayTeam?.subMembers ?? []),
    ];
    const finalArr = homeTeamActiveAndSub.concat(awayTeamActiveAndSub);

    if (partOfTeam?.length === 2) {
      const allChecked =
        finalArr.sort().join(',') === [...matchData?.checked_in_students].sort().join(',');
      allChecked ? setOgmaCheck(false) : setOgmaCheck(true);
    } else if (partOfTeam?.length === 1 && partOfTeam[0][1] === 'Home') {
      setOgmaCheck(true);
    } else if (partOfTeam.length === 1 && partOfTeam[0][1] === 'Away') {
      setOgmaCheck(true);
    } else {
      setOgmaCheck(false);
    }
  }, [matchData, partOfTeam]);

  const [showCheckIn, showCheckOut, captainsPresent, results] = useMatchData();

  const {
    // data: rescheduleData,
    isLoading: rescheduleIsLoading,
    error: rescheduleError,
  } = useGetAllReschedulesByMatchIdQuery(param?.id, { skip: !param?.id });

  const rescheduleData = useSelector(selectReschedules);

  const dispatch = useDispatch();
  const [teamId, setTeamId] = useState<number | undefined | 'admin'>(undefined);
  const studentMatchData = useMatchStudentTeamId();

  // useEffect(() => {
  //   return () => {
  //     dispatch(setMatchData({}));
  //   };
  // }, []);
  useEffect(() => {
    if (!param?.id) return;
    getMatchInfo(param?.id);
  }, [param?.id]);
  // useEffect(() => {
  //   if (Object.keys(matchData).length > 1) return;

  //   getMatchInfo(param.id)
  //     .unwrap()
  //     .then((res: any) => dispatch(setMatchData(res)));
  // }, [matchData]);
  useEffect(() => {
    if (isLoading || !Object.keys(matchData).length || !rooms) return;
    let isOk = false;
    user?.team?.forEach((t: any, i: number) => {
      console.log({ t, gameId: matchData?.game_id });
      if (t?.game_id === matchData?.game_id) {
        // teamId = i;
        setTeamId(i);
        isOk = true;
        console.log({ teamId });
      }
    });
    if (!isOk) setTeamId('admin');
  }, [isLoading, matchData, rooms]);

  useEffect(() => {
    if (!teamId?.toString() || teamId === undefined || (data && Object.keys(data)?.length === 1))
      return;
    dispatch({
      type: SocketActions.Connect,
      payload: {
        id: param?.id,
        rooms,
        teamId,
        team: user?.team?.length ? studentMatchData[0] : undefined,
      },
    });
    return () => {
      dispatch({ type: SocketActions.Disconnect, payload: { id: user?.id } });
      dispatch(setMatchData({}));
      dispatch(initChatMessages([]));
    };
  }, [teamId]);

  const [
    createGameMatch,
    { isLoading: crIsLoading, isSuccess: crIsSuccess, isError: crIsError, error: crError },
  ] = useCreateGameMatchMutation();

  function handleCreateGameMatch() {
    createGameMatch(param?.id);
  }
  function sendChat(message: any, team: boolean | number) {
    if (teamId === undefined) return;
    let payload: any;
    if (team) {
      payload = {
        isTeam: true,
        room: `m${param?.id}-t${typeof team === 'number' ? team : user?.team[teamId]?.id}`,
        user_id: user?.id,
        match_id: param?.id,
        team_id: typeof team === 'number' ? team : user?.team[teamId]?.id,
        username: user.username,
        team: user?.team?.length ? user?.team[teamId]?.teamName : '',
        message,
        image: user.image ? user.image : '',
        time: new Date().toTimeString().split(' ')?.[0],
      };
      // socket.emit('team-chat', JSON.stringify(payload));
    } else {
      payload = {
        isTeam: false,
        room: param?.id,
        user_id: user?.id,
        username: user?.username,
        team: user?.team?.length ? user?.team[teamId]?.teamName : '',
        message,
        image: user.image ? user.image : '',
        time: new Date().toTimeString().split(' ')?.[0],
      };
      // socket.emit('room-chat', JSON.stringify(payload));
    }
    dispatch({ type: SocketActions.SendChat, payload });
  }

  function handleCheckIn() {
    if (teamId === undefined) return;
    let payload = {
      room: param?.id,
      user_id: user?.id,
      // username: user?.username,
      teamId: [user?.team?.length ? user?.team[teamId]?.id : undefined],
      singleCheckIn: true,
    };
    dispatch({ type: SocketActions.CheckIn, payload });
  }

  function handleCheckOut() {
    if (teamId === undefined) return;
    let payload = {
      room: param?.id,
      user_id: user?.id,
      teamId: [user?.team?.length ? user?.team[teamId]?.id : undefined],
      singleCheckOut: true,
    };
    dispatch({ type: SocketActions.CheckOut, payload });
  }
  //   socket.emit('check-in', payload);
  //   dispatch(checkIn(user.id));
  // }

  const [changeUsersMatchTeam, { data: chData, isLoading: chIsLoading, isError: chIsError }] =
    useChangeUsersMatchTeamRoleMutation();

  const handleDragEnd = (event: any) => {
    const { over, active } = event;
    if (!over) return;
    console.log('active id = ', active?.id);
    const whichTeam = active?.id?.[0] === 'h' ? 'home' : 'away';
    const subMemberToGoToActive = active?.id.slice(1);
    const activeMemberToGoToSub = over?.id.slice(1);
    const matchId = param?.id;

    if (whichTeam === 'home') {
      /**
       * changeUsersTeam({
        id: param.id,
        body: { studentId: active.id, team: type },
      });
       * 
       */
      // TODO: Hysen???
      changeUsersMatchTeam({
        id: matchId,
        body: {
          whichTeam,
          subMemberToGoToActive: subMemberToGoToActive,
          activeMemberToGoToSub: activeMemberToGoToSub,
        },
      });
    } else {
      changeUsersMatchTeam({
        id: matchId,
        body: {
          whichTeam: whichTeam,
          subMemberToGoToActive: subMemberToGoToActive,
          activeMemberToGoToSub: activeMemberToGoToSub,
        },
      });
    }
  };

  const disputes = useSelector(selectDisputes);
  // if (isError) return <Navigate to='/went-wrong' />;
  const socketState = useSelector(selectSocketState);

  if (isError)
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError)?.data?.message }} />;
  // return <WentWrong message={{ message: (error as CustomError).data.message }} />;

  const loadingState =
    rescheduleIsLoading ||
    isLoading ||
    !matchData ||
    !Object.keys(matchData).length ||
    teamId === undefined ||
    socketState === 'loading';
  if (loadingState) return <p>Loading...</p>;
  if (Object.keys(matchData).length === 1) {
    return <AddGameAccount gameId={data?.game_id} />;
  }

  const checkInCondition =
    matchData.status === MatchStatus.Upcoming || matchData.status === MatchStatus.InProgress;
  matchData.status === MatchStatus.Upcoming || matchData.status === MatchStatus.InProgress;
  const checkIsNotByWeek = matchData?.away_team?.length && matchData?.home_team?.length;
  const startButtonConditionStudent =
    // (matchData.status === MatchStatus.Upcoming || matchData.status === MatchStatus.Postponed) &&
    captainsPresent &&
    (user?.id === data?.away_team?.[0]?.captain || user?.id === data?.home_team?.[0]?.captain);
  const startMatchConditionAdmin =
    // (matchData.status === MatchStatus.Upcoming || matchData.status === MatchStatus.Postponed) &&
    user.roles === UserRoles.Admin;
  const canEditHome =
    user.id === data?.home_team?.[0]?.captain ||
    (user.roles === UserRoles.Ogma && partOfTeam.filter((t: any) => t[1].toLowerCase() === 'home'))
      .length ||
    user.roles === UserRoles.Admin;
  const canEditAway =
    user.id === data?.away_team?.[0]?.captain ||
    (user.roles === UserRoles.Ogma && partOfTeam.filter((t: any) => t[1].toLowerCase() === 'away'))
      .length ||
    user.roles === UserRoles.Admin;

  return (
    <>
      <div className='listViewContainer fullPage relative border-1 p-0'>
        <component.ViewMatchHeader />
        <div className='flex flex-col'>
          {matchData.season_week_id && (
            <div className='flex pl-5 mb-2'>
              <component.BackToSeason
                seasonId={matchData.SeasonWeek.season_id}
                seasonWeekId={matchData.season_week_id}
              />
            </div>
          )}
          <div className='p-5 flex gap-2 justify-between items-center flex-wrap'>
            <div className='flex gap-2 '>
              {user.roles === UserRoles.Admin && (
                <>
                  <h2 className='font-bold  '>Match Details</h2>
                  {matchData.status === MatchStatus.Upcoming ||
                  matchData.status === MatchStatus.RescheduleRequested ||
                  matchData.status === MatchStatus.UpcomingRescheduledFor ? (
                    <component.EditMatchDetails />
                  ) : null}
                </>
              )}
              {(data?.home_team[0]?.activeMembers.includes(user?.id) ||
                data?.away_team[0]?.activeMembers.includes(user?.id) ||
                data?.home_team[0]?.subMembers.includes(user?.id) ||
                data?.away_team[0]?.subMembers.includes(user?.id) ||
                user.roles === UserRoles.Admin ||
                (user.roles === UserRoles.Ogma && matchData?.canReschedule)) && (
                // matchData.status === MatchStatus.Upcoming && (
                <CanIView matchRules={['reschedule', matchData.status]}>
                  <Link
                    attr-count={rescheduleData.length}
                    to='./view-reschedules'
                    className='main-gr-bg-color cursor-pointer rounded-2xl flex items-center justify-center px-4 p-1 text-xs gap-1 text-white notification-show before:!-top-2'
                  >
                    Reschedule Requests
                  </Link>
                </CanIView>
              )}
              <CanIView matchRules={['live', matchData.status]} component='view-match.disputes'>
                <Link
                  attr-count={disputes?.length}
                  to='./view-disputes'
                  className='main-gr-bg-color cursor-pointer rounded-md flex items-center justify-center px-4 p-1 text-xs gap-1 text-white notification-show before:!-top-2'
                >
                  Disputes
                </Link>
              </CanIView>
              {user?.roles === UserRoles.Admin ||
              (user?.roles === UserRoles.Ogma && matchData?.canChat) ? (
                <Link
                  attr-count={matchData?.ForfeitHistory?.length}
                  to='./view-forfeits'
                  className='main-gr-bg-color cursor-pointer rounded-md flex items-center justify-center px-4 p-1 text-xs gap-1 text-white notification-show before:!-top-2'
                >
                  Forfeits
                </Link>
              ) : null}
              {checkIsNotByWeek ? (
                <CanIView
                  matchRules={['start', matchData.status]}
                  component='view-match.start-game'
                >
                  <component.StartMatchButton id={param?.id ?? ''} />
                </CanIView>
              ) : null}
            </div>
            {matchData.canChat && (
              <component.ShowChatButton showChat={showChat} setShowChat={setShowChat} />
            )}
          </div>
          <Outlet />
        </div>
        <div>
          {showChat && (
            <component.ChatMatch
              setShowChat={setShowChat}
              sendChat={sendChat}
              tempChat={tempChat}
              setTempChat={setTempChat}
            />
          )}
          <div className='flex overflow-auto w-full relative flex-col gap-5 p-5 text-lg items-center flex-1'>
            {(user?.id === data?.home_team[0]?.captain ||
              user?.id === data?.away_team[0]?.captain ||
              (user.roles === UserRoles.Ogma && data?.canReschedule)) &&
            rescheduleData.length <= 0 ? (
              <CanIView matchRules={['reschedule', matchData.status]}>
                <component.RescheduleButton />
              </CanIView>
            ) : null}
            <CanIView component='view-match.streaming-info'>
              <div className='flex flex-col border rounded-lg p-2 text-center'>
                <h2 className='font-bold text-sm'>Streaming Info</h2>
                <p className='text-sm'>Match ID: {matchData?.id} </p>
                <p className='text-sm'>
                  {matchData?.SeasonWeek?.season_id
                    ? `Season ID: ${matchData?.SeasonWeek?.season_id}`
                    : matchData?.TournamentRound?.tournament_id
                    ? `Tournament ID: ${matchData?.TournamentRound?.tournament_id}`
                    : ''}
                </p>
              </div>
            </CanIView>
            <div className='flex gap-5'>
              {matchData.streaming_user && (
                <h2>{matchData.streaming_user} is streaming this match!</h2>
              )}
              {user.roles === UserRoles.Ogma &&
              (!matchData.streaming_user || matchData.streaming_user === user.username) &&
              matchData.canChat ? (
                <CanIView matchRules={['live', matchData.status]}>
                  <component.StreamingUpdateButton />
                </CanIView>
              ) : (
                <></>
              )}
            </div>
            <component.ViewMatchDetails
              {...matchData}
              isStreaming={user.is_streaming}
              isMatch={true}
            />
            <div className='flex flex-wrap justify-center gap-8 w-full h-full'>
              <DndContext onDragEnd={handleDragEnd}>
                <component.HomeTeamMatchInfo canEdit={canEditHome} />
              </DndContext>

              <div className='test:hidden flex lg:flex flex-col rounded-lg overflow-hidden w-full lg:w-2/4 max-w-[450px] h-fit justify-between'>
                <div className='m-auto'>{getTypeOfMatchFormat(matchData)}</div>
                <div className='flex flex-col relative'>
                  {matchData?.MatchGame.map((m: any, i: number) => {
                    return (
                      <component.MatchGameRow
                        key={i}
                        id={m?.id}
                        index={i}
                        game={`Game ${i + 1}`}
                        hScore={m.ht_result}
                        aScore={m.aw_result}
                        game_screenshots={m?.MatchGameScreenshot}
                        screenshot_status={m.status}
                        matchStatus={matchData.status === MatchStatus.InProgress}
                        canEdit={matchData.canChat}
                      />
                    );
                  })}
                  <CanIView
                    component='view-match.create-match-game'
                    matchRules={['createGame', matchData.status]}
                  >
                    <div
                      onClick={handleCreateGameMatch}
                      className='flex rounded-lg hover:opacity-80 cursor-pointer mt-2 p-2 bg-slate-300 rounded-2 text-white justify-center'
                    >
                      {crIsLoading ? 'Creating game...' : <FaPlus />}
                    </div>
                  </CanIView>
                </div>
                <div className='flex flex-col gap-2 py-2'>
                  {checkInCondition && (
                    <component.MatchCheckInStatus
                      areAllCheckedIn={
                        matchData?.away_team?.length && matchData?.home_team?.length
                          ? [
                              ...matchData?.away_team[0]?.activeMembers,
                              ...matchData?.away_team[0]?.subMembers,
                            ]
                              .concat([
                                ...matchData?.home_team[0]?.activeMembers,
                                ...matchData?.home_team[0]?.subMembers,
                              ])
                              .every((i) => matchData?.checked_in_students.includes(i))
                          : false
                      }
                      matchStatus={matchData?.status}
                    />
                  )}
                  <div className='flex self-center mt-4'>
                    <CanIView
                      matchRules={['live', matchData.status]}
                      component='view-match.end-game'
                    >
                      <>
                        {user.roles === UserRoles.Admin ? (
                          <component.EndGameButton />
                        ) : user.roles === UserRoles.Ogma && isOgmaTeam ? (
                          <component.EndGameButton />
                        ) : user.roles === UserRoles.Student &&
                          (user.id === matchData?.away_team?.[0]?.captain ||
                            user.id === matchData?.home_team?.[0]?.captain) ? (
                          <component.EndGameButton />
                        ) : null}
                      </>
                    </CanIView>
                  </div>
                  {user?.roles === UserRoles.Admin ||
                  (user?.roles === UserRoles.Ogma && matchData?.canChat) ? (
                    <div className='flex self-center'>
                      {(matchData.status === MatchStatus.InProgress ||
                        matchData.status === MatchStatus.Upcoming ||
                        matchData.status === MatchStatus.RescheduleRequested ||
                        matchData.status === MatchStatus.UpcomingRescheduledFor) &&
                      checkIsNotByWeek &&
                      data?.TournamentRound?.Tournament?.status !==
                        TournamentProgressStatus.Completed ? (
                        <component.ForfeitMatchButton />
                      ) : null}
                    </div>
                  ) : null}
                  <CanIView component='view-match.edit-match'>
                    <div className='flex flex-wrap gap-2 justify-center items-center '>
                      {matchData.status === MatchStatus.Completed &&
                        data?.TournamentRound?.Tournament?.status !==
                          TournamentProgressStatus.Completed && <component.EditMatchResultButton />}
                      {matchData.status === MatchStatus.Forfeit &&
                      checkIsNotByWeek &&
                      data?.TournamentRound?.Tournament?.status !==
                        TournamentProgressStatus.Completed &&
                      data?.SeasonWeek?.Season?.status !== SeasonProgressStatus.Completed ? (
                        <component.UnforfeitMatchButton />
                      ) : null}
                      {matchData.status === MatchStatus.DoubleForfeit &&
                      data?.TournamentRound?.Tournament?.status !==
                        TournamentProgressStatus.Completed &&
                      checkIsNotByWeek ? (
                        <component.UnforfeitDoubleMatchButton />
                      ) : null}
                      {showCheckIn
                        ? tabs.map((t: any, i: number) => (
                            <CanIView key={i} matchRules={['checkin', matchData.status]}>
                              <div className='flex justify-center'>
                                {t?.[0] !== 0 ? (
                                  <>
                                    <div className='flex flex-wrap gap-5 justify-center items-center '>
                                      <Link
                                        to={`check-in/${t?.[0]}`}
                                        className='rounded-lg flex justify-center p-2 mr-2 uppercase main-gr-bg-color text-white text-sm min-w-[200px] cursor-pointer'
                                      >
                                        Check in {t?.[1]} team
                                      </Link>
                                    </div>
                                    <div className='flex flex-wrap gap-5 justify-center items-center '>
                                      <Link
                                        to={`check-out/${t?.[0]}`}
                                        className='rounded-lg flex justify-center p-2 uppercase bg-red-700 text-white text-sm min-w-[200px] cursor-pointer'
                                      >
                                        Check Out {t?.[1]} team
                                      </Link>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className='flex flex-wrap gap-5 justify-center items-center '>
                                      <Link
                                        to={`check-in/all/${tabs?.[1]?.[0]}/${tabs?.[2]?.[0]}`}
                                        className='rounded-lg flex justify-center p-2 mr-2 uppercase main-gr-bg-color text-white text-sm min-w-[200px] cursor-pointer'
                                      >
                                        Check in All teams
                                      </Link>
                                    </div>
                                    <div className='flex flex-wrap gap-5 justify-center items-center '>
                                      <Link
                                        to={`check-out/all/${tabs?.[1]?.[0]}/${tabs?.[2]?.[0]}`}
                                        className='rounded-lg flex justify-center p-2 uppercase bg-red-700 text-white text-sm min-w-[200px] cursor-pointer'
                                      >
                                        Check Out All Teams
                                      </Link>
                                    </div>
                                  </>
                                )}
                              </div>
                            </CanIView>
                          ))
                        : null}
                      {(matchData.status === MatchStatus.Upcoming ||
                        matchData.status === MatchStatus.RescheduleRequested ||
                        matchData.status === MatchStatus.UpcomingRescheduledFor) &&
                      canWeForfeit !== tournamentRoundData?.match?.length &&
                      data?.TournamentRound?.Tournament?.status !==
                        TournamentProgressStatus.Completed ? (
                        <component.CancelMatchButton />
                      ) : null}
                      {matchData.tournament_round_id === null &&
                        data?.TournamentRound?.Tournament?.status !==
                          TournamentProgressStatus.Completed && <component.DeleteMatchButton />}
                      {matchData.status === MatchStatus.Cancelled &&
                      data?.TournamentRound?.Tournament?.status !==
                        TournamentProgressStatus.Completed ? (
                        <component.UncancelMatchButton />
                      ) : null}
                    </div>
                  </CanIView>
                  {user.roles === UserRoles.Student &&
                    Object.keys(matchData).length &&
                    // matchData.status === MatchStatus.Upcoming &&
                    matchData.canCheckIn && (
                      // || matchData.status === MatchStatus.Live
                      // matchData.status === MatchStatus.Postponed
                      <CanIView matchRules={['checkin', matchData.status]}>
                        <div className='flex flex-wrap flex-col gap-5 justify-center items-center '>
                          {showCheckIn && !matchData?.checked_in_students.includes(user.id) && (
                            <div
                              onClick={handleCheckIn}
                              className='rounded-lg flex justify-center p-2 uppercase main-gr-bg-color text-white text-sm min-w-[200px] cursor-pointer'
                            >
                              Check in
                            </div>
                          )}
                          {showCheckOut && (
                            <div
                              onClick={handleCheckOut}
                              className='rounded-lg flex justify-center p-2 uppercase main-gr-bg-color text-white text-sm min-w-[200px] cursor-pointer'
                            >
                              Check Out
                            </div>
                          )}
                          {checkInStudentRoom?.[0].map((room: any) => {
                            const teamMembers = [
                              ...matchData?.[`${room?.[0]}_team_active`],
                              ...matchData?.[`${room?.[0]}_team_sub`],
                            ];
                            const allCheckedIn = teamMembers.every((t) =>
                              matchData?.checked_in_students.includes(t),
                            );
                            return (
                              <>
                                {!teamMembers.every((t) =>
                                  matchData?.checked_in_students.includes(t),
                                ) &&
                                  (matchData.home_team[0].captain === user.id ||
                                    matchData.away_team[0].captain === user.id) && (
                                    <Link
                                      key={`${room?.[0]}-check-in`}
                                      to={`check-in/${room?.[1]}`}
                                      className='rounded-lg flex justify-center p-2 uppercase main-gr-bg-color text-white text-sm min-w-[200px] cursor-pointer'
                                    >
                                      Check in {room?.[0]} team
                                    </Link>
                                  )}
                                {teamMembers.some((t) =>
                                  matchData?.checked_in_students.includes(t),
                                ) &&
                                  (matchData.home_team[0].captain === user.id ||
                                    matchData.away_team[0].captain === user.id) && (
                                    <Link
                                      key={`${room?.[0]}-check-out`}
                                      to={`check-out/${room?.[1]}`}
                                      className='rounded-lg flex justify-center p-2 uppercase main-gr-bg-color text-white text-sm min-w-[200px] cursor-pointer'
                                    >
                                      Check Out {room?.[0]} team
                                    </Link>
                                  )}
                              </>
                            );
                          })}
                        </div>
                      </CanIView>
                    )}
                  {user.roles === UserRoles.Ogma &&
                  ogmaCheck &&
                  showCheckIn &&
                  partOfTeam?.length === 2 ? (
                    <CanIView matchRules={['checkin', matchData.status]}>
                      <div className='flex justify-center'>
                        <div className='flex flex-wrap gap-2 justify-center items-center '>
                          <Link
                            to={`check-in/all/${partOfTeam[0]?.[0]}/${partOfTeam[1]?.[0]}`}
                            className='rounded-lg flex justify-center p-2 uppercase main-gr-bg-color text-white text-sm min-w-[200px] cursor-pointer'
                          >
                            Check in All team
                          </Link>
                          <Link
                            to={`check-out/all/${partOfTeam[0]?.[0]}/${partOfTeam[1]?.[0]}`}
                            className='rounded-lg flex justify-center p-2 uppercase bg-red-700 text-white text-sm min-w-[200px] cursor-pointer'
                          >
                            Check out All team
                          </Link>
                        </div>
                      </div>
                    </CanIView>
                  ) : null}
                  {user.roles === UserRoles.Ogma && ogmaCheck && showCheckIn
                    ? partOfTeam.map((t: any, i: number) => (
                        <CanIView key={i} matchRules={['checkin', matchData.status]}>
                          <div className='flex justify-center'>
                            <div className='flex flex-wrap gap-5 justify-center items-center '>
                              <Link
                                to={`check-in/${t?.[0]}`}
                                className='rounded-lg flex justify-center p-2 mr-2 uppercase main-gr-bg-color text-white text-sm min-w-[200px] cursor-pointer'
                              >
                                Check in {t?.[1]} team
                              </Link>
                            </div>
                            <div className='flex flex-wrap gap-5 justify-center items-center '>
                              <Link
                                to={`check-out/${t?.[0]}`}
                                className='rounded-lg flex justify-center p-2 uppercase bg-red-700 text-white text-sm min-w-[200px] cursor-pointer'
                              >
                                Check Out {t?.[1]} team
                              </Link>
                            </div>
                          </div>
                        </CanIView>
                      ))
                    : null}
                  {/* {user.roles === UserRoles.Ogma && ogmaCheck && showCheckIn
                  ? tabs.map((t, i) => (
                      <CanIView key={i} matchRules={['checkin', matchData.status]}>
                        <div className='flex flex-wrap gap-5 justify-center items-center '>
                          <Link
                            to={`check-in/${t?.[0]}`}
                            className='rounded-lg flex justify-center p-2 uppercase main-gr-bg-color text-white text-sm min-w-[200px] cursor-pointer'
                          >
                            Check in {t?.[1]} team
                          </Link>
                        </div>
                      </CanIView>
                    ))
                  : null} */}
                  {user.roles === UserRoles.Student && (
                    // matchData.status === MatchStatus.Live && (
                    <CanIView matchRules={['live', matchData.status]}>
                      <div className='flex flex-wrap gap-5 justify-center items-center '>
                        <Link
                          to='./dispute'
                          className='rounded-lg flex justify-center p-2 uppercase main-gr-bg-color text-white text-sm min-w-[200px] cursor-pointer'
                        >
                          Dispute Results
                        </Link>
                      </div>
                    </CanIView>
                  )}
                </div>
              </div>
              <div className='flex flex-col gap-2 w-fit'>
                <DndContext onDragEnd={handleDragEnd}>
                  <component.AwayTeamInfo canEdit={canEditAway} />
                </DndContext>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewMatch;
