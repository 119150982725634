import { ReactNode } from 'react';

function TableContainer({ children, extraClass }: { children: ReactNode; extraClass?: string }) {
  return (
    <div className='border-b border-gray-200 overflow-auto flex-1'>
      <table className='table-fixed min-w-full divide-y divide-gray-200'>{children}</table>
    </div>
  );
}

export default TableContainer;
