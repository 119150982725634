import { Formik } from 'formik';
import { Navigate, useParams } from 'react-router-dom';
import { useGetTeamByIdQuery, useUpdateTeamMutation } from '../../../slices/teamsApiSlice';
import { OutsideClick } from '../../../components/outside-click';
import { UpdateTeamSchema } from './yup-schema/update-team.schema';
import ModalContent from '../../../components/modal-content';
import { CustomError } from '../../../utils/custom-types';

const UpdateTeamModal = () => {
  const param = useParams();
  const { error, isSuccess, data, isError, isLoading, isFetching, refetch } = useGetTeamByIdQuery({
    id: param.id,
    query: ''
  });

  const [updateTeam, { data: updateData, isLoading: updateIsLoading, isSuccess: updateIsSuccess, isError: updateTeamIsError, error: updateTeamError }] =
    useUpdateTeamMutation();

  const onSubmit = (values: any) => {
    console.log({ values });
    const data = values;
    // data.status = Number(data.status);
    // if (data.school !== undefined) {
    //   data.schoolId = data.school ? +data.school : null;
    // }
    delete data.School;
    delete data.school;
    console.log({ data });
    updateTeam({ id: param.id, body: data })
      .unwrap()
      .then((res: any) => console.log(res))
      .catch((error: any) => console.log(error));
  };
  console.log('test', data);
  const formInputs = [
    {
      mainTitle: 'Team Information',
      name: 'teamName',
      label: 'Team Name',
      type: 'text',
      placeholder: 'Enter team name',
      isTrue: true,
    },
    // {
    //   mainTitle: 'Team Information',
    //   name: 'school',
    //   label: 'School',
    //   type: 'text',
    //   placeholder: 'High School 1',
    //   isSelect: true,
    //   options: schoolData ? schoolData : [],
    // },
    // {
    //   mainTitle: 'Team Information',
    //   name: 'status',
    //   label: 'Status',
    //   type: 'text',
    //   placeholder: 'Status',
    //   isSelect: true,
    //   options: [
    //     { id: 0, name: 'Deactivated' },
    //     { id: 1, name: 'Active' },
    //   ],
    // },
    // {
    //   mainTitle: 'Team Information',
    //   name: 'teamMembers',
    //   label: 'Team Members',
    //   type: 'text',
    //   placeholder: 'Enter students username ...',
    //   isTrue: true,
    // },
  ];

  if (updateIsSuccess) return <Navigate to='../' />;
  if (isError || (isSuccess && !data))
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError).data.message }} />;

  if (isLoading) return <p>Loading...</p>;
  return (
    <>
      <OutsideClick />
      <Formik
        initialValues={{ teamName: data.teamName }}
        validationSchema={UpdateTeamSchema}
        onSubmit={onSubmit}
      >
        {({ errors }) => {
          return (
            <ModalContent
              errors={Object.keys(errors).length}
              extraClass='h-[45vh]'
              formInputs={formInputs}
              isLoading={updateIsLoading}
              name={`Edit Team - ${data.teamName}`}
              isError={updateTeamIsError}
              error={updateTeamError ? (updateTeamError as CustomError).data.message : ''}
            />
          );
        }}
      </Formik>
    </>
  );
};

export default UpdateTeamModal;
