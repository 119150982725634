import Bracket from '../../../routes/tournaments/routes/torunament-bracket/Bracket';
import { Navigate, RouteObject } from 'react-router-dom';
import MyAccount from '../../../features/Settings/AdminSettings/MyAccount';
import NotificationSettings from '../../../features/Settings/AdminSettings/Notifications';
import SecuritySettings from '../../../features/Settings/AdminSettings/Security';
import Settings from '../../../features/Settings/AdminSettings/Settings';
import AllClassrooms from '../../../routes/classrooms/all-classrooms';
import FanDashboard from '../../../routes/fan-dashboard/fan-dashboard';
import GameArena from '../../../routes/gamearena/gamearena';
import GamearenaBrowse from '../../../routes/gamearena/routes/gamearena-browse/gamearena-browse';
import GameArenaLive from '../../../routes/gamearena/routes/gamearena-live/gamearena-live';
import GamearenaStreamerPage from '../../../routes/gamearena/routes/gamearena-streamer-page/gamearena-streamer-page';
import GaRecentVideoRoute from '../../../routes/gamearena/routes/gamearena-video/ga-recent-video-route';
import AllGames from '../../../routes/games/AllGames';
import GameOverview from '../../../routes/games/overview/GameOverview';
import ViewMatch from '../../../routes/match/view-match';
import { AllSchedule } from '../../../routes/schedule/AllSchedules';
import AllSeasons from '../../../routes/seasons/routes/all/all-seasons';
import { SeasonsByGame } from '../../../routes/seasons/routes/game/seasons-by-game';
import { Seasons } from '../../../routes/seasons/seasons';
import AllTournaments from '../../../routes/tournaments/routes/all/all-tournaments';
import TournamentsByDivision from '../../../routes/tournaments/routes/by-division/tournaments-by-division';
import TournamentsByGame from '../../../routes/tournaments/routes/by-game/tournaments-by-game';
import TournamentCategoryDivisions from '../../../routes/tournaments/routes/divisions/category-divisions';
import TournamentSubdivisions from '../../../routes/tournaments/routes/subdivisions/subdivisions';
import Tournaments from '../../../routes/tournaments/tournaments';
import ViewClassroom from '../../../routes/view-classroom/view-classroom';
import ViewSeason from '../../../routes/view-season/view-season';
import { TournamentMatches } from '../../../routes/view-tournament/tournament-matches';
import TournamentOverview from '../../../routes/view-tournament/tournament-overview';
import TournamentStandings from '../../../routes/view-tournament/tournament-standings';
import TournamentTeams from '../../../routes/view-tournament/tournament-teams';
import Tournament from '../../../routes/view-tournament/view-tournament';
import Dashboard from '../../Dashboard';
import AllNotifications from '../../../routes/notifications/all-notification';
import ClassroomsScheduleHours from '../../../routes/classrooms/route/classrooms-schedule-hours';
import StudentBookHours from '../../../routes/classrooms/route/student-book-hours';
import ViewLesson from '../../../routes/view-lesson/view-lesson';
import PreviewClassroom from '../../../routes/preview-classroom/preview-classroom';
import Support from '../../../routes/support/support';
import SuccessSettings from '../../../routes/settings/success-settings';
import SupportOtherRolesView from '../../../routes/support/support-other-roles';
import ViewMyTickets from '../../../routes/support/components/viewMyTickets';
import CreateNewTicket from '../../../routes/support/components/createNewTicket';
import TicketDetails from '../../../routes/support/components/ticket-details';
import ArchiveTicket from '../../../routes/support/components/archive-ticket';
import CategoryDivisions from '../../../routes/tournaments/routes/divisions/category-divisions';
import Subdivisions from '../../../routes/tournaments/routes/subdivisions/subdivisions';
import DeleteUser from '../../../routes/delete-user/delete-user';

export const fanProtectedRoutes: RouteObject[] = [
  {
    path: '',
    element: <Navigate to='./dashboard' />,
  },
  // {
  //   path: 'calendar',
  //   element: <Calendar />,
  // },
  // {
  //   path: 'subscriptions',
  //   element: <SubscriptionRedirect />,
  // },
  // {
  //   path: '/subscriptions/student/thank-you',
  //   element: <StudentSubscriptionThankYou />,
  // },
  // {
  //   path: 'subscriptions/student/pay/:id',
  //   element: <StudentSubsPayments />,
  // },
  // {
  //   path: '/subscriptions/student',
  //   element: <StudentSubscriptions />,
  //   children: [
  //     {
  //       path: 'manage/:id',
  //       element: <ManageStudentSubscription />,
  //     },
  //   ],
  // },
  // {
  //   path: '/subscriptions/ogma',
  //   element: <OgmaSchoolSubscriptions />,
  //   children: [
  //     {
  //       path: 'school/:id',
  //       element: <OgmaSubSchoolInfo />,
  //       children: [],
  //     },
  //     {
  //       path: 'school/:id/:invoice',
  //       element: <OgmaSubPayments />,
  //     },
  //   ],
  // },
  {
    path: 'dashboard',
    element: <Dashboard />,
  },
  {
    path: 'notifications',
    element: <AllNotifications />,
  },
  // {
  //   path: '/dashboard/student',
  //   element: <StudentDashboard />,
  // },
  // {
  //   path: '/dashboard/admin',
  //   element: <AdminDashboard />,
  // },
  // {
  //   path: '/dashboard/ogma/subscription-plans',
  //   element: <OgmaSubscriptionPlans />,
  //   children: [
  //     {
  //       path: 'school/:schoolid',
  //       element: <ChangeSchoolPlan />,
  //     },
  //   ],
  // },
  // {
  //   path: '/dashboard/ogma',
  //   element: <OGMADashboard />,
  //   children: [
  //     {
  //       path: 'invite-students',
  //       element: <InviteStudentsOgma />,
  //     },
  //     {
  //       path: 'coach-hours',
  //       element: <ManageCoachHoursOGMA />,
  //     },
  //   ],
  // },
  // {
  //   path: '/dashboard/coach',
  //   element: <CoachDashboard />,
  // },
  // {
  //   path: '/dashboard/guardian',
  //   element: <GuardianDashboard />,
  // },
  // {
  //   path: '/dashboard/guardian',
  //   element: <GuardianDashboard />,
  // },
  {
    path: '/dashboard/fan',
    element: <FanDashboard />,
  },
  // {
  //   path: 'classroom',
  //   element: <ViewClassroom />,
  // },
  {
    path: 'gamearena',
    element: <GameArena />,
    children: [
      // {
      //   path: 'connect',
      //   element: <ConnectTwitch />,
      // },
      {
        path: 'video/:vid',
        element: <GaRecentVideoRoute />,
      },
    ],
  },
  {
    path: 'gamearena/live/:id',
    element: <GameArenaLive />,
  },

  {
    path: 'gamearena/streamer/:id',
    element: <GamearenaStreamerPage />,
    children: [
      {
        path: 'video/:vid',
        element: <GaRecentVideoRoute />,
      },
    ],
  },
  {
    path: 'gamearena/browse',
    element: <GamearenaBrowse />,
  },
  {
    path: 'match/:id',
    element: <ViewMatch />,
    children: [
      // {
      //   path: 'submit',
      //   element: <SubmitMatchResults />,
      // },
      // {
      //   path: 'dispute',
      //   element: <DisputeMatch />,
      // },
      // {
      //   path: 'edit',
      //   element: <EditMatch />,
      // },
      // {
      //   path: 'edit-result',
      //   element: <EditMatchResults />,
      // },
      // {
      //   path: 'forfeit',
      //   element: <ForfeitMatch />,
      // },
      // {
      //   path: 'reschedule',
      //   element: <MatchDetailsReschedule />,
      // },
      // {
      //   path: 'view-reschedules',
      //   element: <ViewReschedules />,
      // },
      // {
      //   path: 'view-disputes',
      //   element: <ViewDisputes />,
      // },
      // {
      //   path: 'edit-game-results/:gid',
      //   element: <EditGameResults />,
      // },
      // {
      //   path: 'submit-game-results/:gid',
      //   element: <SubmitGameResult />,
      // },
    ],
  },
  // {
  //   path: 'coach',
  //   element: <CoachProfile />,
  // },
  {
    path: '/tournaments/by-game/:id',
    element: <TournamentsByGame />,
  },
  { path: '/seasons/by-game/:id', element: <SeasonsByGame /> },
  {
    path: '/tournaments/by-division/:id',
    element: <TournamentsByDivision />,
  },
  // {
  //   path: '/seasons/by-division/:id',
  //   element: <SeasonByDivision />,
  // },
  {
    path: 'seasons/view/:id',
    element: <ViewSeason />,
    children: [
      // {
      //   path: 'edit-teams',
      //   element: <EditSeasonTeams />,
      // },
      // {
      //   path: 'generate-matches/:weekid',
      //   element: <GenerateMatch />,
      // },
      // {
      //   path: 'new-week',
      //   element: <NewSeasonWeek />,
      // },
      // {
      //   path: 'edit-season',
      //   element: <EditSeason />,
      // },
    ],
  },
  {
    path: 'seasons',
    element: <Seasons />,
    children: [
      {
        path: 'all',
        element: <AllSeasons />,
        children: [
          // {
          //   path: 'new',
          //   element: <NewSeason />,
          // },
          // {
          //   path: 'edit/:id',
          //   element: <EditSeason />,
          // },
          // {
          //   path: 'archive/:id',
          //   element: <ArchiveSeason />,
          // },
        ],
      },
      // {
      //   path: 'division',
      //   element: <CategoryDivisions />,
      //   children: [
      //     // {
      //     //   path: 'new',
      //     //   element: <NewSeasonDivision />,
      //     // },
      //     // {
      //     //   path: 'edit/:id',
      //     //   element: <EditSeasonDivision />,
      //     // },
      //   ],
      // },
      // {
      //   path: 'subdivision',
      //   element: <Subdivisions />,
      //   children: [
      //     // {
      //     //   path: 'new',
      //     //   element: <NewSeasonSubdivision />,
      //     // },
      //   ],
      // },
    ],
  },
  // {
  //   path: 'invoices',
  //   element: <Invoices />,
  //   children: [
  //     {
  //       path: 'schools',
  //       element: <InvoiceOrganizations />,
  //       children: [
  //         {
  //           path: 'new',
  //           element: <NewInvoiceOrganization />,
  //         },
  //         {
  //           path: 'edit/:id',
  //           element: <EditSchoolInvoice />,
  //         },
  //       ],
  //     },
  //     {
  //       path: 'students',
  //       element: <InvoiceStudents />,
  //       children: [
  //         {
  //           path: 'new',
  //           element: <NewInvoiceStudent />,
  //         },
  //         {
  //           path: 'edit/:id',
  //           element: <EditStudentInvoice />,
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    path: 'classrooms',
    element: <AllClassrooms />,
    children: [
      {
        path: 'schedule-ogma/:id',
        element: <ClassroomsScheduleHours />,
      },
      {
        path: 'book-student/:id',
        element: <StudentBookHours />,
      },
      // {
      //   path: 'view/:id',
      //   element: <ViewClassroom />,
      // },
    ],
  },

  {
    path: 'classrooms/view/:id',
    element: <ViewClassroom />,
    children: [
      {
        path: 'l/:lessonid/content/:publicId',
        element: <ViewLesson />,
      },
    ],
  },
  {
    path: 'classrooms/preview/:id',
    element: <PreviewClassroom />,
  },
  {
    path: 'tournaments',
    element: <Tournaments />,
    children: [
      {
        path: 'all',
        element: <AllTournaments />,
        children: [
          // {
          //   path: 'new',
          //   element: <NewTournament />,
          // },
          // {
          //   path: 'edit/:id',
          //   element: <EditTournament />,
          // },
          // {
          //   path: 'archive/:id',
          //   element: <ArchiveTournament />,
          // },
          // {
          //   path: 'duplicate/:id',
          //   element: <DuplicateTournament />,
          // },
        ],
      },
      {
        path: 'divisions',
        element: <TournamentCategoryDivisions />,
        children: [
          // {
          //   path: 'new',
          //   element: <NewDivision />,
          // },
          // {
          //   path: 'edit/:id',
          //   element: <EditDivision />,
          // },
          // {
          //   path: 'archive/:id',
          //   element: <ArchiveDivision />,
          // },
        ],
      },

      {
        path: 'subdivisions',
        element: <TournamentSubdivisions />,
        children: [
          // {
          //   path: 'new',
          //   element: <NewSubDivisions />,
          // },
          // {
          //   path: 'edit/:id',
          //   element: <EditSubDivision />,
          // },
          // {
          //   path: 'archive/:id',
          //   element: <ArchiveSubDivision />,
          // },
        ],
      },
    ],
  },
  {
    path: 'tournaments/view/:id',
    element: <Tournament />,
    children: [
      // {
      //   path: 'edit-tournament',
      //   element: <EditTournament />,
      // },
      {
        path: '',
        element: <TournamentOverview />,
      },
      {
        path: 'bracket',
        element: <Bracket />,
        children: [
          // {
          //   path: 'generate-bracket',
          //   element: <GenerateBracket />,
          // },
          // {
          //   path: 'edit',
          //   element: <EditBracket />,
          // },
          // {
          //   path: 'edit-bracket/:bracketid',
          //   element: <EditSingleBracket />,
          // },
          // {
          //   path: "manual-bracket",
          //   element: <ManualBracket />,
          // }
        ],
      },

      {
        path: 'matches',
        element: <TournamentMatches />,
      },
      // {
      //   path: "add",
      //   element: <NewTeamTournament />
      // },
      {
        path: 'teams',
        element: <TournamentTeams />,
        children: [
          // {
          //   path: 'edit',
          //   element: <NewTeamTournament />,
          // },
        ],
      },
      {
        path: 'standings',
        element: <TournamentStandings />,
      },
    ],
  },
  {
    path: '/dashboard',
    element: <Dashboard />,
  },
  {
    path: '*',
    // element: <Dashboard />,
  },
  // {
  //   path: 'Users',
  //   element: <Users />,
  //   children: [
  //     {
  //       path: 'new',
  //       element: <Modal />,
  //     },
  //     {
  //       path: 'edit/:id',
  //       element: <EditUser />,
  //     },
  //     {
  //       path: 'archive/:id',
  //       element: <ArchiveUser />,
  //     },
  //   ],
  // },
  // {
  //   path: 'teams/view/:id',
  //   element: <ViewTeam />,
  //   children: [
  //     {
  //       path: '',
  //       element: <TeamOverview />,
  //     },
  //     {
  //       path: 'members',
  //       element: <TeamMembers />,
  //       children: [
  //         {
  //           path: 'add',
  //           element: <AddStudent />,
  //         },
  //       ],
  //     },
  //     {
  //       path: 'stats',
  //       element: <TeamViewStats />,
  //     },
  //     {
  //       path: 'team-schedule',
  //       element: <TeamSchedule />,
  //     },
  //   ],
  // },
  // {
  //   path: 'users/view/:id',
  //   children: [
  //     {
  //       path: 'ogma',
  //       element: <OgmaProfile />,
  //       children: [
  //         {
  //           path: 'invoices',
  //           element: <OgmaProfileInvoices />,
  //         },
  //         {
  //           path: 'schools',
  //           element: <OgmaProfileSchools />,
  //         },
  //       ],
  //     },
  //     {
  //       path: 'guardian',
  //       element: <ViewGuardian />,
  //       children: [
  //         {
  //           path: 'overview',
  //           element: <GuardianOverview />,
  //         },
  //         {
  //           path: 'students',
  //           element: <GuardianMyStudents />,
  //           children: [
  //             {
  //               path: 'new',
  //               element: <AddMyStudent />,
  //             },
  //             {
  //               path: 'manage/:subscriptionId',
  //               element: <ManageStudentSubscription />,
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       path: 'student',
  //       element: <ViewUser />,
  //       children: [
  //         {
  //           path: 'overview',
  //           element: <UserOverview />,
  //         },
  //         {
  //           path: 'stats',
  //           element: <UserStats />,
  //         },
  //       ],
  //     },
  //     {
  //       path: 'coach',
  //       element: <ViewCoach />,
  //       children: [
  //         {
  //           path: 'overview',
  //           element: <CoachOverview />,
  //         },
  //         {
  //           path: 'classrooms',
  //           element: <Classrooms />,
  //           // children: [
  //           //   {
  //           //     path: 'new',
  //           //     element: <NewClassroom />,
  //           //   },
  //           // ],
  //         },
  //         {
  //           path: 'training',
  //           element: <TrainingContent />,
  //           children: [
  //             {
  //               path: 'new',
  //               element: <NewClassroom />,
  //             },
  //             {
  //               path: 'edit/:cId',
  //               element: <EditClassroom />,
  //             },
  //             {
  //               path: 'archive/:cId',
  //               element: <ArchiveClassroom />,
  //             },
  //           ],
  //         },
  //         {
  //           path: 'training/lesson/:cId',
  //           element: <Lessons />,
  //           children: [
  //             {
  //               path: 'new',
  //               element: <NewLesson />,
  //             },
  //             {
  //               path: 'edit/:lId',
  //               element: <EditLesson />,
  //             },
  //             {
  //               path: 'delete/:lId',
  //               element: <DeleteLesson />,
  //             },
  //             {
  //               path: 'content/:lId',
  //               element: <AddLessonContent />,
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   path: 'coaches/view/:id',
  //   element: <ViewCoach />,
  //   children: [
  //     {
  //       path: 'overview',
  //       element: <CoachOverview />,
  //     },
  //     {
  //       path: 'classrooms',
  //       element: <Classrooms />,
  //       children: [
  //         {
  //           path: 'new',
  //           element: <NewClassroom />,
  //         },
  //       ],
  //     },
  //     {
  //       path: 'training',
  //       element: <TrainingContent />,
  //       children: [
  //         {
  //           path: 'new',
  //           element: <NewClassroom />,
  //         },
  //         {
  //           path: 'edit/:cId',
  //           element: <EditClassroom />,
  //         },
  //         {
  //           path: 'archive/:cId',
  //           element: <ArchiveClassroom />,
  //         },
  //       ],
  //     },
  //     // {
  //     //   path: 'training/new',
  //     //   element: <NewClassroom />,
  //     // },
  //     // {
  //     //   path: 'training/edit/:cId',
  //     //   element: <EditClassroom />,
  //     // },
  //     {
  //       path: 'training/lesson/:cId',
  //       element: <Lessons />,
  //       children: [
  //         {
  //           path: 'new',
  //           element: <NewLesson />,
  //         },
  //         {
  //           path: 'edit/:lId',
  //           element: <EditLesson />,
  //         },
  //         {
  //           path: 'delete/:lId',
  //           element: <DeleteLesson />,
  //         },
  //         {
  //           path: 'content/:lId',
  //           element: <AddLessonContent />,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   path: 'organization',
  //   element: <ViewOrganization />,
  //   children: [
  //     {
  //       path: '',
  //       element: <OrganizationOverview />,
  //     },
  //     {
  //       path: 'schools',
  //       element: <OrganizationSchools />,
  //     },
  //     {
  //       path: 'teams',
  //       element: <OrganizationTeams />,
  //     },
  //     {
  //       path: 'coaches',
  //       element: <OrganizationCoaches />,
  //       children: [
  //         {
  //           path: 'add',
  //           element: <OrganizationAddCoaches />,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   path: 'organizations/view/:id',
  //   element: <ViewOrganization />,
  //   children: [
  //     {
  //       path: '',
  //       element: <OrganizationOverview />,
  //     },
  //     {
  //       path: 'schools',
  //       element: <OrganizationSchools />,
  //     },
  //     {
  //       path: 'teams',
  //       element: <OrganizationTeams />,
  //     },
  //     {
  //       path: 'coaches',
  //       element: <OrganizationCoaches />,
  //       children: [
  //         {
  //           path: 'add',
  //           element: <OrganizationAddCoaches />,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   path: 'Teams',
  //   element: <Teams />,
  //   children: [
  //     {
  //       path: 'new',
  //       element: <NewTeamModal />,
  //     },
  //     {
  //       path: 'edit/:id',
  //       element: <UpdateTeamModal />,
  //     },
  //     {
  //       path: 'archive/:id',
  //       element: <ArchiveTeam />,
  //     },
  //     {
  //       path: 'view/:id',
  //       element: <ViewTeam />,
  //     },
  //   ],
  // },
  // {
  //   path: 'schools/view/:id',
  //   element: <ViewSchool />,
  //   children: [
  //     {
  //       path: '',
  //       element: <SchoolOverview />,
  //     },
  //     {
  //       path: 'students',
  //       element: <SchoolStudents />,
  //     },
  //     {
  //       path: 'teams',
  //       element: <SchoolTeams />,
  //     },
  //   ],
  // },
  // {
  //   path: 'coaches',
  //   element: <ViewCoaches />,
  //   children: [
  //     {
  //       path: 'new',
  //       element: <NewCoachModal />,
  //     },
  //     {
  //       path: 'edit/:id',
  //       element: <UpdateCoachModal />,
  //     },
  //     {
  //       path: 'archive/:id',
  //       element: <ArchiveCoachModal />,
  //     },
  //   ],
  //   // children: [
  //   //   {
  //   //     path: "overview",
  //   //     element: <SchoolOverview />,
  //   //   },
  //   //   {
  //   //     path: "students",
  //   //     element: <SchoolStudents />,
  //   //   },
  //   //   {
  //   //     path: "teams",
  //   //     element: <SchoolTeams />,
  //   //   },
  //   // ],
  // },
  // {
  //   path: 'sponsors',
  //   element: <Sponsors />,
  //   children: [
  //     {
  //       path: 'new',
  //       element: <NewSponsor />,
  //     },
  //     {
  //       path: 'edit/:id',
  //       element: <EditSponsor />,
  //     },
  //     {
  //       path: 'archive/:id',
  //       element: <ArchiveSponsor />,
  //     },
  //   ],
  // },
  // {
  //   path: '/schools',
  //   element: <Schools />,
  //   children: [
  //     {
  //       path: 'new',
  //       element: <NewSchoolModal />,
  //     },
  //     {
  //       path: 'edit/:id',
  //       element: <UpdateSchoolModal />,
  //     },
  //     {
  //       path: 'archive/:id',
  //       element: <ArchiveSchool />,
  //     },
  //   ],
  // },
  // {
  //   path: '/organizations',
  //   element: <Organizations />,
  //   children: [
  //     {
  //       path: 'new',
  //       element: <NewOrganizationModal />,
  //     },
  //     {
  //       path: 'edit/:id',
  //       element: <UpdateOrganizationModal />,
  //     },
  //     {
  //       path: 'archive/:id',
  //       element: <ArchiveOrg />,
  //     },
  //   ],
  // },
  {
    path: '/schedule',
    element: <AllSchedule />,
  },

  {
    path: '/games',
    element: <AllGames />,
    children: [
      {
        path: '',
        element: <GameOverview />,
        children: [
          //   {
          //     path: 'add',
          //     element: <AddGameAccount />,
          //     children: [
          //       {
          //         path: 'manual',
          //         element: <GameManual />,
          //       },
          //       {
          //         path: 'api',
          //         element: <GameAPI />,
          //       },
          //     ],
          //   },
        ],
      },
      // {
      //   path: 'teams',
      //   element: <GameAllTeams />,
      //   children: [
      //     {
      //       path: 'new',
      //       element: <NewTeamModal />,
      //     },
      //   ],
      // },
    ],
  },
  {
    path: '/support',
    element: <Support />,
    children: [
      {
        path: '',
        element: <SupportOtherRolesView />,
      },
      {
        path: 'viewMytickets',
        element: <ViewMyTickets />,
      },
      {
        path: 'create-new-ticket',
        element: <CreateNewTicket />,
      },
      {
        path: 'viewMyticket/:id',
        element: <TicketDetails />,
        children: [
          {
            path: 'archive',
            element: <ArchiveTicket />,
          },
        ],
      },
    ],
  },
  {
    path: '/Settings',
    element: <Settings />,

    children: [
      {
        path: '',
        element: <MyAccount />,
      },
      {
        path: 'success',
        element: <SuccessSettings />,
      },

      {
        path: 'security/',
        element: <SecuritySettings />,
      },
      {
        path: 'notification/',
        element: <NotificationSettings />,
      },
      {
        path: 'delete/:id',
        element: <DeleteUser />,
      },
    ],
  },
];
