import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { FormInput } from '../../../../components/form-input/form-input';
import { OutsideClick } from '../../../../components/outside-click';
import { SubmitButton } from '../../../../components/submit-button';
import { setUserTwitchUsername } from '../../../../slices/auth';
import { useTestTwitchUserMutation } from '../../../../slices/twitchApiSlice';
import { useUpdateTwitchUsernameMutation } from '../../../../slices/userApiSlice';

const ConnectTwitch = () => {
  const dispatch = useDispatch();
  const [testTwitchUser, { data: twData, isLoading, isSuccess, isError }] =
    useTestTwitchUserMutation();
  const [updateTwitchUsername, { data: upData, isLoading: upIsLoading, isSuccess: upIsSuccess }] =
    useUpdateTwitchUsernameMutation();
  const [usError, setUsError] = useState('');
  function handleSubmit(values: any) {
    setUsError('');
    testTwitchUser(values.username)
      .unwrap()
      .then((res) => {
        console.log('test res', res);
        !res.data.length
          ? setUsError('Please provide a valid username!')
          : updateTwitchUsername({ id: res.data[0].id, login: values.username })
              .unwrap()
              .then((r) => {
                dispatch(
                  setUserTwitchUsername({
                    id: r.twitch_id,
                    login: r.twitch_login,
                  }),
                );
              });
      });
  }
  if (upIsSuccess) return <Navigate to='../' />;
  return (
    <>
      <OutsideClick />
      <Formik initialValues={{}} onSubmit={handleSubmit}>
        {(formik) => {
          return (
            <Form>
              <div className='fixed pb-20 top-0 left-0 right-0 z-10 w-auto my-6 mx-auto max-w-3xl max-h-[100vh] '>
                {/*content*/}
                <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-full overflow-auto p-2'>
                  {/*header*/}
                  <div className='flex items-start justify-between mb-2 p-5 border-b border-solid border-slate-200 rounded-t sticky top-0 z-[1] bg-white'>
                    <h3 className='text-3xl font-semibold'>Connect to Twitch</h3>
                  </div>
                  <FormInput
                    label='Username'
                    placeholder='Write your twitch username'
                    name='username'
                  />
                  <p className='text-red-600'>{usError}</p>
                  <div className='flex items-center justify-end p-2  border-solid border-slate-200 rounded-b'>
                    {/* <button onClick={()=> setShowModal(false)} className="w-1/5 my-12  py-1 bg-white text-black shadow-lg font-semibold rounded-lg  ">
                        Cancel
                      </button> */}
                    <Link
                      className='w-1/5  text-center py-1 bg-white text-black shadow-lg font-semibold rounded-lg'
                      to='../'
                    >
                      Cancel
                    </Link>

                    <SubmitButton isLoading={isLoading || upIsLoading} />
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default ConnectTwitch;
