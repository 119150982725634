export class NewStudentInvoiceModel {
  students?: any[];
  constructor(d: any) {
    if (d.length) {
      this.students = [];
      d?.forEach((s: any) =>
        this.students?.push({
          id: s.id,
          name: s.firstName ? `${s.firstName} ${s.lastName}` : ' - ',
        }),
      );
    }
  }
}
