export function GamesLoadingSceleton() {
  return (
  <div className="w-full h-full border-2 rounded-xl flex flex-col p-1">
      <div className="flex flex-wrap p-6 justify-between">
        <div className="h-[50px] w-[200px] bg-slate-200 rounded-lg"></div>
      </div>
    <div>
        <hr />
        <div className="flex flex-wrap p-6 justify-between">
           <div className="h-[50px] w-[200px] bg-white rounded-lg"></div>
           <div className="flex flex-wrap gap-5">
           <div className="h-[50px] w-[200px] bg-slate-200 rounded-lg"></div>
           <div className="h-[50px] w-[200px] bg-slate-200 rounded-lg"></div>
           </div>
        </div>
    </div>

      <hr />
     
    <div className="flex flex-wrap p-6 justify-between"> 
          <div className="h-[50px] w-[250px] bg-white rounded-lg"></div>
         <div className="flex flex-wrap gap-5">
          <div className="h-[50px] w-[250px] bg-slate-200 rounded-lg"></div>
          <div className="h-[50px] w-[250px] bg-slate-200 rounded-lg"></div>
          </div> 
    </div>
    <div>
      <hr/>
     <div className='flex items-center align-middle -mt-6 ml-4 grid-cols-4 gap-3 skeleton__pulse p-6'>
        <div className='w-[250px] min-h-[250px]  items-center rounded-lg shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] bg-slate-400 mt-5 '></div>
        <div className='w-[250px] min-h-[250px]  items-center rounded-lg shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] bg-slate-400 mt-5 '></div>
        <div className='w-[250px] min-h-[250px]  items-center rounded-lg shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] bg-slate-400 mt-5 '></div>
        <div className='w-[250px] min-h-[250px]  items-center rounded-lg shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] bg-slate-400 mt-5 '></div>
      </div>
    </div>
    
  </div>
  );
}
