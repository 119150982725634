import * as yup from 'yup';
import { SchemaMessages } from '../../../../../utils/constants';
import { checkSeasonWeekEndDate, checkStartDate } from '../../../../../utils/utils';

export const NewWeekVSchema = (isLeaderboard: any) => {
  return yup.object().shape({
    name: yup.string().required('Required!'),
    start_time: yup
      .mixed()
      .transform((value, originalValue) => originalValue)
      .test('test', SchemaMessages.SelectedDateMusBeFromToday, checkStartDate)
      .required('Required!'),
    end_time: yup.mixed().transform((v, ov) => ov),
    // .test('test', SchemaMessages.EndDate, (v, ctx) => checkSeasonWeekEndDate(v, ctx, 'start_time')),
    format_id: !Number(isLeaderboard)
      ? yup.string().required('Required!')
      : yup.string().nullable(),
  });
};
