import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import { logOut, refreshTokens } from '../slices/auth';
import { RootState } from '../store';

const baseUrl = import.meta.env.VITE_API_URL;
const mutex = new Mutex();
const baseQuery = fetchBaseQuery({
  baseUrl: baseUrl,
  mode: 'cors',
  prepareHeaders: (headers, { getState }) => {
    const state: any = getState();
    const token = state.auth.token;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    headers.set('Cache-Control', 'no-cache,no-store');
    return headers;
  },
});

const refreshBaseQuery = fetchBaseQuery({
  baseUrl: baseUrl,
  mode: 'cors',
  prepareHeaders: (headers, { getState }) => {
    const state = <RootState>getState();
    const token = state.auth.refreshToken;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    headers.set('Cache-Control', 'no-cache,no-store');
    return headers;
  },
});

const customFetchBase: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (result.error?.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        console.log('refresh started');
        const refreshResult = await refreshBaseQuery({ url: 'auth/refresh' }, api, extraOptions);

        if (refreshResult.data) {
          // Retry the initial query
          api.dispatch(refreshTokens(refreshResult.data));
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(apiSlice.util.resetApiState());
          api.dispatch(logOut());
          // window.location.href = "/login";
        }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }

  return result;
};

export const apiSlice = createApi({
  baseQuery: customFetchBase,
  endpoints: (builder) => ({}),
  tagTypes: [
    'SponsorPlacements',
    'ShortInfo',
    'SupportTickets',
    'UnseenNotifications',
    'SchoolHrRequests',
    'SchoolHours',
    'OrgCoaches',
    'SchoolEnCoach',
    'SchoolInvoices',
    'SchoolPlan',
    'OgmaSchools',
    'SingleInvoice',
    'StudentInvoice',
    'OgmaInvoice',
    'SeasonStanding',
    'SingleSeasonCategory',
    'Seasons',
    'SingleSeasonInfo',
    'SingleMatchInfo',
    'TournamentStanding',
    'SingleSponsor',
    'Sponsor',
    'User',
    'SingleUser',
    'Organization',
    'School',
    'SingleSchool',
    'Team',
    'SingleTeam',
    'Game',
    'UserWithoutTeam',
    'SchoolTeams',
    'SchoolStudent',
    'latestSchools',
    'latestStudents',
    'CategoryDivisions',
    'Subdivisions',
    'SingleTournament',
    'Tournaments',
    'Division',
    'Match',
    'TournamentTeams',
    'FavoriteGames',
    'Reschedules',
    'Invoices',
    'Classroom',
    'Lesson',
    'Notification',
    'Support',
    'SupportTicket',
    'SupportCategory',
    'SupportFaq',
    'SupportJira',
    'SupportSubCategory',
    'InGameName',
    'Unarchived',
    'MatchFormats',
    'TermsAndServices',
    'Screenshot',
    'OrgOgmas',
    'MatchFormatsTypes',
    'TicketComment',
    'ApproveTicket',
    'DisconnectChildTicket',
    'ApprovalStatus',
    'ArchiveCategory',
    'UnArchiveCategory',
    'ArchiveSubcategory',
    'UnArchiveSubcategory',
    'SeasonWeek',
    'SeasonStage',
    'ArchiveStudent',
    'RescheduleHistory',
    'DiscordName',
    'MatchChatDispute',
  ],
});
