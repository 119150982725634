import React from 'react';
import { Link, NavLink, Outlet } from 'react-router-dom';
export function TournamentHeaderButton({ text = '', bool = false, link = '', color = '' }) {
  return bool ? (
    <>
      <Link
        className='text-center justify-center rounded-lg bg-gray-700 text-white uppercase p-2 px-3 text-sm cursor-pointer'
        to={link}
      >
        {/* <div className="text-center rounded-lg bg-slate-700 text-white uppercase p-2 px-3 text-sm cursor-pointer"> */}
        {text}
        {/* </div> */}
      </Link>
    </>
  ) : (
    <div className={`text-center rounded-lg bg-gray-700 text-white uppercase p-2 px-3 text-sm cursor-pointer ${color}`}>
      {text}
    </div>
  );
}
