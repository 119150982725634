import { useState } from 'react';
import { DeletedUserModel } from '../../model/deleted-user.model';
import TableContainer from '../../components/table-container/table-container';
import { TableHead } from '../../components/table-head';
import { Separator } from '../../components/ui/separator/Separator';
import React from 'react';
import TableTd from '../../components/table-td/table-td';
import Pagination from '../../components/pagination';
import { useSearchParams } from 'react-router-dom';
import { useGetDeletedUsersQuery } from '../../slices/userApiSlice';

const DeletedUsers = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, error, isLoading } = useGetDeletedUsersQuery({
    page: searchParams.get('page') ? +searchParams.get('page')! : 1,
  });
  const configureListView = [
    {
      name: 'First Name',
      active: true,
      property: 'firstName',
    },
    {
      name: 'Last Name',
      active: true,
      property: 'lastName',
    },
    {
      name: 'Date',
      active: true,
      property: 'formatedDeletedOnDate',
    },
  ];
  const [listView, setListView] = useState(configureListView);
  if (isLoading) return <h1>Data loading</h1>;
  return (
    <>
      <div className='listViewContainer fullPage rounded-xl border-1'>
        <div className='flex items-center justify-between'>
          <div className='space-y-1'>
            <h2 className='text-2xl font-semibold tracking-tight'>Deleted Users</h2>
          </div>
        </div>
        <Separator />
        <div className='flex flex-col flex-1 justify-between'>
          <TableContainer>
            <TableHead tableList={listView.map((l) => l.name)} />
            <tbody className='bg-white divide-y divide-gray-200'>
              {data &&
                data.data?.map((person: any, i: number) => {
                  const model: { [key: string]: any } & DeletedUserModel = new DeletedUserModel(
                    person,
                  );
                  return (
                    <React.Fragment key={i}>
                      <tr key={i}>
                        {listView.map((p) => {
                          return <TableTd key={p.property}>{model[p.property]}</TableTd>;
                        })}
                      </tr>
                    </React.Fragment>
                  );
                })}
            </tbody>
          </TableContainer>
          <Pagination length={data?.data?.length} {...data?.meta} />
        </div>
      </div>
    </>
  );
};

export default DeletedUsers;
