import { useEffect, useState } from 'react';
import ModalContent from '../../../components/modal-content';
import { OutsideClick } from '../../../components/outside-click';
import { Formik, FormikValues } from 'formik';
import { Navigate, useParams } from 'react-router';
import { useGetGameByIdQuery, useUpdateGameMutation } from '../../../slices/gameApiSlice';
import { CustomError } from '../../../utils/custom-types';
import { GameModel } from '../../../model/game.model';
import { EditGameValidationSchema } from '../../../features/authentication/yupschema';

const EditGame = () => {
  const formInputs = [
    {
      mainTitle: 'Game Information',
      type: 'text',
      label: 'Game name',
      name: 'name',
      placeholder: 'Enter game name',
    },
  ];
  const param = useParams();

  const [
    updateGame,
    {
      data: upData,
      isLoading: upIsLoading,
      isSuccess: upIsSuccess,
      isError: upIsError,
      error: upError,
    },
  ] = useUpdateGameMutation();
  const { data, isLoading, isSuccess, isError, error } = useGetGameByIdQuery(param.id ?? '');
  const [model, setModel] = useState<GameModel>();
  useEffect(() => {
    if (!data) return;
    setModel(new GameModel(data));
  }, [data]);
  function handleUpdate(values: FormikValues) {
    updateGame({ id: param.id ?? '', name: values.name });
  }
  if (upIsSuccess) return <Navigate to='../' />;

  if (isError)
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError)?.data?.message }} />;
  if (isLoading) return <p>Loading...</p>;
  return (
    <>
      <OutsideClick />
      <Formik
        initialValues={{ name: model?.name }}
        validationSchema={EditGameValidationSchema}
        enableReinitialize
        onSubmit={handleUpdate}
      >
        {({ isSubmitting, values, errors }) => {
          return (
            <ModalContent
              name={`Edit Game`}
              formInputs={formInputs}
              isLoading={upIsLoading}
              extraClass='h-fit'
              errors={Object.keys(errors).length}
            />
          );
        }}
      </Formik>
    </>
  );
};

export default EditGame;
