export class OrganizationCoachModel {
  id: number = 0;
  firstName: string = '';
  lastName: string = '';
  image: string = '';
  fullName: string = '';
  org_list: number[] = [];
  username?: string;
  yearsOfExperience?: string | number;
  tags?: string[];
  email?: string;
  classroom_count?: number;
  constructor(data: any) {
    if (data.hasOwnProperty('id')) this.id = data.id;
    if (data.hasOwnProperty('_count')) {
      if (data._count.hasOwnProperty('classrooms')) this.classroom_count = data._count.classrooms;
    }
    if (data.hasOwnProperty('tags')) this.tags = data?.tags?.split(',');
    if (data.hasOwnProperty('email')) this.email = data.email;
    if (data.hasOwnProperty('yearsOfExperience')) this.yearsOfExperience = data.yearsOfExperience;
    if (data.hasOwnProperty('username')) this.username = data.username;
    if (data.hasOwnProperty('firstName')) this.firstName = data.firstName;
    if (data.hasOwnProperty('lastName')) this.lastName = data.lastName;
    if (data.hasOwnProperty('image')) this.image = data.image;
    if (data.hasOwnProperty('CoachOrganization')) {
      data.CoachOrganization.forEach((cg: { organization_id: number }) =>
        this.org_list.push(cg.organization_id),
      );
    }
    if (data.hasOwnProperty('user')) {
      if (data.user.hasOwnProperty('id')) this.id = data.user.id;
      if (data.user.hasOwnProperty('firstName')) this.firstName = data.user.firstName;
      if (data.user.hasOwnProperty('lastName')) this.lastName = data.user.lastName;
      if (data.user.hasOwnProperty('image')) this.image = data.user.image;
    }
    if (this.firstName.length && this.lastName.length) {
      this.fullName = `${this.firstName} ${this.lastName}`;
    }
  }
}
