import React from 'react';
import { NavLink } from 'react-router-dom';
import { AvatarImage } from '../../components/avatar-image';
import { CoachProfileClassroomCard } from './components/coach-profile-classroom-card';

import coachImage from '../../assets/coach.jpg';
import { CoachProfileIntro } from './components/coach-profile-intro';

function CoachProfile() {
  const links = [
    {
      name: 'Overview',
      link: '',
    },
    {
      name: 'Classrooms',
      link: './classrooms',
    },
  ];

  return (
    <div className='p-5 w-full gap-5 flex flex-col bg-white rounded-xl'>
      <div className='flex items-center gap-5  w-full min-h-[100px] bg-white'>
        <AvatarImage size='xxl' source={coachImage} />

        <div className='flex justify-center flex-col gap-[1px]'>
          <h2 className='font-bold'>CoachName</h2>
          <p className='text-xs'>Member since July 2, 2022</p>
        </div>
      </div>
      <div className='flex gap-2 w-full'>
        {links.map((l) => (
          <NavLink key={l.name} to={l.link} className='settingsLink'>
            {l.name}
          </NavLink>
        ))}
      </div>
      <div className='flex relative w-full flex-wrap '>
        <div className='flex flex-col p-10 flex-1 gap-10'>
          <CoachProfileIntro />{' '}
          <div className='flex justify-between w-full text-center '>
            <CoachProfileClassroomCard classroom='Classroom 1' coach='Coach Name' />

            <CoachProfileClassroomCard classroom='Classroom 2' coach='Coach Name' />
          </div>
        </div>
        <div className='flex flex-col gap-5 min-w-[30%]'>
          <h2 className='font-bold'>About</h2>
        </div>
      </div>
    </div>
  );
}

export default CoachProfile;
