import { UserRoles } from '../../../utils/constants';

export const settingsPermissions: { [key: string]: number[] } = {
  'settings.gradeLevel': [UserRoles.Student],
  'settings.streetAddress': [UserRoles.Admin, UserRoles.Ogma, UserRoles.Coach, UserRoles.Guardian],
  'settings.dateOfBirth': [UserRoles.Admin, UserRoles.Ogma, UserRoles.Coach, UserRoles.Guardian],
  'settings.phoneNumber': [UserRoles.Admin, UserRoles.Ogma, UserRoles.Coach, UserRoles.Guardian],
  'settings.gender': [
    UserRoles.Admin,
    UserRoles.Coach,
    UserRoles.Guardian,
    UserRoles.Ogma,
    UserRoles.Student,
  ],
  'settings.officeNumber': [UserRoles.Ogma],
  'settings.usernames': [UserRoles.Student, UserRoles.Ogma, UserRoles.Coach]
};
