import { MouseEventHandler, ReactNode, useEffect } from 'react';
import { AiOutlinePlus, AiOutlineUnorderedList, AiOutlineUsergroupAdd } from 'react-icons/ai';
import { BsFillGearFill, BsGrid } from 'react-icons/bs';
import { FiMoreHorizontal, FiUpload } from 'react-icons/fi';
import { GrFavorite } from 'react-icons/gr';
import { MdAddCircleOutline } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { DropdownListView } from '../features/listview/users/components/dropdown-list-view';
import { Button } from './ui/button/Button';
import { TbReplace } from 'react-icons/tb';

export function HeaderButton({
  type,
  name,
  func,
  view,
  listView,
  setListView,
}: {
  type: string;
  name?: string;
  func?: MouseEventHandler;
  view?: boolean;
  listView?: any;
  setListView?: any;
}) {
  useEffect(() => {
    console.log(view);
  }, [view]);
  const buttons: { [key: string]: any } = {
    sponsorPlacement: {
      icon: <TbReplace size={20} />,
      text: 'Manage placement',
      customCss: 'uppercase !text-white main-gr-bg-color',
      func: func ?? null,
    },
    export: {
      icon: <FiUpload />,
      text: name ?? 'Export List',
      customCss: view ? '' : 'hidden',
      func: func ?? null,
    },
    configureListView: {
      icon: <BsFillGearFill />,
      text: 'Configure List View',
      customCss: view ? 'uppercase text-sm	' : 'hidden',
      listView: listView ?? null,
      setListView: setListView ?? null,
    },
    newClassroom: {
      icon: <AiOutlinePlus size={20} className='w-4 h-4' />,
      text: 'New Classroom',
      customCss: 'uppercase',
    },
    newLesson: {
      icon: <AiOutlinePlus size={20} className='w-4 h-4' />,
      text: 'New Lesson',
      customCss: 'uppercase',
    },
    newCategoryDivision: {
      icon: <MdAddCircleOutline size={20} color='white' />,
      text: 'New Category Division',
      customCss: 'uppercase !text-white main-gr-bg-color',
    },
    newSubdivision: {
      icon: <MdAddCircleOutline size={20} color='white' />,
      text: 'New Subdivision',
      customCss: 'uppercase !text-white main-gr-bg-color',
    },
    newTournament: {
      icon: <MdAddCircleOutline size={20} color='white' />,
      text: 'New Tournament',
      customCss: 'uppercase !text-white main-gr-bg-color',
    },
    newTeam: {
      icon: <MdAddCircleOutline size={20} color='white' />,
      text: 'New Team',
      customCss: 'uppercase !text-white main-gr-bg-color',
    },
    newSeason: {
      icon: <MdAddCircleOutline size={20} color='white' />,
      text: 'New Season',
      customCss: 'uppercase !text-white main-gr-bg-color',
    },
    newInvoice: {
      icon: <MdAddCircleOutline size={20} color='white' />,
      text: 'New Invoice',
      customCss: 'uppercase !text-white main-gr-bg-color',
    },
    newSchool: {
      icon: <MdAddCircleOutline size={20} color='white' />,
      text: 'New School',
      customCss: 'uppercase !text-white main-gr-bg-color',
    },
    newUser: {
      icon: <MdAddCircleOutline size={20} color='white' />,
      text: 'New User',
      customCss: 'uppercase !text-white main-gr-bg-color',
    },
    newOrganization: {
      icon: <MdAddCircleOutline size={20} color='white' />,
      text: 'New Organization',
      customCss: 'uppercase !text-white main-gr-bg-color',
    },
    newSponsor: {
      icon: <MdAddCircleOutline size={20} color='white' />,
      text: 'New Sponsor',
      customCss: 'uppercase !text-white main-gr-bg-color',
    },
    newCoach: {
      icon: <MdAddCircleOutline size={20} color='white' />,
      text: 'New Coach',
      customCss: 'uppercase !text-white main-gr-bg-color',
    },
    CreateNewRequest: {
      icon: <MdAddCircleOutline size={20} color='white' />,
      text: 'New Invoice',
      customCss: 'uppercase !text-white main-gr-bg-color',
    },
    listView: {
      icon: <AiOutlineUnorderedList color={view ? 'red' : 'black'} />,
      customCss: 'border-none !min-w-fit !p-0 hover:!bg-transparent  !shadow-none',
      func: func ?? null,
    },
    gridView: {
      icon: <BsGrid color={!view ? 'red' : 'black'} />,
      customCss: 'border-none !min-w-fit !p-0 hover:!bg-transparent  !shadow-none',
      func: func ?? null,
    },
    filter: {
      // icon: <RiListSettingsLine />,
      text: 'Clear Filter',
      func: func ?? null,
    },
    editTeams: {
      text: 'Edit Teams',
    },
    showFavorites: {
      text: 'Show Favorite Games',
      icon: <GrFavorite />,
    },
    showAll: {
      text: 'Show All Games',
      icon: <FiMoreHorizontal />,
    },
    createTeam: {
      text: 'Create Team',
      icon: <AiOutlineUsergroupAdd />,
    },
    generateBracket: {
      icon: <MdAddCircleOutline size={20} color='white' />,
      text: 'Generate Bracket',
      customCss: 'uppercase !text-white main-gr-bg-color',
    },
    addGame: {
      text: 'Add Game',
      customCss: 'uppercase !text-white !bg-red-700 p-4 px-8',
    },
    addAccount: {
      text: 'Add Account',
      customCss: '!text-white !bg-red-700',
    },
    connectWithRiot: {
      text: 'Connect with Riot',
      customCss: '!text-white !bg-red-700',
    },
    matchSchedule: {
      text: 'Match Schedule',
      icon: <AiOutlinePlus size={20} color='black' />,
    },
    editSeasonWeek: {
      text: 'Edit Weeek',
      // icon: <AiOutlinePlus size={20} color='black' />,
    },
    deleteSeasonWeek: {
      text: 'Delete Week',
    },
    addStudentToGuardian: {
      text: 'Add Student',
      icon: <AiOutlinePlus size={20} color='black' />,
    },
    newCategorySupport: {
      icon: <MdAddCircleOutline size={20} color='white' />,
      text: 'New Category',
      customCss: 'uppercase !text-white main-gr-bg-color',
    },
    editCategorySupport: {
      text: 'Edit Category',
      customCss: 'p-2',
    },
    newSubCategorySupport: {
      icon: <MdAddCircleOutline size={20} color='white' />,
      text: 'New Subcategory',
      customCss: 'uppercase !text-white main-gr-bg-color',
    },
    newGame: {
      icon: <MdAddCircleOutline size={20} color='white' />,
      text: 'New Game',
      customCss: 'uppercase !text-white main-gr-bg-color',
    },
    newMatchFormat: {
      icon: <MdAddCircleOutline size={20} color='white' />,
      text: 'New Match Format',
      customCss: 'uppercase !text-white main-gr-bg-color',
    },
    newTicket: {
      icon: <MdAddCircleOutline size={20} color='white' />,
      text: 'New Ticket',
      customCss: 'uppercase !text-white main-gr-bg-color h-auto p-[0.5rem] w-[150px]',
    },
  };
  return type === 'sponsorPlacement' ? (
    <Link to='./placement' className={`filterBtn flex ${buttons[type].customCss}`}>
      {buttons[type].icon && <div className='self-center pr-3'>{buttons[type].icon}</div>}
      {buttons[type].text && buttons[type].text}
    </Link>
  ) : // <Link to='placement' className={`filterBtn flex ${buttons[type].customCss}`}>
  //   <ButtonHeader {...buttons[type]} />
  // </Link>
  // )
  type === 'newTournament' ||
    type === 'newCategoryDivision' ||
    type === 'newSubdivision' ||
    type === 'CreateNewRequest' ||
    type === 'newCoach' ||
    type === 'newUser' ||
    type === 'newSponsor' ||
    type === 'newSchool' ||
    type === 'newTeam' ||
    type === 'newOrganization' ||
    type === 'sponsorPlacement' ||
    type === 'newSeason' ||
    type === 'newGame' ||
    type === 'newClassroom' ||
    type === 'newLesson' ||
    type === 'newSubCategorySupport' ? (
    <Link to='./new' className={`filterBtn flex ${buttons[type].customCss}`}>
      {buttons[type].icon && <div className='self-center pr-3'>{buttons[type].icon}</div>}
      {buttons[type].text && buttons[type].text}
    </Link>
  ) : type === 'configureListView' ? (
    <ButtonHeader configureListView {...buttons[type]} />
  ) : type === 'newMatchFormat' ? (
    <Link to='./newMatchFormat' className={`filterBtn flex ${buttons[type].customCss}`}>
      {buttons[type].icon && <div className='self-center pr-3'>{buttons[type].icon}</div>}
      {buttons[type].text && buttons[type].text}
    </Link>
  ) : (
    <ButtonHeader {...buttons[type]} />
  );
}

const ButtonHeader = ({
  icon,
  text,
  customCss,
  func,
  configureListView,
  listView,
  setListView,
}: {
  icon: ReactNode;
  text: string;
  customCss: string;
  func?: MouseEventHandler;
  configureListView?: boolean;
  listView?: any;
  setListView?: any;
}) => {
  return configureListView ? (
    <div className={`group ${customCss}`}>
      <Button variant='outline' size='lg' onClick={func} className={`group filterBtn  flex `}>
        {icon && <div className='self-center pr-3'>{icon}</div>}
        {text && text}
      </Button>
      <div className={'relative z-10 hidden group-hover:block '}>
        {/* <div
          id='dropdown'
          className='absolute z-10 w-80 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
          role='menu'
          aria-orientation='vertical'
          aria-labelledby='menu-button'
        > */}
        <DropdownListView
          state={listView ? listView : []}
          configureListView={listView ? listView : []}
          setFunc={setListView}
        />
        {/* </div> */}
      </div>
    </div>
  ) : (
    <Button
      size='lg'
      variant='outline'
      onClick={func ? func : () => console.log('first')}
      className={`filterBtn  flex ${customCss}`}
    >
      {icon && <div className='self-center pr-3'>{icon}</div>}
      {text && text}
    </Button>
  );
};
