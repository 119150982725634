import { useEffect, useState } from 'react';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import { IoIosArrowForward } from 'react-icons/io';
import { Link } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import img from '../../../assets/garenasearch.svg';
import { AvatarImage } from '../../../components/avatar-image';
import { Search } from '../../../components/search/search';
import { useGetAllStreamersQuery } from '../../../slices/gamearenaApiSlice';

export const GamearenaSearch = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  // const [getAllStreamers, { data: stData, isLoading: stIsLoading, isSuccess: stIsSuccess }] =
  // useLazyGetAllStreamersQuery();
  const {
    data: stData,
    isLoading: stIsLoading,
    isSuccess: stIsSuccess,
  } = useGetAllStreamersQuery({
    page: searchParams.get('page'),
    name: searchParams.get('name') ? searchParams.get('name') : '',
  });
  function goPrevPage() {
    setSearchParams({ page: `${stData.meta.prev}` });
  }
  function goNextPage() {
    setSearchParams({ page: `${stData.meta.next}` });
  }

  const filterList = [
    {
      title: 'name',
      property: 'name',
    },
    // {
    //   title: 'Matches',
    //   property: 'Matches',
    // },
    // {
    //   title: 'Team',
    //   property: 'Team',
    // },
    // {
    //   title: 'Game',
    //   property: 'Game',
    // },
  ];
  const [searchQuery, setSearchQuery] = useState(filterList[0].title);
  const [search, setSearch] = useState({ by: 'name', keyword: '' });
  // useEffect(() => {
  //   const timeout = setTimeout(
  //     () =>
  //       getAllStreamers({
  //         username: searchParams.get('Players'),
  //         page: searchParams.get('page'),
  //       }),
  //     1000,
  //   );
  //   return () => clearTimeout(timeout);
  // }, [searchParams.get('Players')]);
  return (
    <>
      <div className='flex justify-between bg-slate-900 gap-5 relative  w-full sm:max-w-[700px] lg:max-w-[94%] m-auto rounded-xl border-1 h-fit mt-24 overflow-hidden min-h-[300px]  '>
        <div className='flex flex-col p-8 h-full justify-between'>
          <h1 className=' text-white font-bold text-2xl '>Welcome to Game Arena</h1>
          {/* <span className='flex flex-col gap-1'>
            {' '}
            <p className=' text-white text-sm'>
              Streams of ongoing matches and events can be found here
            </p>
            <p className=' text-white text-sm'>
              You can filter stream by game, team, season, and more.
            </p>
          </span> */}

          <Search
            setQuery={setSearchQuery}
            filterList={filterList}
            search={search}
            setSearch={setSearch}
            extraClass='w-fit'
          />
          <div className='flex mt-2'>
            <Link
              className='flex gap-4 items-center justify-between px-4 py-2 bg-gray-300 rounded-lg text-black hover:opacity-80'
              to='browse'
            >
              <p>Browse</p>
              <IoIosArrowForward className='' />
            </Link>
          </div>
        </div>
        <img src={img} className=' h-full object-fill' />
      </div>
      {stIsLoading ? (
        <p>Loading...</p>
      ) : stData?.data?.length ? (
        <>
          <div className='flex w-full flex-wrap gap-8 ml-9 '>
            {stData?.data?.map((u: any) => (
              <Link key={u.id} to={`/gamearena/streamer/${u.id}`}>
                <div className='flex flex-col items-center'>
                  <AvatarImage source={u.image} size='xxl' />
                  {u.firstName}
                </div>
              </Link>
            ))}
          </div>
          <div className='flex gap-2 items-center flex-wrap ml-12'>
            <span>
              {stData?.meta?.currentPage} of {stData?.meta?.lastPage}
            </span>
            <div className='cursor-pointer' onClick={stData.meta.prev ? goPrevPage : () => null}>
              <BsArrowLeft color={stData.meta.prev ? '#b50303' : 'grey'} />
            </div>
            <div className='cursor-pointer' onClick={stData.meta.next ? goNextPage : () => null}>
              <BsArrowRight color={stData.meta.next ? '#b50303' : 'grey'} />
            </div>
          </div>
        </>
      ) : stIsSuccess ? (
        <p>No user found!</p>
      ) : (
        <p>Empty</p>
      )}
    </>
  );
};
