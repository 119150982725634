import Pagination from '../../components/pagination';
import { Separator } from '../../components/ui/separator/Separator';
import { UserModel } from '../../features/listview/users/model/user.model';
import {
  useGetAllUsersQuery,
  useResendInvitationMutation,
  useGenerateInviteLinkMutation,
} from '../../slices/userApiSlice';
import { LoadingSceletonUsers } from '../tournaments/routes/all/loading-sceleton-users';
import { Link, Outlet, useSearchParams } from 'react-router-dom';
import { Search } from '../../components/search/search';
import { useState } from 'react';
import TableContainer from '../../components/table-container/table-container';
import { TableHead } from '../../components/table-head';
import TableTd from '../../components/table-td/table-td';
import { BiRefresh } from 'react-icons/bi';
import { AiFillDelete } from 'react-icons/ai';
import { toast } from 'react-toastify';
import CanIView from '../../components/can-i-view/can-i-view';

const listView = [
  {
    name: 'first name',
    active: true,
    property: 'firstName',
  },
  {
    name: 'last name',
    active: true,
    property: 'lastName',
  },
  {
    name: 'email',
    active: true,
    property: 'email',
  },
  {
    name: 'school',
    active: true,
    property: 'school_name',
  },
  {
    name: 'invite sent',
    active: true,
    property: 'updateDifference',
  },
];

const OgmaInvitedUsers = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState({ by: 'email', keyword: '' });

  const filterList = [
    {
      title: 'email',
      property: 'email',
    },
    {
      title: 'school',
      property: 'school',
    },
    {
      title: 'name',
      property: 'name',
    },
  ];

  const [searchQuery, setSearchQuery] = useState(filterList[0].title);
  const { data, isLoading, isSuccess, isError } = useGetAllUsersQuery({
    // query: 'invited' ,
    // param: 'true',
    params: { invited: true, ...Object.fromEntries(searchParams.entries()) },
    page: searchParams.get('page') ?? 1,
  });

  if (isLoading) return <LoadingSceletonUsers />;

  function GenerateInviteLink({ email }: { email: string }) {
    const [getInviteLink, { isLoading }] = useGenerateInviteLinkMutation();
    return (
      <span
        className='items-center flex btn red text-center min-w-[1px] max-w-fit m-auto'
        onClick={async () => {
          const data = await getInviteLink({ email });
          if ('data' in data)
            navigator.clipboard
              .writeText(data.data.url)
              .then(() => {
                toast.success('URL copied to clipboard', {
                  position: toast.POSITION.TOP_CENTER,
                });
              })
              .catch((error) => {
                toast.error('Failed to copy to clipboard', {
                  position: toast.POSITION.TOP_CENTER,
                });
              });
        }}
      >
        <BiRefresh color='white' className={isLoading ? 'animate-spin' : ''} />
        Generate Link
      </span>
    );
  }

  function UserResend({ email }: { email: string }) {
    const [resend, { isLoading }] = useResendInvitationMutation();
    return (
      <span
        className='items-center  flex btn red text-center min-w-[1px] max-w-fit m-auto'
        onClick={() => resend({ email })}
      >
        <BiRefresh color='white' className={isLoading ? 'animate-spin' : ''} />
        Resend to User
      </span>
    );
  }

  return (
    <div className='listViewContainer fullPage border'>
      <div className='flex flex-col justify-between gap-5'>
        <div className='flex w-full justify-between'>
          <div className='flex items-center justify-between'>
            <div className='space-y-1'>
              <h2 className='text-2xl font-semibold tracking-tight'>Invited Users</h2>
            </div>
          </div>
        </div>
        <div>
          <Separator className='my-5' />
        </div>
        <div>
          <div className='flex flex-wrap md:justify-between justify-center gap-5 '>
            <div className='flex gap-x-2'>
              <Link to='../users' className='button hover:bg-opacity-80 w-fit bg-fen-blue'>
                SHOW ACTIVE USERS
              </Link>
            </div>
            <Search
              setQuery={setSearchQuery}
              filterList={filterList}
              search={search}
              setSearch={setSearch}
            />
          </div>
        </div>
        <TableContainer>
          <TableHead
            oneMore
            tableList={listView
              .map((l) => l.name)
              .filter(
                (t) => listView.find((d) => d.name.toLowerCase() === t.toLowerCase())?.active,
              )}
          />
          <tbody className='bg-white divide-y divide-gray-200 '>
            {data?.data?.map((d: any, i: number) => (
              <tr key={d.id}>
                {listView
                  .filter((l) => l.active)
                  .map((t) => {
                    const model: UserModel & { [key: string]: any } = new UserModel(d);
                    if (t?.name.split(' ').join('') === 'invitesent') {
                      return (
                        <TableTd key={t.name}>
                          <span
                            className={`basis-[20%] flex-1 font-bold text-center min-w-[1px] ${
                              Number(model[t.property]?.split('d')?.join('')) > 7
                                ? 'text-red-700'
                                : ''
                            }`}
                          >
                            {model[t.property]}
                          </span>
                        </TableTd>
                      );
                    }
                    return <TableTd key={t.name}>{model[t.property]}</TableTd>;
                  })}
                <TableTd>
                  <div className='flex items-center gap-2'>
                    <div className='flex flex-col 2xl:flex-row gap-2'>
                      <CanIView component='generateInviteLink'>
                        <GenerateInviteLink email={d?.email} />
                      </CanIView>
                      <UserResend email={d?.email} />
                    </div>
                    <div>
                      <Link to={`cancel/${d?.id}`} className='cursor-pointer'>
                        <AiFillDelete color='red' size={30} />
                      </Link>
                    </div>
                  </div>
                </TableTd>
              </tr>
            ))}
          </tbody>
        </TableContainer>
        <Outlet />
        <Pagination length={data?.data?.length} {...data?.meta} />
      </div>
    </div>
  );
};

export default OgmaInvitedUsers;
