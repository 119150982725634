import React from 'react';
import { HiUsers } from 'react-icons/hi';
import { MdGroups } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { useGetSingleTournamentInfoQuery } from '../../../slices/tournamentApiSlice';
export function TournamentOverviewFormat({ }) {
  const param = useParams();
  const { data, isLoading } = useGetSingleTournamentInfoQuery(param.id);

  if (isLoading) return <p>Loading...</p>;
  return (
    <div className='flex flex-row md:flex-col gap-2 order-1 md:order-2 w-full md:w-fit '>
      <div className='flex items-center flex-col w-1/2 md:w-72 text-center justify-around px-10 p-5  text-black border border-gray-200 rounded-xl'>
        <HiUsers size={25} />
        <p className='text-black font-bold'>Team Size</p>
        <p className='text-black text-sm'>{data.team_size}</p>
      </div>
      <div className='flex items-center flex-col text-center w-1/2 md:w-72 justify-around px-10 p-5  text-black border border-gray-200 rounded-xl'>
        <MdGroups size={25} />
        <p className='text-black font-bold'>Format</p>
        <p className='text-black text-sm'>{data?.match_format?.name}</p>
      </div>
    </div>
  );
}
