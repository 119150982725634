import React from 'react';
import { NotificationModel } from '../model/notification.model';
import { Link } from 'react-router-dom';
export function NotificationNavRow({ model }: { model: NotificationModel }) {
  if (model?.link?.length)
    return (
      <Link to={model.link}>
        <div className='flex items-center leading-8 gap-5 border-b-1 border-color p-3 hover:bg-slate-50'>
          <NotificationCore model={model} />
        </div>
      </Link>
    );
  return (
    <div className='flex items-center leading-8 gap-5 border-b-1 border-color p-3'>
      <NotificationCore model={model} />
    </div>
  );
}

function NotificationCore({ model }: { model: NotificationModel }) {
  return (
    <>
      <img
        className='rounded-full h-5 w-5'
        src='https://friconix.com/png/fi-ctluxx-exclamation-mark-rounded.png'
        alt='notification icon'
      />
      <div>
        <p className='font-semibold dark:text-gray-200 text-sm'>{model.title}</p>
        <p className='text-gray-500 text-xs dark:text-gray-400'> {model.description} </p>
        <p className='text-gray-500 text-xs dark:text-gray-400'> {model.f_time} </p>
      </div>
    </>
  );
}
