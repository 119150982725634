import React from 'react'
import { Navigate, useParams } from 'react-router';
import { useUnArchiveMutation } from '../../../../../slices/unarchiveApiSlice';
import ModalUnarchive from '../../../components/unarchive-modal';
import { LoadingSceletonArchived } from '../../../../tournaments/routes/all/loading-sceleton-archived';

const UnarchiveClassroomModal = () => {
    const param = useParams();
    const [unarchive, { isLoading, isError, isSuccess }] = useUnArchiveMutation();
    const onSubmit = () => {
        unarchive({
            id: Number(param.id),
            body: { name: 'classroom' },
        });
    };
    if (isSuccess) return <Navigate to='../' />;
    return <ModalUnarchive onSubmit={onSubmit} isLoading={isLoading} name={param.name ?? ''} />;
    return <LoadingSceletonArchived/>;
}

export default UnarchiveClassroomModal