import { Outlet, useLocation, useNavigate } from 'react-router';
import { SupportOptionCard } from './SupportOptionCard';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';

const SupportTicketsTypeTab = () => {
  const [isActive, setIsActive] = useState(1);
  const [dropdownItemSelected, setDropDownItemSelected] = useState(1);
  const [openDropDown, setOpenDropDown] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const supportTabNavigation = [
    {
      id: 1,
      option: 'All',
      description: '',
      to: 'all',
    },
    {
      id: 2,
      option: 'Internal',
      description: '',
      to: 'internal',
    },
    {
      id: 3,
      option: 'External',
      description: '',
      to: 'external',
    },
  ];

  useEffect(() => {
    switch (location.pathname) {
      case '/support/tickets/open':
        navigate('all');
        setDropDownItemSelected(1);
        break;
      case '/support/tickets/closed':
        navigate('all');
        setDropDownItemSelected(1);
        break;
      case '/support/tickets/archived':
        navigate('all');
        setDropDownItemSelected(1);
        break;
    }
  }, [location.pathname]);

  const handleItemClick = (itemId: any) => {
    setDropDownItemSelected(itemId);
    setOpenDropDown(false);
  };

  return (
    <div className='flex min-h-fit gap-5 items-center justify-end p-5 flex-row bg-white flex-wrap'>
      <div className='flex items-center gap-2'>
        <div>
          <h1>Type:</h1>
        </div>
        <div
          className='w-[150px] border-1 p-2 relative cursor-pointer'
          onClick={() => setOpenDropDown(!openDropDown)}
        >
          <div className='flex justify-between items-center'>
            <span>
              {supportTabNavigation.find((item) => item.id === dropdownItemSelected)?.option}
            </span>
            {openDropDown ? <AiOutlineArrowUp /> : <AiOutlineArrowDown />}
          </div>
          {openDropDown ? (
            <div className='mt-5 mb-5 absolute z-1 w-[130px] max-w-[130px] bg-white flex flex-col'>
              {supportTabNavigation.map((item) => (
                <Link
                  className={`${dropdownItemSelected === item.id ? 'bg-gray-300' : ''} p-2`}
                  to={item.to}
                  onClick={() => handleItemClick(item.id)}
                  key={item.id}
                >
                  {item.option}
                </Link>
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SupportTicketsTypeTab;
