import { apiSlice } from '../api/apiSlice';

export const supportSubCategoryApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createSubcategory: builder.mutation({
      query: (body) => ({
        url: '/support-subcategory/new',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['SupportSubCategory'],
    }),
    getAllSubcategories: builder.query({
      query: (query) => ({
        url: '/support-subcategory/all',
        method: 'GET',
        params: { ...query, page: query.page ?? 1, isArchived: query.isArchived ?? '' },
      }),
      providesTags: [
        'SupportSubCategory',
        'SupportCategory',
        'ArchiveSubcategory',
        'UnArchiveSubcategory',
        'ArchiveCategory',
        'UnArchiveCategory',
      ],
    }),
    getSubcategoryById: builder.query({
      query: ({ id, path }) => ({
        url: `/support-subcategory/${id}?path=${path}`,
        method: 'GET',
      }),
      providesTags: ['SupportSubCategory'],
    }),
    getAllSubcategoriesByCategoryId: builder.query({
      query: (id) => ({
        url: `/support-subcategory/category/${id}`,
        method: 'GET',
        params: {
          optional: 1,
        },
      }),
      providesTags: ['SupportSubCategory', 'SupportCategory'],
    }),
    updateSubcategory: builder.mutation({
      query: ({ id, body }) => ({
        url: `/support-subcategory/update/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['SupportSubCategory'],
    }),
    // deleteSubcategoryById: builder.mutation({
    //   query: (id) => ({
    //     url: `/support-subcategory/${id}`,
    //     method: 'DELETE',
    //   }),
    //   invalidatesTags: ['SupportSubCategory'],
    // }),
    // changeCategory: builder.mutation({
    //   query: ({ id, body }) => ({
    //     url: `/support-subcategory/change/category/${id}`,
    //     method: 'POST',
    //     body,
    //   }),
    //   invalidatesTags: ['SupportSubCategory'],
    // }),
    // changeTierLevel: builder.mutation({
    //   query: ({ id, body }) => ({
    //     url: `/support-subcategory/change/tier/${id}`,
    //     method: 'POST',
    //     body,
    //   }),
    //   invalidatesTags: ['SupportSubCategory'],
    // }),
    // changePriorityLevel: builder.mutation({
    //   query: ({ id, body }) => ({
    //     url: `/support-subcategory/change/priority/${id}`,
    //     method: 'POST',
    //     body,
    //   }),
    //   invalidatesTags: ['SupportSubCategory'],
    // }),
    archiveSubcategory: builder.mutation({
      query: (id) => ({
        url: `/support-subcategory/archive/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['ArchiveSubcategory'],
    }),
    unArchiveSubcategory: builder.mutation({
      query: (id) => ({
        url: `/support-subcategory/unArchive/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['UnArchiveSubcategory'],
    }),
  }),
});

export const {
  useCreateSubcategoryMutation,
  useGetAllSubcategoriesQuery,
  useGetSubcategoryByIdQuery,
  useGetAllSubcategoriesByCategoryIdQuery,
  useUpdateSubcategoryMutation,
  // useDeleteSubcategoryByIdMutation,
  useArchiveSubcategoryMutation,
  useUnArchiveSubcategoryMutation,
} = supportSubCategoryApiSlice;
