import { apiSlice } from '../api/apiSlice';
import { TokenResult } from '@square/web-payments-sdk-types';

export const paymentApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // payCoachByCard: builder.mutation({
    //   query: (body: { school_id: number; hours: number; total: number; token: TokenResult }) => ({
    //     url: '/payments/coach-card',
    //     method: 'POST',
    //     body,
    //   }),
    // }),
    // payCoachByCheck: builder.mutation({
    //   query: (body: { school_id: number; hours: number; total: number }) => ({
    //     url: '/payments/coach-check',
    //     method: 'POST',
    //     body,
    //   }),
    // }),
    payByCheckOgmaInvoice: builder.mutation({
      query: (id: number | string) => ({
        url: `/payments/ogma-by-check/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['OgmaSchools', 'SchoolInvoices'],
    }),
    payByCardOgmaInvoice: builder.mutation({
      query: (body: {
        id: number;
        type: number;
        total: number;
        list_students?: number[];
        token: any;
      }) => ({
        url: '/payments/ogma-card',
        method: 'Post',
        body,
      }),
      invalidatesTags: ['OgmaSchools', 'SchoolInvoices'],
    }),
    payStudentInvoice: builder.mutation({
      query: (body: { total: number; invoice_id: number; token: any; user_id: number }) => ({
        url: '/payments/student',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['StudentInvoice', 'SingleInvoice'],
    }),
    paySchoolAndStudentInvoice: builder.mutation({
      query: (body) => ({
        url: '',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['OgmaInvoice'],
    }),
    paySchoolInvoice: builder.mutation({
      query: (body: { total: number; invoice_id: number; token: string; school_id: number }) => ({
        url: '/payments/school',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['OgmaInvoice', 'SchoolInvoices'],
    }),
    testPayment: builder.mutation({
      query: (body) => ({
        url: '/payments/test',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  // usePayCoachByCardMutation,
  // usePayCoachByCheckMutation,
  usePayByCheckOgmaInvoiceMutation,
  usePayByCardOgmaInvoiceMutation,
  usePayStudentInvoiceMutation,
  usePaySchoolAndStudentInvoiceMutation,
  usePaySchoolInvoiceMutation,
  useTestPaymentMutation,
} = paymentApiSlice;
