import axios from 'axios';
import { apiSlice } from '../api/apiSlice';

export const twitchApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTwitchByUserId: builder.query({
      queryFn(arg, api, extraOptions, baseQuery) {
        console.log('arg test', arg);
        // @ts-ignore
        const twitchToken = api.getState().gamearena.twitch_token;
        const data = axios
          // .get(`https://api.twitch.tv/helix/streams?user_id=${arg.userId}`, {

          // .get(
          //   `https://api.twitch.tv/helix/streams?user_login=xhocii&type=all`,
          //   {
          .get(
            `https://api.twitch.tv/helix/streams${arg.query}${arg.limit ?? ''}${
              arg.pagination ?? ''
            }`,
            {
              headers: {
                'Client-id': twitchToken.clientId,
                Authorization: `Bearer ${twitchToken.twitch.access_token}`,
              },
            },
          )
          .then((res) => res.data);
        return {
          data,
        };
      },
    }),
    getRecentVideos: builder.query({
      queryFn(arg, api, extraOptions, baseQuery) {
        // @ts-ignore
        const twitchToken = api.getState().gamearena.twitch_token;
        const data = axios
          .get(
            `https://api.twitch.tv/helix/videos${arg.query}&sort=views${arg.limit ?? ''}${
              arg.pagination ?? ''
            }`,
            {
              headers: {
                'Client-id': twitchToken.clientId,
                Authorization: `Bearer ${twitchToken.twitch.access_token}`,
              },
            },
          )
          .then((res) => res.data);
        return {
          data,
        };
      },
    }),
    validateTwitchToken: builder.mutation({
      queryFn(arg, api, extraOptions, baseQuery) {
        // @ts-ignore
        const twitchToken = api.getState().gamearena.twitch_token;
        const data = axios
          .get(`https://id.twitch.tv/oauth2/validate`, {
            headers: {
              // "Client-id": twitchToken.clientId,
              Authorization: `Bearer ${twitchToken.twitch.access_token}`,
            },
          })
          .then((res) => res.data);
        return {
          data,
        };
      },
    }),
    testTwitchUser: builder.mutation({
      queryFn(arg, api, extraOptions, baseQuery) {
        // @ts-ignore
        const twitchToken = api.getState().gamearena.twitch_token;
        const data = axios
          .get(`https://api.twitch.tv/helix/users?login=${arg}`, {
            headers: {
              'Client-id': twitchToken.clientId,
              Authorization: `Bearer ${twitchToken.twitch.access_token}`,
            },
          })
          .then((res) => res.data);
        return {
          data,
        };
      },
    }),
  }),
});

export const {
  useValidateTwitchTokenMutation,
  useGetRecentVideosQuery,
  useGetTwitchByUserIdQuery,
  useLazyGetRecentVideosQuery,
  useLazyGetTwitchByUserIdQuery,
  useTestTwitchUserMutation,
} = twitchApiSlice;
