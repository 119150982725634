import React from 'react';
import { ClassroomModel } from '../../../model/classroom.model';
import CanIView from '../../../components/can-i-view/can-i-view';
import { Link } from 'react-router-dom';

const defaultBg = (await import('../../../assets/grid-background.jpg')).default;

const ClassroomCard = ({ classroom, hide }: { classroom: ClassroomModel, hide?: boolean; }) => {
  return (
    <Link to={`/classrooms/view/${classroom.id}`}>
      <div
        className='w-[350px] items-center rounded bg-white shadow'>

        {/* shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] */}
        {/* <img
          className=' w-full h-[200px] object-cover rounded-t'
          src={classroom?.profile_image?.length ? classroom?.profile_image : defaultBg}
          alt=''
        /> */}
        <div className='relative'>
          <img
            className=' w-full h-[200px] object-cover rounded-t'
            src={classroom?.profile_image?.length ? classroom?.profile_image : defaultBg}
            alt=''
          />
          <span className='absolute top-0 right-0 p-2 bg-[black] rounded m-2 text-white max-w-[6rem] truncate'>{classroom.game_name}</span>
        </div>
        <div className='w-[350px] flex flex-col gap-5 items-center'>
          <div className={`${hide ? '' : 'border-neutral-100'} px-6 py-3 dark:border-neutral-600 dark:text-neutral-50 font-bold max-w-[30ch] truncate`}>
            {classroom?.name}
          </div>
          <div className='flex flex-col items-center'>
            <div className='mb-2 text-xl text-center font-medium leading-tight text-neutral-800 dark:text-neutral-50 max-w-[20ch] truncate'>
              {classroom?.coach}
            </div>
            {/* <div className='mb-2 text-xl text-center font-medium leading-tight text-neutral-800 dark:text-neutral-50 max-w-[20ch] truncate'>
              {classroom?.game_name}
            </div> */}
            {/* <p className="mb-4 text-base text-neutral-600 dark:text-neutral-200">
          {classroom.description}
          </p> */}
            <button className='flex justify-center rounded bg-primary  pt-2.5 text-xs font-medium uppercase text-white'>
              <CanIView component='classroom.card.book-hours'>
                <Link to={`./book-student/${classroom?.id}`} className='button'>
                  Book hours
                </Link>
              </CanIView>
              <CanIView component='classroom.card.schedule-hours'>
                <Link to={`./schedule-ogma/${classroom?.id}`} className={`button ${hide ? 'hidden' : ''}`}>
                  Schedule Hours
                </Link>
              </CanIView>
            </button>
          </div>
          <div className={`text-base ${hide ? '' : 'border-neutral-100'} px-6 py-3 dark:border-neutral-600 dark:text-neutral-50`}>
            {classroom?.createdAt}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ClassroomCard;
