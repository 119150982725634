import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import TableContainer from '../../../../../../components/table-container/table-container';
import { TableHead } from '../../../../../../components/table-head';
import TableTd from '../../../../../../components/table-td/table-td';
import { useGetAllStudentBySchoolIdQuery } from '../../../../../../slices/userApiSlice';
import { dateAndTimeFormatter } from '../../../../../../utils/dateFormatter';
import Pagination from '../../../../../../components/pagination';

const SchoolStudents = () => {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    isLoading,
    error,
    data: studentData,
  } = useGetAllStudentBySchoolIdQuery({
    id: Number(params.id),
    params: { page: searchParams.get('page') ?? undefined },
  });
  const navigate = useNavigate();
  if (isLoading) {
    return <p>Loading ...</p>;
  }
  return (
    <>
      <div className='flex flex-col gap-2'>
        <TableContainer>
          <TableHead tableList={['Username', 'Teams', 'Joined', 'Email Address']} />

          <tbody className='bg-white divide-y divide-gray-200'>
            {studentData &&
              studentData?.data?.map((o: any) => (
                <tr key={o.id} onClick={() => navigate(`/users/view/${o.id}/student/overview`)}>
                  <TableTd extraClass=''>
                    <div className='max-w-[15ch] truncate '>{o.username}</div>
                  </TableTd>
                  <TableTd>
                    {o.team.slice()
                      .sort((a: any, b: any) => a.teamName.localeCompare(b.teamName))
                      .map((team: any) => (
                        <div key={team.id} className="hover:text-black hover:bg-opacity-20 hover:font-bold hover:underline"
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/teams/view/${team.id}/`);
                          }}>
                          {team.teamName}
                        </div>
                      ))}
                  </TableTd>
                  <TableTd>{dateAndTimeFormatter(o.createdAt).split(';')[0]}</TableTd>
                  <TableTd>{o.email}</TableTd>
                </tr>
              ))}
          </tbody>
        </TableContainer>

        <Pagination length={studentData?.data?.length} {...studentData?.meta} />
      </div>
    </>
  );
};

export default SchoolStudents;
