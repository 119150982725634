import { dateAndTimeFormatter } from "../../utils/dateFormatter";
import { TicketStatusTime, UserRoles, priorityLevels, supportTicketStatusName, userRoleNames } from "../../utils/constants";
import { wordWithFirstLetterToUpperCase } from "../../utils/utils";

export class TicketModel {
    id?: number;
    title;
    firstName?: string;
    lastName?: string;
    fullName?: string;
    image;
    roles?: string;
    category?: string;
    subcategory?: string;
    status?: string;
    createdAt?: string;
    email?: string;
    priority?: string;
    closedAt?: string;
    constructor(data: any) {
        if (data.hasOwnProperty('id')) this.id = data.id;
        if (data.hasOwnProperty('user') && data.user && data.user.hasOwnProperty('firstName'))
            this.firstName = wordWithFirstLetterToUpperCase(data.user.firstName);
        if (data.hasOwnProperty('user') && data.user && data.user.hasOwnProperty('lastName'))
            this.lastName = wordWithFirstLetterToUpperCase(data.user.lastName);
        if (this.firstName && this.lastName) {
            this.fullName = `${this.firstName} ${this.lastName}`;
        }
        else {
            this.fullName = data?.email
        }
        if (data.hasOwnProperty('image')) this.image = data.image;
        if (data.hasOwnProperty('user') && data.user && data.user.hasOwnProperty('roles')) {
            this.roles = userRoleNames[data.user.roles];
        }
        else {
            this.roles = '-';
        }
        if (data.hasOwnProperty('category') && data.category && data.category.hasOwnProperty('name'))
            this.category = data.category.name;
        if (data.hasOwnProperty('subcategory') && data.subcategory && data.subcategory.hasOwnProperty('name'))
            this.subcategory = data.subcategory.name;
        if (data.hasOwnProperty('status') && !data?.ticketTier) {
            this.status = supportTicketStatusName[data.status];
        }
        if (data.hasOwnProperty('status') && data?.ticketTier) {
            const todoOrAck = data?.ticketTracking?.ack ? 'In Progress' : 'Not Started';
            switch (data?.status) {
                case TicketStatusTime.Closed:
                    this.status = 'Closed'
                    break;
                case TicketStatusTime.ToDo:
                    this.status = todoOrAck;
                    break;
                case TicketStatusTime.InProgressT2:
                    this.status = 'In Progress'
                    break;
                case TicketStatusTime.MonitorReview:
                    this.status = 'Monitoring'
                    break;
            }
        }
        if (data.hasOwnProperty('createdAt')) this.createdAt = dateAndTimeFormatter(data.createdAt ?? new Date()).split(';')[0];
        if (data.hasOwnProperty('title')) this.title = data.title;
        if (data.hasOwnProperty('email')) this.email = data.email ?? '-';
        if (data.hasOwnProperty('subcategory') && data.subcategory && data.subcategory.hasOwnProperty('priorityLevel')) {
            this.priority = priorityLevels[data?.subcategory?.priorityLevel];
        }
        if (data?.hasOwnProperty('ticketTracking') && data?.ticketTracking.hasOwnProperty('createdInJira') && data.ticketTracking.createdInJira.length > 1 && data.ticketTier && data?.status === TicketStatusTime.ToDo) {
            this.status = 'Pending Resolution';
        }
        if (data?.hasOwnProperty('adminApproval') && data?.adminApproval && data?.status) {
            this.status = 'Resolved';
        }
        if (data?.hasOwnProperty('closedAt')) {
            this.closedAt = data?.closedAt ? dateAndTimeFormatter(data?.closedAt)?.split(';')?.join() : '-';
        }
    }
};