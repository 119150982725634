import { useEffect, useMemo, useState } from 'react';
import { useGetAllOrganizationTeamsQuery } from '../slices/teamsApiSlice';
import { useSelector } from 'react-redux';
import { selectUser } from '../slices/auth';
import { UserRoles } from '../utils/constants';

function useOgmaBelongsToTeam(id: string[]) {
  const user = useSelector(selectUser);
  if (user.roles !== UserRoles.Ogma) return false;
  const queryArgs = useMemo(() => ({}), []);
  const { data } = useGetAllOrganizationTeamsQuery(queryArgs);
  const [isOgmaTeam, setIsOgmaTeam] = useState(false);
  useEffect(() => {
    const filtered = data?.filter((t: any) => id.includes(t.id));
    setIsOgmaTeam(Boolean(filtered?.length));
  }, [data, id]);

  return isOgmaTeam;
}

export default useOgmaBelongsToTeam;
