import React, { useEffect } from 'react';
import {
  useEndMatchMutation,
  useGetSingleMatchInfoQuery,
  useSendToNextRoundTheWinnerTeamMutation,
} from '../../../slices/matchApiSlice';
import { useParams } from 'react-router';
export function EndGameButton({}) {
  const [endMatch, { isLoading: emIsLoading, isError: emIsError, error }] = useEndMatchMutation();
  useEffect(() => {
    console.log({ error });
  }, [error]);
  const param = useParams();

  const { data, isLoading, isError } = useGetSingleMatchInfoQuery(param.id);

  const [sendToNextRound, { data: sendToNextRoundData }] =
    useSendToNextRoundTheWinnerTeamMutation();

  if (isLoading) return <p>Loading...</p>;

  //   function handleSubmit() {

  //     console.log('tournament_round_id = ', data.tournament_round_id);
  //       sendToNextRound({
  //         id:param.id,
  //         tournament_round_id:data.tournament_round_id,
  //         round:data.round,
  //         home_team:data.home_team[0].id,
  //         away_team:data.away_team[0].id,
  //         ht_score:data.ht_score,
  //         at_score:data.at_score
  //         })

  // }

  function handleSubmit() {
    endMatch({
      matchID: param.id,
      // tournamentRoundID: data.tournament_round_id,
      // round: data.round,
      // homeTeamID: data.home_team[0].id,
      // awayTeamID: data.away_team[0].id,
      // htScore: data.ht_score,
      // atScore: data.at_score,
    });
  }
  return (
    <div className=' flex flex-col items-center'>
      <div
        onClick={() => {
          handleSubmit();
        }}
        className='rounded-lg flex justify-center p-2 uppercase bg-fen-blue text-white text-sm w-[200px] content-center cursor-pointer'
      >
        <p className='text-white'>Submit Scores</p>
        {/* <RxStop color='white' /> */}
      </div>
      {/* @ts-ignore */}
      <div className='text-red-700 text-center'>{error?.data?.message}</div>
    </div>
  );
}
