export function LoadingSceletonLists() {
  return (
    <div className='w-full h-full border-2 rounded-xl flex flex-col p-1'>
      <div className=' flex flex-wrap p-6 justify-between '>
        <div className='h-[50px] w-[200px] bg-slate-200 rounded-lg '></div>
        <div className='flex flex-wrap gap-5'>
          <div className='h-[50px] w-[200px] bg-slate-200 rounded-lg skeleton__pulse'></div>
          <div className='h-[50px] w-[200px] bg-slate-200 rounded-lg skeleton__pulse'></div>
          <div className='h-[50px] w-[200px] bg-slate-200 rounded-lg skeleton__pulse'></div>
        </div>
      </div>
      <div className=''>
        <hr />
        <div className='flex justify-between p-6 rounded-lg'>
          <div className='h-[50px] w-[200px] bg-white  '></div>
          <div className='h-[50px] w-[200px] bg-slate-200 rounded-lg  skeleton__pulse'></div>
        </div>
      </div>
      <div className=' h-full md:w-full  bg-slate-200 rounded-lg skeleton__pulse '></div>
    </div>
  );
}
