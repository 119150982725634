import { MatchScheduleModel } from '../../../model/match-schedule.model';
import { Link } from 'react-router-dom';
import { timeFormatter } from '../../../utils/dateFormatter';

const CalendarWeekRow = ({ date, model }: { date: Date; model: MatchScheduleModel }) => {
  return (
    <Link
      to={`/match/${model.id}`}
      className={'h-[150px] w-40 flex-shrink-0 bg-sky-100 rounded-lg flex-wrap flex flex-col p-1 items-center overflow-auto'}
    >
      <div className='self-start w-full flex justify-between mt-2'>
        <p className='text-cyan-700 text-xs font-extrabold'>{model.f_competitionType}</p>
        <p className='text-cyan-700 text-xs font-bold'>{timeFormatter(model.start_date)}</p>
      </div>
      <span className='flex flex-col gap-y-2 mt-4 text-center'>
        <p className='text-cyan-900 text-xs font-extrabold truncate'>{model.home_teamName}</p>
        <p className='text-cyan-700 font-xs font-bold'>vs</p>
        <p className='text-cyan-900 text-xs font-extrabold truncate'>{model.away_teamName}</p>
      </span>
    </Link>
  );
};

export default CalendarWeekRow;
