import { Socket, io } from 'socket.io-client';
import { apiSlice } from '../api/apiSlice';
import { changeTerms, updateUser } from './auth';

export const notificationApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // testScheduleAdmin: builder.mutation({
    //   query: () => ({
    //     url: '/notification/admin-test',
    //     method: 'GET',
    //   }),
    // }),
    getUnseenNotification: builder.query({
      query: (id: number | string) => ({
        url: '/notification/by-user',
        method: 'GET',
      }),
      providesTags: ['UnseenNotifications'],
      async onCacheEntryAdded(
        arg,
        { cacheDataLoaded, cacheEntryRemoved, updateCachedData, getState, dispatch },
      ) {
        let socket: undefined | Socket = io(import.meta.env.VITE_NOTIFICATION_SOCKET ?? '');
        try {
          await cacheDataLoaded;
          const state = getState();
          socket?.emit('join-room', `user-${arg}`);
          socket.on('changedTerms', (id) => dispatch(changeTerms(id)));
          // me qu id me socket me kallzu qe u ndrru terms
          socket.on('jira', () => {
            dispatch(apiSlice.util.invalidateTags(['SupportTicket']));
          });
          socket.on('updateUser', (user) => {
            console.log('updateUser', user);
            dispatch(updateUser(user));
          });
          socket.on('notification', (msg: MessageEvent) => {
            updateCachedData((draft) => {
              draft.latest.push(msg);
              draft.unseen++;
            });
          });
        } catch (error) {
          console.log(error);
        }
        await cacheEntryRemoved;
        socket.offAny();
        socket.close();
        socket.disconnect();
        socket = undefined;
      },
    }),
    getAllNotificationsByUserId: builder.query({
      query: (query) => ({
        url: `/notification/all-notifications-by-user?page=${query.page ? query.page : 1}`,
        method: 'Get',
      }),
      providesTags: ['Notification'],
    }),
    // getLatestNotificationsByUserId: builder.query({
    //   query: () => ({
    //     url: '/notification/latest-notifications',
    //     method: 'Get',
    //   }),
    //   providesTags: ['Notification'],
    // }),
    makeSeenListOfNotifications: builder.mutation({
      query: ({ body, count, user }) => ({
        url: '/notification/make-seen-list',
        method: 'PUT',
        body,
      }),
      async onQueryStarted(arg, api) {
        console.log('onQueryStarted', arg, api);
        const patchResult = api.dispatch(
          notificationApiSlice.util.updateQueryData('getUnseenNotification', arg.user, (draft) => {
            console.log('test', draft.unseen, arg.count);
            draft.unseen = arg.count;
          }),
        );
        console.log('patchResult', patchResult);
        try {
          console.log('awaiting');
          await api.queryFulfilled;
          console.log('fulfilled');
        } catch {
          console.log('undo patches');
          patchResult.undo();
        }
      },
    }),
  }),
});
export const {
  useLazyGetAllNotificationsByUserIdQuery,
  // useTestScheduleAdminMutation,
  useLazyGetUnseenNotificationQuery,
  useGetUnseenNotificationQuery,
  useGetAllNotificationsByUserIdQuery,
  // useGetLatestNotificationsByUserIdQuery,
  useMakeSeenListOfNotificationsMutation,
} = notificationApiSlice;
