import { Navigate, NavLink, Outlet, useNavigate, useParams } from 'react-router-dom';
import {
  useChangeOrganizationProfilePhotoMutation,
  useGetOrgByIdQuery,
} from '../../../slices/organizationApiSlice';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../slices/auth';
import OrganizationHeader from './organization-header';
import { CustomError } from '../../../utils/custom-types';
import CanIView from '../../../components/can-i-view/can-i-view';

const ViewOrganization = () => {
  const [changeOrganizationProfilePhoto, { isError, isLoading, isSuccess }] =
    useChangeOrganizationProfilePhotoMutation();

  const param = useParams();
  const user = useSelector(selectUser);
  function handleFileChange(event: any) {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    changeOrganizationProfilePhoto({ photo: formData, id: param.id ?? organizationData.id })
      .unwrap()
      .then((res) => console.log(res))
      .catch((e) => console.log(e));
  }
  const buttonList = [
    {
      name: 'Schools',
      link: param.id ? `/organizations/view/${param.id}/` : '/organization/',
    },
    {
      name: 'Teams',
      link: './teams',
    },
    {
      name: 'Coaches',
      link: './coaches',
    },
    {
      name: 'Ogma',
      link: './ogma',
    },
  ];

  const {
    error,
    data: organizationData,
    isLoading: organizationIsLoading,
    isError: organizationIsError,
    isSuccess: organizationIsSuccess,
  } = useGetOrgByIdQuery({
    id: param.id ?? user.organizations[0].id,
    query: 'match',
  });

  const navigate = useNavigate();
  if (
    organizationIsError ||
    (organizationIsSuccess && Object.keys(organizationData).includes('clientVersion'))
  )
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError).data.message }} />;

  if (organizationIsLoading) return <p>Loading...</p>;

  const orgSchools = organizationData.schools;

  return (
    <div className='listViewContainer fullPage border-1'>
      {/* <div className='flex justify-between pt-10 flex-col rounded-lg bg-white w-full h-[235px]'> */}
      <OrganizationHeader
        organizationData={organizationData}
        handleFileChange={handleFileChange}
        isLoading={isLoading}
      />
      <div className='flex gap-2 p-2 bg-white'>
        {buttonList.map((b) => {
          if (b.name.toLowerCase() === 'coaches' || b.name.toLowerCase() === 'ogma') {
            return (
              <CanIView component='organizationSections' key={b.link}>
                <NavLink to={b.link} className='settingsLink'>
                  <div> {b.name} &nbsp;</div>
                </NavLink>
              </CanIView>
            );
          } else {
            return (
              <NavLink key={b.link} to={b.link} className='settingsLink'>
                <div> {b.name} &nbsp;</div>
              </NavLink>
            );
          }
        })}
      </div>
      {/* </div> */}
      <Outlet />
    </div>
  );
};

export default ViewOrganization;
