import { useEffect, useState } from 'react';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import { OutsideClick } from '../../../../../../components/outside-click';
import { useGetTeamByIdQuery } from '../../../../../../slices/teamsApiSlice';
import { useGetAllStudentBySchoolIdQuery } from '../../../../../../slices/userApiSlice';
import ModalHeader from '../../../../../../components/modal-header';
import Pagination from '../../../../../../components/pagination';
import TeamMemberStudentRow from './team-member-student-row';
import { UserModel } from '../../../../../../features/listview/users/model/user.model';
import { Search } from '../../../../../../components/search/search';
import { Switch } from '../../../../../../components/ui/switch/Switch';
import { CustomError } from '../../../../../../utils/custom-types';
import { WarningMessages } from '../../../../../../utils/constants';

function AddStudent({ onTeam }: { onTeam?: boolean }) {
  const param = useParams();
  const {
    data: teamData,
    isLoading: teamIsLoading,
    isError: teamIsError,
    error,
  } = useGetTeamByIdQuery(
    { id: param.id, query: '' },
    {
      skip: !param.id,
    },
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const [search, setSearch] = useState({ by: 'name', keyword: '' });
  const initFilterList = [{ title: 'name', property: 'name' }];
  const [filterList, setFilterList] = useState(initFilterList);
  useEffect(() => {
    if (teamData?.School?.id) return;
    setFilterList([
      ...initFilterList,
      { title: 'school', property: 'school' },
      { title: 'organization', property: 'organization' },
    ]);
    {
    }
  }, [teamData]);
  const [searchQuery, setSearchQuery] = useState(filterList[0].title);
  const [coopOnly, setCoopOnly] = useState(true);
  function handleCheckChange(c: boolean) {
    searchParams.set('page', '1');
    setCoopOnly(c);
  }
  const { data: ssData, isLoading: ssIsLoading } = useGetAllStudentBySchoolIdQuery(
    {
      id: teamData?.schoolId ?? undefined,
      params: {
        team: param.id,
        coopOnly,
        withoutTeam: teamData?.game_id,
        name: searchParams.get('name')?.length ? searchParams.get('name') ?? undefined : undefined,
        school: searchParams.get('school') ?? undefined,
        organization: searchParams.get('organization') ?? undefined,
        page: searchParams.get('page') ?? undefined,
      },
    },
    { skip: !param.id },
  );
  const tableHead = ['Name', 'School', 'Organization'];
  if (teamIsLoading || ssIsLoading) return <p>Loading...</p>;
  if (teamIsError)
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError).data.message }} />;

  return (
    <>
      <OutsideClick />
      <div className='fixed left-0 right-0 top-0 bottom-0 z-10 m-auto max-w-full md:max-w-[80vw] md:w-fit md:min-w-[70vw] min-h-fit max-h-[70vh] lg:container overflow-auto flex flex-col'>
        <div className='border-0 rounded-lg shadow-lg relative flex flex-col flex-1 w-full bg-white outline-none focus:outline-none h-fit'>
          <ModalHeader name={'Add Student'} />
          <div className='flex justify-end p-2'>
            {onTeam && !teamData.School ? (
              <div className='flex flex-1 gap-2 items-center'>
                <label htmlFor='coop' className='cursor-pointer'>
                  <h3 className='font-semibold'>Co-Opted Organizations</h3>
                </label>
                <Switch id='coop' checked={coopOnly} onCheckedChange={handleCheckChange} />{' '}
              </div>
            ) : (
              <></>
            )}

            <Search
              setQuery={setSearchQuery}
              filterList={filterList}
              search={search}
              setSearch={setSearch}
            />
          </div>
          {teamData.Game.active_limit === 1 && teamData.activeMembers.length === 1 ? (
            <h3 className='flex text-center justify-center text-lg font-bold m-10'>
              {WarningMessages.CanNotAddSubsToOnePlayerTeams}
            </h3>
          ) : (
            <>
              <div className='flex border-b-1'>
                {tableHead.map((t, i) => (
                  <span key={t} className={`basis-[${!i ? '35' : '25'}%] text-center min-w-[1px]`}>
                    {t}
                  </span>
                ))}
              </div>
              <div className='flex flex-col flex-1'>
                {ssData?.data?.map((s: any) => (
                  <TeamMemberStudentRow
                    disabled={teamIsLoading || ssIsLoading}
                    key={s.id}
                    user={new UserModel(s)}
                  />
                ))}
              </div>
              <Pagination length={ssData?.data?.length} {...ssData?.meta} />
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default AddStudent;
