export class GameModel {
  id?: number;
  name?: string;
  active_limit?: number;
  subs_limit?: number;
  image?: string;
  team_size?: string[];
  constructor(data: any) {
    if (data.hasOwnProperty('id')) {
      this.id = data.id;
    }
    if (data.hasOwnProperty('name')) {
      this.name = data.name;
    }
    if (data.hasOwnProperty('active_limit')) {
      this.active_limit = data.active_limit;
    }
    if (data.hasOwnProperty('subs_limit')) {
      this.subs_limit = data.subs_limit;
    }
    if (data.hasOwnProperty('image')) {
      this.image = data.image;
    }
    if (data.hasOwnProperty('team_size')) {
      this.team_size = data.team_size;
    }
  }
}
