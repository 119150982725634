import { useSelector } from 'react-redux';
import CanIView from '../../components/can-i-view/can-i-view';
import Pagination from '../../components/pagination';
import TableContainer from '../../components/table-container/table-container';
import { TableHead } from '../../components/table-head';
import TableTd from '../../components/table-td/table-td';
import DropdownItem from '../../features/listview/DropDown/DropDownItem';
import { UserRoles } from '../../utils/constants';
import { TicketModel } from './TicketModel';
import { selectUser } from '../../slices/auth';
import { Outlet, useSearchParams } from 'react-router-dom';
import { useGetAllTicketsQuery } from '../../slices/supportTicketApiSlice';
import { useState } from 'react';

const ExternalTickets = () => {
  const user = useSelector(selectUser);
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    data: tickets,
    error,
    isLoading,
  } = useGetAllTicketsQuery({
    status: '0',
    type: '1',
    page: searchParams.get('page') ? searchParams.get('page') : 1,
  });

  const initialOpenListState = {
    id: null,
    x: null,
    y: null,
  };

  const [openList, setOpenList] = useState<{ [key: string]: number | null }>(initialOpenListState);

  const tableHeaderColumns = [
    {
      name: 'ticket id',
      active: true,
      property: 'id',
    },
    {
      name: 'Owner',
      active: true,
      property: 'email',
    },
    {
      name: 'Created date',
      active: true,
      property: 'createdAt',
    },
    {
      name: 'Priority',
      active: true,
      property: 'priority',
    },
    {
      name: 'Category',
      active: true,
      property: 'category',
    },
    {
      name: 'Subcategory',
      active: true,
      property: 'subcategory',
    },
    {
      name: 'Status',
      active: true,
      property: 'status',
    },
  ];

  const handleClick = (prop: { id: number; x: number; y: number }) => {
    if (user?.roles !== UserRoles.Admin) return;
    openList.id == prop.id ? setOpenList(initialOpenListState) : setOpenList(prop);
  };

  if (isLoading) return <p>Loading...</p>;
  return (
    <>
      <Outlet />
      <TableContainer>
        <TableHead oneMore tableList={tableHeaderColumns.map((table) => table.name)} />
        <tbody className='bg-white divide-y divide-gray-200'>
          {tickets &&
            tickets.data.map((t: any, i: number) => (
              <tr key={t.id} onClick={(e) => handleClick({ id: t.id, x: e.pageX, y: e.pageY })}>
                {tableHeaderColumns
                  .filter((l) => l.active)
                  .map((o, i) => {
                    const model: TicketModel & { [key: string]: any } = new TicketModel(t);
                    return <TableTd key={i}>{model[o.property]}</TableTd>;
                  })}
                <CanIView component='threedots'>
                  <TableTd>
                    <div className='listViewMenu--parent'>
                      <div
                        className={openList.id === t.id ? '' : 'hidden'}
                        style={
                          openList.id === t.id
                            ? {
                                position: 'fixed',
                                top: Number(openList.y),
                                left:
                                  Number(openList.x) < 200
                                    ? Number(openList.x) + 200
                                    : Number(openList.x),
                                zIndex: 99,
                              }
                            : {
                                left: 0,
                              }
                        }
                      >
                        <div
                          className='listViewMenu'
                          role='menu'
                          aria-orientation='vertical'
                          aria-labelledby='menu-button'
                        >
                          <div className='' role='none'>
                            <DropdownItem
                              id={t.id}
                              setFunc={setOpenList}
                              noView={false}
                              hasDelete={true}
                              hasConnectTicket={!t?.ticketTier}
                              viewLink={`/support/viewticket/${t.id}`}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </TableTd>
                </CanIView>
              </tr>
            ))}
        </tbody>
      </TableContainer>
      <Pagination length={tickets?.data?.length} {...tickets?.meta} />
    </>
  );
};

export default ExternalTickets;
