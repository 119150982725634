import { ConnectTwitchButton } from './components/connect-twitch-button';
import { GamearenaHeader } from './component/gamearena-header';
import React, { useEffect, useState } from 'react';
import { useInitGamearenaQuery } from '../../slices/gamearenaApiSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectLiveVideos,
  selectRecentVideos,
  selectTwitchToken,
  setLiveVideos,
} from '../../slices/gamearenaSlice';
import {
  useLazyGetRecentVideosQuery,
  useLazyGetTwitchByUserIdQuery,
} from '../../slices/twitchApiSlice';
import GameArenaLiveMatches from './component/gamearena-live-matches';
import { GamearenaSearch } from './component/gamearena-search';
import GameArenaRecentMatches from './component/gamearena-recent-b-matches';
import { Navigate, Outlet } from 'react-router-dom';
import { selectRole, selectUser } from '../../slices/auth';
import { SponsorPlacements, UserRoles } from '../../utils/constants';
import GameArenaFollow from './component/gamearena-sidebar';
import { GameArenaLoadingSceleton } from './component/gamearena-loading-sceleton';
import DisconnectTwitchButton from './components/discconect-twitch-button';
import CanIView from '../../components/can-i-view/can-i-view';
import { SponsorRow } from '../../components/sponsor-row/sponsor-row';

const GameArena = () => {
  const [isOpen, setIsOpen] = useState(true);

  const { data, isLoading, isFetching } = useInitGamearenaQuery('');
  const user = useSelector(selectUser);
  const role = useSelector(selectRole);
  const twitchToken = useSelector(selectTwitchToken);
  const [getTwitchByUserId, { data: twData, isLoading: twIsLoading }] =
    useLazyGetTwitchByUserIdQuery();
  const [getRecentVideos, { data: rcData, isLoading: rcIsLoading }] = useLazyGetRecentVideosQuery();

  const dispatch = useDispatch();
  const liveVideos = useSelector(selectLiveVideos);
  const recentVideos = useSelector(selectRecentVideos);
  useEffect(() => {
    if (!twitchToken || !data) {
      return;
    }
    let streamers = data?.streamingUsers?.map((s: any) => s.twitch_id);
    if (!streamers) return;
    let queryString = `?user_id=${streamers?.join('&user_id=')}`;
    // let queryString = `?user_id=886429483`;
    getTwitchByUserId({ query: queryString, pagination: '&first=4' }, true)
      .unwrap()
      .then((res) => dispatch(setLiveVideos(res.data)));
  }, [twitchToken, data]);
  //allow GameArena routes
  if (role !== 0) {
    return <Navigate to='../dashboard' />;
  }
  if (isLoading || !twitchToken || twIsLoading || rcIsLoading) return <GameArenaLoadingSceleton />;
  return (
    <div className='flex relative rounded-xl border-1 bg-white h-[calc(100vh-105px)] p-0 px-3 overflow-y-auto overflow-x-visible fullPage'>
      <GameArenaFollow />
      {/* </div> */}

      <div className='flex flex-col m-auto gap-8 relative rounded-xl  bg-white  p-0 px-8 my-4 overflow-y-auto overflow-x-visible '>
        <SponsorRow name={SponsorPlacements.Gamearena} />
        <CanIView component='view-match.streaming-info'>
          {user.twitch_id === null ? <ConnectTwitchButton /> : <DisconnectTwitchButton />}
        </CanIView>
        <div className='flex flex-col lg:flex-row justify-between gap-5 absolute top-0 left-0 bottom-0   '></div>
        <GamearenaHeader />
        <GamearenaSearch />
        <div className='flex flex-col flex-wrap justify-center mb-5'>
          {liveVideos && <GameArenaLiveMatches />}
          {/* TODO: Pagination it's causing re-render of twitch player */}
          {recentVideos && <GameArenaRecentMatches />}
        </div>
      </div>
      <Outlet />
    </div>
  );
};

export default GameArena;
