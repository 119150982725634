import * as yup from 'yup';
import {
  zipCodeRules,
  zipCodeRulesHint,
  usPhoneRules,
  usPhoneRulesHint,
} from '../../../authentication/yupschema';
import { ErrorMessages } from '../../../../utils/messages.enum';
export const UpdateSchoolYupSchema = yup.object().shape({
  name: yup
    .string()
    .matches(
      /^[a-zA-ZÀ-ÖØ-öø-ÿÇç0-9\s-,.'"]{0,50}$/,
      'Only letters, numbers, spaces, and hyphens are allowed (maximum 50 characters)',
    )
    .required('Required!'),
  streetAddress: yup.string().required('Required!'),
  city: yup
    .string()
    .required('Required!')
    .matches(/^[a-zA-ZÀ-ÖØ-öø-ÿÇç\s-,.'"]+$/, ErrorMessages.CityValidation),
  state: yup
    .string()
    .required('Required!')
    .matches(/^[a-zA-Z\s,.]+$/, 'State name must only contain letters and spaces'),
  zipCode: yup.string().matches(zipCodeRules, zipCodeRulesHint).required('Required!'),
  phoneNumber: yup.string().matches(usPhoneRules, usPhoneRulesHint).required('Required!'),
  // status: yup.string().required('Required'),
  // tags: yup.string().required('Required!'),
  price: yup.number().required('Required!'),
  price_per_student: yup.number().required('Required!'),
});
