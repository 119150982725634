import { AiFillEdit, AiOutlineFileDone } from 'react-icons/ai';
import { CiTimer } from 'react-icons/ci';
import { Link, Outlet, useParams, useSearchParams } from 'react-router-dom';
import { useGetAllClassroomsByUserIdQuery } from '../../../../slices/classroomApiSlice';
import ClassroomCard from '../components/ClassroomCard';
import { BsFacebook, BsLinkedin, BsYoutube } from 'react-icons/bs';
import { useGetCoachByIdQuery, useGetUserByIdQuery } from '../../../../slices/userApiSlice';
import CanIView from '../../../../components/can-i-view/can-i-view';
// TODO: use .data started using pagination
const CoachOverview = () => {
  const param = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    data: classroomData,
    isLoading: classroomIsLoading,
    isError: classroomIsError,
  } = useGetAllClassroomsByUserIdQuery({
    id: param.id,
    query: {
      name: searchParams.get('name') ?? undefined,
      page: searchParams.get('page') ?? 1,
      limit: 4,
    },
  });

  const {
    data: coachData,
    isLoading: coachIsLoading,
    error: coachIsError,
  } = useGetCoachByIdQuery(param.id!, { skip: !param.id });

  if (coachIsLoading || classroomIsLoading) return <p>Loading...</p>;
  const classrooms = (
    <div className='flex flex-wrap gap-4 '>
      {classroomData?.data.map((classroom: any) => (
        <ClassroomCard {...classroom} key={classroom.id} growMode={true} />
      ))}
    </div>
  );

  return (
    <div className='flex flex-wrap justify-between gap-5'>
      <div className='w-full order-2 md:order-1 md:w-2/3'>
        {/* <div className='flex flex-wrap min-w-[300px] gap-10 justify-around items-center bg-white rounded-4xl min-h-[200px] px-10 border border-gray-300'>
            <div className='flex gap-4 flex-wrap items-center'>
              <CiTimer size={50} color='green' />
              <div>
                <h1 className='text-3xl font-bold'>{coachData.yearsOfExperience}</h1>
                <p>Years of Experience</p>
              </div>
            </div>
            <div className='flex gap-4 flex-wrap items-center '>
              <AiOutlineFileDone size={50} color='green' />
              <div>
                <h1 className='text-3xl font-bold'>{classroomData?.meta.total}</h1>
                <p>Published classrooms</p>
              </div>
            </div>
          </div> */}
        {/* <div> */}
        {classrooms}
        {/* </div> */}
      </div>
      <div className='flex flex-col gap-4 bg-white p-5 rounded-xl w-full md:w-1/4 min-w-[300px] border border-gray-300 order-1 md:order-2'>
        <div className='flex justify-between items-center px-4'>
          <h1 className='font-bold text-xl'>About</h1>
          <CanIView component='coach.about.edit'>
            <Link to='./edit-about'>
              <AiFillEdit color='white' size={'30px'} className='bg-gray-500 rounded-full p-2' />
            </Link>
          </CanIView>
        </div>
        <hr />
        <p className='p-5 bg-gray-100 rounded-3xl break-all'>
          {coachData?.about ?? 'About text is not available!'}
        </p>
        {/* <div className='flex justify-around items-center px-4 bg-gray-500 rounded-3xl p-4'>
            <BsFacebook color='white' size={40} />
            <BsLinkedin color='white' size={40} />
            <BsYoutube color='white' size={40} />
          </div> */}
      </div>
      <Outlet />
    </div>
  );
};

export default CoachOverview;
