import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { persistor, store } from './store';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import './index.css';
import { ContextProvider } from './contexts/ContextProvider';
import { FormProvider } from './contexts/FormContext';
import { PersistGate } from 'redux-persist/integration/react';

import RouteInit from './components/route-init/route-init';
import CookieConsents from './components/cookie-consent';
import * as Sentry from '@sentry/react';

if (import.meta.env.VITE_SENTRY_ENVIRONMENT !== 'development') {
  Sentry.init({
    dsn: 'https://488758852ca2b7a78224c2f823e23e86@o4507863498293248.ingest.us.sentry.io/4507863503208448',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration(),
    ],
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT || 'default',
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // results in 25% of transactions being profiled (0.5*0.5=0.25)
    profilesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
ReactDOM.render(
  <ContextProvider>
    <FormProvider>
      <PersistGate loading={null} persistor={persistor}>
        <Provider store={store}>
          <Sentry.ErrorBoundary fallback={<p>An error has occured.</p>}>
            <RouteInit />
            <CookieConsents />
          </Sentry.ErrorBoundary>
        </Provider>
      </PersistGate>
    </FormProvider>
  </ContextProvider>,

  document.getElementById('root'),
);
