import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { ErrorMessage, Field, Form, Formik } from 'formik';
// import CustomCheckbox from "./CustomCheckbox";
// import CustomSelect from "./CustomSelect";
import { useNavigate } from 'react-router-dom';

import { useStateContext } from '../../../contexts/ContextProvider';

import google from '../../../assets/google.svg';
import facebook from '../../../assets/facebook.svg';

import { FormInput } from '../../../components/form-input/form-input';
import { useSignUpMutation } from '../../../slices/authApiSlice';
import { useAppDispatch } from '../../../store';
import { setCredentials } from '../../../slices/auth';
import { SubmitButton } from '../../../components/submit-button';
import { fanSchema } from './schema/fan-register.schema';

const FanRegisterForm = () => {
  const { isLoggedIn, setIsLoggedIn } = useStateContext();
  const { modalTerms, setModalTerms } = useStateContext();
  const { modalTermsCheckbox, setModalTermsCheckbox } = useStateContext();
  const dispatch = useAppDispatch();
  function onChangeCaptcha() {
    console.log('Captcha value:');
  }

  const navigate = useNavigate();

  const [signUp, { isLoading, isError, isSuccess }] = useSignUpMutation();
  const onSubmit = (values: any) => {
    const {
      city,
      confirmPassword,
      dateOfBirth,
      email,
      firstName,
      lastName,
      password,
      phoneNumber,
      postal,
      state,
      streetAddress,
    } = values;
    signUp({
      firstName,
      lastName,
      email,
      password,
      city,
      state,
      dateOfBirth: new Date(dateOfBirth).toISOString(),
      isVerified: 0,
      phoneNumber,
      zipCode: +postal,
      streetAddress,
      roles: 5,
    })
      .unwrap()
      .then((res) => dispatch(setCredentials(res)));
  };

  const formInputs = [
    {
      mainTitle: 'Fan Information',
      name: 'firstName',
      label: 'First Name',
      type: 'text',
      placeholder: 'Enter first name',
    },
    {
      mainTitle: 'Fan Information',
      name: 'lastName',
      label: 'Last Name',
      type: 'text',
      placeholder: 'Enter last name',
    },
    {
      mainTitle: 'Fan Information',
      name: 'streetAddress',
      label: 'Street Address',
      type: 'text',
      placeholder: 'Enter street address',
    },
    {
      mainTitle: 'Fan Information',
      name: 'city',
      label: 'City',
      type: 'text',
      placeholder: 'Enter city',
    },
    {
      mainTitle: 'Fan Information',
      name: 'state',
      label: 'State',
      type: 'text',
      placeholder: 'Enter state',
    },
    {
      mainTitle: 'Fan Information',
      name: 'postal',
      label: 'Zip code',
      type: 'text',
      placeholder: 'Enter zip code',
    },
    {
      mainTitle: 'Fan Information',
      name: 'email',
      label: 'Email Address',
      type: 'text',
      placeholder: 'Enter email address',
    },
    {
      mainTitle: 'Fan Information',
      name: 'phoneNumber',
      label: 'Phone Number',
      type: 'text',
      placeholder: 'Enter phone number',
    },
    {
      mainTitle: 'Fan Information',
      name: 'dateOfBirth',
      label: 'Date of birth',
      type: 'date',
      placeholder: 'Enter date of birth',
    },
    // {
    //   mainTitle: 'Fan Information',
    //   name: 'gender',
    //   label: 'Gender',
    //   type: 'text',
    //   placeholder: 'Enter your gender',
    // },
    {
      mainTitle: 'Security Information',
      name: 'password',
      label: 'Password',
      type: 'password',
      placeholder: 'Enter password',
    },
    {
      mainTitle: 'Security Information',
      name: 'confirmPassword',
      label: 'Confirm Password',
      type: 'password',
      placeholder: 'Re-enter password',
    },
  ];
  const initialValues: { [key: string]: string } = {};
  formInputs.forEach((input) => (initialValues[input.name] = ''));

  return (
    <Formik
      initialValues={{ ...initialValues, check: false }}
      validationSchema={fanSchema}
      onSubmit={onSubmit}
    >
      <Form className='max-w-3xl m-4 p-10 bg-white rounded shadow-xl'>
        <h1 className='flex items-center justify-center mt-1 text-2xl pt-1 mb-2'>
          Create a new Fan account
        </h1>
        {formInputs.map((input: any, i: number) => (
          <React.Fragment key={i}>
            {!formInputs[i - 1] || formInputs[i - 1]?.mainTitle !== input.mainTitle ? (
              <>
                <div className='flex flex-col'>
                  <div className='mb-2'>
                    <p className='text-gray-800 font-bold pt-6'>{input.mainTitle}</p>
                    <hr />
                  </div>
                  <div className='flex flex-wrap gap-5'>
                    {formInputs
                      .filter((inp1: any) => inp1.mainTitle === input.mainTitle)
                      .map((inp: any, i: number) => (
                        <div key={i} className='flex-1 min-w-[300px]'>
                          <FormInput {...inp} />
                        </div>
                      ))}
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </React.Fragment>
        ))}

        {/*reCaptcha */}
        <div className=' flex items-center justify-center mt-8'>
          <ReCAPTCHA
            sitekey='6LcPChQjAAAAADHSMNqPY8PasyUqNowhUPFYV9yB'
            onChange={onChangeCaptcha}
          />
        </div>
        <p className=' flex items-center justify-center mt-7'>
          <Field name='check' type='checkbox' className='mr-2' />

          {/* <input
            className='mr-2'
            type='checkbox'
            onClick={() => {
              setModalTermsCheckbox(!modalTermsCheckbox);
            }}
            checked={modalTermsCheckbox}
          /> */}
          <span
            onClick={() => {
              setModalTerms(true);
            }}
            className='font-italic'
          >
            I have read and agree to the{' '}
            <span className='text-black underline font-bold'> Terms and condition</span>
          </span>
        </p>
        <div className='flex justify-center p-2'>
          <ErrorMessage name='check' component='div' className='text-red-400 text-xs' />
        </div>
        <div className='flex justify-center '>
          <SubmitButton isLoading={isLoading} />
        </div>

        <div className='mt-1 grid grid-cols-3 items-center text-gray-400'>
          <hr className='border-gray-400]' />
          <p className='text-center text-sm'>OR</p>
          <hr className='border-gray-400' />
        </div>

        <button className='bg-white border py-2 w-full rounded-xl mt-5 flex justify-center items-center text-sm hover:scale-105 duration-300 text-[#002D74]'>
          <img className='pr-2' src={google}></img>
          Register with Google
        </button>
        <button className='bg-blue-600 border py-2 w-full rounded-xl mt-5 flex justify-center items-center text-white hover:scale-105 duration-300 '>
          <img className='pr-2' src={facebook}></img>
          Register with Facebook
        </button>
      </Form>
    </Formik>
  );
};

export default FanRegisterForm;
