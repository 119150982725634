import React, { useState } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useGetAllMatchesByTournamentQuery } from '../../slices/matchApiSlice';
import { MatchTeamAway } from '../match/components/match-team-away';
import { MatchTeamHome } from '../match/components/match-team-home';
import Pagination from '../../components/pagination';
import { dateToLocal } from '../../utils/dateFormatter';
import { SponsorPlacements, UserRoles } from '../../utils/constants';
import { SponsorRow } from '../../components/sponsor-row/sponsor-row';
import { useSelector } from 'react-redux';
import { selectUser } from '../../slices/auth';

enum MatchStatus {
  Upcoming = 0,
  Completed = 1,
  InProgress = 2,
  Overdue = 3,
  Cancelled = 4,
  Forfeit = 5,
  DoubleForfeit = 6,
  ByeMatch = 7,
  RescheduleRequested = 8,
  Benched = 9,
  UpcomingRescheduledFor = 10,
}

export function TournamentMatches({
  ht = '',
  htImage = '',
  aT = '',
  atImage = '',
  htScore = '',
  atScore = '',
  htSchool = '',
  atSchool = '',
  dateOfMatch = '',
  bgColor = '',
  isTournament = false,
}) {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    isLoading,
    error,
    data: matchData,
  } = useGetAllMatchesByTournamentQuery({
    id: params.id,
    query: searchParams.get('page') ? searchParams.get('page') : 1,
  });

  const matchStatusColors: { [key: number]: string } = {
    0: 'black',
    1: 'text-green-600',
    2: 'text-yellow-600',
    3: 'text-red-600',
    4: 'text-gray-600',
    5: 'text-red-600',
    6: 'text-red-600',
    7: 'text-blue-600',
    8: 'text-orange-500',
    9: 'text-purple-600',
    10: 'black',
  };
  const matchStatusNames: { [key: number]: string } = {
    0: 'UPCOMING',
    1: 'COMPLETED',
    2: 'IN PROGRESS',
    3: 'OVERDUE!',
    4: 'CANCELLED',
    5: 'FORFEITED',
    6: 'DOUBLE FORFEITED',
    7: 'BYE',
    8: 'RESCHEDULE REQUESTED!',
    9: 'BENCHED',
    10: 'UPCOMING (Rescheduled for)',
  };

  let currentDate = new Date(dateOfMatch);
  currentDate.setDate(currentDate.getDate() + 1);

  const hasTeams = ht.length > 0 && aT.length > 0;
  const customStyles = !hasTeams ? { backgroundColor: '', borderRadius: '16px' } : {};
  const user = useSelector(selectUser);
  const [part, setPart] = useState<boolean>(false);

  const isPart = (m: any) => {
    const home_team = m?.home_team;
    const away_team = m?.away_team;

    if (user?.roles === UserRoles.Ogma) {
      const homeOgmas = [
        home_team?.[0]?.organizations?.map((org: any) => org.user?.id),
        home_team?.[0]?.School?.organization?.user?.id,
        ...(home_team?.[0]?.organizations?.map((org: any) =>
          org.ogmas?.map((o: { id: number }) => o.id),
        ) ?? []),
        ...(home_team?.[0]?.School?.organization?.ogmas?.map((o: { id: number }) => o.id) ?? []),
      ].flat();

      const awayOgmas = [
        away_team?.[0]?.organizations?.map((org: any) => org.user?.id),
        away_team?.[0]?.School?.organization?.user?.id,
        ...(away_team?.[0]?.organizations?.map((org: any) =>
          org.ogmas?.map((o: { id: number }) => o.id),
        ) ?? []),
        ...(away_team?.[0]?.School?.organization?.ogmas?.map((o: { id: number }) => o.id) ?? []),
      ].flat();

      if (
        homeOgmas?.includes(user?.id) &&
        home_team?.[0]?.isBenched &&
        m?.status !== MatchStatus.ByeMatch
      ) {
        return (
          <span className={`text-[#B50303] font-[600] font-[Inter] text-[12px]`}>BENCHED</span>
        );
      }

      if (
        awayOgmas?.includes(user?.id) &&
        away_team?.[0]?.isBenched &&
        m?.status !== MatchStatus.ByeMatch
      ) {
        return (
          <span className={`text-[#B50303] font-[600] font-[Inter] text-[12px]`}>BENCHED</span>
        );
      }
    }

    if (user?.roles === UserRoles?.Student) {
      const homePart =
        home_team?.[0]?.activeMembers?.includes(user?.id) ||
        home_team?.[0]?.subMembers?.includes(user?.id);

      const awayPart =
        away_team?.[0]?.activeMembers?.includes(user?.id) ||
        away_team?.[0]?.subMembers?.includes(user?.id);

      if (homePart && home_team?.[0]?.isBenched && m?.status !== MatchStatus.ByeMatch) {
        return (
          <span className={`text-[#B50303] font-[600] font-[Inter] text-[12px]`}>BENCHED</span>
        );
      }

      if (awayPart && away_team?.[0]?.isBenched && m?.status !== MatchStatus.ByeMatch) {
        return (
          <span className={`text-[#B50303] font-[600] font-[Inter] text-[12px]`}>BENCHED</span>
        );
      }
    }

    if (
      user?.roles === UserRoles.Admin &&
      (home_team?.[0]?.isBenched || away_team?.[0]?.isBenched) &&
      m?.status !== MatchStatus.ByeMatch
    ) {
      return <span className={`text-purple-600 font-[600] font-[Inter] text-[12px]`}>BENCHED</span>;
    }
  };

  if (isLoading) return <p>Loading ... </p>;

  return (
    <div className='flex gap-2 flex-col relative p-10 bg-white w-full items-center'>
      <SponsorRow
        name={SponsorPlacements.TournamentMatches}
        extraClass={matchData?.data?.length ? 'mb-[2rem]' : ''}
      />
      {matchData?.data?.map((m: any, i: number) => (
        <React.Fragment key={i}>
          <Link
            to={`/match/${m.id}`}
            className={`flex gap-5 mb-5 flex-wrap justify-center  w-full  items-center relative ${bgColor} cursor-pointer container`}
          >
            <MatchTeamHome
              id={m.home_team[0]?.id}
              image={m.home_team[0] ? m.home_team[0].image : ''}
              team={m.home_team[0] ? m.home_team[0].teamName : ''}
              result={m.ht_score}
              school={htSchool}
              status={m.status}
              size='xl'
              win={m.ht_score > m.at_score}
              match_date={m.match_date}
              teamData={m.home_team[0]}
            />

            <span className='text-center w-[300px]'>
              <div className='span text-[10px] leading-tight flex flex-col h-fit top-0 left-0 right-0 m-auto items-center'>
                <div className='hidden md:flex flex-col justify-center h-full'>
                  <span
                    className={`text-center ${
                      user?.roles === UserRoles.Admin &&
                      (m?.home_team?.[0]?.isBenched || m?.away_team?.[0]?.isBenched) &&
                      m?.status === MatchStatus.ByeMatch
                        ? 'text-purple-600'
                        : (user?.roles === UserRoles.Ogma || user?.roles === UserRoles.Student) &&
                          m?.status === MatchStatus.ByeMatch &&
                          (m?.home_team?.[0]?.checkStatus || m?.away_team?.[0]?.checkStatus)
                        ? 'text-purple-600'
                        : matchStatusColors[m.status] || ''
                    }`}
                  >
                    <div className='span text-[10px] leading-tight flex flex-col h-fit top-0 left-0 right-0 m-auto items-center'>
                      <p
                        className={`font-bold text-sm ${
                          user?.roles === UserRoles.Admin &&
                          (m?.home_team?.[0]?.isBenched || m?.away_team?.[0]?.isBenched) &&
                          m?.status === MatchStatus.ByeMatch
                            ? ''
                            : (user?.roles === UserRoles.Ogma ||
                                user?.roles === UserRoles.Student) &&
                              (m?.home_team?.[0]?.checkStatus || m?.away_team?.[0]?.checkStatus) &&
                              m?.status === MatchStatus.ByeMatch
                            ? ''
                            : matchStatusColors[m.status] || ''
                        }`}
                      >
                        {new Date(
                          new Date(m.match_date).setDate(new Date(m.match_date).getDate() + 1),
                        ) < new Date(Date.now()) && m.status === MatchStatus.Upcoming ? (
                          <p className='text-sm font-bold text-red-800'>OVERDUE!</p>
                        ) : user?.roles === UserRoles.Admin &&
                          (m?.home_team?.[0]?.isBenched || m?.away_team?.[0]?.isBenched) &&
                          m?.status === MatchStatus.ByeMatch ? (
                          <span className={`text-[#B50303] font-bold font-[Inter] text-sm`}>
                            BENCHED
                          </span>
                        ) : (user?.roles === UserRoles.Ogma || user?.roles === UserRoles.Student) &&
                          (m?.home_team?.[0]?.checkStatus || m?.away_team?.[0]?.checkStatus) &&
                          m?.status === MatchStatus.ByeMatch ? (
                          <span className={`text-[#B50303] font-bold font-[Inter] text-sm`}>
                            BENCHED
                          </span>
                        ) : (
                          matchStatusNames[m.status]
                        )}
                      </p>
                      {isPart(m)}
                      <p className='font-bold text-sm'>
                        {dateToLocal(new Date(m.match_date), 'llll zz')}
                      </p>
                    </div>
                    VS
                  </span>
                </div>
              </div>
            </span>

            <MatchTeamAway
              id={m.away_team[0]?.id}
              image={m.away_team[0] ? m.away_team[0].image : ''}
              result={m.at_score}
              win={m.ht_score < m.at_score}
              school={atSchool}
              status={m.status}
              team={m.away_team[0] ? m.away_team[0].teamName : ''}
              size='xl'
              match_date={m.match_date}
              teamData={m.away_team[0]}
            />
          </Link>
        </React.Fragment>
      ))}
      <Pagination length={matchData?.data?.length} {...matchData?.meta} />
    </div>
  );
}
