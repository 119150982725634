import React from 'react';
import { AvatarImage } from '../avatar-image';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
export function GameInputs({
  setHScore,
  hScore,
  setAScore,
  aScore,
  hImage,
  hTeam,
  hSchool,
  aImage,
  aSchool,
  aTeam,
}: {
  setHScore: Function;
  hScore: number;
  setAScore: Function;
  aScore: number;
  hImage: string;
  hSchool: string;
  aImage: string;
  aSchool: string;
  hTeam: string;
  aTeam: string;
}) {
  return (
    <div className='flex justify-center gap-10'>
      <div className='flex items-center justify-evenly rounded-lg bg-gray-100 border p-1 px-3 w-[300px]'>
        <AvatarImage source={hImage} size='xl' />
        <div className='flex flex-col justify-center flex-1 min-w-[1px] items-center '>
          <h2 className='text-sm font-bold truncate w-full text-center'>{hTeam}</h2>
          <h3 className='text-xs truncate w-full text-center'>{hSchool}</h3>
        </div>
        <div className='flex gap-1 h-[80%] items-center p-2 border-2 bg-white rounded-xl'>
          <AiOutlineMinus
            onClick={() => setHScore((s: number) => (s > 0 ? s - 1 : s))}
            className='cursor-pointer hover:scale-110 '
          />
          <input
            onChange={(e) => setHScore(+e.target.value)}
            type='text'
            value={hScore}
            className='w-[20px] text-center font-bold focus:outline-none active:outline-none'
          />
          <AiOutlinePlus
            onClick={() => setHScore((s: number) => s + 1)}
            className='cursor-pointer hover:scale-110 '
          />
        </div>
      </div>
      <div className='flex items-center justify-evenly rounded-lg bg-gray-100 border p-1 px-3 w-[300px]'>
        <div className='flex gap-1 h-[80%] items-center p-2 border-2 bg-white rounded-xl'>
          <AiOutlineMinus
            onClick={() => setAScore((s: number) => (s > 0 ? s - 1 : s))}
            className='cursor-pointer hover:scale-110 '
          />
          <input
            type='text'
            onChange={(e) => setAScore(+e.target.value)}
            value={aScore}
            className='w-[20px] text-center font-bold focus:outline-none active:outline-none'
          />
          <AiOutlinePlus
            onClick={() => setAScore((s: number) => s + 1)}
            className='cursor-pointer hover:scale-110 '
          />{' '}
        </div>
        <div className='flex flex-col justify-center flex-1 items-center min-w-[1px] '>
          <h2 className='text-sm font-bold w-full text-center truncate'>{aTeam}</h2>
          <h3 className='text-xs w-full truncate text-center'>{aSchool}</h3>
        </div>
        <AvatarImage source={aImage} size='xl' />
      </div>
    </div>
  );
}
