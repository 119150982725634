import React, { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { OutsideClick } from '../../../components/outside-click';
import { useGetSchoolsEnabledCoachQuery } from '../../../slices/schoolsApiSlice';
import { selectUser } from '../../../slices/auth';
import { useSelector } from 'react-redux';
import {
  useChangeSchoolScheduledHoursMutation,
  useLazyGetSchoolsScheduledHoursQuery,
} from '../../../slices/classroomApiSlice';
import { Form, Formik, FormikValues } from 'formik';
import { FormInput } from '../../../components/form-input/form-input';
import { Link } from 'react-router-dom';
import { SubmitButton } from '../../../components/submit-button';
import { OgmaSchoolCoachHoursSchema } from './schema/ogma-school-coach-hours.schema';
import { timeFormatter } from '../../../utils/dateFormatter';

const ClassroomsScheduleHours = () => {
  const [selSchool, setSelSchool] = useState<number>();
  const param = useParams();
  const user = useSelector(selectUser);
  const formInputs = [
    {
      mainTitle: 'Time Information',
      name: 'start_time',
      label: 'Start Time',
      type: 'time',
      placeholder: 'Starting Time',
    },
    {
      mainTitle: 'Time Information',
      name: 'end_time',
      label: 'End Time',
      type: 'time',
      placeholder: 'End Time',
    },
  ];
  const {
    data: schData,
    isLoading: schIsLoading,
    isError: schIsError,
  } = useGetSchoolsEnabledCoachQuery(user?.organizations[0].id);

  const [
    getSchoolsScheduledHours,
    { data: schoolData, isLoading: schoolLoading, isError: schoolError },
  ] = useLazyGetSchoolsScheduledHoursQuery();
  useEffect(() => {
    if (!selSchool) return;
    getSchoolsScheduledHours({ classroom_id: Number(param.id), school_id: selSchool });
  }, [selSchool]);
  const [
    changeSchoolScheduledHours,
    { isLoading: changeLoading, isSuccess: changeSuccess, isError: changeError },
  ] = useChangeSchoolScheduledHoursMutation();
  function handleSubmit(values: FormikValues) {
    changeSchoolScheduledHours({
      class_id: Number(param.id),
      end_time: values.end_time,
      start_time: values.start_time,
      school_id: Number(selSchool),
    });
  }
  if (schIsLoading) return <p>Loading...</p>;
  return (
    <>
      <OutsideClick />
      <div className='fixed  top-0 left-0 right-0 z-10 w-auto my-6 mx-auto max-w-3xl h-fit max-h-[100vh] '>
        <div className='border-0 p-10 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none h-fit overflow-auto gap-2'>
          <h1 className='text-xl font-bold mr-5'>Manage School's Coaching Hours</h1>
          <h2>Please select the school, for which you are changing scheduled coaching hours!</h2>
          <div className={`resetInput `}>
            <label className='resetLabel group'>Select a school</label>
            <select
              className='resetFormInput'
              onChange={(e: ChangeEvent<HTMLSelectElement>) => setSelSchool(+e.target.value)}
            >
              <option value='' label='Please select an option' />
              {schData.data.length &&
                schData.data.map((o: any) => <option key={o.id} value={o.id} label={o.name} />)}
            </select>
          </div>
          {schoolLoading && <p>Loading...</p>}
          {selSchool && schoolData ? (
            <>
              {schoolData && Object.keys(schoolData).length ? (
                <>
                  <p>Current Scheduled Hours</p>
                  <p>
                    Start Time: <b>{timeFormatter(schoolData.start_date, 'new')}</b>
                  </p>

                  <p>
                    End Time: <b> {timeFormatter(schoolData.end_date, 'new')}</b>
                  </p>
                </>
              ) : (
                <p>You don't have scheduled hours!</p>
              )}
              <Formik
                validationSchema={OgmaSchoolCoachHoursSchema}
                // initialValues={initialValues}
                initialValues={{}}
                onSubmit={handleSubmit}
              >
                {(formik) => {
                  // console.log(formik);
                  return (
                    <Form>
                      <div className='relative p-6 flex-auto'>
                        {formInputs.map((input: any, i: number) => (
                          <React.Fragment key={i}>
                            {!formInputs[i - 1] ||
                            formInputs[i - 1]?.mainTitle !== input.mainTitle ? (
                              <>
                                <div className='flex flex-col'>
                                  <div className='flex flex-wrap gap-1'>
                                    {formInputs
                                      .filter((inp1: any) => inp1.mainTitle === input.mainTitle)
                                      .map((inp: any, i: any) => (
                                        <React.Fragment key={i}>
                                          <div
                                            className={
                                              inp.isTrue ? 'w-full ' : 'flex-1 min-w-[300px]'
                                            }
                                          >
                                            <FormInput {...inp} />
                                          </div>
                                        </React.Fragment>
                                      ))}
                                  </div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </React.Fragment>
                        ))}
                        {changeError && <p className='text-red-700'>Something went wrong!</p>}
                        {changeSuccess && (
                          <p className='text-green-700'>School Schedule Updated Successfully!</p>
                        )}
                      </div>
                      <div className='flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b'>
                        <Link
                          className='w-1/5 my-12 text-center py-1 bg-white text-black shadow-lg font-semibold rounded-lg'
                          to='../'
                        >
                          Cancel
                        </Link>
                        <SubmitButton isLoading={changeLoading} />
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default ClassroomsScheduleHours;
