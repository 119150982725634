import * as yup from 'yup';

export const editOrgInvoiceSchema = yup.object().shape({
  type: yup.string().required('Required'),
  // organization:yup.string().required("Required"),
  start_date: yup.string().required('Required'),
  end_date: yup.string().required('Required'),
  // numOfPlayers:yup.string().required("Required"),
  // price_student: yup.string().required('Required'),
  // payment_percentage:yup.string().required("Required"),
  total: yup.string().required('Required'),
  status: yup.string().required('Required'),
});
