import { FaArchive } from 'react-icons/fa';
import { selectToken, selectUser } from '../../slices/auth';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useAppDispatch } from '../../store';
import { SocketActions } from '../../utils/constants';

const DeleteChatMessageMatchAll = ({ msgId }: { msgId: any }) => {
  const token = useSelector(selectToken);
  const user = useSelector(selectUser);
  const param = useParams();
  // const [socket, setSocket] = useState<Socket>();
  // const url = import.meta.env.VITE_API_URL;

  // useEffect(() => {
  //   if (socket) return;
  //   setSocket(io(url ?? '', { extraHeaders: { token } }));
  // }, []);
  const dispatch = useAppDispatch();
  const handleDeleteChatMessage = () => {
    if (msgId) {
      const payload = {
        room: `${param.id}`,
        userRole: user.roles,
        id: msgId,
      };
      dispatch({
        type: SocketActions.DeleteMatchMessage,
        payload,
      });
    }
  };

  return (
    <>
      <div>
        <div className={'flex items-center absolute z-10 shadow-slate-100 border-2'}>
          <div
            className='w-[100px] bg-white p-2 text-center flex items-center cursor-pointer'
            onClick={handleDeleteChatMessage}
          >
            <FaArchive />
            <span className='ml-2'>Delete</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteChatMessageMatchAll;
