import { GaRecentVideoCard } from './ga-recent-video-card';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addToRecentVideos,
  selectRecentVideos,
  selectTwitchToken,
  setRecentVideos,
} from '../../../slices/gamearenaSlice';
import { useLazyGetRecentVideosQuery } from '../../../slices/twitchApiSlice';

const GameArenaRecentMatches = (props: any) => {
  // const [count, setCount] = useState(4);
  const [recentCursor, setRecentCursor] = useState<any>('first');
  const data = useSelector(selectRecentVideos);
  const dispatch = useDispatch();
  const [getRecentVideos, { data: rcData, isLoading: rcIsLoading }] = useLazyGetRecentVideosQuery();
  let recentStreamers = data.recentStreamers?.map((s: any) => s.twitch_id);
  let recentQuery = `?user_id=${recentStreamers?.join('&user_id=')}`;
  const twitchToken = useSelector(selectTwitchToken);
  useEffect(() => {
    console.log('recent cursor', recentCursor);
  }, [recentCursor]);
  useEffect(() => {
    if (!recentStreamers) return;
    getRecentVideos({
      query: `${recentQuery}`,
      pagination: '&first=4',
    })
      .unwrap()
      .then((res) => {
        dispatch(setRecentVideos(res.data));
        setRecentCursor(res.pagination);
      });
  }, [twitchToken]);

  const nextRecent = () => {
    getRecentVideos({
      query: `${recentQuery}`,
      limit: '&first=6',
      pagination: `&after=${recentCursor.cursor}`,
    })
      .unwrap()
      .then((res: any) => {
        dispatch(addToRecentVideos(res.data));
        setRecentCursor(res.pagination);
      });
  };
  useEffect(() => {
    console.log('rcData', rcData);
  }, [rcData]);
  if (!rcData) return <p>Loading...</p>;
  return (
    <>
      <div className='flex flex-col mx-4'>
        <h1 className='font-bold'>Recent Broadcasts</h1>

        <div className='flex flex-wrap gap-2'>
          {data.map((tw: any) => (
            <GaRecentVideoCard key={tw.id} {...tw} />
          ))}
        </div>
        <div className='flex w-full justify-center cursor-pointer'>
          {rcIsLoading ? (
            <p>Loading...</p>
          ) : (
            <span
              onClick={
                recentCursor === 'first' || Object.keys(recentCursor).length
                  ? () => nextRecent()
                  : () => null
              }
            >
              {recentCursor === 'first' || Object.keys(recentCursor).length
                ? 'Show More'
                : 'No more to show'}
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default GameArenaRecentMatches;
