import { useEffect, useState } from 'react';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import { Search } from '../../../components/search/search';
import TableContainer from '../../../components/table-container/table-container';
import { TableHead } from '../../../components/table-head';
import { DropdownMenuFilter } from '../../../components/ui/dropdown-menu/DropdownMenuFIlter';
import { useGetAllGamesQuery } from '../../../slices/gameApiSlice';
import { useGetAllTeamsQuery } from '../../../slices/teamsApiSlice';
import { filterByDropdown } from '../../../utils/filterByDropdown';
import TableTd from '../../../components/table-td/table-td';
import { NameAndAvatar } from '../../../components/name-and-avatar';
import { useStateContext } from '../../../contexts/ContextProvider';
import Pagination from '../../../components/pagination';
import { LoadingSceletonGameAllTeams } from '../../tournaments/routes/all/loading-sceleton-gameallteams';

const GameAllTeams = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const filterList = [{ title: 'name', property: 'teamName' }];
  const initialState = filterList[0].title;
  const [searchQuery, setSearchQuery] = useState(initialState);
  const [search, setSearch] = useState({ by: 'teamName', keyword: '' });
  const [selectedGames, setSelectedGames] = useState<string[]>([]);

  const handleGameFilterChange = (games: string[]) => {
    setSelectedGames(games);
    handleFilterChange('Game', games);
    console.log(games);
  };

  const configureListView = [
    {
      name: 'Name',
      active: true,
      property: 'teamName',
    },
    {
      name: 'Created',
      active: true,
      property: 'created',
    },
    {
      name: 'School',
      active: true,
      property: 'School',
    },
    {
      name: 'Number of Players',
      active: true,
      property: 'number_of_players',
    },
  ];
  const [listView, setListView] = useState(configureListView);

  const {
    data: gameData,
    isLoading: gameIsLoading,
    error: gameError,
  } = useGetAllGamesQuery({
    // query: searchQuery,
    // param: searchParams.get(searchQuery) ? searchParams.get(searchQuery) : "",
    // page: searchParams.get("page") ? searchParams.get("page") : 1,
  });

  const { data: teamData, isLoading } = useGetAllTeamsQuery({
    // query: searchQuery,
    // param: searchParams.get(searchQuery) ? searchParams.get(searchQuery) : "",
    // param: searchParams.getAll(searchQuery) ? searchParams.getAll(searchQuery) : "",

    query: { [searchQuery]: searchParams.get(searchQuery) ?? '' },
    param: Object.fromEntries(searchParams.entries()),
    page: searchParams.get('page') ? searchParams.get('page') : 1,
    // page: searchParams.get('page') ? searchParams.get('page') : 1,
  });

  const handleFilterChange = (query: string, params: string[]) => {
    // setSearchQuery((state) => ({ ...state, [query]: params.join(',') }));
    setSearchQuery('Games');
    searchParams.set(query, params.join(','));
    setSearchParams(searchParams);
  };

  useEffect(() => {
    console.log('use effect search query', searchQuery);
  }, [searchQuery]);

  // const histoyry = useHistory();
  const handleResetSearchParam = () => {
    setSearchParams(new URLSearchParams());
    setSearch({ by: 'teamName', keyword: '' });
    setSearchQuery(initialState);
    // history.push('/')
  };
  const { setShowModal } = useStateContext();

  if (isLoading || gameIsLoading) return <LoadingSceletonGameAllTeams />;

  return (
    <>
      <div className='flex flex-col relative p-10 bg-white w-full '>
        <div className='mb-5 flex justify-between'>
          <div className='flex gap-2 mt-2'>
            {gameData && (
              <DropdownMenuFilter
                title='Game'
                items={gameData.map((game: any, index: number) => ({
                  label: game.name,
                  defaultChecked: selectedGames.includes(game.name),
                }))}
                onClear={() => {
                  setSelectedGames([]);
                  handleResetSearchParam();
                }}
                onChange={(checkedItems) => {
                  const games = checkedItems
                    .filter((item) => item.defaultChecked)
                    .map((item) => item.label);
                  handleGameFilterChange(games);
                }}
              />
            )}
          </div>
          <div className='flex gap-2'>
            {/* <Link to='./new'>
              <HeaderButton type={'createTeam'} />
            </Link> */}
            {/* <Button
              size='lg'
              variant='outline'
              onClick={() => {
                setShowModal(true);
                navigate('./new');
              }}
            >
              <AiOutlineUsergroupAdd className='w-6 h-6' />
              New Team
            </Button> */}
            {/* <SearchGames
              setQuery={setSearchQuery}
              filterList={filterList}
              search={search}
              setSearch={setSearch}
            /> */}
            <Search
              setQuery={setSearchQuery}
              filterList={filterList}
              search={search}
              setSearch={setSearch}
            />
          </div>
        </div>
        <TableContainer>
          <TableHead
            oneMore
            tableList={listView
              .map((l) => l.name)
              .filter(
                (t) => listView.find((d) => d.name.toLowerCase() === t.toLowerCase())?.active,
              )}
          />
          <tbody className='bg-white divide-y divide-gray-200'>
            {teamData.data
              .filter((t: any) => !t.isArchived)
              .filter((t: any) => t[search.by].toLowerCase().includes(search.keyword.toLowerCase()))
              .map((team: any, ind: number) => (
                <tr
                  className='cursor-pointer'
                  onClick={() => navigate(`../../teams/view/${team.id}`)}
                  key={ind}
                >
                  {filterByDropdown(team, listView).map((p: any, i) =>
                    p === 'teamName' ? (
                      <TableTd key={i}>
                        <NameAndAvatar name={team.teamName} image={team.image} isAlt={false} />
                      </TableTd>
                    ) : p === 'created' ? (
                      <TableTd key={i}>{new Date(team[p]).toDateString()}</TableTd>
                    ) : p === 'School' ? (
                      <TableTd key={i}>{team.School ? team.School.name : '-'}</TableTd>
                    ) : (
                      <TableTd key={i}>{team[p]}</TableTd>
                    ),
                  )}
                  <TableTd>
                    {/* {Math.floor(Math.random() * 15) + 1} */}
                    {team.User.length}
                  </TableTd>
                  <TableTd>
                    <></>
                  </TableTd>
                  {/* <TableTd>
                    <div className='inline-block text-left'>
                      <button
                        type='button'
                        className='inline-flex w-full justify-center rounded-md bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50'
                        id='menu-button'
                        aria-expanded='true'
                        aria-haspopup='true'
                      >
                        <BsThreeDots />
                      </button>
                    </div>

                    <div className={openList === team.id ? '' : 'hidden'}>
                      <div
                        className='listViewMenu'
                        role='menu'
                        aria-orientation='vertical'
                        aria-labelledby='menu-button'
                      >
                        <div className='py-1' role='none'>
                          <DropdownItem id={team.id} setFunc={setOpenList} />
                        </div>
                      </div>
                    </div>
                  </TableTd> */}
                </tr>
              ))}
          </tbody>
        </TableContainer>
        <Outlet />
        <Pagination length={teamData.data.length} {...teamData.meta} />
      </div>
    </>
  );
};

export default GameAllTeams;
