import React from 'react';
export function ViewClassroomChatTab({
  tab,
  setTab,
  title,
  id,
}: {
  tab: number;
  setTab: Function;
  title: string;
  id: number;
}) {
  return (
    <div
      className={`classroom__chat--tab w-full ${tab === id ? 'active' : ''}`}
      onClick={() => setTab(id)}
    >
      {title}
    </div>
  );
}
