import { Formik } from 'formik';
import { OutsideClick } from '../../../components/outside-click';
import ModalContent from '../../../components/modal-content';
import { useGetAllSupportCategoryQuery } from '../../../slices/supportCategoryApiSlice';
import { useCreateSubcategoryMutation } from '../../../slices/supportSubcategoryApiSlice';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router';
import { SupportSubCategoryValidationSchema } from '../../authentication/yupschema';
import { CustomError } from '../../../utils/custom-types';

const NewSubCategoryModal = () => {
  const [createSubCategory, { error, isError, isSuccess }] = useCreateSubcategoryMutation();
  const { data: categories, isLoading } = useGetAllSupportCategoryQuery({
    optional: 1,
  });
  const [optionValues, setOptionValues] = useState<any>([]);
  const priorityLevels = [
    {
      id: '1',
      name: 'P1',
    },
    {
      id: '2',
      name: 'P2',
    },
    {
      id: '3',
      name: 'P3',
    },
  ];

  const formInputs = [
    {
      name: 'name',
      label: 'Subcategory',
      placeholder: 'Subcategory',
    },
    {
      name: 'supportCategoryId',
      label: 'Category',
      isSelect: true,
      options: categories,
    },
    // {
    //     name: 'responseLevel',
    //     label: 'Response level',
    //     isSelect: true,
    //     options: [
    //         {
    //             id: 1, name: "Emergency",
    //         },
    //         {
    //             id: 2, name: "Urgent",
    //         },
    //         {
    //             id: 3, name: "Normal"
    //         }
    //     ]
    // },
    // {
    //     name: 'severityLevel',
    //     label: 'Severity level',
    //     isSelect: true,
    //     options: optionValues?.responseLevel === '1' ? [
    //         {
    //             id: 1,
    //             name: 'Outage'
    //         },
    //         {
    //             id: 2,
    //             name: 'Critical'
    //         }
    //     ] : optionValues?.responseLevel === '2' ? [
    //         {
    //             id: 3, name: "Urgent",
    //         },
    //     ] : optionValues?.responseLevel === '3' ? [
    //         {
    //             id: 4,
    //             name: 'Important'
    //         },
    //         {
    //             id: 5,
    //             name: 'Monitor'
    //         },
    //         {
    //             id: 6,
    //             name: 'Informational'
    //         },
    //     ] : []
    // },
    {
      name: 'priorityLevel',
      label: 'Priority level',
      isDisabled: false,
      isSelect: true,
      options: priorityLevels?.map((priority: any) => ({
        id: priority.id,
        name: priority.name,
        selected: 1,
      })),
      // value: generatePriority(optionValues.responseLevel, optionValues.severityLevel)
    },
  ];

  const initialValues: { [key: string]: string } = {};
  formInputs.forEach((input) => (initialValues[input.name] = ''));

  const onSubmit = (values: any) => {
    const updatedValues = {
      ...values,
      // responseLevel: parseInt(values.responseLevel, 10),
      // severityLevel: parseInt(values.severityLevel, 10),
      priorityLevel: parseInt(values.priorityLevel),
      supportCategoryId: parseInt(values.supportCategoryId, 10),
    };
    createSubCategory(updatedValues)
      .unwrap()
      .then((resp) => {
        console.log('resp after submiting subcategory modal', resp);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  if (isSuccess) return <Navigate to='../' />;
  if (isLoading) return <p>Loading...</p>;

  return (
    <>
      <OutsideClick />
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={SupportSubCategoryValidationSchema}
      >
        {({ values }) => {
          useEffect(() => {
            if (!values) return;

            setOptionValues(values);
          }, [values]);
          return (
            <ModalContent
              formInputs={formInputs}
              isLoading={false}
              extraClass='h-fit'
              name='Create Subcategory'
              isError={isError}
              error={isError ? (error as CustomError).data.message : ''}
            />
          );
        }}
      </Formik>
    </>
  );
};

export default NewSubCategoryModal;
