import React, { useState } from 'react';
import { AvatarImage } from '../../../components/avatar-image';
import avatar from '../../../assets/g-avatar.jpg';
import { BsFillArrowLeftCircleFill } from 'react-icons/bs';
import { useGetAllStreamersQuery } from '../../../slices/gamearenaApiSlice';
import { useGetAllFollowedStreamersQuery } from '../../../slices/userApiSlice';
import { Link } from 'react-router-dom';
import { FollowedStreamers } from '../components/followed-streamers';

const GameArenaFollow = () => {
  const [open, setOpen] = useState(true);
  // function handleMenuClick(){
  //   setIsOpen(!isOpen)
  // }
  const { data, isError, isLoading, isSuccess } = useGetAllFollowedStreamersQuery('');
  const links = [
    {
      name: 'Forest Lake High',
      icon: avatar,
    },
    {
      name: 'Waterfall High',
      icon: avatar,
    },
    {
      name: 'Southview Academy',
      icon: avatar,
    },
  ];
  if (isLoading) return <p> Loading ...</p>;
  return (
    <div className='flex flex-col lg:flex-row justify-between gap-5 border-r-4 rounded-xl'>
      <div
        className={` ${open ? 'w-72' : 'w-16 '
          } bg-white h-[calc(97vh-90px)] p-3 px-3 border-0 border-gray-200 rounded-xl  pt-8 relative duration-300`}
      >
        <div
          className={`absolute cursor-pointer -right-0 top-8 w-7 mr-4
          rounded-full  ${!open && 'rotate-180 mr-5'}`}
        >
          <BsFillArrowLeftCircleFill onClick={() => setOpen(!open)} size='25' color='gray' />
        </div>

        <div className='flex gap-x-4 items-center'>
          {/* <img
            src="./src/assets/logo.png"
            className={`cursor-pointer duration-500 ${
              open && "rotate-[360deg]"
            }`}
          /> */}
          <h1
            className={`text-gray-500 origin-left font-medium text-xl  duration-200 ${!open && ' hidden '
              }`}
          >
            Followed Streamers
          </h1>
        </div>
        <FollowedStreamers open={open} />
      </div>
    </div>
  );
};

export default GameArenaFollow;
