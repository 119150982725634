import { Outlet } from 'react-router';
import SupportTicketsTypeTab from './support-tickets-type-tab';

const OpenTicketsType = () => {
    return (
        <div className='w-full mx-auto pb-2 mt-2 flex overflow-auto max-w-full flex-col flex-1 rounded-xl bg-white'>
            <div className='flex justify-end items-center mb-3'>
                <SupportTicketsTypeTab />
            </div>
            <Outlet />
        </div>
    );
};

export default OpenTicketsType;