import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectMatchData } from '../slices/matchSlice';
import { selectUser } from '../slices/auth';

const useMatchStudentTeamId = () => {
  const matchData = useSelector(selectMatchData);
  const user = useSelector(selectUser);
  const [teamId, setTeamId] = useState<[string, number][]>([]);
  const [teamIndex, setTeamIndex] = useState<number>();
  useEffect(() => {
    if (!user || !matchData) return;
    const arr = user?.team?.map((t: any, i: number) => {
      if (t.id === matchData.home_team?.[0]?.id) return ['home', t.id];
      if (t.id === matchData.away_team?.[0]?.id) return ['away', t.id];
    });
    setTeamId(
      arr.filter((a: any) => a).sort((a: [string, number], b: [string, number]) => a[0] !== 'home'),
    );
  }, [user, matchData]);
  return [teamId, teamIndex] as const;
};

export default useMatchStudentTeamId;
