import { AiFillDelete, AiFillEdit, AiOutlineDelete } from 'react-icons/ai';
import { FaArchive } from 'react-icons/fa';
import { GrView } from 'react-icons/gr';
import { IoDuplicate } from 'react-icons/io5';
import viewInvoice from '../../../assets/view-invoice.svg';
import updateInvoice from '../../../assets/update-invoice.svg';
import { Link, useLocation } from 'react-router-dom';
import { OutsideClick } from '../../../components/outside-click';
import { PiChairFill, PiPlugsConnectedBold } from 'react-icons/pi';
import { MdDelete } from 'react-icons/md';

function DropdownItem({
  type,
  id,
  setFunc,
  tournament,
  season,
  invoices,
  classrooms,
  noView,
  justView,
  viewLink,
  hasDelete,
  hasEdit,
  hasConnectTicket,
  hideArchive,
  ticketComment,
  editAndArchive,
  justEdit,
  isBenched,
  isTeam,
}: {
  type?: string;
  id?: number;
  setFunc: Function;
  season?: boolean;
  classrooms?: boolean;
  invoices?: boolean;
  tournament?: boolean;
  noView?: boolean;
  justView?: boolean;
  viewLink?: string;
  hasDelete?: boolean;
  hasEdit?: boolean;
  hasConnectTicket?: boolean;
  hideArchive?: boolean;
  ticketComment?: boolean;
  editAndArchive?: boolean;
  justEdit?: boolean;
  isBenched?: boolean;
  isTeam?: boolean;
}) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  if (invoices)
    return (
      <>
        <OutsideClick noBg setFunc={() => setFunc(false)} />
        <div className='z-10 relative overflow-hidden'>
          <Link to={`/invoices/view/${id}`}>
            <div className='flex cursor-pointer text-left text-gray-700  px-4 py-2 text-sm z-10'>
              <div className='self-center pr-3'>
                <img src={viewInvoice} />
              </div>
              <p className='text-left text-gray-700 text-sm'>View Invoice</p>
            </div>
          </Link>
          <hr />
          <Link onClick={() => setFunc(false)} to={`./edit/${id}`}>
            <div className='flex cursor-pointer text-left text-gray-700  px-4 py-2 text-sm z-10'>
              <div className='self-center pr-3'>
                <img src={updateInvoice} />
              </div>
              Edit Invoice
            </div>
          </Link>
        </div>
      </>
    );
  if (ticketComment)
    return (
      <>
        <OutsideClick noBg setFunc={() => setFunc(false)} />
        <div className='z-10 absolute w-full bg-white rounded-lg h-max-full select-none overflow-hidden'>
          <Link
            className='py-2 px-4 flex items-center gap-2 text-sm bg-gray-100  hover:bg-slate-50 text-gray-800'
            onClick={() => setFunc(false)}
            to={`./delete-comment/${id}`}
          >
            <AiFillDelete />
            Delete
          </Link>
        </div>
      </>
    );
  if (editAndArchive)
    return (
      <>
        <OutsideClick noBg setFunc={() => setFunc(false)} />
        <div className='z-10 absolute w-full bg-white rounded-lg h-max-full select-none overflow-hidden'>
          <Link
            className='py-2 px-4 flex items-center gap-2 text-sm text-gray-700 hover:bg-slate-50'
            onClick={() => setFunc(false)}
            to={`./edit/${id}`}
          >
            <AiFillEdit />
            Edit
          </Link>
          <Link
            className='py-2 px-4 flex items-center gap-2 text-sm  hover:bg-slate-50 text-red-800'
            onClick={() => setFunc(false)}
            to={`./archive/${id}`}
          >
            <FaArchive />
            Archive
          </Link>
        </div>
      </>
    );
  if (justEdit)
    return (
      <>
        <OutsideClick noBg setFunc={() => setFunc(false)} />
        <div className='z-10 absolute w-full bg-white rounded-lg h-max-full select-none overflow-hidden'>
          <Link
            className='py-2 px-4 flex items-center gap-2 text-sm text-gray-700 hover:bg-slate-50'
            onClick={() => setFunc(false)}
            to={`./edit/${id}`}
          >
            <AiFillEdit />
            Edit
          </Link>
        </div>
      </>
    );
  if (isTeam)
    return (
      <>
        <OutsideClick noBg setFunc={() => setFunc(false)} />
        <div className='z-10 absolute w-full bg-white rounded-lg h-max-full select-none overflow-hidden'>
          <Link
            className='py-2 px-4 flex items-center gap-2 text-sm text-gray-700 hover:bg-slate-50'
            onClick={() => setFunc(false)}
            to={`./view/${id}`}
          >
            <GrView />
            View
          </Link>
          <hr />
          <Link
            className='py-2 px-4 flex items-center gap-2 text-sm text-gray-700 hover:bg-slate-50'
            onClick={() => setFunc(false)}
            to={`./edit/${id}`}
          >
            <AiFillEdit />
            Edit
          </Link>
          <hr />
          {isBenched ? (
            <Link
              className='py-2 px-4 flex items-center gap-2 text-sm text-gray-700 hover:bg-slate-50'
              onClick={() => setFunc(false)}
              to={`./unbench/${id}`}
            >
              <PiChairFill size={18} className='text-red-800' />
              Unbench
            </Link>
          ) : (
            <Link
              className='py-2 px-4 flex items-center gap-2 text-sm text-gray-700 hover:bg-slate-50'
              onClick={() => setFunc(false)}
              to={`./bench/${id}`}
            >
              <PiChairFill size={18} className='text-red-800' />
              Bench
            </Link>
          )}
          <hr />
          <Link
            className='py-2 px-4 flex items-center gap-2 text-sm  hover:bg-slate-50 text-red-800'
            onClick={() => setFunc(false)}
            to={`./archive/${id}`}
          >
            <FaArchive />
            Archive
          </Link>
        </div>
      </>
    );
  return (
    <>
      <OutsideClick noBg setFunc={() => setFunc(false)} />
      <div className='z-10 absolute w-full bg-white rounded-lg h-max-full select-none overflow-hidden'>
        {noView ? (
          <></>
        ) : (
          <>
            {tournament && !justView ? (
              <>
                <Link
                  onClick={() => setFunc(false)}
                  to={`./duplicate/${id}`}
                  className='py-2 px-4 flex items-center gap-2 text-sm text-gray-700 hover:bg-slate-50'
                >
                  <IoDuplicate />
                  Duplicate
                </Link>
                <hr />
              </>
            ) : (
              <></>
            )}
            <Link
              className='py-2 px-4 flex items-center gap-2 text-sm text-gray-700 hover:bg-slate-50'
              onClick={() => setFunc(false)}
              to={
                viewLink
                  ? viewLink
                  : classrooms
                  ? `/classrooms/view/${id}`
                  : tournament
                  ? `/tournaments/view/${id}/`
                  : season
                  ? `/seasons/view/${id}`
                  : `./view/${id}/`
              }
            >
              <GrView />
              View
            </Link>
            <hr />
          </>
        )}
        {!justView && hasConnectTicket && (
          <>
            <Link
              className='py-2 px-4 flex items-center gap-2 text-sm  hover:bg-slate-50 text-gray-800'
              onClick={() => setFunc(false)}
              to={`./connect/${id}`}
            >
              <PiPlugsConnectedBold />
              Connect ticket
            </Link>
          </>
        )}
        {!justView && !hasDelete ? (
          <>
            <Link
              className='py-2 px-4 flex items-center gap-2 text-sm text-gray-700 hover:bg-slate-50'
              onClick={() => setFunc(false)}
              to={{
                pathname: `./edit/${id}`,
                search: queryParams.toString(),
              }}
            >
              <AiFillEdit />
              Edit
            </Link>
            <hr />
          </>
        ) : (
          <></>
        )}
        {!justView && hasEdit ? (
          <>
            <Link
              className='py-2 px-4 flex items-center gap-2 text-sm text-gray-700 hover:bg-slate-50'
              onClick={() => setFunc(false)}
              to={`./edit/${id}`}
            >
              <AiFillEdit />
              Edit
            </Link>
            <hr />
          </>
        ) : (
          <></>
        )}
        {!justView && !hasDelete ? (
          <>
            <Link
              className='py-2 px-4 flex items-center gap-2 text-sm  hover:bg-slate-50 text-red-800'
              onClick={() => setFunc(false)}
              to={`./archive/${id}`}
            >
              <FaArchive />
              Archive
            </Link>
          </>
        ) : !justView && hideArchive ? null : (
          <>
            <Link
              className='py-2 px-4 flex items-center gap-2 text-sm  hover:bg-slate-50 text-red-800'
              onClick={() => setFunc(false)}
              to={`./archive/${id}`}
            >
              <FaArchive />
              Archive
            </Link>
          </>
        )}
        {hasDelete && (
          <>
            <Link
              className='py-2 px-4 flex items-center gap-2 text-sm  hover:bg-slate-50 text-red-800'
              onClick={() => setFunc(false)}
              to={`./delete/${id}`}
            >
              <MdDelete />
              Delete
            </Link>
          </>
        )}
      </div>
    </>
  );
}

export default DropdownItem;
