import { OrganizationStatus } from '../utils/constants';
import { dateToLocal } from '../utils/dateFormatter';

export class OrganizationModel {
  id?: number;
  name;
  street_address;
  city;
  state;
  email;
  zipCode;
  phone_number;
  status;
  updatedAt?;
  //   status_name;
  tags;
  image?: string;
  banner?: string;
  constructor(organization: any) {
    if (organization.hasOwnProperty('id')) this.id = organization.id;
    if (organization.hasOwnProperty('image')) this.image = organization.image ?? '';
    if (organization.hasOwnProperty('name')) this.name = organization.name;
    if (organization.hasOwnProperty('street_address'))
      this.street_address = organization.street_address;
    if (organization.hasOwnProperty('city')) this.city = organization.city;
    if (organization.hasOwnProperty('email')) this.email = organization.email;
    if (organization.hasOwnProperty('state')) this.state = organization.state;
    if (organization.hasOwnProperty('zipCode')) this.zipCode = organization.zip_code;
    if (organization.hasOwnProperty('phone_number')) this.phone_number = organization.phone_number;
    if (organization.hasOwnProperty('updated_at'))
      this.updatedAt = dateToLocal(organization?.updated_at, 'MMMM D, YYYY');
    if (organization.hasOwnProperty('status')) {
      this.status = OrganizationStatus[organization.status];
      // this.status_name = OrganizationStatus[this.status];
    }
    if (organization.hasOwnProperty('tags')) this.tags = organization.tags;
  }
  getOptionName() {
    return [this.name, this.state].filter((o) => o).join(' / ');
  }
}
