import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import { useParams, useSearchParams } from 'react-router-dom';
import { useGetSeasonsByGameIdQuery } from '../../../../slices/seasonApiSlice';
import { SeasonGridCard } from '../all/routes/components/season-grid-card';

export const SeasonsByGame = () => {
  const param = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, isLoading, isError } = useGetSeasonsByGameIdQuery({
    id: param.id,
    page: searchParams.get('page') ? searchParams.get('page') : 1,
  });
  function goPrevPage() {
    setSearchParams({ page: `${data.meta.prev}` });
  }
  function goNextPage() {
    setSearchParams({ page: `${data.meta.next}` });
  }

  if (isLoading) return <p>Loading...</p>;
  //   if (isError) return <Navigate to="/went-wrong" />;
  return (
    <div className='flex justify-between flex-col h-[calc(100vh-85px)] rounded-xl w-full bg-white p-5 border-2'>
      <div className='flex flex-col gap-5 overflow-hidden'>
        <h2 className='font-bold text-xl'>Seasons sorted by game {data.data[0].Game.name}</h2>
        <div className='flex w-full gap-5 flex-wrap overflow-auto'>
          {data.data.map((d: any) => (
            <SeasonGridCard key={d.id} {...d} justView />
          ))}
        </div>
      </div>
      <div className='flex w-full justify-between'>
        <span>
          {data.data.length} of {data.meta.total} results
        </span>
        <div className='flex gap-2 items-center'>
          <span>
            {data.meta.currentPage} of {data.meta.lastPage}
          </span>
          <div className='cursor-pointer' onClick={data.meta.prev ? goPrevPage : () => null}>
            <BsArrowLeft color={data.meta.prev ? '#b50303' : 'grey'} />
          </div>
          <div className='cursor-pointer' onClick={data.meta.next ? goNextPage : () => null}>
            <BsArrowRight color={data.meta.next ? '#b50303' : 'grey'} />
          </div>
        </div>
      </div>
    </div>
  );
};
