import { Substitudes } from './components/substitudes';
import { CurrentTeam } from './components/current-team';
import { HTMLProps, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { DndContext, DragEndEvent, useDroppable } from '@dnd-kit/core';
import { useGetTeamByIdQuery } from '../../../../../slices/teamsApiSlice';
import { useChangeUsersTeamMutation } from '../../../../../slices/userApiSlice';
import CanIView from '../../../../../components/can-i-view/can-i-view';
import { SponsorRow } from '../../../../../components/sponsor-row/sponsor-row';
import { SeasonProgressStatus, SponsorPlacements, UserRoles } from '../../../../../utils/constants';
import useErrorToast from '../../../../../hooks/use-error-toast';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../../../../../components/ui/tooltip/Tooltip';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../../slices/auth';

function TeamMembers() {
  useDroppable({
    id: 'droppable',
  });
  const [editMode, setEditMode] = useState(false);

  const param = useParams();
  const user = useSelector(selectUser);
  const { data: teamData } = useGetTeamByIdQuery(
    { id: param.id, query: 'match' },
    { skip: !param?.id },
  );
  const btnList = [
    {
      title: 'Add',
      link: './add',
      edit: false,
    },
    {
      title: 'Edit',
      onClick: () => setEditMode(true),
      edit: false,
    },
    {
      title: 'Save',
      onClick: () => setEditMode(false),
      edit: true,
    },
    {
      title: 'Disband',
      link: './disband',
      edit: false,
      className: 'bg-fen-red text-white',
    },
  ];
  const [
    changeUsersTeam,
    { data: chData, isLoading: chIsLoading, isError: chIsError, error: chError },
  ] = useChangeUsersTeamMutation();
  const handleDragEnd = (event: DragEndEvent) => {
    const { over, active } = event;
    if (!over) return;
    const type =
      typeof over.id === 'string'
        ? teamData.subMembers.includes(active.id)
          ? 'substitudes'
          : 'active'
        : false;

    const subsLength = teamData.subMembers.filter(
      (u: number) => u === over.id || u === active.id,
    ).length;
    const subsCheck = teamData.subMembers.includes(active.id) && subsLength === 2;
    const activeLength = teamData.activeMembers.filter(
      (u: number) => u === over.id || u === active.id,
    ).length;
    const activeCheck = teamData.activeMembers.includes(active.id) && activeLength === 2;
    const isSameTeam = !type && (activeCheck || subsCheck);
    if ((!type || type !== over.id) && over.id !== active.id && !isSameTeam)
      changeUsersTeam({
        id: param.id,
        body: { studentId: active.id, team: over.id },
        current: teamData,
      });
  };
  useErrorToast(chIsError, chError);
  return (
    <>
      <SponsorRow name={SponsorPlacements.TeamsMembers} />
      <DndContext onDragEnd={handleDragEnd}>
        <div className='flex flex-col gap-2 listViewContainer'>
          {!teamData?.readPermission ? (
            <div className='flex justify-end gap-2 pr-10'>
              {btnList
                .filter((b) => b.edit === editMode)
                .map(({ edit, ...b }, i) =>
                  user.roles !== UserRoles.Admin &&
                  teamData.Game.active_limit === 1 &&
                  teamData.activeMembers.length === 1 &&
                  teamData.season.filter(
                    (season: any) => season.status === SeasonProgressStatus.Ongoing,
                  ).length > 0 ? (
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger>
                          <CanIView key={i} component={`team.members.${b.title.toLowerCase()}`}>
                            <button className='flex max-h-[20px] p-5 justify-center items-center cursor-pointer rounded-xl bg-gray-500 text-gray-300'>
                              {b.title}
                            </button>
                          </CanIView>
                        </TooltipTrigger>
                        <TooltipContent>
                          No changes can be made for teams with one player once a seasson starts.
                          Please contact a Fenworks Admin.
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  ) : b.link ? (
                    <CanIView key={i} component={`team.members.${b.title.toLowerCase()}`}>
                      <Link className={`viewBtn ${b.className ?? ''}`} to={b.link}>
                        {b.title}
                      </Link>
                    </CanIView>
                  ) : (
                    <CanIView key={i} component={`team.members.${b.title.toLowerCase()}`}>
                      <div
                        className={`viewBtn ${b.className ?? ''}`}
                        {...(b as HTMLProps<HTMLDivElement>)}
                      >
                        {b.title}
                      </div>
                    </CanIView>
                  ),
                )}
            </div>
          ) : null}
          <CurrentTeam
            editMode={editMode}
            captain={teamData.captain}
            userList={teamData.User.filter((u: any) => teamData.activeMembers.includes(u.id))}
            usersLength={teamData.activeMembers.filter((u: number) => u)}
            limit={teamData.Game.active_limit}
          />
          <Substitudes
            editMode={editMode}
            userList={teamData.User.filter((u: any) => teamData.subMembers.includes(u.id))}
            usersLength={teamData.subMembers.filter((u: number) => u)}
            team={teamData}
          />
        </div>
      </DndContext>
      <Outlet />
    </>
  );
}

export default TeamMembers;
