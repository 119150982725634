import { ChangeEvent, useEffect, useState } from 'react';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { useSearchParams } from 'react-router-dom';
import { OutsideClick } from '../outside-click';
export function Search({
  setSearch,
  filterList,
  search,
  setQuery,
  setParam,
  extraClass,
}: {
  setSearch: Function;
  filterList?: { title: string; property: string }[];
  search: { by: string; keyword: string };
  setQuery?: Function;
  setParam?: Function;
  extraClass?: string;
}) {
  const [show, setShow] = useState(false);
  const [searchQuery, setSearchQuery] = useState(filterList ? filterList[0].title : '');
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState<string>();
  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    if (setQuery) {
      setQuery(search.by);
    }
    setSearchQuery(search.by);
    setValue(e.target.value);
  }

  useEffect(() => {
    if (value === undefined) return;
    const debounce = setTimeout(() => {
      const params = new URLSearchParams();
      if (value.trim() !== '') {
        params.set(search.by, value.trim());
      } else {
        params.delete(search.by);
      }
      setSearchParams(params);
    }, 500);
    return () => clearTimeout(debounce);
  }, [value, search.by]);
  useEffect(() => {
    if (!value) return;
    if (!searchParams.get(searchQuery)) setValue('');
  }, [searchParams.get(searchQuery)]);
  return (
    <>
      <div className='flex items-center'>
        <label htmlFor='simple-search' className='sr-only'>
          Search
        </label>
        <div
          className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 relative flex ${
            extraClass ? extraClass : ''
          }`}
        >
          <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
            <svg
              aria-hidden='true'
              className='w-5 h-5 text-gray-500 dark:text-gray-400'
              fill='currentColor'
              viewBox='0 0 20 20'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                d='M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z'
                clipRule='evenodd'
              ></path>
            </svg>
          </div>
          <input
            onChange={handleChange}
            type='text'
            id='simple-search'
            placeholder={`Search by ${
              filterList?.filter((f) => f.property === search.by)[0]?.title
            }`}
            className='bg-transparent h-full outline-none'
            defaultValue={value}
          />
          <RiArrowDropDownLine
            className='cursor-pointer'
            onClick={() => setShow((s) => !s)}
            size={20}
          />
          {show && (
            <>
              <OutsideClick noBg setFunc={() => setShow(false)} />
              <div className='absolute w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg left-0 top-[100%] p-1 flex flex-col gap-2 z-20'>
                {filterList?.map((l) => (
                  <span
                    className={`cursor-pointer capitalize hover:font-medium ${
                      search.by === l.property ? 'font-medium' : ''
                    }`}
                    key={l.property}
                    onClick={() => {
                      setSearch({ by: l.property, keyword: '' });
                      setSearchQuery(l.property);
                      if (setQuery) setQuery(l.property);
                      setShow(false);
                    }}
                  >
                    {l.title}
                  </span>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
