import React from 'react';
import ModalContent from '../../../components/modal-content';
import { useParams } from 'react-router';
import { useGetMatchChatDisputeByIdQuery } from '../../../slices/matchApiSlice';
import { OutsideClick } from '../../../components/outside-click';

const ViewImage = () => {
  const params = useParams();
  const { data, isLoading } = useGetMatchChatDisputeByIdQuery({ disputeId: params.disputeId });
  return (
    <>
      <OutsideClick />
      <div className='w-fit h-fit fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50'>
        <div className='bg-white p-4 rounded-lg relative'>
          <ModalContent name='Dispute Image' hideFooter={true}>
            {isLoading ? (
              <p>Loading...</p>
            ) : (
              <img
                src={data?.url}
                alt='Screenshot'
                className='max-w-full max-h-full border-2 border-black m-2 p-2'
              />
            )}
          </ModalContent>
        </div>
      </div>
    </>
  );
};

export default ViewImage;
