import React, { createContext, useContext, useState } from 'react';

const StateContext = createContext();

const initialState = {
  chat: false,
  cart: false,
  userProfile: false,
  notification: false,
};
export const ContextProvider = ({ children }) => {
  const [activeMenu, setActiveMenu] = useState(true);
  const [isClicked, setIsClicked] = useState(initialState);
  const [screenSize, setScreenSize] = useState(undefined);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [registerView, setRegisterView] = useState('student');
  const [modalTerms, setModalTerms] = useState(false);
  const [modalTermsCheckbox, setModalTermsCheckbox] = useState(false);
  const [copaisChecked, setCopaisChecked] = useState(false);
  const [NewUserModal, setNewUserModal] = useState('admin');
  const [showModal, setShowModal] = useState(false);
  const [showModalSchools, setShowModalSchools] = useState(false);
  const [showMyAccounts, setShowMyAccounts] = useState(true);
  const [securitySettings, setSecuritySettings] = useState(false);
  const [notificationsSettings, setNotificationsSettings] = useState(false);
  const [bracketModal, setBracketModal] = useState('Manual');
  const [isScreenshoot, setIsScreenshoot] = useState(false);
  const [selectedWeek, setSelectedWeek] = useState(undefined);

  // const [uploadScreenshootId, setUploadScreenshootId] = useState(false);

  const [uploadScreenshootId, setUploadScreenshootId] = useState([]);

  const handleClick = (clicked) => {
    setIsClicked({ ...initialState, [clicked]: true });
  };

  return (
    <StateContext.Provider
      value={{
        activeMenu,
        setActiveMenu,
        isClicked,
        setIsClicked,
        handleClick,
        screenSize,
        setScreenSize,
        isLoggedIn,
        setIsLoggedIn,
        registerView,
        setRegisterView,
        modalTerms,
        setModalTerms,
        modalTermsCheckbox,
        setModalTermsCheckbox,
        copaisChecked,
        setCopaisChecked,
        NewUserModal,
        setNewUserModal,
        showModal,
        setShowModal,
        showModalSchools,
        setShowModalSchools,
        showMyAccounts,
        setShowMyAccounts,
        securitySettings,
        setSecuritySettings,
        notificationsSettings,
        setNotificationsSettings,
        bracketModal,
        setBracketModal,
        isScreenshoot,
        setIsScreenshoot,
        uploadScreenshootId,
        setUploadScreenshootId,
        selectedWeek,
        setSelectedWeek,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};
export const useStateContext = () => useContext(StateContext);
