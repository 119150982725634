import React, { useEffect, useRef, useState } from 'react';
import { useGetAllClassroomsByGameIdQuery } from '../../../slices/classroomApiSlice';
import { selectOtherClassroomsBySameGame } from '../../../slices/matchSlice';
import { useSelector } from 'react-redux';
import useIntersectionObserver from '../../../hooks/use-intersection-observer';
import { useNavigate } from 'react-router';
import { ClassroomModel } from '../../../model/classroom.model';

const OtherClassroomCardBySameGame = ({ gameId, cId }: { gameId: number; cId: number; }) => {
  const [fetch, setFetch] = useState(false);
  const navigate = useNavigate();
  const elementRef = useRef(null);
  const [page, setPage] = useState<number>();
  const allOtherClassromsByGame = useSelector(selectOtherClassroomsBySameGame);
  const entry = useIntersectionObserver(elementRef, {});
  const isVisible = !!entry?.isIntersecting;
  const hasIOSupport = !!window.IntersectionObserver;

  const { data, isLoading, isSuccess, isError } = useGetAllClassroomsByGameIdQuery({
    id: gameId,
    page,
  });

  useEffect(() => {
    if (isSuccess) console.log('isVisible', fetch);
    if (!isVisible || !fetch || isLoading || !data || !data.meta.next) return;
    setPage(data.meta.next);
  }, [isVisible, data?.meta, fetch]);

  useEffect(() => {
    console.log('isLoading', isLoading, fetch);
    if (isLoading) setFetch(false);
    else setTimeout(() => setFetch(true), 2000);
  }, [isLoading]);

  if (isLoading) return <p>Loading...</p>;
  return (
    <div className='flex flex-col gap-5 w-full sm:w-full rounded-lg p-2 shadow-lg overflow-y-scroll'>
      {allOtherClassromsByGame.filter((f: any) => f.id !== cId).map((d: any) => {
        const classroom = new ClassroomModel(d);
        return (
          <div
            key={classroom.id}
            className='hover:cursor-pointer flex w-full border-1 border-black rounded-lg'
            onClick={() => navigate(`../classrooms/view/${classroom.id}`)}
          >
            <div className='max-h-[100px] w-1/2 bg-black rounded-l-lg'>
              <img src={classroom.profile_image} className='object-contain h-full m-auto' />
            </div>
            <div className='leading-tight flex flex-col flex-1  justify-between p-5'>
              <h2 className='text-sm font-semibold  max-w-[22ch] truncate'>{classroom.name}</h2>
              <p className='text-[10px]'>
                Game: <b>{classroom.game_name}</b>
              </p>

              <p className='text-[10px]'>Created: {classroom.createdAt}</p>
            </div>
          </div>
        );
      })}
      {isLoading ? (
        <div>Loading</div>
      ) : (
        <div ref={elementRef} className='flex flex-1 w-full min-h-[10px]'></div>
      )}
      {!hasIOSupport && data.meta.next && <span>Load more</span>}
    </div>
  );
};

export default OtherClassroomCardBySameGame;
