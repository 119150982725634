import { InvoiceStatusNames, schoolStatusNames } from '../../utils/constants';
import { dateToLocal } from '../../utils/dateFormatter';

export class SchoolModel {
  id?: number;
  optionName?: string;
  name;
  streetAddress;
  city;
  state;
  zipCode;
  phoneNumber;
  status;
  tags;
  organization_id;
  price;
  price_per_student;
  coach_price;
  coach_enabled;
  coach_hours;
  updatedAt?;
  image?: string;
  banner?: string;
  user_count?: number;
  org_name?: string;
  status_name?: string;
  schoolState?: string;
  constructor(school: any) {
    if (school?.hasOwnProperty('Invoices')) {
      if (school.Invoices?.length) {
        const invoice = school.Invoices[0];
        if (invoice.hasOwnProperty('status')) {
          this.schoolState = InvoiceStatusNames[invoice.status] ?? 'No Invoice';
        }
      }
    }
    if (school?.hasOwnProperty('organization')) {
      if (school?.organization?.hasOwnProperty('name')) {
        this.org_name = school?.organization?.name;
      }
    }
    if (school?.hasOwnProperty('image')) {
      this.image = school.image;
    }
    if (school?.hasOwnProperty('banner')) {
      this.banner = school.banner;
    }
    if (school?.hasOwnProperty('_count')) {
      if (school?._count?.hasOwnProperty('User')) {
        this.user_count = school._count.User;
      }
    }
    if (school.hasOwnProperty('id')) this.id = school.id;
    if (school.hasOwnProperty('updated_at'))
      this.updatedAt = dateToLocal(school?.updated_at, 'MMMM D, YYYY');
    if (school.hasOwnProperty('name')) this.name = school.name;
    if (school.hasOwnProperty('streetAddress')) this.streetAddress = school.streetAddress;
    if (school.hasOwnProperty('city')) this.city = school.city;
    if (school.hasOwnProperty('state')) this.state = school.state;
    if (school.hasOwnProperty('zipCode')) this.zipCode = school.zipCode;
    if (school.hasOwnProperty('phoneNumber')) this.phoneNumber = school.phoneNumber;
    if (school.hasOwnProperty('status')) {
      this.status = school.status;
      this.status_name = schoolStatusNames[this.status];
    }
    if (school.hasOwnProperty('tags')) this.tags = school.tags;
    if (school.hasOwnProperty('organization_id')) this.organization_id = school.organization_id;
    if (school.hasOwnProperty('price')) this.price = school.price;
    if (school.hasOwnProperty('price_per_student'))
      this.price_per_student = school.price_per_student;
    if (school.hasOwnProperty('coach_price')) this.coach_price = school.coach_price;
    if (school.hasOwnProperty('coach_enabled')) {
      this.coach_enabled = school.coach_enabled ? true : false;
    }
    if (school.hasOwnProperty('coach_hours')) this.coach_hours = school.coach_hours;
  }
  getOptionName() {
    return [this.name, this.state, this.org_name].filter((o) => o).join(' / ');
  }
}
