import React, { useEffect, useState } from 'react';
import { AvatarImage } from '../../../../components/avatar-image';
import CanIView from '../../../../components/can-i-view/can-i-view';
import { RescheduleRequestModel } from '../../../../model/reschedule-request.model';
import { useSelector } from 'react-redux';
import { selectMatchData, selectReschedules, setReschedules } from '../../../../slices/matchSlice';
import {
  useApproveRescheduleMutation,
  useDeleteRescheduleMutation,
} from '../../../../slices/matchApiSlice';
import { useAppDispatch } from '../../../../store';
export function ReceivedRescheduleRequestCard({ model }: { model: RescheduleRequestModel }) {
  const [team, setTeam] = useState<{
    User: { id: number; username: string }[];
    teamName: string;
  }>();
  const matchData = useSelector(selectMatchData);
  useEffect(() => {
    matchData.home_team[0].captain === model.requested
      ? setTeam(matchData.home_team[0])
      : setTeam(matchData.away_team[0]);
  }, [matchData]);
  const dispatch = useAppDispatch();
  const rescheduleData = useSelector(selectReschedules);
  const [approveRe, { isLoading: loady }] = useApproveRescheduleMutation();
  const approveReschedule = () => {
    approveRe({
      id: model.id,
      new_date: new Date(model.new_date??"").toISOString(),
    })
      .unwrap()
      .then((res: { id: number }) =>
        dispatch(setReschedules(rescheduleData.filter((r: any) => r.id !== res.id))),
      );
  };
  const [
    deleteReschedule,
    {
      isError: deleteRescheduleIsError,
      isLoading: deleteRescheduleIsLoading,
      isSuccess: deleteRescheduleIsSuccess,
    },
  ] = useDeleteRescheduleMutation();
  const refuseReschedule = () => {
    deleteReschedule({ id: model.id })
      .unwrap()
      .then((res: { id: number }) =>
        dispatch(setReschedules(rescheduleData.filter((r: any) => r.id !== res.id))),
      );
  };

  return (
    <div className='hover:bg-slate-100 flex flex-col items-center border-1 rounded-lg'>
      <div className='flex p-2 gap-2 bg-slate-50 w-full '>
        <CanIView component='view-match.reschedules.ogma-status'>
          <p className='text-xs'>
            OGMA Status: <b>{model.admin_status_name}</b>
          </p>
        </CanIView>
        <CanIView component='view-match.reschedules.captain-status'>
          <p className='text-xs'>
            Opposite Captain Status: <b>{model.captain_status_name}</b>
          </p>
        </CanIView>
      </div>
      <div className='flex w-full p-2 gap-2 items-center justify-between flex-wrap'>
        <div className='flex items-center gap-2'>
          <AvatarImage source='' />
          <div className='flex flex-col items-center'>
            <p className='text-xs '>Requester</p>
            <p className='text-xs font-bold'>
              {team?.User.filter((u: { id: number }) => u.id === model.requested)[0]?.username}
            </p>
            <p className='text-xs font-bold'>{team?.teamName}</p>
          </div>
        </div>
        <div className='flex flex-col items-center'>
          <p className='text-xs '>Requested Date</p>
          <p className='text-xs font-bold'>{model.f_new_date}</p>
        </div>
        <div className='flex flex-col gap-2'>
          <div
            onClick={approveReschedule}
            className='hover:bg-opacity-80 cursor-pointer select-none flex p-2 min-w-[80px] justify-center py-1 gap-2 rounded-md bg-green-600 text-white text-xs uppercase'
          >
            Approve
          </div>
          <div
            onClick={refuseReschedule}
            className='hover:bg-opacity-80 cursor-pointer select-none flex p-2 min-w-[80px] justify-center py-1 gap-2 rounded-md bg-red-600 text-white text-xs uppercase'
          >
            Refuse
          </div>
        </div>
      </div>
    </div>
  );
}
