import React, { useEffect, useRef, useState } from 'react';
import { dayInString, weekday } from '../../../utils/utils';
import CalendarWeekRow from './calendar-week-row';
import { useGetAllMatchesByDateStatusQuery } from '../../../slices/matchApiSlice';
import { MatchScheduleModel } from '../../../model/match-schedule.model';
import useIntersectionObserver from '../../../hooks/use-intersection-observer';
import { useSelector } from 'react-redux';
import { selectCalendar } from '../../../slices/matchSlice';
export function CalendarWeekDay({
  index,
  weekDay,
}: {
  index: number;
  weekDay: { day: number; year: number; month: number; fullDate: Date };
}) {
  const [fetch, setFetch] = useState(false);
  const elementRef = useRef(null);
  const [page, setPage] = useState<number>(1);

  const entry = useIntersectionObserver(elementRef, {});
  const isVisible = !!entry?.isIntersecting;
  const hasIOSupport = !!window.IntersectionObserver;
  const { data, isLoading, isSuccess } = useGetAllMatchesByDateStatusQuery([
    {
      date: weekDay.fullDate.toISOString().split('T')[0],
      name: '',
      page,
    },
    { dateStatus: 3 },
  ]);

  useEffect(() => {
    if (isSuccess) console.log('isVisible', fetch);
    if (!isVisible || !fetch || isLoading || !data || !data.meta.next) return;
    setPage(data.meta.next);
  }, [isVisible, data?.meta, fetch]);
  useEffect(() => {
    console.log('isLoading', isLoading, fetch);
    if (isLoading) setFetch(false);
    else setTimeout(() => setFetch(true), 2000);
  }, [isLoading]);
  const calendarData = useSelector(selectCalendar);
  return (
    <div
      className={` flex-1 flex flex-col w-40 min-h-[400px] ${
        index  === weekday.length ? 'border-r-1' : 'border-r-0'
      } `}
    >
      <div className={`p-2  ${
        index  === weekday.length ? 'border-r-1' : 'border-r-0'
      } `}>
        <p className=' overflow-hidden whitespace-nowrap truncate text-ellipsis text-base font-semibold text-center'>
          {weekDay.fullDate.toLocaleString('default', {
            month: 'short',
            year: 'numeric',
          })}{' '}
          <br />
         <p className='font-extrabold text-3xl'> {weekDay.day}  <br /> </p> 
          {dayInString(weekDay.year, weekDay.month, weekDay.fullDate.getDate())}
        </p>
      </div>
      
      <div>
        
      </div>
      <div className={` w-full h-full p-3 border-t border-b border-l ${
        index + 1  === weekday.length ? 'border-r-1' : 'border-r-0' 
      } border-neutral-300 flex flex-col items-center gap-2`}>
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          calendarData[weekDay.fullDate.toISOString().split('T')[0]]?.map((d: any, i: number) => (
            <CalendarWeekRow key={i} date={weekDay.fullDate} model={new MatchScheduleModel(d)} />
          ))
        )}

        {data?.meta?.next && (
          <div ref={elementRef} className='flex flex-1 w-full min-h-[10px]'></div>
        )}
      </div>
    </div>
  );
}
