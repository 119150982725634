import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TableContainer from '../../../../components/table-container/table-container';
import { TableHead } from '../../../../components/table-head';
import { useGetOrgByIdQuery } from '../../../../slices/organizationApiSlice';
import { OrganizationTeamsBySchool } from './organization-teams-by-school';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../slices/auth';

const OrganizationTeams = () => {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const param = useParams();
  const {
    data: organizationData,
    isLoading: organizationIsLoading,
    isError: organizationIsError,
    isSuccess: organizationIsSuccess,
  } = useGetOrgByIdQuery({
    id: param.id ?? user.organizations[0].id,
    query: 'match'
  });

  const tableL = ['Name', 'Created', 'Number of Players', 'School', 'Game'];

  useEffect(() => {
    console.log(organizationData);
  }, []);

  if (organizationIsLoading) {
    return <p>Loading ...</p>;
  }
  return (
    <>
      <div className='flex flex-col'>
        <TableContainer>
          <TableHead tableList={['', 'Name', 'Create', 'Number of Players', 'School', 'Game']} />

          {/* {teamData && teamData.map((t: any) => ( */}
          {/* <tr> */}
          {organizationData &&
            organizationData.schools.map((school: any, i: number) => (
              // {tableL.map((table:any)=> (

              <tbody key={i} className='bg-white divide-y divide-gray-200 '>
                <OrganizationTeamsBySchool active={false} id={school.id} sName={school.name} />
              </tbody>

              // ))}
            ))}
          {/* </tr> */}
          {/* ))} */}
          {/* </tbody> */}
        </TableContainer>
      </div>
    </>
  );
};

export default OrganizationTeams;
