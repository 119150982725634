export function LoadingSceletonArchived (){
    return (
    
     <div className="w-full h-full border-2 rounded-xl flex flex-col p-1">
        <div className=" flex flex-wrap p-3 justify-between ">
            <div className="flex flex-wrap gap-3">
            </div>
        </div>
        <div className="">
        
            <div className="flex justify-between p-1 rounded-lg">
            <div className=" h-[900px] md:w-full  bg-slate-200 rounded-lg skeleton__pulse "></div>
            </div>
            
        </div>
     </div>
    )
    
 }