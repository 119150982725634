import { Navigate, useParams } from 'react-router-dom';
import {
  useArchiveOrganizationMutation,
  useGetOrgByIdQuery,
} from '../../slices/organizationApiSlice';
import ModalArchive from '../../components/modal-archive';
import { CustomError } from '../../utils/custom-types';
function ArchiveOrg() {
  const param = useParams();
  const {
    error,
    data: orgData,
    isSuccess: orgIsSuccess,
    isLoading: orgIsLoading,
    isError: orgIsError,
  } = useGetOrgByIdQuery({
    id: param.id,
    query: ''
  });
  const [archiveOrganization, { data, isSuccess, isError, isLoading }] =
    useArchiveOrganizationMutation();
  const onSubmit = () => {
    archiveOrganization(param.id)
      .unwrap()
      .then((res) => console.log(res))
      .catch((e) => console.log(e));
  };
  if (orgIsError || (orgIsSuccess && !orgData))
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError).data.message }} />;
  if (isSuccess) return <Navigate to='../' />;
  if (orgIsLoading) return <p>Loading...</p>;
  return (
    <ModalArchive onSubmit={onSubmit} isLoading={isLoading} name={orgData.name} />
    // <div className='fixed z-20 top-0 left-0 h-[100vh] w-[100vw] flex justify-center items-center'>
    //   <OutsideClick />
    //   <div className=' flex flex-col items-center gap-5 z-20 p-10 bg-white rounded-xl shadow-xl'>
    //     Are you sure you want to archive this organization?
    //     <div className='w-full flex justify-center items-center gap-5'>
    //       <Formik initialValues={{}} onSubmit={onSubmit}>
    //         <Form>
    //           <Link
    //             to='../'
    //             className='w-[100px] flex ml-4 rounded-md mb-3 items-center justify-center py-1 shadow text-base bg-slate-400 text-white hover:bg-opacity-80'
    //           >
    //             No
    //           </Link>
    //           <SubmitButton title='Archive' isLoading={isLoading} />
    //         </Form>
    //       </Formik>
    //     </div>
    //   </div>
    // </div>
  );
}

export default ArchiveOrg;
