import { AiOutlineCamera } from 'react-icons/ai';
import { useParams } from 'react-router-dom';
import BannerImage from '../../../components/table-td/banner-image';
import {
  useUpdateSeasonBannerImageMutation,
  useViewSingleSeasonQuery,
} from '../../../slices/seasonApiSlice';
import { ViewSeasonHeaderInfo } from './view-season-header-info';
import CanIView from '../../../components/can-i-view/can-i-view';
export function ViewSeasonHeader({}) {
  const param = useParams();
  const { data, isLoading, isError, isSuccess } = useViewSingleSeasonQuery(param.id);
  const [updateSeasonBannerImage, { isLoading: bannerIsLoading }] =
    useUpdateSeasonBannerImageMutation();

  function handleFileChange(event: any) {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    updateSeasonBannerImage({ photo: formData, id: param.id })
      .unwrap()
      .then((res) => console.log(res))
      .catch((e) => console.log(e));
  }
  if (isLoading) return <p>Loading...</p>;
  return (
    <div className='flex sticky top-0  justify-between z-[1] items-center rounded-tl-xl gap-5 rounded-tr-xl w-full  min-h-[170px] bg-white overflow-hidden px-10 border-b-1'>
      <div className='group absolute w-full h-full top-0 left-0 '>
        {bannerIsLoading ? (
          <p>Loading...</p>
        ) : (
          <BannerImage source={data && data.banner_image ? data.banner_image : undefined} />
        )}
        <CanIView component='season.view.editCover'>
          <label className='group-hover:opacity-100 opacity-0  flex bg-black bg-opacity-80 absolute  justify-center items-center w-full h-full  text-white left-0 top-0  cursor-pointer '>
            <AiOutlineCamera size={30} />
            <input
              type='file'
              accept='image/*'
              onChange={handleFileChange}
              className='hidden mr-12'
            />
          </label>
        </CanIView>
      </div>
      <ViewSeasonHeaderInfo />
    </div>
  );
}
