import {
  useChangeUserBannerPhotoMutation,
  useUserHeaderInfobyUserIdQuery,
} from '../../../slices/userApiSlice';
import { Navigate, useParams } from 'react-router';
import { AiOutlineCamera } from 'react-icons/ai';
import BannerImage from '../../../components/table-td/banner-image';
import ViewUserHeaderInfo from './view-user-header-info';
import { CustomError } from '../../../utils/custom-types';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../slices/auth';
import { UserRoles } from '../../../utils/constants';

const UserHeader = () => {
  const [changeUserProfilePhoto, { isError, isLoading, isSuccess }] =
    useChangeUserBannerPhotoMutation();

  const user = useSelector(selectUser);
  const param = useParams();

  function handleFileChange(event: any) {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    changeUserProfilePhoto({ photo: formData, id: param.id ?? user.id })
      .unwrap()
      .then((res) => console.log(res))
      .catch((e) => console.log(e));
  }
  // const {
  //   error,
  //   data: userData,
  //   isLoading: userIsLoading,
  //   isError: userIsError,
  //   isSuccess: userIsSuccess,
  // } = useGetUserByIdQuery(param.id ?? user.id);

  const {
    error,
    data: userData,
    isLoading: userIsLoading,
    isError: userIsError,
    isSuccess: userIsSuccess,
  } = useUserHeaderInfobyUserIdQuery(param.id ?? user.id);
  console.log('userData', userData);
  console.log('user', user);
  const canEdit = () => {
    switch (user?.roles) {
      case UserRoles.Admin:
        return true;
      case UserRoles.Ogma:
        return (
          user?.ogma_org?.filter((org: any) => org.id === userData.school.organization_id).length >
          0
        );
      case UserRoles.Student:
        return user?.id === userData?.id;
      default:
        return false;
    }
  };
  if (userIsLoading) return <p>Loading ...</p>;
  if (userIsError)
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError).data.message }} />;

  return (
    <div className='flex sticky top-0  z-[1] justify-between  items-center rounded-tl-xl gap-5 rounded-tr-xl w-full  min-h-[150px] bg-white overflow-hidden'>
      <div className='group absolute w-full h-full top-0 left-0 '>
        <BannerImage source={userData ? userData.banner_image : null} />
        {canEdit() && (
          <label className='group-hover:opacity-100 opacity-0  flex bg-black bg-opacity-80 absolute z-10 justify-center items-center w-full h-full  text-white left-0 top-0  cursor-pointer '>
            <AiOutlineCamera size={30} />
            {!userData?.readPermission ? (
              <input
                type='file'
                accept='image/*'
                onChange={handleFileChange}
                className='hidden mr-12'
              />
            ) : null}
          </label>
        )}
      </div>
      <ViewUserHeaderInfo />
    </div>
  );
};

export default UserHeader;
