import { useState } from 'react';
import { ScheduleType } from './components/schedule-type';
import { Link, useSearchParams } from 'react-router-dom';
import { SponsorRow } from '../../components/sponsor-row/sponsor-row';
import { SponsorPlacements } from '../../utils/constants';
import { FcCalendar } from 'react-icons/fc';

export const AllSchedule = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [links, setLinks] = useState<any>([
    { id: 2, name: 'Match Schedule', bool: true },
    { id: 0, name: 'Tournament Schedule', bool: false },
    { id: 1, name: 'Season Schedule', bool: false },
  ]);

  function setLinkActive(id: number) {
    setLinks((prevLinks: any) =>
      prevLinks.map((link: any) => {
        if (link.id === id) {
          return { ...link, bool: true };
        } else {
          return { ...link, bool: false };
        }
      }),
    );
    setSearchParams('');
  }

  return (
    <div className='container mx-auto '>
      <SponsorRow name={SponsorPlacements.Schedule} extraClass='mb-[2rem]' />
      <div className='flex'>
        {links.map((t: any, i: number) => (
          <div
            key={i}
            onClick={() => setLinkActive(t.id)}
            className={`${t.bool ? 'border-b-0' : 'bg-gray-100 flex border-b-2'} border-l-2 ${
              t.id === 1 ? 'border-r-2' : ''
            }  border-t-2 flex whitespace-nowrap cursor-pointer p-2 px-8`}
          >
            <p className=''>{t.name}</p>
          </div>
        ))}
        <div className='border-b-2 w-full flex justify-end'>
          <Link to='/calendar' className='self-end'>
            <FcCalendar size={30} className='animate-bounce' />
          </Link>
        </div>
      </div>
      <div className='p-4 border-2 border-t-0 h-[calc(100vh-150px)] overflow-auto flex flex-col'>
        {links.map((t: any) => t.bool && <ScheduleType id={t.id} key={t?.id} />)}
      </div>
    </div>
  );
};
