import { useState, useEffect } from 'react';
import { Navigate, Link, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { store } from '../../../store';

import { Form, Formik } from 'formik';
import { loginSchema } from '../yupschema';

// assets
import logo from '../../../assets/logo2.png';
import { useLoginMutation } from '../../../slices/authApiSlice';
import { selectToken, setCredentials } from '../../../slices/auth';
import { FormInput } from '../../../components/form-input/form-input';
import { Button } from '../../../components/ui/button/Button';
import { AiOutlineLoading } from 'react-icons/ai';
import { CustomError } from '../../../utils/custom-types';

const LoginForm = () => {
  type AppDispatch = typeof store.dispatch;
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);
  const token = useSelector(selectToken);
  useEffect(() => {
    console.log({ token });
  }, [token]);

  const [login, { isLoading, error, isError }] = useLoginMutation();

  const [formError, setFormError] = useState('');
  const handleLogin = async (formValue: any) => {
    const { email, password } = formValue;
    const data = await login({ email: email, password })
      .unwrap()
      .then((data) =>
        data.status > 200
          ? setFormError(data.message)
          : dispatch(
              setCredentials({
                user: data.user,
                accessToken: data.token,
                refreshToken: data.refreshToken,
              }),
            ),
      )
      .catch((error) => {
        console.log(error);
        if (error.status === 400) {
          setFormError("User doesn't exist!");
        }
      });
  };

  if (token) {
    return <Navigate to='/dashboard/' />;
  }

  return (
    <>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={loginSchema}
        onSubmit={handleLogin}
      >
        <Form className='max-w-[400px] max-h-[500px] w-full mx-auto  px-8'>
          <img
            className='max-w-[250px] max-h-[100px] mb-15 pb-12 pl-6 ml-10'
            width="196" 
            height="49"
            src={logo}
            alt='fenworks-logo'
          />
          <h2 className='text-4xl dark:text-white pb-5 font-bold text-center'>Login</h2>
          <h3 className='text-4xxl pb-8 mb-2'>Enter your credential to access your account.</h3>

          <div className='mb-3'>
            <FormInput
              label='Email Address'
              name='email'
              type='text'
              placeholder='Enter email address'
            />
          </div>

          <div className=''>
            <FormInput
              label='Password'
              name='password'
              type='password'
              placeholder='Enter password'
            />
          </div>
          {isError && <p className='text-red-700'>{(error as CustomError)?.data?.message}</p>}
          <div className='flex justify-between text-gray-400 py-2'>
            <p>
              I forgot my password.{' '}
              <Link to='/auth/reset-password'>
                <span className='underline text-blue-700'>Click here to reset</span>
              </Link>
            </p>
          </div>
          <Button
            disabled={isLoading}
            type='submit'
            className='w-full my-5 py-2 bg-red-700 shadow-lg text-white font-semibold rounded-lg'
          >
            {isLoading && <AiOutlineLoading className='mr-2 h-4 w-4 animate-spin' />}
            Login
          </Button>
        </Form>
      </Formik>
    </>
  );
};

export default LoginForm;
