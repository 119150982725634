import { MdFavorite } from 'react-icons/md';
import { AspectRatio } from '../../../../components/ui/aspect-ratio/AspectRatio';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../../../../components/ui/tooltip/Tooltip';
import { cn } from '../../../../utils/utils';
import { Game } from '../MostPlayedGames';
import { Link } from 'react-router-dom';
import {
  useGetStudentInGameNameByIdQuery,
  useToggleFavGameMutation,
} from '../../../../slices/gameApiSlice';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../slices/auth';
import CanIView from '../../../../components/can-i-view/can-i-view';
import { BsThreeDots } from 'react-icons/bs';
import { useState } from 'react';
import DropdownItem from '../../../../features/listview/DropDown/DropDownItem';

interface GameCardProps extends React.HTMLAttributes<HTMLDivElement> {
  game: Game;
  aspectRatio?: number;
  userId?: number;
  isAvailable?: boolean;
  onlyFavorites?: boolean;
}

export function GameCard({
  game,
  aspectRatio = 3 / 4,
  isAvailable,
  userId,
  className,
  ...props
}: GameCardProps) {
  const user = useSelector(selectUser);

  const {
    data: inGameName,
    isLoading: inGameIsLoading,
    error: inGameIsError,
  } = useGetStudentInGameNameByIdQuery('');

  const [toggle] = useToggleFavGameMutation();

  const addAsFavorite = (gameId: number) => {
    const body = {
      userId: user.id,
      gameId: gameId,
    };
    //   game.FavoriteGames.length !== 0 && game.FavoriteGames.map((fg: any) => fg.userId === user.id);

    //   game.FavoriteGames.length && game.FavoriteGames.filter((f: any) => f.userid === user.id).length
    //     ? deleteFavoriteGame(body)
    //         .unwrap()
    //         .then((res) => console.log(res))
    //         .catch((err) => console.log(err))
    //     : addFavoriteGame(body)
    //         .unwrap()
    //         .then((res) => console.log(res))
    //         .catch((err) => console.log(err));

    console.log(
      game?.FavoriteGames?.filter((f: any) => f.userId === user.id)?.length,
      game?.FavoriteGames,
    );
    toggle(gameId);
  };

  const [openList, setOpenList] = useState(false);

  if (inGameIsLoading) return <p>Loading...</p>;

  return (
    <div className={cn('relative', 'space-y-3', className)} {...props}>
      <AspectRatio ratio={aspectRatio} className='overflow-hidden rounded-md'>
        <img
          src={game.image}
          alt={game.name}
          className='object-cover h-full w-full transition-all hover:scale-105 rounded-lg cursor-pointer'
        />
        <CanIView component='threedots'>
          <>
            <div
              onClick={() => setOpenList(true)}
              className='absolute cursor-pointer top-2 left-2 p-1 px-2 rounded-lg bg-black bg-opacity-80'
            >
              <BsThreeDots color='white' className='group' />
            </div>
            {openList ? (
              <div className='absolute top-0 left-0 listViewMenu bg-transparent h-fit '>
                <DropdownItem noView id={game.id} setFunc={setOpenList} />
              </div>
            ) : (
              <></>
            )}
          </>
        </CanIView>
        <div className='absolute top-2 right-2 cursor-pointer'>
          <div className=''>
            {game.matches ? (
              <p className='text-xs text-black bg-white px-4 py-2 rounded-xl select-none'>
                <span className='font-bold'>Matches Played: </span>
                {game.matches}
              </p>
            ) : null}
          </div>
          {game.FavoriteGames && (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger
                // onClick={() => addAsFavorite(game.id)}
                >
                  <MdFavorite
                    color={
                      game?.FavoriteGames?.filter((f: any) => f.userId === user.id)?.length
                        ? 'red'
                        : 'white'
                    }
                    onClick={() => addAsFavorite(game.id)}
                    size='30px'
                  />
                </TooltipTrigger>
                <TooltipContent>
                  {game?.FavoriteGames?.filter((fg: any) => fg.userId === user.id).length ? (
                    <p>Remove from Favorites</p>
                  ) : (
                    <p>Add to Favorites</p>
                  )}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
        </div>
      </AspectRatio>
      <div className='flex justify-between items-center space-y-1 text-sm'>
        <h3 className='cursor-pointer font-bold hover:text-rose-600'>{game.name}</h3>
        {isAvailable && (
          <CanIView component='game.connect'>
            {inGameName?.some(
              (inGame: any) => inGame.game_id === game.id && inGame.in_game_name != '',
            ) ? (
              <Link
                to={`./delete/${game.id}`}
                className='px-2 py-1 border rounded-xl hover:bg-gray-100'
              >
                Disconnect
              </Link>
            ) : (
              <Link
                to={`./add/${game.id}`}
                className='px-2 py-1 border rounded-xl hover:bg-gray-100'
              >
                Connect
              </Link>
            )}
          </CanIView>
        )}
      </div>
    </div>
  );
}
