import React, { useState } from 'react';
import { useStateContext } from '../../../contexts/ContextProvider';
import ReCAPTCHA from 'react-google-recaptcha';
import { ErrorMessage, Field, Form, Formik, FormikValues } from 'formik';
import { FormInput } from '../../../components/form-input/form-input';
import { useAcceptUserInviteMutation } from '../../../slices/userApiSlice';
import { useParams } from 'react-router';
import { useAppDispatch } from '../../../store';
import { setCredentials } from '../../../slices/auth';
import { ogmaValidationSchema } from './schema/ogma-register.schema';
import { allStates, gendersOptions } from '../../../utils/constants';
import { CustomError } from '../../../utils/custom-types';
import ModalTerms from './ModalTerms';

const OGMARegisterForm = () => {
  const { modalTerms, setModalTerms, modalTermsCheckbox, setModalTermsCheckbox } =
    useStateContext();
  const [captcha, setCaptcha] = useState('');

  function onChange(value: string | null) {
    if (value) setCaptcha(value);
  }
  const dispatch = useAppDispatch();
  const param = useParams();
  const [acceptInvite, { isError, error }] = useAcceptUserInviteMutation();

  const handleRegister = (values: FormikValues) => {
    console.log({ values });
    acceptInvite({
      token: `Bearer ${param.token}`,
      body: {
        username: values.username,
        firstName: values.firstName,
        lastName: values.lastName,
        city: values.city,
        confirmPassword: values.confirmPassword,
        password: values.password,
        phoneNumber: values.phoneNumber,
        state: values.state,
        // streetAddress: values.streetAddress,
        // dateOfBirth: new Date(values.dateOfBirth).toISOString(),
        office_number: values.office_number,
        zipCode: +values.zipCode,
        gender: values.gender?.toString(),
      },
      recaptcha: captcha,
    })
      .unwrap()
      .then((res) => dispatch(setCredentials(res)));
  };

  const formInputs = [
    {
      mainTitle: 'Organization Manager Information',
      name: 'firstName',
      label: 'First Name',
      type: 'text',
      placeholder: 'Enter first name',
    },
    {
      mainTitle: 'Organization Manager Information',
      name: 'lastName',
      label: 'Last Name',
      type: 'text',
      placeholder: 'Enter last name',
    },
    {
      mainTitle: 'Organization Manager Information',
      name: 'username',
      label: 'Username',
      type: 'text',
      placeholder: 'Enter username',
    },
    {
      mainTitle: 'Organization Manager Information',
      name: 'city',
      label: 'City',
      type: 'text',
      placeholder: 'Enter city',
    },
    {
      mainTitle: 'Organization Manager Information',
      name: 'state',
      label: 'State',
      type: 'text',
      placeholder: 'Enter state',
      isSelect: true,
      options: allStates ?? [],
    },
    {
      mainTitle: 'Organization Manager Information',
      name: 'zipCode',
      label: 'Zip Code',
      type: 'number',
      placeholder: 'Enter ZIP Code',
    },
    {
      mainTitle: 'Organization Manager Information',
      name: 'phoneNumber',
      label: 'Phone Number',
      type: 'text',
      placeholder: 'Enter phone number',
    },
    {
      mainTitle: 'Organization Manager Information',
      name: 'gender',
      label: 'Gender',
      type: 'text',
      isSelect: true,
      options: gendersOptions,
    },
    {
      mainTitle: 'Organization Manager Information',
      name: 'office_number',
      label: 'Office Number',
      type: 'text',
      placeholder: 'Enter office number',
    },
    {
      mainTitle: 'Security Information',
      name: 'password',
      label: 'Password',
      type: 'password',
      placeholder: 'Enter password',
    },
    {
      mainTitle: 'Security Information',
      name: 'confirmPassword',
      label: 'Confirm Password',
      type: 'password',
      placeholder: 'Re-enter password',
    },
  ];
  const initialValues: { [key: string]: string } = {};
  formInputs.forEach((input) => (initialValues[input.name] = ''));
  return (
    <>
      <Formik
        initialValues={{ ...initialValues, check: false }}
        validationSchema={ogmaValidationSchema}
        onSubmit={handleRegister}
      >
        {({ isSubmitting, errors, setFieldValue, values }) => (
          <Form className='max-w-3xl m-4 p-10 bg-white rounded shadow-xl'>
            <h1 className='flex items-center justify-center mt-1 text-2xl pt-1 mb-2'>
              Create a new OGMA account
            </h1>
            {formInputs.map((input: any, i: number) => (
              <React.Fragment key={i}>
                {!formInputs[i - 1] || formInputs[i - 1]?.mainTitle !== input.mainTitle ? (
                  <>
                    <div className='flex flex-col'>
                      <div className='mb-2'>
                        <p className='text-gray-800 font-bold pt-6'>{input.mainTitle}</p>
                        <hr />
                      </div>
                      <div className='flex flex-wrap gap-5'>
                        {formInputs
                          .filter((inp1) => inp1.mainTitle === input.mainTitle)
                          .map((inp, i: number) => (
                            <div key={i} className='flex-1 min-w-[300px]'>
                              <FormInput {...inp} />
                            </div>
                          ))}
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </React.Fragment>
            ))}

            {/*reCaptcha */}
            <div className=' flex items-center justify-center mt-8'>
              <ReCAPTCHA
                sitekey={import.meta.env.VITE_GOOGLE_RECAPTCHA_KEY}
                onChange={(value) => onChange(value)}
              />
            </div>
            <span className=' flex items-center justify-center mt-7'>
              <Field
                name='check'
                type='checkbox'
                className='mr-2'
                // onClick={() => {
                //   setModalTermsCheckbox(!modalTermsCheckbox);
                // }}
                // checked={modalTermsCheckbox}
              />
              {modalTerms && (
                <ModalTerms
                  setModalTerms={setModalTerms}
                  setModalTermsCheckbox={setModalTermsCheckbox}
                  setFieldValue={setFieldValue}
                />
              )}

              <span
                className='font-italic'
                onClick={() => {
                  setModalTerms(true);
                }}
              >
                I have read and agree to the{' '}
                <span className='text-black underline font-bold'> Terms and condition</span>
              </span>
            </span>
            <div className='flex justify-center p-2'>
              <ErrorMessage name='check' component='div' className='text-red-400 text-xs' />
            </div>
            <div className='flex justify-center'>
              <button
                className='w-1/2 m-auto  py-2 bg-red-700 shadow-lg  text-white font-semibold rounded-lg'
                // disabled}
                type='submit'
              >
                Submit
              </button>
            </div>

            {isError && <p className='text-red-300'>{(error as CustomError)?.data?.message}</p>}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default OGMARegisterForm;
