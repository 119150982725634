import { Form, Formik } from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { FormInput } from '../../components/form-input/form-input';
import { SubmitButton } from '../../components/submit-button';
import { changeUserData, logOut, selectToken, selectUser } from '../../slices/auth';
import { useAppDispatch } from '../../store';
import logo from '../../assets/logo2.png';
import Lock from '../../assets/padlock2.svg';
import { FirstTimeYup } from '../../features/authentication/yupschema';
import { useFirstTimeMutation } from '../../slices/authApiSlice';
import { FaUserLock } from 'react-icons/fa';
import { UserRoles } from '../../utils/constants';

function FirstTime() {
  const user = useSelector(selectUser);
  const token = useSelector(selectToken);
  console.log('user role??', user);
  const dispatch = useAppDispatch();
  const [firstTime, { isLoading, isSuccess, isError, error }] = useFirstTimeMutation();
  const handleSubmit = (values: any) => {
    firstTime(values)
      .unwrap()
      .then((res) => dispatch(changeUserData(res)));
  };
  if (!user) return <Navigate to='/' />;
  if (!user.firstTime) return <Navigate to='/' />;
  return (
    <div className='flex flex-col items-center justify-center h-[100vh] w-full'>
      <div className='w-full absolute top-0 h-100 flex  border-b-1  p-5  border-black'>
        <img width="196" height="49" src={logo} alt='Fenworks Logo' />
      </div>

      <div className='w-full lg:w-2/3 flex flex-col items-center text-center bg-white justify-center p-5 rounded-lg lg:rounded-l-none gap-5'>
        <FaUserLock size={100} color={'#2e2e2e'} />
        <Formik
          initialValues={{ newPassword: '', confirmPassword: '' }}
          validationSchema={FirstTimeYup}
          onSubmit={handleSubmit}
        >
          <Form className='flex flex-col gap-5'>
            <p className='text-red-500'>
              {error && isError && 'data' in error ? error?.data?.toString() : <></>}
            </p>
            <p className=' text-md font-medium text-[#15325E]'>
              Welcome{' '}
              <span className='text-red-500'>
                {user.firstName[0].toUpperCase()}
                {user.firstName.slice(1)}
              </span>
              . Please change your temporary password by submitting the form below.{' '}
            </p>
            <FormInput
              placeholder='Enter password'
              label='New Password'
              type='password'
              name='newPassword'
              id='newPassword'
            />
            <FormInput
              placeholder='Re-enter password'
              label='Confirm Password'
              name='confirmPassword'
              id='confirmPassword'
              type='password'
            />
            <div className='flex h-fit justify-center gap-5 w-full'>
              {user.roles === UserRoles.Guardian ? (
                <>
                  <SubmitButton isLoading={isLoading} />
                  <div
                    onClick={() => dispatch(logOut())}
                    className=' 
                 select-none 
                  cursor-pointer px-8 py-2 text-gray-800 border border-gray-400  font-semibold rounded-lg hover:bg-opacity-80'
                  >
                    Cancel
                  </div>
                </>
              ) : (
                <>
                  <SubmitButton isLoading={isLoading} />
                  <div
                    onClick={() => dispatch(logOut())}
                    className=' 
                 select-none 
                  cursor-pointer px-8 py-2 text-gray-800 border border-gray-400  font-semibold rounded-lg hover:bg-opacity-80'
                  >
                    Cancel
                  </div>
                </>
              )}
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
}

export default FirstTime;
