import { Navigate, useParams } from 'react-router-dom';
import { useArchiveSchoolMutation, useGetSchoolByIdQuery } from '../../slices/schoolsApiSlice';
import ModalArchive from '../../components/modal-archive';
import { CustomError } from '../../utils/custom-types';

function ArchiveSchool() {
  const param = useParams();
  const {
    error,
    data: schoolData,
    isSuccess: schoolIsSuccess,
    isError: schoolIsError,
    isLoading: schoolIsLoading,
  } = useGetSchoolByIdQuery(param.id);
  const [archiveSchool, { data, isSuccess, isError, isLoading }] = useArchiveSchoolMutation();
  const onSubmit = () => {
    archiveSchool(param.id)
      .unwrap()
      .then((res) => console.log(res))
      .catch((e) => console.log(e));
  };
  if (schoolIsError || (schoolIsSuccess && !schoolData))
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError).data.message }} />;
  if (isSuccess) return <Navigate to='../' />;
  if (schoolIsLoading) return <p>Loading...</p>;
  return (
    <ModalArchive onSubmit={onSubmit} isLoading={isLoading} name={schoolData.name} />
    // <div className='fixed z-20 top-0 left-0 h-[100vh] w-[100vw] flex justify-center items-center'>
    //   <OutsideClick />
    //   <div className=' flex flex-col items-center gap-5 z-20 p-10 bg-white rounded-xl shadow-xl'>
    //     Are you sure you want to archive this school?
    //     <div className='w-full flex justify-center items-center gap-5'>
    //       <Formik initialValues={{}} onSubmit={onSubmit}>
    //         <Form>
    //           <Link
    //             to='../'
    //             className='rounded-md shadow py-2 px-5 bg-slate-400 text-white hover:bg-opacity-80'
    //           >
    //             No
    //           </Link>
    //           <SubmitButton title='Archive' isLoading={isLoading} />
    //         </Form>
    //       </Formik>
    //     </div>
    //   </div>
    // </div>
  );
}

export default ArchiveSchool;
