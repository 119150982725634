export enum MatchFormats {
  BestOf = 1,
  FirstTo = 2,
  Minimum = 3,
  Chess = 4,
}

export const matchFormatNames: Record<string, string> = {
  [MatchFormats.BestOf]: 'Best Of',
  [MatchFormats.FirstTo]: 'First To',
  [MatchFormats.Minimum]: 'Minimum',
  [MatchFormats.Chess]: 'Chess',
};

export class MatchFormatType {
  list: { id: number | string; name: string }[] = [];
  constructor(data: any) {
    Object.keys(data).forEach((v, i) => this.list.push({ id: v, name: data[v] }));
  }
}
