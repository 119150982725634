import { CalendarWeekDay } from './calendar-week-day';
import { useRef, useState } from 'react';
import { getDaysInMonth } from '../../../utils/utils';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

const CalendarWeekView = () => {
  const [date, setDate] = useState(new Date());
  const [currentYear, setCurrentYear] = useState(date.getFullYear());
  const [currentMonth, setCurrentMonth] = useState(date.getMonth());
  const currentDay = date.getDay();
  const arr = new Array(7);
  const dateDay = date.getDate();
  const day = date.getDay();
  arr[day] = dateDay;
  let temp = day;
  let tempDay = dateDay;
  const daysInCurrentMonth = getDaysInMonth(currentYear, currentMonth);
  function getWeekArray(date: Date) {
    const weekArray = [];
    const currentDate = new Date(date);
    const currentDayOfWeek = currentDate.getDay();
    const startOfWeek = new Date(currentDate.setDate(currentDate.getDate() - currentDayOfWeek));
    for (let i = 0; i < 7; i++) {
      const day = new Date(startOfWeek);
      day.setDate(day.getDate() + i);
      weekArray.push({
        day: day.getDate(),
        month: day.getMonth(),
        year: day.getFullYear(),
        fullDate: day,
      });
    }
    return weekArray;
  }
  const inputRef = useRef<HTMLInputElement>(null);


  const handleClear = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  // const date = new Date(); // Replace this with your desired date
  const [weeksArray, setWeeksArray] = useState(getWeekArray(date));
  function nextWeek() {
    const lastElement = weeksArray.slice(-1);
    setDate(lastElement[0].fullDate);
    date.setDate(lastElement[0].fullDate.getDate() + 1);
    setDate(date);
    lastElement[0].fullDate.setDate(lastElement[0].fullDate.getDate() + 1);
    setWeeksArray(getWeekArray(lastElement[0].fullDate));
  }
  function prevWeek() {
    const lastElement = weeksArray[0];
    setDate(lastElement.fullDate);
    date.setDate(lastElement.fullDate.getDate() - 1);
    setDate(date);
    lastElement.fullDate.setDate(lastElement.fullDate.getDate() - 1);
    setWeeksArray(getWeekArray(lastElement.fullDate));
  }

  return (
    <>
      <div className='flex gap-10'>
        <div className={`resetInput w-[300px]`}>
          <label className='resetLabel group' htmlFor={'date'}>
            Select Date
          </label>
          <input
            className='resetFormInput'
            type='date'
            onChange={(e) => setWeeksArray(getWeekArray(new Date(e.target.value)))}
            ref={inputRef}
            min={'2020-01-01'}
          />
        </div>
        <div
          onClick={() => {(setWeeksArray(getWeekArray(new Date()))),handleClear()}}
          className='btn edit text-white flex items-center uppercase main-gr-bg-color font-light text-lg'
        >
          Current Week
        </div>
      </div>
      <div className='flex flex-row xs:flex-row flex-1'>
        <div className='flex'>
          <IoIosArrowBack
            size={10}
            onClick={prevWeek}
            className='min-w-[30px] min-h-[30px] p-2 bg-slate-200 rounded-full hover:bg-slate-300 cursor-pointer mt-10'
          />
        </div>
        <div className='flex p-2 xs:flex-col flex-1 border-none'>
          {weeksArray.map((d, i: number) => (
            <CalendarWeekDay key={i} index={i} weekDay={d} />
          ))}
        </div>

        <IoIosArrowForward
          size={10}
          onClick={nextWeek}
          className='min-w-[30px] min-h-[30px] p-2 bg-slate-200 rounded-full hover:bg-slate-300 cursor-pointer mt-10'
        />
      </div>
    </>
  );
};

export default CalendarWeekView;
