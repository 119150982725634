import * as yup from 'yup';
import { SchemaMessages } from '../../../../../../../utils/constants';
import { checkEndDate, checkStartDate } from '../../../../../../../utils/utils';

export const NewSeasonVSchema = (isLeaderboard: number) => {
  return yup.object().shape({
    game_id: yup.string().required('Required!'),
    name: yup
      .string()
      .matches(
        /^[a-zA-ZÀ-ÖØ-öø-ÿÇç0-9\s-,.'"]{0,50}$/,
        'Only letters, numbers, spaces, and hyphens are allowed (maximum 50 characters)',
      )
      .required('Required!'),
    type_id: yup.string().required('Required!'),
    state: yup.string().required('Required!'),
    division_id: yup.string().required('Rrequired!'),
    subdivision: yup.string().nullable(),
    start_date: yup
      .mixed()
      .required('Required!')
      .transform((value, originalValue) => {
        return originalValue;
      })
      .test('test', SchemaMessages.DateShouldNotBeInThePast, checkStartDate),
    end_date: yup
      .mixed()
      .required('Required!')
      .when('start_date', {
        is: (start_date: any) => start_date != null,
        then: yup
          .mixed()
          .transform((v, ov) => ov)
          .test('test', SchemaMessages.EndDate, (v, ctx) => checkEndDate(v, ctx, 'start_date')),
      }),
    // end_date: yup
    //   .mixed()
    //   .transform((value, originalValue) => {
    //     return originalValue;
    //   })
    //   .test('test', "End date can't be before start date", (value, ctx) =>
    //     checkEndDate(value, ctx, 'start_date'),
    //   )
    //   .required('Required!'),
    description: yup.string().required('Required!'),
    teams: yup.string().nullable(),
    // image: yup.string().nullable(),
    format_id: !Number(isLeaderboard)
      ? yup.string().required('Required!')
      : yup.string().nullable(),
  });
};
