import React, { useState } from 'react';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md';
import CalendarMonthRow from './calendar-month-row';
import { getDaysInMonth } from '../../../utils/utils';

export function CalendarMonthView({}) {
  const date = new Date();
  const [currentYear, setCurrentYear] = useState(date.getFullYear());
  const [currentMonth, setCurrentMonth] = useState(date.getMonth());
  // const currentMonth = date.getMonth(); // 👈️ months are 0-based
  const currentDay = date.getDay();
  const daysInCurrentMonth = getDaysInMonth(currentYear, currentMonth);
  const daysInJanuary = getDaysInMonth(2025, 1);
  const daysInSeptember = getDaysInMonth(2025, 9);

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  function handleDateChange(action: 'plus' | 'minus') {
    if (action === 'plus') {
      if (currentMonth + 1 > 11) {
        setCurrentYear((y) => y + 1);
        setCurrentMonth(0);
        return;
      }
      setCurrentMonth((s) => s + 1);
      return;
    } else {
      if (currentMonth - 1 < 0) {
        setCurrentYear((y) => y - 1);
        setCurrentMonth(11);
        return;
      }
      setCurrentMonth((s) => s - 1);
      return;
    }
  }

  return (
    <>
      <div>
        <div className='flex items-center'>
          <MdOutlineKeyboardArrowDown
            size={30}
            onClick={() => handleDateChange('minus')}
            className='
            min-w-[30px] min-h-[30px] p-2 bg-slate-200 rounded-full hover:bg-slate-300 cursor-pointer mb-1 mr-2'
          />
          <b>
            {months[currentMonth]} {currentYear}{' '}
          </b>
          <MdOutlineKeyboardArrowUp
            size={30}
            onClick={() => handleDateChange('plus')}
            className='
            min-w-[30px] min-h-[30px] p-2 bg-slate-200 rounded-full hover:bg-slate-300 cursor-pointer mb-1 ml-2 '
          />
        </div>
        <div className='flex flex-col '>
          {Object.keys(Array.from(new Array(daysInCurrentMonth))).map((d, i: number) => (
            <CalendarMonthRow
              key={'c' + d}
              d={d}
              currentMonth={currentMonth}
              currentYear={currentYear}
              daysInCurrentMonth={daysInCurrentMonth}
            />
          ))}
        </div>
      </div>
    </>
  );
}
