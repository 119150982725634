import { apiSlice } from '../api/apiSlice';

export const schoolsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    toggleSchoolStatus: builder.mutation({
      query: (id: string | number) => ({
        url: `/schools/toggle-status/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Invoices', 'School', 'SingleSchool'],
    }),
    uploadBanner: builder.mutation({
      query: ({ id, body }: { id: string; body: any }) => ({
        url: `/schools/upload-banner/${id}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['SingleSchool'],
    }),
    downloadList: builder.mutation({
      query: (params: { [key: string]: string }) => ({
        url: '/schools/export/',
        method: 'GET',
        params,
        responseHandler: async (response) => {
          if (
            response.status === 200 &&
            response.headers.get('content-type')?.includes('text/csv')
          ) {
            window.open(
              window.URL.createObjectURL(await response.blob()),
              '_blank',
              'noopener,noreferrer',
            );
          } else {
            console.error('Unexpected content type or response status');
          }
        },
        cache: 'no-cache',
      }),
    }),
    getSchoolsByOgmaId: builder.query({
      query: (id: string) => ({
        url: `/schools/by-ogma/${id}`,
        method: 'GET',
      }),
    }),
    // getHoursById: builder.query({
    //   query: (id: number) => ({
    //     url: `/schools/hours/${id}`,
    //     method: 'GET',
    //   }),
    // }),
    getSchoolsEnabledCoach: builder.query({
      query: (id: number) => ({
        url: `/schools/en-hours/${id}`,
        method: 'GET',
      }),
      providesTags: ['SchoolEnCoach'],
    }),
    // changeSubplans: builder.mutation({
    //   query: (body: { id: number; partial_fee: number }) => ({
    //     url: '/schools/subplans',
    //     method: 'POST',
    //     body,
    //   }),
    //   invalidatesTags: ['SchoolPlan'],
    // }),
    // getSubplansById: builder.query({
    //   query: (id: number | string) => ({
    //     url: `/schools/subplans/${id}`,
    //     method: 'GET',
    //   }),
    //   providesTags: ['SchoolPlan'],
    // }),
    getSchoolsByOrgId: builder.query({
      query: (orgId: number) => ({
        url: `/schools/by-org-id/${orgId}`,
        method: 'GET',
      }),
      providesTags: ['OgmaSchools'],
    }),
    getAllSchools: builder.query({
      query: (params) => ({
        url: '/schools/all',
        method: 'GET',
        params: {
          ...params,
          page: params.page ?? 1,
        },
      }),
      providesTags: ['School'],
    }),
    getSchoolsWithoutOrg: builder.query({
      query: () => ({
        url: '/schools/without-org',
        method: 'GET',
      }),
      providesTags: ['School'],
    }),
    archiveSchool: builder.mutation({
      query: (id) => ({
        url: `/schools/archive/${id}`,
        method: 'Post',
      }),
      onQueryStarted: async (_, api) => {
        try {
          await api.queryFulfilled;
          api.dispatch(apiSlice.util.resetApiState());
        } catch (error) {
          console.log(error);
        }
      },
    }),
    getSchoolById: builder.query({
      query: (id) => ({
        url: `/schools/${id}`,
        method: 'GET',
      }),
      providesTags: ['SingleSchool'],
    }),
    createSchool: builder.mutation({
      query: (body) => ({
        url: '/schools/new',
        method: 'Post',
        body,
      }),
      invalidatesTags: ['School'],
    }),
    updateSchool: builder.mutation({
      query: ({ id, body }) => ({
        url: `/schools/${id}`,
        method: 'put',
        body,
      }),
      invalidatesTags: ['SingleSchool', 'School'],
    }),
    getAllTeamsBySchoolId: builder.query({
      query: (id) => ({
        url: `/teams/by-school/${id}`,
        method: 'GET',
      }),
      providesTags: ['School', 'Team'],
    }),
    getNewestSchools: builder.query({
      query: () => ({
        url: '/schools/latest/schools',
        method: 'GET',
      }),
      providesTags: ['latestSchools'],
    }),
    // uploadImage: builder.mutation({
    //   query: (image) => ({
    //     url: '/schools/upload-image',
    //     method: 'POST',
    //     body: image,
    //   }),
    // }),
    changeSchoolProfilePhoto: builder.mutation({
      query: ({ photo, id }) => ({
        url: `/schools/profile-photo/${id}`,
        method: 'PUT',
        body: photo,
      }),
      invalidatesTags: ['School', 'SingleSchool'],
    }),
    generateSchoolInviteLink: builder.mutation({
      query: (body: { schoolId: number; invalidateOldLinks: boolean }) => ({
        url: '/schools/generate-school-invite-link',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['School', 'SingleSchool'],
    }),
  }),
});

export const {
  useUploadBannerMutation,
  useDownloadListMutation,
  useGetSchoolsByOgmaIdQuery,
  useGetSchoolsEnabledCoachQuery,
  // useLazyGetHoursByIdQuery,
  // useChangeSubplansMutation,
  // useGetSubplansByIdQuery,
  useGetSchoolsByOrgIdQuery,
  useGetSchoolByIdQuery,
  useGetAllSchoolsQuery,
  useCreateSchoolMutation,
  useUpdateSchoolMutation,
  useGetSchoolsWithoutOrgQuery,
  useArchiveSchoolMutation,
  useGetAllTeamsBySchoolIdQuery,
  useGetNewestSchoolsQuery,
  useChangeSchoolProfilePhotoMutation,
  // useUploadImageMutation,
  useToggleSchoolStatusMutation,
  useGenerateSchoolInviteLinkMutation,
} = schoolsApiSlice;
