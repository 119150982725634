import { CheckboxListTeamsTournament } from './component/checkbox-list-teams-tournament';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { OutsideClick } from '../../../../../../components/outside-click';
import {
  useGetTournamentByIdQuery,
  useUpdateTournamentTeamMutation,
} from '../../../../../../slices/tournamentApiSlice';
import ModalHeader from '../../../../../../components/modal-header';
import ModalFooter from '../../../../../../components/modal-footer';
import { CustomError } from '../../../../../../utils/custom-types';
import { TeamModel } from '../../../../../../model/team.model';

function NewTeamTournament() {
  const [chosenTeam, setChosenTeam] = useState<object[]>([]);
  const param = useParams();

  const [updateTournamentTeam, { isLoading, isSuccess, isError }] =
    useUpdateTournamentTeamMutation();
  const {
    error,
    data: tournamentData,
    isLoading: tournamentIsLoading,
    isError: tournamentIsError,
    isSuccess: tournamentIsSuccess,
  } = useGetTournamentByIdQuery(param.id);
  useEffect(() => {
    if (tournamentData?.teams) {
      setChosenTeam((s) => {
        const newTeams = tournamentData.teams.filter((team: any) => !s.includes(team));
        return [
          ...s.map((t: any) => ({
            id: t.id,
            image: t.image,
            teamName: new TeamModel(t).getOptionName(),
          })),
          ...newTeams.map((t: any) => ({
            id: t.id,
            image: t.image,
            teamName: new TeamModel(t).getOptionName(),
          })),
        ];
      });
    }
  }, [tournamentData]);

  const onSubmit = (values: any, errors: any) => {
    console.log('test per errora tournament teams', errors);
    const data = { ...values };

    data.teams = [];
    chosenTeam.forEach((t: any) => data.teams.push({ id: t.id }));

    delete data.id;

    console.log('test update tournament', data);
    updateTournamentTeam({ id: param.id, body: data })
      .unwrap()
      .then((res: any) => setChosenTeam([]))
      .catch((error: any) => console.log(error));
  };

  const formInputs = [
    {
      mainTitle: 'Tournament Information',
      name: 'teams',
      label: 'Teams',
      type: 'text',
      placeholder: 'Enter the name of team you want to add...',
      isTrue: true,
      search: 'team',
      setChosenTeam,
      chosenTeam,
    },
  ];

  const initialValues: { [key: string]: string } = {};
  formInputs.forEach((input) => (initialValues[input.name] = ''));

  if (tournamentIsError || (tournamentIsSuccess && !tournamentData))
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError).data.message }} />;
  if (tournamentIsLoading) return <p>Loading...</p>;
  // if (isDataLoading) return <p>Loading ...</p>;
  if (isSuccess) return <Navigate to='../' />;
  return (
    <>
      <OutsideClick />
      <Formik
        // validationSchema={ }
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Form>
              <div className='fixed top-0 left-0 bottom-0 right-0 m-auto z-10 w-auto  max-w-2xl h-[90vh] max-h-[90vh]'>
                <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none h-full'>
                  <ModalHeader name={'Edit Teams'} />
                  <div className='relative px-3 flex-auto overflow-auto'>
                    <CheckboxListTeamsTournament
                      chosenTeam={chosenTeam}
                      setChosenTeam={setChosenTeam}
                    />
                  </div>
                  <ModalFooter isLoading={tournamentIsLoading} />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default NewTeamTournament;
