import { Navigate } from 'react-router';
import { Formik, Form } from 'formik';
import { Link, useParams } from 'react-router-dom';
import {
  useCheckIfMatchCanBeDeletedQuery,
  useCancelMatchMutation,
} from '../../slices/matchApiSlice';
import { OutsideClick } from '../../components/outside-click';
import { CustomError } from '../../utils/custom-types';
import { SubmitButton } from '../../components/submit-button';
import { useEffect } from 'react';

const CancelMatch = () => {
  const params = useParams();
  const [cancelMatch, { data, isSuccess, isError, isLoading, error }] = useCancelMatchMutation();
  useEffect(() => {
    console.log(params);
    console.log(params.id);
  }, [params?.id]);
  const onSubmit = () => {
    if (!params?.id) return;
    cancelMatch(params?.id);
  };
  if (isError)
    return (
      <Navigate
        to='/went-wrong'
        state={{
          message: (error as CustomError)?.data?.message ?? (error as CustomError)?.message,
        }}
      />
    );
  if (isLoading) return <p>Loading...</p>;

  if (isSuccess) return <Navigate to='../' />;
  return (
    <div className='fixed z-20 top-0 left-0 h-[100vh] w-[100vw] flex justify-center items-center'>
      <OutsideClick />
      <div className=' flex flex-col items-center gap-5 z-20 p-10 bg-white rounded-xl shadow-xl'>
        <p>Are you sure you want to cancel this match?</p>
        <div className='flex gap-2'>
          <SubmitButton title='Yes' isLoading={isLoading} onSubmit={onSubmit} />
          <Link
            to='../'
            className='px-8 text-center text-sm py-2 bg-white text-black border font-semibold rounded-lg hover:bg-gray-100'
          >
            No
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CancelMatch;
