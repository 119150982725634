import { apiSlice } from '../api/apiSlice';
import { matchApiSlice } from './matchApiSlice';

export const gameApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    toggleFavGame: builder.mutation({
      query: (id: number) => ({
        url: `/game/toggle-fav/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['FavoriteGames', 'Game'],
    }),
    updateGame: builder.mutation({
      query: ({ id, name, isArchived }: { id: string; name?: string; isArchived?: 1 | 0 }) => ({
        url: `/game/update/${id}`,
        method: 'PUT',
        body: { name, isArchived },
      }),
      invalidatesTags: ['Game', 'InGameName'],
    }),
    getGameById: builder.query({
      query: (id: string) => ({
        url: `/game/id/${id}`,
        method: 'GET',
      }),
    }),
    getAllGames: builder.query({
      query: (query) => ({
        url: `/game?${query.query}=${query.param}`,
        method: 'GET',
      }),
      providesTags: ['Game'],
    }),
    createGame: builder.mutation({
      query: (body) => ({
        url: 'game/create',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Game', 'SingleSeasonInfo'],
    }),
    getStatsByGame: builder.query({
      query: ({ gameId, studentId }) => ({
        url: `game/${gameId}/stats-by-game/${studentId}`,
        method: 'Get',
      }),
    }),
    searchGameByName: builder.mutation({
      query: (data) => ({
        url: `game/by-name?name=${data}`,
        method: 'Get',
      }),
    }),
    connectInGameName: builder.mutation({
      query: (body) => ({
        url: '/game/connect',
        method: 'POST',
        body,
      }),

      invalidatesTags: [
        'InGameName',
        'SingleUser',
        'SingleSeasonInfo',
        'SingleTournament',
        'SingleMatchInfo',
        'Match',
        'Team',
      ],
    }),
    deleteStudentInGameNameById: builder.mutation({
      query: (body) => ({
        url: '/game/in-game-name',
        method: 'DELETE',
        body,
      }),
      invalidatesTags: [
        'InGameName',
        'SingleUser',
        'SingleSeasonInfo',
        'SingleTournament',
        'SingleMatchInfo',
        'DiscordName',
      ],
    }),
    getStudentInGameNameById: builder.query({
      query: (id) => ({
        url: `/game/in-game-name`,
        method: 'GET',
      }),
      providesTags: ['InGameName', 'DiscordName'],
    }),
    updateStudentInGameNameById: builder.mutation({
      query: (body) => ({
        url: `/game/in-game-name`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['InGameName', 'SingleUser'],
    }),
    getMostPlayedGames: builder.query({
      query: () => ({
        url: '/game/most-played',
        method: 'GET',
      }),
      providesTags: ['InGameName'],
    }),
  }),
});

export const {
  useUpdateGameMutation,
  useUpdateStudentInGameNameByIdMutation,
  useGetGameByIdQuery,
  useGetAllGamesQuery,
  useCreateGameMutation,
  // useGetStatsByGameMutation,
  useGetStatsByGameQuery,
  useSearchGameByNameMutation,
  useConnectInGameNameMutation,
  useDeleteStudentInGameNameByIdMutation,
  useGetStudentInGameNameByIdQuery,
  useGetMostPlayedGamesQuery,
  useToggleFavGameMutation,
  // useGetStatsByGameQuery,
  useLazyGetStatsByGameQuery,
} = gameApiSlice;
