import React from 'react';
import { NavLink } from 'react-router-dom';

export function UnArchiveOptionCard({
  option,
  description,
  isActive,
  to,
}: {
  option: string;
  description?: string;
  isActive?: boolean;
  to: string;
}) {
  return (
    <NavLink to={to} className={'tournamentsNavButton'}>
      <div className={`tournamentsNavButtonIcon  ${isActive ? '' : ''}   `}></div>
      <div className='flex flex-col justify-between'>
        <h2 className='font-bold text-xs'>{option}</h2>

        <p className='text-xs'>{description ? description : ''} </p>
      </div>
    </NavLink>
  );
}
