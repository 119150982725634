import { SentRescheduleRequestCard } from './components/sent-reschedule-request-card';
import { ReceivedRescheduleRequestCard } from './components/received-reschedule-request-card';
import { useEffect, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { Link, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import { selectMatchData, selectReschedules } from '../../../slices/matchSlice';
import { RescheduleRequestModel } from '../../../model/reschedule-request.model';
import { selectUser } from '../../../slices/auth';
import { MatchStatus, UserRoles, rescheduleRoles } from '../../../utils/constants';
import { useGetMatchRescheduleHistoryByIdQuery } from '../../../slices/matchApiSlice';
import { dateAndTimeFormatter } from '../../../utils/dateFormatter';
import { CustomError } from '../../../utils/custom-types';

const ViewReschedules = () => {
  const [show, setShow] = useState(false);
  const rescheduleData = useSelector(selectReschedules);
  const matchData = useSelector(selectMatchData);
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const param = useParams();
  const { data, isLoading, isError, error } = useGetMatchRescheduleHistoryByIdQuery(param?.id);

  function handleExit() {
    setShow(false);
    setTimeout(() => navigate('../'), 600);
  }

  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), 100);
    return () => clearTimeout(timeout);
  }, []);
  if (
    (user.roles === UserRoles.Guardian || user.roles === UserRoles.Coach) &&
    (matchData.status !== MatchStatus.Upcoming ||
      matchData.status !== MatchStatus.UpcomingRescheduledFor ||
      matchData.status !== MatchStatus.RescheduleRequested)
  ) {
    return <Navigate to='../' />;
  }

  if (isLoading) return <span>loading</span>;

  if (isError)
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError).data.message }} />;

  return (
    <div
      className={`absolute max-w-[500px] top-[150px]  bg-white bottom-0 w-full md:w-1/2 z-10 border-1 p-2 duration-500 ${
        show ? '-left-0' : '-left-[100%]'
      } overflow-auto`}
    >
      <Outlet />
      <div className='flex justify-end'>
        <AiOutlineClose className='cursor-pointer' onClick={handleExit} />
      </div>
      {(user.roles === UserRoles.Ogma && matchData?.canChat) ||
      matchData?.home_team[0]?.captain === user?.id ||
      matchData?.away_team[0]?.captain === user?.id ? (
        <>
          <div className='flex justify-between'>
            <h2 className='text-sm font-bold'>Sent Reschedule Requests</h2>
          </div>
          <div className='hover:bg-slate-100 flex flex-col items-center border-1 rounded-lg'>
            {!rescheduleData?.filter((r: RescheduleRequestModel) => r.requested === user.id)
              ?.length && <p>There are no sent reschedule requests!</p>}
            {rescheduleData
              ?.filter((r: RescheduleRequestModel) => r.requested === user.id)
              ?.map((d: any, i: number) => (
                <SentRescheduleRequestCard key={i} model={new RescheduleRequestModel(d)} />
              ))}
          </div>

          <h2 className='text-sm font-bold'>Received Reschedule Requests</h2>
          {!rescheduleData?.filter(
            (r: RescheduleRequestModel) =>
              r.requested !== user.id &&
              (user.roles === UserRoles.Ogma ? !r.admin_status : !r.captain_status),
          )?.length && <p>There are no incoming reschedule requests!</p>}
          {rescheduleData
            ?.filter(
              (r: RescheduleRequestModel) =>
                r.requested !== user.id &&
                (user.roles === UserRoles.Ogma ? !r.admin_status : !r.captain_status),
            )
            ?.map((d: any, i: number) => (
              <ReceivedRescheduleRequestCard key={i} model={new RescheduleRequestModel(d)} />
            ))}
        </>
      ) : null}

      <div className='flex flex-col gap-3 mt-2'>
        <h2 className='text-sm font-bold'>Reschedule History</h2>
        <div>
          {data?.map((r: any, id: number) => (
            <div className='flex flex-col gap-3 border-1 rounded-lg p-2'>
              <div className='flex justify-between gap-2'>
                {/* <span className='text-sm'>Time:{timeFormatter(r?.date)?.split(';')[0]}</span> */}
                <span className='text-sm'>
                  Request At:{dateAndTimeFormatter(r?.date ?? '').replace(';', '')}
                </span>
              </div>
              <div className='flex justify-between gap-2'>
                <span className='text-sm'>
                  {r?.action === 0
                    ? `Requested by: ${r?.User?.username}`
                    : r?.action === 1
                    ? `Approved by: ${r?.User?.username}`
                    : r?.action === 2
                    ? `Rejected by: ${r?.User?.username}`
                    : r?.action === 3
                    ? `Edited by: ${r?.User?.username}`
                    : null}
                </span>
                <span className='text-sm'>Role:{rescheduleRoles[r?.User?.roles]}</span>
              </div>
              <div className='flex justify-between gap-2'>
                {/* <span className='text-sm'>Match reschedule time:{timeFormatter(r?.Reschedule?.new_date)?.split(';')[0]}</span> */}
                <span className='text-sm'>
                  Match reschedule at:{dateAndTimeFormatter(r?.newDate ?? '').replace(';', '')}
                </span>
              </div>
              {id === 0 &&
              r?.Reschedule?.admin_status === 0 &&
              r?.Reschedule?.captain_status === 0 &&
              (r?.User.id === user?.id || user.roles === UserRoles.Admin) ? (
                <div className='flex justify-center gap-2'>
                  <Link
                    to={`edit/${r?.Reschedule?.id}`}
                    className='hover:bg-opacity-80 cursor-pointer select-none flex p-2 min-w-[80px] justify-center py-1 gap-2 rounded-md bg-green-600 text-white text-xs uppercase'
                  >
                    Edit
                  </Link>
                  <Link
                    to={`cancel/${r?.Reschedule?.id}`}
                    className='hover:bg-opacity-80 cursor-pointer select-none flex p-2 min-w-[80px] justify-center py-1 gap-2 rounded-md bg-red-600 text-white text-xs uppercase'
                  >
                    Cancel
                  </Link>
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ViewReschedules;
