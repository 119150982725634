import { apiSlice } from '../api/apiSlice';

import {
  initClassroomChatMessages,
  initOtherClassrooms,
  initOtherClassroomsBySameGame,
  pushClassroomChatMessage,
  pushOtherClassrooms,
  pushOtherClassroomsBySameGame,
} from './matchSlice';
import { addDashboardClassrooms, initDashboardClassrooms } from './userSlice';

export const classroomApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    changeClassroomBanner: builder.mutation({
      query: ({ id, body }: { id: string; body: FormData }) => ({
        url: `/classrooms/banner/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Classroom'],
    }),
    getLesson: builder.query({
      query: (lesson_id: number | string) => ({
        url: `/classrooms/lesson/${lesson_id}`,
        method: 'GET',
      }),
      providesTags: ['Lesson'],
    }),
    previewClassroom: builder.query({
      query: (id: string) => ({
        url: `/classrooms/preview/${id}`,
        method: 'POST',
      }),
      providesTags: ['Classroom'],
    }),
    joinClassroom: builder.query({
      query: (id: string) => ({
        url: `/classrooms/join-classroom/${id}`,
        method: 'POST',
      }),
      providesTags: ['Classroom'],
    }),
    responseToRequest: builder.mutation({
      query: (data: {
        id: number;
        body: {
          response: number;
          hours: number;
          school_id: number;
        };
      }) => ({
        url: `/classrooms/response-request/${data.id}`,
        method: 'POST',
        body: data.body,
      }),
      invalidatesTags: ['SchoolHrRequests'],
    }),
    getSchoolsHourRequests: builder.query({
      query: () => ({
        url: '/classrooms/school-requests',
        method: 'GET',
      }),
      providesTags: ['SchoolHrRequests'],
    }),
    getRequestForOrg: builder.query({
      query: () => ({
        url: '/classrooms/hours-requests',
        method: 'GET',
      }),
      providesTags: ['SchoolHrRequests'],
    }),
    sendBookCoachHoursRequest: builder.mutation({
      query: (body: { start_time: string; end_time: string; classroom_id: number }) => ({
        url: '/classrooms/book-hours',
        method: 'POST',
        body,
      }),
    }),
    changeSchoolScheduledHours: builder.mutation({
      query: (body: {
        school_id: number;
        class_id: number;
        start_time: string;
        end_time: string;
      }) => ({
        url: '/classrooms/school-hours',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['SchoolHours'],
    }),
    getSchoolsScheduledHours: builder.query({
      query: (body: { school_id: number; classroom_id: number }) => ({
        url: '/classrooms/school-hours',
        method: 'POST',
        body,
      }),
      providesTags: ['SchoolHours'],
    }),
    getAllClassrooms: builder.query({
      query: () => ({
        url: '/classrooms/all',
        method: 'GET',
      }),
      providesTags: ['Classroom'],
    }),
    getClassroomById: builder.query({
      query: (id) => ({
        url: `/classrooms/${id}`,
        method: 'GET',
      }),
      providesTags: ['Classroom'],
    }),
    getAllClassroomsByUserId: builder.query({
      query: ({ id, query }) => ({
        url: `/classrooms/user/${id}?name=${query.name ? query.name : ''}&page=${
          query.page ?? 1
        }&limit=${query.limit ?? 10}`,
        method: 'GET',
      }),
      async onCacheEntryAdded(arg, { cacheDataLoaded, dispatch, getCacheEntry }) {
        await cacheDataLoaded;
        const entries = getCacheEntry();
        if (arg.page) dispatch(pushOtherClassrooms(entries.data.data));
        else dispatch(initOtherClassrooms(entries.data.data));
      },
      // async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
      //   const patchResult = dispatch(
      //     api.util.updateQueryData('getPost', id, (draft) => {
      //       Object.assign(draft, patch);
      //     }),
      //   );
      //   queryFulfilled.catch(patchResult.undo)
      // },
      providesTags: ['Classroom'],
    }),
    getAllClassroomsByGameId: builder.query({
      query: ({ id, page }) => ({
        url: `/classrooms/game/${id}?page=${page ? page : 1}`,
        method: 'GET',
      }),
      async onCacheEntryAdded(arg, { cacheDataLoaded, dispatch, getCacheEntry }) {
        await cacheDataLoaded;
        const entries = getCacheEntry();
        if (arg.page) dispatch(pushOtherClassroomsBySameGame(entries.data.data));
        else dispatch(initOtherClassroomsBySameGame(entries.data.data));
      },
      providesTags: ['Classroom', 'User'],
    }),
    createClassroom: builder.mutation({
      query: (data) => ({
        url: '/classrooms/new',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Classroom'],
    }),
    updateClassroom: builder.mutation({
      query: ({ id, body }) => ({
        url: `/classrooms/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Classroom'],
    }),
    archiveClassroom: builder.mutation({
      query: (id) => ({
        url: `/classrooms/archive/${id}`,
        method: 'POST',
      }),
      async onCacheEntryAdded(arg, { cacheDataLoaded, dispatch, getCacheEntry }) {
        await cacheDataLoaded;
        dispatch(initOtherClassrooms([]));
        dispatch(initOtherClassroomsBySameGame([]));
      },
      invalidatesTags: ['Classroom'],
    }),
    getAllLessons: builder.query({
      query: () => ({
        url: '/classrooms/lessons/all',
        method: 'GET',
      }),
      providesTags: ['Lesson'],
    }),
    getLessonById: builder.query({
      query: (id) => ({
        url: `/classrooms/lessons/${id}`,
        method: 'GET',
      }),
      providesTags: ['Lesson'],
    }),
    getAllLessonsByClassroomId: builder.query({
      query: ({ classroomId, query }) => ({
        url: `/classrooms/lessons/classroom/${classroomId}?name=${
          query.name ? query.name : ''
        }&page=${query.page ?? 1}`,
        method: 'GET',
      }),
      providesTags: ['Lesson'],
    }),
    createLesson: builder.mutation({
      query: (data) => ({
        url: '/classrooms/lessons/new',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Lesson'],
    }),
    updateLesson: builder.mutation({
      query: ({ id, body }) => ({
        url: `/classrooms/lessons/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Lesson'],
    }),
    addLessonContent: builder.mutation({
      query: ({ id, data }) => ({
        url: `/classrooms/lessons/update-content/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Lesson'],
    }),
    deleteLessonContent: builder.mutation({
      query: ({ id, body }) => ({
        url: `/classrooms/lessons/content/${id}`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: ['Lesson'],
    }),
    deleteClassroom: builder.mutation({
      query: (id) => ({
        url: `/classrooms/classrooms/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Classroom'],
    }),
    deleteLesson: builder.mutation({
      query: (id) => ({
        url: `/classrooms/lessons/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Lesson'],
    }),
    getAllClassroomsByOrganizationId: builder.query({
      query: (data: { limit?: number | string; page?: number }) => ({
        url: `/classrooms/by-organization?page=${data.page ?? 1}${
          data.limit ? `&limit=${data.limit}` : ''
        }`,
        method: 'GET',
      }),
      async onCacheEntryAdded(arg, { cacheDataLoaded, dispatch, getCacheEntry }) {
        await cacheDataLoaded;
        const entries = getCacheEntry();
        if (arg.page) dispatch(addDashboardClassrooms(entries.data.data));
        else dispatch(initDashboardClassrooms(entries.data.data));
      },
      providesTags: ['Classroom', 'User'],
    }),
    initClassroomChatMessages: builder.query({
      query: ({ id, page }) => ({
        url: `/classrooms/chat/${id}?page=${page ? page : 1}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 1,
      async onCacheEntryAdded(arg, { cacheDataLoaded, dispatch, getCacheEntry }) {
        await cacheDataLoaded;
        const entries = getCacheEntry();
        if (arg.page) dispatch(pushClassroomChatMessage(entries.data.data));
        else dispatch(initClassroomChatMessages(entries.data.data));
      },
    }),
  }),
});

export const {
  useGetLessonQuery,
  usePreviewClassroomQuery,
  useJoinClassroomQuery,
  useResponseToRequestMutation,
  useGetSchoolsHourRequestsQuery,
  useGetRequestForOrgQuery,
  useSendBookCoachHoursRequestMutation,
  useChangeSchoolScheduledHoursMutation,
  useGetSchoolsScheduledHoursQuery,
  useLazyGetSchoolsScheduledHoursQuery,
  useGetAllClassroomsQuery,
  useGetClassroomByIdQuery,
  useGetAllClassroomsByUserIdQuery,
  useCreateClassroomMutation,
  useUpdateClassroomMutation,
  useArchiveClassroomMutation,
  useGetAllLessonsQuery,
  useGetLessonByIdQuery,
  useGetAllLessonsByClassroomIdQuery,
  useCreateLessonMutation,
  useUpdateLessonMutation,
  useDeleteLessonMutation,
  useDeleteClassroomMutation,
  useGetAllClassroomsByOrganizationIdQuery,
  useInitClassroomChatMessagesQuery,
  useLazyInitClassroomChatMessagesQuery,
  useAddLessonContentMutation,
  useDeleteLessonContentMutation,
  useGetAllClassroomsByGameIdQuery,
  useChangeClassroomBannerMutation,
} = classroomApiSlice;
