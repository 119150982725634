import React, { useState, useEffect, FormEvent } from 'react';
import { useStateContext } from '../../../contexts/ContextProvider';
import ReCAPTCHA from 'react-google-recaptcha';
import { ErrorMessage, Field, Form, Formik, FormikValues } from 'formik';
import { clearMessage } from '../../../slices/message';
import { useAppDispatch } from '../../../store';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router-dom';
import { FormInput } from '../../../components/form-input/form-input';
import { setCredentials } from '../../../slices/auth';
import { SubmitButton } from '../../../components/submit-button';
import {
  useAcceptSchoolInviteMutation,
  useAcceptStudentInviteMutation,
} from '../../../slices/userApiSlice';
import { studentSchema } from './schema/student-register-form.schema';
import { allStates, gendersOptions } from '../../../utils/constants';
import { CustomError } from '../../../utils/custom-types';

import { gradeLevels } from '../../../utils/utils';
import ModalTerms from './ModalTerms';
import { AiOutlineLoading } from 'react-icons/ai';

type studentRegisterFormProps = {
  isSchoolRegistrationLink: boolean;
};

const StudentRegisterForm = ({ isSchoolRegistrationLink }: studentRegisterFormProps) => {
  const param = useParams();
  const dispatch = useAppDispatch();
  // const { isLoggedIn, setIsLoggedIn } = useStateContext();
  const [successful, setSuccessful] = useState(false);
  const { modalTerms, setModalTerms } = useStateContext();
  const { modalTermsCheckbox, setModalTermsCheckbox } = useStateContext();
  const { copaisChecked, setCopaisChecked } = useStateContext();

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const [captcha, setCaptcha] = useState('');

  function onChange(value: string | null) {
    if (value) setCaptcha(value);
  }
  const handleCopaInput = (e: any) => {
    if (e.target.checked) {
      setCopaisChecked(true);
    } else {
      setCopaisChecked(false);
    }
  };
  // const Copa =() => {
  //   copaisChecked ?  <Link to="/login" /> : <Link to="/copa-modal" />
  // }
  const formInputs = [
    // {
    //   mainTitle: "School Information",
    //   name: "schoolName",
    //   label: "School Name",
    //   type: "text",
    //   placeholder: "Please select your school",
    // },
    {
      mainTitle: 'Student Information',
      name: 'firstName',
      label: 'First Name',
      type: 'text',
      placeholder: 'Enter first name',
    },
    {
      mainTitle: 'Student Information',
      name: 'lastName',
      label: 'Last Name',
      type: 'text',
      placeholder: 'Enter last name',
    },
    {
      mainTitle: 'Student Information',
      name: 'username',
      label: 'Username',
      type: 'text',
      placeholder: 'Enter username',
    },
    ...(isSchoolRegistrationLink
      ? [
          {
            mainTitle: 'Student Information',
            name: 'email',
            label: 'Email Address',
            type: 'text',
            placeholder: 'Enter email address',
          },
        ]
      : []),

    // {
    //   mainTitle: "Your Information",
    //   name: "email",
    //   label: "Email Address",
    //   type: "text",
    //   placeholder: "Enter your student email",
    // },

    {
      mainTitle: 'Student Information',
      name: 'gradeLevel',
      label: 'Grade Level',
      type: 'text',
      isSelect: true,
      options: gradeLevels,
      placeholder: 'Ex: 8th Grade',
    },
    // {
    //   mainTitle: 'Student Information',
    //   name: 'city',
    //   label: 'City',
    //   placeholder: 'Enter city',
    //   type: 'text',
    // },
    // {
    //   mainTitle: 'Student Information',
    //   name: 'state',
    //   label: 'State',
    //   type: 'text',
    //   placeholder: 'Enter state',
    //   isSelect: true,
    //   options: allStates ?? [],
    // },
    // {
    //   mainTitle: 'Student Information',
    //   name: 'city',
    //   label: 'City',
    //   placeholder: 'Enter city',
    //   type: 'text',
    // },
    // {
    //   mainTitle: 'Student Information',
    //   name: 'state',
    //   label: 'State',
    //   type: 'text',
    //   placeholder: 'Enter state',
    //   isSelect: true,
    //   options: allStates ?? [],
    // },
    // {
    //   mainTitle: 'Student Information',
    //   name: 'streetAddress',
    //   label: 'Street Address',
    //   placeholder: 'Enter street address',
    //   type: 'text',
    // },
    // {
    //   mainTitle: 'Student Information',
    //   name: 'zipCode',
    //   label: 'Zip Code',
    //   type: 'number',
    //   placeholder: 'Enter zip code',
    // },
    // {
    //   mainTitle: 'Student Information',
    //   name: 'zipCode',
    //   label: 'Zip Code',
    //   type: 'number',
    //   placeholder: 'Enter zip code',
    // },
    {
      mainTitle: 'Student Information',
      name: 'gender',
      label: 'Gender',
      type: 'text',
      isSelect: true,
      options: gendersOptions,
    },
    // {
    //   mainTitle: 'Student Information',
    //   name: 'dateOfBirth',
    //   label: 'Date of birth',
    //   type: 'date',
    //   placeholder: 'Enter date of birth',
    // },
    // {
    //   mainTitle: 'Student Information',
    //   name: 'phoneNumber',
    //   label: 'Phone Number',
    //   type: 'text',
    //   placeholder: 'Enter phone number',
    // },
    // {
    //   mainTitle: 'Your Information',
    //   name: 'gender',
    //   label: 'Gender',
    //   type: 'text',
    //   placeholder: 'Enter your gender',
    //   isSelect: true,
    //   options: [
    //     { id: 0, name: 'M' },
    //     { id: 1, name: 'F' },
    //   ],
    // },
    {
      mainTitle: 'Security Information',
      name: 'password',
      label: 'Password',
      type: 'password',
      placeholder: 'Enter password',
    },

    {
      mainTitle: 'Security Information',
      name: 'confirmPassword',
      label: 'Confirm Password',
      type: 'password',
      placeholder: 'Re-enter password',
    },
  ];
  // const [signUp, { isLoading, isError, isSuccess }] = useSignUpMutation();
  const [acceptInvite, { isLoading, isError, error, isSuccess }] = isSchoolRegistrationLink
    ? useAcceptSchoolInviteMutation()
    : useAcceptStudentInviteMutation();

  const handleRegister = (value: FormikValues) => {
    if (value.ageAgreement) {
      value.ageAgreement = 1;
    }
    if (!value.city) {
      value.city = '';
    }
    if (!value.state) {
      value.state = '';
    }
    if (!value.zipCode) {
      delete value.zipCode;
    } else value.zipCode = value?.zipCode ? +value.zipCode : '';
    acceptInvite({
      token: `Bearer ${param.token}`,
      body: {
        firstName: value.firstName,
        lastName: value.lastName,
        password: value.password,
        username: value.username,
        confirmPassword: value.confirmPassword,
        gradeLevel: value.gradeLevel,
        // dateOfBirth: new Date(value.dateOfBirth).toISOString(),
        isVerified: 1,
        // phoneNumber: value.phoneNumber,
        roles: 2,
        state: value.state,
        // streetAddress: value.streetAddress,
        zipCode: value.zipCode,
        city: value.city,
        gender: value.gender?.toString(),
        ageAgreement: value.ageAgreement,
        email: value.email,
      },
      recaptcha: captcha,
    })
      .unwrap()
      .then((res) => dispatch(setCredentials(res)));
    setSuccessful(false);
  };

  const initialValues: { [key: string]: string } = {};
  formInputs.forEach((input) => (initialValues[input.name] = ''));

  return (
    <>
      <Formik
        initialValues={{ ...initialValues, check: false, ageAgreement: false }}
        validationSchema={studentSchema(isSchoolRegistrationLink)}
        onSubmit={handleRegister}
      >
        {({ isSubmitting, errors, setFieldValue, values }) => (
          <Form className='max-w-3xl m-4 p-10 bg-white rounded shadow-xl'>
            <h1 className='flex items-center justify-center mt-1 text-2xl pt-1 mb-2'>
              Create a new Student account
            </h1>
            <div className='flex flex-col gap-2'>
              {formInputs.map((input: any, i: number) => (
                <React.Fragment key={i}>
                  {!formInputs[i - 1] || formInputs[i - 1]?.mainTitle !== input.mainTitle ? (
                    <>
                      <div className='flex flex-col'>
                        <div className='mb-2'>
                          <p className='text-gray-800 font-bold pt-6'>{input.mainTitle}</p>
                          <hr />
                        </div>
                        <div className='flex flex-wrap gap-5'>
                          {formInputs
                            .filter((inp1: any) => inp1.mainTitle === input.mainTitle)
                            .map((inp: any, i: number) => (
                              <div key={i} className='flex-1 min-w-[300px]'>
                                <FormInput {...inp} />
                              </div>
                            ))}
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </React.Fragment>
              ))}
              {/*reCaptcha */}
              <div className=' flex items-center justify-center mt-8'>
                <ReCAPTCHA
                  sitekey={import.meta.env.VITE_GOOGLE_RECAPTCHA_KEY}
                  onChange={(value) => onChange(value)}
                />
              </div>
            </div>

            <p className=' flex items-center justify-center mt-7'>
              <Field
                name='ageAgreement'
                className='mr-2'
                type='checkbox'
                // value={copaisChecked}
                // onChange={handleCopaInput}
              />

              <span className='font-italic'>I am 13 years or older or have parental approval.</span>
            </p>

            <div className='flex justify-center p-2'>
              <ErrorMessage name='ageAgreement' component='div' className='text-red-400 text-xs ' />
            </div>

            <p className=' flex items-center justify-center mt-7'>
              <Field
                name='check'
                className='mr-2'
                type='checkbox'
                checked={values.check}
                onChange={(e: any) => {
                  setFieldValue('check', e.target.checked);
                }}
                // onClick={() => {
                //   setModalTermsCheckbox(!modalTermsCheckbox);
                // }}
                // checked={modalTermsCheckbox}
              />
              {modalTerms && (
                <ModalTerms
                  setModalTerms={setModalTerms}
                  setModalTermsCheckbox={setModalTermsCheckbox}
                  setFieldValue={setFieldValue}
                />
              )}

              <span
                onClick={() => {
                  setModalTerms(true);
                }}
                className='font-italic'
              >
                I have read and agree to the{' '}
                <span className='text-black underline font-bold'> Terms and condition</span>
              </span>
            </p>
            <div className='flex justify-center p-2'>
              <ErrorMessage name='check' component='div' className='text-red-400 text-xs m-auto' />
            </div>
            <div className='flex justify-center'>
              <SubmitButton isLoading={isLoading} />
            </div>

            {isError && <p className='text-red-300'>{(error as CustomError)?.data?.message}</p>}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default StudentRegisterForm;
