import { DragEvent, useEffect, useRef, useState } from 'react';

export function DragDropFile({ file, setFile }: any) {
  const [dragActive, setDragActive] = useState(false);
  const handleDrag = function (e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };
  // triggers when file is dropped
  const handleDrop = function (e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // console.log(e);
      // handleFiles(e.dataTransfer.files);
      setFile(e.dataTransfer.files[0]);
    }
  };
  const handleChange = (e: any) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      // at least one file has been selected so do something
      // handleFiles(e.target.files);
      setFile(e.target.files[0]);
    }
  };

  useEffect(() => {
    console.log('file', file);
  }, [file]);
  const inputRef = useRef<any>(null);
  const onButtonClick = (e: any) => {
    e.preventDefault();
    if (inputRef.current) inputRef?.current?.click();
  };
  return (
    <form
      onDragEnter={handleDrag}
      className={`border-2 rounded-lg flex flex-col items-center justify-center text-center border-dashed p-2 h-[180px] ${
        dragActive ? 'dragging-animation' : ''
      }`}
      onSubmit={(e) => e.preventDefault()}
    >
      {' '}
      <input
        className='hidden'
        accept='image/png, image/jpeg'
        ref={inputRef}
        type='file'
        id='input-file-upload'
        multiple={false}
        onChange={handleChange}
      />
      <label id='label-file-upload' htmlFor='input-file-upload'>
        {dragActive ? (
          <div>
            <span>You can drop here!</span>
          </div>
        ) : (
          <div>
            <span>
              Drop your screenshot here, or{' '}
              <button onClick={onButtonClick} className='hover:text-red-800 underline'>
                click to browse
              </button>
            </span>
          </div>
        )}
        {file && <p className='text-xs'>{file.name}</p>}
      </label>
      {dragActive && (
        <div
          className='absolute top-0 left-0 right-0 bottom-0'
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></div>
      )}
    </form>
  );
}
