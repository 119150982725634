import { useDroppable } from '@dnd-kit/core';
import { UserInfo } from './user-info';
export function CurrentTeam({
  userList,
  editMode,
  captain,
  limit,
  usersLength,
}: {
  userList: any;
  editMode: boolean;
  captain: number | null;
  limit: number;
  usersLength: number[];
}) {
  const { isOver, setNodeRef } = useDroppable({ id: 'active' });
  return (
    <div
      ref={setNodeRef}
      className={`flex flex-col items-center min-h-[200px] w-full gap-2 rounded-t-xl ${
        isOver ? 'bg-green-300' : ''
      }`}
    >
      <h2 className='font-bold text-xl text-center'>
        Current Team Roster - Limit: {limit}, Available Slots: {limit - usersLength.length}
      </h2>
      <div className='flex w-fit max-w-full justify-evenly  flex-wrap gap-2 '>
        {usersLength
          // @ts-ignore
          .sort((a: any, b: any) => (b === captain) - (a === captain))
          .map((u: any) => {
            const user = userList.filter((us: any) => us.id === u);
            return (
              <UserInfo
                userList={userList}
                type='activeMember'
                editMode={editMode}
                key={u}
                id={u}
                captain={captain}
                isArchived={!user.length}
                image={user?.image}
                {...user?.[0]}
              />
            );
          })}
      </div>
    </div>
  );
}
