import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useEffect } from 'react';
import { clearMessage } from '../../../../slices/message';

export function SortableItem(props: any) {
  // props.id
  // JavaScript

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: props.id,
  });
  useEffect(() => {
    console.log('test atributet', attributes, listeners, transform, transition);
  }, [attributes, listeners, transform, transition]);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {props.children}
    </div>
  );
}
