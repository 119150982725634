import { Navigate, useParams } from 'react-router';
import { Formik, Form } from 'formik';
import { Link } from 'react-router-dom';
import {
  useCheckIfMatchCanBeDeletedQuery,
  useDeleteMatchMutation,
} from '../../slices/matchApiSlice';
import { OutsideClick } from '../../components/outside-click';
import { CustomError } from '../../utils/custom-types';
import { SubmitButton } from '../../components/submit-button';

const DeleteMatch = () => {
  const params = useParams();
  const {
    error,
    data: matchData,
    isSuccess: matchIsSuccess,
    isLoading: matchIsLoading,
    isError: matchIsError,
  } = useCheckIfMatchCanBeDeletedQuery(Number(params?.matchid), { skip: !params?.matchid });
  const [deleteMatch, { data, isSuccess, isError, isLoading }] = useDeleteMatchMutation();

  const onSubmit = () => {
    if (!params?.matchid) return;
    deleteMatch(params?.matchid);
  };
  if (matchIsLoading) return <p>Loading...</p>;
  if (matchIsError || (matchIsSuccess && !matchData))
    return (
      <Navigate
        to='/went-wrong'
        state={{
          message: (error as CustomError)?.data?.message ?? (error as CustomError)?.message,
        }}
      />
    );
  if (isSuccess) return <Navigate to='../' />;
  return (
    <div className='fixed z-20 top-0 left-0 h-[100vh] w-[100vw] flex justify-center items-center'>
      <OutsideClick />
      <div className=' flex flex-col items-center gap-5 z-20 p-10 bg-white rounded-xl shadow-xl'>
        <p>
          Are you sure you want to delete{' '}
          <span className='font-bold'>
            {matchData.home_team?.length ? matchData.home_team[0]?.teamName : 'null'} vs{' '}
            {matchData.away_team?.length ? matchData.away_team[0]?.teamName : 'null'}
          </span>
          ?
        </p>
        <Formik initialValues={{}} onSubmit={onSubmit}>
          <Form className='flex gap-2'>
            <SubmitButton title='Delete' isLoading={isLoading} />
            <Link
              to='../'
              className='px-8 text-center text-sm py-2 bg-white text-black border font-semibold rounded-lg hover:bg-gray-100'
            >
              No
            </Link>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default DeleteMatch;
