import moment from 'moment';
import * as yup from 'yup';
export const NewSchoolInvoiceSchema = yup.object().shape({
  start_date: yup
    .date()
    .required('Required!')
    .min(moment('2000-01-01'), 'Selected date must be from the year 2000 and later'),
  end_date: yup.string().required('Required!'),
  total: yup.string().required('Required!'),
  school: yup.string().required('Required!'),
  status: yup.number().required('Required!'),
  user: yup.string().required('Required!'),
  season: yup.string().required('Required!'),
  // type: yup.string().required('Required'),
});
