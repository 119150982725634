import React, { createContext, ReactNode, useEffect, useState } from 'react';

const FormContext = createContext({});

export const FormProvider = ({ children }) => {
  const title = {
    0: 'Subscription Plan',
    1: 'Active Students',
    2: 'Overview',
  };

  const [page, setPage] = useState(0);
  const [tags, setTags] = useState([]);
  const [schoolData, setSchoolData] = useState('');
  const [selectedCard, setSelectedCard] = useState(null);

  const [data, setData] = useState({
    subscriptionPlan: '1',
    numberOfStudents: 1,
    percentageCover: 0,
    students: '',
    skipForNow: false,
    school: schoolData,
  });

  const calculation = {
    cost: data.numberOfStudents * 100,
    toBePaidByStudents:
      data.numberOfStudents * 100 - ((data.numberOfStudents * 100) / 100) * data.percentageCover,
    yourTotal:
      ((data.numberOfStudents * 100) / 100) * data.percentageCover +
      (schoolData.length >= 1 ? JSON.parse(schoolData).total : 0),
  };

  useEffect(() => {
    if (data.skipForNow) {
      setData((prevData) => ({
        ...prevData,
        students: '',
      }));
    }
  }, [data.skipForNow]);

  useEffect(() => {
    if (data.subscriptionPlan === '2') {
      setData((prevData) => ({
        ...prevData,
        percentageCover: 0,
      }));
    }
  }, [data.subscriptionPlan]);

  useEffect(() => {
    data.students = tags;
  }, [tags]);

  //   useEffect(() => {
  //     if (data.sameAsBilling) {
  //         setData(prevData => ({
  //             ...prevData,
  //             shipFirstName: prevData.billFirstName,
  //             shipLastName: prevData.billLastName,
  //             shipAddress1: prevData.billAddress1,
  //             shipAddress2: prevData.billAddress2,
  //             shipCity: prevData.billCity,
  //             shipState: prevData.billState,
  //             shipZipCode: prevData.billZipCode
  //         }))
  //     } else {
  //         setData(prevData => ({
  //             ...prevData,
  //             shipFirstName: "",
  //             shipLastName: "",
  //             shipAddress1: "",
  //             shipAddress2: "",
  //             shipCity: "",
  //             shipState: "",
  //             shipZipCode: ""
  //         }))
  //     }
  // }, [data.sameAsBilling])

  // const FORM_STATE = {
  //   selectedIndex: 0,
  //   steps: {
  //     subscriptionPlan: {
  //       valid: false,
  //       dirty: false,
  //       value: {
  //         option: '', //TOFIx
  //       },
  //     },
  //     activeStudents: {
  //       valid: false,
  //       dirty: false,
  //       value: {
  //         numberOfStudents: '',
  //         percentageCover: '',
  //         students: '',
  //         skipForNow: false
  //       }
  //     }
  //   }
  // }

  // const [form, setForm] = useState(FORM_STATE);

  const handleChange = (e) => {
    const type = e.target.type;
    const name = e.target.name;

    const value = type === 'checkbox' ? e.target.checked : e.target.value;
    // const value = e.target.value

    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const canSubmit =
    // [...Object.values(requiredInputs)].every(Boolean) &&
    page === Object.keys(title).length - 1;

  const canNextPage1 = Object.keys(data)
    .filter((key) => key.startsWith('bill') && key !== 'billAddress2')
    .map((key) => data[key])
    .every(Boolean);

  const canNextPage2 = Object.keys(data)
    .filter((key) => key.startsWith('ship') && key !== 'shipAddress2')
    .map((key) => data[key])
    .every(Boolean);

  const disablePrev = page === 0;

  const disableNext =
    page === Object.keys(title).length - 1 ||
    (page === 0 && !canNextPage1) ||
    (page === 1 && !canNextPage2) ||
    schoolData.length <= 0;

  const prevHide = page === 0 && 'hidden';

  const nextHide = page === Object.keys(title).length - 1 && 'hidden';

  const submitHide = page !== Object.keys(title).length - 1 && 'hidden';

  const studentInput = data.skipForNow ? 'hidden' : '';

  return (
    <FormContext.Provider
      value={{
        page,
        setPage,
        title,
        disablePrev,
        prevHide,
        nextHide,
        submitHide,
        canSubmit,
        disableNext,
        data,
        handleChange,
        studentInput,
        calculation,
        setData,
        schoolData,
        setSchoolData,
        selectedCard,
        setSelectedCard,
        tags,
        setTags,

        // title, page, setPage, data, setData, canSubmit, handleChange, disablePrev, disableNext, prevHide, nextHide, submitHide
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export default FormContext;
