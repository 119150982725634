import { apiSlice } from '../api/apiSlice';

export const supportTicketApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createSupportTicket: builder.mutation({
      query: (body) => ({
        url: '/support-ticket/new',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['SupportTicket'],
    }),
    createExternalSupportTicket: builder.mutation({
      query: (body) => ({
        url: '/support-ticket/external/new',
        method: 'POST',
        body,
        // headers: {
        //     'Api-key': import.meta.env.VITE_FENWORKS_API_KEY
        // }
      }),
      invalidatesTags: ['SupportTicket'],
    }),
    getAllTickets: builder.query({
      query: (query) => ({
        url: `/support-ticket/all?status=${query.status}&type=${query.type}&page=${query.page}`,
        method: 'GET',
      }),
      providesTags: ['SupportTicket'],
    }),
    getTicketById: builder.query({
      query: (id) => ({
        url: `/support-ticket/${id}`,
        method: 'GET',
      }),
      providesTags: ['SupportTicket', 'ApproveTicket'],
    }),
    getAllTicketsByUserId: builder.query({
      query: ({ query, param, page }) => ({
        url: `/support-ticket/user/?${query}=${param}&page=${page}`,
        method: 'GET',
      }),
      providesTags: ['SupportTicket', 'ApprovalStatus', 'ApproveTicket', 'UnseenNotifications'],
    }),
    updateSupportTicket: builder.mutation({
      query: ({ id, body }) => ({
        url: `/support-ticket/update/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['SupportTicket'],
    }),
    deleteSupportTicketById: builder.mutation({
      query: (id) => ({
        url: `/support-ticket/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['SupportTicket'],
    }),
    // closeTicket: builder.mutation({
    //   query: (id) => ({
    //     url: `/support-ticket/close/${id}`,
    //     method: 'POST',
    //   }),
    //   invalidatesTags: ['SupportTicket'],
    // }),
    // closeParentTicket: builder.mutation({
    //   query: ({ id, body }) => ({
    //     url: `/support-ticket/close/parent-ticket/${id}`,
    //     method: 'POST',
    //     body,
    //   }),
    //   invalidatesTags: ['SupportTicket'],
    // }),
    archiveTicket: builder.mutation({
      query: (id) => ({
        url: `/support-ticket/archive/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['SupportTicket'],
    }),
    // archiveAllClosedTickets: builder.mutation({
    //   query: () => ({
    //     url: '/support-ticket/archive',
    //     method: 'POST',
    //   }),
    //   invalidatesTags: ['SupportTicket'],
    // }),
    // getAllArchivedTicketsByUserId: builder.query({
    //   query: (id) => ({
    //     url: `/support-ticket/user/archived/${id}`,
    //     method: 'GET',
    //   }),
    //   providesTags: ['SupportTicket'],
    // }),
    // getAllClosedTicketsByUserId: builder.query({
    //   query: (id) => ({
    //     url: `/support-ticket/user/closed/${id}`,
    //     method: 'GET',
    //   }),
    //   providesTags: ['SupportTicket'],
    // }),
    changeTicketTier: builder.mutation({
      query: ({ id, body }) => ({
        url: `/support-ticket/change-tier/${id}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['SupportTicket'],
    }),
    connectTickets: builder.mutation({
      query: (body) => ({
        url: '/support-ticket/tickets/connect-tickets',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['SupportTicket'],
    }),
    createComment: builder.mutation({
      query: (body) => ({
        url: '/support-comments/new',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['TicketComment'],
    }),
    getTicketCommentById: builder.query({
      query: (id) => ({
        url: `/support-comments/all/${id}`,
        method: 'GET',
      }),
      providesTags: ['TicketComment'],
    }),
    deleteTicketCommentById: builder.mutation({
      query: (id) => ({
        url: `/support-comments/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['TicketComment'],
    }),
    approveTicketByAdmin: builder.mutation({
      query: (id) => ({
        url: `/support-ticket/admin-approve/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['ApproveTicket', 'SupportTicket'],
    }),
    disapproveTicketByAdmin: builder.mutation({
      query: (id) => ({
        url: `/support-ticket/admin-NotApprove/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['ApproveTicket', 'SupportTicket'],
    }),
    disconnectChildTickets: builder.mutation({
      query: (body) => ({
        url: '/support-ticket/tickets/disconnect',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['DisconnectChildTicket', 'SupportTicket'],
    }),
    approveTicketByUser: builder.mutation({
      query: (id) => ({
        url: `/support-ticket/user-approve/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['ApproveTicket'],
    }),
    userNotApproveTicket: builder.mutation({
      query: ({ id, body }) => ({
        url: `/support-ticket/user-notApprove/${id}`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['ApprovalStatus', 'SupportTicket', 'TicketComment'],
    }),
    getAllTicketsThatCanConnectWith: builder.query({
      query: (id) => ({
        url: `/support-ticket/can-connect/${id}`,
        method: 'GET',
      }),
      providesTags: ['SupportTicket'],
    }),
  }),
});

export const {
  useCreateExternalSupportTicketMutation,
  useCreateSupportTicketMutation,
  useGetAllTicketsQuery,
  useGetTicketByIdQuery,
  useGetAllTicketsByUserIdQuery,
  useArchiveTicketMutation,
  useDeleteSupportTicketByIdMutation,
  // useCloseTicketMutation,
  useConnectTicketsMutation,
  useCreateCommentMutation,
  useGetTicketCommentByIdQuery,
  useChangeTicketTierMutation,
  useDeleteTicketCommentByIdMutation,
  useUpdateSupportTicketMutation,
  useApproveTicketByAdminMutation,
  useDisconnectChildTicketsMutation,
  useApproveTicketByUserMutation,
  useDisapproveTicketByAdminMutation,
  useUserNotApproveTicketMutation,
  useGetAllTicketsThatCanConnectWithQuery,
} = supportTicketApiSlice;
