import { useGetLatestTermsAndConditionsQuery } from '../../../slices/termsApiSlice';
import DOMPurify from 'dompurify';

function ModalTerms({ setModalTerms, setModalTermsCheckbox, setFieldValue }: any) {
  // const [getTerms, { data, isLoading }] = useLazyGetLatestTermsAndConditionsQuery();
  // useEffect(() => {
  //   getTerms('', false);
  // }, []);
  const { data, isLoading } = useGetLatestTermsAndConditionsQuery('');
  if (isLoading) return <p>Loading ...</p>;
  return (
    <div className='modalBackground'>
      <div className='modalContainer fixed top-0 left-0 right-0 bottom-0 m-auto z-10 h-fit'>
        <p className='text-left text-gray-400'>AGREEMENT</p>

        <p className='text-left text-blue-600 text-2xl mb-7'>Terms of Service</p>

        <div
          className='overflow-y-auto p-4'
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.content) }}
        />
        <div className='flex justify-end '>
          <button
            onClick={() => {
              setModalTerms(false);
              setModalTermsCheckbox(false);
              setFieldValue('check', false);
            }}
            className='w-1/3 my-12  py-1 bg-white text-blue-500 shadow-lg font-semibold rounded-lg  '
          >
            Decline
          </button>

          <button
            onClick={() => {
              setModalTerms(false);
              setModalTermsCheckbox(true);
              setFieldValue('check', true);
            }}
            className='w-1/3 my-12 py-1 bg-red-700 shadow-lg text-white font-semibold rounded-lg ml-4'
          >
            Accept
          </button>
        </div>
      </div>
    </div>
  );
}

export default ModalTerms;
