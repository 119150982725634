import PropagateLoader from 'react-spinners/PropagateLoader';
import {
  useGetAllNotificationsByUserIdQuery,
  useLazyGetAllNotificationsByUserIdQuery,
  useLazyGetUnseenNotificationQuery,
  useMakeSeenListOfNotificationsMutation,
} from '../../slices/notificationApiSlice';
import NoNotifications from './components/no-notifications';
import { NotificationModel } from '../../model/notification.model';
import { useSearchParams } from 'react-router-dom';
import AllNotificationRow from './components/all-notification-row';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../slices/auth';
import Pagination from '../../components/pagination';

const AllNotifications = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const user = useSelector(selectUser);
  const { data, isError, isLoading } = useGetAllNotificationsByUserIdQuery({
    page: searchParams.get('page') ? searchParams.get('page') : 1,
  });
  const [makeSeenListOfNotification] = useMakeSeenListOfNotificationsMutation();
  const [getUnseenNotification] = useLazyGetUnseenNotificationQuery();
  const [getAllNotificationsByUserId] = useLazyGetAllNotificationsByUserIdQuery();
  function goPrevPage() {
    setSearchParams({ page: `${data?.meta.prev}` });
  }
  function goNextPage() {
    setSearchParams({ page: `${data?.meta.next}` });
  }
  useEffect(() => {
    if (!data?.data?.length) return;
    const list: number[] = [];
    data.data
      .filter((d: { status: number; id: number }) => !d.status)
      .forEach((d: { id: number }) => list.push(d.id));
    makeSeenListOfNotification({ body: { list }, count: list.length, user: user.id });
    getUnseenNotification(user.id);
    return () => {
      getAllNotificationsByUserId({
        page: searchParams.get('page') ? searchParams.get('page') : 1,
      });
    };
  }, [data]);
  // useEffect(()=>{})
  if (isLoading) return <PropagateLoader color='#123abc' />;

  return (
    <>
      <div className='listViewContainer fullPage flex flex-col w-full justify-between   bg-white rounded-xl relative py-0 px-5 border-1'>
        <div className='flex flex-col gap-5'>
          <h2 className='tracking-tight font-semibold text-2xl sticky top-0 bg-white p-5'>
            Notifications
          </h2>
          <div className='flex flex-col gap-1 items-center '>
            {data.data.length ? (
              data.data.map((n: any, i: number) => (
                <AllNotificationRow key={i} model={new NotificationModel(n)} />
              ))
            ) : (
              <>
                <NoNotifications />
              </>
            )}
          </div>
        </div>
        <div className='sticky bottom-0 bg-white flex w-full justify-between'>
          <Pagination length={data?.data?.length} {...data?.meta} />
        </div>
      </div>
    </>
  );
};

export default AllNotifications;
