import { Navigate, NavLink, Outlet, useParams } from 'react-router-dom';
import { TournamentHeader } from './components/tournament-header';
import { useGetSingleTournamentInfoQuery } from '../../slices/tournamentApiSlice';
import { CustomError } from '../../utils/custom-types';
import AddGameAccount from '../games/overview/add-game/AddGameAccount';

function Tournament() {
  const links = [
    { name: 'Overview', link: './' },
    { name: 'Bracket', link: './bracket' },
    { name: 'Matches', link: './matches' },
    { name: 'Teams', link: './teams' },
    // { name: 'Standings', link: './standings' },
  ];

  const param = useParams();
  const { error, data, isLoading, isError } = useGetSingleTournamentInfoQuery(param.id);
  if (isLoading) return <p>Loading...</p>;
  if (data && !data?.TournamentRound?.length) {
    return <AddGameAccount gameId={data?.game_id} />;
  }
  if (isError || !data)
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError).data.message }} />;
  return (
    <>
      <div className='flex flex-col p-0 listViewContainer fullPage w-full relative border-1 rounded-xl gap-0'>
        <TournamentHeader />
        <div className='flex gap-5 bg-white px-2'>
          {links.map((l, i) => (
            <NavLink key={i} to={l.link} className='settingsLink'>
              <div> {l.name} </div>
            </NavLink>
          ))}
        </div>
        <div className='px-2'>
          <Outlet />
        </div>
      </div>
    </>
  );
}

export default Tournament;
