import { SponsorRow } from '../../components/sponsor-row/sponsor-row';
import { SponsorPlacements } from '../../utils/constants';
import { TournOverviewInformation } from './components/tourn-overview-information';
import { TournReviewTimeline } from './components/tourn-review-timeline';
import { TournamentOverviewSponsors } from './components/tournament-overview-sponsors';
import { TournamentOverviewTeams } from './components/tournament-overview-teams';

function TournamentOverview() {
  return (
    <>
      <div className='flex flex-col gap-8 relative p-10 bg-white w-full '>
        <SponsorRow name={SponsorPlacements.TournamentOverview} />
        <TournReviewTimeline />
        <TournamentOverviewTeams />
        <TournOverviewInformation />
        <TournamentOverviewSponsors />
      </div>
    </>
  );
}

export default TournamentOverview;
