import { useParams, Navigate } from 'react-router-dom';
import {
  useArchiveDivisionMutation,
  useGetDivisionByIdQuery,
} from '../../../../slices/divisionApiSlice';
import ModalArchive from '../../../../components/modal-archive';
import { CustomError } from '../../../../utils/custom-types';
function ArchiveDivision() {
  const param = useParams();
  const {
    error,
    data: divisionData,
    isLoading: divisionIsLoading,
    isSuccess: divisionIsSuccess,
    isError: divisionIsError,
  } = useGetDivisionByIdQuery(param.id);

  const [archiveDivision, { data, isSuccess, isError, isLoading }] = useArchiveDivisionMutation();

  const onSubmit = () => {
    archiveDivision(param.id)
      .unwrap()
      .then((res) => console.log(res))
      .catch((e) => console.log(e));
  };

  if (divisionIsError || (divisionIsSuccess && !divisionData))
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError).data.message }} />;
  if (divisionIsLoading) return <p>Loading...</p>;
  if (isSuccess) return <Navigate to='../' />;
  return <ModalArchive onSubmit={onSubmit} isLoading={isLoading} name={divisionData.name} />;
}

export default ArchiveDivision;
