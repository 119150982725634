import React from 'react';
import { Link } from 'react-router-dom';
export function UnforfeitMatchButton({ }) {
    return (
        <Link to='./unforfeit'>
            <div className='rounded-lg mt-2 flex justify-center p-2 uppercase main-gr-bg-color text-white text-sm min-w-[200px]'>
                Unforfeit
            </div>
        </Link>
    );
}
