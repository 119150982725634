import { RescheduleStatus } from '../utils/constants';
import { dateAndTimeFormatter } from '../utils/dateFormatter';

export class RescheduleRequestModel {
  id?: number;
  match_id?: number;
  admin_status?: number;
  admin_status_name?: string;
  captain_status?: number;
  captain_status_name?: string;
  requested?: number;
  new_date?: string;
  f_new_date?: string;
  constructor(d: any) {
    if (d.hasOwnProperty('id')) this.id = d.id;
    if (d.hasOwnProperty('new_date')) {
      this.new_date = d.new_date;
      this.f_new_date = dateAndTimeFormatter(this.new_date ?? '').replace(";","");
    }
    if (d.hasOwnProperty('match_id')) this.match_id = d.match_id;
    if (d.hasOwnProperty('requested')) this.requested = d.requested;
    if (d.hasOwnProperty('captain_status')) {
      this.captain_status = d.captain_status;
      switch (this.captain_status) {
        case RescheduleStatus.Requested:
          this.captain_status_name = 'Requested';
          break;
        case RescheduleStatus.Approved:
          this.captain_status_name = 'Approved';
          break;
        case RescheduleStatus.Rejected:
          this.captain_status_name = 'Rejected';
          break;
        default:
          break;
      }
    }
    if (d.hasOwnProperty('admin_status')) {
      this.admin_status = d.admin_status;
      switch (this.admin_status) {
        case RescheduleStatus.Requested:
          this.admin_status_name = 'Requested';
          break;
        case RescheduleStatus.Approved:
          this.admin_status_name = 'Approved';
          break;
        case RescheduleStatus.Rejected:
          this.admin_status_name = 'Rejected';
          break;
        default:
          break;
      }
    }
  }
}
