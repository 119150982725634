import React, { useEffect, useState } from 'react';
import { OutsideClick } from '../../../../components/outside-click';
import { Form, Formik } from 'formik';
import { FormInput } from '../../../../components/form-input/form-input';
import { Link, Navigate, useParams } from 'react-router-dom';
import { SubmitButton } from '../../../../components/submit-button';
import { useGetNewSRequiredDataQuery } from '../../../../slices/seasonApiSlice';
import { useCreateClassroomMutation } from '../../../../slices/classroomApiSlice';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../slices/auth';
import { NewClassroomSchema } from '../schema/new-classroom-schema';
import ModalHeader from '../../../../components/modal-header';
import ModalFooter from '../../../../components/modal-footer';
import { UserRoles } from '../../../../utils/constants';

interface AvatarImage {
  file: File;
  name: string;
}

const NewClassroom = () => {

  const [avatarImage, setAvatarImage] = useState<AvatarImage[]>([]);
  const [profileImage, setProfileImage] = useState<any>();
  const user = useSelector(selectUser);
  const param = useParams();
  const [
    createClassroom,
    { data: classroomData, isLoading: isClassroomLoading, isSuccess: isClassroomSuccess,isError, error },
  ] = useCreateClassroomMutation();

  const {
    data: reqData,
    isLoading: reqIsLoading,
    isSuccess: reqIsSuccess,
    isError: reqIsError,
  } = useGetNewSRequiredDataQuery('');

  const formInputs = [
    {
      mainTitle: 'Classroom Information',
      name: 'name',
      label: 'Classroom name',
      type: 'text',
      placeholder: 'Enter classroom name',
      isTrue: true,
    },
    {
      mainTitle: 'Classroom Information',
      name: 'game_id',
      label: 'Game',
      type: 'text',
      placeholder: 'Select game',
      isTrue: true,
      isSelect: true,
      options: reqData?.games ? reqData.games : [],
    },
    {
      mainTitle: 'Intended Learners',
      name: 'objective_descriptions',
      label: 'Learning Objectives',
      type: 'text',
      placeholder: 'Enter learning objectives',
      isTrue: true,
    },
    {
      mainTitle: 'Upload Profile',
      name: 'profile',
      label: 'Upload Profile',
      type: 'image',
      placeholder: 'Select an image',
      isTrue: true,
    },
    {
      mainTitle: 'Upload Banner',
      name: 'banner',
      label: 'Upload Banner',
      type: 'image',
      placeholder: 'Select an image',
      isTrue: true,
    },
  ];



  const initialValues: { [key: string]: string } = {};
  formInputs.forEach((input) => (initialValues[input.name] = ''));

  function handleSubmit(values: any, actions: any) {
    const data = { ...values };
    data.banner = avatarImage.find((img: any) => img.name === 'banner')?.file?.name;
    data.profile = avatarImage.find((img: any) => img.name === 'profile')?.file?.name;


    data.user_id = UserRoles.Coach === user.roles ? +user.id : param.id;
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]: any[]) => {
      if (value)
        formData.append(key, value);
    });

    avatarImage.forEach(img => {
      formData.append('images', img?.file)
    })

    createClassroom(formData)
      .unwrap()
      .then((res) => console.log(res))
      .catch((e) => console.log(e));
  }

  if (isClassroomSuccess) return <Navigate to='../' />
  const content = (
    <>
      <OutsideClick />
      <Formik
        validationSchema={NewClassroomSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        className='overflow-scroll'
      >
        {({ errors }) => {
          return (
            <Form>
              <div className='fixed h-fit top-0 left-0 bottom-0 right-0 m-auto z-10 w-auto flex items-center justify-center  max-w-2xl'>
                <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-[90vh]'>
                  <ModalHeader name={"Create Classroom"} />
                  <div className="relative px-3 flex-auto overflow-auto">
                    {formInputs.map((input: any, i: number) => (
                      <React.Fragment key={i}>
                        {!formInputs[i - 1] || formInputs[i - 1]?.mainTitle !== input.mainTitle ? (
                          <>
                            <div className='flex flex-col'>
                              <div className='mb-2'>
                                {input.mainTitle === 'Intended Learners' ? (
                                  <>
                                    <p className='text-gray-800 font-bold pt-6'>{input.mainTitle}</p>
                                    <hr />
                                    <div className='px-2 py-4'>
                                      <p className='mb-2'>
                                        The following descriptions will be publicly visible on your
                                        Course Landing Page and will have a direct impact on your
                                        course performance. These descriptions will help learners
                                        decide if your course is right for them.
                                      </p>
                                      <h1 className='text-lg font-semibold mb-3'>
                                        What will students learn in your course?
                                      </h1>
                                      <p>
                                        Enter learning objectives or outcomes that learners can expect
                                        to achieve after completing your course.
                                      </p>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <p className='text-gray-800 font-bold pt-6'>{input.mainTitle}</p>
                                    <hr />
                                  </>
                                )}
                              </div>
                              <div className='flex flex-wrap gap-1'>
                                {formInputs
                                  .filter((inp1: any) => inp1.mainTitle === input.mainTitle)
                                  .map((inp: any, i: any) => (
                                    <React.Fragment key={i}>
                                      <div className={inp.isTrue ? 'w-full' : 'flex-1 min-w-[300px]'}>
                                        {inp.type !== 'image' ? (
                                          <FormInput {...inp} />
                                        ) : (
                                          <>
                                            <FormInput setFieldValue={setAvatarImage} fieldValue={avatarImage} isArr={true} {...inp} />
                                            {/* <FormInput setFieldValue={setProfileImage} {...inp} /> */}
                                          </>
                                        )}
                                      </div>
                                    </React.Fragment>
                                  ))}
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                  <ModalFooter 
                  error={error}
                   errors={Object.keys(errors).length} isLoading={isClassroomLoading!} />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );

  return content;
};

export default NewClassroom;
