import React, { useState } from 'react';
import LastSeasonStanding from './last-season-standing';

const GuardianStudents = ({ students }: any) => {
  const [selectedStudent, setSelectedStudent] = useState(students?.[0]?.id);

  function handleClick(studentId: any) {
    setSelectedStudent(studentId);
  }

  if (!students.length)
    return (
      <div>
        <h2 className='font-bold'>Student's Leaderboard</h2>
        <div className='flex w-full justify-center items-center border rounded-xl h-[300px]'>
          <p>You don't have any student!</p>
        </div>
      </div>
    );

  return (
    <div>
      <h2 className='font-bold'>Student's Leaderboard</h2>
      <div className='flex w-full border rounded-xl h-[300px]'>
        <div className='border-r-1 min-w-[200px] h-full overflow-y-scroll'>
          {students &&
            students.map((s: any, i: number) => (
              <div
                key={i}
                className={`flex flex-1 p-2 cursor-pointer hover:bg-gray-100 ${
                  selectedStudent === s?.id ? 'border-l-2 border-b-0 border-green-500' : ''
                }`}
                onClick={() => handleClick(s.id)}
              >
                <p>{s.firstName}</p>
              </div>
            ))}
        </div>

        {selectedStudent ? <LastSeasonStanding studentId={selectedStudent} /> : null}
      </div>
    </div>
  );
};

export default GuardianStudents;
