import React, { useState } from 'react';
import { NavLink, Outlet, useSearchParams } from 'react-router-dom';
import { GamesLoadingSceleton } from './overview/components/GamesLoadingSceleton';
import { SponsorRow } from '../../components/sponsor-row/sponsor-row';
import { SponsorPlacements } from '../../utils/constants';
import { HeaderButton } from '../../components/header-button';
import CanIView from '../../components/can-i-view/can-i-view';
import { useGetAllGamesQuery } from '../../slices/gameApiSlice';

const AllGames = () => {
  // const { isLoading } = useInitGamearenaQuery('');
  const filterList = [{ title: 'name', property: 'name' }];
  const [search, setSearch] = useState({ by: 'name', keyword: '' });
  const [searchQuery, setSearchQuery] = useState(filterList[0].title);
  const [searchParams, setSearchParams] = useSearchParams();
  const [onlyFavorites, setOnlyFavorites] = useState(false);

  const links = [
    { name: 'Overview', link: '' },
    { name: 'Teams', link: '/games/teams/' },
  ];
  // return <GamesLoadingSceleton />
  // if (isLoading ) return (
  //   <GamesLoadingSceleton />
  //   );
  const {
    data: gameData,
    isLoading: gameIsLoading,
    error,
  } = useGetAllGamesQuery({
    query: searchQuery,
    param: searchParams.get(searchQuery) ? searchParams.get(searchQuery) : '',
    // page: searchParams.get("page") ? searchParams.get("page") : 1,
  });
  if (gameIsLoading) return <GamesLoadingSceleton />;

  return (
    <div className='flex flex-col gap-5'>
      <SponsorRow name={SponsorPlacements.Games} />
      <div className='listViewContainer flex flex-col w-full bg-white rounded-xl relative p-5 border-1'>
        <div className='flex items-center justify-between'>
          <div className='space-y-4 space-x-2'>
            <h2 className='text-2xl font-semibold tracking-tight'>Games</h2>
            <p className='text-sm text-slate-500 dark:text-slate-400'></p>
          </div>
        </div>
        <div className='flex gap-5 bg-white border-b-1'>
          {links.map((l) => {
            if (l.name === 'Teams') {
              return (
                <CanIView component='gameteams' key={l.link}>
                  <NavLink to={l.link} className='settingsLink'>
                    <div> {l.name}</div>
                  </NavLink>
                </CanIView>
              );
            } else {
              return (
                <NavLink key={l.link} to={l.link} className='settingsLink'>
                  <div> {l.name}</div>
                </NavLink>
              );
            }
          })}
        </div>
        <Outlet />
      </div>
    </div>
  );
};

export default AllGames;
