// import React, { useState } from 'react';
import { OutsideClick } from '../../../components/outside-click';
import {
  useCreateMatchFormatMutation,
  useGetMatchFormatTypesQuery,
} from '../../../slices/matchFormatApiSlice';
import { Formik, FormikValues } from 'formik';
import ModalContent from '../../../components/modal-content';
import { newMatchFormatVSchema } from './schema/new-matchFormat.schema';
import { Navigate } from 'react-router';
import { MatchFormatType } from '../../../model/match-format-type.model';
import { useEffect, useState } from 'react';
import { MatchFormats } from '../../../utils/constants';

const NewMatchFormat = () => {
  const { data, isLoading: mftLoading } = useGetMatchFormatTypesQuery('');
  const [createMatchFormat, { isLoading, isSuccess }] = useCreateMatchFormatMutation();
  const [matchFormatType, setMatchFormatType] = useState<number>();

  const formInputs = [
    {
      mainTitle: 'Match Format Information',
      name: 'name',
      label: 'Match format name',
      type: 'text',
      placeholder: 'Enter match format name',
      isTrue: true,
    },
    {
      mainTitle: 'Match Format Information',
      name: 'type',
      label: 'Match format type',
      type: 'select',
      isSelect: true,
      options: data ? new MatchFormatType(data).list : [],
      placeholder: 'Enter match format name',
      setFieldValue: setMatchFormatType,
    },
    {
      mainTitle: 'Match Format Information',
      name: 'value',
      label: 'Value',
      type: 'number',
      min: 1,
      placeholder: 'Enter match format value',
    },

    // {
    //   mainTitle: 'Match Format Information',
    //   name: 'Game',
    //   label: 'Game Name',
    //   type: 'text',
    //   placeholder: 'Enter game name',
    //   isTrue: true,
    //   search: 'game',
    //   setChosenGame,
    //   chosenGame,
    // },
  ];
  const initialValues: { [key: string]: string } = {};
  formInputs.forEach((input) => (initialValues[input.name] = ''));
  function onSubmit(values: FormikValues) {
    values.value = Number(values.value);
    createMatchFormat(values as { name: string; value: number; type: string });
  }
  if (mftLoading) return <></>;
  if (isLoading) return <p>Loading ...</p>;
  if (isSuccess) return <Navigate to='../' />;

  return (
    <>
      <OutsideClick />
      <Formik
        validationSchema={newMatchFormatVSchema}
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={onSubmit}
      >
        {({ errors, values, setValues }) => {
          useEffect(() => {
            if (Number(matchFormatType) === MatchFormats.Chess) {
              setValues({
                ...values,
                value: '2',
              });
            }
          }, [matchFormatType]);
          return (
            <ModalContent
              errors={Object.keys(errors).length}
              extraClass={'h-fit'}
              formInputs={formInputs}
              isLoading={isLoading}
              name='New Match Format'
            />
          );
        }}
      </Formik>
    </>
  );
};

export default NewMatchFormat;
