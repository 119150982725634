import { Navigate, useLocation, useParams } from 'react-router';
import { Formik, FormikValues } from 'formik';
import { useAddMatchToWeekMutation } from '../../slices/matchApiSlice';
import { OutsideClick } from '../../components/outside-click';
import { CustomError } from '../../utils/custom-types';
import ModalContent from '../../components/modal-content';
import { useGetTeamsBySeasonIdQuery } from '../../slices/teamsApiSlice';
import { addMatchToWeekSchema } from './add-match-to-week.schema';
import { useStateContext } from '../../contexts/ContextProvider';
import { useGetSeasonWeekByIdQuery } from '../../slices/seasonApiSlice';

const AddMatchToWeek = () => {
  const { selectedWeek, setSelectedWeek } = useStateContext();
  const params = useParams();
  const [
    addMatch,
    {
      data: addData,
      isLoading: addIsLoading,
      isError: addIsError,
      error: addError,
      isSuccess: addIsSuccess,
    },
  ] = useAddMatchToWeekMutation();
  const onSubmit = (values: FormikValues) => {
    console.log(values);
    addMatch({
      id: selectedWeek ?? '',
      body: {
        home_team_id: +values.home_team,
        away_team_id: values.away_team ? +values.away_team : undefined,
        match_date: new Date(values.match_date).toISOString(),
      },
    });
  };
  const { data, isLoading, isError, error } = useGetTeamsBySeasonIdQuery(params?.id ?? '', {
    skip: !params.id,
  });
  const { data: seasonWeekInfo, isLoading: seasonWeekIsLoading } =
    useGetSeasonWeekByIdQuery(selectedWeek);
  console.log('selectedWeek', selectedWeek);
  if (addIsError || isError)
    return (
      <Navigate
        to='/went-wrong'
        state={{
          message:
            (error as CustomError)?.data?.message ??
            (error as CustomError)?.message ??
            (addError as CustomError)?.data.message,
        }}
      />
    );
  if (addIsSuccess) return <Navigate to='../' />;
  if (isLoading || seasonWeekIsLoading) return <p>Loading...</p>;
  const formInputs = [
    {
      mainTitle: 'Match Information',
      name: 'match_date',
      label: 'Match Date',
      type: 'datetime-local',
      placeholder: 'Start date',
      isTrue: true,
    },
    {
      mainTitle: 'Match Information',
      name: 'home_team',
      label: 'Home Team',
      placeholder: 'Home Team',
      isSelect: true,
      options: data.map((d: { id: number; teamName: string }) => ({ id: d.id, name: d.teamName })),
    },
    {
      mainTitle: 'Match Information',
      name: 'away_team',
      label: 'Away Team',
      placeholder: 'Away Team',
      isSelect: true,
      options: data.map((d: { id: number; teamName: string }) => ({ id: d.id, name: d.teamName })),
      // seasonData.game_id &&
      // reqData?.games?.filter((g: any) => g.id === seasonData?.game_id)[0].MatchFormat
      //   ? reqData?.games?.filter((g: any) => g.id === seasonData?.game_id)[0].MatchFormat
      //   : [],
    },
  ];
  const initialValues: { [key: string]: string } = {};
  formInputs.forEach((input) => (initialValues[input.name] = ''));

  return (
    <>
      {/* <ToastContainer /> */}
      <OutsideClick />
      <Formik
        onSubmit={onSubmit}
        validationSchema={addMatchToWeekSchema(seasonWeekInfo?.start_time)}
        initialValues={initialValues}
        enableReinitialize
      >
        {({ values, errors }) => {
          return (
            <ModalContent
              formInputs={formInputs}
              isLoading={addIsLoading}
              name='Add new match to season week'
            />
          );
        }}
      </Formik>
    </>
  );
};

export default AddMatchToWeek;
