import { Link, useLocation } from 'react-router-dom';
import { AiOutlineClose } from 'react-icons/ai';
import { AvatarImage } from './avatar-image';
import { ReactElement } from 'react';

const ModalHeader = ({
  name,
  image,
  children,
}: {
  name: string;
  image?: string;
  children?: ReactElement;
}) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  return (
    <div className='flex sticky items-center justify-between p-5 border-b border-solid border-slate-200 rounded-xl bg-white'>
      {image ? (
        <div className='flex items-center'>
          <AvatarImage source={image} size='w-16' extraClass='h-auto mr-1' />
          <h3 className='text-2xl font-semibold max-w-[40ch] truncate'>{name}</h3>
        </div>
      ) : (
        <h3 className='text-2xl w-full font-semibold max-w-[40ch] truncate'>{name}</h3>
      )}

      <Link to={`../?${queryParams.toString()}`}>
        <AiOutlineClose size={'2rem'} className='hover:rounded-full hover:bg-gray-100 p-2' />
      </Link>
    </div>
  );
};

export default ModalHeader;
