import * as yup from 'yup';
import { SchemaMessages } from '../../../../../utils/constants';
import { checkEndDate } from '../../../../../utils/utils';
import moment from 'moment';

const currentYear = new Date().getFullYear();
const today = new Date();
today.setHours(0, 0, 0, 0);

export const NewSponsorVSchema = yup.object().shape({
  name: yup.string().trim().required('Required!'),
  // image: yup.mixed().nullable().,
  // .required("Required"),
  start_date: yup
    .date()
    .required('Required!')
    .min(moment('2000-01-01'), 'Selected date must be from the year 2000 and later'),
  // .mixed()
  // .required('Required!')
  // .transform((v, ov) => ov)
  // .test('test', SchemaMessages.SelectedDateMusBeFromToday, checkStartDate),
  // .required('Required!'),
  // .min(today, 'Start date must be from today'),
  expiration_date: yup
    .mixed()
    .required('Required!')
    .when('start_date', {
      is: (start_date: any) => start_date != null,
      then: yup.mixed().transform((v, ov) => ov)
        .test('test', SchemaMessages.EndDate, (v, ctx) => checkEndDate(v, ctx, 'start_date'))
    })
  // expiration_date: yup
  //   .mixed()
  //   .transform((v, ov) => ov)
  //   .test('test', SchemaMessages.EndDate, (v, ctx) => checkEndDate(v, ctx, 'start_date'))
  //   .required('Required!'),
  // .min(yup.ref('start_date'), 'End date cannot be before start date'),
});
