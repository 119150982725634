import { Navigate, useParams } from 'react-router';
import { OutsideClick } from '../../../components/outside-click';
import {
  useGetAllScreenshotByUserGameIdQuery,
  useUpdateScreenshotMutation,
} from '../../../slices/matchApiSlice';
import React, { useEffect, useState } from 'react';
import ModalHeader from '../../../components/modal-header';

import { SubmitButton } from '../../../components/submit-button';
import { CustomError } from '../../../utils/custom-types';

const ViewScreenshot = () => {
  interface ScreenshotType {
    aw_result: number;
    ht_result: number;
    id: number;
    match_game_id: number;
    url: string;
    user_id: number;
  }
  const [screenShotData, setScreenShotData] = useState<ScreenshotType[]>();
  const [selectedPicId, setSelectedPicId] = useState<number | null>();
  const [file, setFile] = useState<any>(null);
  const param = useParams();
  const {
    data,
    isLoading: fetchScreenshotLoading,
    isError,
    error,
  } = useGetAllScreenshotByUserGameIdQuery(param?.gid);

  const [updateScreenshot, { isLoading, isSuccess }] = useUpdateScreenshotMutation();

  useEffect(() => {
    if (data?.MatchGameScreenshot?.length) {
      setScreenShotData([...data?.MatchGameScreenshot]);
    }
  }, [data]);

  useEffect(() => {
    if (file) {
      const objectUrl = URL.createObjectURL(file);
      setScreenShotData((prevScreenShotData: any) =>
        prevScreenShotData.map((s: any) => {
          if (s.id === selectedPicId) {
            return {
              ...s,
              url: objectUrl,
            };
          }
          return s;
        }),
      );
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [file]);

  const handleInputChange = (e: any, matchGame: any) => {
    setSelectedPicId(null);
    setFile(null);
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setSelectedPicId(matchGame?.id);
    }
    e.target.value = '';
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>, matchGame: ScreenshotType) => {
    e.preventDefault();

    if (matchGame) {
      const formData = new FormData();
      formData.append('image', file);
      formData.append('matchGameScreenshotId', matchGame?.id.toString());
      updateScreenshot({
        id: param.gid,
        body: formData,
      });
    }
  };

  if (fetchScreenshotLoading) return <span>loading...</span>;

  if (isError)
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError).data.message }} />;

  return (
    <>
      <OutsideClick />
      <div className='fixed top-0 left-0 right-0 z-10 w-auto mx-auto max-w-3xl h-screen max-h-[100vh] flex items-center'>
        <div className='flex flex-col bg-white w-full max-h-[500px] overflow-y-auto gap-1 rounded-lg'>
          <div className='w-full'>
            <ModalHeader name={'View & modify screenshot'} />
          </div>
          <div className='flex flex-wrap justify-center'>
            {screenShotData?.length &&
              screenShotData
                ?.sort((a, b) => a?.id - b?.id)
                .map((game) => {
                  return (
                    <div
                      key={game?.id}
                      className='flex flex-col items-center justify-center p-2 gap-2'
                    >
                      <div className='flex gap-2 justify-center items-center'>
                        <div className='flex justify-center items-center rounded-full bg-gray-200 min-w-[30px] aspect-square font-bold'>
                          {game.ht_result}
                        </div>
                        -
                        <div className='flex justify-center items-center rounded-full bg-gray-200 min-w-[30px] aspect-square font-bold'>
                          {game.aw_result}
                        </div>
                      </div>
                      <div className='w-[220px] h-[200px] mb-2'>
                        <img
                          src={game.url}
                          alt='game img'
                          className='w-full h-full border-[7px] object-cover'
                        />
                      </div>
                      <div className='flex'>
                        <form onSubmit={(e) => handleSubmit(e, game)}>
                          <div className='flex'>
                            {!data?.status ? (
                              <label className=' flex flex-col items-center bg-red-700 text-blue rounded-lg shadow-lg tracking-wide cursor-pointer '>
                                <span className='min-w-[120px] px-2  py-2 text-center leading-normal text-white'>
                                  Change
                                </span>
                                <input
                                  type='file'
                                  accept='image/jpeg, image/png, image/jpg'
                                  className='hidden'
                                  onChange={(e) => handleInputChange(e, game)}
                                />
                              </label>
                            ) : null}
                            {game.id === selectedPicId && !isSuccess ? (
                              <SubmitButton
                                title='Submit'
                                isLoading={isLoading}
                                disabled={isLoading}
                              />
                            ) : null}
                          </div>
                        </form>
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewScreenshot;
