import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, Navigate, Outlet, useNavigate } from 'react-router-dom';
import { selectUser } from '../../slices/auth';
import { useGetSchoolsByOrgIdQuery } from '../../slices/schoolsApiSlice';
import { AvatarImage } from '../../components/avatar-image';
import { OgmaSubsSchoolModel } from './models/ogma-payments-school-side.model';
import { CustomError } from '../../utils/custom-types';

const OgmaSchoolSubscriptions = () => {
  const [firstTime, setFirstTime] = useState(true);
  const [open, setOpen] = useState(true);
  const user = useSelector(selectUser);
  const { error, data, isLoading, isSuccess, isError } = useGetSchoolsByOrgIdQuery(
    user?.organizations?.[0]?.id,
  );

  const [selectedSchool, setSelectedSchool] = useState<number | undefined>(undefined);

  const navigate = useNavigate();

  useEffect(() => {
    if (!data || !data.length || isLoading) return;
    console.log('data', data, firstTime);
    if (!selectedSchool)
      setSelectedSchool(
        JSON.parse(JSON.stringify(data)).sort(
          (a: { Invoices: number[] }, b: { Invoices: number[] }) =>
            b.Invoices.length - a.Invoices.length,
        )?.[0]?.id,
      );
    if (selectedSchool && firstTime) {
      navigate(`./school/${selectedSchool}`);
      setFirstTime(false);
    }
  }, [data, selectedSchool, firstTime, user]);

  useEffect(() => {
    return () => setFirstTime(true);
  }, []);
  if (isLoading) return <p>Loading...</p>;
  if (isError)
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError).data.message }} />;
  // TODO: PAYMENTS: REMOVE THIS  RETURN TO USE PAYMENTS AGAIN
  return <p></p>;
  return (
    <div className='listViewContainer fullPage  !flex-row '>
      <div
        className={`flex flex-col overflow-auto ${
          open ? 'w-30' : 'w-28'
        } rounded-l-xl shadow-lg h-full py-4 w-fit`}
      >
        <h1 className={' font-bold p-4 text-center'}>Select School</h1>
        {JSON.parse(JSON.stringify(data))
          .sort(
            (a: { Invoices: number[] }, b: { Invoices: number[] }) =>
              b.Invoices.length - a.Invoices.length,
          )
          .map((d: OgmaSubsSchoolModel) => {
            const model = new OgmaSubsSchoolModel(d);
            return (
              <Link
                to={`/subscriptions/ogma/school/${model.id}`}
                className={`
          subscriptionOgma__sidebar
          ${selectedSchool === model.id ? 'shadow-green-700 bg-gray-100 bg-opacity-50' : ''}`}
                key={model.id}
                onClick={() => setSelectedSchool(model.id)}
                {...model.elementProps}
              >
                {selectedSchool === model.id && <span className='activeInvoice'></span>}
                <AvatarImage source={model.image} size={'xl'} />
                <span
                  className={`
              font-bold w-fit`}
                >
                  {model.name}
                </span>
              </Link>
            );
          })}
      </div>
      <div className='flex-1 '>
        <Outlet />
      </div>
    </div>
  );
};

export default OgmaSchoolSubscriptions;
