import { dateToLocal } from '../utils/dateFormatter';

export class SponsorModel {
  id?: number;
  image;
  name?: string;
  start_date?: string;
  f_start_date?: string;
  inp_start_date?: string;
  expiration_date?: string;
  f_expiration_date?: string;
  inp_expiration_date?: string;
  isArchived?: number;
  updatedAt?;
  placements: {
    id: number;
    name: string;
  }[] = [];
  constructor(d: any) {
    if (d.hasOwnProperty('id')) this.id = d.id;
    if (d.hasOwnProperty('image')) this.image = d.image;
    if (d.hasOwnProperty('name')) this.name = d.name;
    if (d.hasOwnProperty('start_date')) {
      this.start_date = d.start_date;
      this.f_start_date = dateToLocal(d.start_date, 'MMMM D, YYYY');
      this.inp_start_date = dateToLocal(d.start_date)?.split('T')[0];
    }
    if (d.hasOwnProperty('expiration_date')) {
      this.expiration_date = d.expiration_date;
      this.f_expiration_date = dateToLocal(d.expiration_date, 'MMMM D, YYYY');
      this.inp_expiration_date = dateToLocal(d.expiration_date)?.split('T')[0];
    }
    if (d.hasOwnProperty('sponsorPlacement')) this.placements = d.sponsorPlacement;
    if (d?.hasOwnProperty('updated_at'))
      this.updatedAt = dateToLocal(d?.updated_at, 'MMMM D, YYYY');
  }
}
