import React from 'react';
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai';
import { MatchStatus } from '../../../utils/constants';

interface MatchCheckInStatusProps {
  areAllCheckedIn?: boolean;
  matchStatus: MatchStatus;
}

export function MatchCheckInStatus({
  areAllCheckedIn = false,
  matchStatus,
}: MatchCheckInStatusProps) {
  if (![MatchStatus.Upcoming, MatchStatus.Overdue].includes(matchStatus)) return null;
  return (
    <div className='rounded-sm flex p-1 mt-5 w-full border-2 border-blue-400 justify-between bg-slate-200'>
      <p className='text-sm flex-1 text-center'>
        {areAllCheckedIn ? 'All players checked in' : 'Waiting for all players to check in '}
      </p>
      {areAllCheckedIn ? (
        <AiOutlineCheckCircle color='green' />
      ) : (
        <AiOutlineCloseCircle color='grey' />
      )}
    </div>
  );
}
