import React from 'react';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import { Link, useSearchParams } from 'react-router-dom';


const defaultBg = (await import('../../../../assets/grid-background.jpg')).default;

const ClassroomCard = ({ userImage, growMode, ...data }: any) => {
  const content = (
    <div
      className={`${growMode ? 'grow' : ''
        } flex flex-col w-full justify-center  bg-white border rounded-3xl p-5 gap-3 hover:transform hover:-translate-y-2 cursor-pointer md:max-w-[300px]`}
    >
      <Link to={`/classrooms/view/${data.id}`}>
        <img src={data?.profile_image?.length ? data.profile_image : defaultBg} className='rounded-t-lg w-full h-[130px] object-cover' />
        <p className='text-center text-l flex justify-between p-2'>
          Name: <span className=' font-semibold max-w-[15ch] truncate'>{data.name}</span>
        </p>
        <hr />
        <p className='text-center text-l flex justify-between p-1'>
          Objectives:{' '}
          <div className=' font-semibold max-w-[15ch] truncate'>
            {data.objective_descriptions}
          </div>
        </p>
      </Link>
    </div>
  );
  return content;
};

export default ClassroomCard;
