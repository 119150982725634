import { SupportTickets } from './components/support-tickets';
import { Link, Outlet } from 'react-router-dom';

const SupportOtherRolesView = () => {
  return (
    <>
      <Outlet />
      <div className='listViewContainer fullPage border-1'>
        <SupportTickets />
        <div className='w-full mx-auto py-10 mt-10'>
          <h2 className='text-4xl font-bold mb-10 text-center'>How can we help you?</h2>
          <h2 className='text-2xl font-semibold mb-10 text-center'>
            Discover personalized assistance by choosing from our options below.
          </h2>
          <div className='flex flex-col gap-4 items-center justify-center'>
            <div>
              <Link to='/support/create-new-ticket'>
                <button className='bg-white w-auto lg:w-[270px] xl:w-[900px] h-[55px] font-semibold text-slate-800 text-xl px-4 py-2 rounded-md border border-gray-200 hover:bg-gray-200  flex flex-col items-center justify-center mb-5'>
                  {' '}
                  Create New Ticket
                  {/* {isLoading ? 'Submitting...' : 'Submit'} */}
                </button>
              </Link>
            </div>

            <Link to='/support/viewMyTickets'>
              <button
                type='submit'
                className='bg-[#B50303] w-auto lg:w-[270px] xl:w-[900px] h-[55px] text-white font-semibold text-xl px-4 py-2 rounded-md border border-[#B50303] flex flex-col items-center justify-center'
              >
                View My Tickets
                {/* {isLoading ? 'Submitting...' : 'Submit'} */}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default SupportOtherRolesView;
