import React from 'react';
import circle from '../assets/circle.svg';
export function SubmitButton({
  isLoading = false,
  title = 'Submit',
  onSubmit,
  disabled,
  extraClassname,
}: {
  isLoading?: boolean;
  title?: string;
  onSubmit?: Function;
  disabled?: boolean;
  extraClassname?: string;
}) {
  if (onSubmit)
    return (
      <button
        onClick={(e) => {
          e.preventDefault();
          console.log('has');
          onSubmit();
        }}
        disabled={isLoading || disabled}
        className={`min-w-[120px] px-2  py-2 ${
          title === 'Duplicate' ? 'bg-green-700' : 'bg-red-700'
        }  ${disabled ? 'bg-opacity-80 cursor-not-allowed' : ''} button ${extraClassname ?? ''}`}
        type='submit'
      >
        {isLoading ? (
          // <span className="animate-spin">
          <span className='flex gap-1'>
            <svg
              className='animate-spin h-5 w-5 text-white'
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
            >
              <circle
                className='opacity-25'
                cx='12'
                cy='12'
                r='10'
                stroke='currentColor'
                strokeWidth='4'
              ></circle>
              <path
                className='opacity-75'
                fill='currentColor'
                d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
              ></path>
            </svg>
            <p className='text-white'>Submitting...</p>
          </span>
        ) : (
          title
        )}{' '}
      </button>
    );
  return (
    <button
      disabled={isLoading || disabled}
      className={`min-w-[120px] px-2  py-2 ${
        title === 'Duplicate' ? 'bg-green-700' : 'bg-red-700'
      } hover:bg-opacity-80 text-white font-semibold text-sm rounded-lg ml-2 ${
        disabled ? 'cursor-not-allowed bg-opacity-80' : ''
      } button ${extraClassname ?? ''}`}
      type='submit'
    >
      {isLoading ? (
        // <span className="animate-spin">
        <span className='flex gap-1'>
          <svg
            className='animate-spin h-5 w-5 text-white'
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
          >
            <circle
              className='opacity-25'
              cx='12'
              cy='12'
              r='10'
              stroke='currentColor'
              strokeWidth='4'
            ></circle>
            <path
              className='opacity-75'
              fill='currentColor'
              d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
            ></path>
          </svg>
          <p className='text-white'>Submitting...</p>
        </span>
      ) : (
        title
      )}{' '}
    </button>
  );
}
