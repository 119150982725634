import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { Navbar, Sidebar } from './components';
import { useStateContext } from './contexts/ContextProvider';
import { selectLogout, selectToken, selectUser } from './slices/auth';
import AcceptedTerms from './routes/terms-and-conditions/components/accept-terms';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfirmLogout from './components/confirm-logout';
import { useEffect, useState } from 'react';

function ProtectedRoutes() {
  const auth = true;
  const { activeMenu } = useStateContext();
  const token = useSelector(selectToken);
  const user = useSelector(selectUser);
  const { isClicked, setIsClicked } = useStateContext();
  const logOut = useSelector(selectLogout);
  const [confirmationLogout, setConfirmationLogout] = useState<boolean>(false);

  useEffect(() => {
    if (logOut === true) setConfirmationLogout(true);
    else setConfirmationLogout(false);
  }, [logOut]);

  if (!token) return <Navigate to='/auth' />;
  if (user && user.firstTime) return <Navigate to='/first-time' />;
  if (user && user?.hasOwnProperty('acceptedTerms') && user.acceptedTerms !== true) {
    return <AcceptedTerms />;
  }
  if (token && !user.isVerified) return <Navigate to='/guardian-verification' />;
  return (
    <>
      <ToastContainer />
      <div className='flex relative dark:bg-main-dark-bg'>
        <div
          className={`transition-width duration-500 ease-in-out ${
            activeMenu ? 'w-72' : 'w-0'
          } fixed sidebar dark:bg-secondary-dark-bg bg-white z-[10] `}
        >
          <Sidebar />
        </div>

        <div
          className={`dark:bg-main-bg bg-main-bg min-h-screen 
          ${activeMenu ? ' md:w-[calc(100%-18rem)] w-full relative md:ml-72 ' : 'flex-2 w-full'}`}
        >
          <div className=' md:static bg-main-bg dark:bg-main-dark-bg navbar w-full '>
            <Navbar />
          </div>

          <div className='p-5 overflow-auto'>
            {confirmationLogout ? <ConfirmLogout /> : null}
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}

export default ProtectedRoutes;
