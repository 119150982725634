import { Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import { FormInput } from '../../../components/form-input/form-input';
import { Avatar, AvatarFallback, AvatarImage } from '../../../components/ui/avatar/Avatar';
import { Button } from '../../../components/ui/button/Button';
import { changeUserImage, selectUser, setCredentials } from '../../../slices/auth';
import {
  useChangeUserProfilePhotoMutation,
  useUpdateAccountSettingsMutation,
} from '../../../slices/userApiSlice';
import { useAppDispatch } from '../../../store';
import { firstLetterToUpperCase } from '../../../utils/utils';
import SettingsContainer from './SettingsContainer';
import { UserModel } from '../../listview/users/model/user.model';
import { UserRoles, allStates, gendersOptions } from '../../../utils/constants';
import {
  UpdateAccountSettingsSchema,
  UpdateAccountSettingsSchemaCoach,
  UpdateAccountSettingsSchemaGuardian,
  UpdateAccountSettingsSchemaOgma,
  UpdateAccountSettingsSchemaStudent,
} from './schema/update-account-settings.schema';
import { useEffect, useState } from 'react';
import { SettingMyAccount } from '../../../utils/messages.enum';
import { LoadingSceletonMyAccount } from '../../../routes/tournaments/routes/all/loading-sceleton-myaccount';
import CanIView from '../../../components/can-i-view/can-i-view';
import { Link, Outlet } from 'react-router-dom';

const MyAccount = () => {
  const [updateInfo, { isError, isLoading, isSuccess, status }] =
    useUpdateAccountSettingsMutation();
  const [msg, setMsg] = useState('');

  useEffect(() => {
    if (isSuccess) {
      setMsg(SettingMyAccount.success);
      const timeOutId = setTimeout(() => setMsg(''), 2500);
      return () => {
        clearTimeout(timeOutId);
      };
    }
  }, [isSuccess]);

  const [
    changeUserProfilePhoto,
    { isError: phIsError, isLoading: phIsLoading, isSuccess: phIsSuccess },
  ] = useChangeUserProfilePhotoMutation();
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  // const model = new UserModel(user);
  const [model, setModel] = useState(new UserModel(user));
  useEffect(() => {
    setModel(new UserModel(user));
  }, [user]);

  console.log('specific user??', user);

  const [inputList, setInputList] = useState([
    {
      label: 'First Name',
      name: 'firstName',
      type: 'text',
      isDisabled: true,
      placeholder: 'Enter first name',
    },
    {
      label: 'Last Name',
      name: 'lastName',
      type: 'text',
      isDisabled: true,
      placeholder: 'Enter last name',
    },
    {
      label: 'Username',
      name: 'username',
      type: 'text',
      isDisabled: true,
      placeholder: 'Enter username',
    },
    {
      label: 'Email Address',
      name: 'email',
      type: 'text',
      isDisabled: true,
      placeholder: 'Enter email address',
    },
    {
      label: 'Grade Level',
      name: 'gradeLevel',
      type: 'text',
      isDisabled: ![UserRoles.Admin].includes(model.roleNumber),
    },

    // {
    //   label: 'Street Address',
    //   name: 'streetAddress',
    //   type: 'text',
    //   placeholder: 'Enter street address',
    // },
    {
      label: 'City',
      name: 'city',
      type: 'text',
      placeholder: 'Enter city',
    },
    {
      label: 'State',
      name: 'state',
      type: 'text',
      isSelect: true,
      options: allStates,
      placeholder: 'Enter state',
    },
    // {
    //   name: 'gender',
    //   label: 'Gender',
    //   type: 'text',
    //   isSelect: true,
    //   // options: gendersOptions,
    //   placeholder: 'Enter state',
    // },
    {
      label: 'Zip Code',
      name: 'zipCode',
      type: 'text',
      placeholder: 'Enter zip code',
    },
    {
      label: 'Phone Number',
      name: 'phoneNumber',
      type: 'text',
      placeholder: 'Enter phone number',
    },
    {
      label: 'Office Number',
      name: 'officeNumber',
      type: 'text',
      placeholder: 'Enter office number',
    },
    {
      name: 'gender',
      label: 'Gender',
      type: 'text',
      isSelect: true,
      options: gendersOptions,

      // isDisabled: ![UserRoles.Admin || UserRoles.Student].includes(model.roleNumber),
      isDisabled: ![
        UserRoles.Admin,
        UserRoles.Student,
        UserRoles.Ogma,
        UserRoles.Guardian,
        UserRoles.Coach,
      ].includes(model.roleNumber),
    },
  ]);

  useEffect(() => {
    if (
      user.roles === UserRoles.Admin ||
      user.roles === UserRoles.Ogma ||
      user.roles === UserRoles.Student ||
      user.roles === UserRoles.Guardian ||
      user.roles === UserRoles.Coach
    )
      return;
    const currentInputs = [...inputList];

    currentInputs.map((input) => {
      if (input.name === 'gender') {
        input.isSelect = false;
      }
    });

    setInputList(currentInputs);
    // }
  }, [user]);

  // This useEffect added to switch places of office number with gender for OGMA

  useEffect(() => {
    if (user.roles === UserRoles.Ogma) {
      const copy = [...inputList];
      [copy[9], copy[10]] = [copy[10], copy[9]];
      setInputList(copy);
    }
  }, []);

  useEffect(() => {
    if (user.roles === UserRoles.Student) {
      const currentInputs = [...inputList];
      const toBeRemoved = ['city', 'state', 'zipCode'];
      const updatedItems = currentInputs.filter((field) => !toBeRemoved.includes(field.name));
      setInputList(updatedItems);
    }
  }, []);

  const onSubmit = (values: any) => {
    console.log('values', values);
    const data = values;
    if (data.gradeLevel) {
      delete data.gradeLevel;
    }
    if (
      user.roles !== UserRoles.Admin &&
      user.roles !== UserRoles.Ogma &&
      user.roles !== UserRoles.Student &&
      user.roles !== UserRoles.Guardian &&
      user.roles !== UserRoles.Coach
    ) {
      delete data.gender;
    }
    if (user.roles === UserRoles.Student) {
      delete data?.zipCode;
      delete data?.phoneNumber;
    } else data.zipCode = +data.zipCode;
    Object.keys(data).forEach((d) => {
      if (inputList.filter((i) => i.name === d && !i.isDisabled).length) return;
      delete data[d];
    });
    // data.dateOfBirth = new Date(values.dateOfBirth).toISOString();
    if (data.hasOwnProperty('dateOfBirth')) delete data.dateOfBirth;
    if (data.hasOwnProperty('officeNumber')) {
      data.office_number = data.officeNumber;
      delete data.officeNumber;
    }
    if (data.office_number === '') {
      delete data.office_number;
    }
    if (data.hasOwnProperty('gender')) data.gender = String(data.gender);
    console.log('data', data);
    updateInfo(data)
      .unwrap()
      .then((res: any) => dispatch(setCredentials(res)))
      .catch((error) => console.log(error));
    setMsg('');
  };

  if (isLoading) return <LoadingSceletonMyAccount />;

  function handleFileChange(event: any) {
    const file = event.target.files[0];
    const formData = new FormData();

    formData.append('file', file);

    changeUserProfilePhoto({ photo: formData, id: user.id })
      .unwrap()
      .then((res) => dispatch(changeUserImage(res.image)))
      .catch((e) => console.log(e));
  }

  // if (isSuccess) return <Navigate to='./success' />;
  return (
    <>
      <SettingsContainer title='Personal Information'>
        <div className='flex gap-6 sm:pl-0 md:pl-10 mb-6 items-center '>
          <Avatar className='h-20 w-20'>
            <AvatarImage width={200} src={user.image} />
            <AvatarFallback>
              {firstLetterToUpperCase(user.firstName) + firstLetterToUpperCase(user.lastName)}
            </AvatarFallback>
          </Avatar>

        <label className='hover:bg-slate-800 duration-75 flex p-2 px-4 text-white  items-center  main-gr-bg-color text-blue rounded-lg shadow-lg tracking-wide  border border-blue cursor-pointer '>
          {phIsLoading ? 'Uploading...' : 'Upload Image'}
          <input type='file' accept='image/*' onChange={handleFileChange} className='hidden' />
        </label>
      </div>
      <Formik
        initialValues={
          user.roles === UserRoles.Student
            ? {
                firstName: model.firstName,
                lastName: model.lastName,
                streetAddress: model.streetAddress,
                // city: model.city,
                // state: model.state,
                // zipCode: model.zipCode,
                gender: model.gender,
                gradeLevel: model.gradeLevel,
                username: model.username,
                email: model.email,
                dateOfBirth: model.dateOfBirth.split('T')[0],
                // phoneNumber: model.phoneNumber,
              }
            : user.roles === UserRoles.Ogma
            ? {
                firstName: model.firstName,
                lastName: model.lastName,
                username: model.username,
                city: model.city,
                state: model.state,
                zipCode: model.zipCode,
                phoneNumber: model.phoneNumber,
                officeNumber: model.officeNumber,
                gender: model.gender,
                email: model.email,
              }
            : {
                firstName: model.firstName,
                lastName: model.lastName,
                streetAddress: model.streetAddress,
                city: model.city,
                state: model.state,
                zipCode: model.zipCode,
                gender: model.gender,
                username: model.username,
                email: model.email,
                dateOfBirth: model.dateOfBirth.split('T')[0],
                phoneNumber: model.phoneNumber,
              }
        }
        validationSchema={
          user?.roles === UserRoles.Student
            ? UpdateAccountSettingsSchemaStudent
            : user?.roles === UserRoles.Ogma
            ? UpdateAccountSettingsSchemaOgma
            : user?.roles === UserRoles.Guardian
            ? UpdateAccountSettingsSchemaGuardian
            : user?.roles === UserRoles.Coach
            ? UpdateAccountSettingsSchemaCoach
            : UpdateAccountSettingsSchema
        }
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        <Form>
          <div className='flex sm:px-0 md:px-8 flex-wrap justify-between w-full '>
            {inputList.map((input, i: number) => (
              <CanIView key={input.name} component={`settings.${input.name}`}>
                <div className={'flex flex-col gap-2 w-5/12 flex-wrap'}>
                  <FormInput {...input} />
                </div>
              </CanIView>
            ))}
          </div>

            {msg && <span className='text-green-700 sm:px-0 md:px-8'>{msg}</span>}

            <div className='flex justify-between'>
              <div className='flex justify-start mt-4'>
                <Link className='button hover:bg-opacity-80' to={`./delete/${user.id}`}>
                  Delete
                </Link>
              </div>
              <div className='flex justify-end mt-4'>
                {/* <SubmitButton isLoading={isLoading} title={'Save Changes'} /> */}
                <Button size='lg' type='submit' disabled={isLoading}>
                  Save Changes
                </Button>
              </div>
            </div>
            {isError && <div className='text-red-700'>Something went wrong!</div>}
          </Form>
        </Formik>
      </SettingsContainer>
      <Outlet />
    </>
  );
};

export default MyAccount;
