import { SponsorPlacementRow } from './components/sponsor-placement-row';
import { Outlet } from 'react-router';
import ModalContent from '../../../../components/modal-content';
import { OutsideClick } from '../../../../components/outside-click';
import { useGetSponsorPlacementsQuery } from '../../../../slices/sponsorApiSlice';
import { Link } from 'react-router-dom';

const SponsorPlacement = () => {
  const { data, isSuccess, isLoading } = useGetSponsorPlacementsQuery('');
  return (
    <>
      <OutsideClick />
      <ModalContent extraClass=' max-h-[90vh] overflow-y-auto ' hideFooter name='Sponsor Placement'>
        <>
          {isLoading ? 'Loading...' : ''}
          {!isLoading ? (
            <div className='flex flex-col gap-5 p-5 overflow-auto'>
              <Link to='./new' className='btn red self-end'>
                Add New
              </Link>
              <Outlet />
              <hr />
              {data.map((d: any) => (
                <SponsorPlacementRow name={d?.name} id={d?.id} key={d?.id} />
              ))}
            </div>
          ) : (
            <></>
          )}
        </>
      </ModalContent>
    </>
  );
};

export default SponsorPlacement;
