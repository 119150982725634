import React, { useEffect, useState } from 'react';
import {
  useConnectStudentWithGuardianMutation,
  useGetGuardianByIdQuery,
} from '../../../../slices/userApiSlice';
import { OutsideClick } from '../../../../components/outside-click';
import { Form, Formik } from 'formik';
import { FormInput } from '../../../../components/form-input/form-input';
import { Navigate, useParams } from 'react-router-dom';
import ModalHeader from '../../../../components/modal-header';
import ModalFooter from '../../../../components/modal-footer';

const AddMyStudent = () => {
  const param = useParams();
  const [chosenStudent, setChosenStudent] = useState<object[]>([]);

  const {
    data: guardian,
    isLoading: guardianIsLoading,
    isError: guardianIsError,
  } = useGetGuardianByIdQuery(param.id);

  useEffect(() => {
    if (!guardian) return;
    if (guardian?.students) {
      console.log('guardian students got called', guardian.students);
      setChosenStudent((s) => (guardian.students ? [...s, ...guardian.students] : []));
    }
  }, [guardian]);

  const [connectStudent, { data, isLoading, isSuccess, isError }] =
    useConnectStudentWithGuardianMutation();

  const formInputs = [
    {
      mainTitle: 'Student Information',
      name: 'studentIds',
      label: 'Student Emails',
      type: 'text',
      placeholder: 'Enter students email',
      isTrue: true,
      search: 'studentEmail',
      setChosenStudent,
      chosenStudent,
    },
  ];

  const onSubmit = (values: any) => {
    const data = { ...values };
    delete data.name;
    data.studentIds = [];
    chosenStudent.forEach((s: any) => data.studentIds.push({ email: s.email }));
    data.guardianId = param.id;

    connectStudent(data)
      .unwrap()
      .then((res) => console.log(res))
      .catch((error) => console.log(error));
  };

  const initialValues: { [key: string]: string } = {};
  formInputs.forEach((input) => (initialValues[input.name] = ''));

  if (isSuccess) return <Navigate to='../' />;
  if (guardianIsLoading) return <p>Loading...</p>;
  return (
    <>
      <OutsideClick />
      <Formik initialValues={initialValues} onSubmit={onSubmit} className='overflow-scroll'>
        {(formik) => {
          return (
            <Form>
              <div className='fixed h-fit top-0 left-0 bottom-0 right-0 m-auto z-10 w-auto flex items-center justify-center  max-w-2xl'>
                <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-[90vh]'>
                  <ModalHeader name='Manage My Students' />
                  <div className='relative px-3 flex-auto min-h-[20vh] max-h-[80vh] overflow-auto'>
                    {formInputs.map((input: any, i: number) => (
                      <React.Fragment key={i}>
                        {!formInputs[i - 1] || formInputs[i - 1]?.mainTitle !== input.mainTitle ? (
                          <>
                            <div className='flex flex-col'>
                              <div className='mb-2'>
                                <p className='text-gray-800 font-bold pt-6'>{input.mainTitle}</p>
                                <hr />
                              </div>
                              <div className='flex flex-wrap gap-1'>
                                {formInputs
                                  .filter((inp1: any) => inp1.mainTitle === input.mainTitle)
                                  .map((inp: any, i: any) => (
                                    <React.Fragment key={i}>
                                      <div
                                        className={inp.isTrue ? 'w-full' : 'flex-1 min-w-[300px]'}
                                      >
                                        <FormInput {...inp} />
                                      </div>
                                    </React.Fragment>
                                  ))}
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                  <ModalFooter isLoading={isLoading!} />
                  {/*footer*/}
                  {/* <div className='flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b'>
                                        <Link
                                            className='min-w-[120px] bg-white px-2 py-2 hover:bg-opacity-80 shadow-lg text-black font-semibold rounded-lg ml-2 text-center'
                                            to='../'
                                        >
                                            Cancel
                                        </Link>
                                        <SubmitButton isLoading={isLoading} disabled={isLoading} />
                                    </div> */}
                </div>
              </div>
              {/* </div> */}
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default AddMyStudent;
