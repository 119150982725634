import { TournamentsGroupByInput } from './components/tournaments-group-by-input';
import { TournamentGridCard } from './components/tournament-grid-card';
import React, { MouseEvent, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { HeaderButton } from '../../../../components/header-button';
import { NameAndAvatar } from '../../../../components/name-and-avatar';
import TableContainer from '../../../../components/table-container/table-container';
import { TableHead } from '../../../../components/table-head';
import TableTd from '../../../../components/table-td/table-td';
import {
  useGetAllTournamentsQuery,
  useDownloadExportedListMutation,
} from '../../../../slices/tournamentApiSlice';
import { Search } from '../../../../components/search/search';
import TournamentGroupedBySubdivision from './components/tournament-grouped-by-subdivision';
import TournamentGroupedByDivision from './components/tournament-grouped-by-division';
import TournamentGroupedByGame from './components/tournament-grouped-by-game';
import { ScrollArea } from '../../../../components/ui/scroll-area/ScrollArea';
import { Separator } from '../../../../components/ui/separator/Separator';
import CanIView from '../../../../components/can-i-view/can-i-view';
import { TournamentModel } from '../../../../model/tournament.model';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../slices/auth';
import { TournamentStatusNames, UserRoles } from '../../../../utils/constants';
import Pagination from '../../../../components/pagination';
import ShowTeamsModal from '../../../../components/show-teams-modal';
import DropdownItem from '../../../../features/listview/DropDown/DropDownItem';
import { LoadingSceletonLists } from './loading-sceleton-lists';

const configureListView = [
  {
    name: 'name',
    active: true,
    prop: 'name',
  },
  {
    name: 'game',
    active: true,
    prop: 'game',
  },
  // {
  //   name: 'status',
  //   active: true,
  //   prop: 'status',
  // },
  {
    name: 'start date',
    active: true,
    prop: 'f_startDate',
  },
  {
    name: 'end date',
    active: true,
    prop: 'f_endDate',
  },
  {
    name: 'teams',
    active: true,
    prop: 'teams',
  },
  {
    name: 'category division',
    active: true,
    prop: 'division',
  },
  {
    name: 'subdivision',
    active: true,
    prop: 'subdivision',
  },
];

const filterList = [
  {
    title: 'name',
    property: 'name',
  },
  {
    title: 'game name',
    property: 'Game',
  },
  {
    title: 'category division',
    property: 'category',
  },
  {
    title: 'subdivision',
    property: 'subdivision',
  },
];

function AllTournaments() {
  const [searchParams, setSearchParams] = useSearchParams();
  const user = useSelector(selectUser);
  const navigate = useNavigate();

  const [view, setView] = useState(true);
  const [listView, setListView] = useState(configureListView);
  const [search, setSearch] = useState({ by: 'name', keyword: '' });
  const [modelId, setModelId] = useState<String[] | undefined>();
  const initialOpenListState = {
    id: null,
    x: null,
    y: null,
  };
  const [openList, setOpenList] = useState<{ [key: string]: number | null }>(initialOpenListState);
  const [openListView, setOpenListView] = useState(false);

  const handleTdClick = (teams: any) => {
    setModelId(teams);
  };

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/tournaments') navigate('/tournament/all');
    console.log(location.pathname);
  });

  function handleClick(prop: { id: number; x: number; y: number }) {
    setOpenListView(false);
    if (openList.id == prop.id) {
      setOpenList(initialOpenListState);
    } else {
      setOpenList(prop);
    }
  }
  function handleListView() {
    console.log('hello');
    setOpenList(initialOpenListState);
    setOpenListView(!openListView);
  }

  const headerButtons = [
    {
      type: 'export',
      view,
      name: 'Export List Filtered',
      func: () =>
        downloadList({
          page: searchParams.get('page') ?? '1',
          query: {
            name: searchParams.get('name') ?? undefined,
            Game: searchParams.get('game') ?? undefined,
            category: searchParams.get('category') ?? undefined,
            subdivision: searchParams.get('subdivision') ?? undefined,
          },
        }),
    },
    {
      type: 'export',
      view,
      name: 'Export List',
      func: () =>
        downloadList({
          page: searchParams.get('page') ?? '1',
          query: {
            unfilter: 'ok',
            name: searchParams.get('name') ?? undefined,
            Game: searchParams.get('game') ?? undefined,
            category: searchParams.get('category') ?? undefined,
            subdivision: searchParams.get('subdivision') ?? undefined,
          },
        }),
    },

    {
      type: 'configureListView',
      view,
      listView,
      setListView,
    },
    {
      type: 'newTournament',
    },
    {
      type: 'listView',
      func: () => setView(true),
    },
    {
      type: 'gridView',
      func: () => setView(false),
    },
  ];
  const [searchQuery, setSearchQuery] = useState(filterList[0].title);

  const [downloadList, { isLoading: isDownloading }] = useDownloadExportedListMutation();
  const { data, isLoading, isError } = useGetAllTournamentsQuery({
    query: searchQuery,
    param: searchParams.get(searchQuery) ? searchParams.get(searchQuery) : '',
    page: searchParams.get('page') ? searchParams.get('page') : 1,
  });

  if (isLoading) return <LoadingSceletonLists />;

  return (
    <div className='flex overflow-auto max-w-full flex-col flex-1 rounded-xl bg-white border-1'>
      <div className='flex w-full p-5 justify-between flex-wrap'>
        <div className='flex items-center justify-between'>
          <h2 className='text-2xl font-semibold tracking-tight'>Tournaments</h2>
        </div>
        <div className='flex gap-2 justify-center flex-wrap'>
          {headerButtons.map((b, i) => (
            <CanIView component={`tournament.${b.type}`} key={b.type + i}>
              <HeaderButton key={b.type} view={view} {...b} />
            </CanIView>
          ))}
        </div>
      </div>
      <Separator className='my-4' />
      <div className='flex w-full justify-between p-5 gap-2 flex-wrap '>
        <div className='flex items-center gap-5 flex-wrap'>
          {view ? (
            <></>
          ) : (
            <CanIView component='tournament.groupedTournamentsButton'>
              <TournamentsGroupByInput />
            </CanIView>
          )}
        </div>
        {view || !searchParams.get('groupedBy')?.length ? (
          <Search
            setQuery={setSearchQuery}
            filterList={filterList}
            search={search}
            setSearch={setSearch}
          />
        ) : null}
      </div>
      {view ? (
        <div className='listViewContainer w-full justify-between h-full'>
          {modelId ? (
            <ShowTeamsModal title='All Tournament Teams' setModelId={setModelId} teams={modelId} />
          ) : (
            <></>
          )}
          <TableContainer>
            <TableHead
              oneMore={user.roles === UserRoles.Admin}
              tableList={listView
                .map((l) => l.name)
                .filter(
                  (t) => listView.find((d) => d.name.toLowerCase() === t.toLowerCase())?.active,
                )}
            />
            <tbody className='bg-white divide-y divide-gray-200 w-full'>
              {data?.data?.map((t: any, i: number) => (
                <tr
                  onClick={(e) =>
                    user.roles === UserRoles.Admin
                      ? handleClick({ id: t.id, x: e.pageX, y: e.pageY })
                      : navigate(`/tournaments/view/${t.id}`)
                  }
                  className={user.roles !== UserRoles.Admin ? 'cursor-pointer' : 'relative'}
                  key={i}
                >
                  {listView
                    .filter((l) => l.active)
                    .map((o, i) => {
                      const model: TournamentModel & { [key: string]: any } = new TournamentModel(
                        t,
                      );
                      if (o.prop === 'name')
                        return (
                          <TableTd key={i}>
                            <div className='flex gap-2'>
                              <NameAndAvatar name={model.name} image={model.image} />
                              <span
                                className={`eventStatus type${model.status} flex items-center `}
                              >
                                {TournamentStatusNames[model.status]}
                              </span>
                            </div>
                          </TableTd>
                        );
                      if (o.prop === 'teams')
                        return (
                          <React.Fragment key={i}>
                            <TableTd
                              key={i}
                              handleClick={(e: MouseEvent<HTMLElement>) => {
                                model.teams.length > 1 ? handleTdClick(model.teams) : null;
                                e.stopPropagation();
                              }}
                            >
                              {model.teams.length > 1 ? (
                                <span className='hover:text-red-600 underline'>Show Teams</span>
                              ) : model.teams.length === 0 ? (
                                <span>-</span>
                              ) : (
                                <div className='max-w-[15ch] truncate'>
                                  {model.teams[0]?.teamName}
                                </div>
                              )}
                            </TableTd>
                          </React.Fragment>
                        );
                      if (o.prop === 'division')
                        return (
                          <TableTd key={i} extraClass='max-w-[15ch]'>
                            <div className='text-sm font-medium text-gray-900 truncate'>
                              {model.division}
                            </div>
                          </TableTd>
                        );

                      if (o.prop === 'game')
                        return (
                          <TableTd key={i} extraClass='max-w-[15ch]'>
                            <div className='text-sm font-medium text-gray-900 truncate'>
                              {model.game}
                            </div>
                          </TableTd>
                        );

                      if (o.prop === 'subdivision')
                        return (
                          <TableTd key={i} extraClass='max-w-[15ch]'>
                            {model?.subdivision?.length === 1 ? (
                              <div className='truncate'>{model.subdivision[0]?.name} </div>
                            ) : (
                              model?.subdivision[0]?.name
                            )}

                            <span className='group relative select-none hover:cursor-pointer '>
                              {model.subdivision.slice(1).length
                                ? `, +${model.subdivision.slice(1).length} more`
                                : ''}
                              <div className='hidden absolute shadow-md  top-[100%] -right-[100%] z-50 group-hover:flex'>
                                {model.subdivision.slice(1).length ? (
                                  <ScrollArea className='max-h-72 min-h-fit w-48 bg-white	 rounded-md border border-slate-100 dark:border-slate-700'>
                                    <div className='p-4'>
                                      {model.subdivision.slice(1).map((sub: any, i: number) => (
                                        <React.Fragment key={i}>
                                          <div className='text-sm'>{sub.name}</div>
                                          <Separator className='my-2' />
                                        </React.Fragment>
                                      ))}
                                    </div>
                                  </ScrollArea>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </span>
                          </TableTd>
                        );
                      return <TableTd key={i}>{model[o.prop]}</TableTd>;
                    })}
                  <TableTd>
                    <div className='listViewMenu--parent'>
                      <div
                        className={openList.id === t.id ? '' : 'hidden'}
                        style={
                          openList.id === t.id
                            ? {
                                position: 'fixed',
                                top: Number(openList.y),
                                left:
                                  Number(openList.x) < 200
                                    ? Number(openList.x) + 200
                                    : Number(openList.x),
                                zIndex: 99,
                              }
                            : {
                                left: 0,
                              }
                        }
                      >
                        <div
                          className='listViewMenu'
                          role='menu'
                          aria-orientation='vertical'
                          aria-labelledby='menu-button'
                        >
                          <div className='' role='none'>
                            <DropdownItem
                              // {...(model.roleNumber === UserRoles.Admin ||
                              // model.roleNumber === UserRoles.Fan
                              //   ? { noView: true }
                              //   : {
                              //       viewLink: `./view/${model.id}/${model.role}`,
                              //     })}
                              id={t.id}
                              setFunc={setOpenList}
                              tournament
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </TableTd>
                  {/* { <ThreeDots tournament={true} id={t.id} /> } */}
                </tr>
              ))}
            </tbody>
          </TableContainer>
          <Pagination length={data?.data?.length} {...data?.meta} />
        </div>
      ) : searchParams.get('groupedBy') === '1' ? (
        <TournamentGroupedByGame />
      ) : searchParams.get('groupedBy') === '2' ? (
        <TournamentGroupedByDivision />
      ) : searchParams.get('groupedBy') === '3' ? (
        <TournamentGroupedBySubdivision />
      ) : (
        <div className='flex flex-col p-5 gap-5 justify-end'>
          <div className='flex  gap-5 justify-center flex-wrap'>
            {data?.data?.map((t: any, i: number) => {
              return <TournamentGridCard key={i} {...t} />;
            })}
          </div>
          <Pagination length={data?.data?.length} {...data?.meta} />
        </div>
      )}
      <Outlet />
    </div>
  );
}

export default AllTournaments;
