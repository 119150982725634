export function LoadingSceletonSecurity() {
  return (
    <div className='w-full h-full border-2 rounded-xl flex flex-col p-1'>
      <div>
        <div className='w-[300px] min-h-[60px] bg-slate-200 rounded-lg  mt-5 ml-3 '></div>
      </div>
      <div className='flex flex-col p-6-4 grid-cols-4 gap-3 skeleton__pulse p-10'>
        <div className='w-[300px] min-h-[60px] bg-slate-200 rounded-lg  '></div>
        <div className='w-[300px] min-h-[60px] bg-slate-200 rounded-lg  '></div>
        <div className='w-[300px] min-h-[60px] bg-slate-200 rounded-lg  '></div>
      </div>
    </div>
  );
}
