import ModalHeader from '../../components/modal-header';
import { OutsideClick } from '../../components/outside-click';
import RemoveTeamsFromSeason from './route/remove-teams-from-season.component';

const RemoveTeamSeason = () => {
  return (
    <>
      <OutsideClick />
      <div className='fixed top-0 left-0 bottom-0 right-0 m-auto z-10 w-auto  max-w-2xl h-[90vh] max-h-[90vh]'>
        <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none h-full'>
          <ModalHeader name={'Remove Team From Season'} />
          <div className='relative px-3 flex-auto overflow-auto flex justify-center'>
            <RemoveTeamsFromSeason />
          </div>
        </div>
      </div>
    </>
  );
};

export default RemoveTeamSeason;
