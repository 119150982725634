import React from 'react';
import CanIView from '../../../components/can-i-view/can-i-view';
import { AiOutlineCamera } from 'react-icons/ai';
import { useChangeClassroomBannerMutation } from '../../../slices/classroomApiSlice';
import { useParams } from 'react-router';

const defaultBg = (await import('../../../assets/grid-background.jpg')).default;
export function ViewClassroomHeader({ image = '', title = '', description = '' }) {
  const [change, { isLoading, isSuccess }] = useChangeClassroomBannerMutation();
  const params = useParams();
  function handleFileChange(event: any) {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);

    if (params.id) change({ body: formData, id: params.id });
  }
  return (
    <div className='flex group flex-col relative w-full min-h-[150px] overflow-hidden'>
      {image.length ? (
        <img src={image} className='h-full w-full object-cover absolute top-0 left-0 ' />
      ) : (
        <img src={defaultBg} className='w-full object-contain absolute top-0 left-0 ' />
        // <div className='w-full absolute top-0 left-0 bg-black bg-opacity-30'></div>
      )}
      <CanIView component={`coach.changeBanner`}>
        <label className='group-hover:opacity-100 opacity-0 flex bg-black bg-opacity-80 absolute justify-center items-center w-full h-full  text-white left-0 top-0 cursor-pointer z-[100]'>
          <AiOutlineCamera size={30} />
          <input
            type='file'
            accept='image/*'
            onChange={handleFileChange}
            className='hidden mr-12'
          />
        </label>
      </CanIView>

      <div className='justify-center items-center p-10 z-10 flex flex-col bg-black bg-opacity-40 min-w-full h-[150px]'>
        <h2 className='font-bold text-white text-lg  uppercase'>{title}</h2>
        <p className='font-semibold text-white text-xs uppercase'>{description}</p>
      </div>
    </div>
  );
}
