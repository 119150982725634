import React, { useEffect } from 'react';
import { useGetUserByIdQuery, useUpdateCoachMutation } from '../../../slices/userApiSlice';
import { Link, Navigate, useParams } from 'react-router-dom';
import { SubmitButton } from '../../../components/submit-button';
import { Form, Formik, FormikValues } from 'formik';
import { FormInput } from '../../../components/form-input/form-input';
import { OutsideClick } from '../../../components/outside-click';
import { UpdateCoachSchema } from './update-coach.schema';
import { allStates } from '../../../utils/constants';

const UpdateCoachModal = () => {
  const param = useParams();

  const {
    data: coachData,
    isLoading: coachIsLoading,
    error: coachIsError,
  } = useGetUserByIdQuery(param.id, { skip: !param.id });

  const [updateCoach, { isLoading, isError, isSuccess }] = useUpdateCoachMutation();

  const onSubmit = (values: FormikValues) => {
    const data = values;
    data.zipCode = Number(data.zipCode);
    data.status = Number(data.status);
    data.dateOfBirth = new Date(data.dateOfBirth).toISOString();
    data.id = Number(param.id);
    console.log('updateCoach = ', data);
    updateCoach(data)
      .unwrap()
      .then((res) => console.log(res))
      .catch((error) => console.log(error));
  };

  const formInputs = [
    {
      mainTitle: 'Coach Information',
      name: 'firstName',
      label: 'First Name',
      type: 'text',
      placeholder: 'Enter first name',
    },
    {
      mainTitle: 'Coach Information',
      name: 'lastName',
      label: 'Last Name',
      type: 'text',
      placeholder: 'Enter last name',
    },
    {
      mainTitle: 'Coach Information',
      name: 'streetAddress',
      label: 'Street Address',
      type: 'text',
      placeholder: 'Enter street address',
    },
    {
      mainTitle: 'Coach Information',
      name: 'city',
      label: 'City',
      type: 'text',
      placeholder: 'Enter city',
    },
    {
      mainTitle: 'Coach Information',
      name: 'state',
      label: 'State',
      type: 'text',
      placeholder: 'Enter state',
      isSelect: true,
      options: allStates ? allStates : [],
    },

    {
      mainTitle: 'Coach Information',
      name: 'zipCode',
      label: 'Zip Code',
      type: 'number',
      placeholder: 'Enter zip code',
    },
    {
      mainTitle: 'Coach Information',
      name: 'tags',
      label: 'Tags',
      type: 'text',
      placeholder: 'Enter tags',
    },
    {
      mainTitle: 'Coach Information',
      name: 'phoneNumber',
      label: 'Phone Number',
      type: 'text',
      placeholder: 'Enter phone number',
    },
    {
      mainTitle: 'Coach Information',
      name: 'status',
      label: 'Status',
      type: 'text',
      placeholder: 'Active',
      isSelect: true,
      options: [
        { id: 0, name: 'Disabled' },
        { id: 1, name: 'Active' },
      ],
      isTrue: true,
    },
  ];

  if (coachIsLoading) return <p>Loading...</p>;
  if (isSuccess) return <Navigate to='../' />;
  return (
    <>
      <OutsideClick />
      <Formik
        validationSchema={UpdateCoachSchema}
        initialValues={{
          firstName: coachData.firstName,
          lastName: coachData.lastName,
          streetAddress: coachData.streetAddress,
          city: coachData.city,
          state: coachData.state,
          zipCode: coachData.zipCode,
          tags: coachData.tags,
          // dateOfBirth: coachData.dateOfBirth,
          // email: coachData.email,
          phoneNumber: coachData.phoneNumber,
          status: coachData.status,
        }}
        onSubmit={onSubmit}
      >
        <Form>
          <div className='fixed pb-20 top-0 left-0 right-0 z-10 w-auto my-6 mx-auto max-w-3xl h-[100vh]'>
            {/*content*/}
            <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none h-full overflow-auto'>
              {/*header*/}
              <div className='flex items-start justify-between mb-2 p-5 border-b border-solid border-slate-200 rounded-t'>
                <h3 className='text-3xl font-semibold'>Edit Coach</h3>
                <button
                  className='p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none'
                  // onClick={() => setShowModalSchools(false)}
                >
                  <span className='bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none'>
                    X
                  </span>
                </button>
              </div>
              <div className='flex flex-col gap-2 p-10'>
                {formInputs.map((input: any, i: number) => (
                  <React.Fragment key={i}>
                    {!formInputs[i - 1] || formInputs[i - 1]?.mainTitle !== input.mainTitle ? (
                      <>
                        <div className='flex flex-col'>
                          <div className='mb-2'>
                            <p className='text-gray-800 font-bold pt-6'>{input.mainTitle}</p>
                            <hr />
                          </div>
                          <div className='flex flex-wrap gap-1'>
                            {formInputs
                              .filter((inp1: any) => inp1.mainTitle === input.mainTitle)
                              .map((inp: any, i: number) => (
                                <React.Fragment key={i}>
                                  <div className={inp.isTrue ? 'w-full ' : 'flex-1 min-w-[300px]'}>
                                    <FormInput {...inp} />
                                  </div>
                                </React.Fragment>
                              ))}
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </React.Fragment>
                ))}
              </div>

              {/*footer*/}
              <div className='flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b'>
                <SubmitButton isLoading={isLoading} />
                <Link
                  className='min-w-[120px] h-10 my-12 text-center py-2 bg-white text-black border-1 border-neutral-200 font-semibold rounded-lg'
                  to='../'
                >
                  Cancel
                </Link>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
    </>
  );
};

export default UpdateCoachModal;
