import { useNavigate, useParams } from 'react-router';
import {
  useGetTeamByIdQuery,
  useGetValidSeasonsByTeamIdQuery,
} from '../../../slices/teamsApiSlice';
import { useEffect, useMemo, useState } from 'react';
import { SeasonModel } from '../../../model/season.model';
import { CiSearch } from 'react-icons/ci';
import { Form, Formik } from 'formik';
import { OutsideClick } from '../../../components/outside-click';
import ModalFooter from '../../../components/modal-footer';
import { PiWarningCircle } from 'react-icons/pi';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../../../components/ui/tooltip/Tooltip';
import { SeasonProgressStatus } from '../../../utils/constants';
import {
  useRemoveTeamFromSeasonMutation,
  useUpdateSeasonTeamMutation,
} from '../../../slices/seasonApiSlice';
import { toast } from 'react-toastify';

const TeamSeasonSelection = () => {
  const params = useParams();
  const navigate = useNavigate();

  const { data, isLoading, isError } = useGetValidSeasonsByTeamIdQuery(params.id);
  const {
    data: teamData,
    isLoading: teamIsLoading,
    isError: teamIsError,
  } = useGetTeamByIdQuery({ id: params.id, query: '' });
  const [
    updateSeasonTeam,
    {
      isLoading: updateSeasonIsLoading,
      isSuccess: updateSeasonIsSuccess,
      isError: updateSeasonIsError,
      error: updateTeamError,
    },
  ] = useUpdateSeasonTeamMutation();
  const [
    RemoveTeamFromSeason,
    {
      isLoading: removeSeasonIsLoading,
      isSuccess: removeSeasonIsSuccess,
      isError: removeSeasonIsError,
    },
  ] = useRemoveTeamFromSeasonMutation();
  const [selected, setSelected] = useState<SeasonModel[]>([]);
  const [existingSeasons, setExistingSeasons] = useState<SeasonModel[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const list = useMemo(() => {
    return data
      ?.filter((o: any) => {
        const model = new SeasonModel(o);

        return model.name?.toLowerCase()?.includes(searchValue.toLowerCase());
      })
      .sort((a: any, b: any) => a?.name!?.localeCompare(b?.name));
  }, [searchValue, data]);

  useEffect(() => {
    const existingSeasons = data
      ?.filter((season: any) => season.teams.some((team: any) => team.id === +(params.id ?? 0)))
      ?.map((season: any) => {
        return { id: season.id };
      });
    setExistingSeasons(existingSeasons);
    setSelected(existingSeasons);
  }, [data]);

  const handleSelection = (season: SeasonModel) => {
    setSelected((prevSelected) => {
      if (prevSelected.some((item) => item.id === season.id))
        return prevSelected?.filter((item) => item.id !== season.id);
      return [...prevSelected, season];
    });
  };

  const AddTeamToSeasonSchema = '';
  let error = false;
  const handleSubmit = async () => {
    try {
      const seasonsToRemove = existingSeasons.filter(
        (season: any) => !selected.some((selectedItem: any) => selectedItem.id === season.id),
      );
      const seasonsToAdd = selected.filter(
        (season) => !existingSeasons.some((existingSeason) => existingSeason.id === season.id),
      );
      await Promise.all(
        seasonsToAdd.map(async (season) => {
          const existingTeams = season.teams.map((team: any) => {
            return { id: team.id };
          });
          await updateSeasonTeam({
            id: season.id,
            body: { teams: [...existingTeams, params.id ? { id: +params.id } : []] },
          }).unwrap();
        }),
      );

      await Promise.all(
        seasonsToRemove.map(async (season) => {
          await RemoveTeamFromSeason({ season_id: season.id, team_id: +(params.id ?? 0) }).unwrap();
        }),
      );
    } catch (error: any) {
      if (error.data.message)
        toast.error(error.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      else {
        toast.error('Could not modify seasons.', {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      return;
    }
    navigate('../');
    toast.success('Successfully modified seasons.', {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  return (
    <>
      <OutsideClick />
      <Formik
        validationSchema={AddTeamToSeasonSchema}
        initialValues={{ match_date: undefined }}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        <Form>
          <div
            className='fixed h-fit top-0 left-0 bottom-0 right-0 m-auto z-10 
            w-fit flex items-center justify-center  container'
          >
            <div
              className='border-0 p-10 rounded-lg shadow-lg relative flex flex-col
               w-[50vh] bg-white outline-none focus:outline-none h-[90vh] '
            >
              <h3 className='uppercase text-center mb-4'>Available Seasons</h3>
              <p className='flex justify-center text-center text-gray-500 mb-2'>
                Ongoing seasons will not appear. To join an ongoing season, contact a Fenworks Rep.
              </p>
              <div className='relative mb-2'>
                <input
                  value={searchValue}
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                  className='resetInput font-normal !pl-7'
                  type='search'
                  placeholder='Search season'
                />
                <CiSearch className='absolute left-2 top-0 bottom-0 my-auto' />
              </div>

              <div className='flex flex-col font-bold gap-2  h-full overflow-auto'>
                {list?.map((d: SeasonModel) => {
                  const model = new SeasonModel(d);
                  const getClassName = () => {
                    if (selected?.filter((item) => item.id === d.id).length)
                      return 'bg-gray-200 hover:bg-gray-300 border-[4px] border-fen-blue';
                    return 'bg-white hover:bg-slate-50';
                  };
                  return (
                    <span
                      onClick={() => handleSelection(d)}
                      key={model.id}
                      className={`
                          ${getClassName()}
													p-3 select-none w-full
                          font-normal rounded-lg border-2 truncate 
                          flex items-center justify-between min-h-[44px] h-[44px] max-h-[44px] cursor-pointer   
                          `}
                    >
                      {<p className='text-gray-500 truncate pr-2'>{model.name}</p>}
                      {model.status === SeasonProgressStatus.Ongoing && (
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <div>
                                <PiWarningCircle size={20} color='red' />
                              </div>
                            </TooltipTrigger>
                            <TooltipContent>
                              <p>This season is currently ongoing.</p>
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      )}
                    </span>
                  );
                })}
              </div>
              <div className='flex self-center'>
                <ModalFooter isLoading={isLoading!} />
              </div>
            </div>
          </div>
        </Form>
      </Formik>
    </>
  );
};
export default TeamSeasonSelection;
