import React from 'react';
import { Link } from 'react-router-dom';
export function DeleteMatchButton({ }) {
    return (
        <Link to='./delete'>
            <div className='rounded-lg flex justify-center p-2 uppercase bg-red-700 text-white text-sm min-w-[200px]'>
                Delete
            </div>
        </Link>
    );
};