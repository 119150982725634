import React, { ReactNode } from 'react';

function TableTd({
  text = 'default',
  color = 'default',
  extraClass,
  children,
  handleClick,
}: {
  text?: string;
  color?: string;
  extraClass?: string;
  children: ReactNode;
  handleClick?: any;
}) {
  const textType: { [key: string]: string } = {
    sm: 'text-sm',
    md: 'text-md',
    lg: 'text-lg',
    default: '',
  };
  const textColor: { [key: string]: string } = {
    gray500: 'text-gray-500',
    gray900: 'text-gray-900',
    default: '',
  };

  return (
    <td
      className={`${
        extraClass ? extraClass : ''
      } px-6 py-4 whitespace-nowrap text-center cursor-pointer ${textType[text]} ${
        textColor[color]
      }`}
      onClick={handleClick}
    >
      {children}
    </td>
  );
}

export default TableTd;
