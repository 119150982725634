import { dateAndTimeFormatter } from "../../utils/dateFormatter";
import { TicketStatusTime, UserRoles, supportTicketStatusName, userRoleNames } from "../../utils/constants";
import { wordWithFirstLetterToUpperCase } from "../../utils/utils";
import moment from "moment";

export class ViewTicketModel {
    id?: number;
    title?: any;
    firstName?: string;
    lastName?: string;
    fullName?: string;
    image?: any;
    role?: string;
    category?: string;
    subcategory?: string;
    status?: string;
    createdAt?: string | any;
    email?: string;
    priorityLevel?: number;
    f_createdAt?: string;
    ttrTime?: string;
    hours?: number;
    minutes?: number;
    seconds?: number;
    slaTime?: string;
    inProgress?: string;
    description?: string;
    slaTimeT1?: string;
    resolveTimeT1?: string;

    constructor(data: any) {
        if (data?.hasOwnProperty('title')) this.title = data.title;
        if (data?.hasOwnProperty('user') && data.user && data.user.hasOwnProperty('firstName'))
            this.firstName = wordWithFirstLetterToUpperCase(data.user.firstName);
        if (data?.hasOwnProperty('user') && data.user && data.user.hasOwnProperty('lastName'))
            this.lastName = wordWithFirstLetterToUpperCase(data.user.lastName);
        if (this.firstName && this.lastName) this.fullName = `${this.firstName} ${this.lastName}`;
        if (data?.hasOwnProperty('user') && data.user && data.user.hasOwnProperty('roles')) this.role = userRoleNames[data.user.roles];
        if (data?.hasOwnProperty('createdAt')) {
            this.createdAt = data.createdAt;
            this.f_createdAt = dateAndTimeFormatter(this.createdAt).split(';')[0];
        }
        if (data?.hasOwnProperty('description')) {
            this.description = data.description;
        }
        if (data?.hasOwnProperty('subcategory') && data.subcategory && data?.subcategory?.hasOwnProperty('priorityLevel')) {
            this.priorityLevel = data.subcategory.priorityLevel;
        }
        if (data?.hasOwnProperty('email')) this.email = data.email;
        if (data?.hasOwnProperty('ticketTracking') && data.ticketTracking.hasOwnProperty('slaTime1') && data.ticketTracking.slaTime1?.length) {
            const responseTimeT1 = data.ticketTracking.slaTime1;
            const lastItem = responseTimeT1[responseTimeT1.length - 1];
            this.hours = Math.floor(lastItem / 3600);
            this.minutes = Math.floor((lastItem % 3600) / 60);
            this.seconds = lastItem % 60;
            this.slaTimeT1 = formatHour(this.hours) + ':' + formatMinutes(this.minutes) + ':' + formatSeconds(this.seconds);
        }
        else {
            this.slaTimeT1 = '00:00:00';
        }
        if (data?.hasOwnProperty('ticketTracking') && data?.ticketTracking?.hasOwnProperty('ttrTime1') && data?.ticketTracking?.ttrTime1?.length) {
            const timeStamp = data.ticketTracking.ttrTime1;
            const lastItem = timeStamp[0];
            this.hours = Math.floor(lastItem / 3600);
            this.minutes = Math.floor((lastItem % 3600) / 60);
            this.seconds = lastItem % 60;
            this.resolveTimeT1 = formatHour(this.hours) + ':' + formatMinutes(this.minutes) + ':' + formatSeconds(this.seconds);
        }
        else {
            this.resolveTimeT1 = '00:00:00';
        }
        if (data?.hasOwnProperty('ticketTracking') && data?.ticketTracking?.hasOwnProperty('slaTime2') && data?.ticketTracking?.slaTime2?.length && data?.ticketTier && data?.status !== TicketStatusTime.ToDo) {
            const timeStamp = data.ticketTracking.slaTime2;
            const lastItem = timeStamp[timeStamp.length - 1];
            this.hours = Math.floor(lastItem / 3600);
            this.minutes = Math.floor((lastItem % 3600) / 60);
            this.seconds = lastItem % 60;
            this.slaTime = formatHour(this.hours) + ':' + formatMinutes(this.minutes) + ':' + formatSeconds(this.seconds);
        }
        else if (data?.hasOwnProperty('ticketTracking') && data?.ticketTier && data?.ticketTracking?.ack && data?.status === TicketStatusTime.ToDo) {
            const todoJira = data?.ticketTracking?.createdInJira;
            const todoJiraTime = moment(todoJira[todoJira?.length - 1]);
            const todoAck = data?.ticketTracking?.ackStartToDo;
            const todoAckTime = moment(todoAck[todoAck?.length - 1]);
            const diff = todoAckTime.diff(todoJiraTime, 'milliseconds');
            this.slaTime = calculateTimeDiff(diff);
        }
        else {
            this.slaTime = '00:00:00';
        }
        if (data?.hasOwnProperty('ticketTracking') && data?.ticketTracking?.hasOwnProperty('ttrTime2') && data?.ticketTracking?.ttrTime2?.length && data?.ticketTier) {
            const timeStamp = data.ticketTracking.ttrTime2[0];
            this.hours = Math.floor(timeStamp / 3600);
            this.minutes = Math.floor((timeStamp % 3600) / 60);
            this.seconds = timeStamp % 60;
            this.ttrTime = formatHour(this.hours) + ':' + formatMinutes(this.minutes) + ':' + formatSeconds(this.seconds);
        }
        else if (data?.hasOwnProperty('ticketTracking') && !data?.ticketTracking?.ttrTime2?.length && data?.ticketTier && data?.status === TicketStatusTime.InProgressT2 && data?.ticketTracking?.whenInProgress2?.length && data?.ticketTracking?.whenInAckStart?.length && data?.ticketTracking?.ack) {
            const jiraInProgress = data?.ticketTracking?.whenInProgress2;
            const jiraInProgressTime = moment(jiraInProgress[jiraInProgress?.length - 1]);
            const ackProgress = data?.ticketTracking?.whenInAckStart;
            const totalPause = data?.ticketTracking?.pauseDurationInProgress;
            const ackProgressTime = ackProgress?.length <= 1 ? moment(ackProgress[ackProgress?.length - 1]) : moment(ackProgress[ackProgress?.length - 1]).subtract(totalPause, 'seconds');
            const diff = ackProgressTime.diff(jiraInProgressTime);
            this.ttrTime = calculateTimeDiff(diff);
        }
        else {
            this.ttrTime = '00:00:00';
        }
        if (data?.hasOwnProperty('status') && data?.ticketTier) {
            const todoOrAck = data?.ticketTracking?.ack ? 'In Progress' : 'Not Started';
            switch (data?.status) {
                case TicketStatusTime.ToDo:
                    this.status = todoOrAck;
                    break;
                case TicketStatusTime.InProgressT2:
                    this.status = 'In Progress'
                    break;
                case TicketStatusTime.MonitorReview:
                    this.status = 'Monitoring'
                    break;
            }
        }
        if (data?.hasOwnProperty('adminApproval') && data?.adminApproval) {
            this.status = 'Resolved';
        }
        if (data?.hasOwnProperty('ticketTracking') && data?.ticketTracking.hasOwnProperty('createdInJira') && data.ticketTracking.createdInJira?.length > 1 && data.ticketTier && data?.status === TicketStatusTime.ToDo) {
            this.status = 'Pending Resolution';
        }
    }
};

const formatHour = (hour: any) => {
    if (hour >= 0 && hour <= 9) {
        hour = '0' + hour;
    }
    else {
        return hour;
    }
    return hour;
};

const formatMinutes = (mins: any) => {
    if (mins >= 0 && mins <= 9) {
        mins = '0' + mins;
    }
    else {
        return mins;
    }
    return mins;
};

const formatSeconds = (sec: any) => {
    if (sec >= 0 && sec <= 9) {
        sec = '0' + sec;
    }
    else {
        return sec;
    }
    return sec;
};

const calculateTimeDiff = (time: number) => {
    const formattedHours = String(Math.floor(time / (1000 * 60 * 60))).padStart(2, '0');
    const formattedMinutes = String(
        Math.floor((time % (1000 * 60 * 60)) / (1000 * 60)),
    ).padStart(2, '0');
    const formattedSeconds = String(Math.floor((time % (1000 * 60)) / 1000)).padStart(
        2,
        '0',
    );
    return formattedHours + ':' + formattedMinutes + ':' + formattedSeconds;
};