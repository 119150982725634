import React, { ChangeEvent, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const SearchSchedule = ({ links }: any) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [dateValue, setDateValue] = useState<string>('');
  const [nameValue, setNameValue] = useState<string>('');
  const [type, setType] = useState<any>();

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    if (type === 'date') {
      setDateValue(e.target.value);
    } else if (type === 'name') {
      setNameValue(e.target.value);
    }
  }

  useEffect(() => handleClear(), [links]);

  useEffect(() => {
    if (dateValue === undefined || nameValue === undefined) return;
    const debounce = setTimeout(() => {
      type === 'date' ? searchParams.set('date', dateValue) : searchParams.set('name', nameValue);
      setSearchParams(searchParams);
    }, 1000);
    return () => clearTimeout(debounce);
  }, [dateValue, nameValue]);

  const handleClear = () => {
    setDateValue('');
    setNameValue('');
    setSearchParams('');
  };

  return (
    <>
      <div className='flex items-center'>
        <div
          className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 relative`}
        >
          <input
            onClick={() => setType('date')}
            onChange={handleChange}
            value={dateValue}
            type='date'
            id='simple-search'
            className='bg-transparent h-full outline-none'
          />
        </div>
      </div>
      <div className='flex items-center'>
        <label htmlFor='simple-search' className='sr-only'>
          Search
        </label>
        <div
          className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 relative`}
        >
          <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
            <svg
              aria-hidden='true'
              className='w-5 h-5 text-gray-500 dark:text-gray-400'
              fill='currentColor'
              viewBox='0 0 20 20'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                d='M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z'
                clipRule='evenodd'
              ></path>
            </svg>
          </div>
          <input
            onClick={() => setType('name')}
            onChange={handleChange}
            value={nameValue}
            type='text'
            id='simple-search'
            placeholder={`Search by name`}
            className='bg-transparent h-full outline-none'
          />
        </div>
      </div>
    </>
  );
  // return (
  //     <div className='flex items-center'>
  //         <div
  //             className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 relative`}
  //         >
  //             <input
  //                 onChange={handleChange}
  //                 type='date'
  //                 id='simple-search'
  //                 className='bg-transparent h-full outline-none'
  //             />
  //         </div>
  //     </div>
  // )
};

export default SearchSchedule;
