import { Outlet, useParams } from 'react-router';
import {
  useGetOrgCoachesQuery,
  useRemoveCoachFromOrgMutation,
} from '../../../../slices/organizationApiSlice';
import { OrganizationCoachModel } from '../../../../model/organization-coach.model';
import { AvatarImage } from '../../../../components/avatar-image';
import { IoRemoveCircleOutline } from 'react-icons/io5';
import { Link, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../slices/auth';
import Pagination from '../../../../components/pagination';
const OrganizationCoaches = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const user = useSelector(selectUser);
  const [removeCoachFromOrg, { isSuccess: removeSuccess, isError: removeError, reset }] =
    useRemoveCoachFromOrgMutation();
  const param = useParams();
  const { data, isLoading, isError } = useGetOrgCoachesQuery({
    id: param.id ?? user.organizations[0].id,
    page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
  });
  function removeCoach(coach_id: number) {
    removeCoachFromOrg({ coach_id, organization_id: Number(param.id ?? user.organizations[0].id) });
  }
  if (isLoading) return <p>Loading...</p>;
  if (isError) return <p>Something went wrong! Try again later!</p>;
  return (
    <div className='flex flex-col flex-1 justify-between'>
      <div className='flex flex-col gap-2'>
        <Outlet />
        <div className='flex'>
          <Link to='./add' className='button'>
            Add Coach
          </Link>
        </div>

        {removeError && <p className='text-red-700'>Something went wrong! Please try again!</p>}
        {removeSuccess && (
          <p className='text-green-700'>Coach was removed successfully from this organization!</p>
        )}
        <div className='flex flex-wrap gap-10'>
          {data.data.map((d: any) => {
            const model = new OrganizationCoachModel(d);
            return (
              <div
                key={model.id}
                className='relative flex flex-col p-10 rounded-lg shadow-lg items-center gap-10 w-[200px] overflow-hidden'
              >
                <IoRemoveCircleOutline
                  onClick={() => removeCoach(model.id)}
                  className='absolute top-1 right-1 hover:text-red-700 cursor-pointer'
                />

                <AvatarImage size='xxl' source={model.image} />
                <h2>{model.fullName}</h2>
              </div>
            );
          })}
        </div>
      </div>
      <Pagination length={data?.data?.length} {...data?.meta} />
    </div>
  );
};

export default OrganizationCoaches;
