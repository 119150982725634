import React, { useEffect, useState } from 'react';
import { GoDotFill } from 'react-icons/go';
import { AiFillEye } from 'react-icons/ai';
import { FaPlay } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
export function generateRandomColor() {
  const colors = [
    '#FFB6C1',
    '#8FDAC3',
    '#FF6F61',
    '#F5DF4D',
    '#0F4C81',
    '#939597',
    '#E2725B',
    '#B1B7A7',
    '#C9A0DC',
    '#8B3A3A',
  ];
  const cl = colors[Math.floor(Math.random() * colors.length)];
  let cl2 = colors[Math.floor(Math.random() * colors.length)];
  while (cl === cl2) cl2 = colors[Math.floor(Math.random() * colors.length)];
  // const print = cl + '=' + cl2;
  return {
    cl: cl,
    cl2: cl2,
  };

  // console.log('slice random cl+cl2 = ', print)
  // return cl + '=' + cl2;
  // console.log('random color = ', cl);
  // const r = Math.floor(Math.random() * 256);
  // const g = Math.floor(Math.random() * 256);
  // const b = Math.floor(Math.random() * 256);
  // return { r, g, b }
  // return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

const LiveMatchesCard = ({
  key,
  selected,
  id,
  user_name,
  type,
  viewer_count,
  source,
  game_name,
  tags,
  title,
  isSlider = false,
  setSelected,
  view_count,
  dragging,
}: any) => {
  const [hoverColor, setHoverColor] = useState('');

  function formatNumber(num: any) {
    if (num >= 1000) {
      return (num / 1000).toFixed(1) + 'K';
    } else {
      return num.toString();
    }
  }

  // useEffect(() => {
  //     console.log('slice random cl = ', generateRandomColor().slice(0, generateRandomColor().indexOf('=')))
  //     console.log('slice random cl2 = ', generateRandomColor().slice(generateRandomColor().indexOf('=') + 1))
  // })

  const random = generateRandomColor();
  const randomColorStyle = {
    backgroundColor: generateRandomColor().cl,
  };

  const randomOverlayStyle = {
    // background: `linear-gradient(to right bottom, ${generateRandomColor().cl} 50%, ${generateRandomColor().cl2} 50%)`,
    background: `linear-gradient(130deg, #444444 50%, #222222 50%)`,
    opacity: 0.7,
    ':hover': {
      background: `#ff0000`,
    },
  };

  useEffect(() => {
    console.log('random overlay style', randomOverlayStyle);
  }, []);
  const navigate = useNavigate();
  return (
    <div
      className={`flex flex-col relative cursor-pointer mx-2 ${isSlider ? 'w-[300px]' : 'w-[300px]'}`}
      onClick={(e) => {
        setSelected
          ? setSelected(type === 'live' ? [user_name, 'channel'] : [id, 'video'])
          : navigate(
            `/gamearena/live/${tags[0].toLowerCase().split('m')[1]}?id=${type === 'live' ? user_name : id
            }&type=${type}`,
          );
        dragging && e.preventDefault();
      }}
    >
      <div
        className={`stream relative ${selected?.length
          ? type === 'live'
            ? user_name === selected[0]
              ? 'border-2 border-red-600'
              : ''
            : id === selected[0]
              ? 'border-2 border-red-600'
              : ''
          : ''
          }`}
        style={randomColorStyle}
      >
        {type === 'live' && (
          <div
            className={`${isSlider ? '' : 'stream__thumbnail'} flex absolute top-2 left-2 z-[2]`}
          >
            <div className='flex items-center gap-1 bg-red-700 px-2 rounded text-white text-xs mr-2'>
              <GoDotFill color='white' /> LIVE
            </div>
            <div className='flex items-center gap-1 bg-gray-700 rounded text-white px-2 text-xs'>
              <AiFillEye color='white' />
              {formatNumber(viewer_count)}
            </div>
          </div>
        )}
        {type === 'archive' && (
          <div
            className={`${isSlider ? '' : 'stream__thumbnail'} flex absolute top-2 left-2 z-[2] `}
          >
            <div className='flex items-center gap-1 bg-gray-700 rounded text-white px-2 max-[calc(100vh-115px)]:text-[7px] text-14'>
              <AiFillEye color='white' />
              {formatNumber(view_count)}
            </div>
          </div>
        )}
        <div
          className={`${isSlider ? '' : 'stream__thumbnail'
            } absolute top-0 left-0 w-full h-full z-[1]`}
          style={randomOverlayStyle}
        ></div>
        <div
          className={`${isSlider ? '' : 'stream__thumbnail'
            } absolute inset-0 flex items-center justify-center z-[2]`}
        >
          <FaPlay color='white' size={'20px'} />
        </div>
        <img
          src={source
            .replace('%{width}', '300')
            .replace('{width}', '300')
            .replace('%{height}', '150')
            .replace('{height}', '150')}
          className={`${isSlider ? '' : 'stream__thumbnail'}`}
        />
      </div>
      <h1 className='font-bold text-black'>{user_name}</h1>
      <p>{game_name}</p>
      {isSlider ? null : (
        <div className='flex'>
          {tags && tags.length
            ? tags.slice(0, 2).map((t: any, i: any) => (
              <div
                key={i}
                className='text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 bg-blue-300 text-blue-700 rounded-full'
              >
                {t.length > 10 ? t.slice(0, 10) + '...' : t}
              </div>
            ))
            : null}
        </div>
      )}
    </div>
  );
};

export default LiveMatchesCard;
