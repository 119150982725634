import { Formik } from 'formik';
import * as yup from 'yup';
import { Navigate, useParams } from 'react-router-dom';
import { OutsideClick } from '../../../../components/outside-click';
import {
  useGetSingleMatchInfoQuery,
  useUpdateSingleMatchInfoMutation,
} from '../../../../slices/matchApiSlice';
import ModalContent from '../../../../components/modal-content';
import { CustomError } from '../../../../utils/custom-types';

const EditSingleBracket = () => {
  const params = useParams();
  const { error, data, isLoading, isError, isSuccess } = useGetSingleMatchInfoQuery(
    params.bracketid,
  );

  const [
    updateSingleMatchInfo,
    { data: upData, isLoading: upIsLoading, isError: upIsError, isSuccess: upIsSuccess },
  ] = useUpdateSingleMatchInfoMutation();
  const formInputs = [
    {
      mainTitle: '',
      name: 'match_date',
      label: 'Match Date',
      type: 'datetime-local',
      placeholder: 'Set match date',
      isTrue: true,
    },
  ];

  const handleSubmit = (values: any) => {
    if (!params.bracketid) return;
    updateSingleMatchInfo({
      id: params.bracketid,
      body: {
        match_date: new Date(values.match_date).toISOString(),
        status: +values.status,
      },
    });
  };

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const editSingleBracketVShema = yup.object().shape({
    match_date: yup
      .date()
      .min(today, 'Date and time should not be in the past')
      .transform((value, originalValue) => {
        if (originalValue) {
          return new Date(originalValue);
        }
        return value;
      })
      .required('Required!'),
  });
  if (isError)
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError).data.message }} />;
  if (isLoading) return <p>Loading...</p>;
  if (upIsSuccess) return <Navigate to='../' />;

  return (
    <>
      <OutsideClick />
      <Formik
        validationSchema={editSingleBracketVShema}
        initialValues={{
          match_date: data ? data.match_date.split('T')[0] : null,
          status: data ? data.status : null,
        }}
        onSubmit={handleSubmit}
      >
        <ModalContent
          formInputs={formInputs}
          isLoading={upIsLoading}
          name='Edit Match Details'
          extraClass='h-[60vh]'
        />
      </Formik>
    </>
  );
};

export default EditSingleBracket;
