import { Dispatch, MiddlewareAPI } from '@reduxjs/toolkit';
import { Socket, io } from 'socket.io-client';
import { SocketActions, SocketMessages } from '../utils/constants';
import { updateMatchData, updateSeasonWeekSocket } from '../slices/matchSlice';
import { apiSlice } from '../api/apiSlice';

const SeasonWeekSocketMiddleware = (api: MiddlewareAPI) => {
  let socket: Socket | undefined;
  const url = import.meta.env.VITE_SOCKET_URL + '/season-week';
  type ActionType<T> = { payload: T; type: string };
  return (next: Dispatch) => (action: any) => {
    switch (action.type) {
      case SocketActions.ConnectSeasonWeek:
        api.dispatch(updateSeasonWeekSocket('loading'));
        if (socket) {
          api.dispatch(updateSeasonWeekSocket('connected'));
          break;
        }
        socket = io(url ?? '', { extraHeaders: { token: '' } });
        let { payload } = action as ActionType<{
          id: string;
        }>;
        console.log('start to connect to season week socket');
        socket.on(SocketMessages.Connect, () => {
          console.log('connected to season week socket');
    	  api.dispatch(updateSeasonWeekSocket('connected'));
          socket?.emit('join-room', `week-${payload?.id}`);
        });

        socket.on(SocketMessages.Matches, (msg: any) => {
          api.dispatch(apiSlice.util.invalidateTags(['SingleSeasonInfo']));
          api.dispatch(updateMatchData(msg));
        });

        socket.on(SocketMessages.Teams, () => {
          api.dispatch(apiSlice.util.invalidateTags(['SingleSeasonInfo']));
        });

        break;
      case SocketActions.DisconnectSeasonWeek:
        if (!socket) break;
        socket.close();
        socket.off();
        socket = undefined;
        api.dispatch(updateSeasonWeekSocket('disconnected'));
        break;
      default:
        return next(action);
    }
  };
};

export default SeasonWeekSocketMiddleware;
