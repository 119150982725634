import { ChangeEvent } from 'react';
import {
  useChangeGradeLevelMutation,
  useStudentProfileViewPermissionQuery,
} from '../../slices/userApiSlice';
import { useParams } from 'react-router';
import { gradeLevels } from '../../utils/utils';
import Combobox from '../combobox/combobox';
export function SwitchGrade({}) {
  const props = {
    mainTitle: 'Coach Information',
    name: 'grade',
    label: 'Choose Grade Level',
    type: 'select',
    // placeholder: 'Edit about text',
    isSelect: true,
    options: gradeLevels,
    onChange: (e: ChangeEvent<HTMLInputElement>) => console.log(e.target.value),
    // rows: '4',
  };
  const [changeGrade, { isLoading }] = useChangeGradeLevelMutation();
  const param = useParams();
  // const {
  //   data: userData,
  //   isLoading: userIsLoading,
  //   isError: userIsError,
  //   isSuccess: userIsSuccess,
  // } = useGetUserByIdQuery(param.id);

  const {
    isLoading: userIsLoading,
    data: userData,
    isError: userIsError,
    isSuccess: userIsSuccess,
    error,
  } = useStudentProfileViewPermissionQuery(param?.id, { skip: !param?.id });

  if (userIsLoading) return <p>Loading...</p>;
  return (
    <Combobox
      noFormik
      options={props.options}
      value={userData?.gradeLevel}
      setFieldValue={(e) => changeGrade({ id: param.id ?? '', gradeLevel: e.toString() })}
      name={props.name}
      label={props.label}
    />
  );
}
