import React from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { CustomError } from '../../utils/custom-types';
import { useGenerateSchoolInviteLinkMutation } from '../../slices/schoolsApiSlice';
import ModalNewSchoolInviteLink from '../../components/modal-new-school-invite-link';

function NewSchoolInviteLink() {
  const param = useParams();
  const navigate = useNavigate();
  const [
    generateSchoolInviteLink,
    {
      data: schoolInviteData,
      isLoading: schoolInviteIsLoading,
      isSuccess: schoolInviteIsSuccess,
      isError: schoolInviteIsError,
      error,
    },
  ] = useGenerateSchoolInviteLinkMutation();
  console.log('param', param);

  const onSubmit = async () => {
    await generateSchoolInviteLink({
      schoolId: param.id ? +param.id : 0,
      invalidateOldLinks: true,
    });
    if (schoolInviteData) navigate('../', { state: schoolInviteData });
  };
  if (schoolInviteIsLoading) return <p>Loading...</p>;
  if (schoolInviteIsError || (schoolInviteIsSuccess && !schoolInviteData))
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError)?.data?.message }} />;
  if (schoolInviteIsSuccess) return <Navigate to='../' state={schoolInviteData} />;
  return <ModalNewSchoolInviteLink onSubmit={onSubmit} isLoading={schoolInviteIsLoading} />;
}

export default NewSchoolInviteLink;
