import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Form, Formik } from 'formik';
import { guardianSchema } from '../yupschema';
// import CustomCheckbox from "./CustomCheckbox";
// import CustomSelect from "./CustomSelect";
import { Link, useNavigate } from 'react-router-dom';

import { useStateContext } from '../../../contexts/ContextProvider';

import { FormInput } from '../../../components/form-input/form-input';
import { allStates, gendersOptions } from '../../../utils/constants';

const GuardianRegisterForm = () => {
  const { isLoggedIn, setIsLoggedIn } = useStateContext();
  const { modalTerms, setModalTerms } = useStateContext();
  const { modalTermsCheckbox, setModalTermsCheckbox } = useStateContext();

  function onChangeCaptcha() {
    console.log('Captcha value:');
  }

  const navigate = useNavigate();

  const onSubmit = () => {
    console.log('submitted');
  };

  // const onSubmit = async (values: any, actions: any) => {

  //     try {
  //         const userCredential = await createUserWithEmailAndPassword(auth, values.email, values.password!);
  //         const user = userCredential.user;

  //         updateProfile(auth.currentUser!, {
  //             displayName: values.firstName,
  //         });

  //         const formDataCopy = {
  //             ...values,
  //             role: 'guardian'
  //         };

  //         delete formDataCopy['password'];
  //         delete formDataCopy['confirmPassword'];
  //         formDataCopy.timestamp = serverTimestamp();

  //         await setDoc(doc(db, 'users', user.uid), formDataCopy);

  //         await sendEmailVerification(auth.currentUser!)

  //         setIsLoggedIn(true);
  //         navigate('/');

  //     } catch (error) {
  //         console.log(error);
  //     }
  //     // await new Promise((resolve) => setTimeout(resolve, 1000));
  //     // actions.resetForm();
  // };

  const formInputs = [
    {
      mainTitle: 'Guardian Information',
      name: 'firstName',
      label: 'First Name',
      type: 'text',
      placeholder: 'Enter first name',
    },
    {
      mainTitle: 'Guardian Information',
      name: 'lastName',
      label: 'Last Name',
      type: 'text',
      placeholder: 'Enter last name',
    },
    {
      mainTitle: 'Guardian Information',
      name: 'streetAddress',
      label: 'Street Address',
      type: 'text',
      placeholder: 'Enter street address',
    },
    {
      mainTitle: 'Guardian Information',
      name: 'city',
      label: 'City',
      type: 'text',
      placeholder: 'Enter city',
    },
    {
      mainTitle: 'Guardian Information',
      name: 'state',
      label: 'State',
      type: 'text',
      placeholder: 'Enter state',
      isSelect: true,
      options: allStates ?? [],
    },
    {
      mainTitle: 'Guardian Information',
      name: 'postal',
      label: 'Zip Code',
      type: 'number',
      placeholder: 'Enter postal code',
    },
    {
      mainTitle: 'Guardian Information',
      name: 'email',
      label: 'Email Address',
      type: 'text',
      placeholder: 'Enter email address',
    },
    {
      mainTitle: 'Guardian Information',
      name: 'phoneNumber',
      label: 'Phone Number',
      type: 'text',
      placeholder: 'Enter phone number',
    },
    {
      mainTitle: 'Guardian Information',
      name: 'dateOfBirth',
      label: 'Date of birth',
      type: 'date',
      placeholder: 'Enter date of birth',
    },
    {
      mainTitle: 'Guardian Information',
      name: 'gender',
      label: 'Gender',
      type: 'text',
      placeholder: 'Select gender',
      isSelect: true,
      options: gendersOptions,
    },
    {
      mainTitle: 'Student Information',
      name: 'studentEmail',
      label: 'Email address',
      type: 'text',
      placeholder: 'Enter email address',
    },
    {
      mainTitle: 'Security Information',
      name: 'password',
      label: 'Password',
      type: 'password',
      placeholder: 'Enter password',
    },
    {
      mainTitle: 'Security Information',
      name: 'confirmPassword',
      label: 'Confirm Password',
      type: 'password',
      placeholder: 'Re-enter password',
    },
  ];
  const initialValues: { [key: string]: string } = {};
  formInputs.forEach((input) => (initialValues[input.name] = ''));

  return (
    <>
      <Formik initialValues={initialValues} validationSchema={guardianSchema} onSubmit={onSubmit}>
        {({ isSubmitting, errors }) => (
          <Form className='max-w-3xl m-4 p-10 bg-white rounded shadow-xl'>
            <h1 className='flex items-center justify-center mt-1 text-2xl pt-1 mb-2'>
              Create a new Guardian account
            </h1>
            {formInputs.map((input: any, i: number) => (
              <React.Fragment key={i}>
                {!formInputs[i - 1] || formInputs[i - 1]?.mainTitle !== input.mainTitle ? (
                  <>
                    <div className='flex flex-col'>
                      <div className='mb-2'>
                        <p className='text-gray-800 font-bold pt-6'>{input.mainTitle}</p>
                        <hr />
                      </div>
                      <div className='flex flex-wrap gap-5'>
                        {formInputs
                          .filter((inp1: any) => inp1.mainTitle === input.mainTitle)
                          .map((inp: any, i: number) => (
                            <div key={i} className='flex-1 min-w-[300px]'>
                              <FormInput {...inp} />
                            </div>
                          ))}
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </React.Fragment>
            ))}

            {/*reCaptcha */}
            <div className=' flex items-center justify-center mt-8'>
              <ReCAPTCHA
                sitekey='6LcPChQjAAAAADHSMNqPY8PasyUqNowhUPFYV9yB'
                onChange={onChangeCaptcha}
              />
            </div>

            <p className=' flex items-center justify-center mt-7'>
              <input
                className='mr-2'
                type='checkbox'
                onClick={() => {
                  setModalTermsCheckbox(!modalTermsCheckbox);
                }}
                checked={modalTermsCheckbox}
              />
              <span
                onClick={() => {
                  setModalTerms(true);
                }}
                className='font-italic'
              >
                I have read and agree to the{' '}
                <span className='text-black underline font-bold'> Terms and condition</span>
              </span>
            </p>
            <Link to='./guardian-verification'>
              <button
                className='w-1/2 my-5  py-2 bg-red-700 shadow-lg  text-white font-semibold rounded-lg'
                disabled={isSubmitting}
                type='submit'
              >
                Submit
              </button>
            </Link>

            {/* {isError && <p className='text-red-300'>{(error as CustomError)?.data?.message}</p>} */}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default GuardianRegisterForm;
