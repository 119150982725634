import * as yup from 'yup';
import { OutsideClick } from '../../components/outside-click';
import { Formik, FormikValues } from 'formik';
import ModalContent from '../../components/modal-content';
import { useCreateSupportTicketMutation } from '../../slices/supportTicketApiSlice';
import { useEffect, useState } from 'react';
import { useGetAllSupportCategoryQuery } from '../../slices/supportCategoryApiSlice';
import { useGetAllSubcategoriesByCategoryIdQuery } from '../../slices/supportSubcategoryApiSlice';
import { Navigate } from 'react-router';
import { CreateNewTicketValidationSchema } from '../../features/authentication/yupschema';

const NewTicketAdmin = () => {
  const [createTicket, { isLoading, isError, isSuccess }] = useCreateSupportTicketMutation();
  const { data: categories, isLoading: catIsLoading } = useGetAllSupportCategoryQuery({
    optional: 1,
  });
  const [supportCgId, setSupportCgId] = useState<string | any>(null);
  const { data: subCategoriesOfCategory, isSuccess: subIsSuccess } =
    useGetAllSubcategoriesByCategoryIdQuery(+supportCgId, { skip: !+supportCgId });
  const [avatarImage, setAvatarImage] = useState<File | any>();

  const formInputs = [
    {
      name: 'subject',
      label: 'Subject',
      placeholder: 'Subject name',
      type: 'text',
      isTrue: true,
    },

    {
      name: 'supportCategoryId',
      label: 'Category',
      isSelect: true,
      options: categories ?? [],
    },

    {
      name: 'supportSubcategoryId',
      label: 'Subcategory',
      placeholder: 'Subcategory',
      isSelect: true,
      options: supportCgId && subIsSuccess ? subCategoriesOfCategory : [],
    },

    {
      name: 'description',
      label: 'Describe the issues thoroughly',
      type: 'textarea',
      placeholder: 'Description',
      isTrue: false,
    },

    {
      name: 'link',
      label: 'Link',
      type: 'text',
      placeholder: 'Paste website link of issue',
      isTrue: true,
    },

    {
      name: 'file',
      label: 'Upload file',
      type: 'image',
      placeholder: 'Select a file',
      isTrue: true,
      accept:
        'image/jpeg, image/png, image/jpg, video/mp4, video/x-m4v, video/wmv, video/webm, application/pdf',
    },
  ];

  const initialValues: { [key: string]: string } = {};
  formInputs.forEach((input) => (initialValues[input.name] = ''));

  const onSubmit = (values: FormikValues) => {
    const data = { ...values };
    data.file = avatarImage;
    const formData = new FormData();
    formData.append('subject', data.subject);
    formData.append('description', data.description);
    formData.append('email', data.email);
    formData.append('supportCategoryId', data.supportCategoryId);
    formData.append('supportSubcategoryId', data.supportSubcategoryId);
    formData.append('link', data.link);
    formData.append('file', avatarImage);
    createTicket(formData);
  };

  if (isSuccess) {
    return <Navigate to='../' />;
  }
  if (catIsLoading) return <p>Loading...</p>;
  return (
    <>
      <OutsideClick />
      <Formik
        validationSchema={CreateNewTicketValidationSchema.shape({
          file: yup
            .mixed()
            .nullable()
            .test(
              'test2',
              'Invalid image format. Only JPG, PNG, and JPEG formats are allowed.',
              () => {
                const acceptedFormats = ['image/jpeg', 'image/png', 'image/jpg'];
                if (avatarImage && !acceptedFormats.includes(avatarImage?.type ?? '')) {
                  return false;
                }
                return true;
              },
            ),
        })}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({ values }) => {
          useEffect(() => {
            if (!values) return;
            setSupportCgId(values.supportCategoryId);
          }, [values]);
          return (
            <ModalContent
              setAvatarImage={setAvatarImage}
              extraClass={'h-fit'}
              formInputs={formInputs}
              isLoading={isLoading}
              name='New Support Ticket'
            />
          );
        }}
      </Formik>
    </>
  );
};

export default NewTicketAdmin;
