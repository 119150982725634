import React from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../slices/auth';
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';
import {
  usePayStudentInvoiceMutation,
  useTestPaymentMutation,
} from '../../../slices/paymentApiSlice';
import { Navigate, useParams } from 'react-router-dom';
import { useGetInvoiceByIdQuery } from '../../../slices/invoicesApiSlice';
import { InvoiceStatus } from '../../../utils/constants';
import { CustomError } from '../../../utils/custom-types';

const StudentSubsPayments = ({ isGuardian }: { isGuardian?: Boolean }) => {
  const param = useParams();
  const [payStudentInvoice, { isSuccess: payIsSuccess, isError: payIsError }] =
    usePayStudentInvoiceMutation();
  const user = useSelector(selectUser);
  const {
    error,
    data: inData,
    isLoading: inIsLoading,
    isError: inIsError,
  } = useGetInvoiceByIdQuery(param.id);
  const [testPayment, { isLoading, isSuccess }] = useTestPaymentMutation();
  const handlePayments = (token: any) => {
    payStudentInvoice({
      invoice_id: param.id ? +param.id : 0,
      token,
      total: inData?.total,
      user_id: user.id,
    });
  };
  const appId = import.meta.env.VITE_SANDBOX_APP_ID ?? '';
  const locationId = import.meta.env.VITE_LOCATION_ID ?? '';
  if (payIsSuccess)
    return <Navigate to={`/subscriptions/${isGuardian ? 'guardian' : 'student'}`} />;
  if (inIsLoading) return <p>Loading...</p>;
  if (inIsError || (Object.keys(inData).length && inData.status === InvoiceStatus.Paid))
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError).data.message }} />;
  return (
    <div className='listViewContainer h-[calc(100vh-85px)] overflow-auto flex flex-col items-center justify-start'>
      <div className='w-full flex flex-col'>
        <h2 className='text-5xl font-bold'>Hello {user.firstName}, </h2>
        <p className='text-lg'>Payment for your invoice is overdue</p>
        <p className='text-lg'>
          If you do not make the payment, than you will not be able to participate in the
          competitions
        </p>
      </div>

      <div className='w-full max-w-[500px] '>
        <PaymentForm
          applicationId={appId}
          cardTokenizeResponseReceived={(token, verifiedBuyer) => {
            handlePayments(token);
          }}
          locationId={locationId}
        >
          <CreditCard />
        </PaymentForm>
      </div>

      <div className='w-fit self-start p-4 border-1 border-slate-700 rounded-lg'>
        {payIsError && <p className='text-red-700'>Something went wrong!</p>}
        Total: {inData.total}$
      </div>
    </div>
  );
};

export default StudentSubsPayments;
