import { useGetAllClassroomsByOrganizationIdQuery } from '../../slices/classroomApiSlice';
import { Link, Outlet, useParams, useSearchParams } from 'react-router-dom';
import { ClassroomModel } from '../../model/classroom.model';
import ClassroomCard from './components/classroom-card';
import { ClassRoomCardLoadingSceleton } from './components/classroom-card-loading-sceleton';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import Pagination from '../../components/pagination';
import { SponsorRow } from '../../components/sponsor-row/sponsor-row';
import { SponsorPlacements } from '../../utils/constants';
import { NoContentMessages } from '../../utils/messages.enum';

const AllClassrooms = () => {
  const show = false;
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, isLoading, isError } = useGetAllClassroomsByOrganizationIdQuery({
    page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
  });

  if (isLoading)
    return (
      <div className='flex listViewContainer fullPage border-1 bg-white !flex-row flex-wrap rounded-xl'>
        {Object.keys(Array.from(new Array(8))).map((t: string) => (
          <ClassRoomCardLoadingSceleton key={t} />
        ))}
      </div>
    );
  // TODO: REMOVE THIS RETURN TO SHOW CLASSROOMS
  if (!show)
    return (
      <div className='flex gap-10 flex-col justify-center items-center'>
        <p>
          Please{' '}
          <a
            href='https://fenworks.com/esports-portal-login/'
            className='text-red-700 hover:text-red-900'
            target='_blank'
            rel='noopener noreferrer'
          >
            click here
          </a>{' '}
          for the esports portal or{' '}
          <a
            href='https://fenworks.com/drone-racing-portal-login/'
            className='text-red-700 hover:text-red-900'
            target='_blank'
            rel='noopener noreferrer'
          >
            here
          </a>{' '}
          for the drone racing portal.
        </p>
      </div>
    );
  return (
    <>
      <Outlet />
      {/* <SponsorRow name={SponsorPlacements.Classrooms} /> */}
      <div className='flex listViewContainer fullPage border-1 bg-white rounded-xl'>
        {/* <h1 className='text-2xl font-semibold tracking-tight'> My Classrooms</h1> */}
        <SponsorRow name={SponsorPlacements.Classrooms} />

        <div className='flex h-full flex-col justify-between'>
          {!data?.data?.length && (
            <div className='flex-1 flex justify-center'>{NoContentMessages.Classrooms}</div>
          )}
          <div className='flex  flex-wrap justify-center gap-4 mt-4'>
            {data?.data &&
              data?.data?.map((c: any, i: number) => (
                <Link to={`./view/${c.id}`} key={i}>
                  <div>
                    <ClassroomCard classroom={new ClassroomModel(c)} />
                  </div>
                </Link>
              ))}
            <Pagination length={data?.data?.length} {...data?.meta} />
          </div>
          {/* <Pagination length={data?.data?.length} {...data?.meta} /> */}
        </div>
      </div>
    </>
  );
};

export default AllClassrooms;
