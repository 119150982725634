import { HTMLProps } from 'react';
import { firstLetterToUpperCase } from '../utils/utils';
import { Avatar, AvatarFallback, AvatarImage } from './ui/avatar/Avatar';
interface NameAndAvatarProps extends HTMLProps<HTMLDivElement> {
  image: string;
  name: string;
  isAlt?: boolean;
  schoolName?: string;
  lastName?: string;
  extraClass?: string;
  hasTime?: boolean;
  createdAt?: string;
}
export const NameAndAvatar: React.FC<NameAndAvatarProps> = ({
  image,
  name,
  lastName,
  isAlt = true,
  schoolName,
  extraClass,
  hasTime,
  createdAt,
  ...other
}) => {
  return (
    <div className={`flex  gap-4 items-center truncate ${extraClass ?? ''}`} {...other}>
      <Avatar className='h-16 w-16'>
        <AvatarImage className='object-cover' src={image} />
        <AvatarFallback>
          {firstLetterToUpperCase(name) + firstLetterToUpperCase(lastName || '')}
        </AvatarFallback>
      </Avatar>
      {/* <div className=''> */}
      <div className='flex flex-col flex-1 truncate'>
        <p className='text-sm font-medium text-gray-900 w-full truncate'>{name}</p>
        {hasTime ? <span className='text-sm'>{createdAt}</span> : null}
      </div>
      {/* <div className='text-sm font-medium text-gray-900 w-full truncate'>{name}</div> */}
      {schoolName && <div className='  text-sm font-light max-w-[15ch] truncate'>{schoolName}</div>}
    </div>
    // </div>
  );
};
