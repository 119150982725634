import { dateAndTimeFormatter } from '../../../utils/dateFormatter';

export class StudentCoachHoursRequestModel {
  id;
  school: string = '';
  hours: number = 0;
  school_hours: number = 0;
  username: string = '';
  classroom: string = '';
  start_date: string = '';
  raw_start_date?: string;
  raw_end_date?: string;
  end_date: string = '';
  constructor(d: any) {
    if (d.hasOwnProperty('id')) this.id = d.id;
    if (d.hasOwnProperty('user')) {
      if (d.user.hasOwnProperty('username')) this.username = d.user.username;
      if (d.user.hasOwnProperty('school')) {
        if (d.user.school.hasOwnProperty('name')) this.school = d.user.school.name;
        if (d.user.school.hasOwnProperty('coach_hours'))
          this.school_hours = d.user.school.coach_hours;
      }
    }
    if (d.hasOwnProperty('hours')) this.hours = d.hours;
    if (d.hasOwnProperty('classroom')) {
      if (d.classroom.hasOwnProperty('name')) {
        this.classroom = d.classroom.name;
      }
    }
    if (d.hasOwnProperty('start_date')) {
      this.start_date = dateAndTimeFormatter(d.start_date).replace(";","");
      this.raw_start_date = d.start_date;
    }
    if (d.hasOwnProperty('end_date')) {
      this.end_date = dateAndTimeFormatter(d.end_date).replace(";","");
      this.raw_end_date = d.end_date;
    }
  }
}
