import { UserModel } from '../../../../../../features/listview/users/model/user.model';
import { NameAndAvatar } from '../../../../../../components/name-and-avatar';
import { useAddUserToTeamMutation } from '../../../../../../slices/userApiSlice';
import { useParams } from 'react-router';
import useErrorToast from '../../../../../../hooks/use-error-toast';
import { TeamsOfSingleStudent } from './teams-of-single-student';

const TeamMemberStudentRow = ({ user, disabled }: { user: UserModel; disabled: boolean }) => {
  const param = useParams();
  const [addUser, { isLoading, isError, error, isSuccess }] = useAddUserToTeamMutation();

  function handleAdd() {
    if (isLoading || disabled || isSuccess) return;
    addUser({ id: param.id ?? '', studentid: Number(user.id) });
  }

  useErrorToast(isError, error);
  return (
    <div className='p-2 flex min-w-fit justify-between items-center gap-2'>
      <div className='flex justify-between basis-[35%] items-center flex-col lg:flex-row'>
        <NameAndAvatar
          name={user.fullName ?? ''}
          image={user.image ?? ''}
          extraClass='text-center truncate ellipsis'
        />
        <TeamsOfSingleStudent teams={user?.teams} />
      </div>
      <div className='basis-[25%]  min-w-[1px]  text-center'>{user.school_name}</div>
      <div className='basis-[25%]  min-w-[1px]  text-center'>{user.organization}</div>
      <div onClick={handleAdd} className='btn red basis-[15%] min-w-fit '>
        {isLoading ? 'Adding...' : 'Add'}
      </div>
    </div>
  );
};



export default TeamMemberStudentRow;
