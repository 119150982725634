import { useEffect, useState } from 'react';
import { AiOutlineArrowDown } from 'react-icons/ai';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { HeaderButton } from '../../../components/header-button';
import { useViewSingleSeasonStageQuery } from '../../../slices/seasonApiSlice';
import { SeasonMatches } from './season-matches';
import CanIView from '../../../components/can-i-view/can-i-view';
import { useGetAllMatchesByWeekIdQuery } from '../../../slices/matchApiSlice';
import {
  SeasonProgressStatus,
  SeasonWeekStatus,
  SocketActions,
  StageTypes,
  UserRoles,
} from '../../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../../slices/auth';
import { SeasonWeekModel } from '../../../model/season-week.model';
import { useStateContext } from '../../../contexts/ContextProvider';
import { toast } from 'react-toastify';
import { Button } from '../../../components';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../../../components/ui/tooltip/Tooltip';

interface ViewMatchScheduleProps {
  seasonStageId: number | undefined;
}

export function ViewMatchSchedule({ seasonStageId }: ViewMatchScheduleProps) {
  const { selectedWeek, setSelectedWeek } = useStateContext();
  const [isWithinAnHour, setIsWithinAnHour] = useState(false);
  const param = useParams();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const { data: seasonStageData, isLoading } = useViewSingleSeasonStageQuery(seasonStageId, {
    skip: !seasonStageId,
  });
  useEffect(() => {
    if (
      selectedWeek &&
      seasonStageData?.SeasonWeek.find((seasonWeek: any) => seasonWeek.id === selectedWeek)
    )
      return;
    if (
      searchParams.get('week') &&
      seasonStageData?.SeasonWeek.find(
        (seasonWeek: any) => Number(seasonWeek.id) === Number(searchParams.get('week')),
      )
    ) {
      setSelectedWeek(Number(searchParams.get('week')));
      return;
    }
    const today = new Date();
    setSelectedWeek(
      seasonStageData?.SeasonWeek.find((week: any) => {
        const initialWeek = new Date(week.start_time);
        initialWeek.setDate(initialWeek.getDate() + 7);
        return today < initialWeek;
      })?.id ?? seasonStageData?.SeasonWeek?.[0]?.id,
    );
  }, [seasonStageData, seasonStageId]);

  useEffect(() => {
    const checkTime = () => {
      const selectedWeekData = seasonStageData?.SeasonWeek?.find(
        (week: any) => week.id === selectedWeek,
      );
      if (selectedWeekData) {
        const startTime = new Date(selectedWeekData.start_time).getTime();
        const currentTime = new Date().getTime();
        const oneHourInMillis = 1000 * 60 * 60;
        setIsWithinAnHour(currentTime >= startTime - oneHourInMillis && currentTime < startTime);
      }
    };

    checkTime();

    const now = new Date();
    const delay = 60000 - (now.getSeconds() * 1000 + now.getMilliseconds());

    const timeoutId = setTimeout(() => {
      checkTime();
      const intervalId = setInterval(checkTime, 60000);
      return () => clearInterval(intervalId);
    }, delay);

    return () => clearTimeout(timeoutId);
  }, [seasonStageData, selectedWeek]);

  useEffect(() => {
    dispatch({
      type: SocketActions.ConnectSeasonWeek,
      payload: {
        id: selectedWeek,
      },
    });
    return () => {
      dispatch({ type: SocketActions.DisconnectSeasonWeek, payload: { id: user?.id } });
    };
  }, [selectedWeek, seasonStageId]);

  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (!selectedWeek) return;
    if (searchParams.get('week') === selectedWeek.toString()) return;
    searchParams.set('week', selectedWeek.toString());
    setSearchParams(searchParams);
  }, [selectedWeek, searchParams, setSearchParams]);
  useEffect(() => {
    return () => {
      setSelectedWeek(null);
    };
  }, []);

  const { data, isLoading: matchLoading } = useGetAllMatchesByWeekIdQuery(selectedWeek, {
    skip: !selectedWeek || !seasonStageId,
  });
  if (isLoading || matchLoading) return <p>Loading...</p>;
  const seasonWeek = new SeasonWeekModel(
    seasonStageData?.SeasonWeek?.find((week: any) => week.id === selectedWeek),
  );
  return (
    <>
      <div className='flex flex-col p-2 gap-5'>
        <div className='flex justify-start w-full items-center gap-5'>
          <div className='filterBtn group select-none cursor-pointer'>
            {seasonStageData?.SeasonWeek.find((week: any) => week.id === selectedWeek)?.name}
            <div className='flex items-center  gap-3 select-none cursor-pointer'>
              <AiOutlineArrowDown />{' '}
              <CanIView component='season.generateWeek'>
                {seasonStageData?.status !== SeasonProgressStatus.Completed ? (
                  <>Generate Week</>
                ) : (
                  <></>
                )}
              </CanIView>
            </div>
            <div
              className={`flex-col p-2 gap-2 absolute top-[100%] z-[1] left-0 right-0 m-auto ${
                seasonStageData?.SeasonWeek?.length || user.roles === UserRoles.Admin
                  ? ' bg-white rounded-lg border-1'
                  : ''
              } whitespace-nowrap hidden group-hover:flex max-h-[200px] overflow-auto`}
            >
              {seasonStageData?.status !== SeasonProgressStatus.Completed ? (
                <CanIView component='season.view.generateWeek'>
                  <Link
                    to={`./new-week/${seasonStageId}`}
                    onClick={(event) => {
                      if (!seasonStageId) {
                        event.preventDefault();
                        toast.error('Please select a stage to add this week to.', {
                          position: 'top-right',
                        });
                      }
                    }}
                    className='hover:bg-gray-200 rounded-lg cursor-pointer p-2'
                  >
                    Create New Week(s)
                  </Link>
                </CanIView>
              ) : (
                <></>
              )}
              {seasonStageData?.SeasonWeek?.slice()
                .sort((a: any, b: any) => {
                  return new Date(a.start_time).getTime() - new Date(b.start_time).getTime();
                })
                .map((s: any) => {
                  const sW: SeasonWeekModel = new SeasonWeekModel(s);
                  return (
                    <p
                      key={sW.id}
                      className={`hover:bg-gray-200 rounded-lg cursor-pointer p-2 ${
                        selectedWeek && selectedWeek === s.id ? 'bg-gray-200' : ''
                      }`}
                      onClick={() => setSelectedWeek(sW.id!)}
                    >
                      {sW.name}
                    </p>
                  );
                })}
            </div>
          </div>
          {seasonStageData?.stage_type_id === StageTypes.SkillSync.id &&
          (isWithinAnHour || user.roles === UserRoles.Admin) ? (
            <Link
              to={`./join-week`}
              onClick={(event) => {
                if (!seasonStageId) {
                  event.preventDefault();
                  toast.error('Please select a stage.', {
                    position: 'top-right',
                  });
                }
              }}
              className='hover:bg-gray-200 rounded-lg cursor-pointer p-2 bg-green-500 text-white'
            >
              Join Week
            </Link>
          ) : seasonStageData?.stage_type_id === StageTypes.SkillSync.id ? (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div className='bg-gray-200 rounded-lg p-2 cursor-not-allowed text-white'>
                    Join Week
                  </div>
                </TooltipTrigger>
                <TooltipContent className='border-2 border-black'>
                  <p>
                    Registration opens one hour before the weeks start time and closes at the start
                    time.
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ) : (
            <></>
          )}
          {user.roles === UserRoles.Student && <Button></Button>}
          <div className='flex gap-5 items-center'>
            {seasonStageData?.status !== SeasonProgressStatus.Completed && (
              <CanIView component='season.view.deleteSeasonWeek'>
                <div className='flex gap-2 items-center'>
                  <Link to={`./delete-week/${seasonWeek ? seasonWeek.id : 0}`}>
                    <HeaderButton type='deleteSeasonWeek' />{' '}
                  </Link>
                </div>
              </CanIView>
            )}

            {(!data?.data?.length ||
              (seasonWeek.status === SeasonWeekStatus.NotStarted &&
                seasonStageData?.SeasonType?.leaderboard)) &&
            seasonStageData?.status !== SeasonProgressStatus.Completed &&
            selectedWeek ? (
              <div className='flex gap-5 items-center'>
                <CanIView component='season.view.editSeasonWeek'>
                  <div className='flex gap-2 items-center'>
                    <Link to={`./edit-week/${seasonWeek ? seasonWeek.id : 0}`}>
                      <HeaderButton type='editSeasonWeek' />{' '}
                    </Link>
                  </div>
                </CanIView>
                {!data?.data?.length ? (
                  <CanIView component='season.view.matchSchedule'>
                    <div className='flex gap-2 items-center'>
                      <Link to={`./generate-matches/${seasonWeek ? seasonWeek.id : 0}`}>
                        <HeaderButton type='matchSchedule' />{' '}
                      </Link>
                    </div>
                  </CanIView>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className='flex justify-between gap-4 flex-wrap p-2'>
          <SeasonMatches
            selectedWeek={
              seasonStageData?.SeasonWeek.filter((week: any) => week.id === selectedWeek).length
                ? seasonStageData?.SeasonWeek.find((week: any) => week.id === selectedWeek).id
                : undefined
            }
            seasonStatus={seasonStageData?.status}
          />
        </div>
      </div>
    </>
  );
}
