import { formatDateWithComma } from "../utils/dateFormatter";
export class ScheduleModel {
  id?: number;
  name?: string;
  image?: string;
  team_size?: string;
  start_date?: string;
  end_date?: string;
  status?: string;
  game?: string;
  seasonType?: string;

  constructor(schedule: any) {
    if (schedule.hasOwnProperty('id')) this.id = schedule.id;
    if (schedule.hasOwnProperty('name')) this.name = schedule.name;
    if (schedule.hasOwnProperty('image')) this.image = schedule.image;
    if (schedule.hasOwnProperty('team_size')) this.team_size = schedule.team_size;
    if (schedule.hasOwnProperty('start_date'))
      this.start_date = formatDateWithComma(new Date(schedule.start_date).toDateString());
    if (schedule.hasOwnProperty('end_date') && !schedule.end_date)
      this.end_date = formatDateWithComma(new Date(schedule.end_date).toDateString());
    if (schedule.hasOwnProperty('Game') && schedule.Game?.hasOwnProperty('name'))
      this.game = schedule.Game?.name;
    if (
      schedule.hasOwnProperty('SeasonType') &&
      schedule.SeasonType.hasOwnProperty('name') &&
      !schedule.SeasonType.length
    )
      this.seasonType = schedule.SeasonType.name;
  }
}
