import React from 'react';
import { FaTwitch } from 'react-icons/fa';
import { Link } from 'react-router-dom';
export function ConnectTwitchButton({}) {
  return (
    <Link to='./connect'>
      <div className='w-fit text-white p-2 rounded-xl bg-purple-700 m-auto text-center shadow-lg text-xs mt-2 shadow-purple-700 flex gap-2 items-center font-bold'>
        Please connect to twitch account
        <FaTwitch color='white' size={20} />
      </div>
    </Link>
  );
}
