import { Navigate, useParams } from "react-router"
import ModalArchive from "../../../components/modal-archive";
import { useArchiveUserMutation, useGetUserByIdQuery } from "../../../slices/userApiSlice";

const ArchiveCoachModal = () => {
    const param = useParams();
    const [archiveCoach, { isSuccess, isLoading }] = useArchiveUserMutation();
    const { data: coach } = useGetUserByIdQuery(param.id);

    const onSubmit = () => {
        archiveCoach(param.id).unwrap()
            .then(resp => console.log('resp', resp))
            .catch(err => console.log('err', err));
    };

    if (isSuccess) {
        return <Navigate to="../" />
    };

    return (
        <div>
            <ModalArchive onSubmit={onSubmit} isLoading={isLoading} name={coach?.firstName} />
        </div>
    )
};

export default ArchiveCoachModal;