import { NotificationStatus } from '../utils/constants';
import { dateAndTimeFormatter } from '../utils/dateFormatter';

export class NotificationModel {
  link = '';
  image = '';
  title = '';
  description = '';
  time = '';
  f_time = '';
  status = 0;
  statusName = '';
  constructor(d: any) {
    if (d.hasOwnProperty('link')) this.link = d.link;
    if (d.hasOwnProperty('image')) this.image = d.image;
    if (d.hasOwnProperty('title')) this.title = d.title;
    if (d.hasOwnProperty('description')) this.description = d.description;
    if (d.hasOwnProperty('createdAt')) {
      this.time = d.createdAt;
      this.f_time = dateAndTimeFormatter(d.createdAt).replace(";","");
    }
    if (d.hasOwnProperty('status')) {
      this.status = d.status;
      this.statusName = d.status === NotificationStatus.NotSeen ? 'Unseen' : 'Seen';
    }
  }
}
