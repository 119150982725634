import React from 'react';
import TournamentTeams from '../tournament-teams';
import TournamentTeamsCard from './tournament-teams-card';

const TournamentOrgCard = ({ orgName, teamData }: { orgName: string; teamData: any }) => {
  return (
    <div className='flex flex-col justify-between'>
      <div className='py-2 text-xl'>
        <span className='font-medium'>Organization Name:</span> {orgName}
      </div>
      <div className='flex flex-wrap gap-4 overflow-x-auto'>
        {teamData.map((teams: any, i: number) => (
          <TournamentTeamsCard key={i} {...teams} />
        ))}
      </div>
    </div>
  );
};

export default TournamentOrgCard;
