import { apiSlice } from '../api/apiSlice';
import { changeTerms } from './auth';

export const termsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createTermsAndConditions: builder.mutation({
      query: (body) => ({
        url: '/terms-and-conditions/new',
        method: 'Post',
        body,
      }),
      invalidatesTags: ['TermsAndServices'],
    }),
    // getAllTermsAndConditions: builder.query({
    //   query: () => ({
    //     url: '/terms-and-conditions/all',
    //     method: 'Get',
    //   }),
    //   // providesTags:['TermsAndServices']
    // }),
    getLatestTermsAndConditions: builder.query({
      query: () => ({
        url: '/terms-and-conditions/latest',
        method: 'Get',
      }),
      providesTags: ['TermsAndServices'],
    }),
    acceptTermsAndConditionsByUser: builder.mutation({
      query: (body) => ({
        url: '/terms-and-conditions/connect-with-user',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['TermsAndServices'],
      async onCacheEntryAdded(
        arg,
        { cacheDataLoaded, cacheEntryRemoved, dispatch, getCacheEntry },
      ) {
        await cacheDataLoaded;
        const entries = getCacheEntry();
        dispatch(changeTerms(entries.data.acceptedTerms));
      },
    }),
  }),
});
export const {
  useCreateTermsAndConditionsMutation,
  // useGetAllTermsAndConditionsQuery,
  useGetLatestTermsAndConditionsQuery,
  useAcceptTermsAndConditionsByUserMutation,
  useLazyGetLatestTermsAndConditionsQuery,
} = termsApiSlice;
