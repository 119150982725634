import { Formik, Form } from 'formik';
import React, { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { FormInput } from '../../components/form-input/form-input';
import { OutsideClick } from '../../components/outside-click';
import ModalHeader from '../../components/modal-header';
import ModalFooter from '../../components/modal-footer';
import {
  useGetSubcategoryByIdQuery,
  useUpdateSubcategoryMutation,
} from '../../slices/supportSubcategoryApiSlice';
import { useGetAllSupportCategoryQuery } from '../../slices/supportCategoryApiSlice';
import { SupportSubCategoryValidationSchema } from '../../features/authentication/yupschema';

function EditSupportSubCategory() {
  const param = useParams();
  const {
    data,
    isLoading,
    isError: viewError,
    error: cantView,
  } = useGetSubcategoryByIdQuery({ id: param.id, path: '' });
  const { data: categories, isLoading: catIsLoading } = useGetAllSupportCategoryQuery('');
  const [updateSubCategory, { isError, error, isSuccess }] = useUpdateSubcategoryMutation();
  const [optionValues, setOptionValues] = useState<any>();
  const [responseLevelId, setResponseLevelId] = useState(1);
  const [severityLevelId, setSeverityLevelId] = useState(1);

  useEffect(() => {
    if (data) {
      setResponseLevelId(data?.responseLevel);
      setSeverityLevelId(data?.severityLevel);
    }
  }, [data]);

  useEffect(() => {
    if (optionValues) {
      optionValues.responseLevel = +optionValues.responseLevel;
      optionValues.severityLevel = +optionValues.severityLevel;
      optionValues.responseLevel !== responseLevelId
        ? setResponseLevelId(optionValues.responseLevel)
        : '';
      optionValues.severityLevel !== severityLevelId
        ? setSeverityLevelId(optionValues.severityLevel)
        : '';
    }
  }, [optionValues]);

  const generatePriority = (responseId: any, severityId: any) => {
    switch (responseId) {
      case 1:
        return severityId === 1 || severityId === 2 ? 'Priority 1' : '';
      case 2:
        return severityId === 3 ? 'Priority 1' : '';
      case 3:
        return severityId === 4
          ? 'Priority 2'
          : severityId === 5 || severityId === 6
          ? 'Priority 3'
          : '';
      default:
        return '';
    }
  };

  const priorityLevels = [
    {
      id: '1',
      name: 'P1',
    },
    {
      id: '2',
      name: 'P2',
    },
    {
      id: '3',
      name: 'P3',
    },
  ];

  const formInputs = [
    ...(data?.name?.toLowerCase() !== 'other'
      ? [
          {
            mainTitle: '',
            type: 'text',
            label: 'Subcategory',
            name: 'name',
            placeholder: 'Edit Subcategory',
          },
        ]
      : [
          {
            mainTitle: '',
            type: 'text',
            label: 'Subcategory',
            name: 'name',
            placeholder: 'Edit Subcategory',
            isDisabled: true,
          },
        ]),
    // {
    //     mainTitle: '',
    //     type: 'text',
    //     label: 'Subcategory',
    //     name: 'name',
    //     placeholder: 'Edit Subcategory',
    //     isDisabled: data?.name?.toLowercase() === 'other'
    // },
    ...(data?.name?.toLowerCase() !== 'other'
      ? [
          {
            mainTitle: '',
            name: 'supportCategoryId',
            label: 'Category',
            type: 'text',
            placeholder: 'Change category',
            isSelect: true,
            options: categories?.data?.map((category: any) => ({
              id: category.id,
              name: category.name,
              selected: category.id === data?.category?.id,
            })),
          },
        ]
      : [
          {
            mainTitle: '',
            name: 'supportCategoryId',
            label: 'Category',
            type: 'text',
            placeholder: 'Change category',
            isSelect: false,
            isDisabled: true,
            options: categories?.data?.map((category: any) => ({
              id: category.id,
              name: category.name,
              selected: category.id === data?.category?.id,
            })),
          },
        ]),
    {
      name: 'priorityLevel',
      label: 'Priority level',
      isDisabled: false,
      isSelect: true,
      options: priorityLevels?.map((category: any) => ({
        id: category.id,
        name: category.name,
        selected: category.id === data?.priorityLevel,
      })),
    },
  ];

  const onSubmit = (values: any) => {
    delete values.category;
    console.log('vlaue', values);
    const updatedValues = {
      name: values.name,
      isArchived: values.isArchived,
      priorityLevel: parseInt(values.priorityLevel),
      supportCategoryId: parseInt(values.supportCategoryId, 10),
    };
    updateSubCategory({ id: param.id, body: updatedValues })
      .unwrap()
      .then((resp) => console.log('resp after editing subcategory', resp))
      .catch((err) => console.log('err after editing subcategory', err));
  };

  if (isLoading || catIsLoading) return <p>Loading...</p>;
  // if (viewError) {
  //     return <Navigate to='/went-wrong' state={{ message: (cantView as CustomError).data.message }} />;
  // };
  if (isSuccess) return <Navigate to='../' />;

  return (
    <>
      <OutsideClick />
      <Formik
        initialValues={{
          ...data,
        }}
        onSubmit={onSubmit}
        validationSchema={SupportSubCategoryValidationSchema}
      >
        {({ values }) => {
          useEffect(() => {
            if (!values) return;
            setOptionValues(values);
          }, [values]);
          return (
            <Form>
              <div className='fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-10 w-auto mx-auto max-w-2xl'>
                {/*content*/}
                <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none h-full'>
                  <ModalHeader name='Edit Subcategory' />
                  {/*body*/}
                  <div className='relative px-3 flex-auto overflow-y-auto'>
                    {formInputs.map((input: any, i: number) => (
                      <React.Fragment key={i}>
                        {!formInputs[i - 1] || formInputs[i - 1]?.mainTitle !== input.mainTitle ? (
                          <>
                            <div className='flex flex-col'>
                              <div className='mb-2'>
                                <p className='text-gray-800 font-bold pt-6'>{input.mainTitle}</p>
                                <hr />
                              </div>
                              <div className='flex flex-wrap gap-1'>
                                {formInputs
                                  .filter((inp1: any) => inp1.mainTitle === input.mainTitle)
                                  .map((inp: any, i: number) => (
                                    <React.Fragment key={i}>
                                      <div
                                        className={inp.isTrue ? 'w-full ' : 'flex-1 min-w-[300px]'}
                                      >
                                        <FormInput {...inp} />
                                      </div>
                                    </React.Fragment>
                                  ))}
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </React.Fragment>
                    ))}
                    <ModalFooter isLoading={isLoading} error={error} />
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default EditSupportSubCategory;
