import React from 'react';
import { OutsideClick } from './outside-click';
import { Link } from 'react-router-dom';
import { SubmitButton } from './submit-button';
import { AiOutlineClose } from 'react-icons/ai';
import { PiWarningCircle } from 'react-icons/pi';

const ModalNewSchoolInviteLink = ({
  onSubmit,
  isLoading,
}: {
  onSubmit: () => void;
  isLoading: boolean;
}) => {
  return (
    <>
      <OutsideClick />
      <div className='fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-10 w-auto mx-auto max-w-2xl bg-white p-10 flex flex-col gap-10 items-center justify-center rounded-xl'>
        <div className='absolute top-2 right-2'>
          <Link to='../' className=''>
            <AiOutlineClose size={'2rem'} className='hover:rounded-full hover:bg-gray-100 p-2' />
          </Link>
        </div>
        <div className='py-4 flex flex-col justify-center items-center gap-8'>
          <div className='flex flex-col items-center'>
            <PiWarningCircle size={50} color='red' />
            <h3 className='text-xl'>Warning!</h3>
          </div>
          <p className='select-none font-medium flex flex-col gap-1'>
            <span>Generating a new link will invalidate all previous links for this school.</span>
            If this is not desired, use the link shown on the previous screen.
          </p>
          <div className='flex gap-2'>
            <SubmitButton onSubmit={onSubmit} title='Continue' isLoading={isLoading} />
            <Link
              to='../'
              className='p-4 px-8 text-center text-sm py-2 bg-white text-black border font-semibold rounded-lg hover:bg-gray-100'
            >
              Cancel
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalNewSchoolInviteLink;
