import { OutsideClick } from './../../../components/outside-click';
import { useState } from 'react';
import NewFanModal from './NewFanModal';
import NewCoachModal from './NewCoachModal';
import NewAdminModal from './NewAdminModal';
import NewGuardianModal from './NewGuardianModal';
import NewOGMAModal from './NewOGMAModal';
import NewStudentModal from './NewStudentModal';
import ModalHeader from '../../../components/modal-header';
const Modal = () => {
  const [newUserModal, setNewUserModal] = useState('Admin');
  const optionList = [
    'Admin',
    'OGMA',
    'Student',
    'Coach',
    'Guardian',
    // , "Fan"
  ];
  const modalType: { [key: string]: {} } = {
    Coach: <NewCoachModal />,
    Fan: <NewFanModal />,
    Guardian: <NewGuardianModal />,
    OGMA: <NewOGMAModal />,
    Student: <NewStudentModal />,
    Admin: <NewAdminModal />,
    admin: <NewAdminModal />,
  };

  return (
    <>
      <OutsideClick />
      <div className='fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-10 min-w-[40vw] mx-auto max-w-2xl h-[80vh]'>
        <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none h-full'>
          <ModalHeader name={'New User'} />

          <div className='relative px-3 flex-auto overflow-auto'>
            <>
              <label>Role</label>
              <select
                onChange={(e) => setNewUserModal(e.target.value)}
                className='w-full p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600'
              >
                {optionList.map((o) => (
                  <option key={o} value={o}>
                    {o}
                  </option>
                ))}
              </select>
              {newUserModal && modalType[newUserModal]}
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
