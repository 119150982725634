import { Form, Formik } from 'formik';
import { Link, useParams } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { OutsideClick } from '../../../../../../components/outside-click';
import { SubmitButton } from '../../../../../../components/submit-button';
import {
  useArchiveTournamentMutation,
  useDuplicateTournamentMutation,
  useGetSingleTournamentInfoQuery,
} from '../../../../../../slices/tournamentApiSlice';
import { CustomError } from '../../../../../../utils/custom-types';

function DuplicateTournament() {
  const param = useParams();
  const {
    error,
    data: tournamentData,
    isSuccess: tournamentIsSuccess,
    isError: tournamentIsError,
  } = useGetSingleTournamentInfoQuery(param.id);
  const [duplicateTournament, { data, isSuccess, isError, isLoading }] =
    useDuplicateTournamentMutation();
  const onSubmit = () => {
    duplicateTournament(param.id)
      .unwrap()
      .then((res) => console.log(res))
      .catch((e) => console.log(e));
  };
  if (tournamentIsError || (tournamentIsSuccess && !tournamentData))
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError).data.message }} />;
  if (isSuccess) return <Navigate to='../' />;
  return (
    <div className='fixed z-20 top-0 left-0 h-[100vh] w-[100vw] flex justify-center items-center'>
      <OutsideClick />
      <div className=' flex flex-col items-center gap-5 z-20 p-10 bg-white rounded-xl shadow-xl'>
        Are you sure you want to duplicate this tournament?
        <Formik initialValues={{}} onSubmit={onSubmit}>
          <Form>
            <div className='w-full flex justify-center items-center gap-5'>
              <SubmitButton title='Duplicate' isLoading={isLoading} />
              <Link
                to='../'
                className='w-[100px] flex ml-4 rounded-md  items-center justify-center py-1 shadow text-base bg-slate-400 text-white hover:bg-opacity-80'
              >
                No
              </Link>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
}

export default DuplicateTournament;
