import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  useDownloadInvoiceByIDMutation,
  useGetInvoiceByIdQuery,
} from '../../../../slices/invoicesApiSlice';
import { InvoiceModel } from '../../../ogma-school-subscriptions/models/invoice.model';
import { formatter } from '../../../../utils/utils';

const ViewInvoice = () => {
  const param = useParams();
  const { data, isLoading, isError } = useGetInvoiceByIdQuery(param.id);
  const [model, setModel] = useState<InvoiceModel>();
  const [download, { isLoading: isDownloading, isError: isDwnlError }] =
    useDownloadInvoiceByIDMutation();
  useEffect(() => {
    if (!data) return;
    setModel(new InvoiceModel(data));
  }, [data]);
  const html = {
    contact: {
      org: (
        <>
          <p className='text-xs'>{model?.orgName}</p>
          <p className='text-xs'>{model?.orgEmail}</p>
        </>
      ),
      student: (
        <>
          <p className='text-xs'>{model?.studentEmail}</p>
        </>
      ),
    },
    billTo: {
      org: (
        <>
          <p className='text-xs'>{model?.orgName}</p>
          <p className='text-xs'>{model?.orgState}</p>
          <p className='text-xs'>{model?.orgCity}</p>
          <p className='text-xs'>{model?.orgZip}</p>
          <p className='text-xs'>{model?.orgAddress}</p>
          <p className='text-xs'>{model?.schName}</p>
          <p className='text-xs'>{model?.schoolName}</p>
          <p className='text-xs'>{model?.schoolState}</p>
          <p className='text-xs'>{model?.schoolCity}</p>
          <p className='text-xs'>{model?.schoolZip}</p>
          <p className='text-xs'>{model?.schoolAddress}</p>
        </>
      ),
      student: (
        <>
          <p className='text-xs'>{model?.countStudent}</p>
          <p className='text-xs'>{model?.studentName}</p>
          <p className='text-xs'>{model?.studentState}</p>
          <p className='text-xs'>{model?.studentCity}</p>
          <p className='text-xs'>{model?.studentZip}</p>
          <p className='text-xs'>{model?.studentAddress}</p>
        </>
      ),
    },
  };
  if (isLoading) return <p>Loading...</p>;
  if (isError) return <p>Error </p>;
  return (
    <div className='listViewContainer fullPage border-1 '>
      <div className='max-w-[800px] w-full mx-auto flex flex-col gap-8'>
        <p className='text-xs'>
          Invoice ID: <b>#{model?.id}</b>
        </p>
        <div className='flex gap-2 flex-wrap justify-between '>
          <div className='flex flex-col flex-1 max-w-[300px]'>
            <p className='uppercase  border-b-1 text-xs font-bold'>Bill To</p>
            {model?.orgName ? html.billTo.org : html.billTo.student}
          </div>
          <div className='flex flex-col flex-1 max-w-[300px]'>
            <p className='uppercase  border-b-1 text-xs font-bold'>From</p>
            <p className='text-xs'>Fenworks</p>
            <p className='text-xs'>375 2nd Ave N</p>
            <p className='text-xs'>Grand Forks, ND 58203</p>
            <p className='text-xs'>United States</p>
          </div>
        </div>
        <div className='flex gap-2 flex-wrap justify-between'>
          <div className='flex flex-col flex-1 max-w-[300px]'>
            <p className='uppercase border-b-1 text-xs font-bold'>Contact</p>
            {model?.orgName ? html.contact.org : html.contact.student}
          </div>

          <div className='flex flex-col flex-1 max-w-[300px]'>
            <div className='flex justify-between border-b-1'>
              <p className='text-xs'>Payment Form</p>
              <p className='uppercase text-xs font-bold'>{model?.payTypeName}</p>
            </div>
          </div>
        </div>
        <div className='flex flex-col '>
          <p className='text-xs'>
            Invoice Type <b>{model?.typeName}</b>
          </p>
          <div className='flex flex-col max-w-[400px] self-end'>
            <div className='flex justify-between border-b-1'>
              <p className='text-xs'>Total</p>
              <p className='text-xs'>{formatter.format(Number(model?.total))}</p>
            </div>
            <p className='text-xs'>
              Invoice Status: <b>{model?.statusName}</b>
            </p>
            <div className='flex gap-2'>
              <p className='text-xs'>
                Start Date: <b>{model?.formatedStartDate}</b>
              </p>
              <p className='text-xs'>
                End Date: <b>{model?.formatedEndDate}</b>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div onClick={() => download(Number(param.id))} className='button w-fit'>
        {isDownloading ? 'Downloading...' : 'Download'}
      </div>
      {isDwnlError && <p>Something went wrong!</p>}
    </div>
  );
};

export default ViewInvoice;
