import React, { memo, useEffect, useRef, useState } from 'react';
import { MdLightbulb } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { TwitchPlayer } from 'react-twitch-embed';
import { SponsorsRow } from '../../../components/sponsors-row';
import { selectHeaderVideos } from '../../../slices/gamearenaSlice';
import { GameTagHeader } from './game-tag-header';
import GameArenaSlider from './gamearena-slider';
import { SponsorRow } from '../../../components/sponsor-row/sponsor-row';
import { SponsorPlacements } from '../../../utils/constants';
export function GamearenaHeader() {
  const embed = useRef();
  const handleReady = (e: any) => {
    embed.current = e;
  };
  const headerVideos = useSelector(selectHeaderVideos);
  // const liveVideos = useSelector(selectLiveVideos);
  // const recentVideos = useSelector(selectRecentVideos);
  const [liveVideos, setLiveVideos] = useState<any>();
  const [recentVideos, setRecentVideos] = useState<any>();
  const [selected, setSelected] = useState<string[]>([]);
  const [twProps, setTwProps] = useState<{ [key: string]: any }>({
    channel: 'test',
  });

  useEffect(() => {
    if (!headerVideos) return;
    console.log('headerVideos', headerVideos);
    setLiveVideos(headerVideos.live);
    setRecentVideos(headerVideos.recent);
  }, [headerVideos]);
  useEffect(() => {
    console.log('use effect started', liveVideos, recentVideos);
    if (!liveVideos?.length && !recentVideos?.length) return;
    console.log('use effect live recent');
    if (!selected?.length) {
      console.log('testing conditions', liveVideos.length, recentVideos.length);
      if (liveVideos?.length) {
        console.log('liveVideos');
        setSelected([liveVideos[0].user_name, 'channel']);
      } else if (recentVideos?.length) {
        console.log('recentVideos', recentVideos);
        setSelected([recentVideos[0].id, 'video']);
      }
      //  else {
      //   console.log('testVideo');
      //   setTwProps({ channel: 'test' });
      // }
      console.log('selected', twProps);
      setTwProps({ [selected[1]]: selected[0] });
    }
    if (selected?.length) {
      setTwProps({ [selected[1]]: selected[0] });
    } else if (!selected || !selected.length) setTwProps({ channel: 'test' });
    console.log('test twprops', twProps);
  }, [liveVideos, recentVideos, selected]);

  // return <p>TEST</p>
  return (
    <>
      <div className=' flex relative rounded-xl m-auto bg-black aspect-video sm:max-w-[700px] lg:max-w-[84%] lg:min-w-[84%] px-10 p-32 hover:overflow-visible'>
        <div className='group hover:z-[10]'>
          {/* <SponsorRow
            name={SponsorPlacements.Gamearena}
            noWrap
            extraClass='absolute top-0 left-0 z-[2] gap-0 bg-white bg-opacity-80 rounded-xl p-1'
            // extraImageClass="md:h-[30px]"
          /> */}

          <div className='absolute overflow-hidden w-full h-full max-w-full max-h-full min-w-full min-h-full  rounded-xl top-0 left-0 transition duration-[350ms] group-hover:z-[1] group-hover:translate-y-14 group-hover:lg:scale-110 '>
            {/* {testData[0].user_name} */}
            {twProps ? (
              <TwPlayer
                {...twProps}
                autoplay
                muted
                onReady={handleReady}
                className='max-w-full max-h-full w-full h-full min-w-full min-h-full relative rounded-xl top-0 left-0 transition duration-[350ms] group-hover:z-[1] '
                // group-hover:translate-y-14
                // group-hover:lg:scale-110 "
              />
            ) : (
              <></>
            )}
          </div>
          {/* <img
            src="https://www.gamingcfg.com/img/10500/csgo-de_mirage.jpg"
            className="absolute rounded-xl top-0 left-0 transition duration-[350ms] group-hover:z-[1] group-hover:translate-y-14 group-hover:lg:scale-110 "
          /> */}
          {/* <div className='flex flex-col max-h-full  gap-1 sm:max-w-full md:max-w-[50%] z-[10]'>
            <div className='flex gap-2'>
              <GameTagHeader extraClass='bg-gray-900' name='Valorant' />
            </div>
            <HeaderTitle title='Team A vs Team B' />
            <div className='flex gap-2'>
              <GameTagHeader extraClass='bg-white !text-black' name='Tag 1' />
              <GameTagHeader extraClass='bg-white !text-black' name='Tag 2' />
            </div>
            <p className='text-xs font-bold text-white'>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Alias quod tempora quo magni
              amet necessitatibus nemo illum quos reiciendis saepe.
            </p>
          </div> */}
        </div>
        {selected?.length ? (
          <GameArenaSlider
            // data={[...liveVideos, ...recentVideos]}
            data={[...headerVideos?.live, ...headerVideos?.recent]}
            selected={selected}
            setSelected={setSelected}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
function HeaderTitle({ title }: { title: string }) {
  return <h2 className='text-white font-bold text-lg'>{title}</h2>;
}

const TwPlayer = memo((props: any) => <TwitchPlayer id='headerTwitch' {...props} />);
