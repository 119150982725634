import { SchoolModel } from "./school/school-model";

class Team {
  id?: number;
  teamName?: string;
  activeMembers?: string | number[];
  subMembers?: string | number[];
  captain?: number;
  created?: string;
  image?: any;
  isArchived?: number;
  gameId?: number;
  schoolId?: number;
  eventId?: number;
  school?: SchoolModel;
  organizationName?: string;

  constructor(data: any) {
    if (data.hasOwnProperty('id')) {
      this.id = data.id;
    }
    if (data.hasOwnProperty('teamName')) {
      this.teamName = data.teamName;
    }
    if (data.hasOwnProperty('activeMembers')) {
      this.activeMembers = data.activeMembers;
    }
    if (data.hasOwnProperty('subMembers')) {
      this.subMembers = data.subMembers;
    }
    if (data.hasOwnProperty('captain')) {
      this.captain = data.captain;
    }
    if (data.hasOwnProperty('created')) {
      this.created = data.created;
    }
    if (data.hasOwnProperty('image')) {
      this.image = data.image;
    }
    if (data.hasOwnProperty('isArchived')) {
      this.isArchived = data.isArchived;
    }
    if (data.hasOwnProperty('game_id')) {
      this.gameId = data.game_id;
    }
    if (data.hasOwnProperty('schoolId')) {
      this.schoolId = data.schoolId;
    }
    if (data.hasOwnProperty('eventId')) {
      this.eventId = data.eventId;
    }
    if (data.hasOwnProperty('School')) {
      this.school = data?.School;
      this.organizationName = data?.School?.organization?.name;
    }
  }
}

export class SeasonWeekMatchModel {
  ht_score?: number;
  at_score?: number;
  match_date?: string;
  id?: number;
  home_team?: Team[];
  away_team?: Team[];
  status?: number;
  hasScreenshots = false;
  constructor(data: any) {
    if (data.hasOwnProperty('MatchGame')) {
      if (data.MatchGame?.length) {
        if (data.MatchGame?.[0]?.hasOwnProperty('MatchGameScreenshot')) {
          if (data.MatchGame?.[0]?.MatchGameScreenshot?.length) {
            this.hasScreenshots = true;
          }
        }
      }
    }
    if (data.hasOwnProperty('ht_score')) {
      this.ht_score = data.ht_score;
    }
    if (data.hasOwnProperty('at_score')) {
      this.at_score = data.at_score;
    }
    if (data.hasOwnProperty('match_date')) {
      this.match_date = data.match_date;
    }
    if (data.hasOwnProperty('id')) {
      this.id = data.id;
    }
    if (data.hasOwnProperty('home_team')) {
      this.home_team = data.home_team.map((team: any) => new Team(team));
    }
    if (data.hasOwnProperty('away_team')) {
      this.away_team = data.away_team.map((team: any) => new Team(team));
    }
    if (data.hasOwnProperty('status')) {
      this.status = data.status;
    }
  }
}
