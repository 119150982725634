import * as yup from 'yup';
import React, { useEffect, useState } from 'react';
import { SupportTickets } from './support-tickets';
import { Formik, Form, FormikHelpers } from 'formik';
import { Link, Navigate } from 'react-router-dom';
import { FormInput } from '../../../components/form-input/form-input';
import { useGetAllSupportCategoryQuery } from '../../../slices/supportCategoryApiSlice';
import { useGetAllSubcategoriesByCategoryIdQuery } from '../../../slices/supportSubcategoryApiSlice';
import { useCreateSupportTicketMutation } from '../../../slices/supportTicketApiSlice';
import { SubmitButton } from '../../../components/submit-button';
import { CreateNewTicketValidationSchema } from '../../../features/authentication/yupschema';

const CreateNewTicket = () => {
  const [createTicket, { isLoading, isError, isSuccess }] = useCreateSupportTicketMutation();
  const {
    data: categories,
    isLoading: catIsLoading,
    isSuccess: catIsSuccess,
  } = useGetAllSupportCategoryQuery({
    optional: 1,
  });
  const [supportCgId, setSupportCgId] = useState<any>(null);
  const { data: subCategoriesOfCategory, isSuccess: subIsSuccess } =
    useGetAllSubcategoriesByCategoryIdQuery(+supportCgId, { skip: !supportCgId });
  const [avatarImage, setAvatarImage] = useState<any>('');

  const formInputs = [
    {
      name: 'subject',
      label: 'Subject',
      placeholder: 'Subject name',
      type: 'text',
      isTrue: false,
    },

    {
      name: 'supportCategoryId',
      label: 'Category',
      isSelect: true,
      options: catIsSuccess ? categories : [],
    },

    {
      name: 'supportSubcategoryId',
      label: 'Subcategory',
      placeholder: 'Subcategory',
      isSelect: true,
      options: catIsSuccess && supportCgId && subIsSuccess ? subCategoriesOfCategory : [],
    },

    {
      name: 'description',
      label: 'Describe the issues thoroughly',
      type: 'textarea',
      placeholder: 'Description',
      isTrue: false,
    },

    {
      name: 'link',
      label: 'Link',
      type: 'text',
      placeholder: 'Paste website link of issue',
      isTrue: true,
    },

    {
      name: 'file',
      label: 'Upload file',
      type: 'image',
      placeholder: 'Select a file',
      isTrue: true,
      accept:
        'image/jpeg, image/png, image/jpg, video/mp4, video/x-m4v, video/wmv, video/webm, application/pdf',
    },
  ];

  const initialValues: { [key: string]: string } = {};
  formInputs.forEach((input: any) => (initialValues[input.name] = ''));

  const onSubmit = (values: any, actions: FormikHelpers<{ [key: string]: string }>) => {
    const data = { ...values };
    data.supportCategoryId = +data.supportCategoryId;
    data.supportSubcategoryId = +data.supportSubcategoryId;
    data.file = avatarImage;
    const formData = new FormData();
    formData.append('subject', data.subject);
    formData.append('description', data.description);
    formData.append('link', data.link);
    formData.append('supportCategoryId', data.supportCategoryId);
    formData.append('supportSubcategoryId', data.supportSubcategoryId);
    formData.append('file', avatarImage);
    createTicket(formData);
  };

  if (isSuccess) {
    return <Navigate to='/support/viewMytickets' />;
  }

  if (isLoading || catIsLoading) return <p>Loading...</p>;
  return (
    <>
      <div className='listViewContainer fullPage border-1'>
        <SupportTickets />
        <div className='flex justify-between'>
          <Link to='/support'>
            <button className=' w-[120px] h-[40px] rounded text-slate-700 font-semibold border border-gray-200 text-center hover:bg-gray-200'>
              Back
            </button>
          </Link>
        </div>
        <h2 className='text-4xl font-bold justify-center flex mb-10'>Create New Ticket</h2>

        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={CreateNewTicketValidationSchema.shape({
            file: yup
              .mixed()
              .nullable()
              .test(
                'test2',
                'Invalid image format. Only JPG, PNG, and JPEG formats are allowed.',
                () => {
                  const acceptedFormats = ['image/jpeg', 'image/png', 'image/jpg'];
                  if (avatarImage && !acceptedFormats.includes(avatarImage?.type ?? '')) {
                    return false;
                  }
                  return true;
                },
              ),
          })}
        >
          {({ values }) => {
            useEffect(() => {
              if (!values) {
                return;
              }
              setSupportCgId(values.supportCategoryId);
            }, [values]);
            return (
              <Form>
                <div className='grid gap-y-4 justify-center'>
                  <div className='flex flex-col gap-2'>
                    {formInputs.map((inp: any, i: any) => (
                      <React.Fragment key={i}>
                        <div className={inp.isTrue ? 'w-full' : 'flex-1 min-w-[300px]'}>
                          {inp.type !== 'image' ? (
                            <FormInput {...inp} />
                          ) : (
                            <FormInput setFieldValue={setAvatarImage} {...inp} />
                          )}
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                  <div className='flex justify-end'>
                    <SubmitButton isLoading={isLoading} disabled={isLoading} />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default CreateNewTicket;
