import { GaMatchDetails } from './ga-match-details/ga-match-details';
import { GaLiveVideoSection } from './ga-live-video-section/ga-live-video-section';
import React, { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useGetSingleMatchInfoQuery } from '../../../../slices/matchApiSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectRole, selectToken } from '../../../../slices/auth';
import { io } from 'socket.io-client';
import { setMatchData } from '../../../../slices/matchSlice';
const GameArenaLive = () => {
  // return <div>GameArenaLive</div>;
  const url = import.meta.env.VITE_SOCKET_URL;
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  // const user = useSelector(selectUser);
  // let socket: any;
  const [socket, setSocket] = useState<any>();
  const param = useParams();
  const role=useSelector(selectRole);
  const { data, isLoading, isError } = useGetSingleMatchInfoQuery(param.id);
  useEffect(() => {
    const newsocket = io(url, { extraHeaders: { token } });
    setSocket(newsocket);
    return () => {
      newsocket.close();
    };
  }, [setSocket]);
  useEffect(() => {
    if (!socket) return;
    socket.on('connect', () => {
      socket.emit('join-room', param.id);
      console.log('user connected to socket');
    });
    socket.on('match', (msg: any) => dispatch(setMatchData(msg)));

    return () => {
      console.log('socket return callback');
      socket.close();
      socket.disconnect();
      socket.offAny();
    };
  }, [socket]);
  //allow GameArena routes
  if(role!==0){
    return <Navigate to='../dashboard' />
    }
  if (isLoading) return <p>Loading...</p>;
  return (
    <div className='flex-1 rounded-xl border-1 bg-white h-[calc(100vh-85px)] overflow-y-auto overflow-x-visible p-8 flex-col flex gap-4'>
      <GaLiveVideoSection />
      <GaMatchDetails />
    </div>
  );
};

export default GameArenaLive;
