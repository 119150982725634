import React from 'react';
import { IoChevronBackCircleSharp } from 'react-icons/io5';
import { Link } from 'react-router-dom';

const SuccessSettings = () => {
  return (
    <div className='flex gap-10 items-center'>
      <Link to='../'>
        <IoChevronBackCircleSharp color='gray' className='hover:opacity-80' size={30} />
      </Link>
      <div className='text-green-700'>Information has been updated successfully!</div>
    </div>
  );
};

export default SuccessSettings;
