import { TokenResult } from '@square/web-payments-sdk-types';
import { useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { BsCreditCard2Back } from 'react-icons/bs';
import { CiMoneyCheck1 } from 'react-icons/ci';
import { Navigate, useParams } from 'react-router-dom';
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';
import { useGetInvoiceByIdQuery } from '../../../slices/invoicesApiSlice';
import {
  usePayByCardOgmaInvoiceMutation,
  usePayByCheckOgmaInvoiceMutation,
} from '../../../slices/paymentApiSlice';
import StudentSubscriptionThankYou from '../../student-subscriptions/routes/student-subscription-thank-you';
import { InvoiceModel } from '../models/invoice.model';
import { CustomError } from '../../../utils/custom-types';

const OgmaSubPayments = () => {
  const appId = import.meta.env.VITE_SANDBOX_APP_ID ?? '';
  const locationId = import.meta.env.VITE_LOCATION_ID ?? '';

  const param = useParams();
  const [payType, setPayType] = useState<number>();
  const { error, data, isLoading, isError, isSuccess } = useGetInvoiceByIdQuery(param.invoice);
  const [
    payByCardOgmaInvoice,
    { data: pcData, isLoading: pcIsLoading, isError: pcIsError, isSuccess: pcIsSuccess },
  ] = usePayByCardOgmaInvoiceMutation();
  const [
    payByCheckOgmaInvoice,
    { data: pchData, isLoading: pchIsLoading, isError: pchIsError, isSuccess: pchIsSuccess },
  ] = usePayByCheckOgmaInvoiceMutation();
  const [model, setModel] = useState<InvoiceModel | undefined>();
  useEffect(() => {
    if (!isSuccess) return;
    setModel(new InvoiceModel(data));
  }, [isSuccess]);
  function handleCheckPayment() {
    payByCheckOgmaInvoice(model?.id);
  }
  function handleCardPayment(token: TokenResult) {
    payByCardOgmaInvoice({
      id: model?.id,
      token,
      total: model?.total,
      type: model?.type,
    });
  }
  if (isError || (isSuccess && !data))
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError).data.message }} />;
  if (isLoading) return <p>Loading...</p>;
  if (pcIsSuccess || pchIsSuccess) return <StudentSubscriptionThankYou />;
  return (
    <div className='flex flex-col p-2 gap-5'>
      <h2 className='font-bold text-lg'>Invoice Info</h2>
      {model && <InvoiceInfo model={model} />}
      <ChoosePaymentType setPayType={setPayType} payType={payType} />{' '}
      {payType === 1 && (
        <div className='w-full max-w-[500px] m-auto'>
          <PaymentForm
            applicationId={appId}
            cardTokenizeResponseReceived={(token) => {
              handleCardPayment(token);
            }}
            locationId={locationId}
          >
            <CreditCard />
          </PaymentForm>
          {pcIsError && <p className='font-bold text-red-700'>Something went wrong!</p>}
        </div>
      )}
      {payType === 2 && (
        <div className='w-full  m-auto justify-center items-center flex flex-col'>
          <p>
            <b>Are you sure you want to pay by check for this invoice?</b>
            <br />
            By choosing this payment method, you will need to send a physical check to our
            designated address.
            <br /> Once we receive and process your payment, your invoice will be marked as paid.{' '}
            <br />
            If you are ready to proceed with payment by check, please click
            <b> "Confirm."</b>
            <br />
            If you would like to change your payment method, click <b>"Pay by card" </b>
            option.
          </p>
          <div onClick={handleCheckPayment} className='button'>
            Confirm
          </div>
          {pchIsError && <p className='font-bold text-red-700'>Something went wrong!</p>}
        </div>
      )}
    </div>
  );
};

export default OgmaSubPayments;

function InvoiceInfo({ model }: { model: InvoiceModel }) {
  return (
    <div className='flex justify-evenly shadow-sm p-2'>
      <p>
        <b>ID: </b>
        {model?.id}
      </p>
      <p>
        <b>Type: </b>
        {model?.typeName}
      </p>
      <p>
        <b>Status: </b>
        {model?.statusName}
      </p>
      <span>
        <b>Total: </b>
        <CurrencyFormat
          value={model?.total}
          prefix={'$'}
          displayType='text'
          thousandSeparator={true}
        />
      </span>
    </div>
  );
}

export function ChoosePaymentType({
  setPayType,
  payType,
}: {
  setPayType: Function;
  payType: number | undefined;
}) {
  return (
    <div className='flex w-full justify-center relative flex-wrap'>
      <div onClick={() => setPayType(1)} className={`paymentCard ${payType === 1 ? 'active' : ''}`}>
        <BsCreditCard2Back
          style={{
            minWidth: '100%',
            height: '100%',
          }} // color="white"
        />
        <p className='text-white font-bold '>Pay by card</p>
      </div>
      <div className='p-2 bg-gray-100 rounded-lg absolute m-auto shadow-md border-1 border-gray-300 text-gray-400 left-0 right-0 bottom-0 top-0 w-[40px] h-[40px] font-semibold z-[1]'>
        OR
      </div>
      <div onClick={() => setPayType(2)} className={`paymentCard ${payType === 2 ? 'active' : ''}`}>
        <CiMoneyCheck1
          style={{
            minWidth: '100%',
            height: '100%',
          }} // color="white"
          // className="text-gray-400"
        />
        <p className='text-gray-400 font-bold '>Pay by check</p>
      </div>
    </div>
  );
}
