import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../slices/auth';
import { Link, Outlet, useSearchParams } from 'react-router-dom';
import {
  useGetUnarchivedQuery,
  useMassDeleteMutation,
  useMassUnArchiveMutation,
} from '../../../../slices/unarchiveApiSlice';
import TableContainer from '../../../../components/table-container/table-container';
import { TableHead } from '../../../../components/table-head';
import { UserRoles } from '../../../../utils/constants';
import TableTd from '../../../../components/table-td/table-td';
import Pagination from '../../../../components/pagination';
import { LoadingSceletonArchived } from '../../../tournaments/routes/all/loading-sceleton-archived';
import AreYouSure from '../user/components/are-you-sure';
import useSuccessToast from '../../../../hooks/use-success-toast';
import useErrorToast from '../../../../hooks/use-error-toast';
import useCloseAreYouSure from '../../../../hooks/use-close-are-you-sure';
import { GameModel } from '../../../../model/game.model';

const UnarchiveGame = () => {
  const user = useSelector(selectUser);
  const [searchParams, setSearchParams] = useSearchParams();

  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isArchiveDialogOpen, setArchiveDialogOpen] = useState(false);
  const [checked, setChecked] = useState<number[]>([]);

  const { data, isLoading } = useGetUnarchivedQuery({
    type: 'game',
    page: searchParams.get('page') ?? undefined,
  });

  const [
    massDelete,
    {
      isSuccess: deleteIsSuccess,
      isLoading: deleteIsLoading,
      isError: deleteIsError,
      error: deleteError,
    },
  ] = useMassDeleteMutation();

  const [
    massUnArchive,
    {
      isSuccess: unarchiveIsSuccess,
      isLoading: unarchiveIsLoading,
      isError: unarchiveIsError,
      error: unarchievError,
    },
  ] = useMassUnArchiveMutation();

  const listView = [
    {
      name: '',
      active: true,
      prop: '',
    },
    {
      name: 'name',
      active: true,
      prop: 'name',
    },
  ];

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleDelete = () => {
    massDelete({
      model: 'game',
      ids: [...new Set(checked)],
    });
  };

  const handleArchive = () => {
    massUnArchive({
      model: 'game',
      ids: [...new Set(checked)],
    });
  };

  useCloseAreYouSure({
    archiveIsError: unarchiveIsError,
    archiveIsSuccess: unarchiveIsSuccess,
    deleteIsSuccess,
    deleteIsError,
    setDeleteDialogOpen,
    setArchiveDialogOpen,
    setChecked,
  });
  useSuccessToast(deleteIsSuccess || unarchiveIsSuccess, 'Success');
  useErrorToast(deleteIsError, deleteError);
  useErrorToast(unarchiveIsError, unarchievError);
  if (isLoading) return <LoadingSceletonArchived />;

  return (
    <div className='flex flex-col flex-1 gap-3  '>
      <div className=' flex justify-between gap-2 items-center  '>
        <div className='flex gap-3 items-center pl-10'>
          {data?.data?.length ? (
            <>
              <input
                type='checkbox'
                style={{ width: '15px', height: '15px' }}
                checked={data?.data?.every((i: any) => checked.includes(i.id)) && checked.length}
                onChange={() =>
                  data?.data?.every((i: any) => checked.includes(i.id))
                    ? setChecked(checked.filter((id) => !data?.data.find((d: any) => d.id === id)))
                    : setChecked([...checked, ...(data?.data?.map((item: any) => item.id) || [])])
                }
              />
              <p className='text-base font-body'>Select All</p>
            </>
          ) : (
            <></>
          )}
        </div>
        <div className='flex  items-center gap-5'>
          {checked.length && data?.data?.length ? (
            <button onClick={() => setArchiveDialogOpen(true)} className='btn red '>
              Unarchive
            </button>
          ) : (
            <></>
          )}
          {/* {checked.length && data?.data?.length ? (
            <button onClick={() => setDeleteDialogOpen(true)} className='btn red '>
              Delete
            </button>
          ) : (
            <></>
          )} */}
        </div>
      </div>

      <div className='listViewContainer w-full border flex-1 overflow-x-auto'>
        <div className='flex flex-col flex-1 justify-between'>
          <TableContainer extraClass='overflow-auto'>
            <TableHead
              oneMore={user.roles === UserRoles.Admin}
              tableList={listView
                .map((l) => l.name)
                .filter(
                  (t) => listView.find((d) => d.name.toLowerCase() === t.toLowerCase())?.active,
                )}
            />
            <tbody className='bg-white divide-y divide-gray-200'>
              {data?.data?.map((d: any, i: number) => (
                <tr key={i}>
                  {/* {d?.organization?.isArchived ? <td style={{ width: '15px', height: '15px' }}></td>  :  */}
                  <TableTd>
                    <input
                      type='checkbox'
                      style={{ width: '15px', height: '15px' }}
                      onChange={() =>
                        checked.includes(d.id)
                          ? setChecked((s) => s.filter((id) => id !== d.id))
                          : setChecked((s) => [...s, d.id])
                      }
                      checked={checked.includes(d.id)}
                    />
                  </TableTd>
                  {listView
                    .filter((l) => l.active)
                    .map((s, i) => {
                      const model: GameModel & { [key: string]: any } = new GameModel(d);
                      console.log('model.', model.name);
                      if (s.prop === 'name')
                        return (
                          <TableTd key={i}>
                            <span className='text-sm font-medium text-gray-900 w-full truncate'>
                              {model.name}
                            </span>
                          </TableTd>
                        );
                    })}
                  <TableTd>
                    <Link to={`./unarchive/${d?.id}/${d?.name}`}>
                      <button className='btn red '>Unarchive</button>
                    </Link>
                  </TableTd>
                </tr>
              ))}
            </tbody>
          </TableContainer>
          <Outlet />

          <Pagination length={data?.data?.length} {...data?.meta} />
        </div>
      </div>
      {/* {isDeleteDialogOpen && (
        <AreYouSure
          handleCloseDeleteDialog={handleCloseDeleteDialog}
          handleDelete={handleDelete}
          isLoading={deleteIsLoading}
          text='delete selected games'
        />
      )} */}
      {isArchiveDialogOpen && (
        <AreYouSure
          handleCloseDeleteDialog={setArchiveDialogOpen}
          handleDelete={handleArchive}
          isLoading={unarchiveIsLoading}
          text='unarchive selected teams'
        />
      )}
    </div>
  );
};

export default UnarchiveGame;
