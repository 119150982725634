import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useGetAllMatchesByDateStatusQuery } from '../../../../slices/matchApiSlice';
import MatchScheduleView from './components/match-schedule-view';
import { MatchScheduleModel } from '../../../../model/match-schedule.model';
import Pagination from '../../../../components/pagination';
import { LoadingSceletonSchedule } from '../../../tournaments/routes/all/loading-sceleton-schedule';
import { dateToLocal } from '../../../../utils/dateFormatter';

const MatchScheduleByDate = ({ dateStatus }: { dateStatus: number }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [dataGroupedByDate, setDataGroupedByDate] = useState<{ date: string; data: any }[]>([
    { date: '', data: [] },
  ]);

  const {
    data: event,
    isError,
    isSuccess,
    isLoading,
  } = useGetAllMatchesByDateStatusQuery([
    {
      name: searchParams.get('name') ? searchParams.get('name') : '',
      date: searchParams.get('date') ? searchParams.get('date') : '',
      page: searchParams.get('page') ? searchParams.get('page') : 1,
    },
    { dateStatus: dateStatus ?? undefined },
  ]);

  useEffect(() => {
    if (event && event.data) {
      const groups = event.data.reduce((groups: any, game: any) => {
        const date = dateToLocal(game?.match_date)?.split('T')[0];
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(game);
        return groups;
      }, {});

      const groupArrays = Object.keys(groups)?.map((date) => {
        return {
          date,
          data: groups[date],
        };
      });
      setDataGroupedByDate((prevData) => groupArrays);
    }
  }, [event, event?.data, setDataGroupedByDate]);

  if (isLoading) return <LoadingSceletonSchedule />;
  return (
    <div className='container flex flex-col justify-between flex-1 w-full h-full mx-auto'>
      <div className='flex flex-col w-full'>
        {dataGroupedByDate?.map((e: any, i: number) => (
          <div key={i}>
            <div className='p-4 font-bold'>{dateToLocal(e?.date, 'ddd MMM DD, YYYY')}</div>
            <div className='flex flex-col gap-2 '>
              {e?.data?.map((d: any, i: number) => (
                <Link
                  key={i}
                  to={
                    d.SeasonWeek?.Season?.SeasonType?.leaderboard
                      ? `/seasons/view/${d.SeasonWeek?.Season?.id}`
                      : `/match/${d.id}`
                  }
                >
                  <MatchScheduleView scheduleModel={new MatchScheduleModel(d)} />
                </Link>
              ))}
            </div>
          </div>
        ))}
      </div>
      <Pagination length={event?.data?.length} {...event?.meta} />
    </div>
  );
};

export default MatchScheduleByDate;
