import { MatchResultCircle } from './match-result-circle';
import { useEffect, useState } from 'react';
import { AvatarImage } from '../../../components/avatar-image';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../slices/auth';
import { selectMatchData } from '../../../slices/matchSlice';
import { useDraggable, useDroppable } from '@dnd-kit/core';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import { useNavigate, useParams } from 'react-router';
import { MatchStatus, SeasonProgressStatus, UserRoles } from '../../../utils/constants';
import CanIView from '../../../components/can-i-view/can-i-view';
import { useGetAllOrganizationTeamsQuery } from '../../../slices/teamsApiSlice';
export function MatchTeamAway({
  matchId = 0,
  minWidth = 10,
  id = 0,
  image = '',
  team = '',
  organization = '',
  school = 'High School',
  result = '0',
  win = false,
  size = 'md',
  editMode = false,
  shouldShowLabel = false,
  away_team_length = 0,
  season_status = 2,
  status = 0,
  match_date = '',
  isMatch = false,
  teamData = {
    isBenched: 0,
    organizations: [],
    School: { organization: { ogmas: [], user: { id: '' } } },
    activeMembers: '',
    subMembers: '',
  },
  onMouseEnter = function () {},
}) {
  const sizes: { [key: string]: string[] } = {
    md: ['px-2 py-1', '200px'],
    lg: ['px-4 py-2', '300px'],
    xl: ['px-6 py-3', '400px'],
  };
  const navigate = useNavigate();
  const param = useParams();

  let currentDate = new Date(match_date);
  currentDate.setDate(currentDate.getDate() + 1);
  const user = useSelector(selectUser);
  const matchData = useSelector(selectMatchData);
  const { data, isLoading } = useGetAllOrganizationTeamsQuery({});
  const [isMyTeam, setIsMyTeam] = useState(false);
  const [isOgmaTeam, setIsOgmaTeam] = useState(false);
  useEffect(() => {
    if (data && user.roles === UserRoles.Ogma) {
      const filtered = data?.filter((t: any) => t.id === id);
      setIsOgmaTeam(Boolean(filtered?.length));
    }
  }, [data, id]);
  useEffect(() => {
    if (user && user.team && user.roles == UserRoles.Student) {
      const filtered = user.team.filter((t: any) => t.id === id);
      setIsMyTeam(Boolean(filtered?.length));
    } else if (user && user.ogma_org && user.roles == UserRoles.Ogma) {
      const filtered = user.ogma_org?.filter((t: any) => t.name === organization);
      setIsMyTeam(Boolean(filtered?.length));
    }
  }, [user, id]);

  const { isOver, setNodeRef: setDropNode } = useDroppable({
    id: JSON.stringify([id, matchId, 'away']),
    disabled: !editMode,
  });

  const {
    setNodeRef: setDragNode,
    attributes,
    listeners,
    transform,
    isDragging,
  } = useDraggable({ id: JSON.stringify([id, matchId, 'away']), disabled: !editMode });
  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;
  const isOverdue = status === MatchStatus.Upcoming && new Date(Date.now()) > currentDate;
  const isRescheduleRequested = status === MatchStatus.RescheduleRequested;
  const isForfeited = status === MatchStatus.Forfeit;
  const isCompleted = status === MatchStatus.Completed && win;
  const isDblForfeited = status === MatchStatus.DoubleForfeit;

  const [part, setPart] = useState<boolean>(false);

  useEffect(() => {
    if (teamData) {
      if (user?.roles === UserRoles.Ogma) {
        const isOgmaPart = [
          teamData?.organizations?.map((org: any) => org.user?.id),
          teamData?.School?.organization?.user?.id,
          ...(teamData?.organizations?.map((org: any) =>
            org.ogmas?.map((o: { id: number }) => o.id),
          ) ?? []),
          ...(teamData?.School?.organization?.ogmas?.map((o: { id: number }) => o.id) ?? []),
        ].flat();

        if (isOgmaPart?.includes(user?.id) && teamData?.isBenched) setPart(true);
        else setPart(false);
      }
      if (user?.roles === UserRoles.Student) {
        const isPart =
          teamData?.activeMembers?.includes(user?.id) || teamData?.subMembers?.includes(user?.id);
        if (isPart && teamData?.isBenched) setPart(true);
        else setPart(false);
      }
    }
  }, [teamData]);

  return (
    <div
      style={{ ...style }}
      className={`flex flex-1 group w-full h-full justify-between min-w-[${
        sizes['lg'][1]
      }] h-[50px] rounded-xl items-center bg-gray-200 overflow-hidden relative ${sizes[size][0]} ${
        editMode ? 'editMode' : ''
      } 
        ${isDragging ? 'z-50' : ''} 
        ${isOver ? '!bg-green-500' : ''} 
        ${isMatch ? 'hover:bg-slate-300' : ''}
        ${isOverdue ? 'blink-dark-red' : ''}
        ${isRescheduleRequested ? 'blink-orange' : ''}
        ${isForfeited && !win ? 'border-red-500 border-2 shadow-red' : ''}
        ${isCompleted ? 'border-green-600 border-2 shadow-green' : ''}
        ${isDblForfeited ? 'border-red-500 border-2 shadow-red' : ''}
        `}
      {...listeners}
      {...attributes}
      ref={(el) => {
        setDropNode(el);
        setDragNode(el);
      }}
      // onClick={() => navigate(`/teams/view/${id}`)}
    >
      <div className='flex gap-1 items-center w-full justify-between'>
        <MatchResultCircle
          win={win}
          result={result}
          dblForfeit={status === MatchStatus.DoubleForfeit}
        />
        <CanIView component='threedots'>
          <label className='group-hover:opacity-100 cursor-pointer opacity-0 flex items-start gap-2'>
            {shouldShowLabel &&
            !editMode &&
            season_status !== SeasonProgressStatus.Completed &&
            status !== MatchStatus.InProgress ? (
              <AiFillEdit
                size={22}
                color='black'
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`edit-team/${matchId}?source=away-team`);
                }}
              />
            ) : (
              <></>
            )}
            {shouldShowLabel &&
            !editMode &&
            away_team_length > 0 &&
            season_status !== SeasonProgressStatus.Completed &&
            status !== MatchStatus.InProgress ? (
              <AiFillDelete
                size={22}
                color='red'
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`delete-team/${matchId}?source=away-team`);
                }}
              />
            ) : (
              <></>
            )}
          </label>
        </CanIView>
        <div className='flex flex-col flex-1 min-w-[1px]'>
          <div>
            <h2
              onMouseEnter={onMouseEnter}
              className={`font-bold text-end break-words flex-1 min-w-[1px] text-${size} ${
                isMyTeam || isOgmaTeam ? 'my-team' : ''
              }`}
            >
              {team}
            </h2>
            <div className='flex justify-end'>
              {user?.roles === UserRoles.Admin && teamData?.isBenched ? (
                <div className='self-end'>
                  <span className='text-[#B50303] font-[600] font-[Inter] text-[12px]'>
                    BENCHED
                  </span>
                </div>
              ) : (user?.roles === UserRoles.Ogma || user?.roles === UserRoles.Student) &&
                teamData?.isBenched &&
                part ? (
                <div className='self-end'>
                  <span className='text-[#B50303] font-[600] font-[Inter] text-[12px]'>
                    BENCHED
                  </span>
                </div>
              ) : null}
            </div>
          </div>
          <p className='text-[10px]'>{school}</p>
        </div>
        <AvatarImage source={image} />
      </div>
    </div>
  );
}
