import { Form, Formik } from 'formik';
import React from 'react';

import { ChangePassword } from '../../authentication/yupschema';
import CustomInput from '../../authentication/CustomInput';
import SettingsContainer from './SettingsContainer';
import { useChangePasswordMutation } from '../../../slices/userApiSlice';
import { FormInput } from '../../../components/form-input/form-input';
import { CustomError } from '../../../utils/custom-types';
import { LoadingSceletonSecurity } from '../../../routes/tournaments/routes/all/loading-sceleton-security';

const SecuritySettings = () => {
  const onSubmit = (event: any, { resetForm }: { resetForm: Function }) => {
    // console.log("submitted", event);
    changePassword({
      currentPassword: event.password,
      newPassword: event.newPassword,
      confirmPassword: event.confirmPassword,
    });
    resetForm();
  };
  const [changePassword, { error, isError, isSuccess, data, isLoading }] =
    useChangePasswordMutation();

  const inputList = [
    {
      label: 'Current Password',
      name: 'password',
      type: 'password',
      placeholder: 'Enter current password',
    },
    {
      label: 'New Password',
      name: 'newPassword',
      type: 'password',
      placeholder: 'Enter password',
    },
    {
      label: 'Confirm Password',
      name: 'confirmPassword',
      type: 'password',
      placeholder: 'Re-enter password',
    },
  ];

  if (isLoading) return <LoadingSceletonSecurity />;
  return (
    <>
      <SettingsContainer small title='Change Password'>
        {/* {isError ? 'Something went wrong. Try again' : ''} */}
        {data && data.status > 200 ? data.response : ''}
        <Formik
          initialValues={{
            password: '',
            newPassword: '',
            confirmPassword: '',
          }}
          onReset={() => console.log('reset')}
          validationSchema={ChangePassword}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, resetForm }) => (
            <Form>
              {inputList.map((input, i: number) => (
                <div key={i} className='   max-w-[500px] '>
                  <FormInput
                    // className="w-1/2 px-2 py-2 text-gray-700 bg-gray-200 rounded"
                    {...input}
                  />
                </div>
              ))}
              <div className='text-left'>
                {isError ? (
                  <h1 className='text-red-500'>{(error as CustomError).data.message}</h1>
                ) : (
                  ''
                )}
                {isSuccess ? <h1 className='text-green-700'>Password changed successfully</h1> : ''}
              </div>
              <div className='flex justify-end max-w-[500px] 	'>
                <button
                  className='w-2/7 my-5 pl-7 pr-7  py-2 bg-gray-700 shadow-lg  text-white font-semibold rounded-lg'
                  disabled={isSubmitting}
                  type='submit'
                >
                  Change Password
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </SettingsContainer>
    </>
  );
};

export default SecuritySettings;
