import React from 'react';
import { GuardianDashMatchRow } from './guardian-dash-match-row';
import { Link, useSearchParams } from 'react-router-dom';
import { useGetAllMatchesByDateStatusQuery } from '../../../slices/matchApiSlice';
import { MatchModel } from '../../../model/match.model';
import { MatchScheduleModel } from '../../../model/match-schedule.model';
import MatchScheduleView from '../../schedule/routes/match-schedule/components/match-schedule-view';
import { DateStatus } from '../../../utils/constants';
export function GuardianDashUpMatches({ }) {

  const [searchParams, setSearchParams] = useSearchParams();
  const {
    data: event,
    isError,
    isSuccess,
    isLoading,
  } = useGetAllMatchesByDateStatusQuery([
    {
      name: searchParams.get('name') ? searchParams.get('name') : '',
      date: searchParams.get('date') ? searchParams.get('date') : '',
      page: searchParams.get('page') ? searchParams.get('page') : 1,
      limit: 3
    },
    { dateStatus: DateStatus.Upcoming },
  ]);

  if (isLoading) return <p>Loading...</p>

  return (
    <div className='flex flex-col '>
      <div className='flex justify-between'>
        <h2 className='font-bold'>Upcoming Matches</h2>
        <Link to='/schedule' className='underline'>See all</Link >
      </div>
      <div className='flex flex-col gap-2 bg-white p-5 rounded-xl'>
        {event.data.map((match: any) => (
          // <GuardianDashMatchRow {...new MatchModel(match)} />
          <MatchScheduleView scheduleModel={new MatchScheduleModel(match)} />
        ))}
      </div>
    </div>
  );
}
