import * as yup from 'yup';
import {
  cityRules,
  emailRules, nameRulesHint,
  nameRulesWithSpecialCharacter,
  usPhoneRules,
  usPhoneRulesHint,
  usernameRules,
  usernameRulesHint,
  validEmailRule,
  zipCodeRules,
  zipCodeRulesHint
} from '../../../authentication/yupschema';
import { ErrorMessages } from '../../../../utils/messages.enum';
export const newGuardianSchema = yup.object().shape({
  // Type: yup.string().required("Required"),
  firstName: yup.string().matches(nameRulesWithSpecialCharacter, nameRulesHint).required('Required!'),
  lastName: yup.string().matches(nameRulesWithSpecialCharacter, nameRulesHint).required('Required!'),
  username: yup.string().matches(usernameRules, usernameRulesHint).required('Required!'),
  // streetAddress: yup
  //   .string()
  //   .matches(/^[0-9a-zA-Z\s]+$/, 'Street address must only contain letters, spaces and numbers'),
  // city: yup.string().matches(/^[a-zA-Z\s]+$/, 'City name must only contain letters and spaces').required('Required!'),
  city: yup.string().required('Required!').matches(cityRules, ErrorMessages.CityValidation),
  state: yup.string().required('Required!'),
  zipCode: yup.string().matches(zipCodeRules, zipCodeRulesHint).required('Required!'),
  gender: yup.string().required('Required'),
  // dateOfBirth: yup
  //   .date()
  //   .nullable()
  //   .notRequired()
  //   // .min(moment('1930-01-01').startOf('day'), 'User must be born on or after January 1, 1930')
  //   .min(
  //     new Date(new Date().getFullYear() - 100, 0, 1),
  //     `User must be born on or after January 1, ${new Date(
  //       new Date().getFullYear() - 100,
  //       0,
  //       1,
  //     ).getFullYear()}`,
  //   )
  //   .max(moment().endOf('day').subtract(18, 'years'), 'You must be at least 18 years old!'),
  email: yup.string().required('Required!').matches(emailRules, validEmailRule),
  phoneNumber: yup.string().matches(usPhoneRules, usPhoneRulesHint)
  // guardianStudents: yup.string().required('Required'),
});
