import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { SeasonOptionContainer } from './components/season-option-container';
import CanIView from '../../components/can-i-view/can-i-view';
import { SponsorRow } from '../../components/sponsor-row/sponsor-row';
import { SponsorPlacements } from '../../utils/constants';

export function Seasons() {
  const headerButtons = [
    {
      type: 'export',
    },
    {
      type: 'configureListView',
    },
    {
      type: 'newSeasons',
    },
    {
      type: 'listView',
    },
    {
      type: 'gridView',
    },
  ];
  const navigate = useNavigate();
  // useEffect(() => {
  //   navigate('./all');
  // }, []);

  return (
    <div className='listViewContainer fullPage'>
      <CanIView component='season.option-container'>
        <SeasonOptionContainer />
      </CanIView>
      <SponsorRow name={SponsorPlacements.Seasons} />
      <Outlet />
    </div>
  );
}
