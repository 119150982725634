import { Navigate, useParams } from 'react-router';
import ChangeTicketTierModal from './change-ticket-tier-modal';
import { useChangeTicketTierMutation } from '../../slices/supportTicketApiSlice';

const ChangeTicketTier = () => {
  const param = useParams();
  const [
    changeTicketTier,
    { isLoading: changeTicketTierLoading, isSuccess: ticketTierChangedSuccess },
  ] = useChangeTicketTierMutation();

  const onSubmit = () => {
    const data = {
      newTier: 2,
    };
    changeTicketTier({
      id: param.id,
      body: data,
    });
  };

  if (ticketTierChangedSuccess) {
    return <Navigate to='../' />;
  }

  return <ChangeTicketTierModal onSubmit={onSubmit} isLoading={changeTicketTierLoading} />;
};

export default ChangeTicketTier;
