import { Link, Navigate, NavLink, Outlet, useParams } from 'react-router-dom';
import {
  useChangeTeamProfilePhotoMutation,
  useGetTeamByIdQuery,
} from '../../../slices/teamsApiSlice';
import CanIView from '../../../components/can-i-view/can-i-view';
import { useEffect, useState } from 'react';
import TeamHeader from './routes/stats/components/team-header';
import { CustomError } from '../../../utils/custom-types';

function ViewTeam() {
  const param = useParams();

  const [changeTeamProfilePhoto, { isError, isLoading, isSuccess }] =
    useChangeTeamProfilePhotoMutation();
  const {
    error,
    data: teamData,
    isLoading: teamIsLoading,
    isError: teamIsError,
    isSuccess: teamIsSuccess,
  } = useGetTeamByIdQuery({
    id: param.id,
    query: 'match',
  });

  const [matchStats, setMatchStats] = useState({
    completed: 0,
    wins: 0,
    losses: 0,
  });

  useEffect(() => {
    teamData?.Standing?.map((standing: any) =>
      setMatchStats((prev: any) => ({
        ...prev,
        completed: standing?.wins + standing?.losses,
        wins: standing?.wins,
        losses: standing?.losses,
      })),
    );
  }, [teamData, teamData?.Standing]);

  function handleFileChange(event: any) {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    changeTeamProfilePhoto({ photo: formData, id: param.id })
      .unwrap()
      .then((res) => console.log(res))
      .catch((e) => console.log(e));
  }

  const [buttonList, setButtonList] = useState([
    {
      name: 'Overview',
      link: `/teams/view/${param.id}/`,
    },
    {
      name: 'Members',
      link: './members',
    },
    // {
    //   name: 'Stats',
    //   link: './stats',
    // },
    {
      name: 'Team Schedule',
      link: './team-schedule',
    },
  ]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!teamData || loaded) return;
    if (!teamData.School)
      setButtonList([...buttonList, { name: 'Organizations', link: './organizations' }]);
    setLoaded(true);
  }, [teamData]);

  if (teamIsError || (teamIsSuccess && Object.keys(teamData).includes('clientVersion')))
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError).data.message }} />;

  if (teamIsLoading) return <p>Loading...</p>;

  return (
    <div className='listViewContainer fullPage border-1'>
      <TeamHeader
        teamData={teamData}
        handleFileChange={handleFileChange}
        isLoading={isLoading}
        matchStats={matchStats}
      />
      <div className='flex justify-between items-center flex-wrap'>
        <div className='flex p-2 bg-white '>
          {buttonList.map((b) => (
            <CanIView key={b.link} component={`teams.${b.name.toLowerCase()}`}>
              <NavLink to={b.link} className='settingsLink'>
                <div> {b.name} &nbsp;</div>
              </NavLink>
            </CanIView>
          ))}
        </div>
        <div className='flex'>
          <CanIView component='team.seasonSelection.button'>
            <div className='p-1'>
              <Link
                className='w-[250px] bg-fen-blue text-white px-8 py-2 outline-[#B50303] rounded-lg text-sm'
                to={`./add-to-season`}
              >
                Season Selection
              </Link>
            </div>
          </CanIView>
          <CanIView component='team.bench.button'>
            <div className='p-1'>
              <Link
                className='w-[250px] bg-[#B50303] text-white px-8 py-2 outline-[#B50303] rounded-lg text-sm'
                to={`${teamData?.isBenched ? `./unbench/${param?.id}` : `./bench/${param?.id}`} `}
              >
                {teamData?.isBenched ? 'Unbench' : 'Bench'}
              </Link>
            </div>
          </CanIView>
        </div>
      </div>
      <Outlet />
    </div>
  );
}

export default ViewTeam;
