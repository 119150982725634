import { useGetLastSeasonStandingByStudentIdQuery } from '../../../slices/seasonApiSlice';
import { NameAndAvatar } from '../../../components/name-and-avatar';
import { Link } from 'react-router-dom';
import { dateToLocal } from '../../../utils/dateFormatter';

const LastSeasonStanding = ({ studentId }: any) => {
  const { data, isLoading, isError, error } = useGetLastSeasonStandingByStudentIdQuery(studentId);

  if (isLoading) return <p>Loading...</p>;

  if (data === null)
    return (
      <p className='w-full h-full flex justify-center items-center'>
        This student doesn't participate in any season!
      </p>
    );

  return (
    <div className='flex flex-col w-full h-full'>
      <Link to={`/seasons/view/${data.Season?.id}`}>
        <div className='w-full flex justify-between items-center p-4 border-b hover:bg-gray-100 cursor-pointer'>
          <NameAndAvatar image={data.Season?.image} name={data.Season?.name} />
          <div className='text-end'>
            <p>
              <span className='font-bold'>Start Date: </span>
              {/* {new Date(data.Season?.start_date).toLocaleDateString()} */}
              {dateToLocal(data?.Season?.start_date, 'MMMM D, YYYY')}
            </p>
            <p>
              <span className='font-bold'>End Date: </span>
              {/* {new Date(data.Season?.end_date).toLocaleDateString()} */}
              {dateToLocal(data?.Season?.end_date, 'MMMM D, YYYY')}
            </p>
          </div>
        </div>
      </Link>

      <div className='flex w-full h-full justify-between items-center p-4 font-bold text-lg'>
        <p>Wins: {data.wins}</p>
        <Link
          to={`/teams/view/${data.team?.id}`}
          className='px-4 py-2 rounded-xl hover:bg-gray-100 select-none cursor-pointer'
        >
          <NameAndAvatar image={data.team?.image} name={data.team?.teamName} />
        </Link>
        <p>Losses: {data.losses}</p>
      </div>
    </div>
  );
};

export default LastSeasonStanding;
