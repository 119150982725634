import React, { MouseEvent, useState } from 'react';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import { HeaderButton } from '../../../../components/header-button';
import { NameAndAvatar } from '../../../../components/name-and-avatar';
import { Search } from '../../../../components/search/search';
import TableContainer from '../../../../components/table-container/table-container';
import { TableHead } from '../../../../components/table-head';
import TableTd from '../../../../components/table-td/table-td';
import { Separator } from '../../../../components/ui/separator/Separator';
import {
  useGetAllSeasonsQuery,
  useSeasonExportListMutation,
} from '../../../../slices/seasonApiSlice';
import { TournamentsGroupByInput } from '../../../tournaments/routes/all/components/tournaments-group-by-input';
import { SeasonGridCard } from './routes/components/season-grid-card';
import { SeasonGroupedByGame } from './routes/components/season-grouped-by-game';
import { SeasonGroupedBySubdivision } from './routes/components/season-grouped-by-subdivision';
import { SeasonGroupedBySeasonDivision } from './routes/components/seasons-grouped-by-division';
import CanIView from '../../../../components/can-i-view/can-i-view';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../slices/auth';
import { SeasonProgressStatus, UserRoles } from '../../../../utils/constants';
import Pagination from '../../../../components/pagination';
import { SeasonModel } from '../../../../model/season.model';
import { ScrollArea } from '../../../../components/ui/scroll-area/ScrollArea';
import ShowTeamsModal from '../../../../components/show-teams-modal';
import DropdownItem from '../../../../features/listview/DropDown/DropDownItem';
import { LoadingSceletonLists } from '../../../tournaments/routes/all/loading-sceleton-lists';

function AllSeasons() {
  const [downloadList, { isLoading: isDownloading }] = useSeasonExportListMutation();

  const configureListView = [
    {
      name: 'name',
      active: true,
      prop: 'name',
    },
    {
      name: 'game',
      active: true,
      prop: 'game',
    },
    {
      name: 'start date',
      active: true,
      prop: 'f_startDate',
    },
    {
      name: 'end date',
      active: true,
      prop: 'f_endDate',
    },
    {
      name: 'teams',
      active: true,
      prop: 'teams',
    },
    {
      name: 'category division',
      active: true,
      prop: 'division',
    },
    {
      name: 'subdivision',
      active: true,
      prop: 'subdivisions',
    },
  ];

  const [view, setView] = useState(true);
  const [listView, setListView] = useState(configureListView);
  const headerButtons = [
    {
      type: 'export',
      func: () => {
        const query: { [key: string]: string | undefined } = {};
        filterList.forEach((l) => (query[l.property] = searchParams.get(l.property) ?? undefined));
        console.log(query);
        downloadList({ page: searchParams.get('page') ?? '1', query });
      },
    },
    {
      type: 'configureListView',
      view,
      listView,
      setListView,
    },
    {
      type: 'newSeason',
    },
    {
      type: 'listView',
      func: () => setView(true),
    },
    {
      type: 'gridView',
      func: () => setView(false),
    },
  ];
  const [searchParams, setSearchParams] = useSearchParams();
  const filterList = [
    {
      title: 'name',
      property: 'name',
    },
    {
      title: 'game name',
      property: 'Game',
    },
    {
      title: 'category division',
      property: 'category',
    },
    {
      title: 'subdivisions',
      property: 'subdivisions',
    },
  ];
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState(filterList[0].title);
  const [showMy, setShowMy] = useState(false);
  const { data, isLoading, isError } = useGetAllSeasonsQuery({
    query: searchQuery,
    param: searchParams.get(searchQuery) ? searchParams.get(searchQuery) : '',
    page: searchParams.get('page') ? searchParams.get('page') : 1,
    showMy,
  });
  const [search, setSearch] = useState({ by: 'name', keyword: '' });
  const [modelId, setModelId] = useState<String[] | undefined>();
  const [openListView, setOpenListView] = useState(false);
  const initialOpenListState = {
    id: null,
    x: null,
    y: null,
  };

  const [openList, setOpenList] = useState<{ [key: string]: number | null }>(initialOpenListState);

  function handleClick(prop: { id: number; x: number; y: number }) {
    if (user.roles === UserRoles.Admin) {
      setOpenListView(false);
      if (openList.id == prop.id) {
        setOpenList(initialOpenListState);
      } else {
        setOpenList(prop);
      }
    } else navigate(`/seasons/view/${prop.id}`);
  }
  if (isLoading) return <LoadingSceletonLists />;

  const handleTdClick = (teams: string[]) => {
    setModelId(teams);
  };

  return (
    <div className='flex overflow-auto  max-w-full flex-col flex-1 rounded-xl bg-white border-1'>
      <div className='flex w-full p-5 justify-between'>
        <div className='flex items-center justify-between'>
          <div className='space-y-1'>
            <h2 className='text-2xl font-semibold tracking-tight'>{`${
              showMy ? 'My' : 'All'
            } Seasons`}</h2>
          </div>
        </div>
        <div className='flex gap-2 justify-center'>
          {headerButtons.map((b) => (
            <CanIView component={`season.${b.type}`} key={b.type}>
              <HeaderButton key={b.type} view={view} {...b} />
            </CanIView>
          ))}
        </div>
      </div>
      <Separator className='my-4' />
      <div className='flex w-full justify-between p-5 gap-2 '>
        {user.roles === UserRoles.Ogma && (
          <div className='flex items-baseline'>
            <div
              className='button hover:bg-opacity-80 w-fit bg-fen-blue'
              onClick={() => {
                setShowMy(!showMy);
              }}
            >
              {showMy ? 'Show All Seasons' : 'Show My Seasons'}
            </div>
          </div>
        )}
        <div className='flex items-center gap-5'>
          <div></div>
          {view ? (
            <></>
          ) : (
            <CanIView component='season.groupedSeasonsButton'>
              <TournamentsGroupByInput />
            </CanIView>
          )}
        </div>
        {view || !searchParams.get('groupedBy')?.length ? (
          <Search
            setQuery={setSearchQuery}
            filterList={filterList}
            search={search}
            setSearch={setSearch}
          />
        ) : null}
      </div>
      {view ? (
        <div className='listViewContainer w-full justify-between h-full'>
          {modelId ? (
            <ShowTeamsModal title='All Season Teams' setModelId={setModelId} teams={modelId} />
          ) : (
            <></>
          )}
          <TableContainer>
            <TableHead
              oneMore={user.roles === UserRoles.Admin}
              tableList={listView
                .map((l) => l.name)
                .filter(
                  (t) => listView.find((d) => d.name.toLowerCase() === t.toLowerCase())?.active,
                )}
            />
            <tbody className='bg-white divide-y divide-gray-200'>
              {data?.data?.map((t: any, i: number) => (
                <tr
                  onClick={(e: MouseEvent<HTMLElement>) => {
                    handleClick({ id: t.id, x: e.pageX, y: e.pageY });
                  }}
                  className={user.roles !== UserRoles.Admin ? 'cursor-pointer' : 'relative'}
                  key={i}
                >
                  {listView
                    .filter((l) => l.active)
                    .map((o, i) => {
                      const model: SeasonModel & { [key: string]: any } = new SeasonModel(t);
                      if (o.prop === 'name')
                        return (
                          <TableTd key={i}>
                            <div className='flex gap-2'>
                              <NameAndAvatar name={model?.name} image={model.image} />
                              <span
                                className={`eventStatus type${model.status} flex items-center `}
                              >
                                {model.status === SeasonProgressStatus.Upcoming
                                  ? 'upcoming'
                                  : model.status === SeasonProgressStatus.Completed
                                  ? 'completed'
                                  : 'ongoing'}
                              </span>
                            </div>
                          </TableTd>
                        );
                      if (o.prop === 'teams')
                        return (
                          <React.Fragment key={i}>
                            <TableTd
                              key={i}
                              handleClick={(e: MouseEvent<HTMLElement>) => {
                                if (model.teams.length) {
                                  handleTdClick(model.teams);
                                }
                                e.stopPropagation();
                              }}
                            >
                              {model.teams.length > 1 ? (
                                <span className='hover:text-red-600 underline'>Show Teams</span>
                              ) : !model.teams.length ? (
                                <span>-</span>
                              ) : (
                                <div className='max-w-[15ch] truncate'>
                                  {model.teams[0]?.teamName}
                                </div>
                              )}
                            </TableTd>
                          </React.Fragment>
                        );
                      if (o.prop === 'division')
                        return (
                          <TableTd key={i} extraClass='max-w-[15ch]'>
                            <div className='text-sm font-medium text-gray-900 truncate'>
                              {model.division}
                            </div>
                          </TableTd>
                        );
                      if (o.prop === 'game')
                        return (
                          <TableTd key={i} extraClass='max-w-[15ch]'>
                            <div className='text-sm font-medium text-gray-900 truncate'>
                              {model.game}
                            </div>
                          </TableTd>
                        );

                      if (o.prop === 'subdivisions')
                        return (
                          <TableTd key={i} extraClass='max-w-[15ch]'>
                            {model?.subdivisions?.length === 1 ? (
                              <div className='truncate'>{model.subdivisions[0]?.name}</div>
                            ) : (
                              model?.subdivisions[0]?.name
                            )}
                            <span className='group relative select-none hover:cursor-pointer max-w-[15ch]'>
                              {model.subdivisions.slice(1).length
                                ? `, +${model.subdivisions.slice(1).length} more`
                                : ''}
                              <div className='hidden absolute shadow-md  top-[100%] -right-[100%] z-50 group-hover:flex'>
                                {model?.subdivisions?.slice(1).length ? (
                                  <ScrollArea className='max-h-72 min-h-fit w-48 bg-white	 rounded-md border border-slate-100 dark:border-slate-700'>
                                    <div className='p-4'>
                                      {model?.subdivisions?.slice(1).map((sub: any, i: number) => (
                                        <React.Fragment key={i}>
                                          <div className='text-sm'>{sub.name}</div>
                                          <Separator className='my-2' />
                                        </React.Fragment>
                                      ))}
                                    </div>
                                  </ScrollArea>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </span>
                          </TableTd>
                        );
                      return <TableTd key={i}>{model[o.prop]}</TableTd>;
                    })}
                  <TableTd>
                    <div className='listViewMenu--parent'>
                      <div
                        className={openList.id === t.id ? '' : 'hidden'}
                        style={
                          openList.id === t.id
                            ? {
                                position: 'fixed',
                                top: Number(openList.y),
                                left:
                                  Number(openList.x) < 200
                                    ? Number(openList.x) + 200
                                    : Number(openList.x),
                                zIndex: 99,
                              }
                            : {
                                left: 0,
                              }
                        }
                      >
                        <div
                          className='listViewMenu'
                          role='menu'
                          aria-orientation='vertical'
                          aria-labelledby='menu-button'
                        >
                          <div className='' role='none'>
                            <DropdownItem id={t.id} setFunc={setOpenList} season />
                          </div>
                        </div>
                      </div>
                    </div>
                  </TableTd>
                </tr>
              ))}
            </tbody>
          </TableContainer>
          <Pagination length={data?.data?.length} {...data?.meta} />
        </div>
      ) : searchParams.get('groupedBy') === '1' ? (
        <SeasonGroupedByGame />
      ) : searchParams.get('groupedBy') === '2' ? (
        <SeasonGroupedBySeasonDivision />
      ) : searchParams.get('groupedBy') === '3' ? (
        <SeasonGroupedBySubdivision />
      ) : (
        <div className='flex flex-col p-5 gap-5'>
          <div className='flex  gap-5 justify-center flex-wrap'>
            {data?.data?.map((t: any, i: number) => (
              <SeasonGridCard key={i} {...t} />
            ))}
          </div>
          <Pagination length={data?.data?.length} {...data?.meta} />
        </div>
      )}
      <Outlet />
    </div>
  );
}

export default AllSeasons;
