import { useGetGuardianByIdQuery } from '../../../../slices/userApiSlice';
import { Outlet, useNavigate, useParams } from 'react-router';
import { AiOutlinePlus } from 'react-icons/ai';
import { Button } from '../../../../components/ui/button/Button';
import MyStudentCard from '../components/my-student-card';
import { UserModel } from '../../../../features/listview/users/model/user.model';

const GuardianMyStudents = () => {
  const param = useParams();
  const navigate = useNavigate();

  const { data: guardian, isLoading, isError } = useGetGuardianByIdQuery(param.id);

  if (isLoading) return <p>Loading...</p>;
  return (
    <div className='flex flex-col flex-wrap gap-4'>
      <div className='flex justify-end'>
        <Button
          size='lg'
          variant='outline'
          onClick={() => {
            // setShowModal(true);
            navigate('./new');
          }}
          className='gap-4'
        >
          <AiOutlinePlus className='w-4 h-4' />
          Manage My Students
        </Button>
      </div>
      <div className='flex flex-wrap gap-4'>
        {guardian?.students.map((student: any, i: any) => (
          <MyStudentCard student={new UserModel(student)} key={i} />
        ))}
      </div>

      <Outlet />
    </div>
  );
};

export default GuardianMyStudents;
