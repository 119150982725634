import * as yup from 'yup';
import React, { useState } from 'react';
import { OutsideClick } from '../../../components/outside-click';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import ModalContent from '../../../components/modal-content';
import { newGamesVSchema } from './schema/new-games.schema';
import { useCreateGameMutation } from '../../../slices/gameApiSlice';
import { Navigate } from 'react-router';

const NewGame = () => {
  const [matchFormat, setMatchFormat] = useState<{ id: number; name: string }[]>([]);
  const formInputs = [
    {
      mainTitle: 'Game Information',
      name: 'name',
      label: 'Game Name',
      type: 'text',
      placeholder: 'Enter game name',
      isTrue: true,
    },
    {
      mainTitle: 'Game Information',
      name: 'active_limit',
      label: 'Active Limit',
      type: 'number',
      placeholder: 'Enter active limit',
    },
    // {
    //   mainTitle: 'Game Information',
    //   name: 'subs_limit',
    //   label: 'Substitutes Limit',
    //   type: 'number',
    //   placeholder: 'Enter substitutes limit',
    // },
    {
      mainTitle: 'Game Information',
      name: 'image',
      label: 'Image',
      type: 'image',
      placeholder: 'Select an image',
      isTrue: true,
    },
    {
      mainTitle: 'Game Information',
      name: 'matchFormat',
      label: 'Match Format',
      type: 'text',
      placeholder: 'Search Match Format',
      isTrue: true,
      search: 'matchFormat',
      matchFormat,
      setMatchFormat,
    },
  ];

  const initialValues: { [key: string]: string } = {};
  formInputs.forEach((input) => (initialValues[input.name] = ''));
  const [createGame, { isLoading, isSuccess }] = useCreateGameMutation();
  function onSubmit(
    values: FormikValues,
    actions: FormikHelpers<{
      [key: string]: string;
    }>,
  ) {
    if (!avatarImage) {
      console.log({ avatarImage });
      actions.setErrors({ image: 'Required!' });
      return;
    }
    const acceptedFormats = ['image/jpeg', 'image/png', 'image/jpg'];
    if (!acceptedFormats.includes(avatarImage.type)) {
      actions.setErrors({ image: 'Invalid image format. Only JPG, PNG, and JPEG formats are allowed.' });
      return;
    }
    if (!matchFormat.length) {
      actions.setErrors({ matchFormat: 'Required' });
      return;
    }
    const data = { ...values };
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('active_limit', data.active_limit);
    // formData.append('subs_limit', data.subs_limit);
    formData.append('image', avatarImage);
    data.matchFormats = [];
    matchFormat.forEach((mf: { id: number; name: string }) =>
      data.matchFormats.push({ id: mf.id }),
    );
    formData.append('matchFormats', JSON.stringify(data.matchFormats));

    createGame(formData);
  }
  const [avatarImage, setAvatarImage] = useState<any>();
  if (isSuccess) return <Navigate to='../' />;
  return (
    <>
      <OutsideClick />
      <Formik
        validationSchema={newGamesVSchema.shape({
          image: yup
            .mixed()
            .test('test', 'Required!', () => {
              if (!avatarImage) return false;
              return true;
            })
            .test(
              'test2',
              'Invalid image format. Only JPG, PNG, and JPEG formats are allowed.',
              () => {
                const acceptedFormats = ['image/jpeg', 'image/png', 'image/jpg'];
                if (!acceptedFormats.includes(avatarImage?.type ?? '')) {
                  return false;
                }
                return true;
              },
            ),
        })}
        initialValues={initialValues}
        onSubmit={onSubmit}>
        {({ errors }) => {
          return <ModalContent
            errors={Object.keys(errors).length}
            setAvatarImage={setAvatarImage}
            extraClass={'h-fit'}
            formInputs={formInputs}
            isLoading={isLoading}
            name='New Game'
          />
        }}
      </Formik>
    </>
  );
};

export default NewGame;
