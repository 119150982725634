import React, { useEffect, useState } from 'react';
import { AiFillDelete, AiFillEdit, AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';
import { useGetAllLessonsByClassroomIdQuery } from '../../../../slices/classroomApiSlice';
import { Outlet, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { SearchCoaches } from '../components/SearchCoaches';
import LessonContentTable from './LessonContentTable';
import { BiSad } from 'react-icons/bi';
import Pagination from '../../../../components/pagination';
import { HeaderButton } from '../../../../components/header-button';
import CanIView from '../../../../components/can-i-view/can-i-view';

const Lessons = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState({ is: false, id: null });

  const [openList, setOpenList] = useState(null);

  function handleClick(key: any) {
    if (openList == key) {
      setOpenList(null);
    } else {
      setOpenList(key);
    }
  }

  const [searchParams, setSearchParams] = useSearchParams();

  const {
    data: lessonData,
    isLoading: lessonIsLoading,
    isSuccess: lessonIsSuccess,
    isError: lessonIsError,
  } = useGetAllLessonsByClassroomIdQuery({
    classroomId: params.cId,
    query: { name: searchParams.get('name') ?? undefined, page: searchParams.get('page') ?? 1 },
  });

  useEffect(() => {
    console.log('lesson data = ', lessonData);
  }, [lessonData]);

  const filterList = [{ title: 'name', property: 'name' }];

  const initialState = { [filterList[0].title]: '' };
  const [searchQuery, setSearchQuery] = useState(initialState);
  const [search, setSearch] = useState({ by: 'name', keyword: '' });

  const configureListView = [
    {
      name: 'Name',
      active: true,
      property: 'name',
    },
    {
      name: 'Schedule',
      active: true,
      property: 'schedule',
    },
    {
      name: 'Description',
      active: true,
      property: 'objective_descriptions',
    },
    {
      name: 'Created',
      active: true,
      property: 'createdAt',
    },
  ];

  if (lessonIsLoading) return <p>Loading...</p>;
  if (lessonData?.data.length <= 0)
    return (
      <>
        <div className='flex items-center justify-end gap-4 p-5'>
          <div className=''>
            <CanIView component='classroom.training.new-lesson'>
              <HeaderButton type='newLesson' />
            </CanIView>
          </div>
          <SearchCoaches
            setQuery={setSearchQuery}
            filterList={filterList}
            search={search}
            setSearch={setSearch}
          />
        </div>
        <div className='flex gap-10 flex-col justify-center items-center'>
          <BiSad className='bg-gray-400 rounded-full p-7' size={'250px'} color='white' />
          <p className='font-bold text-2xl text-gray-400'>
            This classroom does not have any lesson!
          </p>
        </div>
        <Outlet />
      </>
    );
  // if (isLessonError) return <Navigate to='/went-wrong' />
  if (lessonData?.data.content <= 0) return <p>Loading...</p>;
  return (
    <>
      <div className='flex items-center justify-end gap-4 p-5'>
        <div className=''>
          <CanIView component='classroom.training.new-lesson'>
            <HeaderButton type='newLesson' />
          </CanIView>
        </div>
        <SearchCoaches
          setQuery={setSearchQuery}
          filterList={filterList}
          search={search}
          setSearch={setSearch}
        />
      </div>
      {lessonData?.data.map((lesson: any, i: number) => (
        <div
          key={i}
          className='w-full border border-gray-300 focus:border-gray-500 rounded-lg  mb-2'
        >
          <div
            className='w-full p-3 cursor-pointer relative flex justify-between items-center group'
            onClick={() => {
              open.id == lesson.id
                ? setOpen({ is: false, id: null })
                : setOpen({ is: !open, id: lesson.id });
            }}
          >
            {/* <div className='absolute top-0 left-0 right-0 bottom-0 border'></div> */}
            <div className='flex items-center justify-evenly '>
              <div className='font-bold'>
                {lesson.name.length > 10 ? lesson.name.slice(0, 10) + '...' : lesson.name}
              </div>
              <div className='border border-r'></div>
              {/* TODO: fix button */}
            </div>
            <div className='flex items-center gap-8 '>
              <CanIView component='classroom.training.modify-lesson'>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    navigate(`./content/${lesson.id}`);
                  }}
                  className='ml-auto border border-gray-200 rounded-full px-2 py-1 text-xs text-black hover:border-gray-300 hover:bg-gray-100'
                >
                  Add Content
                </button>
              </CanIView>
              <AiFillEdit
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`./edit/${lesson.id}`);
                }}
                size='25'
                color='gray'
              />
              <AiFillDelete
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`./delete/${lesson.id}`);
                }}
                size='25'
                color='darkred'
              />
              {open.is ? (
                <AiOutlineArrowUp
                  color='black'
                  size='25'
                  className='duration-500 group-hover:rotate-[360deg]'
                />
              ) : (
                <AiOutlineArrowDown
                  color='black'
                  size='25'
                  className='duration-500 group-hover:rotate-[360deg]'
                />
              )}
            </div>
          </div>
          {open.id === lesson.id && (
            <div className='relative p-2 bg-opacity-80'>
              <LessonContentTable
                // data={JSON.parse(JSON.stringify(lesson.content))}
                data={lesson.content}
                onDelete={true}
                lessonId={lesson.id}
              />
            </div>
          )}
        </div>
      ))}
      <Pagination length={lessonData?.data?.length} {...lessonData?.meta} />
      <Outlet />
    </>
  );
};

export default Lessons;
