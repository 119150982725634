import { ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, Navigate, useLoaderData } from 'react-router-dom';
import FanRegister from '../../features/authentication/Register/FanRegister';
import GuardianRegister from '../../features/authentication/Register/GuardianRegister';
import OgmaRegister from '../../features/authentication/Register/OgmaRegister';
import StudentRegister from '../../features/authentication/Register/StudentRegister';
import useRoleAuthorization from '../../hooks/use-role-authorization';
import { selectToken } from '../../slices/auth';
import logo from '../../assets/logo2.png';
import CoachRegister from '../../features/authentication/Register/coach-register';

export async function loader({ request, params }: any) {
  const baseUrl = import.meta.env.VITE_API_URL;
  let user;
  let isError = false;
  const response = await fetch(`${baseUrl}/auth/validateLink?authInvite=true`, {
    method: 'post',
    headers: new Headers({
      Authorization: `Bearer ${params.token}`,
    }),
  }).then((data) => (user = data));

  console.log(user);
  return user;
}

export async function SchoolLoader({ request, params }: any) {
  const baseUrl = import.meta.env.VITE_API_URL;
  let school;
  let isError = false;
  const response = await fetch(`${baseUrl}/auth/validate-school-link?authInvite=true`, {
    method: 'post',
    headers: new Headers({
      Authorization: `Bearer ${params.token}`,
    }),
  }).then((data) => (school = data));

  console.log(school);
  return school;
}

type InvitedUsersProps = {
  isSchoolRegistrationLink: boolean;
};

const InvitedUsers = ({ isSchoolRegistrationLink }: InvitedUsersProps) => {
  const token = useSelector(selectToken);
  const loaderData: any = useLoaderData();
  useEffect(() => {
    console.log('loaderData', loaderData);
  }, [loaderData]);
  const [isAuthorized, { redirect, userRole }] = useRoleAuthorization(
    [0, 1, 2, 3, 4, 5],
    loaderData?.roles,
  );
  let IsError;
  let role;
  //GET THE RESPONSE IF THE LINK IS EXPIRED OR INVALID
  if (loaderData.response?.message.includes('.')) {
    const message = loaderData.response?.message.split('.');
    IsError = message[0].trim();
    role = message[1].trim();
  } else {
    IsError = loaderData.response?.message;
  }
  // console.log(isError)
  const registerModals: { [key: string]: ReactNode } = {
    student: <StudentRegister isSchoolRegistrationLink={isSchoolRegistrationLink} />,
    ogma: <OgmaRegister />,
    guardian: <GuardianRegister />,
    fan: <FanRegister />,
    coach: <CoachRegister />,
  };
  // if (userRole === "none") return <p>Loading...</p>;
  if (token) return <Navigate to='/' />;
  // return redirect;
  return (
    <div className='flex flex-col min-h-[100vh] h-full w-full relative'>
      <div className='w-full sticky top-0 bg-white z-[1] h-100 flex  border-b-2  p-5 border-black'>
        <img width='196' height='49' src={logo} alt='Fenworks Logo' />
      </div>
      {(loaderData && loaderData.statusCode) || !loaderData.accepted_invite ? (
        IsError === 'Token has expired' ||
        IsError === 'Token is invalid' ||
        IsError === 'No User found' ||
        IsError === 'User has already been signed up through this link!' ||
        IsError === 'This link has expired, contact your GM' ? (
          IsError === 'No User found' ? (
            <>
              <h2 className='font-inter text-5xl'>
                This link is expired. Please reach out to your school manager or{' '}
                <Link className='text-blue-500' to='mailto:info@fenworks.com'>
                  info@fenworks.com
                </Link>{' '}
                for support.
              </h2>
            </>
          ) : IsError === 'User has already been signed up through this link!' ? (
            <>
              <h2 className='font-inter text-5xl'>
                User has already been signed up through this link!
              </h2>
            </>
          ) : IsError === 'Token has expired' ? (
            <>
              {+role === 0 || +role === 1 ? (
                <h2 className='font-inter text-5xl'>
                  This link has expired. Please contact{' '}
                  <Link className='text-blue-500' to='mailto:info@fenworks.com'>
                    info@fenworks.com
                  </Link>{' '}
                  for additional support.
                </h2>
              ) : (
                <h2 className='font-inter text-5xl'>
                  This link has expired. Please contact your school’s General Manager for additional
                  support or{' '}
                  <Link className='text-blue-500' to='mailto:info@fenworks.com'>
                    info@fenworks.com
                  </Link>{' '}
                </h2>
              )}
            </>
          ) : IsError === 'This link has expired, contact your GM' ? (
            <>
              <h2 className='font-inter text-5xl'>This link has expired, contact your GM!</h2>
            </>
          ) : (
            <>
              <h2 className='font-inter text-5xl'>Wrong Link!</h2>
            </>
          )
        ) : isSchoolRegistrationLink ? (
          registerModals[userRole]
        ) : (
          <>
            <h2 className='font-inter text-5xl'>Wrong Link!</h2>
          </>
        )
      ) : (
        registerModals[userRole]
      )}
    </div>
  );
};

export default InvitedUsers;
