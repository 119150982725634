import { Formik, Form, FormikValues } from 'formik';
import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { FormInput } from '../../components/form-input/form-input';
import { OutsideClick } from '../../components/outside-click';
import { EditSupportCategoryValidationSchema } from '../../features/authentication/yupschema';
import ModalHeader from '../../components/modal-header';
import ModalFooter from '../../components/modal-footer';
import {
  useGetSupportCategoryByIdQuery,
  useUpdateSupportCategoryMutation,
} from '../../slices/supportCategoryApiSlice';
import { CustomError } from '../../utils/custom-types';

function EditSupportCategory() {
  const param = useParams();
  const {
    data,
    isLoading,
    isError: errorView,
    error: cantViewError,
  } = useGetSupportCategoryByIdQuery(param.id);
  const [updateCategory, { isError, error, isSuccess }] = useUpdateSupportCategoryMutation();

  const formInputs = [
    {
      mainTitle: '',
      type: 'text',
      label: 'Category',
      name: 'name',
      placeholder: 'Category',
    },
  ];

  const onSubmit = (values: FormikValues) => {
    updateCategory({ id: param.id, body: values })
      .unwrap()
      .then((resp) => console.log('category edited', resp))
      .catch((err) => console.log('category edited', err));
  };

  if (isLoading) return <p>Loading...</p>;

  if (errorView) {
    return (
      <Navigate to='/went-wrong' state={{ message: (cantViewError as CustomError).data.message }} />
    );
  }

  if (isSuccess) {
    return <Navigate to='../' />;
  }

  return (
    <>
      <OutsideClick />
      <Formik
        initialValues={{
          ...data,
        }}
        onSubmit={onSubmit}
        validationSchema={EditSupportCategoryValidationSchema}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className='fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-10 w-auto mx-auto max-w-2xl'>
              {/* content*/}
              <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none h-full'>
                <ModalHeader name='Edit Category' />
                {/* body*/}
                <div className='relative px-3 flex-auto overflow-auto'>
                  {formInputs.map((input: any, i: number) => (
                    <React.Fragment key={i}>
                      {!formInputs[i - 1] || formInputs[i - 1]?.mainTitle !== input.mainTitle ? (
                        <>
                          <div className='flex flex-col'>
                            <div className='mb-2'>
                              <p className='text-gray-800 font-bold pt-6'>{input.mainTitle}</p>
                              <hr />
                            </div>
                            <div className='flex flex-wrap gap-1'>
                              {formInputs
                                .filter((inp1: any) => inp1.mainTitle === input.mainTitle)
                                .map((inp: any, i: number) => (
                                  <React.Fragment key={i}>
                                    <div
                                      className={inp.isTrue ? 'w-full ' : 'flex-1 min-w-[300px]'}
                                    >
                                      <FormInput {...inp} />
                                    </div>
                                  </React.Fragment>
                                ))}
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </React.Fragment>
                  ))}
                </div>
                <ModalFooter isLoading={isLoading} error={error} />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default EditSupportCategory;
