import { OutsideClick } from '../../../../../components/outside-click';
import { AiOutlineClose } from 'react-icons/ai';
import { SubmitButton } from '../../../../../components/submit-button';

const AreYouSure = ({ handleCloseDeleteDialog, handleDelete, isLoading, text, note }: any) => {
  return (
    <>
      <OutsideClick setFunc={() => handleCloseDeleteDialog(false)} />
      <div className='fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-10 w-auto mx-auto max-w-2xl bg-white p-10 flex flex-col gap-10 items-center justify-center rounded-xl'>
        <div className='absolute top-2 right-2' onClick={() => handleCloseDeleteDialog(false)}>
          <AiOutlineClose
            size={'2rem'}
            className='hover:rounded-full hover:bg-gray-100 p-2 cursor-pointer'
          />
        </div>
        <div className='py-4 flex flex-col justify-center items-center gap-8 w-full break-words'>
          <p className='select-none font-medium'>Are you sure you want to {text}?</p>
          {note}
          <div className='flex gap-2'>
            <SubmitButton onSubmit={handleDelete} title='Confirm' isLoading={isLoading} />
            <div
              onClick={() => handleCloseDeleteDialog(false)}
              className='p-4 px-8 text-center text-sm py-2 bg-white text-black border font-semibold rounded-lg hover:bg-gray-100 cursor-pointer'
            >
              No
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AreYouSure;
