import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import InvoicesOptionContainer from './components/invoices-option-container';

const Invoices = () => {
  const navigate = useNavigate();
  const [firstTime, setFirstTime] = useState(true);


  useEffect(() => {
    if (firstTime) {
      navigate('./schools');
      setFirstTime(false);
    }
  }, [firstTime]);
  useEffect(() => {
    return () => setFirstTime(true);
  }, []);
  return (
    <div className='listViewContainer fullPage'>
      <InvoicesOptionContainer />
      <Outlet />
    </div>
  );
};

export default Invoices;
