import React from 'react';
import { AvatarImage } from '../../../components/avatar-image';
import { NotificationModel } from '../../../model/notification.model';
import { Link } from 'react-router-dom';
import { AiFillNotification } from 'react-icons/ai';
import { FaExternalLinkAlt } from 'react-icons/fa';

function AllNotificationRow({ model }: { model: NotificationModel }) {
  if (model?.link?.length)
    return (
      <Link
        to={model.link}
        className={`w-full max-w-[100%] md:max-w-[100%] rounded-lg flex items-center gap-5 h-[100px] min-h-[100px] overflow-hidden px-5 relative border-1 ${
          !model.status ? ' bg-red-100' : ''
        } hover:bg-slate-300`}
      >
        <NotificationCore model={model} />
      </Link>
    );
  return (
    <div
      className={`w-full max-w-[100%] md:max-w-[100%] rounded-lg flex items-center gap-5 h-[100px] min-h-[100px] overflow-hidden px-5 relative border-1 ${
        !model.status ? ' bg-red-100' : ''
      }`}
    >
      <NotificationCore model={model} />
    </div>
  );
}

export default AllNotificationRow;

function NotificationCore({ model }: { model: NotificationModel }) {
  return (
    <>
      {/* <AvatarImage source={model.image} size='xl2' /> */}

      {model.link ? (
        <FaExternalLinkAlt color='gray' size={20} />
      ) : (
        <AiFillNotification color='gray' size={20} />
      )}
      <div className='flex flex-col   flex-1 max-w-[80%] '>
        <span className='text-gray-500 font-bold text-sm'>{model.title}</span>
        <span className='font-medium text-sm text-gray-700 whitespace-pre text-ellipsis overflow-hidden'>
          {model.description}
        </span>
      </div>
      <span className='text-xs absolute bottom-1 right-5'>{model.f_time}</span>
    </>
  );
}
