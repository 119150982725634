import React from 'react';
import { Link } from 'react-router-dom';
import { AvatarImage } from '../../../components/avatar-image';
export function FanDashboardTournCard({ season }: { season?: boolean }) {
  return (
    <Link
      to={season ? '/seasons' : '/tournaments'}
      className='lg:w-[30%] sm:w-[100%] p-5 min-h-[200px] rounded-xl bg-white flex justify-center gap-1 items-center'
    >
      <AvatarImage size='xxl' source='' />
      <p className='font-bold'>Tournament 1</p>
    </Link>
  );
}
