import React, { useState } from 'react';
import { InvoicesOptionCard } from './invoices-option-card';

const InvoicesOptionContainer = () => {
  const invoicesOptions = [
    {
      id: 1,
      option: 'Schools',
      description: 'School invoices',
      to: './schools',
    },
    {
      id: 2,
      option: 'Students',
      description: 'Student invoices',
      to: './students',
    },
  ];
  const [isActive, setIsActive] = useState(1);
  return (
    <div className='flex min-h-fit gap-5 items-center lg:items-start  p-5  flex-wrap flex-row bg-white'>
      {invoicesOptions.map((i) => (
        <InvoicesOptionCard isActive={i.id === isActive} key={i.option} {...i} />
      ))}
    </div>
  );
};

export default InvoicesOptionContainer;
