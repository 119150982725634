import React from 'react';
import { Link } from 'react-router-dom';
export function CancelMatchButton({}) {
  return (
    <Link to='./cancel'>
      <div className='rounded-lg flex justify-center p-2 uppercase border border-gray-700 text-black text-sm min-w-[200px]'>
        Cancel
      </div>
    </Link>
  );
}
