import { AiOutlineClose } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import { OutsideClick } from '../../../../components/outside-click';
import { useDisconnectTwitchNameMutation } from '../../../../slices/userApiSlice';
import { selectUser, setUserTwitchUsername } from '../../../../slices/auth';
import { useDispatch, useSelector } from 'react-redux';

const DisconnectTwitch = () => {
  const [disconnectTwitchAccount, { isSuccess }] = useDisconnectTwitchNameMutation();
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function onSubmit() {
    disconnectTwitchAccount({
      id: user.id,
    })
      .unwrap()
      .then(() => {
        dispatch(
          setUserTwitchUsername({
            id: null,
            login: null,
          }),
        );
      })
      .catch((error) => {
        console.error('An error occurred:', error);
      });
  }
  if (isSuccess) navigate('../');
  return (
    <>
      <OutsideClick />
      <div className='fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-10 w-auto mx-auto max-w-2xl bg-white p-10 flex flex-col gap-10 items-center justify-center rounded-xl'>
        <div className='absolute top-2 right-2'>
          <Link to='../' className=''>
            <AiOutlineClose size={'2rem'} className='hover:rounded-full hover:bg-gray-100 p-2' />
          </Link>
        </div>
        <div className='py-4 flex flex-col justify-center items-center gap-8'>
          <p className='select-none font-medium'>
            Are you sure you want to disconnect your twitch account?
          </p>
          <div className='flex gap-2'>
            <div
              onClick={onSubmit}
              className='p-4 px-8 text-center text-sm py-2 bg-red-700 cursor-pointer text-white border font-semibold rounded-lg '
            >
              Yes
            </div>
            <Link
              to='../'
              className='p-4 px-8 text-center text-sm py-2 bg-white text-black border font-semibold rounded-lg hover:bg-gray-100'
            >
              No
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default DisconnectTwitch;
