import { useParams } from 'react-router-dom';
import { useGetSingleTournamentInfoQuery } from '../../../slices/tournamentApiSlice';
import { TournamentOverviewFormat } from './tournament-overview-format';
export function TournOverviewInformation({}) {
  const param = useParams();
  const { data } = useGetSingleTournamentInfoQuery(param.id);
  return (
    <div className='flex flex-col gap-4 '>
      <h2 className='font-bold text-xl'>Tournament Description</h2>
      <div className='flex gap-5 w-full justify-between border border-gray-300 rounded-xl p-4 flex-wrap'>
        <div className='flex flex-1 w-full md:w-fit border rounded-xl border-gray-200 p-4 break-all order-2 md:order-1'>
          {data.information}
        </div>
        <TournamentOverviewFormat />
      </div>
    </div>
  );
}
