import React, { useEffect } from 'react';
import { BsFillTrashFill } from 'react-icons/bs';
import { FaSchool } from 'react-icons/fa';
import { AvatarImage } from '../../../components/avatar-image';
import { useNavigate } from 'react-router-dom';

function TournamentTeamsCard({
  image,
  teamName,
  School,
  id,
}: {
  image: string;
  teamName: string;
  School: { id: number; name: string; organization: {} };
  id: number;
}) {
  const navigate = useNavigate();

  return (
    <div
      className='w-[220px] flex flex-col items-center justify-center gap-4 py-4 px-8 border border-gray-300 rounded-xl cursor-pointer hover:bg-gray-100'
      onClick={() => navigate(`/teams/view/${id}`)}
    >
      <AvatarImage source={image} size={'xl'} />
      <h1 className='font-semibold text-lg max-w-[15ch] truncate'>{teamName}</h1>
      <div className='w-full flex  items-center justify-around bg-gray-100 px-2 py-1 rounded-xl'>
        <FaSchool color='black' />
        <p className='text-gray-600 max-w-[15ch] truncate'>{School?.name ?? 'CO-OP'}</p>
      </div>
    </div>
  );
}

export default TournamentTeamsCard;
