import { OutsideClick } from '../../../../components/outside-click';
import EditManualBracket from './edit-manual-bracket';

const EditBracket = () => {
  return (
    <>
      <OutsideClick />
      <EditManualBracket />
    </>
  );
};

export default EditBracket;
