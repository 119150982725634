import { apiSlice } from '../api/apiSlice';
import { setTwitchToken } from './gamearenaSlice';

export const gamearenaApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    initGamearena: builder.query({
      query: () => ({
        url: '/gamearena/init',
        method: 'GET',
      }),
      async onCacheEntryAdded(arg, { cacheDataLoaded, dispatch, getCacheEntry }) {
        await cacheDataLoaded;
        const entries = getCacheEntry();
        dispatch(setTwitchToken(entries.data));
      },
    }),
    getAllStreamers: builder.query({
      query: ({ school, page, name,Organization }) => ({
        url: `/user/streamers`,
        method: 'GET',
        params: {
          school,
          page,
          name,
          Organization
        },
      }),
    }),
  }),
});

export const {
  usePrefetch,
  useLazyGetAllStreamersQuery,
  useGetAllStreamersQuery,
  useInitGamearenaQuery,
  useLazyInitGamearenaQuery,
} = gamearenaApiSlice;
