import { Link } from 'react-router-dom';
import { AvatarImage } from '../../../components/avatar-image';
export function OGMADashSchoolRow({ school, schoolId }: { school: string; schoolId: number }) {
  return (
    <Link className='p-2 mb-5 group hover:bg-gray-300 rounded-xl' to={`/schools/view/${schoolId}`}>
      <div className='flex justify-start pl-5'>
        <div className='flex gap-2 items-center'>
          <div>
            <AvatarImage source='' />
          </div>
          <div>
            <span className='break-all'>{school}</span>
          </div>
        </div>
      </div>
    </Link>
  );
}
