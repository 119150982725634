import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export function DraftEditor({
  text,
  handleSave,
}: {
  text?: string;
  handleSave?: (text: string) => void;
}) {
  const [value, setValue] = useState(text ?? '');
  const [hasChanged, setHasChanged] = useState(false);

  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        {
          color: ['red', 'blue', 'yellow'],
        },
      ],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      // ['link', 'image', 'video'],
      ['clean'],
    ],
    clipboard: {
      matchVisual: false,
    },
  };
  useEffect(() => {
    const findFirstDiff = (str1: string, str2: string) =>
      str2[[...str1].findIndex((el, index) => el !== str2[index])];
    if (text) {
      console.log(findFirstDiff(text, value));
      setHasChanged(text !== value);
    }
  }, [text, value]);

  const handleValueChange = (newValue: string) => {
    setValue(newValue);
    if (!hasChanged) {
      setHasChanged(true);
    }
  };

  return (
    <div className='flex flex-col gap-1'>
      {hasChanged && handleSave && (
        <div
          onClick={() => {
            handleSave(value);
            setHasChanged(false);
          }}
          className={`btn red self-end ${value == text ? 'bg-red-300' : ''}`}
        >
          Save
        </div>
      )}
      <ReactQuill modules={modules} theme='snow' value={value} onChange={handleValueChange} />
    </div>
  );
}
