import { Link, Navigate, useParams } from "react-router-dom";
import { OutsideClick } from "../../components/outside-click";
import { AiOutlineClose } from "react-icons/ai";
import { SubmitButton } from "../../components/submit-button";
import { useApproveTicketByAdminMutation, useDisapproveTicketByAdminMutation } from "../../slices/supportTicketApiSlice";

const ApproveOrDisapproveTicketAdmin = () => {
    const param = useParams();
    const [disapproveTicket, { isLoading: unResolvedLoading, isSuccess: ticketDisapprovedSuccess }] = useDisapproveTicketByAdminMutation();
    const [approveTicket, { isLoading: resolvedLoading, isSuccess: adminApproved }] = useApproveTicketByAdminMutation();

    const handleResolveSubmit = () => {
        approveTicket(param.id);
    };

    const handleUnResolveSubmit = () => {
        disapproveTicket(param.id);
    };

    if (ticketDisapprovedSuccess) {
        return <Navigate to="../" />;
    };

    if (adminApproved) {
        return <Navigate to="../" />;
    }

    return (
        <>
            <OutsideClick />
            <div className='fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-10 w-auto mx-auto max-w-2xl bg-white p-10 flex flex-col gap-10 items-center justify-center rounded-xl'>
                <div className='absolute top-2 right-2'>
                    <Link to='../' className=''>
                        <AiOutlineClose size={'2rem'} className='hover:rounded-full hover:bg-gray-100 p-2' />
                    </Link>
                </div>
                <div className='py-4 flex flex-col justify-center items-center gap-8'>
                    <p className='select-none font-medium'>Ticket validation confirmation?</p>
                    <div className='flex gap-2'>
                        <SubmitButton onSubmit={handleResolveSubmit} title='Approve' isLoading={resolvedLoading} />
                        <SubmitButton onSubmit={handleUnResolveSubmit} title='Disapprove' isLoading={unResolvedLoading} />
                    </div>
                </div>
            </div>
        </>
    )
};

export default ApproveOrDisapproveTicketAdmin;