import { Link, Navigate, useParams } from 'react-router-dom';
import { OutsideClick } from '../../../../components/outside-click';
import {
  useCompleteSeasonStageMutation,
  useGetCompleteSeasonStageInfoQuery,
} from '../../../../slices/seasonApiSlice';
import { AiOutlineClose } from 'react-icons/ai';
import { SubmitButton } from '../../../../components/submit-button';
import { CustomError } from '../../../../utils/custom-types';
import { Fragment, useEffect, useState } from 'react';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragStartEvent,
  useDraggable,
  useDroppable,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS as dndCSS } from '@dnd-kit/utilities';
import TableTd from '../../../../components/table-td/table-td';
import { SeasonTypes } from '../../../../utils/constants';
import TableContainer from '../../../../components/table-container/table-container';
import { TableHead } from '../../../../components/table-head';

const CompleteSeasonStage = () => {
  const param = useParams();
  const [standingsRankOrder, setStandingsRankOrder] = useState<any[]>([]);
  const {
    data,
    isLoading: dataIsLoading,
    isSuccess: dataIsSuccess,
  } = useGetCompleteSeasonStageInfoQuery(param.stageId);
  const [completeSeasonStage, { isLoading, isSuccess, isError, error }] =
    useCompleteSeasonStageMutation();

  useEffect(() => {
    if (dataIsSuccess && data) {
      const initialStandings = data?.flatMap((group: any) => group.standings);
      setStandingsRankOrder(initialStandings);
    }
  }, [data, isSuccess]);

  const handleDragOver = (event: any) => {
    const { active, over } = event;
    if (over) {
      const oldIndex = standingsRankOrder.findIndex((item) => item.id === active.id);
      const newIndex = standingsRankOrder.findIndex((item) => item.id === over.id);

      if (oldIndex !== newIndex) {
        setStandingsRankOrder((prevItems: any) => arrayMove(prevItems, oldIndex, newIndex));
      }
    }
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over) return;

    const oldIndex = standingsRankOrder.findIndex((standing) => standing.id === active.id);
    const newIndex = standingsRankOrder.findIndex((standing) => standing.id === over.id);

    if (oldIndex !== newIndex) {
      setStandingsRankOrder((prevstandingsRankOrder) =>
        arrayMove(prevstandingsRankOrder, oldIndex, newIndex),
      );
    }
  };

  function onSubmit() {
    completeSeasonStage({
      stageId: param.stageId,
      body: { standings: standingsRankOrder.map((standing) => standing.id) },
    });
  }

  const tableList = [
    'No #',
    'Team Name',
    standingsRankOrder?.[0]?.seasonType === SeasonTypes.leaderboard ? 'Points' : 'W-L',
    ...(standingsRankOrder?.[0]?.seasonType === SeasonTypes.leaderboard ? [] : ['Score']),
  ];
  if (isSuccess) return <Navigate to='../' />;
  return (
    <>
      <OutsideClick />
      <div className='fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-10 w-auto mx-auto bg-white p-10 flex flex-col gap-10 items-center justify-center rounded-xl'>
        <div className='absolute top-2 right-2'>
          <Link to='../' className=''>
            <AiOutlineClose size={'2rem'} className='hover:rounded-full hover:bg-gray-100 p-2' />
          </Link>
        </div>
        {data ? (
          <div className='py-4 flex w-fit flex-col justify-center items-center gap-8'>
            <p className='select-none font-medium text-center'>
              To complete this stage, resolve all ties and confirm the final standings.
            </p>
            <DndContext
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
              // onDragStart={handleDragStart}
              onDragOver={handleDragOver}
            >
              <SortableContext
                items={standingsRankOrder.map((standing) => standing.id)}
                strategy={verticalListSortingStrategy}
              >
                <TableContainer>
                  <TableHead tableList={tableList} />
                  <tbody className='bg-white divide-y divide-gray-200'>
                    {standingsRankOrder.map((standing: any, index: number) => {
                      return (
                        <Fragment key={standing.id}>
                          <SortableTeam
                            standing={standing}
                            rank={
                              standingsRankOrder.findIndex((s: any) => s.id === standing.id) + 1
                            }
                          />
                        </Fragment>
                      );
                    })}
                  </tbody>
                </TableContainer>
              </SortableContext>
            </DndContext>
            <div className='flex gap-2'>
              <SubmitButton onSubmit={onSubmit} title='Submit' isLoading={isLoading} />
              <Link
                to='../'
                className='p-4 px-8 text-center text-sm py-2 bg-white text-black border font-semibold rounded-lg hover:bg-gray-100'
              >
                Cancel
              </Link>
            </div>
          </div>
        ) : (
          <h3>You must select a week from the week drop down.</h3>
        )}
        {isError ? (
          <p>{(error as CustomError)?.message ?? (error as CustomError)?.data?.message}</p>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

const SortableTeam = ({ standing, rank }: { standing: any; rank: number }) => {
  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
    id: standing?.id,
  });
  const { isOver, setNodeRef: setDroppableRef } = useDroppable({
    id: standing?.id,
  });

  const ref = (node: any) => {
    setNodeRef(node);
    setDroppableRef(node);
  };

  const style = {
    transform: dndCSS.Transform.toString(transform),
    transition: isDragging ? 'none' : 'transform 200ms ease',
  };

  return (
    <tr
      ref={ref}
      key={standing?.id}
      className={`${isDragging ? '!opacity-60' : ''}`}
      style={style}
      {...listeners}
      {...attributes}
    >
      <TableTd>{`# ${rank} `}</TableTd>
      <TableTd extraClass='max-w-[300px] truncate'>{`${standing?.team.teamName} `}</TableTd>
      <TableTd>
        {standing?.seasonType === SeasonTypes.leaderboard
          ? standing?.points
          : `${standing?.wins}W - ${standing?.losses}L`}
      </TableTd>
      {standing?.seasonType !== SeasonTypes.leaderboard && (
        <TableTd>Score: {standing?.score}</TableTd>
      )}
    </tr>
  );
};
export default CompleteSeasonStage;
