import React from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import {
  useDeleteLessonMutation,
  useGetLessonByIdQuery,
} from '../../../../slices/classroomApiSlice';
import { OutsideClick } from '../../../../components/outside-click';
import { Form, Formik } from 'formik';
import { SubmitButton } from '../../../../components/submit-button';
import { CustomError } from '../../../../utils/custom-types';

const DeleteLesson = () => {
  const params = useParams();
  const {
    error,
    data: lessonData,
    isSuccess: lessonIsSuccess,
    isLoading: lessonIsLoading,
    isError: lessonIsError,
  } = useGetLessonByIdQuery(params.lId);
  const [deleteLesson, { data, isSuccess, isError, isLoading }] = useDeleteLessonMutation();
  const onSubmit = () => {
    deleteLesson(params.lId)
      .unwrap()
      .then((res) => console.log(res))
      .catch((e) => console.log(e));
  };
  if (lessonIsError || (lessonIsSuccess && !lessonData))
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError).data.message }} />;
  if (isSuccess) return <Navigate to='../' />;
  if (lessonIsLoading) return <p>Loading...</p>;
  return (
    <div className='fixed z-20 top-0 left-0 h-[100vh] w-[100vw] flex justify-center items-center'>
      <OutsideClick />
      <div className=' flex flex-col items-center gap-5 z-20 p-10 bg-white rounded-xl shadow-xl'>
        <p>
          Are you sure you want to delete <span className='font-bold'>{lessonData.name}</span>?
        </p>
        <Formik initialValues={{}} onSubmit={onSubmit}>
          <Form className='flex gap-2'>
            <SubmitButton title='Delete' isLoading={isLoading} />
            <Link
              to='../'
              className='px-8 text-center text-sm py-2 bg-white text-black border font-semibold rounded-lg hover:bg-gray-100'
            >
              No
            </Link>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default DeleteLesson;
