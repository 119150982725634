import React from 'react'
import { Link, Navigate, useParams } from 'react-router-dom';
import { useGetLessonByIdQuery, useUpdateLessonMutation } from '../../../../slices/classroomApiSlice';
import { OutsideClick } from '../../../../components/outside-click';
import { Form, Formik } from 'formik';
import { FormInput } from '../../../../components/form-input/form-input';
import { SubmitButton } from '../../../../components/submit-button';
import ModalHeader from '../../../../components/modal-header';
import ModalFooter from '../../../../components/modal-footer';
import { EditLessonSchema } from '../schema/edit-lesson-schema';


const EditLesson = () => {
    const params = useParams();

    const [
        updateLesson,
        {
            data: editLesson,
            isLoading: editIsLoadingLesson,
            isSuccess: editIsSuccessLesson
        }
    ] = useUpdateLessonMutation();


    const { data: lessonData, isLoading: lessonIsLoading, isSuccess: lessonIsSuccess, isError: lessonIsError } = useGetLessonByIdQuery(params.lId);


    const formInputs = [
        {
            mainTitle: 'Lesson Information',
            name: 'name',
            label: 'Name',
            type: 'text',
            placeholder: 'Enter classroom name',
            isTrue: true,
        },
        {
            mainTitle: 'Lesson Information',
            name: 'description',
            label: 'Description',
            type: 'description',
            placeholder: 'Start typing...',
            isTrue: true,
        },
    ]

    function handleSubmit(values: any) {
        const data = { ...values };

        delete data.content;
        delete data.classroom_id;
        delete data.id;
        console.log('lesson data = ', data);


        updateLesson({ id: params.lId, body: data })
            .unwrap()
            .then((res: any) => console.log(res))
            .catch((error: any) => console.log(error));
    }


    if (editIsSuccessLesson) return <Navigate to='../' />;
    if (lessonIsLoading) return <p>Loading...</p>
    const content = (
        <>
            <OutsideClick />
            <Formik
                validationSchema={EditLessonSchema}
                initialValues={{
                    ...lessonData
                }}
                onSubmit={handleSubmit}
                className='overflow-scroll'
            >
                {({ errors }) => {
                    return (
                        <Form>
                            <div className='fixed h-fit top-0 left-0 bottom-0 right-0 m-auto z-10 w-auto flex items-center justify-center  max-w-2xl'>
                                <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-[90vh]'>
                                    {/* <div className="p-6 overflow-y-auto max-h-[90vh]"> */}

                                    <ModalHeader name={`Edit Lesson - ${lessonData.name}`} />

                                    <div className="relative px-3 flex-auto overflow-auto">
                                        {formInputs.map((input: any, i: number) => (
                                            <React.Fragment key={i}>
                                                {!formInputs[i - 1] || formInputs[i - 1]?.mainTitle !== input.mainTitle ? (
                                                    <>
                                                        <div className='flex flex-col'>
                                                            <div className='mb-2'>
                                                                <p className='text-gray-800 font-bold pt-6'>{input.mainTitle}</p>
                                                                <hr />
                                                            </div>
                                                            <div className='flex flex-wrap gap-1'>
                                                                {formInputs
                                                                    .filter((inp1: any) => inp1.mainTitle === input.mainTitle)
                                                                    .map((inp: any, i: any) => (
                                                                        <React.Fragment key={i}>
                                                                            <div className={inp.isTrue ? 'w-full' : 'flex-1 min-w-[300px]'}>
                                                                                <FormInput {...inp} />
                                                                            </div>
                                                                        </React.Fragment>
                                                                    ))}
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </React.Fragment>
                                        ))}
                                    </div>
                                    <ModalFooter errors={Object.keys(errors).length} isLoading={editIsLoadingLesson!} />
                                </div>
                            </div>
                            {/* </div> */}
                        </Form>
                    );
                }}
            </Formik>
        </>
    )
    return content;
}

export default EditLesson