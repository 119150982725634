import React from 'react';
import { OutsideClick } from '../../../../components/outside-click';
import { Formik, FormikValues } from 'formik';
import ModalContent from '../../../../components/modal-content';
import {
  useEditAboutTextCoachMutation,
  useGetCoachByIdQuery,
} from '../../../../slices/userApiSlice';
import { Navigate, useParams } from 'react-router';
import { CustomError } from '../../../../utils/custom-types';

const EditAboutTextCoach = () => {
  const formInputs = [
    {
      mainTitle: 'Coach Information',
      name: 'about',
      label: 'About Text',
      type: 'textarea',
      placeholder: 'Edit about text',
      rows: '4',
    },
  ];
  const param = useParams();
  const {
    error,
    data: guData,
    isLoading: guLoading,
    isError: guIsError,
  } = useGetCoachByIdQuery(param.id!, { skip: !param.id });
  const [editAbout, { data, isLoading, isError, isSuccess }] = useEditAboutTextCoachMutation();
  function onSubmit(values: FormikValues) {
    editAbout({ id: Number(param.id), body: { about: values.about } });
  }
  if (guLoading) return <p>Loading...</p>;
  if (guIsError)
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError).data.message }} />;
  if (isSuccess) return <Navigate to='../' />;
  return (
    <>
      <OutsideClick />
      <Formik initialValues={{ about: guData?.about }} onSubmit={onSubmit}>
        <ModalContent formInputs={formInputs} isLoading={isLoading} name='Edit about text' />
      </Formik>
    </>
  );
};

export default EditAboutTextCoach;
