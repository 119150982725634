import { useGetAllMatchesByDateStatusQuery } from '../../../slices/matchApiSlice';
import MatchScheduleView from '../../schedule/routes/match-schedule/components/match-schedule-view';
import { MatchScheduleModel } from '../../../model/match-schedule.model';
import { DateStatus } from '../../../utils/constants';
import { Link } from 'react-router-dom';

export function StudentDashUpMatches({}) {
  const { data, isError, isSuccess, isLoading } = useGetAllMatchesByDateStatusQuery([
    {
      name: '',
      date: '',
      page: 1,
      limit: 3,
    },
    { dateStatus: DateStatus.Upcoming },
  ]);

  if (isLoading) return <p>Loading...</p>;

  return (
    <div className='flex flex-col p-5 border-1 rounded-xl border-slate-300 gap-5'>
      <h2 className='font-bold'>Your Upcoming Matches</h2>
      <div className='flex flex-col bg-white rounded-xl gap-4'>
        {data?.data?.length
          ? data.data.map((m: any, i: number) => (
              <MatchScheduleView fullDate key={i} scheduleModel={new MatchScheduleModel(m)} />
              // <StudentDashMatchRow key={i} model={new MatchModel(m)} />
            ))
          : 'There are no upcoming matches!'}
      </div>
      {data?.data?.length ? (
        <div className='flex justify-center'>
          <Link
            to='/schedule?matchType=upcoming'
            className='text-red-700 bold font-medium hover:text-red-500'
          >
            View more
          </Link>
        </div>
      ) : null}
    </div>
  );
}
