import { Link } from "react-router-dom";
import { AvatarImage } from "../../../components/avatar-image";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { PiImage } from "react-icons/pi";
import { Fragment } from "react";

interface IInGameName {
    in_game_name: string;
    discord_name: string;
};

const ViewStudentInfo = ({ username, studentId, image, gameName, inGameName }: { username: string, studentId: number, image: string, gameName: string, inGameName: Array<IInGameName> }) => {
    return (
        <div className="flex flex-col gap-5 p-4">
            <div className="flex gap-2 items-center">
                {image ? (
                    <AvatarImage source={image} extraClass="w-[60px] h-[60px]" />
                ) : (
                    <div className="bg-white flex items-center justify-center w-[60px] h-[60px] rounded-full">
                        <PiImage size={25} />
                    </div>
                )}
                <div className="flex items-center gap-1">
                    <h5 className="text-base text-white">Student:</h5>
                    <Link to={`/users/view/${studentId}/student`} className="flex items-center gap-2">
                        <span className="text-white text-base border-b-1 break-all">{username}</span>
                        <MdOutlineArrowForwardIos size={12} color="white" />
                    </Link>
                </div>
            </div>
            <hr />
            <div className="flex flex-col gap-2">
                <div className="flex items-center gap-1">
                    <h5 className="text-base text-white">Game:</h5>
                    <span className="text-white text-base">{gameName}</span>
                </div>
                {inGameName?.length ? inGameName?.map((d: IInGameName, i: number) => (
                    <Fragment key={i}>
                        {d?.in_game_name ? (
                            <div className="flex items-center gap-1">
                                <h5 className="text-base text-white">In-Game Name:</h5>
                                <span className="text-white text-base">{d?.in_game_name}</span>
                            </div>
                        ) : (
                            null
                        )}
                        {d?.discord_name ? (
                            <div className="flex items-center gap-1">
                                <h5 className="text-base text-white">Discord:</h5>
                                <span className="text-white text-base">{d?.discord_name}</span>
                            </div>
                        ) : (
                            null
                        )}
                    </Fragment>
                )) : (
                    null
                )}
            </div>
        </div>
    )
};

export default ViewStudentInfo;