import { dateAndTimeFormatter } from '../../utils/dateFormatter';
import {
  UserRoles,
  supportTicketStatusName,
  supportTicketStatusNameUser,
  userRoleNames,
} from '../../utils/constants';
import { wordWithFirstLetterToUpperCase } from '../../utils/utils';

export class ViewTicketModelUser {
  id?: number;
  title?: any;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  image?: any;
  role?: string;
  category?: string;
  subcategory?: string;
  status?: string;
  createdAt?: string | any;
  email?: string;
  priorityLevel?: number;
  f_createdAt?: string;
  ttrTime?: string;
  hours?: number;
  minutes?: number;
  seconds?: number;
  slaTime?: string;
  inProgress?: string;
  description?: string;

  constructor(data: any) {
    if (data?.hasOwnProperty('title')) this.title = data.title;
    if (data?.hasOwnProperty('user') && data.user && data.user.hasOwnProperty('firstName'))
      this.firstName = wordWithFirstLetterToUpperCase(data.user.firstName);
    if (data?.hasOwnProperty('user') && data.user && data.user.hasOwnProperty('lastName'))
      this.lastName = wordWithFirstLetterToUpperCase(data.user.lastName);
    if (this.firstName && this.lastName) this.fullName = `${this.firstName} ${this.lastName}`;
    if (data?.hasOwnProperty('user') && data.user && data.user.hasOwnProperty('roles'))
      this.role = userRoleNames[data.user.roles];
    if (data?.hasOwnProperty('createdAt')) {
      this.createdAt = data.createdAt;
      this.f_createdAt = dateAndTimeFormatter(this.createdAt).split(';')[0];
    }
    if (data?.hasOwnProperty('description')) {
      this.description = data.description;
    }
    if (
      data?.hasOwnProperty('subcategory') &&
      data.subcategory &&
      data?.subcategory?.hasOwnProperty('priorityLevel')
    ) {
      this.priorityLevel = data.subcategory.priorityLevel;
    }
    if (data?.hasOwnProperty('email')) this.email = data.email;
    if (data?.hasOwnProperty('status') && !data?.ticketTier) {
      switch (data?.status) {
        case 0:
          this.status = 'Not Started';
          break;
        case 1:
          this.status = 'Closed';
          break;
        case 2:
          this.status = 'Archived';
          break;
        case 3:
          this.status = 'In Progress';
          break;
        default:
          '';
      }
    }
    if (
      data?.hasOwnProperty('ticketTier') &&
      data?.hasOwnProperty('status') &&
      data?.ticketTier &&
      data?.status
    ) {
      switch (data?.status) {
        case 4:
          this.status = 'Not Started';
          break;
        case 5:
          this.status = 'In Progress';
          break;
        case 6:
          this.status = 'Monitoring';
          break;
      }
    }
    if (data?.hasOwnProperty('adminApproval') && data?.adminApproval && data?.ticketTier) {
      this.status = 'Resolved';
    }
    if (
      data?.hasOwnProperty('adminApproval') &&
      data?.adminApproval &&
      data?.hasOwnProperty('userApproval') &&
      data?.userApproval &&
      data?.ticketTier
    ) {
      this.status = 'Closed';
    }
  }
}
