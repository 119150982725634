import { apiSlice } from '../api/apiSlice';

export const supportCategoryApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createSupportCategory: builder.mutation({
      query: (body) => ({
        url: '/support-category/new',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['SupportCategory'],
    }),
    getAllSupportCategory: builder.query({
      query: (query) => ({
        url: '/support-category/all',
        method: 'GET',
        params: {
          ...query,
          page: query.page ?? 1,
          isArchived: query.isArchived ?? '',
        },
      }),
      providesTags: [
        'SupportCategory',
        'SupportSubCategory',
        'ArchiveCategory',
        'UnArchiveCategory',
      ],
    }),
    getSupportCategoryById: builder.query({
      query: (id) => ({
        url: `/support-category/${id}`,
        method: 'GET',
      }),
      providesTags: ['SupportCategory', 'SupportSubCategory'],
    }),
    updateSupportCategory: builder.mutation({
      query: ({ id, body }) => ({
        url: `/support-category/update/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['SupportCategory'],
    }),
    // deleteSupportCategoryById: builder.mutation({
    //   query: (id) => ({
    //     url: `/support-category/${id}`,
    //     method: 'DELETE',
    //   }),
    //   invalidatesTags: ['SupportCategory'],
    // }),
    archiveCategory: builder.mutation({
      query: (id) => ({
        url: `/support-category/archive/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['ArchiveCategory'],
    }),
    unarchiveCategory: builder.mutation({
      query: (id) => ({
        url: `/support-category/unArchive/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['UnArchiveCategory'],
    }),
  }),
});

export const {
  useCreateSupportCategoryMutation,
  useGetAllSupportCategoryQuery,
  useGetSupportCategoryByIdQuery,
  useUpdateSupportCategoryMutation,
  // useDeleteSupportCategoryByIdMutation,
  useArchiveCategoryMutation,
  useUnarchiveCategoryMutation,
} = supportCategoryApiSlice;
