export function OtherClassroomsTab({
    tab,
    setTab,
    title,
    id,
}: {
    tab: number;
    setTab: Function;
    title: string;
    id: number;
}) {
    return (
        <div
            className={`otherClassrooms--tab ${tab === id ? 'active' : ''}`}
            onClick={() => setTab(id)}
        >
            {title}
        </div>
    );
}