import { apiSlice } from '../api/apiSlice';

export const invoicesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    toggleDefaultStatus: builder.mutation({
      query: (params: { season_id: number; status: number }) => ({
        url: '/invoices/toggle-default-status',
        method: 'PUT',
        params,
      }),
      invalidatesTags: ['Invoices'],
    }),
    downloadInvoiceByID: builder.mutation({
      query: (id: number) => ({
        url: `/invoices/download/${id}`,
        method: 'GET',
        responseHandler: async (response) => {
          let pdfWindow;
          const text = await response.text();
          if (response.status === 200) {
            pdfWindow = window.open('', '', '');
            if (pdfWindow)
              pdfWindow.document.write(
                "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                  encodeURI(text) +
                  "'></iframe>",
              );
          }
          return { cache: 'no-cache' };
        },
      }),
    }),
    invoiceExportList: builder.mutation({
      query: (query: { [key: string]: string }) => ({
        url: `/invoices/export/${query.page ?? 1}`,
        method: 'GET',
        params: query,
        responseHandler: async (response) => {
          if (
            response.status === 200 &&
            response.headers.get('content-type')?.includes('text/csv')
          ) {
            window.open(
              window.URL.createObjectURL(await response.blob()),
              '_blank',
              'noopener,noreferrer',
            );
          } else {
            console.error('Unexpected content type or response status');
          }
        },
        cache: 'no-cache',
      }),
    }),

    getUnpaidInvoicesBySchoolId: builder.query({
      query: (id: number) => ({
        url: `/invoices/unpaid-school/${id}`,
        method: 'GET',
      }),
      providesTags: ['SchoolInvoices'],
    }),
    getInvoiceById: builder.query({
      query: (id) => ({
        url: `/invoices/by-id/${id}`,
        method: 'GET',
      }),
      providesTags: ['SingleInvoice'],
    }),
    getIndividualInvoices: builder.query({
      query: ({ id, params }: { id: number | string; params: any }) => ({
        url: `/invoices/student/${id}`,
        method: 'GET',
        params,
      }),
      providesTags: ['StudentInvoice'],
    }),
    getOgmaInvoices: builder.query({
      query: (body: { id: number; query?: string }) => ({
        url: `/invoices/ogma/${body.id}${body.query ? '?status=all' : ''}`,
        method: 'GET',
      }),
      providesTags: ['OgmaInvoice', 'School'],
    }),
    // getAllOrganizationInvoices: builder.query({

    // getAllOrganizationInvoices : builder.query({
    //   query: (query) => ({
    //     url: `/invoices/organization-invoices?${query.query}=${query.param}&page=${
    //       query.page ? query.page : 1
    //     }`,
    //     method: "Get",
    //   }),
    //   providesTags: ["Invoices"],
    // }),
    getAllOrganizationInvoices: builder.query({
      query: (query) => ({
        url: '/invoices/organization-invoices',
        method: 'Get',
        params: query,
      }),
      providesTags: ['Invoices'],
    }),
    // getAllStudentInvoices: builder.query({
    //   query: () => ({
    //     url: '/invoices/student-invoices',
    //     method: 'Get',
    //   }),
    //   providesTags: ['Invoices'],
    // }),
    createOrganizationInvoices: builder.mutation({
      query: (body) => ({
        url: '/invoices/new-organization-invoice',
        method: 'Post',
        body,
      }),
      invalidatesTags: ['Invoices'],
    }),
    getNewInvoiceHelpData: builder.query({
      query: () => ({
        url: '/invoices/new-organization-invoice',
        method: 'Get',
      }),
      providesTags: ['Organization', 'School'],
    }),
    createSchoolInvoiceModal: builder.mutation({
      query: (body) => ({
        url: '/invoices/new-school-invoice',
        method: 'Post',
        body,
      }),
      invalidatesTags: ['Invoices', 'SingleSchool', 'School', 'SchoolHours'],
    }),
    getAllStudentsInvoices: builder.query({
      query: (params) => ({
        url: '/invoices/all-student-invoices',
        method: 'Get',
        params,
      }),
      providesTags: ['Invoices'],
    }),
    createStudentInvoiceModal: builder.mutation({
      query: (body) => ({
        url: '/invoices/new-student-invoice',
        method: 'Post',
        body,
      }),
      invalidatesTags: ['Invoices'],
    }),
    getSchoolInvoiceById: builder.query({
      query: (id) => ({
        url: `/invoices/school/${id}`,
        method: 'Get',
      }),
      providesTags: ['Invoices'],
    }),
    // getInvoicesBySchoold: builder.query({
    //   query: (id) => ({
    //     url: `/invoices/school-invoices/${id}`,
    //     method: 'Get',
    //   }),
    //   providesTags: ['Invoices'],
    // }),
    getStudentInvoiceById: builder.query({
      query: (id) => ({
        url: `/invoices/students/${id}`,
        method: 'Get',
      }),
      providesTags: ['Invoices'],
    }),
    updateSchoolInvoice: builder.mutation({
      query: ({ id, body }) => ({
        url: `/invoices/update-school/${id}`,
        method: 'Put',
        body,
      }),
      invalidatesTags: ['Invoices', 'School'],
    }),
    updateStudentInvoice: builder.mutation({
      query: ({ id, body }) => ({
        url: `/invoices/update-student/${id}`,
        method: 'Put',
        body,
      }),
      invalidatesTags: ['Invoices'],
    }),
    getLatestInvoicesForAdminDashboard: builder.query({
      query: () => ({
        url: '/invoices/latest-invoices-for-admin',
        method: 'Get',
      }),
      providesTags: ['Invoices'],
    }),
  }),
});

export const {
  useGetUnpaidInvoicesBySchoolIdQuery,
  useGetInvoiceByIdQuery,
  useGetIndividualInvoicesQuery,
  useGetOgmaInvoicesQuery,
  useGetAllOrganizationInvoicesQuery,
  useUpdateStudentInvoiceMutation,
  useUpdateSchoolInvoiceMutation,
  useGetSchoolInvoiceByIdQuery,
  useGetStudentInvoiceByIdQuery,
  useCreateStudentInvoiceModalMutation,
  useGetAllStudentsInvoicesQuery,
  useCreateSchoolInvoiceModalMutation,
  // useGetAllStudentInvoicesQuery,
  useCreateOrganizationInvoicesMutation,
  useGetNewInvoiceHelpDataQuery,
  // useGetInvoicesBySchooldQuery,
  useInvoiceExportListMutation,
  useGetLatestInvoicesForAdminDashboardQuery,
  useDownloadInvoiceByIDMutation,
  useToggleDefaultStatusMutation,
} = invoicesApiSlice;
