import { SlArrowDown, SlArrowLeft, SlArrowUp } from 'react-icons/sl';
import { useSelector } from 'react-redux';
import { selectMatchChatDisputes } from '../../../slices/matchSlice';
import { LuAlertTriangle } from 'react-icons/lu';
import { selectUser } from '../../../slices/auth';
import { UserRoles } from '../../../utils/constants';
export function ShowChatButton({
  showChat,
  setShowChat,
}: {
  showChat: boolean;
  setShowChat: Function;
}) {
  const matchChatDisputes = useSelector(selectMatchChatDisputes);
  const user = useSelector(selectUser);
  return (
    <div
      onClick={() => setShowChat(!showChat)}
      className='select-none cursor-pointer flex items-center justify-center gap-2 p-1 mx-1 px-3 rounded border-2 border-black '
    >
      {showChat ? (
        <>
          <SlArrowUp /> Hide Chat
        </>
      ) : (
        <>
          <SlArrowDown /> Show Chat
        </>
      )}
      {user.roles === UserRoles.Admin && matchChatDisputes.length > 0 && (
        <LuAlertTriangle color='red' />
      )}
    </div>
  );
}
