import { useSelector } from 'react-redux';
import { selectUser } from '../../slices/auth';
import { tournamentPermissions } from './utils/tournament-permissions';
import { classroomPermissions } from './utils/classroom-permissions';
import { viewMatchPermissions } from './utils/view-match-permissions';
import { generalPermissions } from './utils/general-permissions';
import { seasonPermissions } from './utils/season-permissions';
import { teamPermissions } from './utils/teams-permissions';
import { schoolsPermissions } from './utils/schools-permissions';
import { coachPermissions } from './utils/coach-permissions';
import { supportPermissions } from './utils/support-permissions';
import { gamePermissions } from './utils/game-permissions';
import { matchStatusRules } from './utils/match-status-permissions';
import { settingsPermissions } from './utils/settings-permissions';

type Props = {
  component?: string;
  matchRules?: [string, number];
  children: JSX.Element;
};
const components: { [key: string]: number[] } = {
  ...generalPermissions,
  ...tournamentPermissions,
  ...classroomPermissions,
  ...viewMatchPermissions,
  ...seasonPermissions,
  ...teamPermissions,
  ...schoolsPermissions,
  ...coachPermissions,
  ...supportPermissions,
  ...gamePermissions,
  ...settingsPermissions,
};
const matchRules: Record<string, number[]> = {
  ...matchStatusRules,
};
const CanIView = (props: Props) => {
  const user = useSelector(selectUser);

  if (props.component)
    if (components.hasOwnProperty(props.component))
      if (!components[props.component].includes(user.roles)) return <></>;
  // else return props.children;

  if (props.matchRules) {
    if (!matchRules[props.matchRules[0]].includes(props.matchRules[1])) return <></>;
    // else return <></>;
  }

  if (props.matchRules && props.component)
    if (
      !components[props.component].includes(user.roles) &&
      !matchRules[props.matchRules[0]].includes(props.matchRules[1])
    )
      return <></>;
  return props.children;
};

export default CanIView;
