import * as yup from 'yup';
import { nameRulesWithSpecialCharacter, usernameRules } from '../../../../features/authentication/yupschema';
import { matchFormatNames } from '../../../../utils/constants';
export const newMatchFormatVSchema = yup.object().shape({
  name: yup
    .string()
    .required('Required!')
    .matches(
      usernameRules,
      'Invalid input. It should start with a letter and can only contain letters, numbers, and spaces.',
    ),
  type: yup.string().required('Required!'),
  value: yup.number().min(1).required('Required!'),
});
