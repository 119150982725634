import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { OutsideClick } from '../../../components/outside-click';

import { useGetAllGamesQuery } from '../../../slices/gameApiSlice';
import { useGetAllSchoolsQuery } from '../../../slices/schoolsApiSlice';
import { useCreateTeamMutation } from '../../../slices/teamsApiSlice';
import { NewTeamModal } from '../../authentication/yupschema';
import { FormInput } from '../../../components/form-input/form-input';
import ModalFooter from '../../../components/modal-footer';
import ModalHeader from '../../../components/modal-header';
import { Checkbox } from '../../../components/ui/checkbox/Checkbox';

import * as yup from 'yup';
import CanIView from '../../../components/can-i-view/can-i-view';
import { UserRoles } from '../../../utils/constants';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../slices/auth';
import { SchoolModel } from '../../../model/school/school-model';

const NewTeam = () => {
  const [createTeam, { error, isError, isLoading, isSuccess }] = useCreateTeamMutation();
  const { data: gameData, isLoading: gameIsLoading } = useGetAllGamesQuery('');
  const { data: schoolData, isLoading: schoolIsLoading } = useGetAllSchoolsQuery({ nolimit: 'ok' });
  const [coop, setCoop] = useState(false);
  const [chosenOrg, setChosenOrg] = useState<object[]>([]);
  const user = useSelector(selectUser);
  const handleCheckBox = () => {
    setCoop((s) => !s);
    setSchema((state) => {
      const { game, teamName } = state;
      if (!coop) return { game, teamName };
      return NewTeamModal;
    });
  };
  const onSubmit = (values: any) => {
    const data = values;
    data.game = +data.game;
    if (data.school) data.schoolId = +data.school;
    // delete data.school;
    delete data.accountType;
    data.organizations = chosenOrg;
    let body = { ...data };
    delete body.school;
    console.log('body', body);
    createTeam(body);
  };

  const formInputs = [
    {
      mainTitle: 'Game',
      name: 'game',
      label: 'Select a Game',
      type: 'text',
      placeholder: 'Select a game',
      isTrue: true,
      isSelect: true,
      options: gameData ? gameData : [],
    },
    {
      mainTitle: 'Team Information',
      name: 'teamName',
      label: 'Team Name',
      type: 'text',
      placeholder: 'Enter team name',
      isTrue: true,
    },
    {
      mainTitle: 'Team Information',
      name: 'school',
      label: 'School',
      type: 'text',
      placeholder: 'Select an option',
      isSelect: true,
      options: schoolData
        ? schoolData.map((s: any) => {
            const model = new SchoolModel(s);
            return { id: model.id, name: model.getOptionName() };
          })
        : [],
      noCoop: true,
    },
    {
      mainTitle: 'Team Information',
      name: 'organizations',
      label: 'Organizations',
      type: 'text',
      placeholder: 'Enter organizations',
      isTrue: true,
      search: 'organization',
      onlyCoop: true,
      chosenOrg,
      setChosenOrg,
    },
  ];

  useEffect(() => {
    if (user.roles === UserRoles.Ogma) setCoop(false);
  }, []);
  const initialValues: { [key: string]: string } = {};
  formInputs.forEach((input) => (initialValues[input.name] = ''));
  const [schema, setSchema] = useState<{ [key: string]: any }>(NewTeamModal);
  if (gameIsLoading || schoolIsLoading) return <p>Loading...</p>;
  if (isSuccess) return <Navigate to='../' />;

  return (
    <>
      <OutsideClick />
      <div className='fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-10 w-full md:w-[40vw] mx-auto max-w-2xl'>
        {/*content*/}
        <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none h-full'>
          <ModalHeader name={'New Team'} />
          <div className='relative px-3 flex-auto overflow-auto'>
            <CanIView component='coopTeam'>
              <div className='py-4' onClick={() => handleCheckBox()}>
                <div className='flex items-center gap-1.5 hover:bg-slate-50 cursor-pointer'>
                  <Checkbox
                    checked={coop}
                    // onClick={() => handleCheckBox()}
                  />
                  <label htmlFor={'invite'} className='flex-1 cursor-pointer'>
                    CO-OP Teams
                  </label>
                </div>

                <hr />
              </div>
            </CanIView>
            <Formik
              validationSchema={yup.object().shape(schema)}
              initialValues={initialValues}
              onSubmit={onSubmit}
              enableReinitialize
            >
              <Form>
                <div className='flex flex-col gap-2'>
                  {/* {invIsError && <p>{getError(invError)?.data?.message}</p>} */}
                  {formInputs.map((input, i: number) => (
                    <React.Fragment key={input.name}>
                      {!formInputs[i - 1] || formInputs[i - 1]?.mainTitle !== input.mainTitle ? (
                        <>
                          <div className='flex flex-col'>
                            <div className='mb-2'>
                              <p className='text-gray-800 font-bold pt-6'>{input.mainTitle}</p>

                              <hr />
                            </div>
                            <div className='flex flex-wrap gap-1'>
                              {formInputs
                                .filter((inp1) => inp1.mainTitle === input.mainTitle)

                                .filter((inp) => (coop ? !inp.noCoop : !inp.onlyCoop))
                                .map((inp) => (
                                  <React.Fragment key={inp.name}>
                                    <div
                                      className={inp.isTrue ? 'w-full ' : 'flex-1 min-w-[300px]'}
                                    >
                                      <FormInput {...inp} />
                                    </div>
                                  </React.Fragment>
                                ))}
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </React.Fragment>
                  ))}
                </div>
                <ModalFooter error={error} isLoading={isLoading} />
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewTeam;
