import { Navigate, useParams } from 'react-router';
import ModalArchive from '../../../components/modal-archive';
import { useGetGameByIdQuery, useUpdateGameMutation } from '../../../slices/gameApiSlice';
import { CustomError } from '../../../utils/custom-types';
import { useEffect, useState } from 'react';
import { GameModel } from '../../../model/game.model';
import { GameIsArchived } from '../../../utils/constants';

const ArchiveGame = () => {
  function handleSubmit() {
    updateGame({ id: param.id ?? '', isArchived: GameIsArchived.Yes });
  }

  const param = useParams();

  const { data, isLoading, isError, isSuccess, error } = useGetGameByIdQuery(param.id ?? '');

  const [
    updateGame,
    { isLoading: upIsLoading, isSuccess: upIsSuccess, isError: upIsError, error: upError },
  ] = useUpdateGameMutation();

  const [model, setModel] = useState<GameModel>();

  useEffect(() => {
    if (!data) return;
    setModel(new GameModel(data));
  }, [data]);

  if (isError)
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError).data.message }} />;
  if (isLoading) return <></>;
  if (upIsSuccess) return <Navigate to='../' />;
  return <ModalArchive onSubmit={handleSubmit} isLoading={upIsLoading} name={model?.name ?? ''} />;
};

export default ArchiveGame;
