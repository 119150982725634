import { TournamentOptionContainer } from './components/tournament-option-container';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import CanIView from '../../components/can-i-view/can-i-view';
import { SponsorPlacements } from '../../utils/constants';
import { SponsorRow } from '../../components/sponsor-row/sponsor-row';

function Tournaments() {
  const navigate = useNavigate();
  // useEffect(() => {
  //   navigate('./all');
  // }, []);
  return (
    <div className='listViewContainer fullPage'>
      <CanIView component='tournament.optionContainer'>
        <TournamentOptionContainer />
      </CanIView>
      <SponsorRow name={SponsorPlacements.Tournament} />
      <Outlet />
    </div>
  );
}

export default Tournaments;
