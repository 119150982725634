import { Navigate, useParams } from 'react-router-dom';
import { useArchiveUserMutation, useGetUserByIdQuery } from '../../slices/userApiSlice';
import ModalArchive from '../../components/modal-archive';
import { CustomError } from '../../utils/custom-types';

function ArchiveUser() {
  const param = useParams();
  const [archiveUser, { isLoading, isError, isSuccess }] = useArchiveUserMutation();
  const {
    error,
    data: userData,
    isSuccess: userIsSuccess,
    isLoading:userIsLoading,
    isError: userIsError,
  } = useGetUserByIdQuery(param.id);
  const onSubmit = () => {
    archiveUser(param.id)
      .unwrap()
      .then((res) => console.log(res))
      .catch((e) => console.log(e));
  };
  if(userIsLoading)
  return <p>Loading...</p>
  if (userIsError || (userIsSuccess && !userData))
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError)?.data?.message }} />;
  if (isSuccess) return <Navigate to='../' />;
  return <ModalArchive onSubmit={onSubmit} isLoading={isLoading} name={userData?.firstName} />;
}

export default ArchiveUser;
