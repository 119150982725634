import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { selectUser } from '../../../slices/auth';
import { useViewSingleSeasonQuery } from '../../../slices/seasonApiSlice';
import CanIView from '../../../components/can-i-view/can-i-view';
import { SeasonProgressStatus } from '../../../utils/constants';
import { Button } from '../../../components/ui/button/Button';
import { toast } from 'react-toastify';
import { HiMiniArrowSmallRight } from 'react-icons/hi2';
import { HiMiniArrowSmallLeft } from 'react-icons/hi2';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../../../components/ui/tooltip/Tooltip';

interface ViewSeasonStageProps {
  setSeasonStageId: (stage: number) => void;
  seasonStageId: number | undefined;
}

export function ViewSeasonStage({ setSeasonStageId, seasonStageId }: ViewSeasonStageProps) {
  const param = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const user = useSelector(selectUser);
  const { data: seasonData, isLoading } = useViewSingleSeasonQuery(param.id, { skip: !param.id });
  const [currentIndex, setCurrentIndex] = useState(0);
  const [canCompleteStage, setCanCompleteStage] = useState(false);
  const stagesPerPage = 2;
  const totalStages = seasonData?.SeasonStage?.length || 0;

  const visibleStages = seasonData?.SeasonStage.slice(currentIndex, currentIndex + stagesPerPage);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - stagesPerPage, 0));
  };
  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + stagesPerPage, totalStages - stagesPerPage),
    );
  };

  useEffect(() => {
    if (
      searchParams.get('week') &&
      seasonData?.SeasonWeek.find(
        (seasonWeek: any) => seasonWeek.id === Number(searchParams.get('week')),
      )
    ) {
      setSeasonStageId(
        seasonData?.SeasonWeek?.find((week: any) => week.id === Number(searchParams.get('week')))
          .season_stage_id,
      );
      return;
    }

    const today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    let currentStageId: any = null;
    if (seasonData?.SeasonStage?.length) {
      for (let i = 0; i < seasonData.SeasonStage.length; i++) {
        const stageStart = new Date(seasonData.SeasonStage[i].start_time);
        const stageStartDate = new Date(
          stageStart.getFullYear(),
          stageStart.getMonth(),
          stageStart.getDate(),
        );

        if (today < stageStartDate) {
          if (i === 0) {
            currentStageId = seasonData.SeasonStage[0].id;
          } else {
            currentStageId = seasonData.SeasonStage[i - 1].id;
          }
          break;
        }
      }
      if (!currentStageId) {
        currentStageId = seasonData.SeasonStage[seasonData.SeasonStage.length - 1].id;
      }
      setSeasonStageId(currentStageId);
    }

    const index =
      seasonData?.SeasonStage.findIndex((stage: { id: number }) => stage.id === currentStageId) -
      (stagesPerPage - 2);
    setCurrentIndex(
      index < 0 ? 0 : index === seasonData.SeasonStage.length - 1 ? index - 1 : index,
    );
  }, [seasonData]);

  useEffect(() => {
    if (seasonData?.SeasonStage.length && seasonStageId) {
      const lastStage =
        seasonData?.SeasonStage[
          seasonData?.SeasonStage.findIndex((stage: { id: number }) => stage.id === seasonStageId) -
            1
        ];
      (!lastStage || lastStage?.status === SeasonProgressStatus.Completed) &&
      seasonData?.SeasonStage[
        seasonData?.SeasonStage.findIndex((stage: { id: number }) => stage.id === seasonStageId)
      ]?.status !== SeasonProgressStatus.Completed
        ? setCanCompleteStage(true)
        : setCanCompleteStage(false);
    }
  }, [seasonData, seasonStageId]);

  return (
    <div className='flex justify-between p-2'>
      <div className='flex gap-5 items-center font-bold text-xs'>
        {totalStages > stagesPerPage && (
          <Button onClick={handlePrev} disabled={currentIndex === 0}>
            <HiMiniArrowSmallLeft />
          </Button>
        )}
        {visibleStages.map((stage: any) => {
          const today = new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate(),
          );
          const stageStart = new Date(stage.start_time);
          const stageEnd = new Date(stage.end_time);
          const isActive = today >= stageStart && today <= stageEnd;
          return (
            <Button
              key={stage.id}
              variant='outline'
              onClick={() => {
                setSeasonStageId(stage.id);
              }}
              className={`group filterBtn flex ${
                stage?.status === SeasonProgressStatus.Completed ? 'bg-green-300' : ''
              } ${stage.id === seasonStageId ? 'bg-gray-300 border-2 border-fen-blue' : ''}
               ${
                 stage.id === seasonStageId && stage?.status === SeasonProgressStatus.Completed
                   ? 'bg-gray-300 border-2 border-green-500'
                   : ''
               }`}
            >
              <div>
                <h5>{`${stage.name} ${isActive ? '(active stage)' : ''}`}</h5>
                <h6>
                  {new Date(stage.start_time).toLocaleDateString('en-US', {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric',
                  })}{' '}
                  -{' '}
                  {new Date(stage.end_time).toLocaleDateString('en-US', {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric',
                  })}
                </h6>
              </div>
            </Button>
          );
        })}
        {totalStages > stagesPerPage && (
          <Button onClick={handleNext} disabled={currentIndex + stagesPerPage >= totalStages}>
            <HiMiniArrowSmallRight />
          </Button>
        )}
        {seasonData?.status !== SeasonProgressStatus.Completed ? (
          <CanIView component='season.view.generateStage'>
            <Link
              to='./new-stage'
              className='bg-gray-600 text-white p-3 rounded-lg cursor-pointer p-2'
            >
              ADD STAGE +
            </Link>
          </CanIView>
        ) : (
          <></>
        )}
      </div>
      <div className='flex items-center gap-2'>
        <CanIView component='season.view.editStage'>
          {canCompleteStage ? (
            <Link
              onClick={(e) => {
                if (!seasonStageId) {
                  e.preventDefault();
                  toast.error('Please select a stage to edit.', {
                    position: 'top-right',
                  });
                }
              }}
              to={`./complete-stage/${seasonStageId}`}
              className='p-2 flex justify-center text-center items-center bg-fen-blue text-white rounded-lg'
            >
              COMPLETE STAGE
            </Link>
          ) : (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <div
                    className='p-2 flex justify-center text-center items-center bg-fen-blue opacity-70 brightness-75 text-white rounded-lg'
                    onClick={(e) => e.preventDefault()}
                  >
                    COMPLETE STAGE
                  </div>
                </TooltipTrigger>
                {seasonData?.SeasonStage[
                  seasonData?.SeasonStage.findIndex(
                    (stage: { id: number }) => stage.id === seasonStageId,
                  )
                ]?.status === SeasonProgressStatus.Completed ? (
                  <TooltipContent>Stage already completed.</TooltipContent>
                ) : (
                  <TooltipContent>You must complete the previous stage.</TooltipContent>
                )}
              </Tooltip>
            </TooltipProvider>
          )}
        </CanIView>
        <CanIView component='season.view.editStage'>
          <Link
            onClick={(e) => {
              if (!seasonStageId) {
                e.preventDefault();
                toast.error('Please select a stage to edit.', {
                  position: 'top-right',
                });
              }
            }}
            to={`./edit-stage/${seasonStageId}`}
            className='p-2 flex justify-center text-center items-center bg-gray-600 text-white rounded-lg'
          >
            EDIT STAGE +
          </Link>
        </CanIView>
        <CanIView component='season.view.deleteStage'>
          <Link
            onClick={(e) => {
              if (!seasonStageId) {
                e.preventDefault();
                toast.error('Please select a stage to delete.', {
                  position: 'top-right',
                });
              }
            }}
            to={`./delete-stage/${seasonStageId}`}
            className='p-2 flex justify-center text-center items-center main-bg-color text-white rounded-lg'
          >
            DELETE STAGE
          </Link>
        </CanIView>
      </div>
    </div>
  );
}
