import React from 'react';
import { AvatarImage } from '../../../components/avatar-image';
export function TournamentOverviewSponsorCard({
  sponsor,
  image = '',
}: {
  sponsor: string;
  image?: string;
}) {
  return (
    <div className='flex items-center flex-col w-[calc(100%/4)] py-4 text-center justify-around    text-black'>
      <AvatarImage contain size='xxxl' source={image} />
      {/* {sponsor} */}
    </div>
  );
}
