import React, { useState } from 'react';
import { useUpdateUserSettingsMutation } from '../../slices/userApiSlice';
export function Dropdown({
  optionsList,
  setOption,
  option,
}: {
  optionsList: string[];
  setOption: Function;
  option: number;
}) {
  const [updateUserSettings, { isLoading }] = useUpdateUserSettingsMutation();
  const [open, setOpen] = useState(false);
  return (
    <div className='relative inline-block text-left'>
      <div>
        <button
          onClick={() => setOpen((s) => !s)}
          type='button'
          className='inline-flex w-full justify-start rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-bold text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100
                focus:bg-indigo-50'
          id='menu-button'
          aria-expanded='true'
          aria-haspopup='true'
        >
          {optionsList[option]}
          <svg
            className='-mr-1 ml-2 h-5 w-5'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 20 20'
            fill='currentColor'
            aria-hidden='true'
          >
            <path
              fillRule='evenodd'
              d='M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z'
              clipRule='evenodd'
            />
          </svg>
        </button>
      </div>
      {open ? (
        <div
          className='absolute right-0 z-10 mt-2 w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
          role='menu'
          aria-orientation='vertical'
          aria-labelledby='menu-button'
          tabIndex={-1}
        >
          <div className='py-1' role='none'>
            {optionsList.map((o, i) => (
              <a
                key={i}
                href='#'
                className='text-gray-700 block px-4 py-2 text-sm'
                role='menuitem'
                tabIndex={-1}
                id='menu-item-0'
                onClick={() => {
                  setOption(i);
                  setOpen(false);
                  updateUserSettings({ frequency: i });
                }}
              >
                {o}
              </a>
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
