import { Navigate } from 'react-router';
import useRoleAuthorization from '../hooks/use-role-authorization';
import { useSelector } from 'react-redux';
import { selectUser } from '../slices/auth';

const Dashboard = ({ role }: { role?: string }) => {
  const user = useSelector(selectUser);
  const [isAuthorized, { redirect, userRole }] = useRoleAuthorization([0, 1, 2, 3, 4, 5]);
  if (role) return <Navigate to={`./${role}/${user?.id}`} />;
  if (userRole === 'none') return <p>Loading...</p>;
  return redirect;
};

export default Dashboard;
