import * as yup from 'yup';
import {
  cityRules,
  emailRules,
  emailRulesHint, nameRulesHint,
  nameRulesWithSpecialCharacter,
  usPhoneRules,
  usPhoneRulesHint,
  zipCodeRules,
  zipCodeRulesHint
} from '../../../authentication/yupschema';
import moment from 'moment';
import { ErrorMessages } from '../../../../utils/messages.enum';

export const UpdateAccountSettingsSchema = yup.object().shape({
  city: yup.string().required('Required!').matches(cityRules, ErrorMessages.CityValidation),
  state: yup
    .string()
    .required('Required!')
    .matches(/^[a-zA-Z\s]+$/, 'State name must only contain letters and spaces'),
  zipCode: yup.string().matches(zipCodeRules, zipCodeRulesHint).required('Required!'),
  gender: yup.string().required('Required'),
  phoneNumber: yup.string().matches(usPhoneRules, usPhoneRulesHint).required('Required!'),
});

export const UpdateAccountSettingsSchemaStudent = yup.object().shape({
  firstName: yup.string().matches(nameRulesWithSpecialCharacter, nameRulesHint).required('Required!'),
  lastName: yup.string().matches(nameRulesWithSpecialCharacter, nameRulesHint).required('Required!'),
  userName: yup.string(),
  email: yup.string().matches(emailRules, emailRulesHint).required('Required!'),
  gender: yup.string().required('Required'),
});

export const UpdateAccountSettingsSchemaOgma = yup.object().shape({
  firstName: yup.string().matches(nameRulesWithSpecialCharacter, nameRulesHint).required('Required!'),
  lastName: yup.string().matches(nameRulesWithSpecialCharacter, nameRulesHint).required('Required!'),
  userName: yup.string(),
  email: yup.string().matches(emailRules, emailRulesHint).required('Required!'),
  city: yup.string().required('Required!').matches(cityRules, ErrorMessages.CityValidation),
  state: yup
    .string()
    .required('Required!')
    .matches(/^[a-zA-Z\s]+$/, 'State name must only contain letters and spaces'),
  zipCode: yup
    .string()
    .matches(/^[0-9]+$/, 'Zip code must only contain numbers')
    .matches(zipCodeRules, zipCodeRulesHint)
    .required('Required!'),
  gender: yup.string().required('Required!'),
  phoneNumber: yup.string().matches(usPhoneRules, usPhoneRulesHint).required('Required!'),
  officeNumber: yup.string().nullable().matches(usPhoneRules, usPhoneRulesHint),
  dateOfBirth: yup
    .date()
    .min(
      new Date(new Date().getFullYear() - 100, 0, 1),
      `User must be born on or after January 1, ${new Date(
        new Date().getFullYear() - 100,
        0,
        1,
      ).getFullYear()}`,
    )
    .max(moment().endOf('day').subtract(11, 'years'), 'You must be at least 11 years old!'),
  streetAddress: yup
    .string()
    .matches(/^[0-9a-zA-Z\s]+$/, 'Street address must only contain letters, spaces and numbers'),
});

export const UpdateAccountSettingsSchemaGuardian = yup.object().shape({
  firstName: yup.string().matches(nameRulesWithSpecialCharacter, nameRulesHint).required('Required!'),
  lastName: yup.string().matches(nameRulesWithSpecialCharacter, nameRulesHint).required('Required!'),
  userName: yup.string(),
  email: yup.string().matches(emailRules, emailRulesHint).required('Required!'),
  city: yup.string().required('Required!').matches(cityRules, ErrorMessages.CityValidation),
  state: yup
    .string()
    .required('Required!')
    .matches(/^[a-zA-Z\s]+$/, 'State name must only contain letters and spaces'),
  zipCode: yup
    .string()
    .matches(/^[0-9]+$/, 'Zip code must only contain numbers')
    .matches(zipCodeRules, zipCodeRulesHint)
    .required('Required!'),
  gender: yup.string().required('Required!'),
  phoneNumber: yup.string().matches(usPhoneRules, usPhoneRulesHint),
});

export const UpdateAccountSettingsSchemaCoach = yup.object().shape({
  firstName: yup.string().matches(nameRulesWithSpecialCharacter, nameRulesHint),
  lastName: yup.string().matches(nameRulesWithSpecialCharacter, nameRulesHint),
  userName: yup.string(),
  email: yup.string().matches(emailRules, emailRulesHint),
  city: yup.string().required('Required!').matches(cityRules, ErrorMessages.CityValidation),
  state: yup
    .string()
    .required('Required!')
    .matches(/^[a-zA-Z\s]+$/, 'State name must only contain letters and spaces'),
  zipCode: yup
    .string()
    .matches(/^[0-9]+$/, 'Zip code must only contain numbers')
    .matches(zipCodeRules, zipCodeRulesHint)
    .required('Required!'),
  gender: yup.string().required('Required!'),
  phoneNumber: yup.string().matches(usPhoneRules, usPhoneRulesHint).required('Required!'),
});
