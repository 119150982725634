import React from 'react';
import useRoleAuthorization from '../../hooks/use-role-authorization';
import { UserRoles } from '../../utils/constants';

const SubscriptionRedirect = () => {
  const [isAuthorized, { redirect, userRole }] = useRoleAuthorization([
    UserRoles.Ogma,
    UserRoles.Guardian,
  ]);

  if (userRole === 'none') return <p>Loading...</p>;
  return redirect;
};

export default SubscriptionRedirect;
