import React, { useState } from 'react';
import { BsChevronRight } from 'react-icons/bs';
import { Button } from '../../components/ui/button/Button';
import { Separator } from '../../components/ui/separator/Separator';
import { useNavigate } from 'react-router';
import { DeletedUserModel } from '../../model/deleted-user.model';
import TableContainer from '../../components/table-container/table-container';
import { TableHead } from '../../components/table-head';
import TableTd from '../../components/table-td/table-td';
import { useGetDeletedUsersQuery } from '../../slices/userApiSlice';

const RecentlyDeletedUsers = () => {
  const navigate = useNavigate();
  const { data, error, isLoading } = useGetDeletedUsersQuery({ limit: 3 });

  const handleShowMore = () => {
    navigate('/deleted-users');
  };

  const configureListView = [
    {
      name: 'First Name',
      active: true,
      property: 'firstName',
    },
    {
      name: 'Last Name',
      active: true,
      property: 'lastName',
    },
    {
      name: 'Date',
      active: true,
      property: 'formatedDeletedOnDate',
    },
  ];

  const [listView, setListView] = useState(configureListView);
  if (isLoading) return <h1>Data loading</h1>;
  return (
    <>
      <div className='flex flex-col gap-10 border-1 rounded-xl'>
        <h1 className='text-2xl ml-6 mt-6 font-bold'>Recently Deleted Users</h1>
        <Separator />
        <div className='grid grid-cols-3  mx-5'>
          <section className='col-span-3 bg-white rounded-lg mt-3 '>
            <div className='flex  flex-col'>
              <div className='-my-2 overflow-x-auto '>
                <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
                  <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg'>
                    <TableContainer>
                      <TableHead tableList={listView.map((l) => l.name)} />
                      <tbody className='bg-white divide-y divide-gray-200'>
                        {data &&
                          data?.map((person: any, i: number) => {
                            const model: { [key: string]: any } & DeletedUserModel =
                              new DeletedUserModel(person);
                            return (
                              <React.Fragment key={i}>
                                <tr key={i}>
                                  {listView.map((p) => {
                                    return <TableTd key={p.property}>{model[p.property]}</TableTd>;
                                  })}
                                </tr>
                              </React.Fragment>
                            );
                          })}
                      </tbody>
                    </TableContainer>
                  </div>
                </div>
              </div>
            </div>
            <div className='mt-10 mb-5  max-h-[1000px] px-10'>
              <div className='mt-1 grid grid-cols-3 items-center text-gray-400'>
                <hr className='border-gray-200' />
                <div style={{ gridTemplateRows: '1fr 0.5fr 1fr' }}>
                  <div className='flex items-center justify-center'>
                    <Button size='sm' variant='ghost' onClick={() => handleShowMore()}>
                      <p className='text-rose-700 font-semibold'>Go to</p>{' '}
                      <BsChevronRight className='ml-4 text-rose-700 stroke-1' />
                    </Button>
                  </div>
                </div>
                <hr className='border-gray-300' />
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default RecentlyDeletedUsers;
