import React from 'react';
import { Navigate, useParams } from 'react-router';
import ModalArchive from '../../../components/modal-archive';
import {
  useArchiveTicketMutation,
  useGetTicketByIdQuery,
} from '../../../slices/supportTicketApiSlice';

const ArchiveTicket = () => {
  const param = useParams();
  const { data: ticket } = useGetTicketByIdQuery(param.id);
  const [archiveTicket, { isSuccess, isLoading }] = useArchiveTicketMutation();

  const onSubmit = () => {
    archiveTicket(param.id)
      .unwrap()
      .then((resp) => console.log('ticket archived successfully', resp))
      .catch((err) => console.log('ticket couldnt be archived', err));
  };

  if (isSuccess) {
    return <Navigate to='../' />;
  }

  return <ModalArchive onSubmit={onSubmit} isLoading={isLoading} name={ticket?.title} />;
};

export default ArchiveTicket;
