import React, { SetStateAction, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
export function OutsideClick({
  setFunc,
  noBg,
  isError,
  setHasError,
  zIndex
}: {
  noBg?: boolean;
  setFunc?: Function;
  isError?: boolean;
  setHasError?: React.Dispatch<SetStateAction<boolean>>;
  zIndex?: string;
}) {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (!show) setTimeout(() => setShow(true), 150);
  }, []);
  if (isError)
    return (
      <div
        className={`${
          noBg
            ? ''
            : `backdrop-blur-sm bg-black transition duration-500 ${
                show ? 'bg-opacity-60' : 'bg-opacity-0'
              }`
        } flex fixed inset-0 z-10 top-0 left-0 right-0 bottom-0 
      `}
        onClick={() => (setHasError ? setHasError(false) : null)}
      ></div>
    );
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  return (
    <div
      className={`${
        noBg
          ? ''
          : `backdrop-blur-sm bg-black transition duration-500 ${
              show ? 'bg-opacity-60' : 'bg-opacity-0'
            }`
      } flex fixed inset-0 ${zIndex ?? 'z-10'} top-0 left-0 right-0 bottom-0 
      `}
      onClick={() => (setFunc ? setFunc(false) : navigate(`../?${queryParams.toString()}`))}
    ></div>
  );
}
