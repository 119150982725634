export class StudentSubscriptionCardModel {
  invoiceStatus: { [key: string]: string } = {
    0: 'Upcoming',
    1: 'Paid',
    2: 'Overdue',
    3: 'Unpaid',
  };
  id: number = 0;
  season: string = '';
  status: number = 0;
  status_name: string = '';
  total: number = 0;
  season_id: number = 0;
  square_payment_id: string = '';
  coupons: any[] = [];
  constructor(invoice: any) {
    if (invoice.hasOwnProperty('id')) this.id = invoice.id;
    if (invoice?.season.hasOwnProperty('name')) this.season = invoice.season.name;
    if (invoice.hasOwnProperty('status')) this.status = invoice.status;
    if (invoice.hasOwnProperty('total')) this.total = invoice.total;
    if (invoice?.season.hasOwnProperty('id')) this.season_id = invoice.season.id;
    if (this.status) this.status_name = this.invoiceStatus[this.status];
    if (invoice.hasOwnProperty('sq_payment_id')) this.square_payment_id = invoice.sq_payment_id;
    if (invoice.hasOwnProperty('coupons')) this.coupons = invoice.coupons;
  }
}
