import React from 'react';
import { useSelector } from 'react-redux';
import { selectUser, setUserIsStreaming } from '../../../slices/auth';
import { useStreamingUpdateMutation } from '../../../slices/matchApiSlice';
import { useParams } from 'react-router';
import { useAppDispatch } from '../../../store';
export function StreamingUpdateButton() {
  const dispatch = useAppDispatch();
  const param = useParams();
  const user = useSelector(selectUser);
  const [streamingUpdate, { isLoading: stIsLoading }] = useStreamingUpdateMutation();
  function handleStreamUpdate(status: number) {
    streamingUpdate({ status, matchId: param.id })
      .unwrap()
      .then((res) => dispatch(setUserIsStreaming(res.is_streaming)))
      .catch((e) => console.log(e));
  }
  return (
    <div
      onClick={() => handleStreamUpdate(user?.is_streaming ? 0 : 1)}
      className={`bg-red-600 cursor-pointer rounded-2xl flex items-center justify-center px-4 p-1 text-xs gap-1 text-white ${
        user?.is_streaming ? 'live-animation' : '' // user?.is_streaming ? "live-animation" : ""
      }`}
    >
      {user?.is_streaming ? 'STOP STREAMING' : 'GO LIVE'}{' '}
    </div>
  );
}
