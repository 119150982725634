import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { OutsideClick } from '../../components/outside-click';
import useFormContext from '../../hooks/useFormContext';
import { selectUser } from '../../slices/auth';
import { useGetSchoolsByOrgIdQuery } from '../../slices/schoolsApiSlice';
import { useGenerateSchoolInviteLinkMutation } from '../../slices/schoolsApiSlice';
import Combobox from '../../components/combobox/combobox';
import { SchoolModel } from '../../model/school/school-model';
import { FaCopy } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

type SchoolInviteProps = {
  selSchool: number | undefined;
};

const SchoolInvite = (props: SchoolInviteProps) => {
  const [inviteLink, setInviteLink] = useState('');
  const [timeRemaining, setTimeRemaining] = useState(-1);

  useEffect(() => {
    if (inviteLink) {
      const token = inviteLink.split('/').pop() ?? '';
      const [, payloadBase64] = token.split('.');
      const decodedPayload = JSON.parse(atob(payloadBase64));
      const issuedAt = decodedPayload.iat * 1000;
      const expiration = decodedPayload.exp * 1000;

      const duration = expiration - issuedAt;

      const updateTimeRemaining = () => {
        const signedDate = new Date(decodedPayload.signedDate).getTime();
        const expirationTime = signedDate + duration;

        const currentTime = Date.now();
        const timeLeft = expirationTime - currentTime;
        setTimeRemaining(timeLeft > 0 ? timeLeft : 0);
      };

      updateTimeRemaining();
      const interval = setInterval(updateTimeRemaining, 1000);

      return () => clearInterval(interval);
    }
  }, [inviteLink]);

  const formatTimeRemaining = (milliseconds: number) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const days = Math.floor(totalSeconds / (3600 * 24));
    const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    const parts = [];
    if (days > 0) parts.push(`${days}d`);
    if (hours > 0) parts.push(`${hours}h`);
    if (minutes > 0) parts.push(`${minutes}m`);
    if (seconds > 0) parts.push(`${seconds}s`);

    return parts.join(' ');
  };

  const [
    generateSchoolInviteLink,
    {
      data: schoolInviteData,
      isLoading: schoolInviteIsLoading,
      isSuccess: schoolInviteIsSuccess,
      isError: schoolInviteIsError,
    },
  ] = useGenerateSchoolInviteLinkMutation();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (props.selSchool) {
      generateSchoolInviteLink({
        schoolId: props.selSchool ?? 0,
        invalidateOldLinks: schData.find((school: { id: number }) => school.id === props.selSchool)
          .jwt_signed_time
          ? false
          : true,
      }).then(() => {
        refetchSchData();
      });
    }
  }, [props.selSchool, generateSchoolInviteLink]);

  useEffect(() => {
    if (schoolInviteData?.url) {
      setInviteLink(schoolInviteData.url);
    }
  }, [schoolInviteData]);

  useEffect(() => {
    if (location.state?.url) {
      setInviteLink(location.state.url);
    }
  }, [location.state]);

  function handleGenerateNewLink() {
    if (isDisabled) return;
    navigate(`./new-school-link/${props.selSchool}`);
  }

  const user = useSelector(selectUser);
  const {
    data: schData,
    isLoading: schIsLoading,
    isError: schIsError,
    refetch: refetchSchData,
  } = useGetSchoolsByOrgIdQuery(user?.organizations[0]?.id);
  const isDisabled = !props.selSchool || schoolInviteIsLoading;
  if (schIsLoading) return <p>Loading...</p>;
  return (
    <>
      <div className='flex items-center border border-gray-300 rounded-lg bg-gray-200'>
        <input
          value={inviteLink}
          className='rounded-lg bg-gray-200 p-2 focus:border-blue-500 focus:bg-gray-400 focus:outline-none w-full'
          name='Invite Link'
          readOnly={true}
        />
        <div
          onClick={async () => {
            navigator.clipboard
              .writeText(inviteLink)
              .then(() => {
                toast.success('URL copied to clipboard', {
                  position: toast.POSITION.TOP_CENTER,
                });
              })
              .catch((error) => {
                toast.error('Failed to copy to clipboard', {
                  position: toast.POSITION.TOP_CENTER,
                });
              });
          }}
          className={`button select-none w-20 self-center cursor-pointer hover:bg-opacity-80 whitespace-nowrap h-10 bg-[#33a1fd]`}
        >
          <FaCopy size={'20'} />
        </div>
      </div>
      {timeRemaining !== -1 && (
        <div className='flex justify-center'>
          <p className='text-red'>
            Link will expire in:{' '}
            <span className='text-red-500'>{formatTimeRemaining(timeRemaining)}</span>
          </p>
        </div>
      )}
      <div className='flex justify-center'>
        <div
          onClick={handleGenerateNewLink}
          className={`button select-none w-fit self-center cursor-pointer hover:bg-opacity-80 whitespace-nowrap ${
            isDisabled ? 'disabled' : ''
          }`}
        >
          Generate New Link
        </div>
      </div>
    </>
  );
};

export default SchoolInvite;
