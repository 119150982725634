import React, { useEffect, useState } from 'react';
import { OutsideClick } from '../../../components/outside-click';
import { Navigate, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../slices/auth';
import {
  useGetSchoolsScheduledHoursQuery,
  useSendBookCoachHoursRequestMutation,
} from '../../../slices/classroomApiSlice';
import { timeFormatter } from '../../../utils/dateFormatter';
import { calculateScheduledHourLimits } from '../../../utils/utils';
import { Form, Formik, FormikValues } from 'formik';
import { FormInput } from '../../../components/form-input/form-input';
import { StudentBookingHoursSchema } from './schema/student-booking-hours.schema';
import { Error } from '../../../components/ui/error/Error';
import ModalFooter from '../../../components/modal-footer';
import ModalHeader from '../../../components/modal-header';
import { ErrorMessages } from '../../../utils/messages.enum';

const StudentBookHours = () => {
  const [startLimit, setStartLimit] = useState<Date>();
  const [endLimit, setEndLimit] = useState<Date>();
  const param = useParams();
  const user = useSelector(selectUser);
  const { data, isLoading, isError, isSuccess } = useGetSchoolsScheduledHoursQuery({
    school_id: Number(user.school_id),
    classroom_id: Number(param.bookId),
  });
  useEffect(() => {
    if (!data) return;
    if (!Object.keys(data).length) return;
    // const [startDate, endDate] =
    const [start, end] = calculateScheduledHourLimits(data.start_date, data.end_date) ?? [];
    setStartLimit(start);
    setEndLimit(end);
  }, [data]);
  const formInputs = [
    {
      mainTitle: 'Time Information',
      name: 'start_time',
      label: 'Start Time',
      type: 'datetime-local',
      placeholder: 'Starting Time',
    },
    {
      mainTitle: 'Time Information',
      name: 'end_time',
      label: 'End Time',
      type: 'datetime-local',
      placeholder: 'End Time',
    },
  ];
  const [
    sendBookCoachHoursRequest,
    { isLoading: reqLoading, isSuccess: reqSuccess, isError: reqError },
  ] = useSendBookCoachHoursRequestMutation();
  function handleSubmit(values: FormikValues) {
    sendBookCoachHoursRequest({
      classroom_id: Number(param.bookId),
      start_time: new Date(values.start_time).toISOString(),
      end_time: new Date(values.end_time).toISOString(),
    });
  }

  if (isLoading || (data.length && (!startLimit || !endLimit))) return <p>Loading...</p>;

  if (reqSuccess) {
    return <Navigate to='../' />;
  }

  return (
    <>
      <OutsideClick />
      <div className='fixed  top-0 left-0 right-0 z-10 w-auto my-6 mx-auto max-w-3xl h-fit max-h-[100vh] '>
        <div className='border-0  rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none h-fit overflow-auto gap-2'>
          <ModalHeader name='Book Classroom Hours' />
          <h2 className='px-10'>Make a request to book classroom hours!</h2>

          {Object.keys(data).length ? (
            <>
              <p className='px-10'>
                You can book from <b>{timeFormatter(data.start_date, 'new')} </b>
                to <b>{timeFormatter(data.end_date, 'new')} </b>
              </p>
              {startLimit && endLimit ? (
                <Formik
                  validationSchema={StudentBookingHoursSchema}
                  // initialValues={initialValues}
                  initialValues={{ startLimit, endLimit }}
                  onSubmit={handleSubmit}
                >
                  {({ values, setValues }) => {
                    // console.log(formik);

                    return (
                      <Form>
                        <div className='relative p-6 px-10 flex-auto'>
                          {formInputs.map((input, i: number) => (
                            <React.Fragment key={i}>
                              {!formInputs[i - 1] ||
                              formInputs[i - 1]?.mainTitle !== input.mainTitle ? (
                                <>
                                  <div className='flex flex-col'>
                                    <div className='flex flex-wrap gap-1'>
                                      {formInputs
                                        .filter((inp1) => inp1.mainTitle === input.mainTitle)
                                        .map((inp: any, i: any) => (
                                          <React.Fragment key={i}>
                                            <div
                                              className={
                                                inp.isTrue ? 'w-full ' : 'flex-1 min-w-[300px]'
                                              }
                                            >
                                              <FormInput {...inp} />
                                            </div>
                                          </React.Fragment>
                                        ))}
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </React.Fragment>
                          ))}
                          {reqError && <p className='text-red-700'>Something went wrong!</p>}
                          {reqSuccess && (
                            <p className='text-green-700'>Request sent Successfully</p>
                          )}
                        </div>
                        <ModalFooter isLoading={reqLoading} />
                        {/* <div className='flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b'>
                          <Link
                            className='w-1/5 my-12 text-center py-1 bg-white text-black shadow-lg font-semibold rounded-lg'
                            to='../'
                          >
                            Cancel
                          </Link>
                          <SubmitButton isLoading={reqLoading} />
                        </div> */}
                      </Form>
                    );
                  }}
                </Formik>
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              <Error title={ErrorMessages.NoBookHoursSchedule} hideButton />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default StudentBookHours;
