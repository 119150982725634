import { Link, Navigate, useParams } from "react-router-dom";
import { OutsideClick } from "../../components/outside-click";
import { AiOutlineClose } from "react-icons/ai";
import { SubmitButton } from "../../components/submit-button";
import { useApproveTicketByAdminMutation } from "../../slices/supportTicketApiSlice";

const ApproveTicketAdmin = () => {
    const param = useParams();
    const [approveTicket, { isLoading: ticketApprovedLoading, isSuccess: ticketApprovedSuccess }] = useApproveTicketByAdminMutation();

    const handleSubmit = () => {
        approveTicket(param.id);
    };

    if (ticketApprovedSuccess) {
        return <Navigate to="../" />;
    };

    return (
        <>
            <OutsideClick />
            <div className='fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-10 w-auto mx-auto max-w-2xl bg-white p-10 flex flex-col gap-10 items-center justify-center rounded-xl'>
                <div className='absolute top-2 right-2'>
                    <Link to='../' className=''>
                        <AiOutlineClose size={'2rem'} className='hover:rounded-full hover:bg-gray-100 p-2' />
                    </Link>
                </div>
                <div className='py-4 flex flex-col justify-center items-center gap-8'>
                    <p className='select-none font-medium'>Are you sure that the ticket has been resolved?</p>
                    <div className='flex gap-2'>
                        <SubmitButton onSubmit={handleSubmit} title='Yes' isLoading={ticketApprovedLoading} />
                        <Link
                            to='../'
                            className='p-4 px-8 text-center text-sm py-2 bg-white text-black border font-semibold rounded-lg hover:bg-gray-100'
                        >
                            Cancel
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
};

export default ApproveTicketAdmin;