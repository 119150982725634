import { useDraggable, useDroppable } from '@dnd-kit/core';
import React, { useState } from 'react';
import { AiFillStar } from 'react-icons/ai';
import { AvatarImage } from '../../../components/avatar-image';
import { BsFillPatchCheckFill } from 'react-icons/bs';
import { useNavigate } from 'react-router';
import ViewStudentInfo from './view-student-info';

export function MatchMemberRow({
  id = '',
  editMode = false,
  isCaptain = false,
  gameId,
  image = '',
  username = '',
  firstName = '',
  activeMember = false,
  InGameName = '',
  isCheckedIn = false,
  studentId = null,
  gameName = '',
}: any) {
  const { isOver, setNodeRef: setDropNode } = useDroppable({ id: 'h' + id });
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: 'h' + id,
    disabled: !editMode,
  });

  const navigate = useNavigate();

  const [showHover, setShowHover] = useState<boolean>(false);
  let timeOutId: ReturnType<typeof setTimeout> = setTimeout(() => { });

  const handleMouseEnter = () => {
    timeOutId = setTimeout(() => setShowHover(true), 500);
  };

  const handleMouseLeave = () => {
    setShowHover(false);
    clearTimeout(timeOutId);
  };

  if (activeMember) {
    return (
      <div
        className="flex relative group"
        onMouseEnter={() => studentId ? handleMouseEnter() : false}
        onMouseLeave={() => studentId ? handleMouseLeave() : false}
      >
        <div
          className={`flex border-1 items-center w-[200px] sm:w-full xl:w-[250px] gap-2  p-2 bg-gray-100 rounded-lg ${isOver ? '!bg-green-300' : ''
            } ${editMode ? 'border-dashed bg-white border-green-500 ' : ''} ${studentId ? 'hover:bg-slate-200 cursor-pointer' : ''}`}
          ref={setDropNode}
        // onClick={() => studentId ? navigate(`/users/view/${studentId}/student`) : null}
        >
          <AvatarImage source={image} />
          <div className='flex flex-col'>
            <h2 className='font-bold text-[13px] leading-tight whitespace-nowrap'>{username}</h2>
            <p className='text-[13px] leading-tight'>
              {InGameName?.length
                ? InGameName?.find((ig: any) => ig.game_id === gameId && ig.user_id === id)
                  ?.in_game_name
                : ''}
            </p>
          </div>
          {isCaptain ? (
            <span className='border-2 rounded-full border-orange-400'>
              <AiFillStar color='orange' />
            </span>
          ) : (
            <span></span>
          )}
          {isCheckedIn ? (
            <span>
              <BsFillPatchCheckFill color='#3e8ede' />
            </span>
          ) : (
            <span></span>
          )}
        </div>
        {showHover ? (
          <div className='hidden w-[300px] backdrop-blur-[3px] rounded-lg bg-[#4A4A4ACC] group-hover:block absolute top-1/2 transform -translate-y-1/2 h-[225px] max-h-[230px] overflow-y-auto z-50 opacity-85'>
            <ViewStudentInfo
              studentId={studentId}
              username={username}
              gameName={gameName}
              image={image}
              inGameName={InGameName}
            />
          </div>
        ) : null}
      </div>
    );
  }

  const style = transform
    ? {
      transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
    }
    : undefined;

  return (
    <div
      className={`flex items-center w-[200px] sm:w-full xl:w-[250px] gap-2 p-2 bg-gray-100 rounded-lg ${isOver ? 'bg-green-300' : ''
        }`}
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
    >
      <AvatarImage source={image} />
      <div className='flex flex-col'>
        <h2 className='font-bold text-[13px] leading-tight whitespace-nowrap'>{username}</h2>
        <p className='text-[13px] leading-tight'>
          {InGameName?.length ? (
            InGameName?.find((ig: any) => ig.game_id === gameId && ig.user_id === id)?.in_game_name
          ) : (
            <></>
          )}
        </p>
      </div>
      {isCaptain ? (
        <span className='border-2 rounded-full border-orange-400'>
          <AiFillStar color='orange' />
        </span>
      ) : (
        <span></span>
      )}
      {isCheckedIn ? (
        <span>
          <BsFillPatchCheckFill color='#3e8ede' />
        </span>
      ) : (
        <span></span>
      )}
    </div>
  );
}
