// import { useGetSupportTicketsQuery } from '../../../slices/supportApiSlice';

export function SupportTickets({}) {
  // const { data, isLoading } = useGetSupportTicketsQuery('');
  // if (isLoading) return <p>Loading...</p>;
  return (
    <div className='flex flex-col'>
      <h2 className='text-xl font-semibold mb-2'>Support Desk</h2>
      <h2 className='h-[1px] w-full bg-gray-200 rounded'></h2>
      <div className='flex w-full overflow-auto gap-2'>
        {/* {data &&
          data.map((d: any, i: number) => (
            <SupportTicketCard key={i} model={new SupportTicketModel(d)} />
          ))} */}
      </div>
    </div>
  );
}
