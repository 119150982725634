import { useEffect } from 'react';
import { Navigate, Outlet, useNavigate, useParams } from 'react-router';
import { useGetGuardianByIdQuery } from '../../../slices/userApiSlice';
import { UserHeader } from '../../../components/user-header';
import { NavLink } from 'react-router-dom';
import { CustomError } from '../../../utils/custom-types';

const ViewGuardian = () => {
  const param = useParams();
  const navigate = useNavigate();
  const {
    error,
    data: guardian,
    isLoading,
    isError,
  } = useGetGuardianByIdQuery(param.id, { skip: !param.id });

  const buttonList = [
    // {
    //     name: 'Overview',
    //     link: './overview',
    //     permission: 'overview',
    // },
    {
      name: 'My Students',
      link: './overview',
      permission: 'students',
    },
  ];

  useEffect(() => {
    navigate('./overview');
  }, []);

  if (isLoading) return <p>Loading...</p>;
  if (isError)
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError).data.message }} />;
  return (
    <div>
      <UserHeader
        name={guardian.firstName}
        lastName={guardian.lastName}
        image={guardian.image}
        id={guardian.id}
        username={guardian.username}
        noStudent
      />
      <div className='flex p-2 bg-white'>
        {buttonList.map((b) => (
          <NavLink to={b.link} key={b.link} className='settingsLink border-b-2'>
            <div> {b.name} &nbsp;</div>
          </NavLink>
        ))}
      </div>
      <Outlet />
    </div>
  );
};

export default ViewGuardian;
