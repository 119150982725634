import { useState } from 'react';
import { Link, Navigate, useSearchParams } from 'react-router-dom';
import { AvatarImage } from '../../../../components/avatar-image';
import { useGetAllStreamersQuery } from '../../../../slices/gamearenaApiSlice';
import { Search } from '../../../../components/search/search';
import {
  useDeleteFavoriteStreamerMutation,
  useSaveFavoriteStreamerMutation,
} from '../../../../slices/userApiSlice';
import {
  addFavoriteStreamer,
  removeFavoriteStreamer,
  selectRole,
  selectUser,
} from '../../../../slices/auth';
import { useDispatch, useSelector } from 'react-redux';
import { SponsorRow } from '../../../../components/sponsor-row/sponsor-row';
import { SponsorPlacements } from '../../../../utils/constants';
import Pagination from '../../../../components/pagination';

const GamearenaBrowse = () => {
  const user = useSelector(selectUser);
  const role = useSelector(selectRole);

  // const [
  //   getAllStreamers,
  //   { data: stData, isLoading: stIsLoading, isSuccess: stIsSuccess },
  // ] = useLazyGetAllStreamersQuery();
  // useEffect(() => {
  //   const timeout = setTimeout(
  //     () =>
  //       getAllStreamers({
  //         username: searchParams.get("Players"),
  //         page: searchParams.get("page"),
  //       }),
  //     1000
  //   );
  //   return () => clearTimeout(timeout);
  // }, [searchParams.get("Players")]);
  const filterList = [
    {
      title: 'name',
      property: 'name',
    },
    {
      title: 'school',
      property: 'school',
    },
    {
      title: 'Organization',
      property: 'Organization',
    },
  ];
  const [search, setSearch] = useState({ by: 'name', keyword: '' });
  const [searchQuery, setSearchQuery] = useState(filterList[0].title);

  const [searchParams, setSearchParams] = useSearchParams();
  const {
    isError,
    isLoading,
    isSuccess,
    data: stData,
  } = useGetAllStreamersQuery({
    page: searchParams.get('page') ? searchParams.get('page') : 1,
    school: searchParams.get('school') ? searchParams.get('school') : '',
    name: searchParams.get('name') ? searchParams.get('name') : '',
    Organization: searchParams.get('Organization') ? searchParams.get('Organization') : '',
  });

  const [
    saveFavoriteStreamer,
    { isError: fStreamerIsError, isLoading: fStreamerIsLoading, isSuccess: fStreamerIsSuccess },
  ] = useSaveFavoriteStreamerMutation();
  const [
    deleteFavoriteStreamer,
    { isError: dStreamerIsError, isLoading: dStreamerIsLoading, isSuccess: dStreamerIsSuccess },
  ] = useDeleteFavoriteStreamerMutation();

  function goPrevPage() {
    setSearchParams({ page: `${stData?.meta?.prev}` });
  }
  function goNextPage() {
    setSearchParams({ page: `${stData?.meta?.next}` });
  }

  const dispatch = useDispatch();
  const addAsFavorite = (twitchUsername: string, userId: number, image: string) => {
    const fStreamer = {
      userId: userId,
      username: twitchUsername,
      image: image,
    };

    const fS = JSON.stringify(fStreamer);
    const body = {
      favoriteStreamer: fS,
    };

    saveFavoriteStreamer(body)
      .unwrap()
      .then((res) => dispatch(addFavoriteStreamer(fS)));
  };

  const deleteAsFavorite = (userId: number) => {
    const favoriteStreamers = user.favoriteStreamers.filter(
      (fs: any) => userId !== JSON.parse(fs).userId,
    );

    console.log('favoritestreamer', favoriteStreamers);
    const body = {
      favoriteStreamer: '',
      favoriteStreamerArr: favoriteStreamers,
    };

    deleteFavoriteStreamer(body)
      .unwrap()
      .then((res) => dispatch(removeFavoriteStreamer(favoriteStreamers)));
  };
  //allow GameArena routes
  if (role !== 0) {
    return <Navigate to='../dashboard' />;
  }
  if (isLoading) return <p>Loading ...</p>;
  // if (fStreamerIsLoading)
  //   return <p>Loading ...</p>
  // if (dStreamerIsLoading)
  //   return <p>Loading ...</p>
  return (
    <>
      <SponsorRow name={SponsorPlacements.GamearenaBrowse} />
      <div className='listViewContainer h-[calc(100vh-85px)] p-0 gap-y-11 relative overflow-auto flex flex-col'>
        <div className='flex flex-col justify-between gap-3'>
          <div className=' flex flex-col h-[calc(95vh-85px)] gap-2'>
            <div className='flex w-full  p-8 justify-between items-center'>
              <h2 className='flex-1'></h2>
              <h2 className='text-2xl font-semibold tracking-tight'>Browse Streamers</h2>

              <div className='flex flex-1 justify-end'>
                <Search
                  setQuery={setSearchQuery}
                  filterList={filterList}
                  search={search}
                  setSearch={setSearch}
                />
              </div>
            </div>
            <div className='flex w-full justify-start items-start p-5'>
              {isLoading ? (
                <p>Loading...</p>
              ) : stData?.data?.length ? (
                <>
                  <div className='flex w-full justify-around flex-wrap p-3 gap-10 items-center'>
                    {stData.data.map((u: any) => (
                      <div key={u.id} className='flex flex-col gap-y-1 items-center'>
                        {/* <div className="absolute right-0 top-0 ">

                      </div> */}
                        <Link to={`../gamearena/streamer/${u.id}`}>
                          <AvatarImage source={u.image} size='xxxl' />
                          <p className='text-xl font-bold text-center'>{u.firstName}</p>
                          <p className=' text-center'>{u.twitch_login}</p>
                        </Link>
                        {/* {JSON.stringify(user.favoriteStreamers)}
                      {u.id} */}
                        {user.favoriteStreamers.filter((fs: any) => JSON.parse(fs).userId == u.id)
                          .length ? (
                          <button
                            onClick={() => deleteAsFavorite(u.id)}
                            className='px-4 py-2 bg-red-700 text-white min-w-[100px] hover:bg-red-800 rounded-full'
                          >
                            Unfollow
                          </button>
                        ) : (
                          <button
                            onClick={() => addAsFavorite(u.twitch_login, u.id, u.image)}
                            className='px-4 py-2 bg-red-700 text-white min-w-[100px] hover:bg-red-800 rounded-full'
                          >
                            Follow
                          </button>
                        )}
                      </div>
                    ))}
                  </div>
                </>
              ) : isSuccess ? (
                <p>No user found!</p>
              ) : (
                <p>Empty</p>
              )}
            </div>
          </div>
          <Pagination length={stData?.data?.length} {...stData?.meta} />
          {/* <div className='flex justify-center items-center'>
            <div className='flex gap-2 items-center'>
              <span>
                {stData?.meta?.currentPage} of {stData?.meta?.lastPage}
              </span>
              <div
                className='cursor-pointer'
                onClick={stData?.meta?.prev ? goPrevPage : () => null}
              >
                <BsArrowLeft color={stData?.meta?.prev ? 'black' : 'grey'} />
              </div>
              <div
                className='cursor-pointer'
                onClick={stData?.meta?.next ? goNextPage : () => null}
              >
                <BsArrowRight color={stData?.meta?.next ? 'black' : 'grey'} />
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default GamearenaBrowse;
