import { useEffect, useState } from 'react';
import { BsFillGearFill } from 'react-icons/bs';
import { FiUpload } from 'react-icons/fi';
import 'react-dropdown/style.css';
import DropdownItem from './DropDown/DropDownItem';
import { Link, Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import { TableHead } from '../../components/table-head';
import { NameAndAvatar } from '../../components/name-and-avatar';
import { Search } from '../../components/search/search';
import TableContainer from '../../components/table-container/table-container';
import {
  useGetAllOrganizationsQuery,
  useOrgExportListMutation,
} from '../../slices/organizationApiSlice';
import { DropdownListView } from './users/components/dropdown-list-view';
import TableTd from '../../components/table-td/table-td';
import {
  OrganizationStatus,
  UserRoles,
  ProgramTitles,
  ManualTagPrefix,
} from '../../utils/constants';
import { Separator } from '../../components/ui/separator/Separator';
import { HeaderButton } from '../../components/header-button';
import { useSelector } from 'react-redux';
import Pagination from '../../components/pagination';
import { selectUser } from '../../slices/auth';
import { OrganizationModel } from '../../model/organization.model';
import { LoadingSceletonUsers } from '../../routes/tournaments/routes/all/loading-sceleton-users';
import { useSearchGameByNameMutation } from '../../slices/gameApiSlice';
import { appendAutoTags } from '../../utils/utils';

const options = ['one', 'two', 'three'];

const Organizations = () => {
  const [downloadList, { isLoading: isDownloading }] = useOrgExportListMutation();

  const [searchParams, setSearchParams] = useSearchParams();

  const initialOpenListState = {
    id: null,

    y: null,

    x: null,
  };

  const [openList, setOpenList] = useState<{ [key: string]: number | null }>(initialOpenListState);

  const [openListView, setOpenListView] = useState(false);

  const navigate = useNavigate();

  const user = useSelector(selectUser);

  const [searchGameByName, { data: gameData, isLoading: gameIsLoading, isError: gameIsError }] =
    useSearchGameByNameMutation();

  const {
    data: orgData,

    isLoading,

    error,
  } = useGetAllOrganizationsQuery(Object.fromEntries(searchParams.entries()));

  useEffect(() => {
    searchGameByName(ProgramTitles.DroneRacing);
  }, [searchGameByName]);

  function handleClick(prop: { id: number; x: number; y: number }) {
    setOpenListView(false);

    if (openList.id == prop.id) {
      setOpenList(initialOpenListState);
    } else {
      setOpenList(prop);
    }
  }

  function handleListView() {
    console.log('hello');

    setOpenList(initialOpenListState);

    setOpenListView(!openListView);
  }

  const tableHeadList = [
    'name',

    'phone',

    'schools',

    'created',

    'number of students',

    'email',

    'payment status',

    'status',
  ];

  const filterList = [
    { title: 'name', property: 'name' },
    { title: 'email', property: 'email' },
    { title: 'city', property: 'city' },
  ];

  const [searchQuery, setSearchQuery] = useState(filterList[0].title);

  const [search, setSearch] = useState({ by: 'name', keyword: '' });

  const configureListView = [
    {
      name: 'Name',

      active: true,

      property: 'name',
    },

    {
      name: 'City',

      active: true,

      property: 'city',
    },
    {
      name: 'State',

      active: true,

      property: 'state',
    },

    {
      name: 'Phone number',

      active: true,

      property: 'phone_number',
    },

    {
      name: 'Email Address',

      active: true,

      property: 'email',
    },

    {
      name: 'Tags',

      active: true,

      property: 'tags',
    },

    {
      name: 'Status',

      active: true,

      property: 'status',
    },
  ];

  const [listView, setListView] = useState(configureListView);

  function setStatus(status: number) {
    searchParams.set('status', status.toString());

    setSearchParams(searchParams);
  }

  const formatPhoneNumber = (phoneNumberString: string) => {
    var cleaned = phoneNumberString.replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return match[1] + '-' + match[2] + '-' + match[3];
    }
    return phoneNumberString;
  };

  if (isLoading) return <LoadingSceletonUsers />;

  return (
    <div className='listViewContainer fullPage overflow-auto flex flex-col border-1 rounded-xl'>
      <div className='flex w-full justify-between'>
        <div className='flex items-center justify-between'>
          <div className='space-y-1'>
            <h2 className='text-2xl font-semibold tracking-tight'>Organizations</h2>
          </div>
        </div>

        <div className='flex gap-5 justify-end content-center items-center'>
          {/*  mt-8 mb-6 ml-6 lg:px-12 */}

          <button
            onClick={() => downloadList(Object.fromEntries(searchParams.entries()))}
            className='filterBtn flex'
          >
            <div className='self-center pr-3'>
              <FiUpload />
            </div>
            Export list
          </button>

          <div className='relative inline-block text-left group'>
            <div onClick={handleListView}>
              <button
                type='button'
                className='filterBtn flex'
                id='menu-button'
                aria-expanded='true'
                aria-haspopup='true'
              >
                <div className='self-center pr-3'>
                  <BsFillGearFill />
                </div>
                Configure List View
              </button>
            </div>

            <div className='z-10 hidden group-hover:block'>
              <div
                id='dropdown'
                className='absolute z-10 mt-0 w-80 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
                role='menu'
                aria-orientation='vertical'
                aria-labelledby='menu-button'
              >
                <div className='py-1' role='none'>
                  <DropdownListView
                    state={listView}
                    configureListView={configureListView}
                    setFunc={setListView}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* <Link to='./new'> */}
          <HeaderButton type='newOrganization' />
          {/* </Link> */}
        </div>
      </div>

      <Separator className='my-4' />

      <div className='flex justify-between '>
        {/* mt-8 mb-6 lg:px-8 */}

        <div className='filterContainer'>
          {/* {buttonList.map((b) => (
 
            <button key={b} className="filterBtn">
 
              {b}
 
            </button>
 
          ))} */}

          <button onClick={() => setSearchParams()} className='filterBtn'>
            Clear Filter
          </button>

          <div className='filterBtn relative '>
            Status
            {searchParams.get('status')
              ? Number(searchParams.get('status')) === OrganizationStatus.Active
                ? ' - Active'
                : ' - Disabled'
              : ''}
            <div className='filterDropDown'>
              <span onClick={() => setStatus(OrganizationStatus.Active)}>Active</span>

              <span onClick={() => setStatus(OrganizationStatus.Disabled)}>Disabled</span>
            </div>
          </div>
        </div>

        <div>
          <Search
            setQuery={setSearchQuery}
            filterList={filterList}
            search={search}
            setSearch={setSearch}
          />
        </div>
      </div>
      <div className='flex flex-col flex-1 justify-between'>
        <TableContainer>
          <TableHead
            tableList={listView

              .map((l) => l.name)

              .filter(
                (t) => listView.find((d) => d.name.toLowerCase() === t.toLowerCase())?.active,
              )}
            bool={true}
          />
          <tbody className='bg-white divide-y divide-gray-200'>
            {orgData?.data?.map((t: any, i: number) => (
              <tr
                onClick={(e) =>
                  user.roles === UserRoles.Admin
                    ? handleClick({ id: t.id, x: e.pageX, y: e.pageY })
                    : navigate(`./view/${t.id}/`)
                }
                className={user.roles !== UserRoles.Admin ? 'cursor-pointer' : 'relative'}
                key={i}
              >
                {listView
                  .filter((l) => l.active)
                  .map((o, i) => {
                    const model: OrganizationModel & { [key: string]: any } = new OrganizationModel(
                      t,
                    );
                    if (o.property === 'name')
                      return (
                        <TableTd key={i}>
                          <NameAndAvatar image={model.image!} name={model.name} />
                        </TableTd>
                      );
                    if (o.property === 'city')
                      return (
                        <TableTd key={i} extraClass='max-w-[15ch] text-sm  text-gray-500 truncate'>
                          {model.city}
                        </TableTd>
                      );
                    if (o.property === 'city')
                      return (
                        <TableTd key={i} extraClass='max-w-[15ch] text-sm  text-gray-500 truncate'>
                          {model.city}
                        </TableTd>
                      );
                    if (o.property === 'state')
                      return (
                        <TableTd key={i} extraClass='max-w-[15ch] text-sm  text-gray-500 truncate'>
                          {model.state}
                        </TableTd>
                      );
                    if (o.property === 'phone_number')
                      return (
                        <TableTd key={i} extraClass='max-w-[15ch] text-sm  text-gray-500 truncate'>
                          {formatPhoneNumber(model.phone_number)}
                        </TableTd>
                      );
                    if (o.property === 'tags')
                      return (
                        <TableTd key={o.property}>
                          {typeof model.tags !== 'undefined' && model.tags !== null ? (
                            appendAutoTags(
                              model.tags.split(',').map((tag: string) => ManualTagPrefix + tag),
                              t.Team,
                              t.schools,
                              gameData?.[0]?.id,
                            ).map((t: string, i: number) => (
                              <span
                                onClick={(e) => {
                                  e.stopPropagation();
                                  // Non auto generated tags have a "#" prefix
                                  let isGame;
                                  [t, isGame] = t.startsWith(ManualTagPrefix)
                                    ? [t.slice(1), 'false']
                                    : [t, 'true'];
                                  setSearch((s) =>
                                    s.by === 'tags' && s.keyword === t
                                      ? { by: filterList[0].title, keyword: '' }
                                      : { by: 'tags', keyword: t },
                                  );
                                  setSearchQuery('tags');
                                  setSearchParams({
                                    tags: t,
                                    isGame,
                                  });
                                  search.by === 'tags' && search.keyword === t && setSearchParams();
                                }}
                                key={t + i}
                                className={`tag ${(() => {
                                  const tagNoPrefix = t.startsWith(ManualTagPrefix)
                                    ? t.slice(1)
                                    : t;
                                  return search.by === 'tags' && search.keyword === tagNoPrefix
                                    ? 'active'
                                    : '';
                                })()}`}
                              >
                                {t}
                              </span>
                            ))
                          ) : (
                            <></>
                          )}
                        </TableTd>
                      );

                    if (o.property.includes('status'))
                      return (
                        <TableTd key={o.property}>
                          <span className={`invoiceStatus ${model[o.property]?.toLowerCase()}`}>
                            {model[o.property]}
                          </span>
                        </TableTd>
                      );
                    return (
                      <TableTd key={i}>
                        <div className='text-gray-500 test-sm'>{model[o.property]}</div>
                      </TableTd>
                    );
                  })}
                <TableTd>
                  <div className='listViewMenu--parent'>
                    <div
                      className={openList.id === t.id ? '' : 'hidden'}
                      style={
                        openList.id === t.id
                          ? {
                              position: 'fixed',
                              top: Number(openList.y),
                              left:
                                Number(openList.x) < 200
                                  ? Number(openList.x) + 200
                                  : Number(openList.x),
                              zIndex: 99,
                            }
                          : {
                              left: 0,
                            }
                      }
                    >
                      <div
                        className='listViewMenu'
                        role='menu'
                        aria-orientation='vertical'
                        aria-labelledby='menu-button'
                      >
                        <div className='' role='none'>
                          <DropdownItem id={t.id} setFunc={setOpenList} />
                        </div>
                      </div>
                    </div>
                  </div>
                </TableTd>
              </tr>
            ))}
          </tbody>
        </TableContainer>

        <Outlet />

        <Pagination length={orgData?.data?.length} {...orgData?.meta} />
      </div>
    </div>
  );
};

export default Organizations;
