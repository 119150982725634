import { Outlet, NavLink } from 'react-router-dom';
import { UserRoles } from '../../../utils/constants';
import CanIView from '../../../components/can-i-view/can-i-view';

const Settings = () => {
  const buttonList = [
    {
      name: 'My Account',
      link: '',
    },
    {
      name: 'Security',
      link: '/Settings/security/',
    },
    {
      name: 'Notifications',
      link: '/Settings/notification/',
    },
    {
      name: 'Usernames',
      link: '/Settings/usernames/',
      role: UserRoles.Student || UserRoles.Ogma,
    },
  ];

  return (
    <>
      <div className='listViewContainer fullPage overflow-auto flex flex-col border-1'>
        <div className='flex items-center justify-between'>
          <div className='space-y-1'>
            <h2 className='text-2xl font-semibold tracking-tight'>Account Settings</h2>
          </div>
        </div>
        <div className='flex mb-4 gap-4 border-b-1'>
          {buttonList.map((b) => {
            if (b?.role === UserRoles.Student) {
              return (
                <CanIView key={b.name} component='settings.usernames'>
                  <NavLink to={b.link} className='settingsLink'>
                    <div> {b.name}</div>
                  </NavLink>
                </CanIView>
              );
            } else {
              return (
                <NavLink key={b.name} to={b.link} className='settingsLink'>
                  <div> {b.name}</div>
                </NavLink>
              );
            }
          })}
        </div>
        <Outlet />
      </div>
    </>
  );
};

export default Settings;
