import React from 'react';
import { InvoiceModel } from '../../ogma-school-subscriptions/models/invoice.model';
import CurrencyFormat from 'react-currency-format';
import TableTd from '../../../components/table-td/table-td';

const OgmaProfileInvoiceRow = ({ model }: { model: InvoiceModel }) => {
  return ( 
          
            <tr className='min-w-full divide-y divide-gray-200'>
            <TableTd>{model.id}</TableTd>
            <TableTd> {model.schoolName}</TableTd>
            <TableTd> {model.seasonName} <span className={`invoiceStatus ${model.statusName?.toLowerCase()}`}>
                {model.statusName}
                </span></TableTd>
            <TableTd> {model.statusName} </TableTd>
            <TableTd> {model.typeName ?? " - "}</TableTd>
            <TableTd> <CurrencyFormat value={model.total} displayType={'text'} thousandSeparator={true} prefix={'$'}/></TableTd>
                 </tr>
          
  );
};

export default OgmaProfileInvoiceRow;
