import { useState } from 'react';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import { HeaderButton } from '../../../../components/header-button';
import { Search } from '../../../../components/search/search';
import TableContainer from '../../../../components/table-container/table-container';
import { TableHead } from '../../../../components/table-head';
import TableTd from '../../../../components/table-td/table-td';
import DropdownItem from '../../../../features/listview/DropDown/DropDownItem';
import {
  useGetAllOrganizationInvoicesQuery,
  useInvoiceExportListMutation,
} from '../../../../slices/invoicesApiSlice';
import { InvoiceModel } from '../../../ogma-school-subscriptions/models/invoice.model';
import Pagination from '../../../../components/pagination';
import { InvoiceTypes, UserRoles } from '../../../../utils/constants';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../slices/auth';
import { HiSwitchHorizontal } from 'react-icons/hi';
import { useToggleSchoolStatusMutation } from '../../../../slices/schoolsApiSlice';
import { LoadingSceletonInvoice } from '../../../tournaments/routes/all/loading-sceleton-invoice';

const InvoiceOrganizations = () => {
  const [downloadList, { isLoading: isDownloading }] = useInvoiceExportListMutation();
  const navigate = useNavigate();
  const user = useSelector(selectUser);

  const [openListView, setOpenListView] = useState(false);

  const initialOpenListState = {
    id: null,
    x: null,
    y: null,
  };

  const [openList, setOpenList] = useState<{ [key: string]: number | null }>(initialOpenListState);

  function handleClick(prop: { id: number; x: number; y: number }) {
    setOpenListView(false);
    if (openList.id == prop.id) {
      setOpenList(initialOpenListState);
    } else {
      setOpenList(prop);
    }
  }

  const configureListView = [
    // {
    //   name: 'id',
    //   prop: 'id',
    //   active: true,
    // },
    {
      name: 'organization',
      prop: 'orgName',
      active: true,
    },
    {
      name: 'school',
      prop: 'schoolName',
      active: true,
    },
    // {
    //   name: 'Number of students',
    //   prop: 'countStudent',
    //   active: true,
    // },
    {
      name: 'total',
      prop: 'formatedTotal',
      active: true,
    },
    {
      name: 'school status',
      prop: 'schoolState',
      active: true,
    },
    {
      name: 'invoice status',
      prop: 'statusName',
      active: true,
    },
    {
      name: 'Payment Method',
      prop: 'invoiceType',
      active: true,
    },
    {
      name: 'Type',
      prop: 'typeName',
      active: true,
    },
    {
      name: 'start date',
      prop: 'formatedStartDate',
      active: true,
    },
    {
      name: 'end date',
      prop: 'formatedEndDate',
      active: true,
    },
  ];

  const [view, setView] = useState(true);
  const [listView, setListView] = useState(configureListView);

  const headerButtons = [
    {
      type: 'export',
      func: () => downloadList({ type: '1', ...Object.fromEntries(searchParams.entries()) }),
    },
    {
      type: 'configureListView',
      view,
      listView,
      setListView,
    },
    {
      type: 'CreateNewRequest',
    },
  ];
  const [searchParams, setSearchParams] = useSearchParams();
  const filterList = [
    {
      title: 'name',
      property: 'name',
    },
  ];
  const [searchQuery, setSearchQuery] = useState(filterList[0].title);

  const [search, setSearch] = useState({ by: 'name', keyword: '' });
  const { isError, data, isLoading, isSuccess } = useGetAllOrganizationInvoicesQuery(
    Object.fromEntries(searchParams.entries()),
  );
  const [
    toggleSchool,
    { data: tData, isLoading: tIsLoading, isError: tIsError, isSuccess: tIsSuccess },
  ] = useToggleSchoolStatusMutation();

if (isLoading) return <LoadingSceletonInvoice/>;
  return (
    <div className='flex overflow-auto max-w-full flex-col flex-1 rounded-xl bg-white border-1 '>
      <div className='flex w-full p-5 justify-between'>
        <h2 className='text-2xl font-semibold tracking-tight'>School Invoices</h2>
        <div className='flex gap-2 justify-center'>
          {headerButtons.map((b) => (
            <HeaderButton key={b.type} view={view} {...b} />
          ))}
        </div>
      </div>
      <div className='flex w-full justify-between p-5 gap-2 '>
        <div className='flex items-center gap-5'>
          <HeaderButton func={() => setSearchParams({})} type='filter' />
        </div>
        <Search
          setQuery={setSearchQuery}
          filterList={filterList}
          search={search}
          setSearch={setSearch}
        />
      </div>
      <div className='listViewContainer flex-1 w-full justify-between'>
        <TableContainer>
          <TableHead
            tableList={listView
              .map((l) => l.name)
              .filter(
                (t) => listView.find((d) => d.name.toLowerCase() === t.toLowerCase())?.active,
              )}
          />
          <tbody className='bg-white divide-y divide-gray-200'>
            {data?.data?.length ? (
              data?.data?.map((t: any, i: number) => {
                const model: { [key: string]: any } & InvoiceModel = new InvoiceModel(t);
                return (
                  <tr
                    onClick={(e) =>
                      user.roles === UserRoles.Admin
                        ? handleClick({ id: t.id, x: e.pageX, y: e.pageY })
                        : navigate(`/invoices/view/${t.id}`)
                    }
                    className={user.roles !== UserRoles.Admin ? 'cursor-pointer' : 'relative'}
                    key={i}
                  >
                    {listView
                      .filter((l) => l.active)
                      .map((o, i) =>
                        o.prop === 'statusName' || o.prop === 'schoolState' ? (
                          <TableTd key={i}>
                            {model.type === InvoiceTypes.Stadium && (
                              <span
                                onClick={(e) => {
                                  if (o.prop === 'schoolState') {
                                    e.stopPropagation();
                                    toggleSchool(model.schoolId);
                                  }
                                }}
                                className={`invoiceStatus ${model[
                                  o.prop
                                ]?.toLowerCase()} flex items-center gap-1`}
                              >
                                {model[o.prop]}
                                {o.prop === 'schoolState' && <HiSwitchHorizontal />}
                              </span>
                            )}
                          </TableTd>
                        ) : o.name === 'Payment Method' ? (
                          <TableTd key={i}>
                            <span className='font-normal'>
                              {model.type === 0 ? 'Check' : model.type === 1 ? 'Square' : ''}
                            </span>
                          </TableTd>
                        ) : o.name === 'organization' ? (
                          <TableTd key={i} extraClass='max-w-[15ch] truncate'>
                            {model.orgName}
                          </TableTd>
                        ) : o.name === 'school' ? (
                          <TableTd key={i} extraClass='max-w-[15ch] truncate'>
                            {model.schoolName}
                          </TableTd>
                        ) : (
                          <TableTd key={i}>{model[o.prop]}</TableTd>
                        ),
                      )}

                    <TableTd>
                      <div className='listViewMenu--parent'>
                        <div
                          className={openList.id === t.id ? '' : 'hidden'}
                          style={
                            openList.id === t.id
                              ? {
                                  position: 'fixed',
                                  top: Number(openList.y),
                                  left:
                                    Number(openList.x) < 200
                                      ? Number(openList.x) + 200
                                      : Number(openList.x),
                                  zIndex: 99,
                                }
                              : {
                                  left: 0,
                                }
                          }
                        >
                          <div
                            className='listViewMenu'
                            role='menu'
                            aria-orientation='vertical'
                            aria-labelledby='menu-button'
                          >
                            <div className='' role='none'>
                              <DropdownItem id={t.id} setFunc={setOpenList} invoices />
                            </div>
                          </div>
                        </div>
                      </div>
                    </TableTd>
                  </tr>
                );
              })
            ) : (
              <></>
            )}
          </tbody>
        </TableContainer>
        <Outlet />
        <Pagination length={data?.data?.length} {...data?.meta} />
      </div>
    </div>
  );
};

export default InvoiceOrganizations;
