import { GradeLevelsNames, userRoleNames, UserStatusNames } from '../../../../utils/constants';
import { dateAndTimeFormatter } from '../../../../utils/dateFormatter';
import { firstLetterToUpperCase, wordWithFirstLetterToUpperCase } from '../../../../utils/utils';
export type Team = {
  id: number;
  teamName: string;
  image: null | string; // Assuming 'image' can be null or a string
};

export class UserModel {
  organization?: string;
  id?: number;
  fullName?: string;
  firstName = '';
  lastName = '';
  username = '';
  school_name = '';
  email = '';
  createdAt = '';
  role = '';
  roleNumber;
  status = '';
  eligibility_status = '';
  tags = '';
  city = '';
  state = '';
  zipCode = '';
  gender = 0;
  dateOfBirth = '';
  phoneNumber = '';
  streetAddress = '';
  censoredEmail?: string;
  image?: string;
  school_id?: number;
  team_id?: number;
  team_name?: string;
  teams?: Team[];
  updatedAt?: string;
  updateDifference?: number | any;
  gradeNumber?: string;
  gradeLevel?: string;
  officeNumber: any;

  getHourDifference(isoDateString: string): number | undefined | string {
    const targetDate = new Date(isoDateString);
    if (isNaN(targetDate.getTime())) {
      return undefined;
    }
    const currentDate = new Date();
    const timeDifferenceMs = currentDate.getTime() - targetDate.getTime();
    const hoursDifference = timeDifferenceMs / (1000 * 60 * 60);
    if (hoursDifference >= 24) {
      const daysDifference = Math.floor(hoursDifference / 24);
      return `${daysDifference}d`;
    } else {
      return Math.abs(Math.trunc(hoursDifference)) + 'hrs';
    }
  }

  transformEmail(email: string): string {
    const atIndex = email.indexOf('@');

    if (atIndex <= 1) {
      return email;
    }

    const firstChar = email.charAt(0);
    const lastChar = email.charAt(atIndex - 1);
    const domain = email.slice(atIndex);

    const transformedEmail = `${firstChar}****${lastChar}${domain}`;

    return transformedEmail;
  }
  constructor(data: any) {
    if (data.hasOwnProperty('gradeLevel')) {
      this.gradeNumber = data.gradeLevel;
      this.gradeLevel = GradeLevelsNames[data.gradeLevel];
    }
    if (data.hasOwnProperty('id')) this.id = data.id;
    if (data.hasOwnProperty('image')) this.image = data.image;
    if (data.hasOwnProperty('streetAddress')) this.streetAddress = data.streetAddress;
    if (data.hasOwnProperty('phoneNumber')) this.phoneNumber = data.phoneNumber;
    if (data.hasOwnProperty('office_number')) this.officeNumber = data.office_number;
    if (data.hasOwnProperty('dateOfBirth'))
      this.dateOfBirth = new Date(data.dateOfBirth).toISOString();
    if (data.hasOwnProperty('gender')) this.gender = data.gender;
    if (data.hasOwnProperty('zipCode') && data?.zipCode) this.zipCode = data.zipCode;
    if (data.hasOwnProperty('state')) this.state = data.state;
    if (data.hasOwnProperty('city')) this.city = data.city;
    if (data.hasOwnProperty('firstName')) this.firstName = data.firstName;
    if (data.hasOwnProperty('lastName')) this.lastName = data.lastName;
    if (data.hasOwnProperty('username')) this.username = data.username;
    // if (data.hasOwnProperty('school') && data.school.hasOwnProperty('name')) {
    //   this.school_name = data?.school?.name;
    // }
    if (data.hasOwnProperty('roles')) {
      this.role = userRoleNames[data.roles];
      this.roleNumber = data.roles;
    }
    if (this.role === userRoleNames[2] && data.hasOwnProperty('school')) {
      if (data.school?.hasOwnProperty('organization')) {
        data.school.organization?.hasOwnProperty('name');
        this.organization = data.school.organization.name;
      }
      if (data.school?.hasOwnProperty('id')) {
        this.school_id = data?.school?.id;
      }
    } else if (data.hasOwnProperty('ogma_org')) {
      if (Array.isArray(data.ogma_org) && data.ogma_org.length > 0) {
        this.organization = data.ogma_org.map((org: any) => org.name);
      }
    }
    if (data?.school && data?.school?.hasOwnProperty('name')) this.school_name = data?.school?.name;
    if (data.hasOwnProperty('email')) {
      this.email = data.email;

      this.censoredEmail = this.transformEmail(data.email);
    }
    if (data.hasOwnProperty('createdAt'))
      this.createdAt = dateAndTimeFormatter(data.createdAt).split(';')[0] + '';
    if (data.hasOwnProperty('updatedAt')) {
      this.updatedAt = dateAndTimeFormatter(data.updatedAt).split(';')[0];
      this.updateDifference = this.getHourDifference(data.updatedAt);
    }
    if (data.hasOwnProperty('status')) this.status = UserStatusNames[data.status];

    if (data.hasOwnProperty('eligibilityStatus'))
      this.eligibility_status = UserStatusNames[data?.eligibilityStatus];
    if (data.hasOwnProperty('tags')) this.tags = data.tags;
    if (this.firstName && this.lastName)
      this.fullName = `${wordWithFirstLetterToUpperCase(
        this.firstName,
      )} ${wordWithFirstLetterToUpperCase(this.lastName)}`;
    if (data.hasOwnProperty('organizations')) {
      if (data?.organizations?.length) {
        this.organization = data.organizations[0].name;
      }
    }
    if (data.hasOwnProperty('team')) {
      this.teams = data?.team;
      if (data.team?.hasOwnProperty('id')) {
        this.team_id = data.team?.id;
      }
      if (data.team?.hasOwnProperty('name')) {
        this.team_name = data.team?.name;
      }
    }
  }
  dateCheck() {
    return this.dateOfBirth;
  }
}
