import { useEffect, useState } from 'react';
import { MdDone } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { useViewSingleSeasonQuery } from '../../../slices/seasonApiSlice';
import { SeasonProgressStatus } from '../../../utils/constants';
export function SeasonReviewTimeline({}) {
  const param = useParams();
  const { data } = useViewSingleSeasonQuery(param.id);

  const [status, setStatus] = useState({
    createSeason: true,
    addTeams: false,
    startSeason: false,
    createFirstStage: false,
  });

  useEffect(() => {
    if (!data) return;
    if (data?.teams?.length) {
      setStatus((s) => ({ ...s, addTeams: true }));
    }

    if (data.status === SeasonProgressStatus.Ongoing) {
      setStatus((s) => ({ ...s, startSeason: true }));
    }

    if (data?.SeasonStage?.length) {
      setStatus((s) => ({ ...s, createFirstStage: true }));
    }
  }, [data]);

  const timeline = [
    {
      name: 'Create Season',
      condition: status.createSeason,
    },
    {
      name: 'Add Teams',
      condition: status.addTeams,
    },
    {
      name: 'Create First Stage',
      condition: status.createFirstStage,
    },
    {
      name: 'Start Season',
      condition: status.startSeason,
    },
  ];
  return (
    <ol className='items-center justify-center min-w-fit w-full relative whitespace-nowrap sm:flex'>
      {timeline.map((t: any, i: number) => (
        <li key={i} className='relative mb-6 sm:mb-0'>
          <div className='flex  items-center'>
            <div
              className={` flex items-center justify-center w-6 h-6 ${
                t.condition ? 'bg-green-400' : 'bg-white'
              } rounded-full border-2 ring-0 ring-white dark:bg-green-900 sm:ring-8 dark:ring-gray-900 shrink-0`}
            >
              <MdDone color='white' />
            </div>
            {i !== timeline.length - 1 && (
              <div className='hidden sm:flex flex-1 min-w-[20vh] bg-gray-200 h-0.5 dark:bg-gray-700'></div>
            )}
          </div>
          <div className='mt-3 sm:pr-8'>
            <h3 className='text-sm font-semibold text-gray-900 dark:text-white'>{t.name}</h3>
          </div>
        </li>
      ))}
    </ol>
  );
}
