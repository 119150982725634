import * as yup from 'yup';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { OutsideClick } from '../../../../../../components/outside-click';
import {
  useCreateTournamentMutation,
  useGetNewTRequiredDataQuery,
} from '../../../../../../slices/tournamentApiSlice';
import { NewTournamentVSchema } from './schema/new-tournament-v-schema';
import ModalContent from '../../../../../../components/modal-content';
import { addCurrentTimeToDate } from '../../../../../../utils/dateFormatter';

function NewTournament() {
  const [avatarImage, setAvatarImage] = useState<any>();
  const { data, isLoading: isDataLoading } = useGetNewTRequiredDataQuery('');
  const [chosenTeam, setChosenTeam] = useState<object[]>([]);
  const [chosenSubDivision, setChosenSubDivision] = useState<object[]>([]);
  const [chosenSponsor, setChosenSponsor] = useState<object[]>([]);
  const [chosenGame, setChosenGame] = useState<number>();
  const [chosenCategoryDivision, setChosenCategoryDivision] = useState<number>();
  const [teamSizeValue, setTeamSizeValue] = useState<number>();

  useEffect(() => {
    setChosenTeam([]);
  }, [teamSizeValue, chosenGame]);

  const formInputs = [
    {
      mainTitle: 'Tournament Information',
      name: 'name',
      label: 'Tournament Name',
      type: 'text',
      placeholder: 'Enter tournament name',
      isTrue: true,
    },
    {
      mainTitle: 'Tournament Information',
      name: 'type_id',
      label: 'Tournament Type',
      type: 'text',
      placeholder: 'Please select tournament type',
      isTrue: true,
      isSelect: true,
      options: data?.types ? data.types : [],
    },
    {
      mainTitle: 'Tournament Information',
      name: 'game_id',
      label: 'Game',
      type: 'text',
      placeholder: 'Please select tournament game',
      isTrue: true,
      isSelect: true,
      options: data?.games ? data.games : [],
    },
    {
      mainTitle: 'Tournament Information',
      name: 'format_id',
      label: 'Match Format',
      type: 'text',
      placeholder: 'Please select match format',
      isTrue: true,
      isSelect: true,
      options:
        chosenGame && data.games.filter((g: any) => g.id === chosenGame)[0].MatchFormat
          ? data.games.filter((g: any) => g.id === chosenGame)[0].MatchFormat
          : [],
    },
    {
      mainTitle: 'Tournament Information',
      name: 'team_size',
      label: 'Team Size',
      type: 'number',
      placeholder: 'Enter team size',
      isTrue: true,
      isSelect: true,
      options: [
        { id: 4, name: 4 },
        { id: 8, name: 8 },
        { id: 16, name: 16 },
        { id: 32, name: 32 },
      ],
    },
    {
      mainTitle: 'Tournament Information',
      name: 'teams',
      label: 'Teams',
      type: 'text',
      placeholder: 'Enter teams',
      isTrue: true,
      search: 'team',
      teamSizeValue: teamSizeValue,
      setChosenTeam,
      chosenGame,
      chosenTeam,
    },
    {
      mainTitle: 'Tournament Information',
      name: 'start_date',
      label: 'Start Date',
      type: 'date',
      placeholder: 'Please select start date',
      isTrue: true,
    },
    {
      mainTitle: 'Tournament Information',
      name: 'end_date',
      label: 'End Date',
      type: 'date',
      placeholder: 'Please select end date',
      isTrue: true,
    },
    {
      mainTitle: 'Tournament Information',
      name: 'division_id',
      label: 'Category Division',
      type: 'text',
      placeholder: 'Please select category division',
      isTrue: true,
      isSelect: true,
      options: data?.division ? data.division : [],
    },
    {
      mainTitle: 'Tournament Information',
      name: 'subdivisions',
      label: 'Subdivisions',
      type: 'text',
      placeholder: 'Enter subdivisions',
      isTrue: true,
      search: 'subdivision',
      divisionId: chosenCategoryDivision && chosenCategoryDivision,
      chosenSubDivision,
      setChosenSubDivision,
    },
    {
      mainTitle: 'Tournament Information',
      name: 'information',
      label: 'Information',
      type: 'textarea',
      placeholder: 'Enter information',
      isTrue: true,
    },
    {
      mainTitle: 'Tournament Information',
      name: 'sponsors',
      label: 'Sponsors',
      type: 'text',
      placeholder: 'Enter sponsors',
      isTrue: true,
      search: 'sponsor',
      chosenSponsor,
      setChosenSponsor,
    },
    {
      mainTitle: 'Tournament Information',
      name: 'image',
      label: 'Background Image',
      type: 'image',
      placeholder: 'Please select an image',
      isTrue: true,
    },
  ];

  const [createTournament, { isError, isLoading, isSuccess }] = useCreateTournamentMutation();

  const onSubmit = (values: any, actions: any) => {
    const data = { ...values };

    data.image = avatarImage;
    if (chosenSponsor) {
      data.sponsors = [];
      chosenSponsor.forEach((t: any) => data.sponsors.push({ id: t.id }));
    }
    data.start_date = addCurrentTimeToDate(data.start_date);
    data.end_date = addCurrentTimeToDate(data.end_date);
    data.game_id = +data.game_id;
    data.division_id = +data.division_id;
    data.type_id = +data.type_id;
    data.format_id = +data.format_id;
    data.team_size = +data.team_size;

    data.teams = [];
    chosenTeam.forEach((t: any) => data.teams.push({ id: t.id }));

    data.subdivisions = [];
    chosenSubDivision.forEach(
      (sd: any) => sd.division_id === data.division_id && data.subdivisions.push({ id: sd.id }),
    );

    const formData = new FormData();
    Object.entries(data).forEach(([key, value]: any[]) => {
      if (value)
        key === 'sponsors' || key === 'teams' || key === 'subdivisions'
          ? formData.append(key, JSON.stringify(value))
          : formData.append(key, value);
    });

    createTournament(formData)
      .unwrap()
      .then((res) => console.log(res))
      .catch((e) => console.log(e));
  };

  const initialValues: { [key: string]: string } = {};
  formInputs.forEach((input) => (initialValues[input.name] = ''));

  if (isDataLoading) return <p>Loading ...</p>;
  if (isSuccess) return <Navigate to='../' />;
  return (
    <>
      <OutsideClick />
      <Formik
        validationSchema={NewTournamentVSchema.shape({
          image: yup
            .mixed()
            .nullable()
            .test(
              'test2',
              'Invalid image format. Only JPG, PNG, and JPEG formats are allowed.',
              () => {
                const acceptedFormats = ['image/jpeg', 'image/png', 'image/jpg'];
                if (avatarImage && !acceptedFormats.includes(avatarImage?.type ?? '')) {
                  return false;
                }
                return true;
              },
            ),
        })}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({ errors, values }) => {
          // FIXME: React Hook "useEffect" cannot be called inside a callback. React Hooks must be called in a React function component or a custom React Hook function.
          useEffect(() => {
            if (!values) return;
            console.log({ values });
            setChosenGame(+values.game_id);
            setChosenCategoryDivision(+values.division_id);
            setTeamSizeValue(+values.team_size);
          }, [values]);
          return (
            <ModalContent
              errors={Object.keys(errors).length}
              setAvatarImage={setAvatarImage}
              formInputs={formInputs}
              isLoading={isLoading}
              name='New Tournament'
            />
          );
        }}
      </Formik>
    </>
  );
}

export default NewTournament;
