import { UserRoles } from "../utils/constants";
import { dateToLocal } from "../utils/dateFormatter";

export class MatchScheduleModel {
  competitions: { [key: string]: string } = {
    0: 'Tournament',
    1: 'Season',
  };
  id?: string = '';
  start_date?: any = '';
  ht_score?: string = '';
  at_score?: string = '';
  tournamentId?: string = '';
  tournamentName?: string = '';
  tournamentImage?: string = '';
  tournamentMatchFormat?: string = '';
  seasonId?: string = '';
  seasonName?: string = '';
  seasonImage?: string = '';
  seasonMatchFormat?: string = '';
  gameName?: string = '';
  away_teamId?: string = '';
  away_teamName?: string = '';
  away_teamImage?: string = '';
  home_teamId?: string = '';
  home_teamName?: string = '';
  home_teamImage?: string = '';
  competitionType?: number;
  f_competitionType?: string;
  season_type?: number;
  season_week_id?: any;
  tournament_round_id?: any;
  SeasonWeek?: any;
  game_per_match?: any;
  home_team_benched?: boolean;
  away_team_benched?: boolean;
  home_team: any;
  away_team: any;
  constructor(schedule: any) {
    if (schedule.hasOwnProperty('game_per_match')) {
      this.game_per_match = schedule.game_per_match;
    }
    if (schedule.hasOwnProperty('SeasonWeek')) {
      this.SeasonWeek = schedule.SeasonWeek;
    }
    if (schedule.hasOwnProperty('id')) this.id = schedule.id;
    if (schedule.hasOwnProperty('match_date')) this.start_date = dateToLocal(schedule.match_date);
    if (schedule.hasOwnProperty('ht_score')) this.ht_score = schedule.ht_score;
    if (schedule.hasOwnProperty('at_score')) this.at_score = schedule.at_score;
    if (
      schedule.hasOwnProperty('TournamentRound') &&
      schedule.TournamentRound?.hasOwnProperty('Tournament') &&
      schedule.TournamentRound?.Tournament?.hasOwnProperty('id')
    ) {
      this.tournamentId = schedule.TournamentRound.Tournament.id;
      this.competitionType = 0;
      this.f_competitionType = this.competitions[this.competitionType];
    }
    if (
      schedule.hasOwnProperty('TournamentRound') &&
      schedule.TournamentRound?.hasOwnProperty('Tournament') &&
      schedule.TournamentRound?.Tournament?.hasOwnProperty('name')
    )
      this.tournamentName = schedule.TournamentRound.Tournament.name;
    if (
      schedule.hasOwnProperty('TournamentRound') &&
      schedule.TournamentRound?.hasOwnProperty('Tournament') &&
      schedule.TournamentRound.Tournament?.hasOwnProperty('image')
    )
      this.tournamentImage = schedule.TournamentRound.Tournament.image;
    if (
      schedule.hasOwnProperty('TournamentRound') &&
      schedule.TournamentRound?.hasOwnProperty('Tournament') &&
      schedule.TournamentRound?.Tournament?.hasOwnProperty('match_format') &&
      schedule.TournamentRound?.Tournament?.match_format?.hasOwnProperty('name')
    )
      this.tournamentMatchFormat = schedule.TournamentRound.Tournament.match_format.name;
    if (schedule.hasOwnProperty('Game') && schedule.Game?.hasOwnProperty('name'))
      this.gameName = schedule.Game?.name;

    if (schedule.hasOwnProperty('away_team') && schedule.away_team[0]?.hasOwnProperty('id'))
      this.away_teamId = schedule.away_team[0].id;
    if (schedule.hasOwnProperty('away_team') && schedule.away_team[0]?.hasOwnProperty('teamName'))
      this.away_teamName = schedule.away_team[0].teamName;
    if (schedule.hasOwnProperty('away_team') && schedule.away_team[0]?.hasOwnProperty('image'))
      this.away_teamImage = schedule.away_team[0].image;
    if (schedule.hasOwnProperty('home_team') && schedule.home_team[0]?.hasOwnProperty('id'))
      this.home_teamId = schedule.home_team[0].id;
    if (schedule.hasOwnProperty('home_team') && schedule.home_team[0]?.hasOwnProperty('teamName'))
      this.home_teamName = schedule.home_team[0].teamName;
    if (schedule.hasOwnProperty('home_team') && schedule.home_team[0]?.hasOwnProperty('image'))
      this.home_teamImage = schedule.home_team[0].image;
    if (
      schedule.hasOwnProperty('SeasonWeek') &&
      schedule.SeasonWeek?.hasOwnProperty('games_per_match')
    )
      this.seasonMatchFormat = schedule.SeasonWeek.games_per_match;
    if (
      schedule.hasOwnProperty('SeasonWeek') &&
      schedule.SeasonWeek?.hasOwnProperty('Season') &&
      schedule.SeasonWeek?.Season?.hasOwnProperty('id')
    ) {
      this.seasonId = schedule.SeasonWeek.Season.id;
      this.competitionType = 1;
      this.f_competitionType = this.competitions[this.competitionType];
    } else {
      this.competitionType = 0;
      this.f_competitionType = this.competitions[this.competitionType];
    }
    if (
      schedule.hasOwnProperty('SeasonWeek') &&
      schedule.SeasonWeek?.hasOwnProperty('Season') &&
      schedule.SeasonWeek?.Season?.hasOwnProperty('name')
    )
      this.seasonName = schedule.SeasonWeek.Season.name;
    if (
      schedule.hasOwnProperty('SeasonWeek') &&
      schedule.SeasonWeek?.hasOwnProperty('Season') &&
      schedule.SeasonWeek?.Season?.hasOwnProperty('image')
    )
      this.seasonImage = schedule.SeasonWeek.Season.image;
    if (
      schedule.hasOwnProperty('SeasonWeek') &&
      schedule.SeasonWeek?.hasOwnProperty('Season') &&
      schedule.SeasonWeek?.Season?.hasOwnProperty('SeasonType') &&
      schedule.SeasonWeek?.Season?.SeasonType?.hasOwnProperty('leaderboard')
    )
      this.season_type = schedule.SeasonWeek.Season.SeasonType.leaderboard;

    if (schedule.hasOwnProperty('SeasonWeek') && schedule.SeasonWeek?.hasOwnProperty('id'))
      this.season_week_id = schedule.SeasonWeek?.id;
    if (
      schedule.hasOwnProperty('TournamentRound') &&
      schedule.TournamentRound?.hasOwnProperty('id')
    )
      this.tournament_round_id = schedule.TournamentRound.id;

      if (schedule.hasOwnProperty('home_team') && schedule.home_team[0]?.hasOwnProperty('isBenched')){
        this.home_team = schedule?.home_team;
        this.home_team_benched = schedule?.home_team?.[0]?.isBenched;
      }

      if (schedule.hasOwnProperty('away_team') && schedule.away_team[0]?.hasOwnProperty('isBenched'))
      this.away_team = schedule?.away_team;
      this.away_team_benched = schedule?.away_team?.[0]?.isBenched;
  }
}
