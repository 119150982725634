import React, { useState, MouseEvent } from 'react';
import TableContainer from '../../../../components/table-container/table-container';
import { TableHead } from '../../../../components/table-head';
import { selectUser } from '../../../../slices/auth';
import { useSelector } from 'react-redux';
import { UserRoles } from '../../../../utils/constants';
import TableTd from '../../../../components/table-td/table-td';
import { Link, Outlet, useSearchParams } from 'react-router-dom';
import {
  useGetUnarchivedQuery,
  useMassDeleteMutation,
  useMassUnArchiveMutation,
} from '../../../../slices/unarchiveApiSlice';
import Pagination from '../../../../components/pagination';
import { LoadingSceletonArchived } from '../../../tournaments/routes/all/loading-sceleton-archived';
import AreYouSure from '../user/components/are-you-sure';
import { TournamentModel } from '../../../../model/tournament.model';
import ShowAllSubCategories from '../../../support/show-all-subcategories';
import useErrorToast from '../../../../hooks/use-error-toast';
import useSuccessToast from '../../../../hooks/use-success-toast';
import ShowTeamsModal from '../../../../components/show-teams-modal';
import useCloseAreYouSure from '../../../../hooks/use-close-are-you-sure';

const UnarchiveTournament = () => {
  const user = useSelector(selectUser);
  const [searchParams, setSearchParams] = useSearchParams();

  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isArchiveDialogOpen, setArchiveDialogOpen] = useState(false);

  const [checked, setChecked] = useState<number[]>([]);

  const [
    massDelete,
    {
      isSuccess: deleteIsSuccess,
      isLoading: deleteIsLoading,
      isError: deleteIsError,
      error: deleteError,
    },
  ] = useMassDeleteMutation();
  const [
    massUnArchive,
    {
      isSuccess: unarchiveIsSuccess,
      isLoading: unarchiveIsLoading,
      isError: unarchiveIsError,
      error: unarchievError,
    },
  ] = useMassUnArchiveMutation();
  const [teammodelId, setTeamModelId] = useState<any>();
  const [subdivisionsmodelId, setSubdivisionsModelId] = useState<any>();
  const [modelId, setModelId] = useState<String[] | undefined>();

  const handleSubdivisions = (subcategories: any) => {
    setSubdivisionsModelId(subcategories);
  };
  const { data, isLoading } = useGetUnarchivedQuery({
    type: 'tournament',
    page: searchParams.get('page') ?? undefined,
  });

  const listView = [
    {
      name: '',
      active: true,
      prop: '',
    },
    {
      name: 'name',
      active: true,
      prop: 'name',
    },
    {
      name: 'Game',
      active: true,
      prop: 'game',
    },
    {
      name: 'Start Date',
      active: true,
      prop: 'f_startDate',
    },
    {
      name: 'End Date',
      active: true,
      prop: 'f_endDate',
    },
    {
      name: 'teams',
      active: true,
      prop: 'teams',
    },
    {
      name: 'category Division',
      active: true,
      prop: 'division',
    },
    {
      name: 'Subdivision',
      active: true,
      prop: 'subdivision',
    },
    {
      name: 'Archived Date',
      active: true,
      prop: 'updatedAt',
    },
  ];

  const handleTdClick = (teams: any) => {
    setModelId(teams);
  };

  const handleDelete = () => {
    massDelete({
      model: 'tournament',
      ids: [...new Set(checked)],
    });
  };

  const handleArchive = () => {
    massUnArchive({
      model: 'tournament',
      ids: [...new Set(checked)],
    });
  };

  useCloseAreYouSure({
    archiveIsError: unarchiveIsError,
    archiveIsSuccess: unarchiveIsSuccess,
    deleteIsSuccess,
    deleteIsError,
    setDeleteDialogOpen,
    setArchiveDialogOpen,
    setChecked,
  });

  useSuccessToast(deleteIsSuccess || unarchiveIsSuccess, 'Success');
  useErrorToast(deleteIsError, deleteError);
  useErrorToast(unarchiveIsError, unarchievError);
  if (isLoading) return <LoadingSceletonArchived />;
  return (
    <div className='flex flex-col flex-1 gap-3  '>
      <div className=' flex justify-between gap-2 items-center  '>
        <div className='flex gap-3 items-center pl-10'>
          {modelId ? (
            <ShowTeamsModal title='All Tournament Teams' setModelId={setModelId} teams={modelId} />
          ) : (
            <></>
          )}
          {data?.data?.length ? (
            <>
              <input
                type='checkbox'
                style={{ width: '15px', height: '15px' }}
                checked={data?.data?.every((i: any) => checked.includes(i.id)) && checked.length}
                onChange={() =>
                  data?.data?.every((i: any) => checked.includes(i.id))
                    ? setChecked(checked.filter((id) => !data?.data.find((d: any) => d.id === id)))
                    : setChecked([...checked, ...(data?.data?.map((item: any) => item.id) || [])])
                }
              />
              <p className='text-base font-body'>Select All</p>
            </>
          ) : (
            <></>
          )}
        </div>
        <div className='flex  items-center gap-5'>
          {checked.length && data?.data?.length ? (
            <button onClick={() => setArchiveDialogOpen(true)} className='btn red '>
              Unarchive
            </button>
          ) : (
            <></>
          )}
          {checked.length && data?.data?.length ? (
            <button onClick={() => setDeleteDialogOpen(true)} className='btn red '>
              Delete
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>

      <div className='listViewContainer w-full border flex-1 overflow-x-auto'>
        <div className='flex flex-col flex-1 justify-between'>
          {teammodelId ? (
            <ShowAllSubCategories
              setModelId={setTeamModelId}
              subCategories={teammodelId}
              title='All Teams'
            />
          ) : (
            <></>
          )}
          {subdivisionsmodelId ? (
            <ShowAllSubCategories
              setModelId={setSubdivisionsModelId}
              subCategories={subdivisionsmodelId}
              title='All Subdivisions'
            />
          ) : (
            <></>
          )}
          <TableContainer extraClass='overflow-auto'>
            <TableHead
              oneMore={user.roles === UserRoles.Admin}
              tableList={listView
                .map((l) => l.name)
                .filter(
                  (t) => listView.find((d) => d.name.toLowerCase() === t.toLowerCase())?.active,
                )}
            />
            <tbody className='bg-white divide-y divide-gray-200 w-full'>
              {data?.data?.map((d: any, i: number) => (
                <tr key={i}>
                  <TableTd>
                    <input
                      type='checkbox'
                      style={{ width: '15px', height: '15px' }}
                      onChange={() =>
                        checked.includes(d.id)
                          ? setChecked((s) => s.filter((id) => id !== d.id))
                          : setChecked((s) => [...s, d.id])
                      }
                      checked={checked.includes(d.id)}
                    />
                  </TableTd>
                  {listView
                    .filter((l) => l.active)
                    .map((o, i) => {
                      const model: TournamentModel & { [key: string]: any } = new TournamentModel(
                        d,
                      );
                      if (o.prop === 'subdivision') {
                        return (
                          <TableTd key={o.prop}>
                            {model?.subdivision?.length === 1 ? (
                              <p>{model?.subdivision?.[0]?.name}</p>
                            ) : model?.subdivision?.length > 1 ? (
                              <div onClick={() => handleSubdivisions(model?.subdivision)}>
                                Show All
                              </div>
                            ) : (
                              <p>-</p>
                            )}
                          </TableTd>
                        );
                      }
                      if (o.prop === 'teams')
                        return (
                          <React.Fragment key={i}>
                            <TableTd
                              key={i}
                              handleClick={(e: MouseEvent<HTMLElement>) => {
                                model.teams.length > 1 ? handleTdClick(model.teams) : null;
                                e.stopPropagation();
                              }}
                            >
                              {model.teams.length > 1 ? (
                                <span className='hover:text-red-600 underline'>Show Teams</span>
                              ) : model.teams.length === 0 ? (
                                <span>-</span>
                              ) : (
                                <div className='max-w-[15ch] truncate'>
                                  {model.teams[0]?.teamName}
                                </div>
                              )}
                            </TableTd>
                          </React.Fragment>
                        );
                      if (o.prop === 'updatedAt')
                        return <TableTd key={o.prop}>{model[o.prop]}</TableTd>;
                      return o.prop !== '' ? (
                        <TableTd key={o.prop}>{model[o.prop]}</TableTd>
                      ) : (
                        <React.Fragment key={o.prop} />
                      );
                    })}
                  <TableTd>
                    <Link to={`./unarchive/${d?.id}/${d?.name}`}>
                      <button className='btn red '>Unarchive</button>
                    </Link>
                  </TableTd>
                </tr>
              ))}
            </tbody>
          </TableContainer>
          <Outlet />

          <Pagination length={data?.data?.length} {...data?.meta} />
        </div>
      </div>
      {isDeleteDialogOpen && (
        <AreYouSure
          handleCloseDeleteDialog={setDeleteDialogOpen}
          handleDelete={handleDelete}
          isLoading={deleteIsLoading}
          text='delete selected tournaments'
        />
      )}
      {isArchiveDialogOpen && (
        <AreYouSure
          handleCloseDeleteDialog={setArchiveDialogOpen}
          handleDelete={handleArchive}
          isLoading={unarchiveIsLoading}
          text='unarchive selected tournaments'
        />
      )}
    </div>
  );
};

export default UnarchiveTournament;
