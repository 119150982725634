import { apiSlice } from '../api/apiSlice';

export const teamsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTeamsBySeasonId: builder.query({
      query: (id: string | number) => ({
        url: `/teams/by-season/${id}`,
        method: 'GET',
      }),
      providesTags: ['Seasons', 'SingleSeasonInfo', 'Team'],
    }),
    removeOrganizationFromTeam: builder.mutation({
      query: ({ teamId, orgId }: { teamId: string | number; orgId: string | number }) => ({
        url: `/teams/org/${teamId}/${orgId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Organization', 'Team'],
    }),
    teamsExportList: builder.mutation({
      query: ({
        page,
        params,
      }: {
        page: string;
        params: { [key: string]: string | undefined };
      }) => ({
        url: `/teams/export/${page}`,
        method: 'GET',
        params,
        responseHandler: async (response) => {
          if (
            response.status === 200 &&
            response.headers.get('content-type')?.includes('text/csv')
          ) {
            window.open(
              window.URL.createObjectURL(await response.blob()),
              '_blank',
              'noopener,noreferrer',
            );
          } else {
            console.error('Unexpected content type or response status');
          }
        },
        cache: 'no-cache',
      }),
    }),
    // searchTeamByName: builder.mutation({
    //   query: (teamName) => ({
    //     url: `/teams/by-name?teamName=${teamName}`,
    //     method: 'GET',
    //   }),
    // }),
    searchTeamByName: builder.query({
      query: (params: {
        team?: string;
        game?: string;
        schools?: string;
        state?: string;
        stateRequired?: boolean;
      }) => ({
        url: `/teams/by-name`,
        method: 'GET',
        params,
      }),
      providesTags: ['Team'],
    }),
    getAllTeams: builder.query({
      query: (query) => {
        const arr: any = [];
        Object.keys(query.query).forEach(
          (key) => query.query[key].length && arr.push(`${key}=${query.query[key]}`),
        );
        return {
          url: '/teams/all',
          method: 'GET',
          params: {
            ...query.param,
            page: query.page ?? 1,
            teamStatusFilterMode: query.teamStatusFilterMode,
            showMy: query.showMy,
          },
        };
      },
      providesTags: ['Team'],
    }),
    getTeamById: builder.query({
      query: ({ id, query }) => ({
        url: `/teams/${id}?query=${query ?? undefined}`,
        method: 'Get',
      }),
      providesTags: ['Team'],
    }),
    createTeam: builder.mutation({
      query: (body: {
        game: number;
        teamName: string;
        schoolId?: number;
        image: string;
        organizations: any[];
      }) => ({
        url: '/teams',
        method: 'Post',
        body,
      }),
      invalidatesTags: ['Team'],
    }),
    archiveTeam: builder.mutation({
      query: (id) => ({
        url: `teams/archive/${id}`,
        method: 'post',
      }),
      onQueryStarted: async (_, api) => {
        try {
          await api.queryFulfilled;
          api.dispatch(apiSlice.util.resetApiState());
        } catch (error) {
          console.log(error);
        }
      },
    }),
    updateTeam: builder.mutation({
      query: ({ id, body }) => ({
        url: `/teams/${id}`,
        method: 'put',
        body,
      }),
      invalidatesTags: ['SingleTeam', 'Team'],
    }),
    getAllTeamBySchoolId: builder.query({
      query: (id) => ({
        url: `/teams/by-school/${id}`,
        method: 'Get',
      }),
      providesTags: ['SchoolTeams'],
    }),
    // uploadImage: builder.mutation({
    //   query: (image) => ({
    //     url: '/teams/upload-image',
    //     method: 'POST',
    //     body: image,
    //   }),
    // }),
    changeTeamProfilePhoto: builder.mutation({
      query: ({ photo, id }) => ({
        url: `/teams/profile-photo/${id}`,
        method: 'PUT',
        body: photo,
      }),
      invalidatesTags: ['Team'],
    }),
    getAllTeamsByTournamentId: builder.query({
      query: (id) => ({
        url: `/teams/by-tournament/${id}`,
        method: 'Get',
      }),
      providesTags: ['TournamentTeams'],
    }),
    getAllTeamsByWeekId: builder.query({
      query: ({ id, seasonId }) => ({
        url: `/teams/by-season-week/${id}?seasonId=${seasonId}`,
        method: 'Get',
      }),
      providesTags: ['Team'],
    }),
    getAllOrganizationTeams: builder.query({
      query: () => ({
        url: '/teams/of-organization',
        method: 'Get',
      }),
      providesTags: ['Team'],
    }),
    benchTeamById: builder.mutation({
      query: (id) => ({
        url: `/teams/bench-team/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Team', 'Match', 'SingleSeasonInfo', 'SingleMatchInfo'],
    }),
    unBenchTeamById: builder.mutation({
      query: (id) => ({
        url: `/teams/unbench-team/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Team', 'Match', 'SingleSeasonInfo', 'SingleMatchInfo'],
    }),
    getValidSeasonsByTeamId: builder.query({
      query: (id) => ({
        url: `/teams/get-valid-seasons/${id}`,
        method: 'GET',
      }),
      providesTags: ['Team', 'Seasons'],
    }),
    disbandTeamById: builder.mutation({
      query: (id) => ({
        url: `/teams/disband/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Team'],
    }),
  }),
});
export const {
  // useSearchTeamByNameMutation,
  useSearchTeamByNameQuery,
  useLazySearchTeamByNameQuery,
  useGetAllTeamsQuery,
  useCreateTeamMutation,
  useGetTeamByIdQuery,
  useArchiveTeamMutation,
  useUpdateTeamMutation,
  useGetAllTeamBySchoolIdQuery,
  // useUploadImageMutation,
  useChangeTeamProfilePhotoMutation,
  useGetAllTeamsByTournamentIdQuery,
  useGetAllTeamsByWeekIdQuery,
  useTeamsExportListMutation,
  useRemoveOrganizationFromTeamMutation,
  useGetTeamsBySeasonIdQuery,
  useGetAllOrganizationTeamsQuery,
  useBenchTeamByIdMutation,
  useUnBenchTeamByIdMutation,
  useGetValidSeasonsByTeamIdQuery,
  useDisbandTeamByIdMutation,
} = teamsApiSlice;
