import { FanDashboardTournCard } from './components/fan-dashboard-tourn-card';
import React from 'react';
import { AvatarImage } from '../../components/avatar-image';
import { FormInput } from '../../components/form-input/form-input';
import { UserHeader } from '../../components/user-header';
import { useSelector } from 'react-redux';
import { selectUser } from '../../slices/auth';
import { SponsorRow } from '../../components/sponsor-row/sponsor-row';
import { SponsorPlacements } from '../../utils/constants';

function FanDashboard() {
  const user = useSelector(selectUser);

  return (
    <div className='flex flex-col p-10 gap-10 border-1 rounded-xl'>
      <SponsorRow name={SponsorPlacements.Dashboard} />
      <UserHeader name={user.firstName} image={user.image} id={user.id} username={user.username} />
      <div className='resetInput'>
        <label className='resetLabel group' htmlFor={'select'}>
          Select
        </label>
        <select className='resetFormInput'>
          <option value='' label='Please select an option' />
          {Object.keys(Array.from(new Array(3))).map((o: any) => (
            <option key={o.id} value={o.id} label={o} />
          ))}
        </select>
      </div>
      <div className='flex flex-col gap-2'>
        <h2 className='font-bold'>Recent Tournaments</h2>

        <div className='flex relative flex-wrap gap-2 justify-between'>
          <FanDashboardTournCard />
          <FanDashboardTournCard />
          <FanDashboardTournCard />
        </div>
      </div>
      <div className='flex flex-col gap-2'>
        <h2 className='font-bold'>Recent Seasons</h2>

        <div className='flex relative flex-wrap gap-2 justify-between'>
          <FanDashboardTournCard season />
          <FanDashboardTournCard season />
          <FanDashboardTournCard season />
        </div>
      </div>
    </div>
  );
}

export default FanDashboard;
