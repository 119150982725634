import React from 'react';
import { AiOutlineCamera } from 'react-icons/ai';
import { useParams } from 'react-router-dom';
import { AvatarImage } from '../../../components/avatar-image';
import {
  useChangeTournamentProfilePhotoMutation,
  useGetSingleTournamentInfoQuery,
} from '../../../slices/tournamentApiSlice';
import CanIView from '../../../components/can-i-view/can-i-view';
import { TournamentModel } from '../../../model/tournament.model';
import { NameAndAvatar } from '../../../components/name-and-avatar';
import { TournamentProgressStatus } from '../../../utils/constants';
import { dateToLocal, formatDateWithComma } from '../../../utils/dateFormatter';
export function TournamentHeaderInfo({ }) {
  const param = useParams();
  const [changeTournamentProfilePhoto, { isSuccess, isLoading: profilePhotoIsLoading }] = useChangeTournamentProfilePhotoMutation();

  const { data, isLoading, isError } = useGetSingleTournamentInfoQuery(param.id);

  function handleFileChange(event: any) {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    changeTournamentProfilePhoto({ photo: formData, id: param.id })
      .unwrap()
      .then((res) => console.log(res))
      .catch((e) => console.log(e));
  }

  let model;
  if (!isLoading) model = new TournamentModel(data);

  if (isLoading) return <p>Loading ...</p>;
  return (
    <div className='bg-black bg-opacity-60 border-1 border-gray-500 flex gap-5 items-center p-4 pointer-events-auto rounded-lg'>
      <div className='group cursor-pointer flex relative w-[100px] aspect-square rounded-full overflow-hidden justify-center items-center'>

        {profilePhotoIsLoading ? <p>Loading...</p> :
          <AvatarImage size='xxl' source={data.image} />
        }
        <CanIView component='tournament.view.change-profile'>
          <label className='cursor-pointer text-white  w-full bg-black bg-opacity-80 h-full absolute top-0 right-0 left-0 bottom-0 m-auto hidden group-hover:flex justify-center items-center  '>
            <AiOutlineCamera />
            <input
              type='file'
              accept='image/*'
              onChange={handleFileChange}
              className='hidden mr-12'
            />
          </label>
        </CanIView>
      </div>
      <div className='flex flex-col space-evenly gap-1'>
        <div className='flex justify-between items-center gap-4'>
          <h2 className='text-white text-2xl font-bold max-w-[15ch] truncate'>{model?.name}</h2>
          <p className='text-white max-w-[15ch] truncate'>{data.teams.length} Teams</p>
        </div>
        <hr />
        <div className='flex justify-between items-center  gap-4'>
          <p className='flex items-center justify-center gap-2 text-white'>
            {model?.game?.toUpperCase()}
          </p>
          {Number(model?.status) === TournamentProgressStatus.Upcoming ?
            <span className='text-white uppercase px-2 py-1 bg-purple-700 rounded-md w-fit text-sm'>
              UPCOMING</span> :
            Number(model?.status) === TournamentProgressStatus.Completed ?
              <span className='text-white uppercase px-2 py-1 bg-red-700 rounded-md w-fit text-sm'>
                Completed</span> :
              <span className='text-white uppercase px-2 py-1 bg-green-700 rounded-md w-fit text-sm'>
                ONGOING</span>}
        </div>
        <hr />

        <div className='text-white flex justify-between'>
          <span className='text-white'>{dateToLocal(model?.startDate, 'ddd MMM DD, YYYY')} </span> -{' '}
          <span className='text-white'>{dateToLocal(model?.endDate, 'ddd MMM DD, YYYY')}</span>
        </div>
      </div>
    </div>
  );
}
