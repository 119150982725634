import React from 'react';
import { DisputeModel } from '../../../../model/dispute.model';

const DisputeRow = ({ model }: { model: DisputeModel }) => {
  return (
    <div className='flex gap-2'>
      {model.username} opened this dispute!
      <b>
        {model.ht_result}:{model.aw_result}
      </b>
      <a
        target='_blank'
        rel='noopener noreferrer'
        href={model.image}
        className='underline hover:font-bold'
      >
        Image
      </a>
    </div>
  );
};

export default DisputeRow;
