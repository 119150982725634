import { Outlet } from 'react-router';
import SupportTicketStatusTab from './SupportTicketStatusTab';

const SupportTicketAdmin = () => {
    return (
        <>
            <div className='w-full mx-auto mt-2 flex overflow-auto max-w-full flex-col flex-1 rounded-xl bg-white border-1'>
                <div className='flex justify-between items-center mb-3'>
                    <h2 className='text-2xl ml-3 font-semibold tracking-tight'>Tickets</h2>
                    <>
                        <SupportTicketStatusTab />
                    </>
                </div>
                <Outlet />
            </div>
        </>
    );
};

export default SupportTicketAdmin;