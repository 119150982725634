import { parse } from 'path';
import React, { useEffect, useState } from 'react';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import { useDeleteLessonContentMutation, useGetLessonByIdQuery } from '../../../../slices/classroomApiSlice';
import { OutsideClick } from '../../../../components/outside-click';
import { AiOutlineClose } from 'react-icons/ai';

const LessonContentTable = ({ data, onDelete, lessonId }: any) => {
    const [hasError, setHasError] = useState<boolean>(false);

    const { data: lessonData, isLoading: lessonIsLoading, isError: lessonIsError } = useGetLessonByIdQuery(lessonId);

    const [
        deleteLessonContent, {
            isLoading: deleteLessonIsLoading,
            isError: deleteLessonIsError,
            isSuccess: deleteLessonIsSuccess,
        }
    ] = useDeleteLessonContentMutation();

    const [selected, setSelected] = useState(null);
    // const [parsedArr, setParsedArr] = useState<any[]>([]);


    useEffect(() => {
        console.log('data = ', data);
        // setParsedArr(data);
    }, [data])

    const handleClick = (index: any) => {
        setSelected(index);
    };

    function handleDelete(url: string, blobName: string, mimeType: string) {
        // console.log('idx = ', index);


        deleteLessonContent({ id: lessonId, body: { url, blobName, mimeType } })
            .unwrap()
            .then((res: any) => console.log(res))
            .catch((error: any) => error?.status === 500 && setHasError(true))

    };

    return (
        <>
            <table className='w-full'>
                <thead>
                    <tr>
                        <th className='px-4 py-2'>File Name</th>
                        <th className='px-4 py-2'>Type</th>
                        <th className='px-4 py-2'>Size</th>
                        {/* <th className='px-4 py-2'>Created At</th>
                    <th className='px-4 py-2'>Public Id</th> */}
                        <th className='px-4 py-2'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {/* {data.map((str: any, idx: any) => JSON.parse(str)).map((item: any, index: any) => (
                    <tr key={index}>
                        <td className="border px-4 py-2">{item[index]?.originalname}</td>
                        <td className="border px-4 py-2">{item[index]?.format}</td>
                        <td className="border px-4 py-2">{(Number(item[index]?.bytes) / (1024 * 1024)).toFixed(2)} MB</td>
                        <td className="border px-4 py-2 flex justify-center">
                            <button
                                // className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleDelete(index)
                                }}>
                                <RiDeleteBin6Fill color='darkred' size={25} />
                            </button>
                        </td>
                    </tr>
                ))} */}

                    {
                        data.map((d: any, idx: any) => (
                            <tr key={idx}>
                                <td className="border px-4 py-2">{d.fileName}</td>
                                <td className="border px-4 py-2">{d.fileType}</td>
                                <td className="border px-4 py-2">{(Number(d.fileSize) / (1024 * 1024)).toFixed(2)} MB</td>
                                {/* <td className="border px-4 py-2">{new Date(d.created_at).toLocaleString()}</td>
                            <td className="border px-4 py-2">{d.public_id}</td> */}
                                <td className="border px-4 py-2 flex justify-center">
                                    <button
                                        // className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleDelete(d.res.url, d.res.blobName, d.mimeType)
                                        }}>
                                        <RiDeleteBin6Fill color='darkred' size={25} />
                                    </button>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>

            {hasError ? (<><OutsideClick isError setHasError={setHasError} /><div className='fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-10 w-auto mx-auto max-w-2xl bg-white  py-5 px-5 flex flex-col gap-10 items-center justify-center rounded-xl'>
                <div className='flex w-full justify-end'>
                    <AiOutlineClose size={'2rem'} className='hover:rounded-full hover:bg-gray-100 p-2 cursor-pointer' onClick={() => setHasError(false)} />
                </div>
                {/* <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-[90vh]'></div> */}
                <p className='px-10 pb-10 text-red-700 text-lg'>Something went wrong! Please try again</p>
            </div></>) : <></>}
        </>
    );
};

export default LessonContentTable;