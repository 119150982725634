import { useLocation, useNavigate } from 'react-router';
import { SupportOptionCard } from './SupportOptionCard';
import { useEffect, useState } from 'react';
import { HeaderButton } from '../../components/header-button';
import { Link } from 'react-router-dom';

const SupportAdminNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const supportTabNavigation = [
    {
      id: 1,
      option: 'Tickets',
      description: '',
      to: './tickets',
    },
    {
      id: 2,
      option: 'Category',
      description: '',
      to: './categories',
    },
    {
      id: 3,
      option: 'Subcategory',
      description: '',
      to: './subcategories',
    },
  ];

  useEffect(() => {
    if (location.pathname === '/support' || location.pathname === '/support/' || location.pathname === '/support/tickets') {
      navigate('/support/tickets/open/all');
    }
  }, [location.pathname]);

  const [isActive, setIsActive] = useState(1);

  return (
    <div className='flex justify-between items-center flex-wrap min-h-fit gap-5 p-5 flex-row bg-white'>
      <div className='flex flex-wrap gap-5'>
        {supportTabNavigation.map((t) => (
          <SupportOptionCard isActive={t.id === isActive} key={t.option} {...t} />
        ))}
      </div>
      <div className='flex'>
        <Link to='new/ticket'>
          <HeaderButton type='newTicket' />
        </Link>
      </div>
    </div>
  );
};

export default SupportAdminNavigation;