import { InvoicePaymentType } from '../../../utils/constants';
import { dateToLocal } from '../../../utils/dateFormatter';
import { formatter, wordWithFirstLetterToUpperCase } from '../../../utils/utils';

export class InvoiceModel {
    invoiceTypes: {
        [key: string]: string
    } = {
            0: 'Stadium',
            1: 'Coaching Hours',
            2: 'Student',
            3: "Student's partial payment"
        };
    invoiceStatus: {
        [key: string]: string
    } = {
            0: 'Upcoming',
            1: 'Active',
            2: 'Overdue',
            3: 'Inactive',
            4: 'Processing'
        };
    schoolId;
    type;
    typeName;
    startDate;
    formatedStartDate = '';
    endDate;
    formatedEndDate = '';
    total;
    formatedTotal?: string;
    status;
    statusName;
    id;
    schoolName = '';
    orgName = '';
    countStudent;
    seasonName = '';
    firstName = '';
    schName = '';
    schoolActivateStatus?: Boolean;
    schoolState?: string;
    schoolCity?: string;
    schoolAddress?: string;
    schoolZip?: number;
    orgState?: string;
    orgCity?: string;
    orgAddress?: string;
    orgZip?: number;
    orgEmail?: string;
    payType?: string | number;
    payTypeName?: string;
    studentName?: string;
    studentState?: string;
    studentCity?: string;
    studentAddress?: string;
    studentZip?: number;
    studentEmail?: string;
    role?: string;
    defaultStatus?: 'Active' | 'Payment';
    seasonInfo_id?: number;
    season_id?: number;

    constructor(data: any) {
        if (data?.hasOwnProperty('defaultStatus'))
            this.defaultStatus = data.defaultStatus ? 'Active' : 'Payment';



        if (data?.hasOwnProperty('school')) {
            if (data.school?.hasOwnProperty('id'))
                this.schoolId = data.school?.id;



            if (data.school?.hasOwnProperty('status')) { }
            this.schoolState = !data.school?.status ? 'Active' : 'Inactive';
        }
        if (this.seasonName)
            this.role = 'Student';
        else
            this.role = 'OGMA';



        if (data.hasOwnProperty('season')) {
            if (data?.season?.hasOwnProperty('id')) {
                this.season_id = data?.season?.id;
            }
            if (data.hasOwnProperty('user')) {
                if (data.user?.hasOwnProperty('season_info')) {
                    if (data.user?.season_info?.length) {
                        const currSeasInfo = data.user?.season_info?.filter((s: {
                            id: number;
                            defaut_status: number;
                            season_id: number
                        }) => s.season_id === this.season_id,);
                        this.seasonInfo_id = currSeasInfo?.length ? currSeasInfo[0]?.id : undefined;
                        this.defaultStatus = currSeasInfo[0]?.default_status ? 'Active' : 'Payment';
                    }
                }
            }
            if (data.season?.hasOwnProperty('name'))
                this.seasonName = data?.season?.name;



        }
        if (data.hasOwnProperty('user')) {
            if (data.user?.hasOwnProperty('season_info')) {
                if (data.user?.season_info?.hasOwnProperty('id')) {
                    const currSeasInfo = data.user?.season_info?.filter((s: {
                        id: number;
                        defaut_status: number;
                        season_id: number
                    }) => s.season_id === this.season_id,);
                    this.seasonInfo_id = currSeasInfo?.length ? currSeasInfo[0]?.id : undefined;
                    this.defaultStatus = currSeasInfo[0]?.default_status ? 'Active' : 'Payment';
                }
            }
            if (data.user?.hasOwnProperty('firstName') && data.user?.hasOwnProperty('lastName')) {
                this.studentName = `${wordWithFirstLetterToUpperCase(data.user?.firstName,)
                    } ${wordWithFirstLetterToUpperCase(data.user?.lastName)
                    }`;
            }
            if (data.user?.hasOwnProperty('zipCode'))
                this.studentZip = data.user?.zipCode;



            if (data.user?.hasOwnProperty('city'))
                this.studentCity = data.user?.city;



            if (data.user?.hasOwnProperty('state'))
                this.studentState = data.user?.state;



            if (data.user?.hasOwnProperty('streetAddress'))
                this.studentAddress = data.user?.streetAddress;



            if (data.user?.hasOwnProperty('email'))
                this.studentEmail = data.user?.email;



        }
        if (data.hasOwnProperty('payment_type')) {
            this.payType = data.payment_type;
            if (this.payType === InvoicePaymentType.Check)
                this.payTypeName = 'check';
            else
                this.payTypeName = 'card';



        }
        if (data.hasOwnProperty('id'))
            this.id = data.id;



        if (data.hasOwnProperty('type'))
            this.type = data.type;



        if (data.hasOwnProperty('type'))
            this.typeName = this.invoiceTypes[data.type];



        if (data.hasOwnProperty('start_date')) {
            this.startDate = data.start_date;
            this.formatedStartDate = dateToLocal(this.startDate, 'MMMM D, YYYY') ?? '';
        }
        if (data.hasOwnProperty('end_date')) {
            this.endDate = data.end_date;
            this.formatedEndDate = dateToLocal(this.endDate, 'MMMM D, YYYY') ?? '';
        }
        if (data.hasOwnProperty('total')) {
            this.total = data.total;
            this.formatedTotal = formatter.format(data.total);
        }
        if (data.hasOwnProperty('status'))
            this.status = data.status;



        if (data.hasOwnProperty('status'))
            this.statusName = this.invoiceStatus[data.status];



        if (data.hasOwnProperty('school'))
            this.schoolName = data?.school?.name;



        if (data.hasOwnProperty('school') && data?.school?.hasOwnProperty('organization')) {
            this.orgName = data.school?.organization?.name;
            if (data.school?.organization?.hasOwnProperty('state'))
                this.orgState = data.school?.organization?.state;



            if (data.school?.organization?.hasOwnProperty('city'))
                this.orgCity = data.school?.organization?.city;



            if (data.school?.organization?.hasOwnProperty('street_address'))
                this.orgAddress = data.school?.organization?.street_address;



            if (data.school?.organization?.hasOwnProperty('zip_code'))
                this.orgZip = data.school?.organization?.zip_code;



            if (data.school?.organization?.hasOwnProperty('email'))
                this.orgEmail = data.school?.organization?.email;



        }
        if (data.hasOwnProperty('school') && data.school?.hasOwnProperty('state'))
            this.schoolState = data.school?.state;



        if (data.hasOwnProperty('school') && data.school?.hasOwnProperty('city'))
            this.schoolCity = data.school?.city;



        if (data.hasOwnProperty('school') && data.school?.hasOwnProperty('streetAddress'))
            this.schoolAddress = data.school?.streetAddress;



        if (data.hasOwnProperty('count_student'))
            this.countStudent = data.count_student;



        if (data.hasOwnProperty('name'))
            this.firstName = data?.firstName;



        if (data.hasOwnProperty('name'))
            this.firstName = data?.firstName;



        // Added
        if (data?.hasOwnProperty('user') && data?.user?.hasOwnProperty('firstName')) {
            this.firstName = data.user.firstName;
        }
        // if (data?.hasOwnProperty('user') && data?.user?.hasOwnProperty('id')) {
        // this.id = data.user.id;
        // }
        if (data?.hasOwnProperty('school') && data?.school?.hasOwnProperty('organization') && data?.school?.organization?.hasOwnProperty('user') && data?.school?.organization?.user?.hasOwnProperty('firstName')) {
            this.firstName = data.school.organization.user.firstName;
        }
        if (data?.hasOwnProperty('school') && data?.school?.hasOwnProperty('organization') && data?.school?.organization?.hasOwnProperty('user') && data?.school?.organization?.user?.hasOwnProperty('firstName')) { // this.id = data.school.organization.user.id;
        }
        // ends here

        if (data.hasOwnProperty('user') && data.user?.hasOwnProperty('school'))
            this.schName = data.user?.school?.name;



    }
}
