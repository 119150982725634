import { dateToLocal } from '../utils/dateFormatter';

export class DeletedUserModel {
  id;
  firstName;
  lastName;
  deletedOnDate;
  formatedDeletedOnDate;
  constructor(data: any) {
    if (data.hasOwnProperty('id')) this.id = data.id;

    if (data.hasOwnProperty('firstName')) {
      this.firstName = data.firstName;
    }

    if (data.hasOwnProperty('lastName')) this.lastName = data.lastName;

    if (data.hasOwnProperty('deletedOnDate')) this.deletedOnDate = data.deletedOnDate;
    this.formatedDeletedOnDate = dateToLocal(this.deletedOnDate, 'MMMM D, YYYY') ?? '';
  }
}
