import { apiSlice } from '../api/apiSlice';
import { updateUser } from './auth';
import { teamsApiSlice } from './teamsApiSlice';
interface OgmaAcceptInvite {
  token: string;
  recaptcha?: string;
  body: {
    username: string;
    firstName: string;
    lastName: string;
    // streetAddress: string;
    office_number?: string;
    city: string;
    state: string;
    zipCode: number;
    phoneNumber: string;
    password: string;
    confirmPassword: string;
    gender: string;
  };
}

interface CoachAcceptInvite {
  token: string;
  recaptcha?: string;
  body: {
    username: string;
    firstName: string;
    lastName: string;
    streetAddress: string;
    city: string;
    state: string;
    zipCode: number;
    dateOfBirth: string;
    // yearsOfExperience: number;
    tags: string;
    phoneNumber: string;
    password: string;
    confirmPassword: string;
  };
}
export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserHeaderInfobyUserId: builder.query({
      query: (id: number | string) => ({
        url: `/user/header-info/${id}`,
        method: 'GET',
        providesTags: ['User'],
      }),
    }),
    userHeaderInfobyUserId: builder.query({
      query: (id: number | string) => ({
        url: `/user/header-info/${id}`,
        method: 'GET',
        providesTags: ['User'],
      }),
    }),

    refreshUserData: builder.query({
      query: () => ({
        url: `/user/refresh-user`,
        method: 'GET',
      }),
      async onCacheEntryAdded(arg, { cacheDataLoaded, getCacheEntry, dispatch }) {
        try {
          await cacheDataLoaded;
          const entries = getCacheEntry();
          dispatch(updateUser(entries.data));
        } catch (error) {}
      },
    }),
    generateInviteLink: builder.mutation({
      query: ({ email }: { email: string }) => ({
        url: '/user/generate-invite-link',
        method: 'POST',
        body: { email },
      }),
    }),
    resendInvitation: builder.mutation({
      query: ({ email }: { email: string }) => ({
        url: '/user/resend-invitation',
        method: 'PUT',
        body: { email },
      }),
      invalidatesTags: ['User'],
    }),
    changeGradeLevel: builder.mutation({
      query: ({ id, gradeLevel }: { id: string; gradeLevel: string }) => ({
        url: `/user/change-grade/${id}`,
        method: 'POST',
        body: { gradeLevel },
      }),
      invalidatesTags: ['User', 'SingleUser'],
    }),
    toggleEligibility: builder.mutation({
      query: (id: string | number) => ({
        url: `/user/toggle-eligibility/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['User', 'SingleUser', 'School'],
    }),
    editAboutTextCoach: builder.mutation({
      query: ({ id, body }: { id: number; body: { about: string } }) => ({
        url: `/user/edit-about/${id}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['SingleUser'],
    }),
    acceptUserInvite: builder.mutation({
      query: ({ token, body, recaptcha }: OgmaAcceptInvite | CoachAcceptInvite) => ({
        url: '/user/accept-invite/user',
        method: 'POST',
        body,
        headers: { authorization: token, recaptcha: recaptcha },
      }),
    }),
    inviteUser: builder.mutation({
      query: ({
        body,
        params,
      }: {
        body: { email: string; organization_id?: number };
        params: { role: number };
      }) => ({
        url: '/user/invite/user',
        method: 'POST',
        body,
        params,
      }),
    }),
    userExportList: builder.mutation({
      query: (data: {
        page: string;
        query: {
          unfilter?: string | undefined;
        };
      }) => ({
        url: `/user/export/${data.page}`,
        method: 'GET',
        params: data.query,
        responseHandler: async (response) => {
          if (
            response.status === 200 &&
            response.headers.get('content-type')?.includes('text/csv')
          ) {
            window.open(
              window.URL.createObjectURL(await response.blob()),
              '_blank',
              'noopener,noreferrer',
            );
          } else {
            console.error('Unexpected content type or response status');
          }
        },
        cache: 'no-cache',
      }),
    }),

    getUsersShortInfo: builder.query({
      query: (id: string) => ({
        url: `/user/short-info/${id}`,
        method: 'GET',
      }),
      providesTags: ['ShortInfo'],
    }),
    // token from email
    sendStudentInvitations: builder.mutation({
      query: (body: { school_id: number; list_students: string[]; eligibilityStatus: number }) => ({
        url: '/user/invite-students',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['User'],
    }),
    acceptStudentInvite: builder.mutation({
      query: ({ token, body, recaptcha }) => ({
        url: '/user/accept-student-invite',
        method: 'POST',
        body,
        headers: { authorization: token, recaptcha: recaptcha },
      }),
    }),
    acceptSchoolInvite: builder.mutation({
      query: ({ token, body, recaptcha }) => ({
        url: '/user/accept-school-invite',
        method: 'POST',
        body,
        headers: { authorization: token, recaptcha: recaptcha },
      }),
    }),
    updateTwitchUsername: builder.mutation({
      query: ({ login, id }) => ({
        url: `/user/set-twitch-name?name=${login}&twId=${id}`,
        method: 'Post',
      }),
    }),
    getAllUsers: builder.query({
      query: (query) => ({
        url:
          // query
          // ? `/user/all?${query.query}=${query.param}&page=${query.page ? query.page : 1}`
          '/user/all',
        method: 'GET',
        params: query.params ?? {
          [query.query]: query.param,
          page: query.page ?? 1,
        },
      }),
      providesTags: ['User'],
    }),
    getAllUsersByRole: builder.query({
      query: ({ id, params }: { id: number; params: any }) => ({
        url: `/user/roles/${id}`,
        method: 'GET',
        params,
      }),
      providesTags: ['User'],
    }),
    getUserById: builder.query({
      query: (id) => ({
        url: `/user/${id}`,
        method: 'get',
      }),
      providesTags: ['SingleUser', 'User', 'School'],
    }),
    updateUser: builder.mutation({
      query: ({ id, body }) => ({
        url: `/user/edit-user/${id}`,
        method: 'put',
        body,
      }),
      invalidatesTags: ['SingleUser', 'User', 'School'],
    }),

    getUserSettings: builder.query({
      query: () => ({
        url: '/user/settings',
        method: 'GET',
      }),
      providesTags: ['User'],
    }),
    updateUserSettings: builder.mutation({
      query: (data: {
        frequency?: number;
        unseenNotification?: boolean;
        promotionNotification?: boolean;
      }) => ({
        url: '/user/settings',
        method: 'PUT',
        body: data,
      }),
      async onQueryStarted(arg, api) {
        console.log('onQueryStarted', arg, api);
        const patchResult = api.dispatch(
          userApiSlice.util.updateQueryData('getUserSettings', '', (draft) =>
            Object.assign(draft, arg),
          ),
        );
        console.log('patchResult', patchResult);
        try {
          console.log('awaiting');
          await api.queryFulfilled;
          console.log('fulfilled');
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: ['User'],
    }),
    changePassword: builder.mutation({
      query: (data: { currentPassword: string; confirmPassword: string; newPassword: string }) => ({
        url: '/user/change-pass',
        method: 'PUT',
        body: data,
      }),
    }),
    sendEmailVerification: builder.mutation({
      query: () => ({
        url: '/user/email-verify',
        method: 'Get',
      }),
    }),
    verifyAccount: builder.mutation({
      query: (pin: string) => ({
        url: 'user/verify',
        method: 'post',
        body: { pin },
      }),
    }),
    updateAccountSettings: builder.mutation({
      query: (body) => ({
        url: 'user/account-settings',
        method: 'put',
        body,
      }),
    }),
    createAdmin: builder.mutation({
      query: (body) => ({
        url: 'user/create-admin',
        method: 'post',
        body,
      }),
      invalidatesTags: ['User'],
    }),
    createOgma: builder.mutation({
      query: (body) => ({
        url: 'user/create-ogma',
        method: 'post',
        body,
      }),
      invalidatesTags: ['User', 'Organization', 'OrgOgmas'],
    }),
    createStudent: builder.mutation({
      query: (body) => ({
        url: 'user/create-student',
        method: 'post',
        body,
      }),
      invalidatesTags: ['User'],
    }),
    createCoach: builder.mutation({
      query: (body) => ({
        url: 'user/create-coach',
        method: 'post',
        body,
      }),
      invalidatesTags: ['User'],
    }),
    updateCoach: builder.mutation({
      query: (body) => ({
        url: 'user/edit-coach',
        method: 'put',
        body,
      }),
      invalidatesTags: ['User'],
    }),
    createGuardian: builder.mutation({
      query: (body) => ({
        url: 'user/create-guardian',
        method: 'post',
        body,
      }),
      invalidatesTags: ['User'],
    }),
    archiveUser: builder.mutation({
      query: (id) => ({
        url: `user/archive/${id}`,
        method: 'post',
      }),
      onQueryStarted: async (_, api) => {
        try {
          await api.queryFulfilled;
          api.dispatch(apiSlice.util.resetApiState());
        } catch (error) {
          console.log(error);
        }
      },
      invalidatesTags: ['User', 'Unarchived', 'Team', 'SingleTeam'],
    }),
    unarchiveUser: builder.mutation({
      query: (id) => ({
        url: `user/unarchive/${id}`,
        method: 'post',
      }),
      invalidatesTags: ['User'],
    }),
    // getUsersWithoutTeam: builder.query({
    //   query: ({ id, schoolId }) => ({
    //     url: `user/without-team/${id}/${schoolId}`,
    //     method: 'get',
    //   }),
    //   providesTags: ['UserWithoutTeam'],
    // }),
    addUserToTeam: builder.mutation({
      query: (data: { id: string; studentid: number }) => ({
        url: `user/add-team/${data.id}`,
        method: 'post',
        body: { id: data.studentid },
      }),
      invalidatesTags: ['UserWithoutTeam', 'Team', 'Organization'],
    }),
    changeUsersTeam: builder.mutation({
      query: (data) => ({
        url: `/user/change-team/${data.id}`,
        method: 'put',
        body: data.body,
      }),
      invalidatesTags: ['Team'],
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          teamsApiSlice.util.updateQueryData('getTeamById', data.id, (draft) => {
            console.log('onquery', data);
            console.log('test from api slice 1', draft);
            if (data.body.team === 'substitudes') {
              const arr1 = draft.activeMembers.filter((d: number) => d !== +data.body.studentId);
              const arr2 = [...data.current.subMembers, +data.body.studentId];
              draft.activeMembers = arr1;
              draft.subMembers = arr2;
            } else if (data.body.team === 'active') {
              const arr1 = draft.subMembers.filter((d: number) => d !== +data.body.studentId);
              const arr2 = [...data.current.activeMembers, +data.body.studentId];

              draft.subMembers = arr1;
              draft.activeMembers = arr2;
            } else if (typeof data.body.team === 'number') {
              // [0] team to place dragged player, [1] team to place dropped player
              const toReplace = (draft.activeMembers as number[]).some((u) => u === data.body.team)
                ? ['activeMembers', 'subMembers']
                : ['subMembers', 'activeMembers'];
              const arr1 = [
                ...draft[toReplace[0]].filter((d: number) => d !== data.body.team),
                +data.body.studentId,
              ];
              const arr2 = [
                ...draft[toReplace[1]].filter((d: number) => d !== +data.body.studentId),
                data.body.team,
              ];
              draft[toReplace[0]] = arr1;
              draft[toReplace[1]] = arr2;
            }
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    changeUsersMatchTeamRole: builder.mutation({
      query: (data) => ({
        url: `user/change-matchteam/${data.id}`,
        method: 'put',
        body: data.body,
      }),
      invalidatesTags: ['Team'],
    }),
    getAllStudentBySchoolId: builder.query({
      query: ({
        id,
        params,
      }: {
        id: number | string;
        params: {
          team?: string;
          coopOnly?: boolean;
          name?: string;
          page?: string;
          withoutTeam?: string;
          organization?: string;
          school?: string;
        };
      }) => ({
        url: `/user/by-school/${id}`,
        method: 'Get',
        params,
      }),
      providesTags: ['SchoolStudent', 'School', 'User', 'UserWithoutTeam'],
    }),
    removeUserFromTeam: builder.mutation({
      query: (data) => ({
        url: `/user/remove-team/${data.id}`,
        method: 'Delete',
        body: data.body,
      }),
      invalidatesTags: ['Team', 'UserWithoutTeam', 'Organization'],
    }),
    setCaptainOfTheTeam: builder.mutation({
      query: (data) => ({
        url: `/user/captain/${data.id}`,
        method: 'Post',
        body: { studentId: data.studentId },
      }),
      invalidatesTags: ['Team'],
    }),
    getNewestStudent: builder.query({
      query: () => ({
        url: '/user/latest/students',
        method: 'GET',
      }),
      providesTags: ['latestStudents'],
    }),
    // uploadImage: builder.mutation({
    //   query: (image) => ({
    //     url: `/user/upload-image`,
    //     method: 'POST',
    //     body: image,
    //   }),
    // }),
    changeUserProfilePhoto: builder.mutation({
      query: ({ photo, id }) => ({
        url: `/user/profile-photo/${id}`,
        method: 'PUT',
        body: photo,
      }),
      invalidatesTags: ['User', 'SingleUser'],
    }),
    changeUserBannerPhoto: builder.mutation({
      query: ({ photo, id }) => ({
        url: `/user/banner-photo/${id}`,
        method: 'PUT',
        body: photo,
      }),
      invalidatesTags: ['User', 'SingleUser'],
    }),
    // getFavoriteGamesByUserId: builder.query({
    //   query: (id) => ({
    //     url: `/user/favorite-games/${id}`,
    //     method: 'GET',
    //   }),
    //   providesTags: ['FavoriteGames', 'Game'],
    // }),
    saveFavoriteStreamer: builder.mutation({
      query: (body) => ({
        url: '/user/favorite-streamers',
        method: 'PUT',
        body: { favoriteStreamer: body.favoriteStreamer },
      }),
      invalidatesTags: ['User'],
    }),
    getAllFollowedStreamers: builder.query({
      query: () => ({
        url: '/user/followedStreamers',
        method: 'Get',
      }),
      providesTags: ['User'],
    }),
    deleteFavoriteStreamer: builder.mutation({
      query: (body) => ({
        url: '/user/favorite-streamers',
        method: 'DELETE',
        body: {
          favoriteStreamer: body.favoriteStreamer,
          favoriteStreamerArr: body.favoriteStreamerArr,
        },
      }),
      invalidatesTags: ['User'],
    }),
    searchCoachByName: builder.mutation({
      query: (data) => ({
        url: `/user/coach-by-name?firstName=${data}`,
        method: 'Get',
      }),
    }),
    // getAllStudentsWithoutGuardian: builder.query({
    //   query: () => ({
    //     url: '/user/without-guardian',
    //     method: 'GET',
    //   }),
    //   providesTags: ['User'],
    // }),
    // searchStudentsWithouGuardianByEmail: builder.mutation({
    //   query: (email) => ({
    //     url: `/user/students/by-email?email=${email}`,
    //     method: 'GET',
    //   }),
    //   invalidatesTags: ['User'],
    // }),
    getGuardianById: builder.query({
      query: (id) => ({
        url: `/user/guardian/${id}`,
        method: 'GET',
      }),
      providesTags: ['User', 'SingleUser'],
    }),
    connectStudentWithGuardian: builder.mutation({
      query: (body) => ({
        url: `/user/connect-guardian`,
        method: 'PUT',
        body: {
          guardianId: body.guardianId,
          studentIds: body.studentIds,
        },
      }),
      invalidatesTags: ['User'],
    }),
    // getAllTeamsByUserId: builder.query({
    //   query: (id) => ({
    //     url: `/user/teams-by-user/${id}`,
    //     method: 'Get',
    //   }),
    //   providesTags: ['User', 'Team'],
    // }),
    // getGuardianByUserId: builder.query({
    //   query: (id) => ({
    //     url: `/user/guardian-by-user/${id}`,
    //     method: 'Get',
    //   }),
    //   providesTags: ['User'],
    // }),
    // getAllGamesByUserId: builder.query({
    //   query: (id) => ({
    //     url: `/user/all-games-by-user/${id}`,
    //     method: 'Get',
    //   }),
    //   providesTags: ['User', 'Game'],
    // }),
    getGuardianStudentsById: builder.query({
      query: (id) => ({
        url: `/user/students/${id}`,
        method: 'Get',
      }),
      providesTags: ['User'],
    }),
    // getAllArchivedUsers: builder.query({
    //   query: () => ({
    //     url: '/user/archived-user',
    //     method: 'Get',
    //   }),
    //   providesTags: ['User'],
    // }),
    disconnectTwitchName: builder.mutation({
      query: (body) => ({
        url: '/user/disconnect-twitch-name',
        method: 'Post',
        body,
      }),
    }),
    getAllStudentsByOrgId: builder.query({
      query: (id) => ({
        url: `/user/students-by-org/${id}`,
        method: 'GET',
      }),
      providesTags: ['ArchiveStudent'],
    }),
    // archiveStudentById: builder.mutation({
    //   query: (id) => ({
    //     url: `/user/archive-student/${id}`,
    //     method: 'Post',
    //   }),
    //   invalidatesTags: ['ArchiveStudent', 'Team'],
    // }),
    deleteUser: builder.mutation({
      query: (id) => ({
        url: `/user/${id}`,
        method: 'Delete',
      }),
      invalidatesTags: ['User'],
    }),
    deleteOwnUser: builder.mutation({
      query: () => ({
        url: `/user/delete/self`,
        method: 'Delete',
      }),
      invalidatesTags: ['User'],
    }),
    GetDeletedUsers: builder.query({
      query: (params: { page?: number; limit?: number }) => ({
        url: '/user/deleted-users',
        method: 'Get',
        params,
      }),
      providesTags: ['User'],
    }),
    getAllUsersByIds: builder.query({
      query: (id: number) => ({
        url: `/user/by-ids/${id}`,
        method: 'GET',
      }),
      providesTags: ['User'],
    }),
    getUsernamesByUserById: builder.query({
      query: () => ({
        url: `/user/usernames`,
        method: 'get',
      }),
      providesTags: ['SingleUser', 'User', 'School'],
    }),
    // getUserGradeLevel: builder.query({
    //   query: (id: number) => ({
    //     url: `/user/grade/${id}`,
    //     method: 'GET',
    //   }),
    //   providesTags: ['SingleUser', 'User'],
    // }),
    getUserBannerImage: builder.query({
      query: (id: string | number) => ({
        url: `/user/banner/${id}`,
        method: 'GET',
      }),
      providesTags: ['SingleUser', 'User'],
    }),
    getStreamerById: builder.query({
      query: (id: string | number) => ({
        url: `/user/streamer/${id}`,
        method: 'GET',
        providesTags: ['SingleUser', 'User', 'School'],
      }),
    }),
    getCoachById: builder.query({
      query: (id: string | number) => ({
        url: `/user/coach/${id}`,
        method: 'GET',
        providesTags: ['SingleUser', 'User', 'School'],
      }),
    }),

    studentProfileViewPermission: builder.query({
      query: (id) => ({
        url: `/user/view-student-profile/${id}`,
        method: 'GET',
      }),
      providesTags: ['User'],
    }),

    booleanDelete: builder.query({
      query: (id) => ({
        url: `/user/boolean-delete/${id}`,
        method: 'Get',
      }),
      providesTags: ['SingleUser', 'User'],
    }),
  }),
});

export const {
  useChangeGradeLevelMutation,
  useToggleEligibilityMutation,
  useEditAboutTextCoachMutation,
  useGetUsersShortInfoQuery,
  // useGetAllTeamsByUserIdQuery,
  // useGetGuardianByUserIdQuery,
  // useGetAllGamesByUserIdQuery,
  useSendStudentInvitationsMutation,
  useAcceptStudentInviteMutation,
  useAcceptSchoolInviteMutation,
  useUpdateTwitchUsernameMutation,
  useGetAllUsersQuery,
  useGetAllUsersByRoleQuery,
  useGetUserSettingsQuery,
  useUpdateUserSettingsMutation,
  useChangePasswordMutation,
  useSendEmailVerificationMutation,
  useVerifyAccountMutation,
  useCreateAdminMutation,
  useCreateOgmaMutation,
  useCreateStudentMutation,
  useCreateCoachMutation,
  useUpdateCoachMutation,
  useCreateGuardianMutation,
  useGetUserByIdQuery,
  useArchiveUserMutation,
  // useGetUsersWithoutTeamQuery,
  useAddUserToTeamMutation,
  useChangeUsersTeamMutation,
  useGetAllStudentBySchoolIdQuery,
  useRemoveUserFromTeamMutation,
  useSetCaptainOfTheTeamMutation,
  useGetNewestStudentQuery,
  useChangeUserProfilePhotoMutation,
  // useUploadImageMutation,
  // useGetFavoriteGamesByUserIdQuery,
  useChangeUsersMatchTeamRoleMutation,
  useSaveFavoriteStreamerMutation,
  useGetAllFollowedStreamersQuery,
  useDeleteFavoriteStreamerMutation,
  useSearchCoachByNameMutation,
  useChangeUserBannerPhotoMutation,
  // useGetAllStudentsWithoutGuardianQuery,
  useGetGuardianByIdQuery,
  useConnectStudentWithGuardianMutation,
  // useSearchStudentsWithouGuardianByEmailMutation,
  useUserExportListMutation,
  useInviteUserMutation,
  useAcceptUserInviteMutation,
  useGetGuardianStudentsByIdQuery,
  // useGetAllArchivedUsersQuery,
  useUnarchiveUserMutation,
  useDisconnectTwitchNameMutation,
  useResendInvitationMutation,
  useRefreshUserDataQuery,
  useGenerateInviteLinkMutation,
  useGetAllStudentsByOrgIdQuery,
  // useArchiveStudentByIdMutation,
  useDeleteUserMutation,
  useDeleteOwnUserMutation,
  useGetDeletedUsersQuery,
  useUpdateAccountSettingsMutation,
  useUpdateUserMutation,
  useGetAllUsersByIdsQuery,
  useStudentProfileViewPermissionQuery,
  useGetUsernamesByUserByIdQuery,
  // useGetUserGradeLevelQuery,
  useGetUserBannerImageQuery,
  useGetStreamerByIdQuery,
  useGetCoachByIdQuery,
  useGetUserHeaderInfobyUserIdQuery,
  useUserHeaderInfobyUserIdQuery,
  useBooleanDeleteQuery,
} = userApiSlice;
