import { useState } from 'react';
import { BsThreeDots } from 'react-icons/bs';
import DropdownItem from '../../../../../features/listview/DropDown/DropDownItem';
import { dateAndTimeFormatter } from '../../../../../utils/dateFormatter';
const defaultBg = (await import('../../../../../assets/grid-background.jpg')).default;

export function DivisionGridCard({ ...d }) {
  const [openList, setOpenList] = useState(null);

  function dateAndTimeFormater(arg0: any) {
    throw new Error('Function not implemented.');
  }

  return (
    <div className='relative flex flex-col rounded-xl w-[300px] aspect-square overflow-hidden bg-white border-2 border-gr-200'>
      <div
        onClick={() => setOpenList(d.id)}
        className='absolute cursor-pointer top-2 right-2 p-1 px-2 rounded-lg bg-black bg-opacity-80'
      >
        <BsThreeDots color='white' className='group' />
      </div>

      <div className={`listViewMenu--parent--grid  ${openList === d.id ? 'flex' : 'hidden'}`}>
        <div
          className='listViewMenu !absolute'
          role='menu'
          aria-orientation='vertical'
          aria-labelledby='menu-button'
        >
          <div className='' role='none'>
            <DropdownItem id={d.id} setFunc={setOpenList} noView={true} />
          </div>
        </div>
      </div>

      <img
        src={d.background_image ? d.background_image : defaultBg}
        className='max-h-[50%] object-cover w-full h-full overflow-hidden'
      />
      <div className='flex p-1 flex-col justify-between  h-[50%] w-full'>
        <div className='flex justify-between'>
          <h2 className='font-bold uppercase text-gray-900 ml-3 mb-4 max-w-[15ch] truncate'>{d.name}</h2>
        </div>

        <div className='flex justify-between'>
          <div className='flex flex-col text-sm self-end border-t-2 border-b-neutral-200 w-full items-center p-2'>
            <h3 className='text-gray-900 font-bold'>Created by:</h3>
            <p className='text-gray-900 max-w-[15ch] truncate'>{d.user.firstName}</p>
            <div className='flex flex-col text-sm border-t-2 border-neutral-200 w-full items-center p-2'>
              <h3 className='text-gray-900 font-bold'>Created at:</h3>
              <p className='text-gray-900'>{dateAndTimeFormatter((d.created_at)).split(';')[0]}</p>
            </div>
          </div>

          {/* <div className='flex flex-col text-sm self-end'>
            <p className='text-gray-900'>{t.CategorySubDivision[0]?.name}</p>
            <p className='group text-gray-900 relative select-none'>
              {t.CategorySubDivision.slice(1).length
                ? `+${t.CategorySubDivision.slice(1).length} more`
                : ''}
              {t.CategorySubDivision.slice(1).length ? (
                <div className='hidden absolute bottom-[100%] flex-col p-2 bg-white rounded-lg group-hover:flex'>
                  {t.CategorySubDivision.slice(1).map((s: any, i: number) => (
                    <span key={i}>{s.name}</span>
                  ))}
                </div>
              ) : (
                <></>
              )}
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
}
