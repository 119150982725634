import React from 'react';
import { Link } from 'react-router-dom';
import { firstLetterToUpperCase, wordWithFirstLetterToUpperCase } from '../utils/utils';
import { Avatar, AvatarFallback, AvatarImage } from './ui/avatar/Avatar';
import CanIView from './can-i-view/can-i-view';
export function UserHeader({
  name,
  lastName,
  image,
  school,
  schoolId,
  id,
  username,
  noStudent,
}: {
  name: string;
  lastName?: string;
  image?: string;
  school?: string;
  schoolId?: string | number;
  id?: number;
  username?: string | any;
  noStudent?: boolean;
}) {
  return (
    // min-w-[600px]
    <div className='flex justify-between p-5 items-center  bg-white rounded-xl'>
      <div className='flex gap-x-4 items-center '>
        <Avatar className='h-20 w-20 items-center'>
          <AvatarImage src={image} />
          <AvatarFallback>
            {firstLetterToUpperCase(name) + firstLetterToUpperCase(lastName || '')}
          </AvatarFallback>
        </Avatar>
        <h2 className='font-bold '>
          Hi,
          {username ? wordWithFirstLetterToUpperCase(username) : ''}
        </h2>
      </div>
      {noStudent ? (
        <></>
      ) : (
        <>
          <div className='flex gap-x-4'>
            <CanIView component='admin.inviteStudents'>
              <Link to='./invite-students' className='button hover:bg-opacity-80'>
                Invite Students
              </Link>
            </CanIView>
          </div>
        </>
      )}
      {school ? (
        <Link to={`/schools/view/${schoolId}`} className='  '>
          {/* <p className='text-[10px] underline underline-offset-2 pr-24'>My school</p> */}
          <button className='text-base rounded-xl text-white bg-red-700 p-2 px-3 '>
            My school
          </button>
        </Link>
      ) : null}
    </div>
  );
}
