import { RouteObject, RouterProvider, createBrowserRouter } from 'react-router-dom';
import GuardianVerification from '../../features/authentication/GuardianVerification';
import Login from '../../features/authentication/Login/Login';
import ResetPassword from '../../features/authentication/ResetPassword';
import ResetPasswordForm, { loader } from '../../features/authentication/reset-password-form';
import ProtectedRoutes from '../../protected-routes';
import FirstTime from '../../routes/first-time/first-time';
import InvitedUsers, {
  loader as InvitedUsersLoader,
  SchoolLoader as InvitedSchoolLoader,
} from '../../routes/invite-users/invite-users';
import WentWrong from '../../routes/went-wrong/went-wrong';
import { useSelector } from 'react-redux';
import { selectUser } from '../../slices/auth';
import { useEffect, useState } from 'react';
import { UserRoles } from '../../utils/constants';
import { adminProtectedRoutes } from './utils/protected-routes-admin';
import { ogmaProtectedRoutes } from './utils/protected-routes-ogma';
import { studentProtectedRoutes } from './utils/protected-routes-student';
import { coachProtectedRoutes } from './utils/protected-routes-coach';
import { fanProtectedRoutes } from './utils/protected-routes-fan';
import { guardianProtectedRoutes } from './utils/protected-routes-guardian';
import SupportModal from '../support-modal/SupportModal';

type Props = {};
const RouteInit = (props: Props) => {
  const [protectedRoutes, setProtectedRoutes] = useState<RouteObject[]>(adminProtectedRoutes);
  const router = createBrowserRouter([
    // {
    //   path: "/test-twitch",
    //   element: <TestTwitch />,
    // },
    {
      path: '/',
      element: <ProtectedRoutes />,
      children: protectedRoutes,
      // ErrorBoundary: () => <WentWrong />,
      // children: [],
    },
    {
      path: '/went-wrong',

      Component: (prop) => <WentWrong {...prop} />,
      // element: <WentWrong />,
    },
    {
      path: '/auth',
      element: <Login />,
      children: [
        // {
        //   path: 'fan-login',
        //   element: <SocialLogin />,
        // },
        {
          path: 'support',
          element: <SupportModal />,
        },
      ],
    },
    // {
    //   path: '/auth/register',
    //   element: <Register />,
    // },
    {
      path: '/auth/invite/:token',
      element: <InvitedUsers isSchoolRegistrationLink={false} />,
      loader: InvitedUsersLoader,
      children: [
        {
          path: 'student',
          element: <p>Student</p>,
        },
        {
          path: 'admin',
          element: <p>Admin</p>,
        },
        {
          path: 'ogma',
          element: <p>Ogma</p>,
        },
        {
          path: 'guardian',
          element: <p>Guardian</p>,
        },
      ],
    },
    {
      path: '/auth/invite/school/:token',
      element: <InvitedUsers isSchoolRegistrationLink={true} />,
      loader: InvitedSchoolLoader,
      children: [
        {
          path: 'student',
          element: <p>Student</p>,
        },
        {
          path: 'admin',
          element: <p>Admin</p>,
        },
        {
          path: 'ogma',
          element: <p>Ogma</p>,
        },
        {
          path: 'guardian',
          element: <p>Guardian</p>,
        },
      ],
    },
    {
      path: '/auth/reset-password',
      element: <ResetPassword />,
    },
    { path: '/guardian-verification', element: <GuardianVerification /> },
    {
      path: '/first-time',
      element: <FirstTime />,
    },

    {
      path: '/auth/reset/:token',
      element: <ResetPasswordForm />,
      loader: loader,
    },
  ]);
  const user = useSelector(selectUser);
  useEffect(() => {
    if (!user) return;
    switch (user?.roles) {
      case UserRoles.Admin:
        setProtectedRoutes(adminProtectedRoutes);
        break;
      case UserRoles.Ogma:
        setProtectedRoutes(ogmaProtectedRoutes);
        break;
      case UserRoles.Student:
        setProtectedRoutes(studentProtectedRoutes);
        break;
      case UserRoles.Coach:
        setProtectedRoutes(coachProtectedRoutes);
        break;
      case UserRoles.Fan:
        setProtectedRoutes(fanProtectedRoutes);
        break;
      case UserRoles.Guardian:
        setProtectedRoutes(guardianProtectedRoutes);
        break;
      default:
        break;
    }
  }, [user]);
  return <RouterProvider router={router} />;
};

export default RouteInit;
