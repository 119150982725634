import { HoursRequestRow } from './component/hours-request-row';
import { useSelector } from 'react-redux';
import { UserHeader } from '../../components/user-header';
import { selectUser } from '../../slices/auth';
import { OGMADashSchoolRow } from './components/ogma-dash-school-row';
import { Link, Outlet } from 'react-router-dom';
import { useGetOrgByIdQuery } from '../../slices/organizationApiSlice';
import { useGetRequestForOrgQuery } from '../../slices/classroomApiSlice';
import { useEffect, useState } from 'react';
import { SchoolRequestsTitleModel } from './model/school-requests-title.model';
import { StudentDashUpMatches } from '../student-dashboard/components/student-dash-up-matches';
import { SponsorRow } from '../../components/sponsor-row/sponsor-row';
import { SponsorPlacements } from '../../utils/constants';
import { StudentDashLiveMatches } from '../student-dashboard/components/student-dash-live-matches';

function OGMADashboard() {
  const user = useSelector(selectUser);
  const [reqModel, setReqModel] = useState<SchoolRequestsTitleModel>();
  const { data: organizationData } = useGetOrgByIdQuery({
    id: user?.organizations[0]?.id,
    query: '',
  });
  const { data, isLoading, isError } = useGetRequestForOrgQuery('');

  console.log('reqModel', reqModel);

  console.log('organization data', organizationData);

  if (data) {
    console.log('OGMA Data', data);
  }

  useEffect(() => {
    if (!data) return;
    setReqModel(new SchoolRequestsTitleModel(data));
  }, [data]);

  return (
    <>
      <div className='flex flex-col p-10 gap-10'>
        <SponsorRow name={SponsorPlacements.Dashboard} />
        <div className='flex flex-col gap-10'>
          <div className='flex gap-10 items-center flex-wrap justify-between sticky bg-white top-0'>
            <UserHeader
              name={user.firstName}
              image={user.image}
              id={user.id}
              username={user.username}
            />
            <div className='flex flex-wrap gap-x-4 pr-5'>
              <Link to='./invite-students' className='button hover:bg-opacity-80'>
                Invite Students
              </Link>
              {/* <Link to='./subscription-plans' className='button hover:bg-opacity-80'>
                Change Subscription Plans
              </Link>
              <Link to='./coach-hours' className='button hover:bg-opacity-80'>
                Manage Coach Hours
              </Link> */}
            </div>
          </div>
          {/* <OGMADashUpMatches />  */}
          <StudentDashLiveMatches />
          <StudentDashUpMatches />
        </div>
        <div className='flex justify-center gap-10 flex-wrap'>
          <div className='flex flex-col items-center gap-5 realtive w-full sm:w-[100%] lg:w-[40%] border-1 rounded-xl border-slate-300'>
            <h2 className='font-bold text-lg pt-5'>Coach Hours Requests</h2>
            <div className='flex flex-col w-full bg-white rounded-xl max-w-[100%] min-h-[300px]'>
              {isLoading ? (
                <p className='text-left pl-5'>Loading...</p>
              ) : (
                <>
                  {reqModel && reqModel.requests.length ? (
                    reqModel.getSchools()?.map((d, i: number) => {
                      // const m = new StudentCoachHoursRequestModel(d);
                      const school = JSON.parse(d);
                      return (
                        <HoursRequestRow key={i} school={school} requests={reqModel.requests} />
                      );
                    })
                  ) : (
                    <p className='text-left p-2 pl-5 mb-5'>No upcoming activities!</p>
                  )}
                </>
              )}
            </div>
          </div>
          <div className='flex flex-col items-center gap-5 realtive w-full sm:w-[100%] lg:w-[40%] border-1 rounded-xl border-slate-300'>
            <h2 className='font-bold text-center text-lg pt-5'>Organization's Schools</h2>
            <div className='flex flex-col w-full bg-white text-white rounded-xl max-w-[100%] min-h-[300px]'>
              {organizationData ? (
                organizationData?.schools?.map((school: { id: number; name: string }) => (
                  <OGMADashSchoolRow key={school.id} schoolId={school.id} school={school.name} />
                ))
              ) : (
                <p className='text-left pl-5'>Organization doesn't have any school!</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
}

export default OGMADashboard;
