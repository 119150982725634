import dashboard from './dashboard.svg';
import tournament from './tournament.svg';
import seasons from './seasons.svg';
import Schedule from './Schedule.svg';
import game from './game.svg';
import classrooms from './classrooms.svg';
import gamearena from './gamearena.svg';
import teams from './teams.svg';
import users from './users.svg';
import invoices from './invoices.svg';
import schools from './schools.svg';
import organizations from './organizations.svg';
import coaches from './coaches.svg';
import settings from './settings.svg';
import helpcenter from './helpcenter.svg';
import darkmode from './darkmode.svg';
import logout from './logout.svg';
import archive from './archive.svg';
import subscriptions from './subscriptions.svg';
import termsOfService from './terms-of-service.svg';
import { UserRoles } from '../utils/constants';

export const gridOrderImage = (props) => (
  <div>
    <img className='rounded-xl h-20 md:ml-3' src={props.ProductImage} alt='order-item' />
  </div>
);

export const gridOrderStatus = (props) => (
  <button
    type='button'
    style={{ background: props.StatusBg }}
    className='text-white py-1 px-2 capitalize rounded-2xl text-md'
  >
    {props.Status}
  </button>
);

export const links = [
  {
    title: '',
    links: [
      {
        roles: [
          UserRoles.Admin,
          UserRoles.Ogma,
          UserRoles.Student,
          UserRoles.Coach,
          UserRoles.Guardian,
          UserRoles.Fan,
        ],
        name: 'Dashboard',
        icon: dashboard,
      },
      {
        roles: [
          // UserRoles.Admin,
          // UserRoles.Ogma,
          UserRoles.Student,
          // UserRoles.Coach,
          // UserRoles.Guardian,
          // UserRoles.Fan,
        ],
        name: 'My Profile',
        icon: users,
      },
      {
        roles: [
          UserRoles.Admin,
          // UserRoles.Ogma,
          // UserRoles.Student,
          // UserRoles.Coach,
          UserRoles.Guardian,
          UserRoles.Fan,
        ],
        name: 'Tournaments',
        icon: tournament,
        href: 'tournaments/all',
      },

      {
        roles: [
          UserRoles.Admin,
          UserRoles.Ogma,
          UserRoles.Student,
          // UserRoles.Coach,
          UserRoles.Guardian,
          UserRoles.Fan,
        ],
        name: 'Seasons',
        icon: seasons,
        href: 'seasons/all',
      },
      {
        roles: [
          UserRoles.Admin,
          UserRoles.Ogma,
          UserRoles.Student,
          // UserRoles.Coach,
          UserRoles.Guardian,
          UserRoles.Fan,
        ],
        name: 'Schedule',
        icon: Schedule,
      },
      {
        roles: [
          UserRoles.Admin,
          UserRoles.Ogma,
          UserRoles.Student,
          UserRoles.Coach,
          UserRoles.Guardian,
          UserRoles.Fan,
        ],
        name: 'Games',
        icon: game,
      },
      {
        roles: [
          UserRoles.Admin,
          UserRoles.Ogma,
          UserRoles.Student,
          UserRoles.Coach,
          // UserRoles.Guardian,
          // UserRoles.Fan,
        ],
        name: 'Classrooms',
        icon: classrooms,
      },
      {
        roles: [
          UserRoles.Admin,
          // UserRoles.Ogma,
          // UserRoles.Student,
          // UserRoles.Coach,
          // UserRoles.Guardian,
          // UserRoles.Fan,
        ],
        name: 'GameArena',
        icon: gamearena,
      },
      {
        roles: [
          UserRoles.Admin,
          UserRoles.Ogma,
          UserRoles.Student,
          // UserRoles.Coach,
          UserRoles.Guardian,
          // UserRoles.Fan,
        ],
        name: 'Teams',
        icon: teams,
      },
      {
        roles: [
          UserRoles.Admin,
          UserRoles.Ogma,
          // UserRoles.Student,
          // UserRoles.Coach,
          // UserRoles.Guardian,
          // UserRoles.Fan,
        ],
        name: 'Users',
        icon: users,
      },
      {
        roles: [
          // UserRoles.Admin,
          // UserRoles.Ogma,
          // UserRoles.Student,
          // UserRoles.Coach,
          // UserRoles.Guardian,
          // UserRoles.Fan,
        ],
        name: 'Invited Users',
        icon: users,
      },
      {
        roles: [
          UserRoles.Admin,
          // UserRoles.Ogma,
          // UserRoles.Student,
          // UserRoles.Coach,
          // UserRoles.Guardian,
          // UserRoles.Fan,
        ],
        name: 'Invoices',
        href: 'invoices/schools',
        icon: invoices,
      },
      {
        roles: [
          // UserRoles.Admin,
          // TODO: Payment: Return ogma roles to use again payments
          // UserRoles.Ogma,
          // UserRoles.Student,
          // UserRoles.Coach,
          // TODO: Payment: Return guardian roles to use again payments
          // UserRoles.Guardian,
          // UserRoles.Fan,
        ],
        name: 'Subscriptions',
        icon: subscriptions,
      },
      {
        roles: [
          UserRoles.Admin,
          UserRoles.Ogma,
          // UserRoles.Student,
          // UserRoles.Coach,
          UserRoles.Guardian,
          // UserRoles.Fan,
        ],
        name: 'Schools',
        icon: schools,
      },
      {
        roles: [
          UserRoles.Admin,
          // UserRoles.Ogma,
          // UserRoles.Student,
          // UserRoles.Coach,
          // UserRoles.Guardian,
          // UserRoles.Fan,
        ],
        name: 'Organizations',
        icon: organizations,
      },
      {
        roles: [
          // UserRoles.Admin,
          UserRoles.Ogma,
          // UserRoles.Student,
          // UserRoles.Coach,
          // UserRoles.Guardian,
          // UserRoles.Fan,
        ],
        name: 'Organization',
        icon: organizations,
      },
      {
        roles: [
          UserRoles.Admin,
          // UserRoles.Ogma,
          // UserRoles.Student,
          // UserRoles.Coach,
          // UserRoles.Guardian,
          // UserRoles.Fan,
        ],
        name: 'Coaches',
        icon: coaches,
      },
      {
        roles: [
          UserRoles.Admin,
          // UserRoles.Ogma,
          // UserRoles.Student,
          // UserRoles.Coach,
          // UserRoles.Guardian,
          // UserRoles.Fan,
        ],
        name: 'Sponsors',
        icon: organizations,
      },
      {
        roles: [
          UserRoles.Admin,
          // UserRoles.Ogma,
          // UserRoles.Student,
          // UserRoles.Coach,
          // UserRoles.Guardian,
          // UserRoles.Fan,
        ],
        name: 'Archived',
        href: 'archived/user',
        icon: archive,
      },
      // {
      //   roles: [
      //     // UserRoles.Admin,
      //     UserRoles.Ogma,
      //     // UserRoles.Student,
      //     // UserRoles.Coach,
      //     // UserRoles.Guardian,
      //     // UserRoles.Fan,
      //   ],
      //   name: 'Students',
      //   href: 'students',
      //   icon: users,
      // },
      {
        roles: [
          // UserRoles.Admin,
          // UserRoles.Ogma,
          // UserRoles.Student,
          UserRoles.Coach,
          // UserRoles.Guardian,
          // UserRoles.Fan,
        ],
        name: 'Archived',
        href: 'archived/classroom',
        icon: archive,
      },
    ],
  },
  {
    title: 'Preferences',
    links: [
      {
        roles: [UserRoles.Admin],
        name: 'Terms Of Services',
        icon: termsOfService,
      },
      {
        roles: [
          UserRoles.Admin,
          UserRoles.Ogma,
          UserRoles.Student,
          UserRoles.Coach,
          UserRoles.Guardian,
          UserRoles.Fan,
        ],
        name: 'Settings',
        icon: settings,
      },
      {
        roles: [
          UserRoles.Admin,
          UserRoles.Ogma,
          UserRoles.Student,
          UserRoles.Coach,
          UserRoles.Guardian,
          UserRoles.Fan,
        ],
        name: 'Support',
        icon: helpcenter,
      },

      {
        roles: [
          UserRoles.Admin,
          UserRoles.Ogma,
          UserRoles.Student,
          UserRoles.Coach,
          UserRoles.Guardian,
          UserRoles.Fan,
        ],
        name: 'Logout',
        icon: logout,
        logout: true,
      },
    ],
  },
];
