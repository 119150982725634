import { NavLink, Outlet } from 'react-router-dom';

import UserHeader from './user-header';

const buttonList = [
  // {
  //   name: 'Overview',
  //   link: './overview',
  // },
  {
    name: 'Stats',
    link: './stats',
  },
  // {
  //   name: 'Usernames',
  //   link: './usernames',
  // },
];

const ViewUser = () => {
  return (
    <>
      <div className='listViewContainer fullPage border-1 p-0'>
        {/* <div className='flex justify-between flex-col rounded-lg bg-white w-full h-[235px]'> */}
        <UserHeader />
        <div className='flex px-2 bg-white '>
          {/* {buttonList.map((b) => (
            <NavLink key={b.link} to={b.link} className='settingsLink'>
              <div> {b.name} &nbsp;</div>
            </NavLink>
          ))} */}
          <NavLink to='./overview' className='settingsLink'>
            <div> Overview &nbsp;</div>
          </NavLink>
        </div>
        <Outlet />
      </div>
      {/* </div> */}
    </>
  );
};

export default ViewUser;
