import { useEffect, useState } from 'react';
import { AiOutlineMinusSquare, AiOutlinePlusSquare } from 'react-icons/ai';
import { Link, useSearchParams } from 'react-router-dom';
import { useGetSeasonsGroupedByDivisionQuery } from '../../../../../../slices/seasonApiSlice';
import { SeasonGridCard } from './season-grid-card';

export const SeasonGroupedBySeasonDivision = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, isLoading, isSuccess, isError } = useGetSeasonsGroupedByDivisionQuery(
    searchParams.get('page') ? searchParams.get('page') : '',
  );
  const [visible, setVisible] = useState<{ [key: string]: boolean }>();

  useEffect(() => {
    if (data?.data.length)
      data.data.forEach((d: any) => setVisible((s) => ({ ...s, [d.id]: true })));
  }, [data]);

  if (isLoading || isError) return <p>Loading...</p>;
  return (
    <div className='flex flex-col gap-5 p-5 pt-0'>
      {data.data.map((d: any) => (
        <div key={d.id} className='flex flex-col gap-5'>
          <div className='flex justify-between w-full border-b-2 border-black font-bold cursor-pointer uppercase '>
            <h2>{d.name}</h2>
            <span>
              {visible && visible[d.id] ? (
                <AiOutlineMinusSquare
                  size={20}
                  className='cursor-pointer'
                  onClick={() => setVisible((s: any) => ({ ...s, [d.id]: false }))}
                />
              ) : (
                <AiOutlinePlusSquare
                  size={20}
                  className='cursor-pointer'
                  onClick={() => setVisible((s: any) => ({ ...s, [d.id]: true }))}
                />
              )}
            </span>
          </div>
          <div className='flex gap-5 justify-center flex-wrap'>
            {visible &&
              visible[d.id] &&
              d.Season.map((t: any) => <SeasonGridCard key={t.id} {...t} />)}
          </div>
          <div className='flex justify-end'>
            <Link to={`/seasons/by-division/${d.id}`}>
              <div className='flex py-2 px-5 rounded-xl border-2 cursor-pointer select-none hover:bg-green-400 hover:text-white transition-all duration-700'>
                Show all
              </div>
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};
