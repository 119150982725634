import { Navigate, Outlet, useParams } from 'react-router';
import { OutsideClick } from '../../../components/outside-click';
import { Link } from 'react-router-dom';
import { SubmitButton } from '../../../components/submit-button';
import { AiOutlineClose } from 'react-icons/ai';
import { useResolveMatchChatDisputeMutation } from '../../../slices/matchApiSlice';
import { CustomError } from '../../../utils/custom-types';
import { useSelector } from 'react-redux';
import { selectMatchChatDisputes } from '../../../slices/matchSlice';
import { useState } from 'react';
import { RiScreenshot2Line } from 'react-icons/ri';

const ResolveMatchChatDispute = () => {
  const params = useParams();
  const matchChatDisputes = useSelector(selectMatchChatDisputes);
  const [selectedDispute, setSelectedDispute] = useState(0);
  const [resolveDispute, { isError, isSuccess, error, isLoading }] =
    useResolveMatchChatDisputeMutation();

  const handleResolveDispute = (disputeId: number, resolveAll: boolean) => {
    if ((!selectedDispute || selectedDispute !== disputeId) && !resolveAll) {
      setSelectedDispute(disputeId);
      return;
    }

    resolveDispute({ matchId: params?.id ?? '', body: { disputeId, resolveAll } });
  };
  if (isSuccess) return <Navigate to='../' />;
  return (
    <>
      <OutsideClick />
      <div className='fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-10 w-auto mx-auto max-w-2xl min-w-[90vw] bg-white p-10 flex flex-col gap-10 items-center justify-center rounded-xl'>
        <div className='absolute top-2 right-2'>
          <Link to='../' className=''>
            <AiOutlineClose size={'2rem'} className='hover:rounded-full hover:bg-gray-100 p-2' />
          </Link>
        </div>
        <h2 className='text-md font-bold'>All Disputes</h2>
        <div className='w-full flex flex-col border-2 border-black p-2 rounded max-h-[60vh] overflow-scroll'>
          {matchChatDisputes.map((dispute: any) => {
            return (
              <div
                key={dispute.id}
                className='cursor-pointer w-full flex flex-col mb-4'
                onClick={() => handleResolveDispute(dispute.id, false)}
              >
                <div className='self-center'>Reported by: {dispute.user?.username}</div>
                <div
                  className={`border-2 flex justify-between ${
                    dispute.id === selectedDispute ? 'border-green-500' : ''
                  } p-4 rounded`}
                >
                  {dispute.message}
                  {dispute.url && (
                    <Link
                      to={`./view/${dispute.id}`}
                      className='group relative hover:bg-slate-100 cursor-pointer p-1 h-[30px] flex items-center justify-end bg-slate-200 rounded-lg px-2'
                      onClick={(e) => e.stopPropagation()}
                    >
                      <RiScreenshot2Line size={25} color={dispute.url ? 'green' : ''} />
                    </Link>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <div className='py-4 flex flex-col justify-center items-center gap-8'>
          <div className='flex gap-2'>
            <SubmitButton
              onSubmit={() => handleResolveDispute(0, true)}
              title='Resolve All'
              isLoading={isLoading}
            />
          </div>
        </div>
        {isError ? (
          <p>{(error as CustomError)?.message ?? (error as CustomError)?.data?.message}</p>
        ) : (
          <></>
        )}
      </div>
      <Outlet />
    </>
  );
};

export default ResolveMatchChatDispute;
