import { MdModeEditOutline } from 'react-icons/md';
import { Link } from 'react-router-dom';

export function EditMatchDetails({ }) {
  return (
    <Link to='./edit'>
      <div className='flex rounded-md p-1 gap-2 px-2 text-white bg-slate-500 text-xs items-center'>
        <MdModeEditOutline color='white' />
        Edit Match Details
      </div>
    </Link>
  );
}
