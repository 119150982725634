import { Outlet, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useGetAllClassroomsByUserIdQuery } from '../../../../slices/classroomApiSlice';
import ClassroomCard from '../../../classrooms/components/classroom-card';
import { ClassroomModel } from '../../../../model/classroom.model';
import { BiSad } from 'react-icons/bi';
import Pagination from '../../../../components/pagination';

const Classrooms = () => {
  const param = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    data: classroomData,
    isLoading: classroomIsLoading,
    isError: classroomIsError,
  } = useGetAllClassroomsByUserIdQuery({
    id: param.id,
    query: { name: searchParams.get('name') ?? undefined, page: searchParams.get('page') ?? 1 },
  });

  if (classroomIsLoading) return <p>Loading...</p>;

  if (!classroomData.data.length)
    return (
      <div className='flex gap-10 flex-col justify-center items-center'>
        <BiSad className='bg-gray-400 rounded-full p-7' size={'150px'} color='white' />
        <p className='font-bold text-2xl text-gray-400'>No classrooms!</p>
      </div>
    );

  return (
    <div>
      <div className='flex flex-wrap justify-center gap-4 mt-4'>
        {classroomData.data.map((classroom: any) => (
          <ClassroomCard classroom={new ClassroomModel(classroom)} key={classroom.id} hide />
        ))}
        <Pagination length={classroomData.data.length} {...classroomData.meta} />
      </div>
      <Outlet />
    </div>
  );
};

export default Classrooms;
