import { useDispatch } from 'react-redux';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { apiSlice } from './api/apiSlice';
import authReducer from './slices/auth';
import messageReducer from './slices/message';
import storage from 'redux-persist/lib/storage';
import userReducer from './slices/userSlice';
import matchReducer from './slices/matchSlice';
import { persistReducer, persistStore } from 'redux-persist';
import gamearenaSlice from './slices/gamearenaSlice';
import MatchSocketMiddleware from './middleware/match-socket.middleware';
import SeasonWeekSocketMiddleware from './middleware/season-week-socket.middleware';
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch: () => AppDispatch = useDispatch;
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
};
const persistGamearenaConfig = {
  key: 'gamearena',
  storage,
  whitelist: ['twitch_token'],
};
const reducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  auth: authReducer,
  message: messageReducer,
  user: userReducer,
  match: matchReducer,
  gamearena: persistReducer(persistGamearenaConfig, gamearenaSlice),
});
const persistedReducer = persistReducer(persistConfig, reducer);
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      apiSlice.middleware,
      MatchSocketMiddleware,
      SeasonWeekSocketMiddleware,
    ),
  devTools: import.meta.env.MODE === 'development' ? true : false,
});

export const persistor = persistStore(store);
