import { OutsideClick } from '../../../components/outside-click';
import { Formik, FormikValues } from 'formik';
import ModalContent from '../../../components/modal-content';
import { Navigate, useParams } from 'react-router';
import { useCanEditRescheduleQuery, useEditRescheduleDateMutation } from '../../../slices/matchApiSlice';
import { EditRescheduleDateValidationSchema } from '../../../features/authentication/yupschema';

const EditReschedule = () => {
    const param = useParams();
    const { isError } = useCanEditRescheduleQuery(param?.rescheduleId, { skip: !param?.id });

    const formInputs = [
        {
            name: 'newDate',
            label: 'Enter date',
            type: 'datetime-local',
            placeholder: 'Enter date',
            isTrue: true,
        },
    ];

    const initialValues: { [key: string]: string } = {};
    formInputs.forEach((input) => (initialValues[input.name] = ''));
    const [editRescheduleDate, { isLoading, isSuccess }] = useEditRescheduleDateMutation();

    const onSubmit = (values: FormikValues) => {
        const data = { ...values };
        data.newDate = new Date(data.newDate).toISOString();
        editRescheduleDate({
            id: param?.rescheduleId,
            body: data
        });
    };

    if (isError || isSuccess) return <Navigate to='../' />;

    return (
        <>
            <OutsideClick />
            <Formik initialValues={{}} onSubmit={onSubmit} validationSchema={EditRescheduleDateValidationSchema}>
                {({ errors }) => {
                    return <ModalContent
                        errors={Object.keys(errors).length}
                        extraClass={'h-fit'}
                        formInputs={formInputs}
                        isLoading={isLoading}
                        name='Edit Reschedule Date'
                    />
                }}
            </Formik>
        </>
    );
};

export default EditReschedule;