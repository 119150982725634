import { Link, NavLink, useLocation } from 'react-router-dom';
import logo from '../assets/logo2.png';
import { links } from '../assets/sidebar-links';
import { useStateContext } from '../contexts/ContextProvider';
import { changeLogOut, selectUser } from '../slices/auth';
import { useDispatch, useSelector } from 'react-redux';
import { usePrefetch } from '../slices/gamearenaApiSlice';
import { Separator } from './ui/separator/Separator';

const SideBar = () => {
  const user = useSelector(selectUser);
  const prefetchGamearena = usePrefetch('initGamearena');
  const { activeMenu, setActiveMenu, screenSize } = useStateContext();
  const handleCloseSideBar = () => {
    if (activeMenu && screenSize <= 900) {
      setActiveMenu(false);
    }
  };

  const activeLink =
    'activeColor flex items-center  gap-5 pl-4 pt-3 pb-2.5 rounded-lg  text-white text-md m-2 bg-red-800	';
  const normalLink =
    'flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2';
  const dispatch = useDispatch();
  const location = useLocation();

  const locationPath = location.pathname.slice(
    1,
    location.pathname.indexOf('/', location.pathname.indexOf('/') + 1),
  );

  return (
    <div className='ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10 '>
      {activeMenu && (
        <>
          <Link to='/' onClick={handleCloseSideBar} className='sidebar--logo z-[1]'>
            <img width='196' height='49' src={logo} alt='Fenworks Logo' />
          </Link>
          <div className='mt-10 '>
            {links.map((item: any) => (
              <div key={item.title}>
                {/* <p className='text-gray-400 dark:text-gray-400 m-3 mt-4 uppercase'>{item.title}</p> */}
                {item.title && <Separator className='mt-8' />}
                {item.links
                  .filter((l: any) =>
                    import.meta.env.NODE_ENV !== '' ? l.roles.includes(user.roles) : l,
                  )
                  .map((link: any) => {
                    const linkTo =
                      link.name === 'Logout'
                        ? location.pathname
                        : `/${
                            link.name === 'Tournaments' ||
                            link.name === 'Seasons' ||
                            link.name === 'Archived' ||
                            link.name === 'Invoices'
                              ? link.href
                              : link.name.replace(/ /g, '').toLowerCase()
                          }`;
                    return (
                      <NavLink
                        to={linkTo}
                        key={link.name}
                        onMouseEnter={
                          link.name === 'GameArena' ? () => prefetchGamearena('') : () => null
                        }
                        onClick={() => {
                          if (link.hasOwnProperty('logout')) {
                            dispatch(changeLogOut(true));
                            // dispatch(apiSlice.util.resetApiState());
                            // dispatch(logOut());
                          } else handleCloseSideBar();
                        }}
                        className={({ isActive }) =>
                          (isActive && link.name.toLowerCase() !== 'logout') ||
                          locationPath === link.name.toLowerCase() ||
                          (link.name === 'Schedule' && locationPath === 'match') ||
                          (link.name === 'Users' &&
                            (locationPath === 'user' || locationPath === 'inviteduser'))
                            ? `${activeLink}`
                            : normalLink
                        }
                      >
                        <img alt='icon' src={link.icon} />{' '}
                        <span className='capitalize'>{link.name}</span>
                      </NavLink>
                    );
                  })}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default SideBar;
