import { Formik } from 'formik';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { OutsideClick } from '../../../../components/outside-click';
import {
  useCreateSeasonWeekMutation,
  useGetNewSRequiredDataQuery,
  useViewSingleSeasonStageQuery,
} from '../../../../slices/seasonApiSlice';
import { NewWeekVSchema } from './schema/new-week-v-schema';
import ModalContent from '../../../../components/modal-content';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addCurrentTimeToDate } from '../../../../utils/dateFormatter';

const NewSeasonWeek = () => {
  const param = useParams();
  const navigate = useNavigate();
  const [createSeasonWeek, { isLoading: createIsLoading, isSuccess: createIsSuccess }] =
    useCreateSeasonWeekMutation();
  const { data: stageData, isLoading: seasonIsLoading } = useViewSingleSeasonStageQuery(
    param.stageId,
  );

  const {
    data: reqData,
    isLoading: reqIsLoading,
    isSuccess: reqIsSuccess,
    isError: reqIsError,
  } = useGetNewSRequiredDataQuery('');
  const formInputs = [
    {
      mainTitle: 'Week Information',
      name: 'name',
      label: 'Name',
      type: 'text',
      placeholder: 'Name',
      isTrue: true,
    },
    {
      mainTitle: 'Week Information',
      name: 'start_time',
      label: 'Start date',
      type: 'datetime-local',
      placeholder: 'Start date',
      isTrue: true,
    },
    {
      mainTitle: 'Week Information',
      name: 'end_time',
      label: 'End date',
      type: 'date',
      placeholder: 'End date',
      isTrue: true,
    },
    {
      mainTitle: 'Week Information',
      name: 'format_id',
      label: 'Match Format',
      type: 'number',
      min: 0,
      placeholder: 'Game per match',
      hidden: stageData && stageData?.SeasonType?.leaderboard,
      isDisabled: stageData && stageData?.SeasonType?.leaderboard,
      isSelect: true,
      options:
        stageData?.game_id &&
        reqData?.games?.filter((g: any) => g.id === stageData?.game_id)[0].MatchFormat
          ? reqData?.games?.filter((g: any) => g.id === stageData?.game_id)[0].MatchFormat
          : [],
    },
    {
      mainTitle: 'Create Multiple Weeks',
      name: 'num_weeks',
      label: 'Number of Weeks',
      type: 'number',
      min: 1,
      placeholder: 'Number of Weeks',
    },
  ];
  const initialValues: { [key: string]: string } = {};
  formInputs.forEach((input) => (initialValues[input.name] = ''));

  function handleSubmit(values: any) {
    const startTimeLocalDate = new Date(values.start_time);
    const body = {
      ...values,
      start_time: new Date(startTimeLocalDate.setSeconds(0)).toISOString(),
    };
    !values.format_id ? delete body.format_id : (body.format_id = +values.format_id);
    if (!values.num_weeks) delete body.num_weeks;

    if (values.end_time) {
      body.end_time = addCurrentTimeToDate(values.end_time);
    } else delete body.end_time;
    console.log('body', body);
    createSeasonWeek({
      id: param.stageId,
      body,
    });
  }

  useEffect(() => {
    if (createIsSuccess)
      toast.success('Season week has been cretead successfully.', { position: 'top-right' });
  }, [createIsSuccess]);

  if (createIsSuccess) {
    return <Navigate to='../' />;
  }

  // if (seasonData.teams.length % 2 === 1 || seasonData.teams.length === 0)
  //   return (
  //     <>
  //       <ToastContainer />
  //       <OutsideClick />
  //       <div className='fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-10 w-auto mx-auto max-w-2xl bg-white  py-5 px-5 flex flex-col gap-10 items-center justify-center rounded-xl'>
  //         <div className='flex w-full justify-end'>
  //           <AiOutlineClose
  //             size={'2rem'}
  //             className='hover:rounded-full hover:bg-gray-100 p-2 cursor-pointer'
  //             onClick={() => navigate('../')}
  //           />
  //         </div>
  //         {/* <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-[90vh]'></div> */}
  //         <p className='px-10 pb-10 text-red-700 text-lg'>
  //           Number of teams should be even and not empty!
  //         </p>
  //       </div>
  //     </>
  //   );

  const newSeasonWeekSchema = NewWeekVSchema(stageData?.SeasonType?.leaderboard);
  return (
    <>
      <OutsideClick />
      <Formik
        enableReinitialize
        validationSchema={newSeasonWeekSchema}
        initialValues={{ ...initialValues, format_id: stageData?.format_id }}
        onSubmit={handleSubmit}
      >
        {({ values, errors }) => {
          return (
            <ModalContent
              formInputs={formInputs.filter((formInput) => !formInput.hidden)}
              isLoading={createIsLoading}
              name='New Season Week'
            />
          );
        }}
      </Formik>
    </>
  );
};

export default NewSeasonWeek;
