import { UserRoles } from '../../../utils/constants';

export const tournamentPermissions = {
  'tournament.optionContainer': [UserRoles.Admin],
  'tournament.newTournament': [UserRoles.Admin],
  'tournament.export': [UserRoles.Admin, UserRoles.Ogma],
  'tournament.division.newCategoryDivision': [UserRoles.Admin],
  'tournament.division.export': [UserRoles.Admin, UserRoles.Ogma],
  'tournament.subdivision.newSubdivision': [UserRoles.Admin],
  'tournament.subdivision.export': [UserRoles.Admin, UserRoles.Ogma],
  'tournament.view.edit-tournament': [UserRoles.Admin],
  'tournament.view.change-profile': [UserRoles.Admin],
  'tournament.view.change-cover': [UserRoles.Admin],
  'tournament.bracket.generate': [UserRoles.Admin],
  'tournament.bracket.edit': [UserRoles.Admin],
  'tournament.teams.edit': [UserRoles.Admin],
  'tournament.groupedTournamentsButton': [UserRoles.Admin],
  'tournament.newUser': [UserRoles.Admin]
};
