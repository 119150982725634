import { useEffect, useState } from 'react';
import { useGetAllGamesQuery, useGetStatsByGameQuery } from '../../../slices/gameApiSlice';
import { Error } from '../../../components/ui/error/Error';
import MatchScheduleView from '../../schedule/routes/match-schedule/components/match-schedule-view';
import { MatchScheduleModel } from '../../../model/match-schedule.model';
import Combobox from '../../../components/combobox/combobox';
import { useParams } from 'react-router';

const UserStats = () => {
  const param = useParams();
  const { data, isLoading: gameIsLoading } = useGetAllGamesQuery({
    query: null,
  });

  const [selectedGame, setSelectedGame] = useState<number>();
  const [optionList, setOptionList] = useState<{ id: number | string; name: string }[]>([]);

  const { data: stats, isLoading } = useGetStatsByGameQuery(
    {
      gameId: optionList.filter((o) => o.id === selectedGame)?.[0]?.id,
      studentId: param.id,
    },
    { skip: !optionList.length },
  );

  useEffect(() => {
    if (!data) return;
    setOptionList(data?.map((g: any) => ({ id: g.id, name: g.name })));
    setSelectedGame(data?.[0]?.id);
  }, [data]);

  // useEffect(() => {
  //   if (selectedGame) {
  //     const gameName = optionList?.find((i) => i?.id === selectedGame)?.name;
  //     getStats({
  //       id: param?.id ?? user?.id,
  //       gameName: gameName,
  //     });
  //   }
  // }, [selectedGame]);

  // console.log(game)

  if (isLoading || !optionList || gameIsLoading || !selectedGame) return <p>Loading...</p>;
  return (
    <div className='card flex flex-col justify-content-center'>
      <div className='relative p-5   flex-auto flex-col'>
        <h2 className='font-normal text-xl text-gray-800'>Game</h2>
        <div className='flex w-[300px]'>
          <Combobox
            noFormik
            options={optionList}
            value={selectedGame}
            setFieldValue={(v) => setSelectedGame(v)}
            label='Select Game'
            name='Game'
          />
        </div>
      </div>

      <h2 className='font-normal p-5  text-xl text-gray-800'> Main Statistics</h2>

      <div className=' px-10'>
        <div className='flex p-5 px-20 justify-between gap-2 border rounded-lg border-gray-300 '>
          <div className='flex flex-col items-center'>
            <h2 className=' font-bold'>{stats?.match ? stats?.match?.length : 0} </h2>

            <h2 className='text-lg'>Matches</h2>
          </div>

          <div className='flex flex-col items-center'>
            <h2 className='font-bold'>{stats?.winPercentage ? stats?.winPercentage : 0} </h2>

            <h2 className='text-lg'>Win Rate %</h2>
          </div>

          <div className='flex flex-col items-center'>
            <h2 className=' font-bold'>{stats?.losePercentage ? stats?.losePercentage : 0}</h2>

            <h2 className='text-lg'>Loss Rate %</h2>
          </div>
        </div>
      </div>

      <h2 className='font-normal p-5  text-xl text-gray-800'> Match History</h2>

      {stats?.match?.length ? (
        stats?.match?.slice(0, 3).map((match: any, i: number) => (
          <div key={i} className='flex gap-3 flex-col p-2 px-10 '>
            <MatchScheduleView scheduleModel={new MatchScheduleModel(match)} />
          </div>
        ))
      ) : (
        <>
          <Error title='No matches for this game' hideButton />
        </>
      )}
    </div>
  );
};

export default UserStats;
