import React from 'react';

export const ModalMessages = {
  CloseSeasonWeek: (weekName: string) => (
    <>
      Are you sure you want to close <span className='font-semibold'>{weekName}</span>?
    </>
  ),
  PublishSeasonWeek: (weekName: string) => (
    <>
      Are you sure you want to close and publish
      <span className='font-semibold'> {weekName}</span>?
    </>
  ),
  DeleteMatchGameRow: (screenshotsLength: number) => (
    <>
      Are you sure you want to delete this game?
      <span className='font-semibold'>
        {' '}
        {screenshotsLength ? `It contains ${screenshotsLength} screenshots!` : ''}
      </span>
    </>
  ),
};

export enum ToastMessages {
  CloseSeasonWeek = 'You succesfully closed week!',
  PublishSeasonWeek = 'You succesfully published week!',
  OpenSeasonWeek = 'You succesfully started week!',
  LeaderboardSingleMatchPublish = 'You succesfully published scores!',
  LeaderboardMatchScoreSubmit = 'You succesfully submitted score!',
  DeleteMatchGame = 'You successfully deleted game!',
}
