
import {
  useArchiveSubDivisionMutation,
  useGetSubDivisionByIdQuery,
} from '../../../../slices/subDivisionApiSlice';
import ModalArchive from '../../../../components/modal-archive';
import { CustomError } from '../../../../utils/custom-types';
import { useParams, Navigate } from 'react-router';
function ArchiveSubDivision() {
  const param = useParams();
  const {
    error,
    data: subDivisionData,
    isLoading: subDivisionIsLoading,
    isSuccess: subDivisionIsSuccess,
    isError: subDivisionIsError,
  } = useGetSubDivisionByIdQuery(param.id);

  const [archiveSubDivision, { data, isSuccess, isError, isLoading }] =
    useArchiveSubDivisionMutation();

  const onSubmit = () => {
    archiveSubDivision(param.id)
      .unwrap()
      .then((res) => console.log(res))
      .catch((e) => console.log(e));
  };
  if (subDivisionIsError || (subDivisionIsSuccess && !subDivisionData))
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError).data.message }} />;
  if (subDivisionIsLoading) return <p>Loading...</p>;
  if (isSuccess) return <Navigate to='../' />;
  return <ModalArchive onSubmit={onSubmit} isLoading={isLoading} name={subDivisionData.name} />;
}

export default ArchiveSubDivision;
