import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { Navigate, useParams } from 'react-router-dom';
import { AvatarImage } from '../../../components/avatar-image';
import { OutsideClick } from '../../../components/outside-click';
import {
  useEditMatchScoreMutation,
  useGetSingleMatchInfoQuery,
  useUpdateMatchResultMutation,
} from '../../../slices/matchApiSlice';
import ModalFooter from '../../../components/modal-footer';
import ModalHeader from '../../../components/modal-header';
import { CustomError } from '../../../utils/custom-types';
import { MatchStatus } from '../../../utils/constants';
import { TiesAreNotAllowed } from '../../../utils/messages.enum';

const EditMatchResults = () => {
  const [hScore, setHScore] = useState(1);
  const [aScore, setAScore] = useState(1);
  const param = useParams();
  const { error, data, isLoading, isError } = useGetSingleMatchInfoQuery(param.id);

  useEffect(() => {
    if (!data) return;
    setHScore(data?.ht_score);
    setAScore(data?.at_score);
  }, [data]);

  const [
    updateMatchResult,
    {
      error: error2,
      data: upData,
      isSuccess: upIsSuccess,
      isLoading: upIsLoading,
      isError: upIsError,
    },
  ] = useUpdateMatchResultMutation();

  const [
    updateMatchScore,
    { isLoading: updateScoreLoading, isSuccess: updateScoreSuccess, isError: updateScoreError },
  ] = useEditMatchScoreMutation();

  const handleSubmit = () => {
    if (hScore === aScore) return;
    if (data?.status === MatchStatus.InProgress) {
      updateMatchResult({
        id: param.id,
        body: { ht_score: hScore, at_score: aScore },
      });
    } else {
      updateMatchScore({
        id: param.id,
        body: { ht_score: hScore, at_score: aScore },
      });
    }
  };

  if (upIsSuccess || updateScoreSuccess) return <Navigate to='../' />;

  if (isError || upIsError || updateScoreError) {
    const errorMessage = upIsError
      ? (error2 as CustomError).data?.message
      : (error as CustomError).data?.message;
    return <Navigate to='/went-wrong' state={{ message: errorMessage }} />;
  }

  if (isLoading || updateScoreLoading) return <p>Loading...</p>;

  return (
    <>
      <OutsideClick />
      <Formik
        initialValues={{}}
        onSubmit={handleSubmit}
      // onSubmit={() =>
      //   updateMatchResult({
      //     id: param.id,
      //     body: { ht_score: hScore, at_score: aScore },
      //   })
      // }
      >
        {(formik) => {
          return (
            <Form>
              <div className='fixed pb-20 top-0 left-0 right-0 z-10 w-auto my-6 mx-auto max-w-3xl max-h-[100vh] '>
                {/* content*/}
                <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-full overflow-auto'>
                  {/* header*/}
                  <ModalHeader name={'Edit Match Results'} />
                  <div className='relative p-6 flex flex-col gap-5'>
                    {data?.status === MatchStatus.Completed && (
                      <div>
                        <span className='text-md text-red-700'>
                          WARNING: This will effect match result
                        </span>
                      </div>
                    )}

                    <div className='flex justify-center gap-10'>
                      <div className='flex items-center justify-evenly rounded-lg bg-gray-100 border p-1 px-3 w-[300px]'>
                        <AvatarImage source={`${data?.home_team[0].image ?? ''}`} size='xl' />
                        <div className='flex flex-col justify-center flex-1 items-center '>
                          <h2 className='text-sm font-bold w-[15ch] truncate'>
                            {data?.home_team?.[0]?.teamName}
                          </h2>
                          <h2 className='text-xs w-[15ch] truncate'>
                            {data?.away_team?.[0]?.School?.name}
                          </h2>
                        </div>
                        <div className='flex gap-1 h-[80%] items-center p-2 border-2 bg-white rounded-xl'>
                          <AiOutlineMinus
                            onClick={() => setHScore((s) => (s > 0 ? s - 1 : s))}
                            className='cursor-pointer hover:scale-110 '
                          />
                          <input
                            onChange={(e) => setHScore(+e.target.value)}
                            type='text'
                            value={hScore}
                            className='w-[20px] text-center font-bold focus:outline-none active:outline-none'
                          />
                          <AiOutlinePlus
                            onClick={() => setHScore((s) => s + 1)}
                            className='cursor-pointer hover:scale-110 '
                          />
                        </div>
                      </div>
                      <div className='flex items-center justify-evenly rounded-lg bg-gray-100 border p-1 px-3 w-[300px]'>
                        <div className='flex gap-1 h-[80%] items-center p-2 border-2 bg-white rounded-xl'>
                          <AiOutlineMinus
                            onClick={() => setAScore((s) => (s > 0 ? s - 1 : s))}
                            className='cursor-pointer hover:scale-110 '
                          />
                          <input
                            type='text'
                            onChange={(e) => setAScore(+e.target.value)}
                            value={aScore}
                            className='w-[20px] text-center font-bold focus:outline-none active:outline-none'
                          />
                          <AiOutlinePlus
                            onClick={() => setAScore((s) => s + 1)}
                            className='cursor-pointer hover:scale-110 '
                          />{' '}
                        </div>
                        <div className='flex flex-col justify-center flex-1 items-center'>
                          <h2 className='text-sm font-bold w-[15ch] truncate'>
                            {data?.away_team?.[0]?.teamName}
                          </h2>
                          <h3 className='text-xs w-[15ch] truncate'>
                            {data?.away_team?.[0]?.School?.name}
                          </h3>
                        </div>
                        <AvatarImage source={`${data?.away_team[0].image ?? ''}`} size='xl' />
                      </div>
                    </div>
                    {hScore === aScore ? (
                      <div>
                        <span className='text-md text-red-700'>{TiesAreNotAllowed}</span>
                      </div>
                    ) : (
                      <div>
                        <span className='text-md text-red-700'> </span>
                      </div>
                    )}
                  </div>
                  {/* footer*/}
                  <ModalFooter isLoading={upIsLoading!} />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default EditMatchResults;
