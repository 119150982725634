import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import CookieConsent from 'react-cookie-consent';
import { selectUser } from '../slices/auth';

const CookieConsents = () => {
  const user = useSelector(selectUser);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const consentGiven = localStorage.getItem('acceptCookies');
    if (!consentGiven) {
      setShowModal(true);
    }
  }, []);

  const handleConsent = () => {
    localStorage.setItem('acceptCookies', 'yes');
    setShowModal(false);
  };

  if (!user || !showModal) {
    return null;
  }

  return (
    <>
 <CookieConsent
        // debug={true} //Remove in production
        onAccept={handleConsent}
        style={{ background: '#f1f2f1', color: 'black', borderRadius: '15px' }}
        buttonStyle={{ backgroundColor: '#e74c3c', color: 'white', fontSize: '16px', height: '40px', borderRadius: '100%', width:'40px' }}
        buttonText='X'
      >
By clicking the button in the far right, you acknowledge that we do not store any personal information in cookies.      </CookieConsent>
    </>
  );
};

export default CookieConsents;
