import React, { useState } from 'react';
import { Formik, Form, FormikValues, useFormikContext } from 'formik';
import { Link, Navigate } from 'react-router-dom';
import { FormInput } from '../../../components/form-input/form-input';
import { useCreateAdminMutation } from '../../../slices/userApiSlice';
import { SubmitButton } from '../../../components/submit-button';
import { NewAdminSchema } from './yup-schema/new-admin.schema';
import ModalFooter from '../../../components/modal-footer';
import { allStates, gendersOptions } from '../../../utils/constants';
import { CustomError } from '../../../utils/custom-types';

const NewAdminModal = () => {
  const [formInputs, setFormInputs] = useState([
    //   {
    //     mainTitle: "accountType",
    //     name: "accountType",
    //     label: "accountType",
    //     type: "accountType",
    //     placeholder: "accountType",
    //   },
    {
      mainTitle: 'General Admin Information',
      name: 'firstName',
      label: 'First Name',
      type: 'text',
      placeholder: 'Enter first name',
      isTrue: false,
    },
    {
      mainTitle: 'General Admin Information',
      name: 'lastName',
      label: 'Last Name',
      type: 'text',
      placeholder: 'Enter last name',
    },
    {
      mainTitle: 'General Admin Information',
      name: 'username',
      label: 'Username',
      type: 'text',
      placeholder: 'Enter username',
      isTrue: false,
    },
    {
      mainTitle: 'General Admin Information',
      name: 'city',
      label: 'City',
      type: 'text',
      placeholder: 'Enter city',
    },
    {
      mainTitle: 'General Admin Information',
      name: 'state',
      label: 'State',
      type: 'state',
      placeholder: 'Enter state',
      isSelect: true,
      options: allStates ? allStates : [],
    },
    {
      mainTitle: 'General Admin Information',
      name: 'zipCode',
      label: 'Zip Code',
      type: 'number',
      placeholder: 'Enter zip code',
    },
    // {
    //   mainTitle: 'General Admin',
    //   name: 'gender',
    //   label: 'Gender',
    //   type: 'text',
    //   placeholder: 'gender',
    //   isSelect: true,
    //   options: [
    //     { id: 'm', name: 'M' },
    //     { id: 'f', name: 'F' },
    //   ],
    // },

    {
      mainTitle: 'General Admin Information',
      name: 'phoneNumber',
      label: 'Phone Number',
      type: 'text',
      placeholder: 'Enter phone number',
    },
    {
      mainTitle: 'General Admin Information',
      name: 'gender',
      label: 'Gender',
      type: 'text',
      isSelect: true,
      options: gendersOptions,
    },
    {
      mainTitle: 'General Admin Information',
      name: 'email',
      label: 'Email Address',
      type: 'text',
      placeholder: 'Enter email address',
    },
  ]);

  // const initialValues: { [key: string]: string } = {};
  const [initialValues, setInitialValues] = useState<{ [key: string]: string }>({});
  formInputs.forEach((input: any) => (initialValues[input.name] = ''));

  const [createAdmin, { isLoading, isSuccess, isError, error }] = useCreateAdminMutation();

  const onSubmit = (values: FormikValues, { setFieldValue }: { setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void }) => {
    const data = values;
    data.zipCode = +data.zipCode;
    if (data.dateOfBirth) {
      data.dateOfBirth = new Date(values.dateOfBirth).toISOString();
    }
    else {
      data.dateOfBirth = null;
    }

    const selectedState = allStates.find((state) => state.id === values.state);
    if (selectedState) {
      data.state = selectedState.name;
    }

    delete data.accountType;

    createAdmin(data)
      .unwrap()
      .then((res) => console.log(res))
      .catch(err => {
        if (values.dateOfBirth) {
          const newDate = new Date(values.dateOfBirth);
          setFieldValue('dateOfBirth', newDate.toISOString().split('T')[0]);
        }
      });
  };

  if (isSuccess) return <Navigate to='../' />;

  return (
    <>
      <Formik initialValues={initialValues} validationSchema={NewAdminSchema} onSubmit={onSubmit} enableReinitialize={true}>
        {() => (
          <Form>
            <div className='flex flex-col gap-2'>
              {formInputs.map((input, i: number) => (
                <React.Fragment key={i}>
                  {!formInputs[i - 1] || formInputs[i - 1]?.mainTitle !== input.mainTitle ? (
                    <>
                      <div className='flex flex-col'>
                        <div className='mb-2'>
                          <p className='text-gray-800 font-bold pt-6'>{input.mainTitle}</p>
                          <hr />
                        </div>
                        <div className='flex flex-wrap gap-1'>
                          {formInputs
                            .filter((inp1) => inp1.mainTitle === input.mainTitle)
                            .map((inp) => (
                              <React.Fragment key={inp.name}>
                                <div className={inp?.isTrue ? 'w-full ' : 'flex-1 min-w-[300px]'}>
                                  <FormInput {...inp} />
                                </div>
                              </React.Fragment>
                            ))}
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </React.Fragment>
              ))}
              {isError && <p className='text-red-300'>{(error as CustomError).data.message}</p>}
            </div>
            {/* footer*/}
            {/* <div className='flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b'>
              <Link
                className='min-w-[120px] my-12 text-center py-2 bg-white text-black shadow-lg border-1 border-neutral-200 font-semibold rounded-lg'
                to='../'
              >
                Cancel
              </Link>
              <SubmitButton isLoading={isLoading} />
            </div> */}

            <ModalFooter isLoading={isLoading!} />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default NewAdminModal;
