import { Formik, Form, FormikValues } from 'formik';
import { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router';
import { OutsideClick } from '../../../components/outside-click';
import {
  useGetSingleMatchInfoQuery,
  useSendGameDisputeMutation,
} from '../../../slices/matchApiSlice';
import { DragDropFile } from '../edit-game-results/drag-drop-file/drag-drop-file';
import { GameScreenshotType, UserRoles } from '../../../utils/constants';
import { CustomError } from '../../../utils/custom-types';
import ModalHeader from '../../../components/modal-header';
import ModalFooter from '../../../components/modal-footer';
import { GameInputs } from '../../../components/game-inputs/game-inputs';
import { generateGameInputProps } from '../../../utils/utils';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../slices/auth';

const DisputeMatch = () => {
  const [
    sendGameDispute,
    { error: error2, isSuccess: upIsSuccess, isError: upIsError, isLoading: upIsLoading },
  ] = useSendGameDisputeMutation();
  const [file, setFile] = useState<File | null>(null);
  const [hScore, setHScore] = useState(0);
  const [aScore, setAScore] = useState(0);
  const param = useParams();
  const user=useSelector(selectUser)
  const handleSubmit = (values: FormikValues) => {
    if (!file) {
      setErrorMsg('Please upload an image!');
      return;
    }
    const formData = new FormData();
    formData.append('ht_result', hScore.toString());
    formData.append('aw_result', aScore.toString());
    formData.append('image', file);
    formData.append('type', GameScreenshotType.Dispute.toString());
    sendGameDispute({ id: param.id, body: formData });
    return;
  };
  const { error, data, isLoading, isError } = useGetSingleMatchInfoQuery(param.id);
  const [errorMsg, setErrorMsg] = useState('');
  useEffect(() => {
    if (file) setErrorMsg('');
  }, [file]);
  if (upIsSuccess) return <Navigate to='../' />;
  if (isError || upIsError) {
    const errorMessage = upIsError
      ? (error2 as CustomError).data.message
      : (error as CustomError).data.message;
    return <Navigate to='/went-wrong' state={{ message: errorMessage }} />;
  }
  if (isLoading) return <p>Loading...</p>;
  return (
    <>
      <OutsideClick />
      <Formik initialValues={{}} onSubmit={handleSubmit}>
        {(formik) => {
          return (
            <Form>
              <div className='fixed pb-20 top-0 left-0 right-0 z-10 w-auto my-6 mx-auto max-w-3xl max-h-[100vh] '>
                {/* content*/}
                <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-full overflow-auto'>
                  {/* header*/}
                  <ModalHeader name='Dispute Match Results' />
                  <div className='relative p-6 flex flex-col'>
                    <GameInputs
                      {...generateGameInputProps({ ...data, hScore, setHScore, aScore, setAScore })}
                    />
                  </div>
                  {/* <div className="flex items-start justify-between mb-2 p-5 border-b border-solid border-slate-200 rounded-t sticky top-0 z-[1] bg-white">
                    <h3 className="text-3xl font-semibold">Match Claims</h3>
                  </div> */}
                  <div className='flex flex-col p-5 gap-5 drag-upload'>
                    <p className='text-red-700'>{errorMsg}</p>
                    <DragDropFile file={file} setFile={setFile} />
                  </div>
                  {/* footer*/}
                  <ModalFooter isLoading={upIsLoading} disabled={!file} />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default DisputeMatch;
