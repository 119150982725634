import React from 'react';
import { OutsideClick } from '../../components/outside-click';
import { Link } from 'react-router-dom';
import { ISubCategories, subcategoriesType } from './support-categories-admin';

const ShowAllSubCategories = ({
  title,
  setModelId,
  subCategories,
}: {
  setModelId: Function;
  subCategories: subcategoriesType;
  title: string;
}) => {
  return (
    <>
      <OutsideClick setFunc={setModelId} />
      <div className='fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-10 w-1/5 mx-auto max-w-2xl'>
        <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none h-full overflow-auto py-4 h-auto max-h-[80vh]'>
          <h1 className='text-center my-1 text-2xl font-semibold tracking-tight'>{title}</h1>
          {subCategories?.map((subCategory: ISubCategories, i: number) => (
            <React.Fragment key={subCategory?.id ?? i}>
              <span className='p-2 text-sm font-medium text-gray-900 font-bold border-b-1 cursor-pointer mb-1 hover:text-red-600'>
                {subCategory.name ? subCategory.name : subCategory.teamName}
              </span>
            </React.Fragment>
          ))}
        </div>
      </div>
    </>
  );
};

export default ShowAllSubCategories;