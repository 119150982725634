import { useNavigate } from 'react-router';
import { SupportOptionCard } from './SupportOptionCard';
import { useEffect, useState } from 'react';

const SupportTicketStatusTab = () => {
    const [isActive, setIsActive] = useState(1);
    const navigate = useNavigate();

    const supportTabNavigation = [
        {
            id: 1,
            option: 'Open',
            description: '',
            to: './open',
        },
        {
            id: 2,
            option: 'Closed',
            description: '',
            to: './closed',
        },
        {
            id: 3,
            option: 'Archived',
            description: '',
            to: './archived',
        },
    ];

    useEffect(() => {
        // navigate('open/internal')
    }, []);

    return (
        <div className='flex min-h-fit gap-5 items-center lg:items-start p-5 flex-row bg-white flex-wrap'>
            {supportTabNavigation.map((t) => (
                <SupportOptionCard isActive={t.id === isActive} key={t.option} {...t} />
            ))}
        </div>
    );
};

export default SupportTicketStatusTab;