import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ClassroomModel } from '../../../model/classroom.model';

export function StudentDashboardClassroomCard({ model }: { model: ClassroomModel }) {

  const [isModel, setIsModel] = useState(false);

  return (
    <Link to={`/classrooms/view/${model.id}`}>
      <div className='hover:scale-105 transition-[1000s] flex flex-col w-full gap-1 items-center p-2'>
        <div className={model.profile_image ? 'w-[50%] max-w-[50%] h-[100px] rounded-lg overflow-hidden' : 'w-[50%] bg-black max-w-[50%] h-[100px] rounded-lg overflow-hidden'}>
          <img src={model.profile_image ?? 'https://img.redbull.com/images/c_limit,w_1500,h_1000,f_auto,q_auto/redbullcom/2017/11/07/0a36b5f1-90ad-4821-a0a8-0968c6d2439b/mountains-you-need-to-climb'} className='w-full object-cover h-full' />
        </div>
        <p className='max-w-[20ch] truncate'>{model.name}</p>
        <p className='max-w-[17ch] truncate'>{model.coach}</p>
      </div>
    </Link>
  );
}
