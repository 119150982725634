import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { TwitchPlayer } from 'react-twitch-embed';
import { OutsideClick } from '../../../../components/outside-click';
import { selectRole } from '../../../../slices/auth';
import { useSelector } from 'react-redux';

const GaRecentVideoRoute = () => {
  const role=useSelector(selectRole);
  const param = useParams();
  //allow GameArena routes
  if(role!==0){
    return <Navigate to='../dashboard' />
    }
  return (
    <>
      <OutsideClick />
      <TwitchPlayer
        video={param.vid}
        className='fixed top-0 left-0 right-0 bottom-0 w-[70%] m-auto z-[30]'
        autoplay
        muted
      />
    </>
  );
};

export default GaRecentVideoRoute;
