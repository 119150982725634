import { ViewClassroomChatMessage } from './view-classroom-chat-message';
import { ViewClassroomChatTab } from './view-classroom-chat-tab';
import { useState } from 'react';
import { ClassroomChatParticipantRow } from './classroom-chat-participant-row';
import { AiOutlineSend } from 'react-icons/ai';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../../slices/auth';
import { ParticipantType } from '../../../utils/utils';

export function ViewClassroomChat({
  sendChat,
  tempChat,
  setTempChat,
  participants,
  handleDeleteChat,
}: {
  handleDeleteChat: (m: number) => void;
  sendChat: Function;
  tempChat: any;
  setTempChat: Function;
  participants: ParticipantType;
}) {
  const [tab, setTab] = useState(1);
  const param = useParams();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const handleSubmit = () => {
    if (tab === 1) {
      if (!tempChat || !tempChat.length) return;
      // if (tempChat && tempChat.trim() !== '') {
      sendChat(tempChat, true);
      // dispatch(
      //   addClassroomChatMessage({
      //     username: user.username,
      //     user_id: user.id,
      //     // classroom_id:param.id,
      //     team: user?.team?.length ? user.team[teamId].teamName : '',
      //     message: tempChat,
      //     image: user.image,
      //     time: new Date().toISOString(),
      //     isMe: true,
      //   }),
      // );
      // }
    }
    setTempChat('');
  };
  return (
    <div className='flex p-2  gap-2 flex-col w-full rounded-lg shadow-lg h-[400px]'>
      <div className='flex'>
        <ViewClassroomChatTab tab={tab} title='Chat' id={1} setTab={setTab} />{' '}
        <ViewClassroomChatTab tab={tab} title='Participant' id={2} setTab={setTab} />{' '}
      </div>
      <div
        className={`flex-1 gap-2 flex flex-col w-full overflow-auto ${
          tab === 1 ? 'justify-between' : ''
        }`}
      >
        {tab === 1 && (
          <>
            <div className='h-[80%] gap-1 flex flex-col'>
              <ViewClassroomChatMessage handleDeleteChat={handleDeleteChat} test={false} />
              {/* <ViewClassroomChatMessage test={false} /> */}
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <div className='flex p-2  '>
                <div className='flex border-1 w-full items-center outline-2 p-2 rounded-lg'>
                  <input
                    onChange={(e) => setTempChat(e.target.value)}
                    type='text'
                    placeholder='Start writing...'
                    name=''
                    id=''
                    className='flex-1 active:outline-none focus:outline-none'
                    value={tempChat}
                  />
                  <AiOutlineSend onClick={handleSubmit} />
                </div>
              </div>
            </form>
          </>
        )}
        {tab === 2 &&
          participants.map((k) => <ClassroomChatParticipantRow {...k} key={k.username} />)}
      </div>
    </div>
  );
}
