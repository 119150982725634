import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { selectMatchData } from '../../../slices/matchSlice';
import { selectUser } from '../../../slices/auth';
import { SlArrowLeft } from 'react-icons/sl';
import { useGetMatchForfeitHistoryByIdQuery } from '../../../slices/matchApiSlice';
import { ForfeitHistoryActions, userRoleNames } from '../../../utils/constants';
import { dateAndTimeFormatter } from '../../../utils/dateFormatter';
import { CustomError } from '../../../utils/custom-types';

interface IForfeitHistory {
  action: number;
  User: { roles: number };
  date: string;
  id: number;
  match_id: number;
  reason: string;
}

const ViewForfeits = () => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const param = useParams();
  const { data, isLoading, isError, error } = useGetMatchForfeitHistoryByIdQuery(param?.id, {
    skip: !param?.id,
  });
  const matchData = useSelector(selectMatchData);

  function handleExit() {
    setShow(false);
    setTimeout(() => navigate('../'), 600);
  }

  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), 100);
    return () => clearTimeout(timeout);
  }, []);

  if (isLoading && show) return <span>loading...</span>;

  if (isError)
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError)?.data?.message }} />;

  return (
    <div
      className={`absolute max-w-[500px] top-[150px] bg-white bottom-0 w-full md:w-1/2 z-[4] border-1 p-2 duration-500 ${
        show ? '-left-0' : '-left-[100%]'
      } overflow-auto`}
    >
      <div className='flex justify-between items-center mt-2 mb-4 border-b-1 pb-3'>
        <h2 className='text-md font-bold'>Forfeits</h2>
        <SlArrowLeft className='cursor-pointer' size={14} onClick={handleExit} />
      </div>
      <div className='flex flex-col gap-3'>
        {data?.ForfeitHistory?.length ? (
          data?.ForfeitHistory?.map((f: IForfeitHistory) => (
            <div className='flex flex-col gap-2 border-b-1 pb-3' key={f?.id}>
              {f?.reason ? (
                <h5 className='font-bold text-[#000] text-sm'>
                  Reason: <span>{f?.reason}</span>
                </h5>
              ) : null}
              <h5 className='font-bold text-[#000] text-sm'>
                Made by: <span>{userRoleNames[f?.User?.roles]}</span>
              </h5>
              {/* <h5 className='font-bold text-[#000] text-sm'>Date: <span>{dateAndTimeFormatter(f?.date)}</span></h5> */}
              <h5 className='font-bold text-[#000] text-sm'>
                Date: <span>{dateAndTimeFormatter(f?.date)?.split(';')?.join()}</span>
              </h5>
              <h5 className='font-bold text-[#000] text-sm'>
                Action: <span>{ForfeitHistoryActions[f?.action]}</span>
              </h5>
            </div>
          ))
        ) : (
          <div className='flex items-center border-1 rounded-lg p-2'>
            <span>There are no forfeit history data at the moment.</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewForfeits;
