import * as yup from 'yup';
import {
  cityRules,
  emailRules,
  nameRulesHint,
  nameRulesWithSpecialCharacter,
  passwordRules,
  passwordRulesHint,
  usernameRules,
  usernameRulesHint,
  validEmailRule,
  zipCodeRules,
  zipCodeRulesHint,
} from '../../yupschema';
import { ErrorMessages } from '../../../../utils/messages.enum';
export const studentSchema = (includeEmail: boolean) => {
  return yup.object().shape({
    firstName: yup
      .string()
      .matches(nameRulesWithSpecialCharacter, nameRulesHint)
      .required('Required!'),
    lastName: yup
      .string()
      .matches(nameRulesWithSpecialCharacter, nameRulesHint)
      .required('Required!'),
    gradeLevel: yup.string().required('Required!'),
    // schoolName: yup.string().required("Required"),
    username: yup.string().matches(usernameRules, usernameRulesHint).required('Required!'),
    ...(includeEmail && {
      email: yup.string().matches(emailRules, validEmailRule).required('Required!'),
    }),
    // streetAddress: yup.string().required('Required!'),
    // gender: yup.string().required('Required'),
    // phoneNumber: yup.string().matches(usPhoneRules, usPhoneRulesHint).required('Required!'),
    // dateOfBirth: yup
    //   .date()
    //   .max(moment().endOf('day').subtract(13, 'years'), 'You must be at least 13 years old!')
    //   .required('Required!'),

    zipCode: yup.string().matches(zipCodeRules, zipCodeRulesHint).nullable(),
    state: yup.string().nullable(),
    city: yup.string().matches(cityRules, ErrorMessages.CityValidation).nullable(),
    gender: yup.string().required('Required!'),
    // email: yup.string().email("Please enter a valid email").required("Required"),
    ageAgreement: yup.bool().oneOf([true], 'Required!'),
    check: yup.bool().oneOf([true], 'Required!'),
    password: yup
      .string()
      .min(5)
      .matches(passwordRules, { message: passwordRulesHint })
      .required('Required!'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match!')
      .required('Required!'),
    // copa: yup.string().required('Required'),
    // acceptedTos: yup
    //   .boolean()
    //   .oneOf([true], "Please accept the terms of service"),

    // recaptcha: yup.mixed().required("This field is required"),
  });
};
