import { useDroppable } from '@dnd-kit/core';
import React, { useEffect } from 'react';
import { UserInfo } from './user-info';
import { WarningMessages } from '../../../../../../utils/constants';
export function Substitudes({
  userList,
  editMode,
  usersLength,
  team,
}: // limit,
{
  userList: any;
  editMode: boolean;
  usersLength: number[];
  team: any;
  // limit: number;
}) {
  const { isOver, setNodeRef } = useDroppable({ id: 'substitudes' });

  return (
    <div
      ref={setNodeRef}
      className={`flex flex-col items-center gap-2 min-h-[200px] ${
        isOver ? 'bg-green-300' : ''
      } rounded-b-xl`}
    >
      <h2 className='font-bold text-xl text-center'>
        Potential Substitutes
        {/* - Limit: {limit}, Available Slots: {limit - userList.length} */}
      </h2>
      {team.Game.active_limit === 1 ? (
        <h3 className='flex text-center justify-center text-lg font-bold m-10'>
          {WarningMessages.CanNotAddSubsToOnePlayerTeams}
        </h3>
      ) : (
        <div className='w-fit max-w-full flex justify-evenly flex-wrap gap-2'>
          {usersLength
            .filter((u: any) => u.type !== 'activeMember')
            .map((u: any, i) => {
              const user = userList.filter((us: any) => us.id === u);
              return (
                <UserInfo
                  username={u.username}
                  // userList={userList}
                  editMode={editMode}
                  key={i}
                  id={u}
                  type='substitudes'
                  isArchived={!user.length}
                  {...user?.[0]}
                />
              );
            })}{' '}
        </div>
      )}
    </div>
  );
}
