import React from 'react';
import { Link } from 'react-router-dom';
export function UnforfeitDoubleMatchButton({ }) {
    return (
        <Link to='./unforfeit-double'>
            <div className='rounded-lg flex justify-center p-2 uppercase main-gr-bg-color text-white text-sm min-w-[200px]'>
                Double unforfeit
            </div>
        </Link>
    );
};