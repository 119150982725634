import { useGetIndividualInvoicesQuery } from '../../../../slices/invoicesApiSlice';

const GuardianStudentSubscription = ({ studentId }: { studentId?: number }) => {
  const { data, isLoading, isError } = useGetIndividualInvoicesQuery({
    id: Number(studentId),
    params: undefined,
  });
  if (isLoading) return <p>Loading...</p>;

  // TODO: PAYMENT: REMOVE THIS RETURN TO USE PAYMENT
  return <p></p>;
  // if (!data || !data?.length)
  //   return (
  //     <div className='flex flex-col w-full h-full justify-center items-center font-bold'>
  //       <TbMoodEmpty size={40} />
  //       <p>No Subscriptions!</p>
  //     </div>
  //   );
  // return (
  //   <div className='flex flex-col gap-5'>
  //     <div className='flex flex-wrap gap-2 justify-center'>
  //       {data.map((invoice: any) => (
  //         <GuardianStudentSubscriptionCard
  //           key={invoice.id}
  //           {...new StudentSubscriptionCardModel(invoice)}
  //         />
  //       ))}
  //     </div>
  //   </div>
  // );
};

export default GuardianStudentSubscription;
