// import { generateRandomColor } from '../../gamearena/component/live-matches-card';
import { dayInString } from '../../../utils/utils';
import { useGetAllMatchesByDateStatusQuery } from '../../../slices/matchApiSlice';
import { MatchScheduleModel } from '../../../model/match-schedule.model';
import { Link } from 'react-router-dom';
import { timeFormatter } from '../../../utils/dateFormatter';
import { useSelector } from 'react-redux';
import { selectCalendar } from '../../../slices/matchSlice';
import { useEffect, useRef, useState } from 'react';
import useIntersectionObserver from '../../../hooks/use-intersection-observer';

const CalendarMonthRow = ({
  currentYear,
  currentMonth,
  daysInCurrentMonth,
  // i,
  d,
}: {
  currentYear: number;
  currentMonth: number;
  daysInCurrentMonth: number;
  // i: number;
  d: string;
}) => {
  const [page, setPage] = useState<number>(1);
  const [fetch, setFetch] = useState(false);
  const calendarData = useSelector(selectCalendar);
  const { data, isLoading, isSuccess } = useGetAllMatchesByDateStatusQuery([
    {
      date: new Date(currentYear, currentMonth, +d + 2).toISOString().split('T')[0],
      // name: '',
      // name: undefined,
      page,
    },
    { dateStatus: 3 },
  ]);
  function handleClick() {
    setPage(page + 1);
  }
  const elementRef = useRef(null);
  const entry = useIntersectionObserver(elementRef, {});
  const isVisible = !!entry?.isIntersecting;

  const hasIOSupport = !!window.IntersectionObserver;
  useEffect(() => {
    if (isSuccess) console.log('isVisible', fetch);
    if (!isVisible || !fetch || isLoading || !data || !data.meta.next) return;
    setPage(data.meta.next);
  }, [isVisible, data?.meta, fetch]);
  useEffect(() => {
    console.log('isLoading', isLoading, fetch);
    if (isLoading) setFetch(false);
    else setTimeout(() => setFetch(true), 2000);
  }, [isLoading]);

  // if (isLoading) return <p>Loading...</p>;
  return (
    <div key={d} className='flex'>
      <div
        className={`font-semibold text-center border border-r-0  border-neutral-300 py-5 p-2 w-[150px] h-[160px] ${+d + 1 === daysInCurrentMonth ? 'border-b-1' : 'border-b-0'
          } ${+d === 0 ? 'rounded-tl-lg' : ''} ${+d + 1 === daysInCurrentMonth ? 'rounded-bl-lg' : ''}
             ${new Date().toDateString() ===
            new Date(currentYear, currentMonth, +d + 1).toDateString()
            ? 'bg-gray-50 font-bold'
            : ''
          } 
              `}
      >
        {(Number(d) + 1).toString().padStart(2, '0') +
          ' ' +
          dayInString(currentYear, currentMonth, +d + 1)}
      </div>
      <div
        className={`flex-1 border border-neutral-300 p-2 flex gap-2 overflow-auto  
               ${!+d ? 'rounded-tr-lg' : ''} 
 ${new Date().toDateString() === new Date(currentYear, currentMonth, +d + 1).toDateString()
            ? 'bg-gray-50'
            : ''
          } 
           
              ${+d + 1 === daysInCurrentMonth ? 'rounded-br-lg' : 'border-b-0'}`}
      >
        {isLoading || !calendarData ? (
          <p>Loading...</p>
        ) : (
          // data?.data.map((d: any, n: number) => {
          calendarData[
            new Date(currentYear, currentMonth, +d + 2).toISOString().split('T')[0]
          ]?.map((d: any, n: number) => {
            const model = new MatchScheduleModel(d);
            return (
              <Link
                to={`/match/${model.id}`}
                key={n}
                className={`h-[130px] w-40 flex-shrink-0 rounded-md flex-wrap flex flex-col p-1 items-center bg-sky-100`}
              >
                <div className='self-start w-full flex justify-between mt-2'>
                  <p className='text-cyan-700 text-xs font-bold self-start'>
                    {model.f_competitionType}
                  </p>
                  <p className='text-cyan-700 text-xs font-bold'>
                    {timeFormatter(model.start_date)}
                  </p>
                  {/* <p className='text-cyan-700 text-xs font-bold'>
                    {timeFormatter(model.start_date)}
                  </p> */}
                </div>
                <span className='flex flex-col gap-y-2 mt-4 text-center w-full'>
                  <p className='text-cyan-900 text-sm font-extrabold truncate'>{model.home_teamName}</p>
                  <p className='text-cyan-700 text-xs font-bold'>vs</p>
                  <p className='text-cyan-900 text-sm font-extrabold truncate'>{model.away_teamName}</p>
                </span>
              </Link>
            );
          })
        )}
        {data?.meta?.next ? (
          <div ref={elementRef} className='flex flex-1 w-full min-h-[10px]'></div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default CalendarMonthRow;
