import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { TwitchPlayer } from 'react-twitch-embed';
export function GaRecentVideoCard({ id, thumbnail_url }: any) {
  const [play, setPlay] = useState(false);
  const embed = useRef();
  const handleReady = (e: any) => {
    embed.current = e;
  };
  return (
    <Link
      to={`./video/${id}`}
      className='avatarPlaceholder w-[300px] h-[150px] rounded-lg cursor-pointer z-[2] relative'
      onMouseEnter={() => setPlay(true)}
      onMouseLeave={() => setPlay(false)}
    >
      {play ? (
        <>
          <div className='absolute  top-0 left-0 right-0 bottom-0'></div>
          <TwitchPlayer
            id={id}
            className='min-w-full min-h-full max-w-full max-h-full'
            video={id}
            autoplay
            hideControls
            muted
            onReady={handleReady}
          />
        </>
      ) : (
        <img
          src={
            thumbnail_url
              .replace('%{width}', '300')
              .replace('{width}', '300')
              .replace('%{height}', '150')
              .replace('{height}', '150') ?? ''
          }
          className='rounded-lg'
        />
      )}
    </Link>
  );
}
