import React from 'react';
import { Link } from 'react-router-dom';
export function ForfeitMatchButton({}) {
  return (
    <Link to='./forfeit'>
      <div className='rounded-lg flex justify-center p-2 uppercase bg-fen-red text-white text-sm min-w-[200px]'>
        Forfeit
      </div>
    </Link>
  );
}
