import React, { useState } from 'react';
import { OutsideClick } from '../../../../../../components/outside-click';
import { Form, Formik } from 'formik';
import { FormInput } from '../../../../../../components/form-input/form-input';
import { Navigate } from 'react-router-dom';
import {
  useCreateStudentInvoiceModalMutation,
  useGetNewInvoiceHelpDataQuery,
} from '../../../../../../slices/invoicesApiSlice';
import { NewSchoolInvoiceSchema } from './schema/new-student-invoice-v-schema';
import { InvoiceStatus, InvoiceStatusNames } from '../../../../../../utils/constants';
import { NewStudentInvoiceModel } from '../../../../../../model/new-student-invoice.model';
import ModalHeader from '../../../../../../components/modal-header';
import ModalFooter from '../../../../../../components/modal-footer';
import { addCurrentTimeToDate } from '../../../../../../utils/dateFormatter';

const NewInvoiceStudent = () => {
  const [chooseUser, setChooseUser] = useState<number>();

  const { data, isLoading: isDataLoading } = useGetNewInvoiceHelpDataQuery('');

  const [newSchoolInvoice, { isError, isSuccess, isLoading }] =
    useCreateStudentInvoiceModalMutation();

  const formInputs = [
    // {
    //   mainTitle: "Billing Information",
    //   name: "type",
    //   label: "Invoice Type",
    //   type: "number",
    //   placeholder: "Select subscription plan",
    // },
    {
      mainTitle: 'Invoice Information',
      name: 'school',
      label: 'School',
      type: 'text',
      placeholder: "Select organization's name",
      isSelect: true,
      options: data?.school ? data?.school : [],
    },
    {
      mainTitle: 'Invoice Information',
      name: 'user',
      label: 'Student',
      type: 'text',
      placeholder: 'Select a Student',
      isSelect: true,
      options: data?.school?.filter((o: any) => o.id === chooseUser)[0]?.User?.length
        ? new NewStudentInvoiceModel(data?.school?.filter((o: any) => o.id === chooseUser)[0]?.User)
            .students
        : [],
    },
    // {
    //   mainTitle: "Invoice Information",
    //   name: "description",
    //   label: "Description",
    //   type: "textarea",
    //   placeholder: "Enter description",
    // },
    {
      mainTitle: 'Invoice Information',
      name: 'season',
      label: 'Season',
      type: 'text',
      placeholder: 'Select a Season',
      isSelect: true,
      options: data?.seasons ? data?.seasons : [],
    },
    {
      mainTitle: 'Invoice Information',
      name: 'start_date',
      label: 'Invoice date',
      type: 'date',
      placeholder: 'Enter invoice date',
      isTrue: true,
    },
    {
      mainTitle: 'Invoice Information',
      name: 'end_date',
      label: 'Invoice due date',
      type: 'date',
      placeholder: 'Enter due date',
      isTrue: true,
    },
    {
      mainTitle: 'Invoice Information',
      name: 'total',
      label: 'Enter total',
      type: 'number',
      placeholder: '$1000',
      isTrue: true,
    },
    {
      mainTitle: 'Invoice Information',
      name: 'status',
      label: 'Invoice status',
      type: 'number',
      placeholder: 'Enter the status of invoice',
      isTrue: true,
      isSelect: true,
      options: [
        { id: InvoiceStatus.Upcoming, name: InvoiceStatusNames[InvoiceStatus.Upcoming] },
        { id: InvoiceStatus.Paid, name: InvoiceStatusNames[InvoiceStatus.Paid] },
        { id: InvoiceStatus.Overdue, name: InvoiceStatusNames[InvoiceStatus.Overdue] },
        { id: InvoiceStatus.Unpaid, name: InvoiceStatusNames[InvoiceStatus.Unpaid] },
        { id: InvoiceStatus.Processing, name: InvoiceStatusNames[InvoiceStatus.Processing] },
      ],
    },

    // {
    //   mainTitle: 'Invoice Information',
    //   name: 'type',
    //   label: 'Invoice type',
    //   type: 'number',
    //   placeholder: 'Check',
    //   isTrue: true,
    // },
  ];
  function onSubmit(values: any) {
    newSchoolInvoice({
      total: values.total,
      status: +values.status,
      // end_date: new Date(values.end_date).toISOString(),
      // start_date: new Date(values.start_date).toISOString(),
      end_date: addCurrentTimeToDate(values.end_date),
      start_date: addCurrentTimeToDate(values.start_date),
      user: values.user,
      season: values.season,
      type: values.type,
    });
  }
  const initialValues: { [key: string]: string } = {};
  formInputs.forEach((input) => (initialValues[input.name] = ''));

  if (isLoading || isDataLoading) return <p>Loading ...</p>;
  if (isSuccess) return <Navigate to='../' />;

  return (
    <>
      <OutsideClick />
      <Formik
        validationSchema={NewSchoolInvoiceSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {(formik) => {
          setChooseUser(+formik.values.school);
          return (
            <Form>
              <div className='fixed h-fit top-0 left-0 bottom-0 right-0 m-auto z-10 w-auto flex items-center justify-center  max-w-2xl'>
                {/*content*/}
                <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-[90vh]'>
                  {/*header*/}
                  <ModalHeader name={'New Invoice'} />
                  {/* <div className='flex items-start justify-between mb-2 p-5 border-b border-solid border-slate-200 rounded-t'>
                    <h3 className='text-3xl font-semibold'>Create Invoice</h3>
                    <button className='p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none'>
                      <span className='bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none'>
                        X
                      </span>
                    </button>
                  </div> */}
                  <div className='relative px-3 flex-auto overflow-auto'>
                    {formInputs.map((input: any, i: number) => (
                      <React.Fragment key={i}>
                        {!formInputs[i - 1] || formInputs[i - 1]?.mainTitle !== input.mainTitle ? (
                          <>
                            <div className='flex flex-col'>
                              <div className='mb-1'>
                                <p className='text-gray-800 font-bold pt-6'>{input.mainTitle}</p>
                                <hr />
                              </div>
                            </div>
                            <div className='flex flex-wrap gap-1'>
                              {formInputs
                                .filter((inp1: any) => inp1.mainTitle === input.mainTitle)
                                .map((inp: any, i: number) => (
                                  <React.Fragment key={i}>
                                    <div className={inp.isTrue ? 'flex-1 min-w-[300px]' : 'w-full'}>
                                      <FormInput {...inp} />
                                    </div>
                                  </React.Fragment>
                                ))}
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                  {/* <div className='flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b'>
                    <Link
                      className='w-1/5 my-12 text-center py-1 bg-white text-black shadow-lg font-semibold rounded-lg'
                      to='../'
                    >
                      Cancel
                    </Link>
                    <SubmitButton isLoading={isLoading} />
                  </div> */}

                  <ModalFooter isLoading={isLoading!} />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default NewInvoiceStudent;
