import { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { SortableItem } from './SortableItem';
import dragndrop from '../../../../assets/dragndrop.svg';
import { AvatarImage } from '../../../../components/avatar-image';
import {
  useGetAllMatchesByTournamentIdQuery,
  useManualBracketGeneratorMutation,
  useUpdateTournamentMatchesMutation,
} from '../../../../slices/matchApiSlice';
import { useGetSingleTournamentInfoQuery } from '../../../../slices/tournamentApiSlice';
import { Form, Formik } from 'formik';
import ModalHeader from '../../../../components/modal-header';
import ModalFooter from '../../../../components/modal-footer';
const EditManualBracket = () => {
  const params = useParams();
  const [
    updateTournamentMatches,
    { data: upData, isLoading: upIsLoading, isError: upIsError, isSuccess: upIsSuccess },
  ] = useUpdateTournamentMatchesMutation();
  const { data: tournamentInfo } = useGetSingleTournamentInfoQuery(params.id);
  const [matchOrder, setMatchOrder] = useState<any>([]);
  const {
    data: teamsByTournament,
    error,
    isLoading,
  } = useGetAllMatchesByTournamentIdQuery(params.id);
  useEffect(() => {
    // test();
    console.log(teamsByTournament);
    if (teamsByTournament) {
      teamsByTournament?.data[0]?.match.forEach((m: any) =>
        // m.forEach((t: any) =>
        //   setTeams((s: any) => (s ? [...s, t.teamName] : [t.teamName]))
        // )
        {
          setMatchOrder((s: any) => [...s, m.id]);
          setTeams((s: any) => [
            ...s,
            JSON.stringify(m.home_team[0]),
            // .teamName,
            JSON.stringify(m.away_team[0]),
            // .teamName,
          ]);
        },
      );
    }
  }, [teamsByTournament]);
  const [manualBracketGenerator, { isError, isLoading: generatorIsLoading, data, isSuccess }] =
    useManualBracketGeneratorMutation();

  function handleSubmit(values: any) {
    const arrToSend: any = [];
    matchOrder.forEach((m: any, i: number) => {
      // const selectedTeam = teamsByTournament.find((d: any) => d.teamName === t);
      // arrToSend.push({ id: selectedTeam.id, name: selectedTeam.teamName });
      arrToSend.push({
        id: m,
        home_team: teams[2 * i],
        away_team: teams[2 * i + 1],
      });
    });
    console.log('test arrToSend', arrToSend);
    updateTournamentMatches({
      id: params.id,
      body: {
        game_id: tournamentInfo.game_id,
        tournament_id: params.id,
        matches: arrToSend,
      },
    });
  }

  // const arr = ["team1", "team2"];
  // console.log(arr);

  {
    /* <img src={dragndrop} /> */
  }

  const [teams, setTeams] = useState<any[]>([]);

  function handleDragEnd(event: any) {
    console.log('Drag end called');
    const { active, over } = event;
    console.log('ACTIVE: ' + active.id);
    console.log('OVER :' + over.id);

    if (active.id !== over.id) {
      setTeams((items: any) => {
        console.log({ items }, 'active id', active.id, 'over id', over.id);
        const activeIndex = items.indexOf(active.id);
        // const activeIndex = items.findIndex((t:any)=>t.teamName === active.id)
        const overIndex = items.indexOf(over.id);
        // const overIndex = items.findIndex((t:any) => t.teamName === over.id)
        console.log('array move', arrayMove(items, activeIndex, overIndex));
        console.log('array of matches id', matchOrder);
        return [...arrayMove(items, activeIndex, overIndex)];

        // items: [2, 3, 1]   0  -> 2
        // [1, 2, 3] oldIndex: 0 newIndex: 2  -> [2, 3, 1]
      });
    }
  }
  if (upIsSuccess) return <Navigate to='../' />;
  if (isLoading) return <p>Loading..</p>;
  return (
    <Formik initialValues={{}} onSubmit={handleSubmit}>
      {({ isSubmitting, errors }) => (
        <Form>
          <div className='fixed h-fit top-0 left-0 bottom-0 right-0 m-auto z-10 w-auto flex items-center justify-center  max-w-2xl'>
            <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-[90vh]'>
              <ModalHeader name='Edit Bracket' />
              <div className='m-4 bg-gray-500 mb-5 p-2 text-white rounded-lg'>
                <h4 className='text-xl text-white'>Manual Bracket</h4>
                <hr />
                <p className='text-white text-sm p-2'>
                  Teams are seeded into the first round of the bracket in the exact order as
                  specified.
                </p>
              </div>

              <div className='relative p-3 flex-auto overflow-auto overflow-x-hidden'>
                <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                  <SortableContext items={teams} strategy={verticalListSortingStrategy}>
                    {/* We need components that use the useSortable hook */}
                    <table className='min-w-full divide-y divide-gray-200'>
                      <thead className='bg-gray-50'>
                        <tr>
                          <th
                            scope='col'
                            className='px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase '
                          >
                            <div className='flex'>
                              <div className='mr-10'>Rank</div>
                              <div className=''>Team</div>
                            </div>
                          </th>
                          {/* <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                      >
                        
                      </th> */}
                        </tr>
                      </thead>
                      <tbody className='divide-y divide-gray-200'>
                        {teams && teams.length ? (
                          teams.map((t: any, key: any) => (
                            <tr
                              key={key}
                              className={`items-center border-l-1 border-r-1 ${
                                key % 2 !== 0 ? 'border-b-2 ' : ' '
                              } ${key === 0 ? 'border-t-2' : null} `}
                            >
                              <td className='px-6 py-4 text-sm text-gray-800 whitespace-nowrap'>
                                <div className='flex'>
                                  <div className='mr-12'>{key + 1}</div>
                                  <div className='ml-4 flex w-1 '>
                                    <SortableItem key={t ?? key} id={t ?? key}>
                                      <div className='flex w-screen items-center'>
                                        <img src={dragndrop} className='mr-2' />
                                        <AvatarImage source={t ? JSON.parse(t)?.image : ''} />
                                        {JSON?.parse(t)?.isBenched ? (
                                          <div className='ml-4 flex items-center'>
                                            <p>{t ? JSON.parse(t)?.teamName : ''}</p>
                                            <span className='ml-2 text-[#B50303] font-[600] font-[Inter] text-[12px]'>
                                              BENCHED
                                            </span>
                                          </div>
                                        ) : (
                                          <div className='ml-4'>
                                            {t ? JSON.parse(t)?.teamName : ''}
                                          </div>
                                        )}
                                      </div>
                                    </SortableItem>
                                  </div>
                                </div>
                              </td>
                              {/* <SortableItem key={teams} id={teams}/> */}
                            </tr>
                          ))
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </table>
                  </SortableContext>
                </DndContext>
              </div>
              <ModalFooter isLoading={generatorIsLoading!} />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditManualBracket;
