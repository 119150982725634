import { useParams } from 'react-router';
import {
  useDeleteOgmaMutation,
  useGetAllOrganizationOgmasQuery,
} from '../../../../slices/organizationApiSlice';
import { OrganizationCoachModel } from '../../../../model/organization-coach.model';
import { AvatarImage } from '../../../../components/avatar-image';
import { IoRemoveCircleOutline } from 'react-icons/io5';
import Pagination from '../../../../components/pagination';
import { useEffect, useState } from 'react';
import { OutsideClick } from '../../../../components/outside-click';
import ModalContent from '../../../../components/modal-content';
import ModalFooter from '../../../../components/modal-footer';
const OrganizationOgmas = () => {
  const [selectedOgma, setSelectedOgma] = useState<{ id: number; name: string } | undefined>();
  const [deleteOgma, mutationState] = useDeleteOgmaMutation();
  const {
    isSuccess: removeSuccess,
    isError: removeError,
    isLoading: removeLoading,
  } = mutationState;
  const param = useParams();
  const { data, isLoading, isError } = useGetAllOrganizationOgmasQuery(param.id ?? '');
  function removeCoach(id: number, name: string) {
    // deleteOgma(coach_id);
    setSelectedOgma({ id, name });
  }
  useEffect(() => {
    if (!removeSuccess) return;
    setSelectedOgma(undefined);
  }, [removeSuccess]);
  if (isLoading) return <p>Loading...</p>;
  if (isError) return <p>Something went wrong! Try again later!</p>;
  return (
    <div className='flex flex-col flex-1 justify-between'>
      {selectedOgma ? (
        <RemoveOgmaDialog
          {...selectedOgma}
          {...mutationState}
          removeOgma={deleteOgma}
          cancel={() => setSelectedOgma(undefined)}
        />
      ) : (
        <></>
      )}
      <div className='flex flex-col gap-2'>
        {removeError && <p className='text-red-700'>Something went wrong! Please try again!</p>}
        {removeSuccess && (
          <p className='text-green-700'>Ogma was removed successfully from this organization!</p>
        )}
        <div className='flex flex-wrap gap-10'>
          {data.data.map((d: any) => {
            const model = new OrganizationCoachModel(d);
            return (
              <div
                key={model.id}
                className='relative flex flex-col p-10 rounded-lg shadow-lg items-center gap-10 w-[200px] overflow-hidden'
              >
                <IoRemoveCircleOutline
                  onClick={() => removeCoach(model.id, model.fullName)}
                  className='absolute top-1 right-1 hover:text-red-700 cursor-pointer'
                />

                <AvatarImage size='xxl' source={model.image} />

                <h2>{model.fullName}</h2>
              </div>
            );
          })}
        </div>
      </div>
      <Pagination length={data?.data?.length} {...data?.meta} />
    </div>
  );
};
const RemoveOgmaDialog = ({
  id,
  name,
  cancel,
  isLoading,
  isSuccess,
  removeOgma,
}: {
  id: number;
  name: string;
  cancel: () => void;
  isLoading: boolean;
  isSuccess: boolean;
  removeOgma: Function;
}) => {
  // if (isSuccess)  cancel();
  return (
    <>
      <OutsideClick />
      <ModalContent extraClass='h-fit' hideFooter name='Remove Ogma?'>
        <>
          <div className='flex flex-col p-10 text-center items-center'>
            <p>Are you sure you want to remove {name}?</p>
          </div>
          <ModalFooter isLoading={isLoading} onCancel={cancel} onSubmit={() => removeOgma(id)} />
        </>
      </ModalContent>
    </>
  );
};
export default OrganizationOgmas;
