import React, { useEffect } from 'react';

import LiveMatchesCard from './live-matches-card';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { BsFillArrowRightCircleFill, BsFillArrowLeftCircleFill } from 'react-icons/bs';

const GameArenaSlider = ({ data, setSelected, selected }: any) => {
  const prevButton = React.useRef(null);
  const nextButton = React.useRef(null);
  let dragging = false;
  useEffect(() => {
    console.log('testData slider', data);
  }, [data]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipe: true,
    swipeToSlide: true,
    beforeChange: () => (dragging = true),
    afterChange: () => (dragging = false),
    nextArrow: <BsFillArrowRightCircleFill color='#b50303' />,
    prevArrow: <BsFillArrowLeftCircleFill color='#b50303' />,
    responsive: [
      {
        breakpoint: 2100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    // <div className='flex flex-wrap bg-transparent absolute inset-x-0 bottom-[-125px] gap-0 rounded-xl p-10 z-40'>
    <div className='bg-transparent absolute inset-x-0 bottom-[-125px] rounded-xl p-10'>
      <Slider {...settings} className=''>
        {data &&
          data.map((tw: any, index: any) => (
            <LiveMatchesCard
              dragging={dragging}
              key={tw.id}
              id={tw.id}
              {...tw}
              source={tw.thumbnail_url}
              isSlider={true}
              setSelected={setSelected}
              selected={selected}
            />
          ))}
      </Slider>
    </div>
  );
};

export default GameArenaSlider;
