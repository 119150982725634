import { NotificationNavRow } from './notification-nav-row';
import { useEffect, useState } from 'react';
// import noNotification from '../assets/notification.svg';
import { Link } from 'react-router-dom';
import {
  useGetUnseenNotificationQuery,
  useLazyGetUnseenNotificationQuery,
  useMakeSeenListOfNotificationsMutation,
} from '../slices/notificationApiSlice';
import { PropagateLoader } from 'react-spinners';
import { NotificationModel } from '../model/notification.model';
import { useSelector } from 'react-redux';
import { selectUser } from '../slices/auth';
import { IoNotificationsOffSharp } from 'react-icons/io5';

const Notification = () => {
  const user = useSelector(selectUser);
  const { data, isLoading, isError, isSuccess, refetch } = useGetUnseenNotificationQuery(user.id);
  const [getUnseenNotification] = useLazyGetUnseenNotificationQuery();

  const [makeSeenNotification, { isLoading: isLoad }] = useMakeSeenListOfNotificationsMutation();
  useEffect(() => {
    if (!data?.latest?.length || !refetch) return;
    const list: number[] = [];
    data.latest.forEach((d: { id: number }) => list.push(d.id));

    makeSeenNotification({ body: { list }, count: data.unseen - list.length, user: user.id });
    return () => {
      getUnseenNotification(user.id);
    };
  }, [isSuccess, refetch]);

  if (isLoading) return <PropagateLoader color='#123abc' />;
  return (
    <div className='flex flex-col gap-5'>
      <div className='flex justify-between items-center'>
        <div className='flex gap-3'>
          <p className='font-semibold text-sm dark:text-gray-200'>Notifications</p>
          {/* <button type='button' className='text-white text-xs rounded p-1 px-2 bg-orange-theme '>
            {' '}
            5 New
          </button> */}
        </div>
      </div>
      <div className='max-h-[300px] overflow-auto'>
        {data.latest.length ? (
          data.latest.map((item: any, index: number) => (
            <NotificationNavRow key={index} model={new NotificationModel(item)} />
          ))
        ) : (
          <>
            <div className='flex flex-col items-center gap-y-7 pt-5'>
              {/* <img src={noNotification} alt='noNotification' /> */}

              <IoNotificationsOffSharp size={40} color='gray' />
              <h2 className='text-gray-500 font-bold text-xs'>
                There are no notifications to display!
              </h2>
            </div>
          </>
        )}
      </div>
      <div className='flex flex-row gap-x-3 '>
        <Link to='../notifications' className='text-xs flex gap-5  text-red-700'>
          See all Notifications
          {data.unseen ? <span className='text-red-700 font-bold'>{data.unseen}+</span> : <></>}
        </Link>
        <Link to='./Settings/notification/' className='text-xs flex gap-5 hover:text-red-900'>
          <span className='text-red-700  '>Settings</span>
        </Link>
      </div>
    </div>
  );
};

export default Notification;
