import { dateToLocal } from '../utils/dateFormatter';

export class TournamentModel {
  id;
  name;
  image;
  game;
  gameImage;
  startDate;
  f_startDate;
  endDate;
  f_endDate;
  team_size;
  teams;
  division;
  subdivision;
  createdAt;
  updatedAt;
  status;
  constructor(data: any) {
    if (data.hasOwnProperty('id')) this.id = data.id;
    if (data.hasOwnProperty('image')) this.image = data.image ?? '';
    if (data.hasOwnProperty('name')) this.name = data.name ?? '';
    if (data.hasOwnProperty('Game')) this.game = data.Game?.name ?? '';
    if (data.hasOwnProperty('Game') && data.Game?.hasOwnProperty('image'))
      this.gameImage = data.Game?.image ?? '';
    if (data.hasOwnProperty('team_size')) this.team_size = data.team_size;
    if (data.hasOwnProperty('start_date')) {
      this.startDate = data.start_date ?? new Date();
      this.f_startDate = dateToLocal(this.startDate, 'MMMM D, YYYY');
    }
    if (data.hasOwnProperty('end_date')) {
      this.endDate = data.end_date ?? new Date();
      this.f_endDate = dateToLocal(this.endDate, 'MMMM D, YYYY');
    }
    if (data.hasOwnProperty('teams')) this.teams = data.teams;
    if (data.hasOwnProperty('CategoryDivision')) {
      this.division = data.CategoryDivision?.name ?? '-';
      if (data.hasOwnProperty('subdivisions')) this.subdivision = data.subdivisions;
    }
    if (data.hasOwnProperty('created_at'))
      this.createdAt = dateToLocal(data.created_at ?? new Date(), 'MMMM D, YYYY');
    if (data.hasOwnProperty('updated_at'))
      this.updatedAt = dateToLocal(data.updated_at ?? new Date(), 'MMMM D, YYYY');
    if (data.hasOwnProperty('status')) {
      this.status = data.status;
    }
  }
}
