import React from 'react';
import { SeasonWeekModel } from '../../../model/season-week.model';
export function SeasonWeekDateInfo({ seasonWeek }: { seasonWeek: SeasonWeekModel }) {
  return (
    <>
      <div className='inline-flex flex-wrap gap-5 items-start'>
        <p>
          Week Start Date: <span className='font-bold'>{seasonWeek.f_start_time}</span>
        </p>
        {seasonWeek.end_time?.length ? <p className='hidden lg:flex'>|</p> : <></>}
        {seasonWeek.end_time?.length ? (
          <p>
            Week End Date: <span className='font-bold'>{seasonWeek.f_end_time}</span>
          </p>
        ) : (
          ''
        )}
        <p>
          Week Start Time:{' '}
          <span className='font-bold'>
            {seasonWeek.start_time ? new Date(seasonWeek.start_time).toLocaleTimeString() : 'N/A'}
          </span>
        </p>
      </div>
    </>
  );
}
