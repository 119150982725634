import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useGetOrgByIdQuery } from '../../../../slices/organizationApiSlice';
import { OrganizationTeamsBySchool } from './organization-teams-by-school';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../slices/auth';

const OrganizationSchools = () => {
  const param = useParams();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const {
    data: organizationData,
    isLoading: organizationIsLoading,
    isError: organizationIsError,
    isSuccess: organizationIsSuccess,
  } = useGetOrgByIdQuery({
    id: param.id ?? user.organizations[0].id,
    query: 'match'
  });

  // const {
  //     data: teamsData,
  //     isLoading: teamsIsLoading,
  //     isError: teamsIsError,
  //     isSuccess: teamsIsSuccess,
  // } = useGetAllTeamsBySchoolIdQuery();

  useEffect(() => {
    console.log('ididididid');
    console.log(organizationData.schools);
  }, []);

  return (
    <div className='flex flex-wrap'>
      {organizationData &&
        organizationData.schools.map((school: any) => (
          <Link to={`/schools/view/${school.id}`}>
            <div className='flex flex-col justify-center items-center bg-white rounded border p-2 hover:bg-gray-100 relative h-[300px] w-[300px] pt-[100px]'>
              <img
                src={school.image}
                className='absolute top-0 left-0 w-full h-full object-cover'
              />
              <div className='bg-black bg-opacity-40 absolute top-0 left-0 right-0 bottom-0'></div>
              <div className='flex flex-col gap-2 items-center justify-center z-[1] text-white'>
                {/* <AvatarImage size='xl' source={school.image} /> */}

                <h1 className='text-xl font-bold text-white max-w-[15ch] truncate'>{school.name}</h1>
                <OrganizationTeamsBySchool active={true} id={school.id} />
              </div>
              {/* <div className='flex justify-center'> */}
              {/* </div> */}
            </div>
          </Link>
        ))}
    </div>
  );
};

export default OrganizationSchools;
