import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { OutsideClick } from '../../../components/outside-click';
import { useDispatch, useSelector } from 'react-redux';
import { apiSlice } from '../../../api/apiSlice';
import { changeTerms, logOut, selectUser } from '../../../slices/auth';
import {
  useAcceptTermsAndConditionsByUserMutation,
  useLazyGetLatestTermsAndConditionsQuery,
} from '../../../slices/termsApiSlice';
import DOMPurify from 'dompurify';


const AcceptedTerms = () => {
  console.log('first');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  // const { data, isLoading } = useGetLatestTermsAndConditionsQuery('');

  const [getTerms, { data, isLoading }] = useLazyGetLatestTermsAndConditionsQuery();

  const [acceptTerm, { isLoading: isLoad, isSuccess }] =
    useAcceptTermsAndConditionsByUserMutation();

  function onClick() {
    acceptTerm({
      body: user.id,
    })
      .unwrap()
      .then((res) => {
        dispatch(changeTerms(true));
      });
  }
  const fetchLatestTerms = () => {
    getTerms('', false);
  };

  useEffect(() => {
    fetchLatestTerms();

    // const pollingInterval = setInterval(fetchLatestTerms, 10000);
    // return () => clearInterval(pollingInterval);
  }, [user?.acceptedTerms]);

  if (!data || isLoading) return <p>Loading</p>;
  // if(isSuccess) return navigate('/dashboard')
  return (
    <>
      <OutsideClick />
      <div className='modalBackground fixed top-0 left-0 right-0 bottom-0 m-auto z-10'>
        <div className='modalContainer'>
          <p className='text-left text-gray-400'>AGREEMENT</p>

          <p className='text-left text-blue-600 text-2xl mb-7'>Terms of Service</p>
          <div className='overflow-y-auto p-4' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.content) }} />

          <div className='flex mt-auto justify-end '>
            <button
              onClick={() => {
                dispatch(apiSlice.util.resetApiState());
                dispatch(logOut());
              }}
              className='w-1/3 my-12  py-1 bg-white text-blue-500 shadow-lg font-semibold rounded-lg  '
            >
              Decline
            </button>

            <button
              onClick={onClick}
              className='w-1/3 my-12 py-1 bg-red-700 shadow-lg text-white font-semibold rounded-lg ml-4'
            >
              Accept
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AcceptedTerms;
