import React from 'react';
import { MdOutlineDone } from 'react-icons/md';
import { CgMediaLive } from 'react-icons/cg';
import { BsFillRecordFill } from 'react-icons/bs';
import { TiCancel } from 'react-icons/ti';
import { MatchStatus } from '../../../utils/constants';
export function MatchDetailsMatchStatus({ status }: { status: number }) {
  // 0 - upcoming, 1 - completed, 2 - in progress, 3 - overdue, 4 - cancelled, 5 - forfeit, 6 - doubleforfeit, 7 - ByeMatch, 8 - RescheduleRequested, 9 - Banched
  switch (status) {
    case MatchStatus.Upcoming:
      return (
        <div className='flex flex-col items-start gap-10'>
          <div className='flex items-center justify-center px-2 py-2 my-2 gap-2 bg-slate-800 uppercase text-xs text-white rounded-md  overflow-hidden min-w-[100px]'>
            Upcoming
            <div className='tracking-wider flex h-full aspect-square rounded-sm p-[2px] bg-slate-400 justify-center items-center'>
              <MdOutlineDone color='white' />
            </div>
          </div>
        </div>
      );
    case MatchStatus.Completed:
      return (
        <div className='flex flex-col items-start gap-10'>
          <div className='tracking-wider flex items-center justify-center px-2 py-2 gap-1 my-2 bg-green-800 uppercase text-xs text-white rounded-md  overflow-hidden min-w-[100px]'>
            Completed
            <div className='flex h-full aspect-square rounded-sm p-[2px] bg-green-400 justify-center items-center'>
              <MdOutlineDone color='white' />
            </div>
          </div>
        </div>
      );
    case MatchStatus.InProgress:
      return (
        <div className='flex flex-col items-start gap-10'>
          <div className='tracking-wider flex items-center justify-evenly px-2 py-2 gap-1 my-2 bg-yellow-800 uppercase text-xs text-white rounded-md  overflow-hidden min-w-[100px]'>
            In Progress
            <div className='flex h-full aspect-square rounded-sm p-[2px] justify-center items-center relative'>
              <BsFillRecordFill className='animate-ping absolute inline-flex h-full w-full rounded-full bg-red-300 opacity-1' />
              <span className='relative inline-flex rounded-full h-2 w-2 bg-red-600'></span>
            </div>
          </div>
        </div>
      );
    case MatchStatus.Overdue:
      return (
        <div className='flex flex-col items-start gap-10'>
          <div className='tracking-wider flex items-center justify-center px-2 py-2 gap-2 my-2 bg-red-800 uppercase text-xs text-white rounded-md  overflow-hidden min-w-[100px]'>
            Overdue
            <div className='flex h-full aspect-square rounded-sm p-[2px] bg-red-400 justify-center items-center'>
              <MdOutlineDone color='white' />
            </div>
          </div>
        </div>
      );
    case MatchStatus.Cancelled:
      return (
        <div className='flex flex-col items-start gap-10'>
          <div className='tracking-wider flex items-center justify-center px-2 py-2 my-2 gap-2 bg-slate-800 uppercase text-xs text-white rounded-md  overflow-hidden min-w-[100px]'>
            Cancelled
            <div className='tracking-wider flex h-full aspect-square rounded-sm p-[2px] bg-slate-400 justify-center items-center'>
              <TiCancel color='white' />
            </div>
          </div>
        </div>
      );
    case MatchStatus.Forfeit:
      return (
        <div className='flex flex-col items-start gap-10'>
          <div className='tracking-wider flex items-center justify-center px-2 py-2 my-2 gap-2 bg-red-800 uppercase text-xs text-white rounded-md  overflow-hidden min-w-[100px]'>
            Forfeit
            <div className='flex h-full aspect-square rounded-sm p-[2px] bg-red-400 justify-center items-center'>
              <MdOutlineDone color='white' />
            </div>
          </div>
        </div>
      );
    case MatchStatus.DoubleForfeit:
      return (
        <div className='flex flex-col items-start gap-10'>
          <div className='tracking-wider flex items-center justify-center px-2 py-2 my-2 gap-2 bg-red-800 uppercase text-xs text-white rounded-md  overflow-hidden min-w-[100px]'>
            Double Forfeit
            <div className='flex h-full aspect-square rounded-sm p-[2px] bg-red-400 justify-center items-center'>
              <MdOutlineDone color='white' />
            </div>
          </div>
        </div>
      );
    case MatchStatus.ByeMatch:
      return (
        <div className='flex flex-col items-start gap-10'>
          <div className='tracking-wider flex items-center justify-center px-2 py-2 my-2 gap-2 bg-blue-800 uppercase text-xs text-white rounded-md  overflow-hidden min-w-[100px]'>
            Bye
            <div className='flex h-full aspect-square rounded-sm p-[2px] bg-blue-400 justify-center items-center'>
              <MdOutlineDone color='white' />
            </div>
          </div>
        </div>
      );
    case MatchStatus.RescheduleRequested:
      return (
        <div className='flex flex-col items-start gap-10'>
          <div className='tracking-wider flex items-center justify-center px-2 py-2 my-2 gap-2 bg-yellow-800 uppercase text-xs text-white rounded-md  overflow-hidden min-w-[100px]'>
            Reschedule Requested
            <div className='flex h-full aspect-square rounded-sm p-[2px] bg-orange-400 justify-center items-center'>
              <MdOutlineDone color='white' />
            </div>
          </div>
        </div>
      );
    case MatchStatus.Benched:
      return (
        <div className='flex flex-col items-start gap-10'>
          <div className='tracking-wider flex items-center justify-center px-2 py-2 my-2 gap-2 bg-purple-800 uppercase text-xs text-white rounded-md  overflow-hidden min-w-[100px]'>
            Benched
            <div className='flex h-full aspect-square rounded-sm p-[2px] bg-purple-400 justify-center items-center'>
              <MdOutlineDone color='white' />
            </div>
          </div>
        </div>
      );
    case MatchStatus.UpcomingRescheduledFor:
      return (
        <div className='flex flex-col items-start gap-10'>
          <div className='tracking-wider flex items-center justify-center px-2 py-2 my-2 gap-2 bg-slate-800 uppercase text-xs text-white rounded-md  overflow-hidden min-w-[100px]'>
            Upcoming (Reschduled for)
            <div className='flex h-full aspect-square rounded-sm p-[2px] bg-slate-400 justify-center items-center'>
              <MdOutlineDone color='white' />
            </div>
          </div>
        </div>
      );
    default:
      return <div></div>;
  }
}
