import React from 'react';
// import { defaultBg } from '../../utils/utils';

const defaultBg = (await import('../../assets/grid-background.jpg')).default;

export function BannerImage({ source = defaultBg, alt }: { source: string; alt?: string }) {
  return (
    <div className='flex absolute w-full h-full'>
      {source ? (
        <img src={source} alt={alt} className='object-contain absolute w-full  top-0 left-0' />
      ) : (
        <></>
      )}
    </div>
  );
}

export default BannerImage;
