import { BsArrowLeft } from 'react-icons/bs';
import { Link } from 'react-router-dom';

interface BackToSeasonProps {
  seasonWeekId: number;
  seasonId: number;
}

export function BackToSeason({ seasonId, seasonWeekId }: BackToSeasonProps) {
  return (
    <Link to={`/seasons/view/${seasonId}?week=${seasonWeekId}`}>
      <div className='flex rounded-md p-1 gap-2 px-2 text-white bg-dark-blue text-xs items-center'>
        <BsArrowLeft color='white' />
        Back to Weeks
      </div>
    </Link>
  );
}
