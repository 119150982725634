import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import {
  cityRules,
  emailRules, nameRulesHint,
  nameRulesWithSpecialCharacter,
  usPhoneRules,
  usPhoneRulesHint,
  usernameRules,
  usernameRulesHint,
  validEmailRule,
  zipCodeRules,
  zipCodeRulesHint
} from '../../authentication/yupschema';
import { useStateContext } from '../../../contexts/ContextProvider';
import { Navigate } from 'react-router-dom';
import { FormInput } from '../../../components/form-input/form-input';
import { useCreateCoachMutation, useInviteUserMutation } from '../../../slices/userApiSlice';
import { Checkbox } from '../../../components/ui/checkbox/Checkbox';
import * as yup from 'yup';
import { UserRoles, gendersOptions } from '../../../utils/constants';
import { allStates } from '../../../utils/constants';
import ModalFooter from '../../../components/modal-footer';
import { CustomError } from '../../../utils/custom-types';
import { ErrorMessages } from '../../../utils/messages.enum';

const NewCoachModal = () => {
  const { showModal, setShowModal } = useStateContext();
  const [inviteOnly, setInviteOnly] = useState(false);
  const [createCoach, { error, isLoading, isError, isSuccess }] = useCreateCoachMutation();
  const [
    inviteUser,
    {
      data: invData,
      isLoading: invIsLoading,
      isSuccess: invIsSuccess,
      isError: invIsError,
      error: invError,
    },
  ] = useInviteUserMutation();

  const onSubmit = (
    values: any,
    {
      setFieldValue,
    }: { setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void },
  ) => {
    if (inviteOnly) {
      inviteUser({
        body: { email: values.email },
        params: { role: UserRoles.Coach },
      });
      return;
    }
    const data = values;
    data.zipCode = Number(data.zipCode);
    data.status = Number(data.status);
    if (data.dateOfBirth) {
      data.dateOfBirth = new Date(data.dateOfBirth).toISOString();
    } else {
      data.dateOfBirth = null;
    }
    const selectedState = allStates.find((state) => state.id === values.state);
    if (selectedState) {
      data.state = selectedState.name;
    }

    createCoach(data)
      .unwrap()
      .then((res) => console.log(res))
      .catch((error) => {
        console.log(error);
        if (values.dateOfBirth) {
          const newDate = new Date(values.dateOfBirth);
          setFieldValue('dateOfBirth', newDate.toISOString().split('T')[0]);
        }
      });
  };

  function handleCheck(ch: boolean | string) {
    setInviteOnly(!inviteOnly);
    setSchema((state) => {
      const { email } = state;
      if (!inviteOnly) return { email };
      return initialSchema;
    });
  }

  const formInputs = [
    {
      mainTitle: 'Coach Information',
      name: 'firstName',
      label: 'First Name',
      type: 'text',
      placeholder: 'Enter first name',
    },
    {
      mainTitle: 'Coach Information',
      name: 'lastName',
      label: 'Last Name',
      type: 'text',
      placeholder: 'Enter last name',
    },
    {
      mainTitle: 'Coach Information',
      name: 'username',
      label: 'Username',
      type: 'text',
      placeholder: 'Enter username',
    },
    {
      mainTitle: 'Coach Information',
      name: 'city',
      label: 'City',
      type: 'text',
      placeholder: 'Enter city',
    },
    {
      mainTitle: 'Coach Information',
      name: 'state',
      type: 'text',
      label: 'State',
      placeholder: 'Enter state',
      isSelect: true,
      options: allStates ? allStates : [],
    },
    {
      mainTitle: 'Coach Information',
      name: 'zipCode',
      label: 'Zip Code',
      type: 'number',
      placeholder: 'Enter zip code',
    },
    // {
    //   mainTitle: 'Coach Information',
    //   name: 'tags',
    //   label: 'Tags',
    //   type: 'text',
    //   placeholder: 'Enter tags',
    // },
    {
      mainTitle: 'Coach Information',
      name: 'phoneNumber',
      label: 'Phone Number',
      type: 'text',
      placeholder: 'Enter phone number',
    },
    {
      mainTitle: 'Coach Information',
      name: 'gender',
      label: 'Gender',
      type: 'text',
      isSelect: true,
      options: gendersOptions,
    },
    // {
    //   mainTitle: 'Coach Information',
    //   name: 'status',
    //   label: 'Status',
    //   type: 'text',
    //   placeholder: 'Active',
    //   isSelect: true,
    //   options: [
    //     { id: 0, name: 'Disabled' },
    //     { id: 1, name: 'Active' },
    //   ],
    //   // isTrue: true,
    // },
    {
      mainTitle: 'Coach Information',
      name: 'email',
      label: 'Email Address',
      type: 'text',
      placeholder: 'Enter email address',
      inviteOnly: true,
    },
  ];

  const initialSchema = {
    // Role: yup.string().required("Required"),
    firstName: yup.string().matches(nameRulesWithSpecialCharacter, nameRulesHint).required('Required!'),
    lastName: yup.string().matches(nameRulesWithSpecialCharacter, nameRulesHint).required('Required!'),
    username: yup.string().matches(usernameRules, usernameRulesHint).required('Required!'),
    // streetAddress: yup
    //   .string()
    //   .matches(/^[0-9a-zA-Z\s]+$/, 'Street address must only contain letters, spaces and numbers'),
    city: yup.string().required('Required!').matches(cityRules, ErrorMessages.CityValidation),
    state: yup.string().nullable().required('Required!'),
    zipCode: yup.string().matches(zipCodeRules, zipCodeRulesHint).required('Required!'),
    gender: yup.string().required('Required'),
    // dateOfBirth: yup
    //   .date()
    //   .nullable()
    //   .notRequired()
    //   // .min(moment('1930-01-01').startOf('day'), 'User must be born on or after January 1, 1930')
    //   .min(new Date(new Date().getFullYear() - 100, 0, 1), `User must be born on or after January 1, ${new Date(new Date().getFullYear() - 100, 0, 1).getFullYear()}`)
    //   .max(moment().endOf('day').subtract(18, 'years'), 'You must be at least 18 years old!'),
    // yearsOfExperience: yup.string().required('Required!'),
    // tags: yup
    //   .string()
    //   .required('Required!')
    //   .matches(/^[a-zA-Z0-9\s]+$/, 'Tags must contain only letters, numbers and spaces'),
    email: yup.string().required('Required!').matches(emailRules, validEmailRule),
    phoneNumber: yup.string().matches(usPhoneRules, usPhoneRulesHint).required('Required!'),
    // status: yup.string(),
  };

  const [schema, setSchema] = useState<{ [key: string]: any }>(initialSchema);

  const initialValues: { [key: string]: string } = {};
  formInputs.forEach((input) => (initialValues[input.name] = ''));
  function getError(error: typeof invError) {
    const err = invError as { [key: string]: any };
    return err;
  }
  if (isSuccess || invIsSuccess) return <Navigate to='../' />;
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={yup.object().shape(schema)}
        onSubmit={onSubmit}
      >
        <Form>
          <div className='flex flex-col gap-2'>
            {invIsError && <p>{getError(invError)?.data?.message}</p>}
            {formInputs.map((input: any, i: number) => (
              <React.Fragment key={i}>
                {!formInputs[i - 1] || formInputs[i - 1]?.mainTitle !== input.mainTitle ? (
                  <>
                    <div className='flex flex-col'>
                      <div className='mb-2'>
                        <p className='text-gray-800 font-bold pt-6'>{input.mainTitle}</p>
                        <div className='flex items-center gap-1.5 hover:bg-slate-50 cursor-pointer'>
                          <Checkbox
                            checked={inviteOnly}
                            onCheckedChange={(ch) => handleCheck(ch)}
                            id={'invite'}
                          />
                          <label htmlFor={'invite'} className='flex-1 cursor-pointer'>
                            Invite by email
                          </label>
                        </div>

                        <hr />
                      </div>
                      <div className='flex flex-wrap gap-1'>
                        {formInputs
                          .filter((inp1) => inp1.mainTitle === input.mainTitle)
                          .filter((inp) => (inviteOnly ? inp.inviteOnly : inp))
                          .map((inp: any, i: number) => (
                            <React.Fragment key={i}>
                              <div className={inp.isTrue ? 'w-full ' : 'flex-1 min-w-[300px]'}>
                                <FormInput {...inp} />
                              </div>
                            </React.Fragment>
                          ))}
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </React.Fragment>
            ))}
          </div>
          {isError && (
            <div className='flex align-center text-red-700'>
              {(error as CustomError).data.message}
            </div>
          )}
          {/* footer*/}
          {/* <div className='flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b'>

            <Link
              className='w-1/5 my-12 text-center py-1 bg-white text-black shadow-lg font-semibold rounded-lg'
              to='../'
            >
              Cancel
            </Link>

            <SubmitButton isLoading={isLoading} />
          </div> */}

          <ModalFooter isLoading={invIsLoading || isLoading} />
        </Form>
      </Formik>
    </>
  );
};

export default NewCoachModal;
