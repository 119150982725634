import React, { ReactNode } from 'react';
import { BsCalendar3, BsTrophy } from 'react-icons/bs';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { SiValorant } from 'react-icons/si';
import { useSelector } from 'react-redux';
import { selectMatchData } from '../../../../../slices/matchSlice';

export function GaMdMatchInfo({}) {
  const matchData = useSelector(selectMatchData);
  return (
    <div className='w-full p-8 gap-8 rounded-xl main-gr-bg-color flex flex-col'>
      <Row>
        <Item icon={<BsCalendar3 color='white' />} text={new Date(matchData?.match_date).toDateString()} />
        <Item icon={<SiValorant color='white' />} text={matchData?.tournament_round_id !== null ? matchData?.TournamentRound?.Tournament?.Game?.name : matchData?.SeasonWeek?.Season?.Game?.name} uppercase text-white />
      </Row>
      <Row>
        <Item
          icon={<AiOutlineCheckCircle color='white' size={20} />}
          text={new Date(matchData?.match_date).toDateString()}
        />
        <Item icon={<BsTrophy color='white' />} text={matchData?.tournament_round_id !== null ? matchData?.TournamentRound?.Tournament?.name : matchData?.SeasonWeek?.Season?.name} uppercase />
      </Row>
    </div>
  );
}
const Row = ({ children }: { children: ReactNode }) => {
  return <div className='w-full flex justify-between items-center'>{children}</div>;
};

const Item = ({
  icon,
  text,
  uppercase,
}: {
  icon: ReactNode;
  text: string;
  uppercase?: Boolean;
}) => {
  return (
    <div className='flex w-[50%] items-center gap-2 text-white'>
      {icon}
      <span className={`text-white text-xs ${uppercase ? 'uppercase' : ''}`}>{text}</span>
    </div>
  );
};
