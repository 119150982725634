import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { DisputeStatus, GameScreenshotType } from '../utils/constants';

export type SocketState = 'loading' | 'connected' | 'disconnected';
interface MyState {
  matchData: any;
  teamUserList: Array<any>;
  reschedules: Array<any>;
  chatMessages: Array<any>;
  teamChatMessages: Array<any>;
  classroomChatMessages: Array<any>;
  otherClassrooms: Array<any>;
  otherClassroomsBySameGame: Array<any>;
  calendar: { [key: string]: any };
  socket: SocketState;
  seasonWeekSocket: SocketState;
  forfeit: any;
}
const initialState: MyState = {
  matchData: {},
  teamUserList: [],
  reschedules: [],
  chatMessages: [],
  teamChatMessages: [],
  classroomChatMessages: [],
  otherClassrooms: [],
  otherClassroomsBySameGame: [],
  calendar: {},
  socket: 'disconnected',
  seasonWeekSocket: 'disconnected',
  forfeit: {},
};
const matchSlice = createSlice({
  name: 'match',
  initialState,
  reducers: {
    updateSocket: (state, { payload }: { payload: SocketState }) => {
      state.socket = payload;
    },
    updateSeasonWeekSocket: (state, { payload }: { payload: SocketState }) => {
      state.seasonWeekSocket = payload;
    },
    updateMatchGame: (state, action) => {
      state.matchData.MatchGame.splice(
        state.matchData.MatchGame.findIndex((m: any) => m.id === action.payload.id),
        1,
        action.payload,
      );
    },
    updateDisputes: (state, action) => {
      state.matchData.disputes.push(action.payload);
    },
    setMatchGames: (state, action) => {
      state.matchData.MatchGame.push(action.payload);
    },
    setMatchData: (state, action) => {
      state.matchData = action.payload;
    },
    updateMatchData: (state, action) => {
      Object.assign(state.matchData, action.payload);
    },
    setReschedules: (state, action) => {
      state.reschedules = action.payload;
    },
    initTeamChatMessages: (state, action) => {
      console.log('inside', action.payload);
      state.teamChatMessages = action.payload;
    },
    addTeamChatMessage: (state, action) => {
      state.teamChatMessages.unshift(action.payload);
    },
    pushTeamChatMessage: (state, action) => {
      state.teamChatMessages = [...state.teamChatMessages, ...action.payload];
    },
    removeTeamChatMessageById: (state, action) => {
      state.teamChatMessages = state.teamChatMessages.map((msg) => {
        if (msg.id === action.payload.id) {
          return {
            ...msg,
            isDeleted: action.payload.isDeleted,
          };
        }
        return msg;
      });
    },
    initChatMessages: (state, action) => {
      state.chatMessages = action.payload;
    },
    addChatMessage: (state, action) => {
      state.chatMessages.unshift(action.payload);
    },
    pushChatMessage: (state, action) => {
      state.chatMessages = [...state.chatMessages, ...action.payload];
    },
    removeChatMessageMatchAllById: (state, action) => {
      // state.chatMessages = state.chatMessages.filter(msg => msg.id !== action.payload.id);
      state.chatMessages = state.chatMessages.map((msg) => {
        if (msg.id === action.payload.id) {
          return {
            ...msg,
            isDeleted: action.payload.isDeleted,
          };
        }
        return msg;
      });
    },
    checkIn: (state, action) => {
      if (action.payload?.length) {
        const ids = action.payload.map((id: number) => id);
        state.matchData.checked_in_students.push(...ids);
      } else {
        state.matchData.checked_in_students.push(action.payload);
      }
    },
    checkOut: (state, action) => {
      if (action.payload?.length) {
        state.matchData.checked_in_students = state.matchData.checked_in_students.filter(
          (s: number | string) => !action.payload.includes(s),
        );
      } else {
        state.matchData.checked_in_students = state.matchData.checked_in_students.filter(
          (s: number | string) => s != (action.payload as number | string),
        );
      }
    },
    addClassroomChatMessage: (state, action) => {
      state.classroomChatMessages.unshift(action.payload);
    },
    initClassroomChatMessages: (state, action) => {
      state.classroomChatMessages = action.payload;
    },
    pushClassroomChatMessage: (state, action) => {
      state.classroomChatMessages = [...state.classroomChatMessages, ...action.payload];
    },
    resetClassroomChatMessage: (state, action) => {
      state.classroomChatMessages = [];
    },
    removeClassroomChatMessage: (state, action) => {
      // state.classroomChatMessages = state.classroomChatMessages.filter(msg => msg.id !== action.payload.id);
      state.classroomChatMessages = state.classroomChatMessages.map((msg) => {
        if (msg.id === action.payload.id) {
          return {
            ...msg,
            isDeleted: action.payload.isDeleted,
          };
        }
        return msg;
      });
    },
    initOtherClassrooms: (state, action) => {
      state.otherClassrooms = action.payload;
    },
    pushOtherClassrooms: (state, action) => {
      state.otherClassrooms = [...state.otherClassrooms, ...action.payload];
    },
    initOtherClassroomsBySameGame: (state, action) => {
      state.otherClassroomsBySameGame = action.payload;
    },
    pushOtherClassroomsBySameGame: (state, action) => {
      state.otherClassroomsBySameGame = [...state.otherClassroomsBySameGame, ...action.payload];
    },
    initCalendar: (state, action) => {
      state.calendar[action.payload.date] = action.payload.data;
    },
    pushToCalendar: (state, action) => {
      state.calendar[action.payload.date] = [
        ...state.calendar[action.payload.date],
        ...action.payload.data,
      ];
    },
    pushForfeitHistory: (state, action) => {
      console.log('yess pushing', state, '..action', action);
      state.forfeit = action.payload;
    },
  },
});

export const {
  initCalendar,
  pushToCalendar,
  updateDisputes,
  updateMatchGame,
  checkIn,
  checkOut,
  setMatchData,
  setReschedules,
  initChatMessages,
  addChatMessage,
  pushChatMessage,
  initTeamChatMessages,
  addTeamChatMessage,
  pushTeamChatMessage,
  setMatchGames,
  addClassroomChatMessage,
  initClassroomChatMessages,
  pushClassroomChatMessage,
  initOtherClassrooms,
  pushOtherClassrooms,
  initOtherClassroomsBySameGame,
  pushOtherClassroomsBySameGame,
  updateSocket,
  updateSeasonWeekSocket,
  resetClassroomChatMessage,
  removeClassroomChatMessage,
  removeChatMessageMatchAllById,
  removeTeamChatMessageById,
  updateMatchData,
  pushForfeitHistory,
} = matchSlice.actions;
const selectSelf = (state: RootState) => state.match;
export const selectMatchData = createSelector(selectSelf, (state) => state.matchData);
export const selectReschedules = createSelector(selectSelf, (state) => state.reschedules);
export const selectDisputes = createSelector(selectSelf, (state) =>
  state.matchData?.disputes?.filter((dispute: any) => dispute.type === GameScreenshotType.Dispute),
);
export const selectMatchChatDisputes = createSelector(selectSelf, (state) =>
  state.matchData?.disputes?.filter(
    (dispute: any) =>
      dispute.type === GameScreenshotType.ChatDispute &&
      dispute.status === DisputeStatus.NotChecked,
  ),
);
export const selectChatMessages = createSelector(selectSelf, (state) =>
  [...state.chatMessages].reverse(),
);
export const selectTeamChatMessages = createSelector(selectSelf, (state) => state.teamChatMessages);
export const selectClassroomChatMessages = createSelector(
  selectSelf,
  (state) => state.classroomChatMessages,
);
export const selectOtherClassrooms = createSelector(selectSelf, (state) => state.otherClassrooms);
export const selectOtherClassroomsBySameGame = createSelector(
  selectSelf,
  (state) => state.otherClassroomsBySameGame,
);
export const selectCalendar = createSelector(selectSelf, (state) => state.calendar);
export const selectSocketState = createSelector(selectSelf, (state) => state.socket);
export const selectForfeitHistoryData = createSelector(selectSelf, (state) => state.forfeit);
export default matchSlice.reducer;
