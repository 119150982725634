import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import ModalUnarchive from '../../../components/unarchive-modal';
import { useUnArchiveMutation } from '../../../../../slices/unarchiveApiSlice';

const UnarchiveSeasonModal = () => {
  const param = useParams();
  const [unarchive, { isLoading, isError, isSuccess }] = useUnArchiveMutation();
  const onSubmit = () => {
    unarchive({
      id: Number(param.id),
      body: { name: 'season' },
    });
  };
  if (isSuccess) return <Navigate to='../' />;
  return <ModalUnarchive onSubmit={onSubmit} isLoading={isLoading} name={param.name ?? ''} />;
};

export default UnarchiveSeasonModal;
