import { dateToLocal } from '../utils/dateFormatter';

export class SeasonModel {
  id;
  name;
  image;
  status;
  game;
  gameImage;
  startDate;
  f_startDate;
  endDate;
  f_endDate;
  teams;
  division;
  subdivisions;
  createdAt;
  updatedAt;
  state;
  subdivision?: any;

  constructor(data: any) {
    if (data?.hasOwnProperty('id')) this.id = data?.id;
    if (data?.hasOwnProperty('image')) this.image = data?.image ?? '';
    if (data?.hasOwnProperty('name')) this.name = data?.name ?? '';
    if (data?.hasOwnProperty('Game')) {
      this.game = data?.Game?.name ?? '';
      this.gameImage = data?.Game?.image ?? '';
    }
    if (data?.hasOwnProperty('start_date')) {
      this.startDate = data?.start_date ?? '';
      this.f_startDate = dateToLocal(this.startDate, 'MMMM D, YYYY');
    }
    if (data?.hasOwnProperty('end_date')) {
      this.endDate = data?.end_date ?? '';
      this.f_endDate = dateToLocal(this.endDate, 'MMMM D, YYYY');
    }
    if (data?.hasOwnProperty('teams')) this.teams = data?.teams ?? '';
    if (data?.hasOwnProperty('CategoryDivision')) {
      this.division = data?.CategoryDivision?.name ?? '-';
      if (data?.hasOwnProperty('subdivisions')) {
        this.subdivisions = data?.subdivisions;
        this.subdivision = data?.subdivisions;
      }
    }
    if (data?.hasOwnProperty('subdivisions')) {
      this.subdivisions = data?.subdivisions;
      this.subdivision = data?.subdivisions;
    }
    if (data?.hasOwnProperty('created_at'))
      this.createdAt = dateToLocal(data?.created_at, 'MMMM D, YYYY');

    if (data?.hasOwnProperty('updated_at'))
      this.updatedAt = dateToLocal(data?.updated_at, 'MMMM D, YYYY');
    if (data?.hasOwnProperty('status')) this.status = data?.status;
    if (data?.hasOwnProperty('state')) this.state = data?.state;
  }
}
