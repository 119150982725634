import * as yup from 'yup';

export const NewLessonSchema = yup.object().shape({
  name: yup
    .string()
    .min(5, 'Name must be at least 5 characters')
    .max(50, 'Name must be below 50 characters')
    .matches(/^[A-Za-z0-9\s-]{0,50}$/, 'Name must contain only letters and numbers')
    .required('Required!'),
  description: yup
    .string()
    .min(20, 'Description should be at least 20 characters')
    .max(1000, 'Description should have maximum of 1000 characters.'),
});
