import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AvatarImage } from '../../../components/avatar-image';
import useIntersectionObserver from '../../../hooks/use-intersection-observer';
import { selectUser } from '../../../slices/auth';
import {
  useLazyInitTeamChatMessagesQuery,
} from '../../../slices/matchApiSlice';
import {
  initTeamChatMessages,
  selectMatchData,
  selectTeamChatMessages,
} from '../../../slices/matchSlice';
import DeleteTeamMessage from '../../../components/delete-team-msg/DeleteTeamMessage';
import { UserRoles } from '../../../utils/constants';
import useMatchStudentTeamId from '../../../hooks/use-match-student-team-id';
import { useAppDispatch } from '../../../store';
import { AiOutlineStop } from 'react-icons/ai';

export function TeamChatMessages({ id }: { id: number }) {
  const [fetch, setFetch] = useState(false);
  const elementRef = useRef(null);
  const teamChatMessages = useSelector(selectTeamChatMessages);
  const user = useSelector(selectUser);
  const param = useParams();
  const [page, setPage] = useState<number>();
  const matchData = useSelector(selectMatchData);
  // let x = 1;
  // {
  //   user?.team?.map((t: any, i: number) => {
  //     if (t.game_id === matchData.game_id) {
  //       x = i;
  //     }
  //   });
  // }
  const [teamId, teamIndex] = useMatchStudentTeamId();
  const [init, { data, isLoading, isSuccess }] = useLazyInitTeamChatMessagesQuery();
  // const { data, isLoading, isSuccess } = useInitTeamChatMessagesQuery({
  // id: param.id,
  // page,
  // team: id ?? teamId,
  // team: x !== 1 ? user.team[x].id : null,
  // });
  const dispatch = useAppDispatch();
  useEffect(() => {
    setPage(undefined);
    init({ id: param.id, page, team: id ?? teamId, init: true }, false)
      .unwrap()
      .then((res) => dispatch(initTeamChatMessages(res.data)));
  }, [id]);
  useEffect(() => {
    init({ id: param.id, page, team: id ?? teamId });
  }, [page]);

  const entry = useIntersectionObserver(elementRef, {});
  const isVisible = !!entry?.isIntersecting;

  const hasIOSupport = !!window.IntersectionObserver;
  useEffect(() => {
    if (isSuccess) console.log('isVisible', fetch);
    if (!isVisible || !fetch || isLoading || !data || !data.meta.next) return;
    setPage(data.meta.next);
  }, [isVisible, data?.meta, fetch]);
  useEffect(() => {
    let timeOut: NodeJS.Timeout;
    console.log('isLoading', isLoading, fetch);
    if (isLoading) setFetch(false);
    else setTimeout(() => setFetch(true), 2000);
    return () => {
      if (timeOut) clearTimeout(timeOut);
    };
  }, [isLoading]);

  const [chatMessageId, setChatMsgId] = useState<any>(null);

  const handleMessageClick = (e: any, msgId: any) => {
    e.stopPropagation();
    if (chatMessageId === msgId) {
      setChatMsgId(null);
    } else {
      setChatMsgId(msgId);
    }
  };
  return (
    <div
      className='flex-1 p-2 gap-2 flex flex-col overflow-auto'
      onClick={() => setChatMsgId(null)}
    >
      {/* {page} */}
      {teamChatMessages
        .filter((ch: any) => {
          if (ch.hasOwnProperty('team_id')) return ch.team_id === id;
          else if (ch.hasOwnProperty('room')) return ch.room === `m${param.id}-t${id}`;
        })
        .map((ch: any, i: number) => {
          const sameUser = ch.isMe || ch.user?.id === user.id || ch?.user_id === user.id;

          return (
            <React.Fragment key={i}>
              {user.roles === UserRoles.Ogma || user.roles === UserRoles.Admin ? (
                <div
                  className={`flex items-center gap-2 w-full max-w-full relative ${sameUser ? 'justify-end' : ''
                    }`}
                  key={i}
                >
                  <div className={`flex gap-2 ${sameUser ? 'order-2' : 'order1'}`}>
                    <AvatarImage
                      size='xl'
                      source={ch.image ? ch.image : ch.user?.image}
                      extraClass={sameUser ? 'order-2' : ''}
                    />
                    {chatMessageId === ch?.id && <DeleteTeamMessage msgId={ch?.id} room={id} />}
                    <div
                      className={`chat__body min-w-[7rem] ${sameUser
                        ? 'order-1 before:hidden cursor-pointer'
                        : 'after:hidden cursor-pointer'
                        } ${ch.isDeleted ? 'opacity-75' : ''} `}
                      onClick={(e) => handleMessageClick(e, ch?.id)}
                    >
                      <div className={`flex gap--2 ${sameUser ? 'justify-end' : ''}`}>
                        <p className={`text-black font-bold `}>
                          {ch.username ? ch.username : ch.user?.username}
                        </p>
                        {/* <span>{ch.team}</span> */}
                      </div>
                      <p className='text-black break-all'>{ch.message}</p>
                      <div className='flex justify-end text-xs'>
                        <p>{ch.time ? new Date(ch.time).toTimeString().split(' ')[0] : ch.clock}</p>
                      </div>
                      {ch.isDeleted ? (
                        <div
                          className={`absolute z-50 bottom-1 ${ch.isMe || ch.user?.id === user.id || ch?.user_id === user.id ? '' : ''
                            }`}
                        >
                          <div className='group'>
                            <AiOutlineStop className='text-red-500' />
                            <div
                              className={`hidden group-hover:block absolute top-[-50px] z-50 w-40 max-w-40 h-fit bg-black opacity-75 rounded p-1 ${ch.isMe || ch.user?.id === user.id || ch?.user_id === user.id
                                ? 'left-0'
                                : 'left-0'
                                }`}
                            >
                              <span className='overflow-ellipsis whitespace-normal text-white'>
                                This message has been deleted
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {/* {ch.isDeleted ? (
                    <div className={`${sameUser ? 'order-1' : 'order-2'}`}>
                      <FaExclamation className='text-red-500' />
                    </div>
                  ) : null} */}
                </div>
              ) : user.roles !== UserRoles.Ogma && ch.isDeleted !== 1 ? (
                <div
                  className={`flex gap-2 w-full max-w-full relative ${sameUser ? 'justify-end' : ''
                    }`}
                  key={i}
                >
                  <AvatarImage
                    size='xl'
                    source={ch.image ? ch.image : ch.user?.image}
                    extraClass={sameUser ? 'order-2' : ''}
                  />
                  {/* {chatMessageId === ch?.id && user.roles === UserRoles.Admin && <DeleteChatMessageMatchAll msgId={ch?.id} />} */}
                  <div
                    className={`chat__body ${sameUser
                      ? 'order-1 before:hidden cursor-pointer'
                      : 'after:hidden cursor-pointer'
                      } `}
                    onClick={(e) => handleMessageClick(e, ch?.id)}
                  >
                    <div className={`flex gap-2 ${sameUser ? 'justify-end' : ''}`}>
                      <p className={`text-black font-bold `}>
                        {ch.username ? ch.username : ch.user?.username}
                      </p>
                      {/* <span>{ch.team}</span> */}
                    </div>
                    <p className='text-black break-all'>{ch.message} </p>
                    <div className='flex justify-end text-xs'>
                      <p>{ch.time ? new Date(ch.time).toTimeString().split(' ')[0] : ch.clock}</p>
                    </div>
                  </div>
                </div>
              ) : null}
            </React.Fragment>
          );
        })}
      {/* {teamChatMessages.map((ch: any, i: number) => (
        <div
          className={`flex gap-2 w-full max-w-full relative ${ch.isMe || ch.user?.id === user.id || ch?.user_id === user.id ? 'justify-end' : ''
            }`}
          key={i}
        >
          <AvatarImage
            size='xl'
            source={ch.image ? ch.image : ch.user?.image}
            extraClass={
              ch.isMe || ch.user?.id === user.id || ch?.user_id === user.id ? 'order-2' : ''
            }
          />
          {chatMessageId === ch?.id && user.roles === UserRoles.Ogma && <DeleteTeamMessage msgId={ch?.id} room={x} />}
          <div
            className={`chat__body ${ch.isMe || ch.user?.id === user.id || ch?.user_id === user.id
              ? 'order-1 before:hidden cursor-pointer'
              : 'after:hidden cursor-pointer'
              } `}
            onClick={(e) => handleMessageClick(e, ch?.id, ch?.room)}
          >
            <div
              className={`flex gap-2 ${sameUser ? 'justify-end' : ''
                }`}
            >
              <p className={`text-black font-bold `}>
                {ch.username ? ch.username : ch.user?.username}
              </p>
              <span>{ch.team ?? ch.user?.team[x]?.teamName}</span>
            </div>
            {ch.isDeleted ? <>
              <p className='text-black break-all'>{ch.message}</p>
              <span className='text-red-500'>this message has been deleted</span>
            </> : <p className='text-black break-all'>{ch.message}</p>}
            <div className='flex justify-end'>
              <p>{ch.time ? new Date(ch.time).toTimeString().split(' ')[0] : ch.clock}</p>
            </div>
          </div>
        </div>
      ))} */}
      {isLoading ? (
        <div>Loading</div>
      ) : (
        <div ref={elementRef} className='flex flex-1 w-full min-h-[10px]'></div>
      )}
      {!hasIOSupport && data.meta.next && <span>Load more</span>}
    </div>
  );
}
