import { SponsorRow } from './../../../../components/sponsor-row/sponsor-row';
import { Bracket, IRoundProps, Seed, SeedItem, SeedTeam } from 'react-brackets';
import React, { useEffect, useState } from 'react';
import { HeaderButton } from '../../../../components/header-button';
import { Link, Outlet, useParams } from 'react-router-dom';
import {
  useGetAllMatchesByTournamentIdQuery,
  useGetWinnerOfTournamentQuery,
} from '../../../../slices/matchApiSlice';
import CanIView from '../../../../components/can-i-view/can-i-view';
import { useGetSingleTournamentInfoQuery } from '../../../../slices/tournamentApiSlice';
import { AvatarImage } from '../../../../components/avatar-image';
import { SponsorPlacements } from '../../../../utils/constants';
import { AiOutlineTrophy } from 'react-icons/ai';
import { dateToLocal } from '../../../../utils/dateFormatter';

const Component = () => {
  const params = useParams();
  const {
    data: matchesByTournamentId,
    error,
    isLoading,
  } = useGetAllMatchesByTournamentIdQuery(params.id);
  // console.log(matchesByTournamentId);
  const { data: winnerofTournament } = useGetWinnerOfTournamentQuery(params.id);
  const { data: singleTournamentInfo } = useGetSingleTournamentInfoQuery(params.id);

  const [test, setTest] = useState<any>([]);

  const CustomSeed = ({ seed, title, breakpoint, roundIndex, seedIndex }: any) => {
    // breakpoint passed to Bracket component
    // to check if mobile view is triggered or not
    // mobileBreakpoint is required to be passed down to a seed
    // console.log('test from inside seed', seed, title, seedIndex);
    if (seed.teams.length === 1) {
      return (
        <Seed className='group relative ' mobileBreakpoint={breakpoint} style={{ fontSize: 12 }}>
          <SeedItem className='!bg-gray-100 rounded-md !shadow-none'>
            <div>
              <SeedTeam
                style={{
                  display: winnerofTournament?.winnerofTournament ? '' : 'flex',
                  justifyContent: winnerofTournament?.winnerofTournament ? '' : 'center',
                  height: '40px',
                }}
                className='bg-gray-100 rounded-md border-2 border-yellow-500 !shadow-none text-black'
              >
                <div className='p-1 flex w-full justify-between gap-x-1 items-center'>
                  {winnerofTournament?.winnerOfTournament ? (
                    <>
                      <div className='text-gray-700 text-base font-bold'>
                        {winnerofTournament?.winnerOfTournament}
                      </div>
                      <AiOutlineTrophy
                        size={25}
                        className='rounded-full bg-yellow-500 p-1 text-white'
                      />
                    </>
                  ) : (
                    <div className='text-gray-700 text-base font-bold'>
                      <p className='text-gray-800 text-base'>TBD</p>
                    </div>
                  )}
                </div>
              </SeedTeam>
            </div>
          </SeedItem>
        </Seed>
      );
    } else {
      return (
        <Seed className='group relative ' mobileBreakpoint={breakpoint} style={{ fontSize: 12 }}>
          <CanIView component='threedots'>
            <Link to={`./edit-bracket/${seed.id}`}>
              <div className='absolute cursor-pointer bg-opacity-80 text-gray-800 z-[1] opacity-1 group-hover:opacity-1 ml-14 mt-1'>
                Edit
              </div>
            </Link>
          </CanIView>
          <SeedItem className='!bg-gray-100 rounded-md !shadow-none'>
            <div>
              <Link
                to={
                  seed?.teams[0]?.name?.length && seed?.teams[1]?.name?.length
                    ? `/match/${seed.id}`
                    : '#'
                }
              >
                <SeedTeam
                  style={{
                    // backgroundColor: 'red',
                    // borderRadius:"1px",
                    // borderColor:"gray",
                    // width:'200px'
                    height: '40px',
                  }}
                  className='bg-gray-100 rounded-md !shadow-none text-black'
                >
                  <div className='p-1 flex gap-x-1 items-center'>
                    {seed.teams[0]?.name !== '' ? (
                      <>
                        <AvatarImage source={seed.teams[0] ? seed.teams[0].image : ''} />
                        <div className='text-gray-700 text-base font-bold'>
                          {seed.teams[0]?.name.length >= 10
                            ? seed.teams[0]?.name.slice(0, 10) + '...'
                            : seed.teams[0]?.name}
                        </div>
                      </>
                    ) : seed.teams[0]?.dblForfeit ? (
                      <div className='text-gray-700 text-base font-bold'>
                        <p className='text-gray-800 text-base'>BYE</p>
                      </div>
                    ) : (
                      <div className='text-gray-700 text-base font-bold'>
                        <p className='text-gray-800 text-base'>TBD</p>
                      </div>
                    )}
                  </div>
                </SeedTeam>
                <SeedTeam
                  style={{
                    // backgroundColor: 'white',
                    // borderRadius:"1px",
                    // borderColor:"gray"
                    display: seed.teams[1].name === '' ? 'flex' : '',
                    height: '40px',
                  }}
                  className='bg-gray-100 !shadow-none'
                >
                  {' '}
                  <div className='p-1 flex gap-x-1 items-center'>
                    {seed.teams[1]?.name !== '' ? (
                      <>
                        <AvatarImage source={seed.teams[1] ? seed.teams[1].image : ''} />
                        <div className='text-gray-700 text-base font-bold'>
                          {seed.teams[1]?.name.length >= 10
                            ? seed.teams[1]?.name.slice(0, 10) + '...'
                            : seed.teams[1]?.name}
                        </div>
                      </>
                    ) : seed.teams[1]?.dblForfeit ? (
                      <div className='text-gray-700 text-base font-bold'>
                        <p className='text-gray-800 text-base'>BYE</p>
                      </div>
                    ) : (
                      <div className='text-gray-700 text-base font-bold'>
                        <p className='text-gray-800 text-base'>TBD</p>
                      </div>
                    )}
                  </div>
                </SeedTeam>
              </Link>
            </div>
          </SeedItem>
          <div>{seed.date}</div>
        </Seed>
      );
    }
  };
  const TeamSize4 = ['Semi Final', 'Final', 'Winner'];
  const TeamSize8 = ['Quarter Final', 'Semi Final', 'Final', 'Winner'];
  const TeamSize16 = ['Round 1', 'Quarter Final', 'Semi Final', 'Final', 'Winner'];
  const TeamSize32 = ['Round 1', 'Round 2', 'Quarter Final', 'Semi Final', 'Final', 'Winner'];
  const rounds: IRoundProps[] = [];

  if (matchesByTournamentId?.data?.length === 5) {
    for (let i = 0; i < TeamSize32.length; i++) {
      const round = {
        title: TeamSize32[i],
        seeds: [],
      };
      rounds.push(round);
    }
  } else if (matchesByTournamentId?.data?.length === 4) {
    for (let i = 0; i < TeamSize16.length; i++) {
      const round = {
        title: TeamSize16[i],
        seeds: [],
      };
      rounds.push(round);
    }
  } else if (matchesByTournamentId?.data?.length === 3) {
    for (let i = 0; i < TeamSize8.length; i++) {
      const round = {
        title: TeamSize8[i],
        seeds: [],
      };
      rounds.push(round);
    }
  } else {
    for (let i = 0; i < TeamSize4.length; i++) {
      const round = {
        title: TeamSize4[i],
        seeds: [],
      };
      rounds.push(round);
    }
  }

  let x = matchesByTournamentId?.data?.length;

  const arr4: object[] = [
    {
      round: x,
      match_date: '',
      id: null,
      home_team: [
        {
          teamName: (
            <p className='text-gray-800 items-center  text-base'>
              {winnerofTournament ? winnerofTournament.winnerOfTournament : 'Winner of Tournament'}
            </p>
          ),
        },
      ],
    },
  ];

  useEffect(() => {
    if (!matchesByTournamentId?.data[0]?.match?.length) return;
    else {
      matchesByTournamentId.data.forEach((t: any, i: number) =>
        t.match?.forEach((m: any) =>
          rounds[m.round + i].seeds.push({
            id: m.id,
            date: dateToLocal(m.match_date, 'llll zz'),
            teams: [
              {
                name: m.home_team[0] ? m.home_team[0].teamName : '',
                dblForfeit: m.ht_forfeit,
              },
              {
                name: m.away_team[0] ? m.away_team[0].teamName : '',
                dblForfeit: m.at_forfeit,
              },
            ],
          }),
        ),
      );

      arr4.forEach((s: any) =>
        rounds[s.round].seeds.push({
          id: s.id,
          date: dateToLocal(s.match_date, 'llll zz'),
          teams: [{ name: s.home_team[0] ? s.home_team[0].teamName : '' }],
        }),
      );
      setTest(rounds);
    }
  }, [matchesByTournamentId]);

  let z = 0;
  matchesByTournamentId?.data[1]?.match.forEach((f: any) => {
    if (f.home_team.length !== 0 || f.away_team.length !== 0) {
      z++;
    }
  });
  if (isLoading) return <p>Loading...</p>;

  return (
    <>
      <div className='flex flex-col gap-10 relative p-10 bg-white w-full rounded-xl '>
        <div>
          <SponsorRow name={SponsorPlacements.TournamentBracket} />
        </div>

        {(matchesByTournamentId?.data?.length == 0 ||
          matchesByTournamentId?.data[0]?.match.length == 0) &&
        singleTournamentInfo?.teams.length === singleTournamentInfo?.team_size ? (
          <CanIView component='tournament.bracket.generate'>
            <div className=' grid justify-items-end mb-12'>
              <Link to='./generate-bracket'>
                <HeaderButton type='generateBracket' />{' '}
              </Link>
            </div>
          </CanIView>
        ) : null}

        <Bracket
          rounds={test}
          renderSeedComponent={CustomSeed}
          roundTitleComponent={(title: React.ReactNode, roundIndex: number) => {
            return (
              <div className='border py-2 rounded-xl mx-2 select-none font-bold text-center text-gray-700'>
                {title}
              </div>
            );
          }}
        />
        <CanIView component='tournament.bracket.edit'>
          <div className='flex justify-end'>
            {matchesByTournamentId?.data?.length !== 0 &&
            matchesByTournamentId?.data[0]?.match.length !== 0 &&
            z === 0 ? (
              <Link to='./edit'>
                <div className='button min-w-[200px]'>Edit Brackets</div>
              </Link>
            ) : null}
          </div>
        </CanIView>
      </div>
      <Outlet />
    </>
  );
};
export default Component;
