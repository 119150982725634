import { useSelector } from 'react-redux';
import { selectMatchData } from '../slices/matchSlice';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { selectUser } from '../slices/auth';

const useMatchData = () => {
  const [showCheckIn, setShowCheckIn] = useState(false);
  const [showCheckOut, setShowCheckOut] = useState(false);
  const [captainsPresent, setCaptainPresents] = useState(false);
  const [results, setResults] = useState({ h: 0, a: 0 });
  const userData = useSelector(selectUser);
  const matchData = useSelector(selectMatchData);
  const matchDate = new Date(matchData?.match_date);
  const homeTeamCaptainId = matchData?.home_team?.map((captain: any) => captain.captain);
  const awayTeamCaptainId = matchData?.away_team?.map((captain: any) => captain.captain);

  useEffect(() => {
    const fifteenMinutes = 15 * 60 * 1000;
    const matchTime = moment(matchData?.match_date);
    const finalDiff = matchTime.diff(moment.now(), 'minutes');
    if (finalDiff) {
      if (finalDiff <= 15) {
        if (!matchData.checked_in_students?.find((v: number) => v === userData.id)) {
          setShowCheckIn(true);
          setShowCheckOut(false);
        } else {
          setShowCheckIn(false);
          setShowCheckOut(true);
        }
        return;
      }
    }
    const intervalId = setInterval(() => {
      const currentTime = new Date();
      const matchTime = moment(matchData?.match_date);
      const finalDiff = matchTime.diff(moment.now(), 'minutes');
      if (finalDiff <= 15) {
        if (!matchData.checked_in_students?.find((v: number) => v === userData.id)) {
          setShowCheckIn(true);
          setShowCheckOut(false);
        } else {
          setShowCheckIn(false);
          setShowCheckOut(true);
        }
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [matchData?.match_date, matchData?.checked_in_students]);

  useEffect(() => {
    if (matchData?.checked_in_students?.length > 1) {
      const arr1 = [...homeTeamCaptainId, ...awayTeamCaptainId];
      const isOr = arr1.every((item: any) => matchData?.checked_in_students?.includes(item));
      if (!isOr) return;
      setCaptainPresents(true);
    }
  }, [matchData?.checked_in_students]);
  const maxGames = +matchData?.game_per_match?.slice(-1);
  useEffect(() => {
    if (!matchData?.MatchGame) return;
    setResults({ h: 0, a: 0 });
    matchData?.MatchGame?.forEach((g: { ht_result: number; aw_result: number }) =>
      g.ht_result > g.aw_result
        ? setResults((s) => ({ h: s.h++, a: s.a }))
        : g.ht_result < g.aw_result
        ? setResults((s) => ({ h: s.h, a: s.a++ }))
        : setResults((s) => ({ h: s.h, a: s.a })),
    );
  }, [matchData?.MatchGame]);

  return [showCheckIn, showCheckOut, captainsPresent, results];
};

export default useMatchData;
