import * as yup from 'yup';
import {
  cityRules, nameRulesHint,
  nameRulesWithSpecialCharacter,
  passwordRules,
  passwordRulesHint,
  usPhoneRules,
  usPhoneRulesHint,
  usernameRules,
  usernameRulesHint,
  zipCodeRules,
  zipCodeRulesHint
} from '../../yupschema';
import { ErrorMessages } from '../../../../utils/messages.enum';
export const ogmaValidationSchema = yup.object().shape({
  username: yup.string().matches(usernameRules, usernameRulesHint).required('Required!'),
  firstName: yup.string().matches(nameRulesWithSpecialCharacter, nameRulesHint).required('Required!'),
  lastName: yup.string().matches(nameRulesWithSpecialCharacter, nameRulesHint).required('Required!'),
  // streetAddress: yup.string().required('Required!'),
  city: yup.string().required('Required!').matches(cityRules, ErrorMessages.CityValidation),
  state: yup.string().required('Required!'),
  zipCode: yup.string().matches(zipCodeRules, zipCodeRulesHint).required('Required!'),
  phoneNumber: yup.string().matches(usPhoneRules, usPhoneRulesHint).required('Required!'),
  office_number: yup.string().matches(usPhoneRules, usPhoneRulesHint),
  check: yup.bool().oneOf([true], 'Required!'),
  gender: yup.string().required('Required'),
  // dateOfBirth: yup
  //   .date()
  //   .max(moment().endOf('day').subtract(18, 'years'), 'User must be at least 18 years old!')
  //   .required('Required!'),
  password: yup
    .string()
    .min(5)
    .matches(passwordRules, { message: passwordRulesHint })
    .required('Required!'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Required!'),
});
