// useErrorToast.js
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { CustomError } from '../utils/custom-types';

const useErrorToast = (isError: boolean, error: FetchBaseQueryError | SerializedError | undefined) => {
    useEffect(() => {
        if (isError) {
            const errorMessage = (error as CustomError)?.data?.message;
            if (errorMessage) {
                toast.error(errorMessage, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }, [isError, error]);
};

export default useErrorToast;
