import React, { useState } from 'react';
import { TournamentOptionCard } from './tournament-option-card';
export function TournamentOptionContainer({ }) {
  const tournamentOptions = [
    {
      id: 1,
      option: 'All Tournaments',
      description: 'View all tournaments',
      to: './all',
    },
    {
      id: 2,
      option: 'Category Divisions',
      description: 'View all category divisions',
      to: './divisions',
    },
    {
      id: 3,
      option: 'Subdivisions',
      description: 'View all subdivisions',
      to: './subdivisions',
    },
  ];
  const [isActive, setIsActive] = useState(1);

  return (
    <div className='flex min-h-fit gap-5 items-center lg:items-start  p-5  flex-wrap flex-row bg-white'>
      {tournamentOptions.map((t) => (
        <TournamentOptionCard isActive={t.id === isActive} key={t.option} {...t} />
      ))}
    </div>
  );
}
