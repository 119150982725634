import React from 'react';
export function AvatarImage({
  source,
  alt,
  size,
  contain,
  extraClass,
}: {
  source: string;
  alt?: string;
  size?: string;
  contain?: boolean;
  extraClass?: string;
}) {
  return (
    <div className={`avatarPlaceholder flex-shrink-0 ${size} ${extraClass ?? ''}`}>
      {source ? (
        <img
          className={`rounded-full w-full h-full ${
            contain ? 'object-contain' : 'object-cover'
          } bg-white`}
          src={source}
          alt={alt}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
