import { Navigate, useParams } from 'react-router';
import { useGetLessonQuery } from '../../slices/classroomApiSlice';
import { ViewClassroomVideo } from '../view-classroom/components/view-classroom-video';
import { CustomError } from '../../utils/custom-types';

const ViewLesson = () => {
  const param = useParams();
  const { error, data, isLoading, isError } = useGetLessonQuery(param.lessonid ?? '');

  if (isError)
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError).data.message }} />;
  if (isLoading) return <p>Loading...</p>;

  if (param.publicId?.split('.').slice(-2)[0] === 'pdf') {
    return (
      <object
        width='100%'
        height='400'
        data={data?.content.find((c: any) => c.unique === param.publicId)?.res?.url}
        type='application/pdf;base64'
        className='w-full  max-w-[700px] aspect-video bg-black'
      >
        {' '}
      </object>
    );
  }

  if (param.publicId?.split('.').slice(-2)[0] === 'video') {
    return (
      <ViewClassroomVideo
        link={data.content.find((c: any) => c.unique === param.publicId)?.res?.url}
      />
    );
  }

  if (param.publicId?.split('.').slice(-2)[0] === 'image') {
    return (
      <div className=''>
        <img
          src={data?.content.find((c: any) => c.unique === param.publicId)?.res?.url}
          className='max-h-[400px] max-w-[700px] object-cover'
        />
      </div>
    );
  }

  return <>This format is not supported</>;
};

export default ViewLesson;
