import { useParams } from 'react-router';
import { useGetOgmaInvoicesQuery } from '../../slices/invoicesApiSlice';
import OgmaProfileInvoiceRow from './components/ogma-profile-invoice-row';
import { InvoiceModel } from '../ogma-school-subscriptions/models/invoice.model';
import { TableHead } from '../../components/table-head';
import TableContainer from '../../components/table-container/table-container';


const OgmaProfileInvoices = () => {
  const param = useParams();
  const { data, isLoading } = useGetOgmaInvoicesQuery({ id: Number(param.id), query: 'all' });
  const thData: string[] = ["ID", "School Name", "Season Name", "Status Name", "Type Name", "Total"]
  if (isLoading) return <p>Loading...</p>;
  return (
    <>
      <div className='shadow border-b border-gray-200 sm:rouned-lg'>
        <TableContainer>
          <TableHead
            tableList={thData}
          />
          <tbody className=''>
            {data.map((d: any, i: number) => (
              <OgmaProfileInvoiceRow key={i} model={new InvoiceModel(d)} />
            ))}
            {!data.length ? <p>There are no invoices</p> : <></>}
          </tbody>
        </TableContainer>

      </div>
    </>
  );
};

export default OgmaProfileInvoices;
