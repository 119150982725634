import { BsChevronRight } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { NameAndAvatar } from '../../components/name-and-avatar';
import { Button } from '../../components/ui/button/Button';
import { Separator } from '../../components/ui/separator/Separator';
import { useGetNewestStudentQuery } from '../../slices/userApiSlice';
import { LatestStudensAndSchoolsLoadingSceleton } from './LatestStudentsandSchoolsLoadingSceleton';
import { userRoleNames } from '../../utils/constants';

const LatestStudents = () => {
  const { data: studentData, isLoading } = useGetNewestStudentQuery('');
  const navigate = useNavigate();

  const handleShowMore = () => {
    navigate('/users?roles=2');
  };
  // return <LatestStudensAndSchoolsLoadingSceleton />
  if (isLoading) return <LatestStudensAndSchoolsLoadingSceleton />;

  const handleStudentView = (recentStudents: { id: string; roles: number }) => {
    const userRole = recentStudents.roles;
    if (userRoleNames[userRole]) {
      const role = userRoleNames[userRole];
      navigate(`/users/view/${recentStudents.id}/${role}`);
    }
  };

  return (
    <>
      <div className='flex flex-col gap-10 border-1 rounded-xl'>
        <h1 className='flex items-center text-2xl ml-6 mt-6 font-bold'>
          Recently Registered Students
        </h1>
        <Separator />
        <div className='grid grid-cols-3  mx-5'>
          <section className='col-span-3 bg-white rounded-lg mt-3 '>
            <div className='flex  flex-col'>
              <div className='-my-2 overflow-x-auto '>
                <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
                  <div className=''>
                    <div className='flex flex-wrap items-center gap-2'>
                      {studentData &&
                        studentData.map((s: any) => (
                          <div
                            key={s.id}
                            onClick={() => handleStudentView(s)}
                            className='cursor-pointer px-6 py-4 bg-none flex w-[270px]
                             text-xl font-bold border border-slate-300 hover:bg-slate-100 rounded-md relative overflow-hidden'
                          >
                            <NameAndAvatar
                              name={s.firstName}
                              image={s.image}
                              // lastName={s.lastName}
                              isAlt={false}
                              // schoolName={s.school && s.school.name ? s.school.name : '-'}
                              extraClass='w-full'
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='mt-10 mb-5  max-h-[1000px] px-10'>
              <div className='mt-1 grid grid-cols-3 items-center text-gray-400'>
                <hr className='border-gray-200' />
                {/* <button className='text-center text-sm'>Show all Transactions</button> */}
                <div style={{ gridTemplateRows: '1fr 0.5fr 1fr' }}>
                  <div className='flex items-center justify-center'>
                    <Button size='sm' variant='ghost' onClick={() => handleShowMore()}>
                      <p className='text-rose-700 font-semibold'>Go to</p>{' '}
                      <BsChevronRight className='ml-4 text-rose-700 stroke-1' />
                    </Button>
                  </div>
                </div>
                <hr className='border-gray-300' />
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default LatestStudents;
