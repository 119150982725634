import React, { useRef } from 'react';
import { FiTarget } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { OutsideClick } from '../../../../components/outside-click';

export function DropdownListView({
  configureListView,
  setFunc,
  state,
}: {
  configureListView: any[];
  setFunc: Function;
  state: any[];
}) {
  const checkboxes = useRef<any[]>([]);

  function handleClear() {
    console.log('test from clear ');

    setFunc((s: any) => {
      console.log(s);
      s.forEach((l: any) => (l.active = true));
      // console.log("set from dropdown", { s }, i, s[i]);
      return [...s];
    });
    // Loop through the checkboxes and uncheck each one
    //  checkboxes.current.forEach((checkbox) => {
    //     checkbox.checked = false;
    //   });
    // setFunc((s: any) => s.forEach((st: any) => (st.active = true)));
  }

  return (
    <div
      id='dropdown'
      className='overflow-auto max-h-[350px] absolute z-10 right-0 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
      role='menu'
      aria-orientation='vertical'
      aria-labelledby='menu-button'
    >
      <div className='flex text-gray-700 py-4 pr-4 text-center justify-center'>
        <button
          onClick={handleClear}
          className='text-center stop-w-1/3 bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded-xl shadow ml-4 w-full select-none cursor-pointer'
          id='menu-button'
          aria-expanded='true'
          aria-haspopup='true'
        >
          Reset
        </button>
        {/* <button
          className="text-center w-2/3 bg-red-700 hover:bg-red-800 text-white font-semibold py-2 px-4 border-gray-400 rounded-xl shadow ml-4"
          id="menu-button"
          aria-expanded="true"
          aria-haspopup="true"
        >
          Apply
        </button>
      */}
      </div>
      <hr />
      <div className='flex text-left text-gray-700 block px-4 py-2 text-sm'>
        <div className='flex self-center text-center'>
          <ul
            className='w-80 p-3 space-y-3 text-sm text-gray-700 dark:text-gray-200'
            aria-labelledby='dropdownCheckboxButton'
          >
            {configureListView.map((l, i) => (
              <React.Fragment key={i}>
                <li
                  className='cursor-pointer'
                  onClick={() => {
                    // let temp = state;
                    // temp[i].active = !temp[i].active;
                    setFunc((s: any) => {
                      s[i].active = !s[i].active;
                      console.log('set from dropdown', { s }, i, s[i]);
                      return [...s];
                    });
                    // console.log(temp, state);
                    // setFunc(temp);
                  }}
                >
                  <div className='flex items-center cursor-pointer select-none'>
                    <form name='myform'>
                      <input
                        type='checkbox'
                        // value={l.active}
                        // checked={l.active}
                        checked={state[i].active}
                        readOnly
                        // ref={(checkbox) => checkboxes.current.push(checkbox)}

                        // onClick={() => (set)}

                        className='w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500 cursor-pointer select-none'
                      />
                    </form>
                    <label
                      htmlFor='checkbox-item-1'
                      className='ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 cursor-pointer select-none'
                    >
                      {l.name.split('_').join(' ')}
                    </label>
                  </div>
                </li>
                {i !== configureListView.length - 1 && <hr />}
              </React.Fragment>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
