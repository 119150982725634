import { Formik } from 'formik';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { OutsideClick } from '../../../../components/outside-click';
import ModalContent from '../../../../components/modal-content';
import { useConnectInGameNameMutation, useGetAllGamesQuery } from '../../../../slices/gameApiSlice';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../slices/auth';
import { AddGameAccountSchema } from './schema/add-game-account.schema';
import CantConnect from './CantConnect';
import { UserRoles } from '../../../../utils/constants';

const AddGameAccount = ({ gameId }: { gameId?: number }) => {
  const param = useParams();
  const user = useSelector(selectUser);
  const [connectInGameName, { isLoading, isSuccess, isError, error }] =
    useConnectInGameNameMutation();
  const { data: gamesData, isLoading: gamesLoading, error: gamesError } = useGetAllGamesQuery('');

  const formInputs = [
    {
      mainTitle: 'Game Information',
      name: 'in_game_name',
      label: user.roles == UserRoles.Student ? `In-Game Name (Required)` : 'In-Game Name',
      type: 'text',
      placeholder:
        'Enter your username for this specific game title. This is how other players will find you.',
      isTrue: true,
    },
    {
      mainTitle: 'Game Information',
      name: 'discord_name',
      label: `Discord Name (Optional)`,
      type: 'text',
      placeholder: 'Enter your discord name',
      isTrue: true,
    },
  ];

  const onSubmit = (values: any, actions: any) => {
    const body = { ...values };
    body.game_id = gameId ? gameId : Number(param.id);
    body.user_id = Number(user.id);

    connectInGameName(body)
      .unwrap()
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  const initialValues: { [key: string]: string } = {};
  formInputs.forEach((input) => (initialValues[input.name] = ''));

  if (gamesLoading) return <p>Loading...</p>;
  if (isSuccess && !gameId) return <Navigate to='../' />;
  if (user.roles == UserRoles.Student) {
    if (!user?.team?.some((t: any) => t.game_id === Number(param.id) || t.game_id === gameId))
      return (
        <CantConnect
          gameName={gamesData?.find((g: any) => g.id === Number(param.id) || g.id === gameId)?.name}
        />
      );
  }

  return (
    <>
      <OutsideClick />
      <Formik
        validationSchema={user.roles == UserRoles.Student ? AddGameAccountSchema : ''}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <ModalContent
              formInputs={formInputs}
              isLoading={isLoading}
              name={`Connect With Game - ${
                gamesData?.find((g: any) => g.id === Number(param.id) || g.id === gameId)?.name
              }`}
              extraClass='h-[45vh]'
            />
          );
        }}
      </Formik>
    </>
  );
};

export default AddGameAccount;
