import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Navigate, useParams } from 'react-router-dom';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { SortableItem } from './SortableItem';
import { useGetAllTeamsByTournamentIdQuery } from '../../../../slices/teamsApiSlice';
import dragndrop from '../../../../assets/dragndrop.svg';
import { AvatarImage } from '../../../../components/avatar-image';
import { useManualBracketGeneratorMutation } from '../../../../slices/matchApiSlice';
import {
  useGetFirstTournamentRoundByTournamentIdQuery,
  useGetSingleTournamentInfoQuery,
} from '../../../../slices/tournamentApiSlice';
import { FormInput } from '../../../../components/form-input/form-input';
import { Form, Formik } from 'formik';
import ModalFooter from '../../../../components/modal-footer';
import ModalHeader from '../../../../components/modal-header';
import { SchemaMessages } from '../../../../utils/constants';
import { checkIfDateIsFuture, checkStartDate } from '../../../../utils/utils';

const ManualBracket = () => {
  const params = useParams();
  const { data: tournamentInfo } = useGetSingleTournamentInfoQuery(params.id);
  const { data: tournament_round_id } = useGetFirstTournamentRoundByTournamentIdQuery(params.id);
  const {
    data: teamsByTournament,
    error,
    isLoading,
  } = useGetAllTeamsByTournamentIdQuery(params.id);
  useEffect(() => {
    // test();
    console.log(teamsByTournament);
    if (teamsByTournament) {
      teamsByTournament.forEach((t: any) =>
        setTeams((s: any) => (s ? [...s, t.teamName] : [t.teamName])),
      );
    }
  }, [teamsByTournament]);
  const [manualBracketGenerator, { isError, isLoading: generatorIsLoading, data, isSuccess }] =
    useManualBracketGeneratorMutation();

  function handleSubmit(values: any) {
    const arrToSend: any = [];
    teams.forEach((t) => {
      const selectedTeam = teamsByTournament.find((d: any) => d.teamName === t);

      arrToSend.push({ id: selectedTeam.id, name: selectedTeam.teamName });
    });
    console.log('test arrToSend', arrToSend);
    manualBracketGenerator({
      game_id: tournamentInfo.game_id,
      games_per_match: tournamentInfo.match_format.name,
      tournament_round_id: tournament_round_id.id,
      tournament_id: params.id,
      teams: arrToSend,
      match_date: new Date(values.match_date).toISOString(),
      team_size: tournamentInfo.team_size,
    });
  }

  const [teams, setTeams] = useState<any[]>([]);
  function handleDragEnd(event: any) {
    console.log('Drag end called');
    const { active, over } = event;
    console.log('ACTIVE: ' + active.id);
    console.log('OVER :' + over.id);

    if (active.id !== over.id) {
      setTeams((items: any) => {
        console.log({ items }, 'active id', active.id, 'over id', over.id);
        const activeIndex = items.indexOf(active.id);
        // const activeIndex = items.findIndex((t:any)=>t.teamName === active.id)
        const overIndex = items.indexOf(over.id);
        // const overIndex = items.findIndex((t:any) => t.teamName === over.id)
        console.log('array move', arrayMove(items, activeIndex, overIndex));
        return [...arrayMove(items, activeIndex, overIndex)];

        // items: [2, 3, 1]   0  -> 2
        // [1, 2, 3] oldIndex: 0 newIndex: 2  -> [2, 3, 1]
      });
    }
  }

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const GenerateBracketVSchema = yup.object().shape({
    match_date: yup
      .mixed()
      .transform((v, ov) => ov)
      .test('test', SchemaMessages.TournametStartDate, (v, ctx) => {
        if (!v) return false;
        const firstDate = tournamentInfo.start_date.split('T')[0];
        const isAfter = checkIfDateIsFuture(firstDate, v);
        return isAfter;
      })
      .test('test', SchemaMessages.DateShouldNotBeInThePast, checkStartDate)
      // .min(tournamentInfo.start_date, 'Selected date must be after tournament start date')
      // .min(today, 'Date and time should not be in the past')
      // .transform((value, originalValue) => {
      //   if (originalValue) {
      //     return new Date(originalValue);
      //   }
      //   return value;
      // })
      .required('Required!'),
  });
  if (isSuccess) return <Navigate to='../' />;
  return (
    <Formik validationSchema={GenerateBracketVSchema} initialValues={{}} onSubmit={handleSubmit}>
      {({ isSubmitting, errors }) => (
        <Form>
          <div className='fixed h-fit top-0 left-0 bottom-0 right-0 m-auto z-10 w-auto flex items-center justify-center  max-w-2xl'>
            <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-[90vh]'>
              <ModalHeader name='Generate Bracket' />
              <div className='m-4 bg-gray-500 mb-5 p-2 text-white rounded-lg'>
                <h4 className='text-xl text-white'>Manual Bracket</h4>
                <hr />
                <p className='text-white text-sm p-2'>
                  Teams are seeded into the first round of the bracket in the exact order as
                  specified.
                </p>
              </div>

              <div className='relative p-3 flex-auto overflow-auto overflow-x-clip'>
                <FormInput
                  type='datetime-local'
                  name='match_date'
                  label='Match Date'
                  isTrue={true}
                />
                <div>
                  <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                    <SortableContext items={teams} strategy={verticalListSortingStrategy}>
                      {/* We need components that use the useSortable hook */}
                      <table className='min-w-full divide-y divide-gray-200'>
                        <thead className='bg-gray-50'>
                          <tr>
                            <th
                              scope='col'
                              className='px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase '
                            >
                              <div className='flex'>
                                <div className='mr-10'>Rank</div>
                                <div className=''>Team</div>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        {teams &&
                          teams.map((teams: any, key: number) => (
                            <tbody key={key} className={'divide-y divide-gray-200 '}>
                              <tr
                                className={`items-center border-l-2 border-r-2 !border-gray-400 ${
                                  key % 2 !== 0 ? 'border-b-2 ' : ' '
                                } ${key === 0 ? 'border-t-2' : null} `}
                              >
                                <td className='px-6 py-4 text-sm text-gray-800 whitespace-nowrap'>
                                  <div className='flex'>
                                    <div className='mr-12'>{key + 1}</div>
                                    <div className='ml-4 flex w-1 '>
                                      <SortableItem key={teams} id={teams}>
                                        <div className='flex w-screen '>
                                          <img src={dragndrop} className='mr-2' />
                                          <AvatarImage
                                            source={
                                              teamsByTournament.find(
                                                (t: any) => t.teamName === teams,
                                              ).image
                                            }
                                          />
                                          {teamsByTournament.find((t: any) => t.teamName === teams)
                                            .isBenched ? (
                                            <div className='ml-4 flex items-center'>
                                              <p>{teams}</p>
                                              <span className='ml-2 text-[#B50303] font-[600] font-[Inter] text-[13px]'>
                                                Benched
                                              </span>
                                            </div>
                                          ) : (
                                            <div className='ml-4'>{teams}</div>
                                          )}
                                        </div>
                                      </SortableItem>
                                    </div>
                                  </div>
                                </td>
                                {/* <SortableItem key={teams} id={teams}/> */}
                              </tr>
                            </tbody>
                          ))}
                      </table>
                    </SortableContext>
                  </DndContext>
                </div>
              </div>
              <ModalFooter isLoading={generatorIsLoading!} />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ManualBracket;
