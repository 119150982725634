import * as yup from 'yup';
import {
  zipCodeRules,
  zipCodeRulesHint,
  emailRules,
  emailRulesHint,
  usPhoneRules,
  usPhoneRulesHint,
} from '../../../authentication/yupschema';
import { ErrorMessages } from '../../../../utils/messages.enum';
export const NewOrganizationSchema = yup.object().shape({
  name: yup
    .string()
    .matches(
      /^[a-zA-ZÀ-ÖØ-öø-ÿÇç0-9\s-,.'"]{0,50}$/,
      'Only letters, numbers, spaces, and hyphens are allowed (maximum 50 characters)',
    )
    .required('Required!'),
  street_address: yup.string().required('Required!'),
  city: yup
    .string()
    .required('Required!')
    .matches(/^[a-zA-ZÀ-ÖØ-öø-ÿÇç\s-,.'"]+$/, ErrorMessages.CityValidation),
  state: yup
    .string()
    .required('Required!')
    .matches(
      /^[a-zA-ZÀ-ÖØ-öø-ÿÇç0-9\s-,.'"]{0,50}$/,
      'Only letters, numbers, spaces, and hyphens are allowed (maximum 50 characters)',
    ),
  zip_code: yup.string().matches(zipCodeRules, zipCodeRulesHint).required('Required!'),
  email: yup.string().matches(emailRules, emailRulesHint).required('Required!'),
  phone_number: yup.string().matches(usPhoneRules, usPhoneRulesHint).required('Required!'),
  // schools: yup.string().nullable(),
  // tags: yup.string().required('Required!'),
  status: yup.string().required('Required!'),
});
