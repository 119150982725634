import { FieldInputProps, FieldMetaProps, useFormikContext } from 'formik';
import React, { FunctionComponent, HTMLProps, useEffect, useMemo, useRef, useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { BsCheck } from 'react-icons/bs';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
interface ComboboxProps extends HTMLProps<HTMLDivElement> {
  options: { id: number | string; username?: string; name?: string }[];
  name: string;
  label: string;
  meta?: FieldMetaProps<any>;
  field?: FieldInputProps<any>;
  setFieldValue?: React.Dispatch<React.SetStateAction<any>>;
  containsAsteriks?: boolean;
  props?: any;
  noFormik?: boolean;
}

const Combobox: FunctionComponent<ComboboxProps> = ({
  options,
  label,
  name,
  meta,
  field,
  className,
  containsAsteriks,
  setFieldValue,
  noFormik,
  props,
  ...other
}) => {
  const [selected, setSelected] = useState<string>();
  let formikObj = noFormik ? undefined : useFormikContext();
  const [searchValue, setSearchValue] = useState('');
  const targetElementRef = useRef<HTMLDivElement>(null);
  const fixedElementRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState({ top: 0, left: 0, width: 0 });

  useEffect(() => {
    const updatePosition = () => {
      if (targetElementRef.current) {
        const targetRect = targetElementRef.current.getBoundingClientRect();
        const newPosition = {
          top: targetRect.bottom + window.scrollY,
          left: targetRect.left + window.scrollX,
          width: targetRect.width,
        };
        setPosition(newPosition);
      }
    };

    updatePosition();
    window.addEventListener('resize', updatePosition);
    window.addEventListener('scroll', updatePosition);

    return () => {
      window.removeEventListener('resize', updatePosition);
      window.removeEventListener('scroll', updatePosition);
    };
  }, [targetElementRef.current]);

  const list = useMemo(() => {
    // return options;
    return options
      ?.filter((o) => {
        const key = o.name?.toString().length ? 'name' : 'username';
        return o[key]?.toString()?.toLowerCase()?.includes(searchValue.toLowerCase());
      })
      .sort((a, b) => {
        if (name === 'grade' || name === 'gradeLevel') return 0;
        const key = a.name?.toString().length ? 'name' : 'username';
        if (typeof a?.[key] === 'string') return a?.[key]!?.localeCompare(b?.[key]!);
        return a?.[key]! > b?.[key]! ? 1 : -1;
      });
  }, [searchValue, options]);

  function handleSearch(value: string) {
    setSearchValue(value);
  }
  return (
    <DropdownMenu.Root>
      <div ref={targetElementRef} className={`resetInput ${className ?? ''}`}>
        <DropdownMenu.Trigger className='flex flex-col items-start'>
          <label
            className={`resetLabel group ${
              containsAsteriks ? `after:content-['*'] after:text-red-500` : ''
            } flex`}
            htmlFor={name}
          >
            {label}
          </label>
          <div className='flex truncate w-full justify-between'>
            <p className='truncate flex-1 text-start'>
              {!noFormik
                ? field?.value?.toString()
                  ? selected ??
                    options?.filter((o) => o.id.toString() === field?.value.toString())?.[0]?.name
                  : 'Please select an option!'
                : other.value
                ? options?.filter((o) => o.id.toString() === other?.value?.toString())?.[0]?.name
                : selected ?? 'Please select an option!'}
            </p>
            <IoIosArrowDown />
          </div>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content
          style={{ width: position.width, maxHeight: 280 }}
          className={`max-w-full z-20 bg-white truncate rounded-lg border-1 text-sm shadow-sm  !overflow-y-auto`}
        >
          <div className='bg-white sticky top-0 left-0 right-0 shadow-sm'>
            <input
              onKeyDown={(e) => e.stopPropagation()}
              type='search'
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => handleSearch(e.target.value)}
              value={searchValue}
              placeholder='Search'
              className='h-full w-full p-2 active:outline-none focus:outline-none border-b-1'
            />
          </div>
          {list?.length ? (
            list
              // .filter((o) => o.name?.includes(searchValue))
              .map((o) => (
                <React.Fragment key={o.id}>
                  <DropdownMenu.Item
                    onClick={(e) => {
                      // if (name === 'orderRank') return;
                      if (setFieldValue) {
                        setFieldValue(o.id);
                      }
                      if (formikObj) formikObj.setFieldValue(field?.name!, o.id);
                      setSelected(props?.selectByUsername ? o.username : o.name);
                      setSearchValue('');
                    }}
                    className='truncate hover:bg-slate-50 cursor-pointer p-1 m-1 focus-visible:outline-3 focus-visible:bg-blue-50 text-sm flex items-center min-w-0'
                  >
                    {(other?.value ? other?.value?.toString() : field?.value?.toString()) ===
                    o?.id?.toString() ? (
                      <BsCheck size={20} />
                    ) : (
                      <></>
                    )}
                    <p className='min-w-0 truncate'>{o.username ?? o.name}</p>
                    {/* <p>{o.username ?? o.name}</p> */}
                  </DropdownMenu.Item>
                  <DropdownMenu.Separator className='border-t-1' />
                </React.Fragment>
              ))
          ) : (
            <p className='p-2 text-sm'>No results found</p>
          )}
        </DropdownMenu.Content>
      </div>
      {meta?.touched && meta.error ? <div className='error'>{meta.error} </div> : <br />}
    </DropdownMenu.Root>
  );

  // return (
  //   <>
  //     {open ? (
  //       <OutsideClick
  //         setFunc={() => {
  //           setOpen(false);
  //           setSearchValue('');
  //         }}
  //         noBg
  //       />
  //     ) : (
  //       <></>
  //     )}
  //     <div
  //       ref={targetElementRef}
  //       onClick={() => setOpen(!open)}
  //       className={`resetInput cursor-pointer ${className}`}
  //     >
  //       <label
  //         className={`resetLabel group ${
  //           containsAsteriks ? `after:content-['*'] after:text-red-500` : ''
  //         }`}
  //         htmlFor={name}
  //       >
  //         {label}
  //       </label>
  //       <div className='flex truncate'>
  //         <p className='truncate flex-1'>{field?.value ? selected : 'Please select an option!'}</p>
  //         <IoIosArrowDown />
  //       </div>
  //       {open ? (
  //         <div
  //           className={cn(
  //             `resetInput absolute bottom-0 left-0 right-0 bg-white rounded-lg z-10 max-h-[280px] overflow-y-auto flex flex-col gap-1`,
  //           )}
  //         >
  //           <div className='bg-white sticky top-0 left-0 right-0 p-2'>
  //             <input
  //               type='search'
  //               onClick={(e) => e.stopPropagation()}
  //               onChange={(e) => handleSearch(e.target.value)}
  //               value={searchValue}
  //               placeholder='Search'
  //               className='h-full w-full active:outline-none focus:outline-none border-b-1'
  //             />
  //           </div>
  //           {list.length ? (
  //             list
  //               // .filter((o) => o.name?.includes(searchValue))
  //               .map((o) => (
  //                 <span
  //                   className='flex items-center p-2 min-h-[30px] hover:bg-slate-50 truncate'
  //                   onClick={(e) => {
  //                     if (name === 'orderRank') return;
  //                     if (setFieldValue) {
  //                       setFieldValue(o.id);
  //                     }
  //                     setFormikFieldValue(field?.name!, o.id);
  //                     setSelected(props.selectByUsername ? o.username : o.name);
  //                     setSearchValue('');
  //                   }}
  //                   key={o.id}
  //                 >
  //                   {field?.value === o.id ? <BsCheck /> : <></>}
  //                   {o.name?.toString()}
  //                 </span>
  //               ))
  //           ) : (
  //             <p className='p-2 text-sm'>No results found</p>
  //           )}
  //         </div>
  //       ) : (
  //         <></>
  //       )}
  //     </div>

  //     {/* {meta?.touched && meta.error ? <div className='error'>{meta.error} </div> : <br />} */}
  //   </>
  // );
};

export default Combobox;
