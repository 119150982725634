import React, { SetStateAction, startTransition, useMemo, useState } from 'react';
import { TeamModel } from '../../model/team.model';
import { MatchesType, SelectionType } from '../../routes/seasons/routes/matches/generate-match';
import {
  useGetAllTeamsByTournamentIdQuery,
  useGetAllTeamsByWeekIdQuery,
} from '../../slices/teamsApiSlice';
import { useParams } from 'react-router';
import { CiSearch } from 'react-icons/ci';
type TeamSectionProps = {
  handleSelection: (selectProps: SelectionType<'team'>, index?: number) => void;
  type: 'season' | 'tournament';
  selections?: SelectionType<'match' | 'team'>;
  matches?: MatchesType;
  setMatches: React.Dispatch<SetStateAction<MatchesType>>;
};
export function TeamSection({ type, selections, handleSelection, matches }: TeamSectionProps) {
  const params = useParams();
  const { data, error, isLoading } =
    type === 'tournament'
      ? useGetAllTeamsByTournamentIdQuery(params.id, { skip: !params.id || type !== 'tournament' })
      : useGetAllTeamsByWeekIdQuery(
          {
            id: params.weekid,
            seasonId: params.id,
          },
          { skip: !params.weekid || params.weekid === null || !params.id || type !== 'season' },
        );
  const [searchValue, setSearchValue] = useState('');
  const list = useMemo(() => {
    return data
      .filter((o: any) => {
        const model = new TeamModel(o);

        return model.getOptionName()?.toLowerCase()?.includes(searchValue.toLowerCase());
      })
      .sort((a: any, b: any) => a?.teamName!?.localeCompare(b?.teamName));
  }, [searchValue]);
  if (isLoading) return <p>Loading...</p>;
  return (
    <>
      <h3 className='uppercase text-center'>Teams</h3>
      <div className='relative'>
        <input
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          className='resetInput font-normal !pl-7'
          type='search'
          placeholder='Search team'
        />
        <CiSearch className='absolute left-2 top-0 bottom-0 my-auto' />
      </div>

      <div className='flex flex-col font-bold gap-2 pr-2 h-full overflow-auto'>
        {list.map((d: any) => {
          const model = new TeamModel(d);
          const getClassName = () => {
            if (selections?.type === 'team' && selections?.team === model.id)
              return 'bg-green-100 hover:bg-green-200';
            if (matches?.filter((m) => m.at?.id === model.id || m.ht?.id === model.id)?.length)
              return 'bg-gray-200 hover:bg-gray-300';
            return 'bg-white hover:bg-slate-50';
          };
          return (
            <span
              onClick={() => handleSelection({ team: model.id, type: 'team' })}
              key={model.id}
              className={`
                          ${getClassName()}                          p-3 
                          select-none w-full
                          font-normal rounded-lg border-2 truncate 
                          flex items-center min-h-[44px] h-[44px] max-h-[44px] cursor-pointer   
                          `}
            >
              {d?.isBenched ? (
                <div className='flex items-center gap-2 w-full'>
                  <div className='max-w-[80%]'>
                    <p className='text-gray-500 truncate'>{model.getOptionName()}</p>
                  </div>
                  <div className=''>
                    <span className='ml-2 text-[#B50303] font-[600] font-[Inter] text-[12px]'>
                      BENCHED
                    </span>
                  </div>
                </div>
              ) : (
                <p className='text-gray-500 truncate'>{model.getOptionName()}</p>
              )}
            </span>
          );
        })}
      </div>
    </>
  );
}
