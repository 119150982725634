import React, { ReactElement } from 'react';
import ModalHeader from './modal-header';
import { FormInput } from './form-input/form-input';
import { Form } from 'formik';
import ModalFooter from './modal-footer';

const ModalContent = ({
  formInputs,
  isLoading,
  name,
  setAvatarImage,
  extraClass,
  children,
  form,
  hideFooter,
  inputFilter,
  errors,
  error,
  isError,
  onCancel,
}: {
  isError?: boolean;
  error?: string;
  formInputs?: any;
  isLoading?: boolean;
  name: string;
  setAvatarImage?: any;
  extraClass?: string;
  children?: ReactElement;
  form?: Boolean;
  hideFooter?: Boolean;
  inputFilter?: Function;
  errors?: any;
  onCancel?: Function;
}) => {
  if (children)
    return (
      <div
        className={`fixed top-0 left-0 right-0 bottom-0 m-auto z-10 w-auto mx-auto max-w-2xl ${
          extraClass ? extraClass : 'max-h-[90vh]'
        }`}
      >
        <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none h-full'>
          <ModalHeader name={name} />
          <div className='relative px-3 flex-auto overflow-auto'>{children}</div>
          {hideFooter ? <></> : <ModalFooter isLoading={isLoading!} />}
        </div>
      </div>
    );
  if (children && form)
    return (
      <Form>
        <div
          className={`fixed top-0 left-0 right-0 bottom-0 m-auto z-10 w-auto mx-auto max-w-2xl ${
            extraClass ? extraClass : 'h-[90vh] max-h-[90vh]'
          }`}
        >
          <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none h-full'>
            <ModalHeader name={name} />
            <div className='relative px-3 flex-auto overflow-auto'>{children}</div>
            {hideFooter ? <></> : <ModalFooter isLoading={isLoading!} onCancel={onCancel} />}
          </div>
        </div>
      </Form>
    );
  return (
    <Form>
      <div
        className={`fixed h-fit top-0 left-0 bottom-0 right-0 m-auto z-10 w-auto flex items-center justify-center  max-w-2xl  ${
          extraClass ? '' : ''
        }`}
      >
        <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-[90vh]'>
          <ModalHeader name={name} />
          <div className='relative px-3 flex-auto min-h-[20vh] max-h-[80vh] overflow-auto'>
            {formInputs.map((input: any, i: number) => (
              <React.Fragment key={i}>
                {!formInputs[i - 1] || formInputs[i - 1]?.mainTitle !== input.mainTitle ? (
                  <>
                    <div className='flex flex-col'>
                      <div className='my-4'>
                        <p className='text-gray-800 font-bold'>{input.mainTitle}</p>
                        <hr />
                      </div>
                      <div className='flex flex-wrap gap-1'>
                        {formInputs
                          .filter((inp1: any) => inp1.mainTitle === input.mainTitle)
                          .filter((inp: any) => (inputFilter ? inputFilter(inp) : true))
                          .map((inp: any, i: any) => (
                            <React.Fragment key={i}>
                              <div className={inp.isTrue ? 'w-full ' : 'flex-1 min-w-[300px]'}>
                                {inp.type !== 'image' ? (
                                  <FormInput {...inp} />
                                ) : (
                                  <FormInput setFieldValue={setAvatarImage} {...inp} />
                                )}
                              </div>
                            </React.Fragment>
                          ))}
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </React.Fragment>
            ))}
          </div>
          {isError && <div className='flex align-center text-red-700 ml-2'>{error}</div>}

          {hideFooter ? (
            <></>
          ) : (
            <ModalFooter isLoading={isLoading!} onCancel={onCancel} errors={errors} />
          )}
        </div>
      </div>
    </Form>
  );
};

export default ModalContent;
