import React, { useEffect, useState } from 'react';
import { Form, Formik, FormikValues } from 'formik';
import {
  cityRules,
  emailRules, nameRulesHint,
  nameRulesWithSpecialCharacter,
  usPhoneRules,
  usPhoneRulesHint,
  usernameRules,
  usernameRulesHint,
  validEmailRule,
  zipCodeRules,
  zipCodeRulesHint
} from '../../authentication/yupschema';
import { Navigate } from 'react-router-dom';
import { FormInput } from '../../../components/form-input/form-input';
import { useGetOrgWithoutOgmaQuery } from '../../../slices/organizationApiSlice';
import { useCreateOgmaMutation, useInviteUserMutation } from '../../../slices/userApiSlice';
import { UserRoles, allStates, gendersOptions } from '../../../utils/constants';
import * as yup from 'yup';
import { Checkbox } from '../../../components/ui/checkbox/Checkbox';
import ModalFooter from '../../../components/modal-footer';
import { CustomError } from '../../../utils/custom-types';
import { ErrorMessages } from '../../../utils/messages.enum';
import { OrganizationModel } from '../../../model/organization.model';

const NewOGMAModal = () => {
  const [inviteOnly, setInviteOnly] = useState(false);
  const initialSchema = {
    organizationName: yup.string().required('Required!'),
    firstName: yup.string().matches(nameRulesWithSpecialCharacter, nameRulesHint).required('Required!'),
    lastName: yup.string().matches(nameRulesWithSpecialCharacter, nameRulesHint).required('Required!'),
    username: yup.string().matches(usernameRules, usernameRulesHint).required('Required!'),
    // streetAddress: yup
    //   .string()
    //   .matches(/^[0-9a-zA-Z\s]+$/, 'Street address must only contain letters, spaces and numbers'),
    city: yup.string().matches(cityRules, ErrorMessages.CityValidation).required('Required!'),
    state: yup.string().required('Required!'),
    zipCode: yup.string().matches(zipCodeRules, zipCodeRulesHint).required('Required!'),
    gender: yup.string().required('Required!'),
    email: yup.string().required('Required!').matches(emailRules, validEmailRule),
    // dateOfBirth: yup
    //   .date()
    //   .nullable()
    //   .notRequired()
    //   // .min(moment('1930-01-01').startOf('day'), 'User must be born on or after January 1, 1930')
    //   .min(
    //     new Date(new Date().getFullYear() - 100, 0, 1),
    //     `User must be born on or after January 1, ${new Date(
    //       new Date().getFullYear() - 100,
    //       0,
    //       1,
    //     ).getFullYear()}`,
    //   )
    //   .max(moment().endOf('day').subtract(18, 'years'), 'User must be at least 18 years old!'),

    phoneNumber: yup.string().matches(usPhoneRules, usPhoneRulesHint).required('Required!'),
    office_number: yup.string().matches(usPhoneRules, usPhoneRulesHint),
    // school: yup.string().required("Required"),
    // manager: yup.string().required("Required"),
    // tags: yup.string().required("Required"),
    // status: yup.string().required("Required"),
  };
  const [schema, setSchema] = useState<{ [key: string]: any }>(initialSchema);
  const [
    createOgma,
    { isLoading: ogmaIsLoading, isSuccess: ogmaIsSuccess, isError: ogmaIsError, error: ogmaError },
  ] = useCreateOgmaMutation();

  const { data: orgData } = useGetOrgWithoutOgmaQuery('');

  const [
    inviteUser,
    {
      data: invData,
      isLoading: invIsLoading,
      isSuccess: invIsSuccess,
      isError: invIsError,
      error: invError,
    },
  ] = useInviteUserMutation();
  const onSubmit = (
    values: FormikValues,
    {
      setFieldValue,
    }: { setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void },
  ) => {
    if (inviteOnly) {
      inviteUser({
        body: { email: values.email, organization_id: +values.organizationName },
        params: { role: UserRoles.Ogma },
      });
      return;
    }
    const data = values;

    data.zipCode = Number(data.zipCode);

    data.organization_id = Number(data.organizationName);

    // if (data.dateOfBirth) {
    //   data.dateOfBirth = new Date(values.dateOfBirth).toISOString();
    // } else {
    //   data.dateOfBirth = null;
    // }

    console.log('start', data, values);
    delete data.organizationName;
    if (!data?.office_number) delete data.office_number;

    createOgma({ ...data, roles: UserRoles.Ogma })
      .unwrap()
      .then((res) => console.log(res))
      .catch((error) => {
        console.log('new ogma error', error);
        // if (values.dateOfBirth) {
        //   const newDate = new Date(values.dateOfBirth);
        setFieldValue('organizationName', data?.organization_id);
        // }
      });
  };

  const formInputs = [
    {
      mainTitle: 'Organization Manager Information',
      name: 'organizationName',
      label: 'Organization Name',
      type: 'text',
      placeholder: 'Organization Name',
      isSelect: true,
      isTrue: true,
      options: orgData
        ? orgData.map((o: any) => {
            const model = new OrganizationModel(o);
            return { id: model.id, name: model.getOptionName() };
          })
        : [],
      inviteOnly: true,
    },

    {
      mainTitle: 'Organization Manager Information',
      name: 'firstName',
      label: 'First Name',
      type: 'text',
      placeholder: 'Enter first name',
    },
    {
      mainTitle: 'Organization Manager Information',
      name: 'lastName',
      label: 'Last Name',
      type: 'text',
      placeholder: 'Enter last name',
    },
    {
      mainTitle: 'Organization Manager Information',
      name: 'username',
      label: 'Username',
      type: 'text',
      placeholder: 'Enter username',
    },
    {
      mainTitle: 'Organization Manager Information',
      name: 'city',
      label: 'City',
      type: 'text',
      placeholder: 'Enter city',
    },
    {
      mainTitle: 'Organization Manager Information',
      name: 'state',
      label: 'State',
      type: 'text',
      placeholder: 'Enter state',
      isSelect: true,
      options: allStates ?? [],
    },
    {
      mainTitle: 'Organization Manager Information',
      name: 'zipCode',
      label: 'Zip Code',
      type: 'number',
      placeholder: 'Enter zip code',
    },
    {
      mainTitle: 'Organization Manager Information',
      name: 'phoneNumber',
      label: 'Phone Number',
      type: 'text',
      placeholder: 'Enter phone number',
    },
    {
      mainTitle: 'Organization Manager Information',
      name: 'office_number',
      label: 'Office Number',
      type: 'text',
      placeholder: 'Enter office number',
    },
    {
      mainTitle: 'Organization Manager Information',
      name: 'gender',
      label: 'Gender',
      type: 'text',
      isSelect: true,
      options: gendersOptions,
    },

    {
      mainTitle: 'Organization Manager Information',
      name: 'email',
      label: 'Email Address',
      type: 'text',
      placeholder: 'Enter email address',
      isTrue: false,
      inviteOnly: true,
    },
  ];

  useEffect(() => {
    if (!inviteOnly) {
      const newFormInputs = formInputs.filter((form) => {
        form.name = form.name === 'organizationName' ? 'fake' : form.name;
      });
    }
  }, [inviteOnly]);

  const initialValues: { [key: string]: string } = {};
  formInputs.forEach((input: any) => (initialValues[input.name] = ''));

  function handleCheck(ch: boolean | string) {
    setInviteOnly(!inviteOnly);
    setSchema((state) => {
      const { organizationName, email } = state;
      if (!inviteOnly) return { organizationName, email };
      else return initialSchema;
    });
  }
  function getError(error: typeof invError) {
    const err = invError as { [key: string]: any };
    return err;
  }
  if (ogmaIsSuccess || invIsSuccess) return <Navigate to='../' />;
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={yup.object().shape(schema)}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        <Form>
          <div className='flex flex-col gap-2'>
            {/* {invIsError && <p>{getError(invError)?.data?.message}</p>} */}
            {formInputs.map((input, i: number) => (
              <React.Fragment key={input.name}>
                {!formInputs[i - 1] || formInputs[i - 1]?.mainTitle !== input.mainTitle ? (
                  <>
                    <div className='flex flex-col'>
                      <div className='mb-2'>
                        <p className='text-gray-800 font-bold pt-6'>{input.mainTitle}</p>
                        <div className='flex items-center gap-1.5 hover:bg-slate-50 cursor-pointer'>
                          <Checkbox
                            checked={inviteOnly}
                            onCheckedChange={(ch) => handleCheck(ch)}
                            id={'invite'}
                          />
                          <label htmlFor={'invite'} className='flex-1 cursor-pointer'>
                            Invite by email
                          </label>
                        </div>

                        <hr />
                      </div>
                      <div className='flex flex-wrap gap-1'>
                        {formInputs
                          .filter((inp1) => inp1.mainTitle === input.mainTitle)
                          .filter((inp) => (inviteOnly ? inp.inviteOnly : inp))
                          .map((inp) => (
                            <React.Fragment key={inp.name}>
                              <div className={inp.isTrue ? 'w-full ' : 'flex-1 min-w-[300px]'}>
                                <FormInput {...inp} />
                              </div>
                            </React.Fragment>
                          ))}
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </React.Fragment>
            ))}
            {inviteOnly
              ? invIsError && (
                  <p className='text-red-300'>{(invError as CustomError).data.message}</p>
                )
              : null}
            {inviteOnly
              ? null
              : ogmaIsError && (
                  <p className='text-red-300'>{(ogmaError as CustomError).data.message}</p>
                )}
          </div>
          {/* footer*/}
          {/* <div className='flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b'>
            <Link
              className='w-1/5 my-12 text-center py-1 bg-white text-black shadow-lg font-semibold rounded-lg'
              to='../'
            >
              Cancel
            </Link>
            <SubmitButton isLoading={ogmaIsLoading || invIsLoading} />
          </div> */}

          <ModalFooter isLoading={ogmaIsLoading || invIsLoading} />
        </Form>
      </Formik>
    </>
  );
};

export default NewOGMAModal;
