import { ReactElement, useEffect, useState } from 'react';
import { selectUser } from '../slices/auth';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

function useRoleAuthorization(
  requiredRole: number[],
  givenRole?: number,
): [boolean, { redirect: ReactElement; userRole: string }] {
  const roles = useSelector(selectUser)?.roles;
  const role = roles ?? givenRole;
  const [isAuthorized, setIsAuthorized] = useState(true);
  const [userRole, setUserRole] = useState('none');
  useEffect(() => {
    console.log('start', isAuthorized);
    if (requiredRole.includes(role)) setIsAuthorized(true);
    else setIsAuthorized(false);
    console.log('end', isAuthorized);
    switch (role) {
      case 0:
        setUserRole('admin');
        break;
      case 1:
        setUserRole('ogma');
        break;
      case 2:
        setUserRole('student');
        break;
      case 3:
        setUserRole('coach');
        break;
      case 4:
        setUserRole('guardian');
        break;
      case 5:
        setUserRole('fan');
        break;
      default:
        setUserRole('fan');
    }
  }, []);

  return [
    isAuthorized,
    {
      redirect: isAuthorized ? <Navigate to={`./${userRole}`} /> : <Navigate to='/went-wrong' />,
      userRole,
    },
  ];
}

export default useRoleAuthorization;
