import { OutsideClick } from '../../../../components/outside-click';
import ManualBracket from './manual-bracket';
const GenerateBracket = () => {
  return (
    <>
      <OutsideClick />
      <ManualBracket />
      {/* <GenerateMatch type='tournament' /> */}
    </>
  );
};

export default GenerateBracket;
