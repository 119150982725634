import { Formik, Form, FormikValues } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { Navigate, useParams } from 'react-router';
import { OutsideClick } from '../../../components/outside-click';
import {
  useGetSingleMatchInfoQuery,
  useCreateMatchChatDisputeMutation,
} from '../../../slices/matchApiSlice';
import { DragDropFile } from '../edit-game-results/drag-drop-file/drag-drop-file';
import { GameScreenshotType, UserRoles } from '../../../utils/constants';
import { CustomError } from '../../../utils/custom-types';
import ModalHeader from '../../../components/modal-header';
import ModalFooter from '../../../components/modal-footer';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../slices/auth';

const DisputeMatchChat = () => {
  const [
    createMatchChatDispute,
    { error: error2, isSuccess: upIsSuccess, isError: upIsError, isLoading: upIsLoading },
  ] = useCreateMatchChatDisputeMutation();
  const [file, setFile] = useState<File | null>(null);
  const [message, setMessage] = useState('');
  const param = useParams();
  const user = useSelector(selectUser);
  const handleSubmit = (values: FormikValues) => {
    const formData = new FormData();
    formData.append('message', textareaRef.current?.value || '');
    if (file) {
      formData.append('image', file);
    }
    formData.append('type', GameScreenshotType.ChatDispute.toString());
    createMatchChatDispute({ id: param.id, body: formData });
    return;
  };
  const { error, data, isLoading, isError } = useGetSingleMatchInfoQuery(param.id);
  const [errorMsg, setErrorMsg] = useState('');
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [textareaRef]);
  useEffect(() => {
    if (file) setErrorMsg('');
  }, [file]);
  if (upIsSuccess) return <Navigate to='../' />;
  if (isError || upIsError) {
    const errorMessage = upIsError
      ? (error2 as CustomError).data.message
      : (error as CustomError).data.message;
    return <Navigate to='/went-wrong' state={{ message: errorMessage }} />;
  }
  if (isLoading) return <p>Loading...</p>;
  return (
    <>
      <OutsideClick />
      <Formik initialValues={{}} onSubmit={handleSubmit}>
        {(formik) => {
          return (
            <Form>
              <div className='fixed pb-20 top-0 left-0 right-0 z-10 w-auto my-6 mx-auto max-w-3xl max-h-[100vh] '>
                <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-full overflow-auto'>
                  <ModalHeader name='Flag an Admin' />
                  <div className='relative p-6 flex flex-col'>
                    <div className={`resetInput`}>
                      <label className='resetLabel group'>Describe your dispute</label>
                      <textarea ref={textareaRef} className='resetFormInput' />
                    </div>
                  </div>
                  <div className='flex flex-col p-5 gap-5 drag-upload'>
                    <p className='text-red-700'>{errorMsg}</p>
                    <DragDropFile file={file} setFile={setFile} />
                  </div>
                  <ModalFooter isLoading={upIsLoading} />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default DisputeMatchChat;
