import { Formik, FormikValues } from 'formik';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { OutsideClick } from '../../components/outside-click';
import { useGetUserByIdQuery, useUpdateUserMutation } from '../../slices/userApiSlice';
import { EditUserSchema } from './edit-user-schema';
import { UserRoles } from '../../utils/constants';
import ModalContent from '../../components/modal-content';
import { useEffect, useState } from 'react';
import { CustomError } from '../../utils/custom-types';
import { useGetAllSchoolsQuery } from '../../slices/schoolsApiSlice';
import { useSelector } from 'react-redux';
import { selectUser } from '../../slices/auth';
import { useGetAllOrganizationsQuery } from '../../slices/organizationApiSlice';
import { SchoolModel } from '../../model/school/school-model';
import { OrganizationModel } from '../../model/organization.model';

function EditUser() {
  const { data, isLoading: schIsLoading } = useGetAllSchoolsQuery({
    nolimit: 'true',
  });
  const { data: organizationData, isLoading: orgIsLoading } = useGetAllOrganizationsQuery({
    nolimit: 'true',
  });

  const user = useSelector(selectUser);
  const [optionStatus, setOptionStatus] = useState<number | undefined>(undefined);
  const isEmailFieldVisible = user.roles === UserRoles.Admin ? true : false;

  const [formInputs, setFormInputs] = useState<any>([
    {
      mainTitle: 'User Information',
      type: 'text',
      label: 'First name',
      name: 'firstName',
      placeholder: 'Enter first name',
    },
    {
      mainTitle: 'User Information',
      type: 'text',
      label: 'Last Name',
      name: 'lastName',
      placeholder: 'Enter last name',
    },
    {
      mainTitle: 'User Information',
      type: 'text',
      label: 'Username',
      name: 'username',
      placeholder: 'Enter username',
    },

    {
      mainTitle: 'User Information',
      type: 'text',
      label: 'Tags',
      name: 'tags',
      placeholder: 'Enter the tags of user',
    },
    {
      mainTitle: 'User Information',
      type: 'text',
      label: 'Email',
      name: 'email',
      placeholder: 'Enter the email of user',
      isVisible: isEmailFieldVisible,
    },
    // {
    //   mainTitle: 'User Information',
    //   type: 'text',
    //   label: 'Roles',
    //   name: 'roles',
    //   placeholder: 'Please select a role',
    //   isSelect: true,
    //   options: [
    //     { id: 0, name: 'Admin' },
    //     { id: 1, name: 'OGMA' },
    //     { id: 2, name: 'Student' },
    //     { id: 3, name: 'Coach' },
    //     { id: 4, name: 'Guardian' },
    //     { id: 5, name: 'Fan' },
    //   ],
    // },
  ]);

  const location = useLocation();
  const onSubmit = (values: FormikValues) => {
    const data = values;
    data.roles = +data.roles;
    // data.status = +data.status;
    // data.eligibilityStatus = +data.eligibilityStatus;
    // delete data.status;
    delete data.eligibilityStatus;
    // delete data.school;
    console.log(data.school);
    data.school = +data.school;
    data.organization = +data.organization;
    console.log(values);
    editUser({ id: param.id, body: data })
      .unwrap()
      .then((res) => console.log(res))
      .catch((e) => console.log(e));
  };
  const param = useParams();
  const {
    error,
    data: userData,
    isLoading: userIsLoading,
    isError: userIsError,
    isSuccess: userIsSuccess,
  } = useGetUserByIdQuery(param.id);

  useEffect(() => {
    if (optionStatus === undefined) return;
    if (+optionStatus === UserRoles.Student) {
      const newFormInputs = formInputs.filter((formInput: any) => {
        return formInput.name !== 'status' && formInput.name !== 'school';
      });
      setFormInputs([
        ...newFormInputs,
        {
          mainTitle: 'User Information',
          name: 'status',
          label: 'Status',
          type: 'text',
          placeholder: 'Active',
          isSelect: true,
          options: [
            { id: 0, name: 'Disabled' },
            { id: 1, name: 'Active' },
          ],
        },
        {
          mainTitle: 'User Information',
          name: 'school',
          label: 'School',
          type: 'text',
          placeholder: 'Active',
          isSelect: true,
          options: data
            ? data.map((s: any) => ({ id: s.id, name: new SchoolModel(s).getOptionName() }))
            : [],
        },
      ]);
    } else if (+optionStatus === UserRoles.Coach) {
      const newFormInputs = formInputs.filter((formInput: any) => {
        return formInput.name !== 'status';
      });
      setFormInputs([
        ...newFormInputs,
        {
          mainTitle: 'User Information',
          name: 'status',
          label: 'Status',
          type: 'text',
          placeholder: 'Active',
          isSelect: true,
          options: [
            { id: 0, name: 'Disabled' },
            { id: 1, name: 'Active' },
          ],
        },
      ]);
    } else if (+optionStatus === UserRoles.Ogma) {
      const newFormInputs = formInputs.filter((formInput: any) => {
        return formInput.name !== 'organization';
      });
      setFormInputs([
        ...newFormInputs,
        {
          mainTitle: 'User Information',
          name: 'organization',
          label: 'Organization',
          type: 'text',
          placeholder: 'Active',
          isSelect: true,
          options: organizationData
            ? organizationData.map((o: any) => ({
                id: o.id,
                name: new OrganizationModel(o).getOptionName(),
              }))
            : [],
        },
      ]);
    } else {
      const newFormInputs = formInputs.filter((formInput: any) => {
        return formInput.name !== 'status';
      });
      setFormInputs([...newFormInputs]);
    }
  }, [optionStatus]);
  useEffect(() => {
    if (!userData || !data || !organizationData) return;
    setOptionStatus(userData.roles);
  }, [userData, data, organizationData]);
  const [editUser, { isLoading, isError, isSuccess, error: editUserError }] =
    useUpdateUserMutation();
  if (isSuccess) {
    const queryParams = new URLSearchParams(location.search);
    return <Navigate to={`../?${queryParams.toString()}`} />;
  }
  if (userIsError || (userIsSuccess && !userData))
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError).data.message }} />;
  if (userIsLoading || schIsLoading || orgIsLoading) return <p>Loading...</p>;
  if (optionStatus === undefined) return <p>Loading...</p>;
  return (
    <>
      <OutsideClick />
      <Formik
        initialValues={{
          username: userData?.username,
          firstName: userData?.firstName,
          lastName: userData?.lastName,
          tags: userData?.tags,
          roles: userData?.roles,
          status: userData?.status,
          school: userData?.school_id,
          eligibilityStatus: userData?.eligibilityStatus,
          email: userData?.email,
          organization: userData?.organizations?.[0]?.id,
        }}
        validationSchema={EditUserSchema}
        onSubmit={onSubmit}
      >
        {({ values, errors }) => {
          return (
            <ModalContent
              name={`Edit User - ${userData?.firstName}`}
              formInputs={formInputs}
              inputFilter={(inp: any) =>
                inp.hasOwnProperty('roles') ? inp?.roles.includes(userData?.roles) : true
              }
              isLoading={isLoading}
              extraClass='h-fit'
              errors={Object.keys(errors).length}
              isError={isError}
              error={isError ? (editUserError as CustomError).data.response.message : ''}
            />
          );
        }}
      </Formik>
    </>
  );
}

export default EditUser;
