import { ReactElement, useState } from 'react';
import { CalendarMonthView } from './components/calendar-month-view';
import { wordWithFirstLetterToUpperCase } from '../../utils/utils';
import { BsCalendar2Month, BsCalendar2Week } from 'react-icons/bs';
import CalendarWeekView from './components/calendar-week-view';
const Calendar = () => {
  const types: { [key: string]: { element: ReactElement; icon: ReactElement } } = {
    month: {
      element: <CalendarMonthView />,
      icon: <BsCalendar2Month />,
    },
    week: {
      element: <CalendarWeekView />,
      icon: <BsCalendar2Week />,
    },
  };
  const [type, setType] = useState('month');
  return (
    <div className='listViewContainer fullPage '>
      <div className='flex gap-2'>
        {Object.keys(types).map((t, i) => (
          <div
            key={i}
            onClick={() => setType(t)}
            className={`flex select-none cursor-pointer p-2 items-center gap-2 ${
              type === t
                ? 'bg-slate-200 hover:bg-slate-300 rounded'
                : 'hover:bg-slate-100 border-transparent rounded'
            }`}
          >
            {types[t].icon}
            {wordWithFirstLetterToUpperCase(t)}
          </div>
        ))}
      </div>

      {types[type].element}
    </div>
  );
};

export default Calendar;
