import { useState } from 'react';
import { OutsideClick } from '../../../components/outside-click';
import { Link, Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { SubmitButton } from '../../../components/submit-button';
import { useViewSingleSeasonQuery } from '../../../slices/seasonApiSlice';
import {
  useGetSingleMatchInfoQuery,
  useUpdateMatchTeamMutation,
} from '../../../slices/matchApiSlice';
import ModalHeader from '../../../components/modal-header';

const EditMatchTeam = () => {
  const [selectedItem, setSelectedItem] = useState<null | number>(null);
  const [teamId, setTeamId] = useState<null | number>(null);
  const param = useParams();
  const handleCheckboxChange = (index: number) => {
    setSelectedItem(index);
  };
  const location = useLocation();
  const navigate = useNavigate();
  const sourceComponent = new URLSearchParams(location.search).get('source');

  const { data: matchInfo, isLoading: matchIsLoading } = useGetSingleMatchInfoQuery(param.matchId);
  const { data, isLoading } = useViewSingleSeasonQuery(param.id);
  const [updateTeam, { isLoading: updateIsLoading, isSuccess }] = useUpdateMatchTeamMutation();
  function onSubmit() {
    updateTeam({ id: param.matchId, body: { side: sourceComponent, team_id: teamId, mode: 0 } });
  }

  const allTeams = [
    ...(data?.teams || []),
    ...(matchInfo?.home_team && matchInfo.home_team[0] ? [matchInfo.home_team[0]] : []),
    ...(matchInfo?.away_team && matchInfo.away_team[0] ? [matchInfo.away_team[0]] : []),
  ];

  const duplicatedTeamIds: number[] = [];
  const seenIds = new Set<number>();
  allTeams.forEach((team) => {
    if (seenIds.has(team.id)) {
      duplicatedTeamIds.push(team.id);
    } else {
      seenIds.add(team.id);
    }
  });
  const uniqueTeamsArray = allTeams
    .filter((team) => !duplicatedTeamIds.includes(team.id))
    .sort((a, b) => a.teamName.localeCompare(b.teamName));

  if (isLoading || matchIsLoading) return <p>Loading ...</p>;
  if (isSuccess) return <Navigate to='../' />;

  return (
    <>
      <OutsideClick />
      <div className='fixed h-fit top-0 left-0 bottom-0 right-0 m-auto z-10 w-auto flex items-center justify-center  max-w-2xl'>
        <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none h-full p-2'>
          <ModalHeader name={'Edit Match Team'} />
          <div className='relative px-3 flex-auto min-h-[20vh] max-h-[80vh] overflow-auto break-words w-full'>
            {uniqueTeamsArray?.map((team: any, i: number) => (
              <div key={i} className='flex justify-between items-center pt-3 break-words w-full '>
                <label className='break-words flex-1 min-w-[1px]'>{team.teamName}</label>
                <input
                  className='w-[20px]'
                  type='checkbox'
                  checked={selectedItem === i}
                  onChange={() => {
                    handleCheckboxChange(i);
                    setTeamId(team.id);
                  }}
                />
              </div>
            ))}
          </div>
          <div className='flex sticky items-center justify-end gap-2 border-t border-solid border-slate-200 rounded-xl bottom-0 bg-white py-6 px-6'>
            <SubmitButton onSubmit={onSubmit} title='Confirm' isLoading={updateIsLoading} />
            <Link
              to='../'
              className='p-4 px-8 text-center text-sm py-2 bg-white text-black border font-semibold rounded-lg hover:bg-gray-100'
            >
              Cancel
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditMatchTeam;
