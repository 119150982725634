import { Link, Outlet, useParams } from 'react-router-dom';
import { SupportTickets } from './support-tickets';

import { useGetTicketByIdQuery } from '../../../slices/supportTicketApiSlice';
import { dateAndTimeFormatter } from '../../../utils/dateFormatter';
import { SupportTicketStatus } from '../../../utils/constants';
import { OutsideClick } from '../../../components/outside-click';
import { useState } from 'react';

const TicketDetails = () => {
  const params = useParams();
  const { error, data: ticket } = useGetTicketByIdQuery(params.id);
  const [viewFullImage, setViewFullImage] = useState(false);

  const handleImageView = () => {
    setViewFullImage(true);
  };

  return (
    <>
      {viewFullImage && <OutsideClick setFunc={setViewFullImage} />}
      <SupportTickets />
      <div className='flex justify-between'>
        <Link to='../'>
          <button className='w-[120px] h-[40px] rounded text-slate-700 font-semibold border border-gray-200 text-center hover:bg-gray-200'>
            Back
          </button>
        </Link>
      </div>
      <div className='text-center mb-4'>
        <h1 className='font-bold text-2xl'>{ticket?.title}</h1>
      </div>
      <div className='container flex flex-col lg:flex-row items-center justify-center lg:items-start gap-10'>
        <div>
          <img
            src={ticket?.image}
            className={
              viewFullImage
                ? 'fixed top-0 left-0 right-0 bottom-0 z-10 w-auto h-[90vh] m-auto'
                : 'w-[250px] cursor-pointer'
            }
            onClick={handleImageView}
          />
        </div>
        <div className='flex flex-col'>
          <div className='status mb-4'>
            <h2 className='font-bold'>
              Status:
              <span
                className={
                  ticket?.status === SupportTicketStatus.Open
                    ? 'text-[green]'
                    : ticket?.status === SupportTicketStatus.Closed
                    ? 'text-[red]'
                    : ticket?.status === SupportTicketStatus.Archived
                    ? 'text-gray'
                    : ''
                }
              >
                {' '}
                {ticket?.status === SupportTicketStatus.Open
                  ? 'Open'
                  : ticket?.status === SupportTicketStatus.Closed
                  ? 'Closed'
                  : ticket?.status === SupportTicketStatus.Archived
                  ? 'Archived'
                  : ''}
              </span>
            </h2>
          </div>
          <div className='mb-4'>
            <p>
              <span className='font-bold'>Created at:</span>{' '}
              {dateAndTimeFormatter(ticket?.createdAt).replace(';', '')}
            </p>
          </div>
          <div className='mb-4'>
            <p>
              <span className='font-bold'>Category:</span> {ticket?.category.name}
            </p>
          </div>
          <div className='mb-4'>
            <p>
              <span className='font-bold'>Subcategory:</span> {ticket?.subcategory.name}
            </p>
          </div>
          <div className='mb-4'>
            <p>
              <span className='font-bold'>Description:</span> {ticket?.description}
            </p>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default TicketDetails;
