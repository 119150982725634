import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../slices/auth';
import { Link, Outlet, useSearchParams } from 'react-router-dom';
import {
  useGetUnarchivedQuery,
  useMassDeleteMutation,
  useMassUnArchiveMutation,
} from '../../../../slices/unarchiveApiSlice';
import TableContainer from '../../../../components/table-container/table-container';
import { TableHead } from '../../../../components/table-head';
import { UserRoles } from '../../../../utils/constants';
import TableTd from '../../../../components/table-td/table-td';
import Pagination from '../../../../components/pagination';
import { LoadingSceletonArchived } from '../../../tournaments/routes/all/loading-sceleton-archived';
import AreYouSure from '../user/components/are-you-sure';
import { TeamModel } from '../../../../model/team.model';
import useErrorToast from '../../../../hooks/use-error-toast';
import useSuccessToast from '../../../../hooks/use-success-toast';
import useCloseAreYouSure from '../../../../hooks/use-close-are-you-sure';

const UnarchiveTeam = () => {
  const user = useSelector(selectUser);
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, isLoading } = useGetUnarchivedQuery({
    type: 'team',
    page: searchParams.get('page') ?? undefined,
  });
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isArchiveDialogOpen, setArchiveDialogOpen] = useState(false);

  const [checked, setChecked] = useState<number[]>([]);
  const [
    massDelete,
    {
      isSuccess: deleteIsSuccess,
      isLoading: deleteIsLoading,
      isError: deleteIsError,
      error: deleteError,
    },
  ] = useMassDeleteMutation();
  const [
    massUnArchive,
    {
      isSuccess: unarchiveIsSuccess,
      isLoading: unarchiveIsLoading,
      isError: unarchiveIsError,
      error: unarchievError,
    },
  ] = useMassUnArchiveMutation();

  const listView = [
    {
      name: '',
      active: true,
      prop: '',
    },
    {
      name: 'Name',
      active: true,
      prop: 'name',
    },
    {
      name: 'Created',
      active: true,
      prop: 'f_createdAt',
    },
    {
      name: 'school',
      active: true,
      prop: 'school',
    },
    {
      name: 'game',
      active: true,
      prop: 'game',
    },
    {
      name: 'Number of Players',
      active: true,
      prop: 'numberOfUsers',
    },
    {
      name: 'Archived Date',
      active: true,
      prop: 'updatedAt',
    },
  ];

  const handleDelete = () => {
    massDelete({
      model: 'team',
      ids: [...new Set(checked)],
    });
  };

  const handleArchive = () => {
    let filterChecked: number[] = [];
    data?.data?.map((d: any, idx: number) => {
      if ([...new Set(checked)].some((t) => t === d.id)) {
        if (d?.School?.isArchived || d?.organizations?.find((org: any) => org.isArchived)) return;
        filterChecked.push(d.id);
      }
    });
    massUnArchive({
      model: 'team',
      ids: filterChecked,
    });
  };

  useCloseAreYouSure({
    archiveIsError: unarchiveIsError,
    archiveIsSuccess: unarchiveIsSuccess,
    deleteIsSuccess,
    deleteIsError,
    setDeleteDialogOpen,
    setArchiveDialogOpen,
    setChecked,
  });

  useSuccessToast(deleteIsSuccess || unarchiveIsSuccess, 'Success');
  useErrorToast(deleteIsError, deleteError);
  useErrorToast(unarchiveIsError, unarchievError);
  if (isLoading) return <LoadingSceletonArchived />;
  return (
    <div className='flex flex-col flex-1 gap-3  '>
      <div className=' flex justify-between gap-2 items-center  '>
        <div className='flex gap-3 items-center pl-10'>
          {data?.data?.length ? (
            <>
              <input
                type='checkbox'
                style={{ width: '15px', height: '15px' }}
                checked={data?.data?.every((i: any) => checked.includes(i.id)) && checked.length}
                onChange={() =>
                  data?.data?.every((i: any) => checked.includes(i.id))
                    ? setChecked(checked.filter((id) => !data?.data.find((d: any) => d.id === id)))
                    : setChecked([...checked, ...(data?.data?.map((item: any) => item.id) || [])])
                }
              />
              <p className='text-base font-body'>Select All</p>
            </>
          ) : (
            <></>
          )}
        </div>
        <div className='flex  items-center gap-5'>
          {checked.length && data?.data?.length ? (
            <button onClick={() => setArchiveDialogOpen(true)} className='btn red '>
              Unarchive
            </button>
          ) : (
            <></>
          )}
          {checked.length && data?.data?.length ? (
            <button onClick={() => setDeleteDialogOpen(true)} className='btn red '>
              Delete
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className='listViewContainer w-full border flex-1 overflow-x-auto '>
        <div className='flex flex-col flex-1 justify-between'>
          <TableContainer extraClass='overflow-auto'>
            <TableHead
              oneMore={user.roles === UserRoles.Admin}
              tableList={listView
                .map((l) => l.name)
                .filter(
                  (t) => listView.find((d) => d.name.toLowerCase() === t.toLowerCase())?.active,
                )}
            />
            <tbody className='bg-white divide-y divide-gray-200 w-full'>
              {data?.data?.map((d: any, i: number) => (
                <tr key={i}>
                  {/* {d?.School?.isArchived || d?.organizations?.find((org:any) => org.isArchived)  ? <td style={{ width: '15px', height: '15px' }}></td> : */}
                  <TableTd>
                    <input
                      type='checkbox'
                      style={{ width: '15px', height: '15px' }}
                      onChange={() =>
                        checked.includes(d.id)
                          ? setChecked((s) => s.filter((id) => id !== d.id))
                          : setChecked((s) => [...s, d.id])
                      }
                      checked={checked.includes(d.id)}
                    />
                  </TableTd>
                  {/* } */}
                  {listView
                    .filter((l) => l.active)
                    .map((t) => {
                      const model: TeamModel & { [key: string]: any } = new TeamModel(d);
                      return t.prop === 'school' ? (
                        <TableTd key={t.prop}>
                          <div className='text-sm font-medium text-gray-900 truncate'>
                            {model.school}
                          </div>
                        </TableTd>
                      ) : t.prop === 'numberOfUsers' ? (
                        <TableTd key={t.prop}>
                          {d?.activeMembers?.length + d?.subMembers?.length}
                        </TableTd>
                      ) : t.prop === 'updatedAt' ? (
                        <TableTd key={t.prop}>{model[t.prop]}</TableTd>
                      ) : t.prop !== '' ? (
                        <TableTd key={t.prop}>{model[t.prop]}</TableTd>
                      ) : (
                        <React.Fragment key={t.prop} />
                      );
                    })}
                  <TableTd>
                    {d?.School?.isArchived ||
                    d?.organizations?.find((org: any) => org.isArchived) ? (
                      <button type='button' className='btn'>
                        Can't unarchive
                      </button>
                    ) : (
                      <Link to={`./unarchive/${d?.id}/${d?.teamName}`}>
                        <button className='btn red '>Unarchive</button>
                      </Link>
                    )}
                  </TableTd>
                </tr>
              ))}
            </tbody>
          </TableContainer>
          <Outlet />

          <Pagination length={data?.data?.length} {...data?.meta} />
        </div>
        {isDeleteDialogOpen && (
          <AreYouSure
            handleCloseDeleteDialog={setDeleteDialogOpen}
            handleDelete={handleDelete}
            isLoading={deleteIsLoading}
            text='delete selected teams'
          />
        )}
        {isArchiveDialogOpen && (
          <AreYouSure
            handleCloseDeleteDialog={setArchiveDialogOpen}
            handleDelete={handleArchive}
            isLoading={unarchiveIsLoading}
            text='unarchive selected teams'
            note={<ArchivedNotes data={data} checked={checked} />}
          />
        )}
      </div>
    </div>
  );
};

const ArchivedNotes = ({ data, checked }: any) => {
  let count = 0;
  {
    data?.data?.map((d: any, idx: number) => {
      const dataChecked = [...new Set(checked)].some((ch: any) => ch === d.id);
      if (
        (d?.School?.isArchived || d?.organizations?.find((org: any) => org.isArchived)) &&
        dataChecked
      )
        count++;
    });
  }
  return (
    <>
      {!count ? null : (
        <div className='w-full flex flex-col gap-1 border rounded-xl border-red-600 text-xs max-h-[200px] overflow-auto pb-2'>
          <p className='text-red-600 text-center font-bold mb-2 p-2 sticky top-0 bg-white'>
            NOTE - Selected teams that can't unarchive are:
          </p>
          <div className='flex flex-col px-2 gap-1'>
            {data?.data?.map((d: any, idx: number) => {
              const dataChecked = [...new Set(checked)].some((ch: any) => ch === d.id);
              if (
                (d?.School?.isArchived || d?.organizations?.find((org: any) => org.isArchived)) &&
                dataChecked
              )
                return (
                  <p key={idx} className='border rounded-xl p-2 text-red-600 border-red-600'>
                    {d.teamName}
                  </p>
                );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default UnarchiveTeam;
