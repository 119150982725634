import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectDisputes, selectReschedules } from '../../../slices/matchSlice';
import { selectUser } from '../../../slices/auth';
import DisputeRow from './components/dispute-row';
import { DisputeModel } from '../../../model/dispute.model';
import { SlArrowLeft } from 'react-icons/sl';

const ViewDisputes = () => {
  const [show, setShow] = useState(false);
  const disputes = useSelector(selectDisputes);
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  function handleExit() {
    setShow(false);
    setTimeout(() => navigate('../'), 600);
  }
  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), 100);
    return () => clearTimeout(timeout);
  }, []);
  return (
    <div
      className={`absolute max-w-[500px] top-[150px] bg-white bottom-0 w-full md:w-1/2 z-10 border-1 p-2 duration-500 ${show ? '-left-0' : '-left-[100%]'
        } overflow-auto`}
    >
      <div className='flex justify-between mt-2 mb-4'>
        <h2 className='text-md font-bold'>Disputes</h2>
        {/* <AiOutlineClose className='cursor-pointer' onClick={handleExit} /> */}
        <SlArrowLeft className='cursor-pointer' size={20} onClick={handleExit} />
      </div>
      <div className='flex flex-col border-1 rounded-lg p-2'>
        {!disputes?.length && <p>There are no disputes!</p>}
        {disputes?.map((d: any, i: number) => (
          <DisputeRow model={new DisputeModel(d)} key={i} />
        ))}
      </div>
    </div>
  );
};

export default ViewDisputes;
