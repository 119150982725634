import React from 'react';
import GuardianRegisterForm from './GuardianRegisterForm';

import { Link } from 'react-router-dom';
import { useStateContext } from '../../../contexts/ContextProvider';
import ModalTerms from './ModalTerms';

const GuardianRegister = () => {
  const { modalTerms, setModalTerms } = useStateContext();
  const { modalTermsCheckbox, setModalTermsCheckbox } = useStateContext();

  return (
    <>
      <div className='leading-loose flex items-center justify-center'>
        <GuardianRegisterForm />
        {modalTerms && (
          <ModalTerms setModalTerms={setModalTerms} setModalTermsCheckbox={setModalTermsCheckbox} />
        )}
      </div>
      <p className='flex items-center justify-center'>
        <span>
          Already have an account?{' '}
          <Link to='/login'>
            <span className='underline font-medium'> Click here to login</span>
          </Link>
        </span>{' '}
      </p>
    </>
  );
};

export default GuardianRegister;
