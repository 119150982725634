import { useSelector } from 'react-redux';
import { selectUser } from '../../slices/auth';
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import SupportAdminNavigation from './SupportAdminNavigation';
import CanIView from '../../components/can-i-view/can-i-view';

const Support = () => {
  const user = useSelector(selectUser);
  const location = useLocation();
  const [hideTab, setHideTab] = useState(false);
  const param = useParams();

  useEffect(() => {
    if (location.pathname.includes('viewticket') && user.roles === 0) {
      setHideTab(true);
    }
    else {
      setHideTab(false);
    }
  }, [location.pathname]);

  return (
    <div className='listViewContainer fullPage'>
      {hideTab ? null :
        <CanIView component='supportTab.optionContainer'>
          <SupportAdminNavigation />
        </CanIView>
      }
      <Outlet />
    </div>
  );
};

export default Support;