import CurrencyFormat from 'react-currency-format';
import { FaMoneyCheckAlt } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import { useGetUnpaidInvoicesBySchoolIdQuery } from '../../../slices/invoicesApiSlice';
import { InvoiceStatus } from '../../../utils/constants';
import { dateFormatter } from '../../../utils/dateFormatter';
import { InvoiceModel } from '../models/invoice.model';

const OgmaSubSchoolInfo = () => {
  const param = useParams();

  const { data: invoiceData, isLoading: invoiceIsLoading } = useGetUnpaidInvoicesBySchoolIdQuery(
    Number(param.id),
  );

  if (invoiceIsLoading) return <p>Loading...</p>;
  return (
    <div className='flex flex-1 flex-col gap-2 p-4 pl-0'>
      {invoiceData.length ? (
        invoiceData.map((invoice: any) => (
          <InvoiceRow key={invoice.id} model={new InvoiceModel(invoice)} />
        ))
      ) : (
        <p className='font-bold'>✅ You don't have any unpaid invoice!</p>
      )}
    </div>
  );
};

const InvoiceRow = ({ model }: { model: InvoiceModel }) => {
  return (
    <div className='flex items-center gap-2 justify-between  p-2 shadow-sm hover:bg-gray-100'>
      <p className='font-bold basis-[30%] text-sm'>{model.typeName}</p>
      <p className=' basis-[30%] text-sm'>
        {dateFormatter(model.startDate)} - {dateFormatter(model.endDate)}
      </p>
      <span className='basis-[30%] '>
        <p className={`invoiceStatus ${model.statusName?.toLowerCase()}`}>{model.statusName}</p>
      </span>
      <p className=' basis-[30%] text-sm'>
        <CurrencyFormat
          value={model.total}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'$'}
        />
      </p>
      {model.status !== InvoiceStatus.Processing ? (
        <Link
          to={`./${model.id}`}
          className='hover:bg-opacity-80 flex items-center gap-1 p-1 px-3 h-fit bg-green-700 text-white font-bold text-xs rounded-lg '
        >
          <FaMoneyCheckAlt />
          Pay
        </Link>
      ) : (
        <div className='hover:bg-opacity-80 flex items-center gap-1 p-1 px-3 h-fit bg-green-700 text-white font-bold text-xs rounded-lg hidden-none invisible '>
          <FaMoneyCheckAlt />
          pay
        </div>
      )}
    </div>
  );
};
export default OgmaSubSchoolInfo;
