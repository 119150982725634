import React, { useState } from 'react';
import MatchScheduleByDate from '../routes/match-schedule/match-schedule-by-date';
import TournamentScheduleByDate from '../routes/tournament-schedule/tournament-schedule-by-date';
import SeasonScheduleByDate from '../routes/season-schedule/season-schedule-by-date';
import SearchSchedule from './search-schedule';
import { useSearchParams } from 'react-router-dom';

export const ScheduleType = ({ id }: { id: any }) => {
  const [queryParameters] = useSearchParams();

  const [links, setLinks] = useState([
    { id: 2, name: 'ONGOING', bool: !queryParameters?.get('matchType') ? true : false },
    { id: 0, name: 'UPCOMING', bool: queryParameters?.get('matchType') ? true : false },
    { id: 1, name: 'PAST', bool: false },
  ]);

  function setLinkActive(id: number) {
    setLinks((prevLinks: any) =>
      prevLinks.map((link: any) => {
        if (link.id === id) {
          return { ...link, bool: true };
        } else {
          return { ...link, bool: false };
        }
      }),
    );
    // setSearchParams('');
  }

  return (
    <>
      <div className='flex justify-between items-center gap-5 bg-white'>
        <div className='flex gap-2'>
          {links.map((t: any) => (
            <div
              key={t?.id}
              onClick={() => setLinkActive(t.id)}
              className={`${
                t.bool ? '' : 'bg-gray-100'
              } text-xs border select-none rounded-xl px-4 py-2 cursor-pointer`}
            >
              {t.name}
            </div>
          ))}
        </div>
        <div className='flex gap-2'>
          <SearchSchedule links={links} />
        </div>
      </div>
      <div className='h-full'>
        {links.map((t: any) => (
          <React.Fragment key={t.id}>
            {t.bool &&
              (id === 2 ? (
                <MatchScheduleByDate dateStatus={t.id} />
              ) : id === 0 ? (
                <TournamentScheduleByDate dateStatus={t.id} />
              ) : id === 1 ? (
                <SeasonScheduleByDate dateStatus={t.id} />
              ) : (
                <></>
              ))}
          </React.Fragment>
        ))}
      </div>
    </>
  );
};
