import { TeamModel } from '../model/team.model';
import { OutsideClick } from './outside-click';
import { Link } from 'react-router-dom';

const ShowTeamsModal = ({
  setModelId,
  teams,
  title,
}: {
  setModelId: Function;
  teams: Array<String> | undefined;
  title: string;
}) => {
  return (
    <>
      <OutsideClick setFunc={setModelId} />
      <div className='fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-10 min-w-72 max-w-screen mx-auto max-w-2xl'>
        <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none h-full overflow-auto py-4 h-auto max-h-[80vh]'>
          <h1 className='text-center my-1 text-2xl font-semibold tracking-tight'>{title}</h1>
          {teams?.map((team: any, i: number) => (
            <Link
              key={team?.id ?? i}
              to={`/teams/view/${team?.id}`}
              className='p-2 text-sm font-medium text-gray-900 font-bold border-b-1 cursor-pointer mb-1 hover:text-red-600 break-all'
            >
              {new TeamModel(team).getOptionName()}
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};

export default ShowTeamsModal;
