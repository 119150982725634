import { Navigate, useParams } from 'react-router';
import { useUnArchiveMutation } from '../../../../../slices/unarchiveApiSlice';
import ModalUnarchive from '../../../components/unarchive-modal';

const UnarchiveDivisionModal = () => {
    const param = useParams();
    const [unarchive, { isLoading, isError, isSuccess }] = useUnArchiveMutation();
    const onSubmit = () => {
        unarchive({
            id: Number(param.id),
            body: { name: 'division' },
        });
    };
    if (isSuccess) return <Navigate to='../' />;
    return <ModalUnarchive onSubmit={onSubmit} isLoading={isLoading} name={param.name ?? ''} />;
}

export default UnarchiveDivisionModal