import { GameInputs } from './../../components/game-inputs/game-inputs';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import {
  useEditClosedGameMatchMutation,
  useEditGameResultMutation,
  useGetSingleMatchInfoQuery,
} from '../../slices/matchApiSlice';
import { CustomError } from '../../utils/custom-types';
import { OutsideClick } from '../../components/outside-click';
import ModalHeader from '../../components/modal-header';
import ModalFooter from '../../components/modal-footer';
import { generateGameInputProps } from '../../utils/utils';
import { MatchStatus } from '../../utils/constants';
import { ResultScreenshotRow } from '../match/edit-match-results/component/result-screenshot-row';
const UpdateGameResults = () => {
  const [hScore, setHScore] = useState(0);
  const [aScore, setAScore] = useState(0);
  const [oldHScore, setOldHScore] = useState(0);
  const [oldAScore, setOldAScore] = useState(0);

  const param = useParams();
  const { error, data, isLoading, isError } = useGetSingleMatchInfoQuery(param.id);
  const [file, setFile] = useState<File | null>(null);
  useEffect(() => {
    if (!data) return;
    const game = data?.MatchGame.filter((m: { id: number }) => m.id == Number(param.gid));
    console.log(game?.[0]?.ht_score, game?.[0]?.at_score);
    setHScore(game?.[0]?.ht_result ?? 0);
    setOldHScore(game?.[0]?.ht_result ?? 0);

    setAScore(game?.[0]?.aw_result ?? 0);
    setOldAScore(game?.[0]?.aw_result ?? 0);
  }, [data]);
  const [
    sendGameMatchScreenshot,
    { error: error2, isSuccess: upIsSuccess, isError: upIsError, isLoading: upIsLoading },
  ] = useEditGameResultMutation();

  const [
    editClosedGameMatch,
    {
      error: editClosedError,
      isSuccess: editClosedIsSuccess,
      isError: editClosedIsError,
      isLoading: editClosedIsLoading,
    },
  ] = useEditClosedGameMatchMutation();

  const fileTypes = ['JPG', 'PNG', 'GIF'];
  const handleChange = (file: any) => {
    setFile(file);
    console.log('file uploaded', file);
  };
  const [errorMsg, setErrorMsg] = useState('');
  const handleSubmit = (values: any) => {
    if (data?.status === MatchStatus.Completed) {
      editClosedGameMatch({
        id: Number(param.gid),
        body: { aw_result: aScore, ht_result: hScore },
      });
    } else {
      sendGameMatchScreenshot({
        id: Number(param.gid),
        body: { aw_result: aScore, ht_result: hScore, oldAScore, oldHScore },
      });
    }
    return;
  };
  useEffect(() => {
    if (file) setErrorMsg('');
  }, [file]);
  if (upIsSuccess || editClosedIsSuccess) return <Navigate to='../' />;
  if (isError || upIsError) {
    const errorMessage = upIsError
      ? (error2 as CustomError).data?.message
      : (error as CustomError).data?.message;
    return <Navigate to='/went-wrong' state={{ message: errorMessage }} />;
  }
  if (isLoading) return <p>Loading...</p>;
  return (
    <>
      <OutsideClick />
      <Formik initialValues={{}} onSubmit={handleSubmit}>
        {(formik) => {
          return (
            <Form>
              <div className='fixed pb-20 top-0 left-0 right-0 z-10 w-auto my-6 mx-auto max-w-3xl max-h-[100vh] '>
                {/* content*/}
                <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-full overflow-auto'>
                  {/* header*/}
                  <ModalHeader name={'Enter Game Result'} />
                  <div className='relative p-6 flex flex-col gap-2'>
                    <div>
                      <span className='text-md text-red-700'>
                        WARNING: This will effect match result
                      </span>
                    </div>
                    <GameInputs
                      {...generateGameInputProps({ ...data, hScore, setHScore, aScore, setAScore })}
                    />
                  </div>
                  <div className='flex flex-col p-5 gap-5'>
                    {data.MatchGame.filter((mg: any) => mg.id == param.gid)[0]?.MatchGameScreenshot
                      .length ? (
                      data.MatchGame.filter(
                        (mg: any) => mg.id == param.gid,
                      )[0]?.MatchGameScreenshot.map((mg: any) => (
                        <ResultScreenshotRow key={mg.id} {...mg} username={mg.user.username} />
                      ))
                    ) : (
                      <></>
                    )}
                  </div>
                  <ModalFooter isLoading={upIsLoading! || editClosedIsLoading!} />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default UpdateGameResults;
