import React, { ReactNode } from 'react';

function SettingsContainer({
  title,
  children,
  small,
}: {
  title: string;
  children?: ReactNode;
  small?: boolean;
}) {
  return (
    <div
      className={`w-full ${
        small ? 'lg:w-[500px]' : 'lg:w-full'
      } p-5 rounded-lg bg-white lg:rounded-xl `}
    >
      <div className=' mb-12 font-bold'>
        <h1 className='pt-4 mb-9 text-2xl '>{title}</h1>
      </div>
      {children}
    </div>
  );
}

export default SettingsContainer;
