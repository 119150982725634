import { DisputeStatus } from '../utils/constants';

export class DisputeModel {
  id?: number;
  ht_result?: number;
  aw_result?: number;
  user_id?: number;
  username?: string;
  status?: number;
  status_name?: string;
  image?: string;
  constructor(d: any) {
    if (d.hasOwnProperty('id')) this.id = d.id;
    if (d.hasOwnProperty('ht_result')) this.ht_result = d.ht_result;
    if (d.hasOwnProperty('aw_result')) this.aw_result = d.aw_result;
    if (d.hasOwnProperty('user_id')) this.user_id = d.user_id;
    if (d.hasOwnProperty('user') && d.user.hasOwnProperty('username'))
      this.username = d.user.username;
    if (d.hasOwnProperty('status')) {
      this.status = d.status;
      this.status_name = this.status === DisputeStatus.Checked ? 'checked' : 'not checked';
    }
    if (d.hasOwnProperty('url')) this.image = d.url;
  }
}
