import ModalArchive from "../../components/modal-archive";
import { Navigate, useParams } from "react-router";
import { useArchiveSubcategoryMutation, useGetSubcategoryByIdQuery } from "../../slices/supportSubcategoryApiSlice";
import { CustomError } from "../../utils/custom-types";

const ArchiveSupportSubCategory = () => {
    const param = useParams();
    const { data: subcategory, error, isError, isLoading: fetching } = useGetSubcategoryByIdQuery({ id: param.id, path: 'archive' });
    const [archiveSupportSubcategory, { isSuccess, isLoading }] = useArchiveSubcategoryMutation();

    const onSubmit = () => {
        archiveSupportSubcategory(param.id).unwrap()
            .then(resp => console.log('resp after archived sub category', resp))
            .catch(err => console.log('err after archived sub category', err));
    };

    if (fetching) {
        return <p>loading...</p>;
    };

    if (isError) {
        return <Navigate to='/went-wrong' state={{ message: (error as CustomError)?.data?.message }} />;
    };

    if (isSuccess) {
        return <Navigate to="../" />
    }

    return (
        <ModalArchive onSubmit={onSubmit} isLoading={isLoading} name={subcategory?.name} />
    )
};

export default ArchiveSupportSubCategory;