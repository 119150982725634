import { useState } from 'react';
import { useGetAllUsersByRoleQuery } from '../../../slices/userApiSlice';
import { Link, Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import TableContainer from '../../../components/table-container/table-container';
import { TableHead } from '../../../components/table-head';
import { OrganizationCoachModel } from '../../../model/organization-coach.model';
import TableTd from '../../../components/table-td/table-td';
import DropdownItem from '../DropDown/DropDownItem';
import CanIView from '../../../components/can-i-view/can-i-view';
import { UserRoles } from '../../../utils/constants';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../slices/auth';
import { HeaderButton } from '../../../components/header-button';
import Pagination from '../../../components/pagination';
import { LoadingSceletonCoaches } from '../../../routes/tournaments/routes/all/loading-sceleton-coaches';

const ViewCoaches = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    data: coachesData,
    isLoading,
    error,
  } = useGetAllUsersByRoleQuery({ id: 3, params: Object.fromEntries(searchParams.entries()) });

  const configureListView = [
    {
      name: 'first name',
      active: true,
      property: 'firstName',
    },
    {
      name: 'last name',
      active: true,
      property: 'lastName',
    },

    {
      name: 'Tags',
      active: true,
      property: 'tags',
    },

    {
      name: 'Email Address',
      active: true,
      property: 'email',
    },
    {
      name: 'Classrooms',
      active: true,
      property: 'classroom_count',
    },
    // {
    //   name: 'Years of experience',
    //   active: true,
    //   property: 'yearsOfExperience',
    // },
  ];
  const filterList = [
    { title: 'username', property: 'username' },

    { title: 'school', property: 'school' },
    { title: 'tags', property: 'tags' },
    { title: 'email', property: 'email' },
  ];
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState(filterList[0].title);
  const [search, setSearch] = useState({ by: 'username', keyword: '' });

  const [listView, setListView] = useState(configureListView);
  const [_, setOpenListView] = useState(false);

  const initialOpenListState = {
    id: null,
    x: null,
    y: null,
  };

  const [openList, setOpenList] = useState<{ [key: string]: number | null }>(initialOpenListState);

  function handleClick(prop: { id: number; x: number; y: number }) {
    setOpenListView(false);
    if (openList.id == prop.id) {
      setOpenList(initialOpenListState);
    } else {
      setOpenList(prop);
    }
  }

  if (isLoading) return <LoadingSceletonCoaches />;
  return (
    <div className='listViewContainer fullPage rounded-xl border-1'>
      <div className='flex justify-between content-center items-center'>
        <h1 className='text-2xl font-semibold tracking-tight'>All Coaches</h1>
        <CanIView component='coach.newCoach'>
          <HeaderButton type='newCoach' />
        </CanIView>
      </div>
      <div className='flex flex-1 flex-col justify-between'>
        <TableContainer>
          <TableHead
            tableList={listView
              .map((l) => l.name)
              .filter(
                (t) => listView.find((d) => d.name.toLowerCase() === t.toLowerCase())?.active,
              )}
            bool={true}
          />
          <tbody className='bg-white divide-y divide-gray-200'>
            {coachesData?.data?.map((coach: any, i: number) => (
              <tr
                onClick={(e) =>
                  user.roles === UserRoles.Admin
                    ? handleClick({ id: coach.id, x: e.pageX, y: e.pageY })
                    : navigate(`/coaches/view/${coach.id}`)
                }
                className={user.roles !== UserRoles.Admin ? 'cursor-pointer' : ''}
                key={i}
              >
                {listView
                  .filter((l) => l.active)
                  .map((l) => {
                    const model: OrganizationCoachModel & { [key: string]: any } =
                      new OrganizationCoachModel(coach);

                    if (l.property === 'firstName')
                      return (
                        <TableTd key={l.property} extraClass='max-w-[15ch] truncate'>
                          {model.firstName}
                        </TableTd>
                      );
                    if (l.property === 'lastName')
                      return (
                        <TableTd key={l.property} extraClass='max-w-[15ch] truncate'>
                          {model.lastName}
                        </TableTd>
                      );
                    if (l.property === 'tags')
                      return (
                        <TableTd key={l.property} extraClass=''>
                          {model.tags?.map((t, i) => (
                            <span
                              onClick={(e) => {
                                e.stopPropagation();
                                setSearch((s) =>
                                  s.by === 'tags' && s.keyword === t
                                    ? { by: filterList[0].title, keyword: '' }
                                    : { by: 'tags', keyword: t },
                                );
                                setSearchQuery('tags');
                                setSearchParams({ tags: t });
                                search.by === 'tags' && search.keyword === t && setSearchParams();
                              }}
                              key={t + i}
                              className={`tag ${
                                search.by === 'tags' && search.keyword === t ? 'active' : ''
                              }`}
                            >
                              {t}
                            </span>
                          ))}
                        </TableTd>
                      );
                    return <TableTd key={l.property}>{model[l.property]}</TableTd>;
                  })}
                <TableTd>
                  <div className='listViewMenu--parent'>
                    <div
                      className={openList.id === coach.id ? '' : 'hidden'}
                      style={
                        openList.id === coach.id
                          ? {
                              position: 'fixed',
                              top: Number(openList.y),
                              left:
                                Number(openList.x) < 200
                                  ? Number(openList.x) + 200
                                  : Number(openList.x),
                              zIndex: 99,
                            }
                          : {
                              left: 0,
                            }
                      }
                    >
                      <div
                        className='listViewMenu'
                        role='menu'
                        aria-orientation='vertical'
                        aria-labelledby='menu-button'
                      >
                        <div className='' role='none'>
                          <DropdownItem id={coach.id} setFunc={setOpenList} />
                        </div>
                      </div>
                    </div>
                  </div>
                </TableTd>
              </tr>
            ))}
          </tbody>
        </TableContainer>
        <Outlet />
        <Pagination length={coachesData?.data?.length} {...coachesData?.meta} />
      </div>
    </div>
  );
};

export default ViewCoaches;
