import { apiSlice } from '../api/apiSlice';

export const sponsorApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSponsorByPlacement: builder.query({
      query: (name: string) => ({
        url: `/sponsor/by-placement/${name}`,
        method: 'GET',
      }),
      providesTags: ['SingleSponsor'],
    }),
    addOrRemovePlacement: builder.mutation({
      query: (body: { sponsorId: number; placementId: number; connect: Boolean }) => ({
        url: '/sponsor/placement',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['SingleSponsor'],
    }),
    updateSponsorPlacement: builder.mutation({
      query: ({
        id,
        body,
      }: {
        id: number | string;
        body: { name: string; sponsors: { [key: string]: any }[] };
      }) => ({
        url: `/sponsorplacement/update/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['SponsorPlacements'],
    }),
    deleteSponsorPlacement: builder.mutation({
      query: (id: number | string) => ({
        url: `/sponsorplacement/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['SponsorPlacements'],
    }),
    addSponsorPlacements: builder.mutation({
      query: (body: { name: string }) => ({
        url: '/sponsorplacement/new',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['SponsorPlacements'],
    }),
    getSponsorPlacements: builder.query({
      query: () => ({
        url: '/sponsorplacement/all',
        method: 'GET',
      }),
      providesTags: ['SponsorPlacements'],
    }),
    archiveSponsor: builder.mutation({
      query: (id) => ({
        url: `/sponsor/archive/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Sponsor', 'Unarchived', 'SingleSponsor'],
    }),
    getAllSponsors: builder.query({
      query: (page) => ({
        url: `/sponsor/all?page=${page}`,
        method: 'GET',
      }),
      providesTags: ['Sponsor'],
    }),
    createSponsor: builder.mutation({
      query: (data) => ({
        url: '/sponsor/new',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Sponsor'],
    }),
    getSingleSponsorInfo: builder.query({
      query: (id) => ({
        url: `/sponsor/info/${id}`,
        method: 'GET',
      }),
      providesTags: ['SingleSponsor'],
    }),
    updateSponsor: builder.mutation({
      query: (data) => ({
        url: `/sponsor/update/${data.id}`,
        method: 'PUT',
        body: data.body,
      }),
      invalidatesTags: ['Sponsor', 'SingleSponsor'],
    }),
    // searchSponsorByName: builder.mutation({
    //   query: (data) => ({
    //     url: `/sponsor/by-name?name=${data}`,
    //     method: 'GET',
    //   }),
    // }),
    searchSponsorByName: builder.query({
      query: (params: { sponsor?: string }) => ({
        url: '/sponsor/by-name',
        method: 'GET',
        params,
      }),
    }),
  }),
});

export const {
  useGetSponsorByPlacementQuery,
  useAddOrRemovePlacementMutation,
  useUpdateSponsorPlacementMutation,
  useDeleteSponsorPlacementMutation,
  useAddSponsorPlacementsMutation,
  useGetSponsorPlacementsQuery,
  useArchiveSponsorMutation,
  // useSearchSponsorByNameMutation,
  useLazySearchSponsorByNameQuery,
  useGetAllSponsorsQuery,
  useCreateSponsorMutation,
  useGetSingleSponsorInfoQuery,
  useUpdateSponsorMutation,
} = sponsorApiSlice;
