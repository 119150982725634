import { ErrorMessage, Field, Form, Formik, FormikValues } from 'formik';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../slices/auth';
import { useGetUsernamesByUserByIdQuery } from '../../../slices/userApiSlice';
import { UserModel } from '../../listview/users/model/user.model';
import { useEffect, useState } from 'react';
import { LoadingSceletonMyAccount } from '../../../routes/tournaments/routes/all/loading-sceleton-myaccount';
import {
  useDeleteStudentInGameNameByIdMutation,
  useUpdateStudentInGameNameByIdMutation,
} from '../../../slices/gameApiSlice';
import { CiCircleMinus } from 'react-icons/ci';
import { UsernamesSettingsValidationSchema } from '../../authentication/yupschema';
import useSuccessToast from '../../../hooks/use-success-toast';
import { UserRoles } from '../../../utils/constants';
import { Link, Outlet } from 'react-router-dom';

interface IUsernamesSettings {
  game: { name: string };
  in_game_name: string;
  discord_name: string;
  game_id: number;
  user_id: number;
}

const Usernames = () => {
  const user = useSelector(selectUser);
  const [updateInfo, { isSuccess: updateIsSuccess }] = useUpdateStudentInGameNameByIdMutation();
  const [deleteInGame] = useDeleteStudentInGameNameByIdMutation();
  const { data: userData, isLoading: isLoading } = useGetUsernamesByUserByIdQuery('');
  // const model = new UserModel(user);

  const onSubmit = (values: FormikValues) => {
    const data = [...values?.game];
    const fieldsToUpdate = data?.map((d: IUsernamesSettings) => {
      return {
        game_id: d?.game_id,
        in_game_name: d?.in_game_name,
        discord_name: d?.discord_name,
      };
    });
    updateInfo(fieldsToUpdate);
  };

  const initialValues = {
    game: userData?.InGameName?.map((item: any) => ({
      ...item,
    })),
  };

  const handleRemove = (data: { user_id: number; game_id: number }) => {
    const { user_id, game_id } = data;
    if (user_id && game_id) deleteInGame({ game_id: game_id });
  };

  useSuccessToast(updateIsSuccess, 'Success');
  if (isLoading) return <LoadingSceletonMyAccount />;
  // if (isSuccess) return <Navigate to='./success' />;
  return (
    <>
      <div className=' flex justify-between font-bold'>
        <h1 className='text-3xl'>My Usernames</h1>
        <div className='flex justify-end pr-12	'>
          <Link
            to={'./add'}
            className='w-2/7 pl-7 pr-7 py-2 shadow-lg border-2 font-semibold rounded-lg'
            // disabled={isSubmitting}
          >
            Add Username +
          </Link>
        </div>
      </div>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        enableReinitialize
        validationSchema={user.roles == UserRoles.Student ? UsernamesSettingsValidationSchema : ''}
      >
        {({ values }) => (
          <Form>
            <div>
              <div className='w-[90%] m-auto flex flex-col gap-5'>
                {values?.game?.length ? (
                  values?.game?.map((d: IUsernamesSettings, index: number) => (
                    <div className='flex flex-col gap-3' key={d?.game_id}>
                      <div>
                        <h4 className='text-[#000000] font-600 text-[20px]'>{d?.game?.name}</h4>
                      </div>
                      <div className='w-full flex justify-between gap-5'>
                        <div className='w-full flex flex-col'>
                          <div className='resetInput'>
                            <label className='resetLabel group' htmlFor='email'>
                              {user.roles == UserRoles.Student
                                ? 'In-Game Name (Required)'
                                : 'In-Game Name'}
                            </label>
                            <Field
                              name={`game.${index}.in_game_name`}
                              placeholder='Enter your username for this specific game title.'
                              type='text'
                              className='resetFormInput'
                            />
                          </div>
                          <ErrorMessage
                            name={`game.${index}.in_game_name`}
                            component='span'
                            className='error'
                          />
                        </div>
                        <div className='resetInput'>
                          <label className='resetLabel group' htmlFor='email'>
                            Discord Name (Optional)
                          </label>
                          <Field
                            name={`game.${index}.discord_name`}
                            placeholder='Enter your discord name'
                            type='text'
                            className='resetFormInput'
                          />
                        </div>
                        <div className='self-center'>
                          <CiCircleMinus
                            color='red'
                            onClick={() => handleRemove(d)}
                            className='transition-transform duration-300 ease-in-out transform hover:scale-110 cursor-pointer'
                            size={20}
                          />
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div>
                    <p className='text-md'>No data available.</p>
                  </div>
                )}
              </div>
            </div>
            {userData?.InGameName?.length ? (
              <div className='flex justify-end mr-12 pr-12	'>
                <button
                  className='w-2/7 my-5 pl-7 pr-7  py-2 bg-gray-700 shadow-lg  text-white font-semibold rounded-lg'
                  // disabled={isSubmitting}
                  type='submit'
                >
                  Save Changes
                </button>
              </div>
            ) : null}
          </Form>
        )}
      </Formik>
      <Outlet />
    </>
  );
};

export default Usernames;
