import { apiSlice } from '../api/apiSlice';
import { UpdateOrgInterface } from '../utils/custom-types';
import { addDashboardCoaches, initDashboardCoaches } from './userSlice';

export const organizationApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    deleteOgma: builder.mutation({
      query: (id: string | number) => ({
        url: `/organization/ogma/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['OrgOgmas'],
    }),
    getAllOrganizationOgmas: builder.query({
      query: (id: string) => ({
        url: `/organization/ogmas/${id}`,
        method: 'GET',
      }),
      providesTags: ['OrgOgmas'],
    }),
    orgExportList: builder.mutation({
      query: (params) => ({
        url: '/organization/export/',
        method: 'GET',
        params,
        responseHandler: async (response) => {
          if (
            response.status === 200 &&
            response.headers.get('content-type')?.includes('text/csv')
          ) {
            window.open(
              window.URL.createObjectURL(await response.blob()),
              '_blank',
              'noopener,noreferrer',
            );
          } else {
            console.error('Unexpected content type or response status');
          }
        },

        cache: 'no-cache',
      }),
    }),

    // TODO: add optimistic updates
    addOneCoach: builder.mutation({
      query: (body: { coach_id: number; org_id: number }) => ({
        url: '/organization/add-coach',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['OrgCoaches'],
    }),
    getOrgCoachesPage: builder.query({
      query: (data: { org_id: number; page?: number }) => ({
        url: `/organization/coaches-page/${data.org_id}?page=${data.page ?? 1}`,
        method: 'GET',
      }),

      providesTags: ['OrgCoaches'],
    }),
    removeCoachFromOrg: builder.mutation({
      query: (body: { coach_id: number; organization_id: number }) => ({
        url: '/organization/coaches/',
        method: 'DELETE',
        body,
      }),
      invalidatesTags: ['OrgCoaches'],
    }),
    getOrgCoaches: builder.query({
      query: (body: { id: string; page?: number }) => ({
        url: `/organization/coaches/${body.id}?page=${body.page ?? 1}`,
        method: 'GET',
      }),
      async onCacheEntryAdded(arg, { cacheDataLoaded, dispatch, getCacheEntry }) {
        await cacheDataLoaded;
        const entries = getCacheEntry();
        if (arg.page) dispatch(addDashboardCoaches(entries.data.data));
        else dispatch(initDashboardCoaches(entries.data.data));
      },
      providesTags: ['OrgCoaches'],
    }),
    newOrganization: builder.mutation({
      query: (body: {
        name: string;
        street_address: string;
        city: string;
        state: string;
        zip_code: number;
        email: string;
        phone_number: string;
        tags: string;
        status: number;
        coach?: { id: number }[];
      }) => ({
        url: '/organization/new-org',
        method: 'POST',
        body,
      }),

      invalidatesTags: ['Organization', 'School'],
    }),
    getAllOrganizations: builder.query({
      query: (query) => ({
        url: '/organization/all',
        // ?${query.query}=${query.param}&page=${query.page ? query.page : 1}`,
        method: 'GET',
        params: query,
      }),
      providesTags: ['Organization'],
    }),
    createNewOrganization: builder.mutation({
      query: (body) => ({
        url: '/organization/new',
        method: 'Post',
        body,
      }),
      invalidatesTags: ['Organization', 'School'],
    }),
    getOrgWithoutOgma: builder.query({
      query: () => ({
        url: '/organization/unadmin',
        method: 'Get',
      }),
      providesTags: ['Organization', 'School'],
    }),
    getOrgById: builder.query({
      query: ({ id, query }) => ({
        url: `/organization/id/${id}?query=${query ?? undefined}`,
        method: 'Get',
      }),
      providesTags: ['Organization'],
    }),
    updateOrganization: builder.mutation({
      query: ({ id, body }: { id: string | number; body: UpdateOrgInterface }) => ({
        url: `/organization/id/${id}`,
        method: 'put',
        body,
      }),
      invalidatesTags: ['Organization'],
    }),
    archiveOrganization: builder.mutation({
      query: (id) => ({
        url: `/organization/archive/${id}`,
        method: 'post',
      }),
      onQueryStarted: async (_, api) => {
        try {
          await api.queryFulfilled;
          api.dispatch(apiSlice.util.resetApiState());
        } catch (error) {
          console.log(error);
        }
      },
    }),
    // uploadImage: builder.mutation({
    //   query: (image) => ({
    //     url: '/organization/upload-image',
    //     method: 'POST',
    //     body: image,
    //   }),
    // }),
    changeOrganizationProfilePhoto: builder.mutation({
      query: ({ photo, id }) => ({
        url: `/organization/profile-photo/${id}`,
        method: 'PUT',
        body: photo,
      }),
      invalidatesTags: ['Organization'],
    }),
    // getAllArchivedOrganizations: builder.query({
    //   query: () => ({
    //     url: '/organization/archived-org',
    //     method: 'Get',
    //   }),
    //   providesTags: ['Organization'],
    // }),
    // unarchiveOrganization: builder.mutation({
    //   query: (id) => ({
    //     url: `organization/unarchive/${id}`,
    //     method: 'post',
    //   }),
    //   invalidatesTags: ['Organization'],
    // }),
  }),
});

export const {
  useAddOneCoachMutation,
  useGetOrgCoachesPageQuery,
  useRemoveCoachFromOrgMutation,
  useGetOrgCoachesQuery,
  useNewOrganizationMutation,
  useGetAllOrganizationsQuery,
  useCreateNewOrganizationMutation,
  useGetOrgWithoutOgmaQuery,
  useGetOrgByIdQuery,
  useUpdateOrganizationMutation,
  useArchiveOrganizationMutation,
  useChangeOrganizationProfilePhotoMutation,
  // useUploadImageMutation,
  useOrgExportListMutation,
  // useGetAllArchivedOrganizationsQuery,
  // useUnarchiveOrganizationMutation,
  useGetAllOrganizationOgmasQuery,
  useDeleteOgmaMutation,
  useLazyGetAllOrganizationsQuery,
} = organizationApiSlice;
