import * as yup from 'yup';
export const StudentBookingHoursSchema = yup.object().shape({
  start_time: yup
    .date()
    .test('test', 'Please correct start time!', (value, ctx) => {
      if (!value) return false;
      const startLimit: Date = ctx.parent.startLimit;
      const endLimit: Date = ctx.parent.endLimit;
      startLimit.setFullYear(new Date(value).getFullYear());
      startLimit.setMonth(new Date(value).getMonth());
      startLimit.setDate(new Date(value).getDate());
      endLimit.setFullYear(startLimit.getFullYear());
      endLimit.setMonth(startLimit.getMonth());
      if (endLimit.getHours() < startLimit.getHours()) endLimit.setDate(startLimit.getDate() + 1);
      else endLimit.setDate(startLimit.getDate());
      return new Date(value) > startLimit && new Date(value) < endLimit;
    })
    .required('Required'),
  end_time: yup
    .string()
    .test('test', 'Please correct end time!', (value, ctx) => {
      if (!value) return false;
      return new Date(value) > ctx.parent.start_time && new Date(value) < ctx.parent.endLimit;
    })
    .required('Required'),
});
