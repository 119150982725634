import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useParams, useSearchParams } from 'react-router-dom';
import { useLazyInitGamearenaQuery } from '../../../../slices/gamearenaApiSlice';
import {
  addToStreamerLiveVideos,
  addToStreamerRecentVideos,
  selectStreamerLiveVideos,
  selectStreamerRecentVideos,
  selectTwitchToken,
  setStreamerLiveVideos,
  setStreamerRecentVideos,
} from '../../../../slices/gamearenaSlice';
import {
  useLazyGetRecentVideosQuery,
  useLazyGetTwitchByUserIdQuery,
  useValidateTwitchTokenMutation,
} from '../../../../slices/twitchApiSlice';
import { useGetStreamerByIdQuery } from '../../../../slices/userApiSlice';
import { GaRecentVideoCard } from '../../component/ga-recent-video-card';
import LiveMatchesCard from '../../component/live-matches-card';
import { CustomError } from '../../../../utils/custom-types';
import { selectRole } from '../../../../slices/auth';
import { UserRoles } from '../../../../utils/constants';

const GamearenaStreamerPage = () => {
  const params = useParams();
  const [recentCursor, setRecentCursor] = useState<any>();
  const [liveCursor, setLiveCursor] = useState<any>();
  const { error, data, isLoading, isError, isSuccess } = useGetStreamerByIdQuery(params?.id!, {
    skip: !params.id,
  });
  const [getTwitchByUserId, { data: lvData, isLoading: lvIsLoading }] =
    useLazyGetTwitchByUserIdQuery();
  const [getRecentVideos, { data: rcData, isLoading: rcIsLoading }] = useLazyGetRecentVideosQuery();
  const twitchToken = useSelector(selectTwitchToken);
  const liveVideos = useSelector(selectStreamerLiveVideos);
  const recentVideos = useSelector(selectStreamerRecentVideos);
  const dispatch = useDispatch();
  const [validateTwitchToken, { data: tkData, isLoading: tkIsLoading, isError: tkIsError }] =
    useValidateTwitchTokenMutation();
  const [initGamearena, { data: igData }] = useLazyInitGamearenaQuery();
  const [validated, setValidated] = useState(false);
  const role = useSelector(selectRole);
  useEffect(() => {
    validateTwitchToken('')
      .unwrap()
      .then(() => setValidated(true))
      .catch((e) => initGamearena(''));
  }, [twitchToken]);
  useEffect(() => {
    if (!data || !validated) return;
    getTwitchByUserId({ query: `?user_id=${data.twitch_id}` })
      .unwrap()
      .then((res) => {
        dispatch(setStreamerLiveVideos(res.data));
        setLiveCursor(res.pagination);
      });
    getRecentVideos({ query: `?user_id=${data.twitch_id}`, limit: '&first=10' })
      .unwrap()
      .then((res) => {
        dispatch(setStreamerRecentVideos(res.data));
        setRecentCursor(res.pagination);
      });
  }, [validated, data]);
  const nextLive = () => {
    getTwitchByUserId({
      query: `?user_id=${data.twitch_id}`,
      limit: '&first=10',
      pagination: `&after=${recentCursor.cursor}`,
    })
      .unwrap()
      .then((res) => {
        dispatch(addToStreamerLiveVideos(res.data));
        setLiveCursor(res.pagination);
      });
  };
  const nextRecent = () => {
    getRecentVideos({
      query: `?user_id=${data.twitch_id}`,
      limit: '&first=1',
      pagination: `&after=${recentCursor.cursor}`,
    })
      .unwrap()
      .then((res) => {
        dispatch(addToStreamerRecentVideos(res.data));
        setRecentCursor(res.pagination);
      });
  };
  const [searchparams, setSearchParams] = useSearchParams();
  //allow GameArena routes
  if (role !== UserRoles.Admin) {
    return <Navigate to='../dashboard' />;
  }
  if ((isSuccess && !data.twitch_id) || isError)
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError).data.message }} />;
  if (isLoading || lvIsLoading || rcIsLoading || tkIsLoading) return <p>Loading...</p>;
  return (
    <>
      <div className='listViewContainer h-[calc(100vh-85px)] p-0 overflow-auto flex flex-col items-center'>
        <h2 className='font-bold text-xl'>{`${data.firstName} ${data.lastName}'s videos`}</h2>
        <div className='flex flex-col w-full px-8'>
          <h2 className='font-bold text-xl'>Live stream</h2>
          <div className='flex flex-wrap w-full'>
            {liveVideos.length ? (
              liveVideos?.map((video: any) => (
                <LiveMatchesCard key={video.id} {...video} source={video.thumbnail_url} />
              ))
            ) : (
              <p>No live stream.</p>
            )}
            {/* <div className='flex w-full justify-center gap-2 items-center'>
              <span
                className='cursor-pointer'
                onClick={
                  lvData?.pagination.hasOwnProperty('cursor') ? () => nextLive() : () => null
                }
              >
                {lvData?.pagination.hasOwnProperty('cursor') ? 'Show More' : 'No more to show'}
              </span>
            </div> */}
          </div>
          <h2 className='font-bold text-xl'>Recent Broadcasts</h2>
          <div className='flex flex-wrap w-full gap-2'>
            {recentVideos.length ? (
              recentVideos?.map((video: any) => <GaRecentVideoCard key={video.id} {...video} />)
            ) : (
              <p>No recent broadcasts.</p>
            )}
          </div>
          <div className='flex w-full justify-center gap-2 items-center'>
            <span
              className='cursor-pointer'
              onClick={
                rcData?.pagination.hasOwnProperty('cursor') ? () => nextRecent() : () => null
              }
            >
              {rcData?.pagination.hasOwnProperty('cursor') ? 'Show More' : 'No more to show'}
            </span>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default GamearenaStreamerPage;
