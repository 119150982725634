import { OutsideClick } from "./outside-click"
import { AiOutlineClose } from "react-icons/ai"
import Button from "./button/Button"
import { useCallback } from "react"
import { useDispatch } from "react-redux"
import { apiSlice } from "../api/apiSlice"
import { AppDispatch } from "../store"
import { changeLogOut, logOut as logout } from '../slices/auth'
import { SubmitButton } from "./submit-button"


const ConfirmLogout = () => {
    const dispatch = useDispatch<AppDispatch>();

    const logOut = useCallback(() => {
        dispatch(apiSlice.util.resetApiState());
        dispatch(logout());
    }, [dispatch]);


    return (
        <>
            <OutsideClick setFunc={() => dispatch(changeLogOut(false))} zIndex="z-20" />
            <div className="fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 w-auto mx-auto max-w-2xl bg-white p-10 flex flex-col gap-10 items-center justify-center rounded-xl z-50">
                <div className='absolute top-2 right-2 cursor-pointer' onClick={() => dispatch(changeLogOut(false))}>
                    <AiOutlineClose size={'2rem'} className='hover:rounded-full hover:bg-gray-100 p-2' />
                </div>
                <div className='py-4 flex flex-col justify-center items-center gap-8'>
                    <span>
                        Are you sure you want to <span className='font-semibold'>logout</span>?
                    </span>
                    <div className='flex gap-2'>
                        <SubmitButton onSubmit={() => { logOut(); dispatch(changeLogOut(false)) }} title='Confirm' />
                        <span onClick={() => dispatch(changeLogOut(false))} className="p-4 px-8 text-center text-sm py-2 bg-white text-black border font-semibold rounded-lg hover:bg-gray-100 cursor-pointer">
                            Cancel
                        </span>
                        {/* <Button onClick={() => dispatch(changeLogOut(false))} text='Cancel' /> */}
                    </div>
                </div>
            </div>
        </>
    )
}
export default ConfirmLogout