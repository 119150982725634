import React from 'react';
import { FaSchool } from 'react-icons/fa';
import { MdOutlineSportsEsports } from 'react-icons/md';
import { SchoolModel } from '../../../../model/school/school-model';
export function SchoolInfo({
  model,
  name,
  organization,
  members,
  status,
}: {
  model: SchoolModel;
  name: string;
  organization: string;
  members: number;
  status: string;
}) {
  return (
    <div className='flex flex-col flex-[3]'>
      <h2 className='font-bold text-white text-xl max-w-[15ch] truncate'>{name}</h2>
      <hr />
      <span className='text-sm text-white font-extralight'>{members} members</span>
      <hr />
      {/* <div className="flex text-xs font-bold gap-3">
        <MdOutlineSportsEsports /> {game}{" "}
      </div> */}
      <div className='flex text-sm gap-3 text-white'>
        <FaSchool />
        <div className='max-w-[15ch] truncate'>{organization}</div>
      </div>
      <hr />
      <span className='flex justify-between'>
        <h2 className='font-bold text-white'>{status}</h2>
        <h2 className='font-bold text-white'>{model?.schoolState}</h2>
      </span>

      {/* {status == 1 && <div>Active</div>} */}
      {/* {status == 0 && <div>Deactive</div>} */}
    </div>
  );
}
