import { Form, Formik, FormikValues } from 'formik';
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { FormInput } from '../../../../../../components/form-input/form-input';
import { OutsideClick } from '../../../../../../components/outside-click';
import {
  useCreateSchoolInvoiceModalMutation,
  useGetNewInvoiceHelpDataQuery,
} from '../../../../../../slices/invoicesApiSlice';
import {
  NewOrganizationInvoiceSchema,
  newOgmaInvoiceCoachHoursSchema,
} from '../new/schema/new-organization-invoice-v-schema';
import {
  InvoiceStatus,
  InvoiceStatusNames,
  InvoiceTypes,
  InvoiceTypesNames,
} from '../../../../../../utils/constants';
import ModalHeader from '../../../../../../components/modal-header';
import ModalFooter from '../../../../../../components/modal-footer';
import { addCurrentTimeToDate } from '../../../../../../utils/dateFormatter';

const NewInvoiceOrganization = () => {
  const [chosenSchool, setChosenSchool] = useState<number>();

  const [newOrgInvoice, { isError, isSuccess, isLoading }] = useCreateSchoolInvoiceModalMutation();
  const { data, isLoading: isDataLoading } = useGetNewInvoiceHelpDataQuery('');
  const [invoiceType, setInvoiceType] = useState<number>(9);

  const formInputs = [
    // {
    //   mainTitle: "Billing Information",
    //   name: "type",
    //   label: "Invoice Type",
    //   type: "number",
    //   placeholder: "Select subscription plan",
    // },
    {
      mainTitle: 'Billing Information',
      name: 'organization',
      label: 'Organization',
      type: 'text',
      placeholder: "Select organization's name",
      isSelect: true,
      options: data?.organization ?? [],
    },
    {
      mainTitle: 'Billing Information',
      name: 'school',
      label: 'School',
      type: 'text',
      placeholder: "Select school's name",
      isSelect: true,
      options:
        chosenSchool && data?.organization?.filter((o: any) => o.id === chosenSchool)[0].schools
          ? data?.organization?.filter((o: any) => o.id === chosenSchool)[0].schools
          : [],
    },
    {
      mainTitle: 'Invoice Information',
      name: 'type',
      label: 'Invoice type',
      type: 'number',
      placeholder: 'Enter type of incoive',
      isTrue: true,
      isSelect: true,
      options: [
        { id: InvoiceTypes.CoachingHours, name: InvoiceTypesNames[InvoiceTypes.CoachingHours] },
        {
          id: InvoiceTypes.Stadium,
          name: InvoiceTypesNames[InvoiceTypes.Stadium],
        },
        // {
        //   id: InvoiceTypes.Student,
        //   name: InvoiceTypesNames[InvoiceTypes.Student],
        // },
        // {
        //   id: InvoiceTypes.StudentsPart,
        //   name: InvoiceTypesNames[InvoiceTypes.StudentsPart],
        // },
      ],
    },
    {
      mainTitle: 'Invoice Information',
      name: 'status',
      label: 'Invoice status',
      type: 'number',
      placeholder: 'Enter status of invoice',
      isTrue: true,
      isSelect: true,
      options: [
        { id: InvoiceStatus.Upcoming, name: InvoiceStatusNames[InvoiceStatus.Upcoming] },
        { id: InvoiceStatus.Paid, name: InvoiceStatusNames[InvoiceStatus.Paid] },
        { id: InvoiceStatus.Overdue, name: InvoiceStatusNames[InvoiceStatus.Overdue] },
        { id: InvoiceStatus.Unpaid, name: InvoiceStatusNames[InvoiceStatus.Unpaid] },
        { id: InvoiceStatus.Processing, name: InvoiceStatusNames[InvoiceStatus.Processing] },
      ],
    },

    {
      mainTitle: 'Invoice Information',
      name: 'start_date',
      label: 'Invoice date',
      type: 'date',
      placeholder: 'Enter invoice date',
      isTrue: true,
    },
    {
      mainTitle: 'Invoice Information',
      name: 'end_date',
      label: 'Invoice due date',
      type: 'date',
      placeholder: 'Enter invoice due date',
      isTrue: true,
    },
    // {
    //   mainTitle: "Invoice Information",
    //   name: "numOfPlayers",
    //   label: "Number of students",
    //   type: "number",
    //   placeholder: "Enter number of active players",
    // },
    {
      mainTitle: 'Invoice Information',
      name: 'price_student',
      label: 'Price per student',
      type: 'number',
      placeholder: 'Enter price per student',
      isTrue: true,
      invoiceType: [InvoiceTypes.Student, InvoiceTypes.StudentsPart, 66],
    },
    // {
    //   mainTitle: "Invoice Information",
    //   name: "payment_percentage",
    //   label: "Payment Percentage",
    //   type: "number",
    //   placeholder: "Enter percentage covered by organization",
    // },
    {
      mainTitle: 'Invoice Information',
      name: 'total',
      label: 'Total',
      type: 'number',
      placeholder: 'Enter total',
      isTrue: true,
    },
    {
      mainTitle: 'Invoice Information',
      name: 'coachHours',
      label: 'Coach Hours',
      type: 'number',
      placeholder: 'Enter coach hours',
      isTrue: true,
      invoiceType: [InvoiceTypes.CoachingHours],
    },
  ];
  const onSubmit = (values: FormikValues) => {
    newOrgInvoice({
      total: values.total,
      status: +values.status,
      type: +values.type,
      // payment_percentage:values.payment_percentage,
      // price_student: values.price_student,
      // end_date: new Date(values.end_date).toISOString(),
      // start_date: new Date(values.start_date).toISOString(),
      end_date: addCurrentTimeToDate(values.end_date),
      start_date: addCurrentTimeToDate(values.start_date),
      // organization:values.organization,
      school: +values.school,
      coachHours: +values.coachHours,
      // type:values.type
    });
  };
  const [schema, setSchema] = useState(NewOrganizationInvoiceSchema);
  useEffect(() => {
    if (invoiceType !== InvoiceTypes.CoachingHours) setSchema(NewOrganizationInvoiceSchema);
    else setSchema(newOgmaInvoiceCoachHoursSchema);
  }, [invoiceType]);
  const initialValues: { [key: string]: string } = {};
  formInputs.forEach((input) => (initialValues[input.name] = ''));
  if (isSuccess) return <Navigate to='../' />;
  if (isLoading || isDataLoading) return <p>Loading ...</p>;
  return (
    <>
      <OutsideClick />
      <Formik validationSchema={schema} initialValues={initialValues} onSubmit={onSubmit}>
        {({ values }) => {
          // TODO: This needs to be fixed: 'React Hook "useEffect" cannot be called inside a callback. React Hooks must be called in a React function component or a custom React Hook function.'
          useEffect(() => {
            if (!values) return;
            setChosenSchool(+values.organization);
            setInvoiceType(+values.type);
          }, [values]);
          return (
            <Form>
              <div className='fixed h-fit top-0 left-0 bottom-0 right-0 m-auto z-10 w-auto flex items-center justify-center  max-w-2xl'>
                <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-[90vh]'>
                  <ModalHeader name={'New Invoice'} />
                  <div className='relative px-3 flex-auto overflow-auto'>
                    {formInputs.map((input: any, i: number) => (
                      <React.Fragment key={i}>
                        {!formInputs[i - 1] || formInputs[i - 1]?.mainTitle !== input.mainTitle ? (
                          <>
                            <div className='flex flex-col'>
                              <div className='mb-1'>
                                <p className='text-gray-800 font-bold pt-6'>{input.mainTitle}</p>
                                <hr />
                              </div>
                            </div>
                            <div className='flex flex-wrap gap-1'>
                              {formInputs
                                .filter((inp1) => inp1.mainTitle === input.mainTitle)
                                .filter((inp) =>
                                  inp.hasOwnProperty('invoiceType')
                                    ? inp?.invoiceType?.includes(invoiceType)
                                    : true,
                                )
                                .map((inp, i: number) => (
                                  <React.Fragment key={i}>
                                    <div className={inp.isTrue ? 'flex-1 min-w-[300px]' : 'w-full'}>
                                      <FormInput {...inp} />
                                    </div>
                                  </React.Fragment>
                                ))}
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                  <ModalFooter isLoading={isLoading!} />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default NewInvoiceOrganization;
