import * as yup from 'yup';
import { Formik } from 'formik';
import { useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import {
  useGetSubDivisionByIdQuery,
  useUpdateSubDivisionMutation,
} from '../../../../slices/subDivisionApiSlice';
import { useGetNewTRequiredDataQuery } from '../../../../slices/tournamentApiSlice';
import { OutsideClick } from '../../../../components/outside-click';
import ModalContent from '../../../../components/modal-content';
import { EditSubDivisionValidationSchema } from '../../../../features/authentication/yupschema';
import { CustomError } from '../../../../utils/custom-types';

function EditSubDivision() {
  const param = useParams();
  const [avatarImage, setAvatarImage] = useState<any>();
  const [chosenCategoryDivision, setChosenCategoryDivision] = useState<number>();

  const {
    error,
    data: subDivisionData,
    isLoading: subDivisionIsLoading,
    isError: subDivisionIsError,
    isSuccess: subDivisionIsSuccess,
  } = useGetSubDivisionByIdQuery(param.id);

  const [updateSubDivision, { isLoading, isError, isSuccess }] = useUpdateSubDivisionMutation();
  const { data: tRequiredData, isLoading: isDataLoading } = useGetNewTRequiredDataQuery('');

  const formInputs = [
    {
      mainTitle: 'Subdivision Information',
      name: 'name',
      label: 'Subdivision Name',
      type: 'text',
      placeholder: 'Enter subdivision name',
      isTrue: true,
    },
    {
      mainTitle: 'Subdivision Information',
      name: 'division_id',
      label: 'Category Division Name',
      type: 'text',
      placeholder: 'Enter category division name',
      isTrue: true,
      isSelect: true,
      options: tRequiredData?.division ? tRequiredData.division : [],
    },
    {
      mainTitle: 'Subdivision Information',
      name: 'background_image',
      label: 'Background Image',
      type: 'image',
      placeholder: 'Upload background image',
      isTrue: true,
    },
  ];

  const onSubmit = (values: any) => {
    const data = { ...values };
    data.division_id = +data.division_id;
    data.image = avatarImage;
    data.background_image = subDivisionData.background_image;

    const formData = new FormData();
    Object.entries(data).forEach(([key, value]: any[]) => {
      if (value) formData.append(key, value);
    });

    updateSubDivision({ id: param.id, body: formData })
      .unwrap()
      .then((res: any) => console.log(res))
      .catch((error) => console.log(error));
  };

  if (subDivisionIsError || (subDivisionIsSuccess && !subDivisionData))
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError).data.message }} />;
  if (subDivisionIsLoading) return <p>Loading...</p>;
  if (isSuccess) return <Navigate to='../' />;
  return (
    <>
      <OutsideClick />
      <Formik
        validationSchema={EditSubDivisionValidationSchema.shape({
          background_image: yup
            .mixed()
            .nullable()
            .test(
              'test2',
              'Invalid image format. Only JPG, PNG, and JPEG formats are allowed.',
              () => {
                const acceptedFormats = ['image/jpeg', 'image/png', 'image/jpg'];
                if (avatarImage && !acceptedFormats.includes(avatarImage?.type ?? '')) {
                  return false;
                }
                return true;
              },
            ),
        })}
        initialValues={subDivisionData}
        onSubmit={onSubmit}
      >
        {({ errors }) => {
          return (
            <ModalContent
              errors={Object.keys(errors).length}
              setAvatarImage={setAvatarImage}
              formInputs={formInputs}
              isLoading={isLoading}
              extraClass='max-h-[90vh]'
              name={`Edit Subdivision - ${subDivisionData.name}`}
            />
          );
        }}
      </Formik>
    </>
  );
}

export default EditSubDivision;
