import { Form, Formik, FormikValues } from 'formik';
import React from 'react';
import { FormInput } from '../../../../../components/form-input/form-input';
import ModalFooter from '../../../../../components/modal-footer';
import { NewSponsorPlacementVSchema } from './schema/new-sponsor-placement.schema';
import { useAddSponsorPlacementsMutation } from '../../../../../slices/sponsorApiSlice';
import { Navigate } from 'react-router';

const NewSponsorPlacement = () => {
  const formInputs = [
    {
      mainTitle: 'Placement Information',
      name: 'name',
      label: 'Enter name',
      type: 'text',
      placeholder: 'Enter name of placement',
    },
  ];

  function handleSubmit(values: FormikValues) {
    submit({ name: values.name });
  }
  const [submit, { isSuccess, isLoading, isError }] = useAddSponsorPlacementsMutation();

  const initialValues: { [key: string]: string } = {};
  formInputs.forEach((input) => (initialValues[input.name] = ''));

  if (isSuccess) return <Navigate to='../' />;
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={NewSponsorPlacementVSchema}
    >
      <Form>
        {formInputs.map((inp: any, i: number) => (
          <div key={i} className='flex-1 min-w-[300px]'>
            <FormInput {...inp} />
          </div>
        ))}
        {isError ? <p className='text-red-700'>Something went wrong!</p> : <></>}
        <ModalFooter isLoading={isLoading} />
      </Form>
    </Formik>
  );
};

export default NewSponsorPlacement;
