export function ResultScreenshotRow({ username = '', ht_result = 0, aw_result = 0, url = '' }) {
  return (
    <div className='flex w-full justify-between rounded-lg border-2 bg-gray-100  p-2 py-5'>
      <p className='text-sm'>
        <span className='font-bold'>{username}</span> claims that the score is
        <span>
          {' '}
          {ht_result} - {aw_result}{' '}
        </span>
      </p>
      <span className='text-sm flex gap-5'>
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={url}
          className='underline text-black
                        '
        >
          View Attachments
        </a>
        {/* <span className="font-sm text-gray-500">10 minutes ago</span> */}
      </span>
    </div>
  );
}
