import { useEffect } from 'react';
import { NavLink, Outlet, useNavigate, useParams } from 'react-router-dom';
import { useGetCoachByIdQuery } from '../../../slices/userApiSlice';
import CoachHeader from './coach-header';
import CanIView from '../../../components/can-i-view/can-i-view';

const ViewCoach = () => {
  const params = useParams();
  const {
    data: userData,
    isLoading: userIsLoading,
    isError: userIsError,
    isSuccess: userIsSuccess,
  } = useGetCoachByIdQuery(params.id!, { skip: !params.id });

  const buttonList = [
    {
      name: 'Overview',
      link: './overview',
      permission: 'overview',
    },
    // FIXME: UNCOMMENT TO ENABLE CLASSROOMS
    // {
    //   name: 'Classrooms',
    //   link: './classrooms',
    //   permission: 'classrooms',
    // },
    // {
    //   name: 'Training Content',
    //   link: './training',
    //   permission: 'trainingContent',
    // },
  ];

  const navigate = useNavigate();
  useEffect(() => {
    navigate('./overview');
  }, []);

  if (userIsLoading) return <p>Loading...</p>;
  return (
    <div className='listViewContainer fullPage border-1'>
      <CoachHeader />
      <div className='flex  p-2 bg-white'>
        {buttonList.map((b) => (
          <CanIView key={b.link} component={`coach.nav.${b.permission}`}>
            <NavLink to={b.link} className='settingsLink border-b-2'>
              <div> {b.name} &nbsp;</div>
            </NavLink>
          </CanIView>
        ))}
      </div>
      <Outlet />
    </div>
  );
};

export default ViewCoach;
