
export function GameArenaLoadingSceleton() {
  return (
    <div className='flex relative rounded-xl border-1 bg-white h-[calc(97h-90px)] p-0 px-3 gap-3'>
      <div
        className='w-[350px]
                    bg-slate-300 h-[calc(97vh-90px)] p-3 px-2 rounded-xl pt-8 relative my-4 skeleton__pulse'
      ></div>
      <div className='flex flex-col w-full h-full relative rounded-xl'>
        <div className='relative rounded-xl m-auto bg-slate-300 aspect-video sm:max-w-[700px] lg:max-w-[73%] lg:min-w-[73%] px-10 p-32 my-4 skeleton__pulse'></div>
        <div className='bg-slate-300 relative w-full sm:max-w-[700px] lg:max-w-[84%] m-auto rounded-xl border-1 h-fit mt-24 min-h-[300px] px-10 p-32 skeleton__pulse'></div>
      </div>
    </div>
  );
}
