import { apiSlice } from '../api/apiSlice';

export const tournamentApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    downloadExportedList: builder.mutation({
      query: (data: {
        page: string;
        query: {
          unfilter?: string | undefined;
          name: string | undefined;
          Game: string | undefined;
          category: string | undefined;
          subdivision: string | undefined;
        };
      }) => ({
        url: `/tournaments/export/${data.page}`,
        method: 'GET',
        params: data.query,
        responseHandler: async (response) => {
          if (
            response.status === 200 &&
            response.headers.get('content-type')?.includes('text/csv')
          ) {
            window.open(
              window.URL.createObjectURL(await response.blob()),
              '_blank',
              'noopener,noreferrer',
            );
          } else {
            console.error('Unexpected content type or response status');
          }
        },
        cache: 'no-cache',
      }),
    }),
    tournamentsTeams: builder.query({
      query: ({ id, page, game, seasonState }) => ({
        url: `/tournaments/teams/${id}?page=${page ? page : 1}&game=${
          game ? game : ''
        }&seasonState=${seasonState ? seasonState : ''}`,
        method: 'GET',
      }),
    }),
    getTournamentStanding: builder.query({
      query: (id) => ({
        url: `/tournaments/standing/${id}`,
        method: 'GET',
      }),
      providesTags: ['TournamentStanding'],
    }),
    getTournamentsByDivisionId: builder.query({
      query: (query) => ({
        url: `/tournaments/division-id/${query.id}?page=${query.page}`,
        method: 'GET',
      }),
    }),
    getTournamentsByGameId: builder.query({
      query: (query) => ({
        url: `/tournaments/game-id/${query.id}?page=${query.page}`,
        method: 'GET',
      }),
    }),
    duplicateTournament: builder.mutation({
      query: (id) => ({
        url: `/tournaments/duplicate/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Tournaments'],
    }),
    getTournamentGroupedByGame: builder.query({
      query: (page) => ({
        url: `/game/tournament-group?page=${page ? page : 1}`,
        method: 'Get',
      }),
    }),
    getTournamentGroupedByDivision: builder.query({
      query: (page) => ({
        url: `/divisions/tournament-group?page=${page ? page : 1}`,
        method: 'Get',
      }),
    }),
    getTournamentGroupedBySubdivision: builder.query({
      query: (page) => ({
        url: `/subdivisions/tournament-group?page=${page ? page : 1}`,
        method: 'Get',
      }),
    }),
    getNewTRequiredData: builder.query({
      query: () => ({
        url: '/tournaments/new',
        method: 'GET',
      }),
      providesTags: ['Division'],
    }),
    getAllTournaments: builder.query({
      query: (query) => ({
        url: `/tournaments/all?${query.query}=${query.param}&page=${query.page ?? 1}`,
        method: 'GET',
      }),
      providesTags: ['Tournaments'],
    }),
    createTournament: builder.mutation({
      query: (body) => {
        console.log('test from api slice', body);
        return {
          url: '/tournaments/new',
          method: 'Post',
          body,
          // ...data,
        };
      },
      invalidatesTags: ['Tournaments'],
    }),
    getTournamentTypes: builder.query({
      query: () => ({
        url: '/tournaments/types',
        method: 'GET',
      }),
    }),
    // getAllCategoryDivisions: builder.query({
    //   query: (query) => ({
    //     url: `/tournaments/divisions/all?${query.query}=${query.param}`,
    //     method: 'GET',
    //   }),
    //   providesTags: ['CategoryDivisions', 'Division'],
    // }),
    // getAllSubdivisions: builder.query({
    //   query: () => ({
    //     url: 'tournaments/subdivisions/all',
    //     method: 'GET',
    //   }),
    //   providesTags: ['Subdivisions'],
    // }),
    getSingleTournamentInfo: builder.query({
      query: (id) => ({
        url: `tournaments/info/${id}`,
        method: 'GET',
      }),
      providesTags: ['SingleTournament', 'Team'],
    }),
    getTournamentTeamsGroupedByOrg: builder.query({
      query: (id) => ({
        url: `tournaments/${id}/teams`,
        method: 'GET',
      }),
      providesTags: ['SingleTournament', 'Team'],
    }),
    getTournamentById: builder.query({
      query: (id) => ({
        url: `tournaments/${id}`,
        method: 'GET',
      }),
      providesTags: ['SingleTournament'],
    }),
    archiveTournament: builder.mutation({
      query: (id) => ({
        url: `tournaments/archive/${id}`,
        method: 'Post',
      }),
      invalidatesTags: ['Tournaments', 'SingleTournament', 'Unarchived'],
    }),
    updateTournament: builder.mutation({
      query: ({ id, body }) => ({
        url: `/tournaments/update/${id}`,
        method: 'Put',
        body,
      }),
      invalidatesTags: ['Tournaments', 'SingleTournament', 'TournamentStanding'],
    }),
    updateTournamentTeam: builder.mutation({
      query: ({ id, body }) => ({
        url: `/tournaments/update/${id}/teams`,
        method: 'Put',
        body,
      }),
      invalidatesTags: ['Tournaments', 'SingleTournament', 'TournamentStanding'],
    }),
    uploadImage: builder.mutation({
      query: (image) => ({
        url: `/tournaments/upload-image`,
        method: 'POST',
        body: image,
      }),
    }),
    changeTournamentProfilePhoto: builder.mutation({
      query: ({ photo, id }) => ({
        url: `/tournaments/profile-photo/${id}`,
        method: 'PUT',
        body: photo,
      }),
      invalidatesTags: ['Tournaments', 'SingleTournament'],
    }),
    changeTournamentBannerPhoto: builder.mutation({
      query: ({ photo, id }) => ({
        url: `/tournaments/banner-photo/${id}`,
        method: 'PUT',
        body: photo,
      }),
      invalidatesTags: ['Tournaments', 'SingleTournament'],
    }),
    getAllTournamentsByDateStatus: builder.query({
      query: ([query, param]) => ({
        url: `/tournaments/by-date/${param.dateStatus}?name=${query.name}&date=${query.date}&page=${
          query.page ?? 1
        }`,
        method: 'Get',
      }),
      providesTags: ['Tournaments'],
    }),
    getFirstTournamentRoundByTournamentId: builder.query({
      query: (id) => ({
        url: `tournaments/tournament-round-by-tournament/${id}`,
        method: 'Get',
      }),
    }),
  }),
});

export const {
  useTournamentsTeamsQuery,
  useGetTournamentStandingQuery,
  useGetFirstTournamentRoundByTournamentIdQuery,
  useGetTournamentsByDivisionIdQuery,
  useGetTournamentsByGameIdQuery,
  useDuplicateTournamentMutation,
  useGetTournamentGroupedByGameQuery,
  useGetTournamentGroupedByDivisionQuery,
  useGetTournamentGroupedBySubdivisionQuery,
  useArchiveTournamentMutation,
  useGetSingleTournamentInfoQuery,
  // useGetAllSubdivisionsQuery,
  // useGetAllCategoryDivisionsQuery,
  useGetNewTRequiredDataQuery,
  useGetTournamentTypesQuery,
  useGetAllTournamentsQuery,
  useCreateTournamentMutation,
  useUpdateTournamentMutation,
  useChangeTournamentProfilePhotoMutation,
  useUploadImageMutation,
  useChangeTournamentBannerPhotoMutation,
  useUpdateTournamentTeamMutation,
  useGetTournamentByIdQuery,
  useDownloadExportedListMutation,
  useGetAllTournamentsByDateStatusQuery,
  useGetTournamentTeamsGroupedByOrgQuery,
} = tournamentApiSlice;
