import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Combobox from '../../../../../components/combobox/combobox';
export function TournamentsGroupByInput({}) {
  const [groupedBy, setGroupedBy] = useState('');
  const options = [
    {
      id: 1,
      value: '1',
      name: 'Game',
    },
    {
      id: 2,
      value: '2',
      name: 'Category Division',
    },
    {
      id: 3,
      value: '3',
      name: 'Subdivision',
    },
  ];
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    setSearchParams({ groupedBy });
  }, [groupedBy]);
  return (
    <div className='flex w-fit items-center gap-5'>
      <Combobox
        className='!min-w-[300px]'
        noFormik
        options={options}
        value={groupedBy}
        setFieldValue={(v) => setGroupedBy(v)}
        label='GROUP BY'
        name='groupBy'
      />
    </div>
  );
}
