import * as yup from 'yup';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { OutsideClick } from '../../../../components/outside-click';
import {
  useAddOrRemovePlacementMutation,
  useGetSingleSponsorInfoQuery,
  useGetSponsorPlacementsQuery,
  useUpdateSponsorMutation,
} from '../../../../slices/sponsorApiSlice';
import ModalFooter from '../../../../components/modal-footer';
import { FormInput } from '../../../../components/form-input/form-input';
import ModalHeader from '../../../../components/modal-header';
import { Checkbox } from '../../../../components/ui/checkbox/Checkbox';
import { SponsorModel } from '../../../../model/sponsor.model';
import { CheckedState } from '@radix-ui/react-checkbox';
import { EditSponsorValidationSchema } from '../../../../features/authentication/yupschema';
import { CustomError } from '../../../../utils/custom-types';
import { addCurrentTimeToDate } from '../../../../utils/dateFormatter';

const EditSponsor = () => {
  const param = useParams();
  const [avatarImage, setAvatarImage] = useState<any>();
  const [currentImage, setCurrentImage] = useState<any>();
  const {
    data: spData,
    isSuccess: spIsSuccess,
    isLoading: spIsLoading,
  } = useGetSponsorPlacementsQuery('');
  const formInputs = [
    {
      mainTitle: 'Sponsor Information',
      name: 'name',
      label: 'Sponsor Name',
      type: 'text',
      placeholder: 'Enter sponsor name',
      isTrue: true,
    },
    {
      mainTitle: 'Sponsor Information',
      name: 'image',
      label: 'Image',
      type: 'image',
      placeholder: 'Select an image',
      isTrue: true,
    },
    {
      mainTitle: 'Sponsor Information',
      name: 'start_date',
      label: 'Start date',
      type: 'date',
      placeholder: 'Select the starting date',
      isTrue: true,
    },
    {
      mainTitle: 'Sponsor Information',
      name: 'expiration_date',
      label: 'Expiration date',
      type: 'date',
      placeholder: 'Select the expiration date',
      isTrue: true,
    },
  ];
  const [
    updateSponsor,
    {
      data: updateData,
      isError: updateIsError,
      isLoading: updateIsLoading,
      isSuccess: updateIsSuccess,
    },
  ] = useUpdateSponsorMutation();

  function onSubmit(values: any, { setErrors }: any) {
    const data = { ...values };
    const formData = new FormData();

    // if (!avatarImage) {
    //   setErrors({ image: 'Please provide an image.' });
    //   return;
    // }

    // const acceptedFormats = ['image/jpeg', 'image/png', 'image/jpg'];
    // if (!acceptedFormats.includes(avatarImage.type)) {
    //   setErrors({ image: 'Invalid image format. Only JPG, PNG, and JPEG formats are allowed.' });
    //   return;
    // }

    if (avatarImage) formData.append('image', avatarImage);
    formData.append('name', data.name);
    formData.append('start_date', addCurrentTimeToDate(data.start_date));
    formData.append('expiration_date', addCurrentTimeToDate(data.expiration_date));
    updateSponsor({ id: param.id, body: formData }).catch((e) => console.log(e));
  }

  const { data, isLoading, isSuccess, isError, error } = useGetSingleSponsorInfoQuery(param.id);
  const initialValues: { [key: string]: string } = {};
  formInputs.forEach((input) => (initialValues[input.name] = ''));
  const [model, setModel] = useState<SponsorModel>();
  const [updatePlacement, { data: upData, isLoading: upIsLoading, isError: upIsError }] =
    useAddOrRemovePlacementMutation();
  function handleCheck(check: CheckedState, placementId: number) {
    updatePlacement({ sponsorId: data.id, placementId, connect: Boolean(check) });
    // if (check) {
    //   // addOneCoach({ coach_id, org_id: Number(param.id ?? user.organizations[0].id) });
    // } else {
    //   // removeCoachFromOrg({
    //   //   coach_id,
    //   //   organization_id: Number(param.id ?? user.organizations[0].id),
    //   // });
    // }
  }

  useEffect(() => {
    if (!data) return;
    setModel(new SponsorModel(data));
  }, [data]);

  useEffect(() => {
    if (avatarImage) {
      setCurrentImage(URL.createObjectURL(avatarImage));
    }
  }, [avatarImage]);

  // @ts-ignore
  if (isError)
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError).data.message }} />;
  if (isLoading || spIsLoading || !model) return <p>Loading...</p>;
  if (updateIsSuccess) return <Navigate to='../' />;
  return (
    <>
      <OutsideClick />
      <Formik
        initialValues={{
          ...model,
          start_date: model?.inp_start_date,
          expiration_date: model?.inp_expiration_date,
          // ...data,
          // start_date: data.start_date.split('T')[0],
          // expiration_date: data.expiration_date.split('T')[0],
        }}
        validationSchema={EditSponsorValidationSchema.shape({
          image: yup
            .mixed()
            .nullable()
            .test(
              'test2',
              'Invalid image format. Only JPG, PNG, and JPEG formats are allowed.',
              () => {
                const acceptedFormats = ['image/jpeg', 'image/png', 'image/jpg'];
                if (avatarImage && !acceptedFormats.includes(avatarImage?.type ?? '')) {
                  return false;
                }
                return true;
              },
            ),
        })}
        // onSubmit={() => console.log("submit")}
        // validationSchema={{}}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Form>
              <div
                className={
                  'fixed left-0 right-0 bottom-0 top-0 m-auto z-10 w-auto mx-auto max-w-2xl max-h-[80vh] '
                }
              >
                <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none h-full'>
                  {model?.image ? (
                    <ModalHeader
                      name={`Edit Sponsor - ${data?.name}`}
                      image={currentImage ? currentImage : model?.image}
                    />
                  ) : (
                    <ModalHeader name={`Edit Sponsor - ${data?.name}`} />
                  )}
                  <div className='relative px-3 flex-auto overflow-auto'>
                    {formInputs.map((input: any, i: number) => (
                      <React.Fragment key={i}>
                        {!formInputs[i - 1] || formInputs[i - 1]?.mainTitle !== input.mainTitle ? (
                          <>
                            <div className='flex flex-col'>
                              <div className='my-4'>
                                <p className='text-gray-800 font-bold'>{input.mainTitle}</p>
                                <hr />
                              </div>
                              <div className='flex flex-wrap gap-1'>
                                {formInputs
                                  .filter((inp1: any) => inp1.mainTitle === input.mainTitle)
                                  .map((inp: any, i: any) => (
                                    <React.Fragment key={i}>
                                      <div
                                        className={inp.isTrue ? 'w-full ' : 'flex-1 min-w-[300px]'}
                                      >
                                        {inp.type !== 'image' ? (
                                          <FormInput {...inp} />
                                        ) : (
                                          <FormInput setFieldValue={setAvatarImage} {...inp} />
                                        )}
                                      </div>
                                    </React.Fragment>
                                  ))}
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </React.Fragment>
                    ))}
                    <div className='flex flex-col gap-2'>
                      {spData.map((d: any) => {
                        return (
                          <div
                            key={d.name}
                            className='flex items-center gap-1.5 hover:bg-slate-50 cursor-pointer'
                          >
                            <Checkbox
                              checked={Boolean(
                                model.placements.filter((t) => t.id === d.id).length,
                              )}
                              onCheckedChange={(ch) => handleCheck(ch, d.id)}
                              // id={model.id.toString()}
                              id={d.name}
                            />
                            <label htmlFor={d.name} className='flex-1 cursor-pointer'>
                              {upIsLoading ? `${d.name} - Updating...` : d.name}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <ModalFooter isLoading={updateIsLoading} />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default EditSponsor;
