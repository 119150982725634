import { TableHead } from '../../components/table-head';
import TableContainer from '../../components/table-container/table-container';
import TableTd from '../../components/table-td/table-td';
import CanIView from '../../components/can-i-view/can-i-view';
import DropdownItem from '../../features/listview/DropDown/DropDownItem';
import { MouseEvent, useState } from 'react';
import { Outlet } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';
import { HeaderButton } from '../../components/header-button';
import { useGetAllSupportCategoryQuery } from '../../slices/supportCategoryApiSlice';
import { dateAndTimeFormatter } from '../../utils/dateFormatter';
import ShowAllSubCategories from './show-all-subcategories';
import { useSelector } from 'react-redux';
import { selectUser } from '../../slices/auth';
import { UserRoles } from '../../utils/constants';
import Pagination from '../../components/pagination';
import { Separator } from '../../components/ui/separator/Separator';
import { LoadingSceletonSupportCategory } from '../tournaments/routes/all/loading-sceleton-support-category';

export interface ISubCategories {
  id: number;
  name: string;
  teamName: string;
}

export type subcategoriesType = ISubCategories[];

const SupportCategories = () => {
  const user = useSelector(selectUser);
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, isLoading } = useGetAllSupportCategoryQuery({
    page: searchParams.get('page') ? searchParams.get('page') : 1,
  });
  const [modelId, setModelId] = useState<subcategoriesType>();

  const initialOpenListState = {
    id: null,
    x: null,
    y: null,
  };

  const [openList, setOpenList] = useState<{ [key: string]: number | null }>(initialOpenListState);

  const tableHeaderColumns = [
    {
      name: 'Name',
      active: true,
      property: 'category',
    },
    {
      name: 'Subcategories',
      active: true,
      property: 'subcategory',
    },
    {
      name: 'Created Date',
      active: true,
      property: 'createddate',
    },
    {
      name: 'Updated Date',
      active: true,
      property: 'updatedAt',
    },
    {
      name: 'Id',
      active: true,
      property: 'id',
    },
  ];
  if (isLoading) return <LoadingSceletonSupportCategory />;
  const handleClick = (prop: { id: number; x: number; y: number }) => {
    if (user?.roles === UserRoles.Admin) {
      if (openList.id == prop.id) {
        setOpenList(initialOpenListState);
      } else {
        setOpenList(prop);
      }
    }
  };

  const handleShowSubCategories = (subcategories: subcategoriesType) => {
    setModelId(subcategories);
  };

  return (
    <>
      <div className='w-full mx-auto py-8 mt-2 flex overflow-auto max-w-full flex-col flex-1 rounded-xl bg-white border-1'>
        <div className='flex justify-between items-center mb-[3rem]'>
          <h2 className='text-2xl ml-3 font-semibold tracking-tight'>Category</h2>
          <div className='flex gap-5  mr-[2rem]'>
            <CanIView component='category.newCategory'>
              <Link to='./new'>
                <HeaderButton type='newCategorySupport' />
              </Link>
            </CanIView>
          </div>
        </div>
        <Separator className='my-1' />
        <div className='flex flex-col flex-1 justify-between mt-20'>
          {modelId ? (
            <ShowAllSubCategories
              setModelId={setModelId}
              subCategories={modelId}
              title='All Subcategories'
            />
          ) : (
            <></>
          )}
          <TableContainer>
            <TableHead oneMore tableList={tableHeaderColumns.map((table) => table.name)} />
            <tbody className='bg-w0hite divide-y divide-gray-200'>
              {data?.data?.map((categoryData: any, index: number) => (
                <tr
                  key={categoryData.id}
                  onClick={(e) => handleClick({ id: categoryData.id, x: e.pageX, y: e.pageY })}
                >
                  <TableTd>{categoryData.name}</TableTd>
                  <TableTd
                    handleClick={(e: MouseEvent<HTMLElement>) => {
                      handleShowSubCategories(categoryData.subcategories);
                      e.stopPropagation();
                    }}
                  >
                    {categoryData.subcategories.length === 1
                      ? categoryData.subcategories[0].name
                      : categoryData.subcategories.length > 1
                      ? 'Show All'
                      : 'Empty'}
                  </TableTd>
                  <TableTd>{dateAndTimeFormatter(categoryData.createdAt).split(';')[0]}</TableTd>
                  <TableTd>{dateAndTimeFormatter(categoryData.updatedAt).split(';')[0]}</TableTd>
                  <TableTd>{categoryData.id}</TableTd>
                  <CanIView component='threedots'>
                    <TableTd>
                      {categoryData?.name.toLowerCase() !== 'other' ? (
                        <div className='listViewMenu--parent'>
                          <div
                            className={openList.id === categoryData.id ? '' : 'hidden'}
                            style={
                              openList.id === categoryData.id
                                ? {
                                    position: 'fixed',
                                    top: Number(openList.y),
                                    left:
                                      Number(openList.x) < 200
                                        ? Number(openList.x) + 200
                                        : Number(openList.x),
                                    zIndex: 99,
                                  }
                                : {
                                    left: 0,
                                  }
                            }
                          >
                            <div
                              className='listViewMenu'
                              role='menu'
                              aria-orientation='vertical'
                              aria-labelledby='menu-button'
                            >
                              <div className='' role='none'>
                                <DropdownItem
                                  id={categoryData.id}
                                  setFunc={setOpenList}
                                  editAndArchive={categoryData?.name.toLowerCase() !== 'other'}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </TableTd>
                  </CanIView>
                </tr>
              ))}
            </tbody>
          </TableContainer>
          <Pagination length={data?.data?.length} {...data?.meta} />
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default SupportCategories;
