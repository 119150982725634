import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { SupportTickets } from './support-tickets';
import { Link } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import TableContainer from '../../../components/table-container/table-container';
import TableTd from '../../../components/table-td/table-td';
import { TableHead } from '../../../components/table-head';
import { useGetAllTicketsByUserIdQuery } from '../../../slices/supportTicketApiSlice';
import { selectUser } from '../../../slices/auth';
import { useSelector } from 'react-redux';
import { dateAndTimeFormatter } from '../../../utils/dateFormatter';
import { Separator } from '../../../components/ui/separator/Separator';
import Pagination from '../../../components/pagination';
import { SupportTicketStatus, UserSupportTabStatus } from '../../../utils/constants';

interface IViewMyTickets {
  id: number;
  createdAt: string;
  category: {
    name: string;
  };
  subcategory: {
    name: string;
  };
  adminApproval: {} | any;
  status: string;
  closedAt: string;
}

const ViewMyTickets = () => {
  const user = useSelector(selectUser);
  const id = user?.id?.toString();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isActive, setIsActive] = useState<Number | undefined>(UserSupportTabStatus.Open);
  const navigate = useNavigate();
  const [tableList, setTableList] = useState<Array<string>>([
    'ID',
    'Created Date',
    'Category',
    'Subcategory',
    'Status',
    'Details',
  ]);

  useEffect(() => {
    navigate('?status=open');
  }, []);

  useEffect(() => {
    if (isActive === UserSupportTabStatus.Closed) {
      setTableList([
        'ID',
        'Created Date',
        'Category',
        'Subcategory',
        'Status',
        'Closed Date',
        'Details',
      ]);
    } else {
      setTableList(['ID', 'Created Date', 'Category', 'Subcategory', 'Status', 'Details']);
    }
  }, [isActive]);

  const { data: tickets, isLoading } = useGetAllTicketsByUserIdQuery({
    query: 'status',
    param: searchParams.get('status') ? searchParams.get('status') : '',
    page: searchParams.get('page') ? searchParams.get('page') : 1,
  });

  const ticketStatuses: Record<string, string> = {
    0: 'Not Started',
    1: 'Closed',
    2: 'Archived',
    3: 'In Progress',
    4: 'Not Started',
    5: 'In Progress',
    6: 'Monitoring',
  };

  const statusOptions = [
    {
      id: 1,
      name: 'Open',
    },
    {
      id: 2,
      name: 'Closed',
    },
    {
      id: 3,
      name: 'Archived',
    },
  ];

  const handleClick = (id: number, status: string) => {
    setIsActive(id);
    navigate(`?status=${status.toLowerCase()}`);
  };

  if (isLoading) return <p>Loading...</p>;

  return (
    <>
      <div className='listViewContainer fullPage border-1'>
        <SupportTickets />
        <div className='flex justify-between'>
          <Link to='/support'>
            <button className='w-[120px] h-[40px] rounded text-slate-700 font-semibold border border-gray-200 text-center hover:bg-gray-200'>
              Back
            </button>
          </Link>
        </div>
        <div className='flex justify-between items-center flex-wrap gap-2'>
          <div>
            <h2 className='font-semibold text-left tracking-tight text-2xl'>Tickets</h2>
          </div>
          <div className='flex gap-3'>
            {statusOptions?.map((status) => (
              <React.Fragment key={status.id}>
                <div className='cursor-pointer'>
                  <a
                    aria-current='page'
                    className={`tournamentsNavButton ${status.id === isActive ? 'active' : ''}`}
                    onClick={() => handleClick(status.id, status.name)}
                  >
                    <div className='tournamentsNavButtonIcon'></div>
                    <div className='flex flex-col justify-between'>
                      <h2 className='font-bold text-xs'>{status.name}</h2>
                      <p className='text-xs'></p>
                    </div>
                  </a>
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
        <Separator className='my-1' />
        <div className='flex flex-col flex-2 justify-between '></div>
        <TableContainer>
          <TableHead tableList={tableList} />
          <tbody className='bg-white divide-y divide-gray-200'>
            {tickets &&
              tickets.data.map((ticket: IViewMyTickets) => (
                <tr key={ticket.id}>
                  <TableTd>{ticket.id}</TableTd>
                  <TableTd>{dateAndTimeFormatter(ticket.createdAt).split(';')[0]}</TableTd>
                  <TableTd>{ticket.category.name}</TableTd>
                  <TableTd>{ticket.subcategory.name}</TableTd>
                  <TableTd>
                    {!ticket?.adminApproval ? ticketStatuses[ticket.status] : 'Resolved'}
                  </TableTd>
                  {isActive === UserSupportTabStatus.Closed ? (
                    <TableTd>
                      {ticket?.closedAt
                        ? dateAndTimeFormatter(ticket?.closedAt)?.split(';')?.join()
                        : '-'}
                    </TableTd>
                  ) : null}
                  <TableTd>
                    <Link to={`../viewMyticket/${ticket.id}`}>
                      <button className='w-[100px] h-[30px] border rounded border-gray-200 hover:bg-gray-300 cursor-pointer'>
                        View
                      </button>
                    </Link>
                  </TableTd>
                </tr>
              ))}
          </tbody>
        </TableContainer>
        <Pagination length={tickets?.data?.length} {...tickets?.meta} />
      </div>
      <Outlet />
    </>
  );
};

export default ViewMyTickets;
