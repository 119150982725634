import { CustomError } from '../utils/custom-types';
import { SubmitButton } from './submit-button';
import { Link, useLocation } from 'react-router-dom';

const ModalFooter = ({
  isLoading,
  errors,
  emailError,
  error,
  disabled,
  onSubmit,
  onCancel,
}: {
  disabled?: boolean;
  isLoading: boolean;
  errors?: any;
  emailError?: any;
  error?: any;
  onSubmit?: Function;
  onCancel?: Function;
}) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  return (
    <div className='flex relative items-center justify-end gap-2 border-t border-solid border-slate-200 rounded-xl bottom-0 bg-white py-6 px-6'>
      {errors ? <p className='text-red-500'>Please fill all the fields</p> : ''}
      {emailError ? <p className='text-red-500'>{emailError}</p> : ''}
      {error ? <p className='text-red-500'>{(error as CustomError).data?.message}</p> : ''}
      <SubmitButton isLoading={isLoading} disabled={isLoading || disabled} onSubmit={onSubmit} />
      {onCancel ? (
        <div className='btn cancel' onClick={() => onCancel()}>
          Cancel
        </div>
      ) : (
        <Link className='btn cancel' to={`../?${queryParams}`}>
          Cancel
        </Link>
      )}
    </div>
  );
};

export default ModalFooter;
