import { AvatarImage } from '../../../components/avatar-image';
import { AiOutlineCamera } from 'react-icons/ai';
import {
  useChangeUserProfilePhotoMutation,
  useGetCoachByIdQuery,
} from '../../../slices/userApiSlice';
import { useParams } from 'react-router-dom';
import CanIView from '../../../components/can-i-view/can-i-view';

const CoachHeaderInfo = () => {
  const params = useParams();

  const [changeUserProfilePhoto, { isError, isLoading, isSuccess }] =
    useChangeUserProfilePhotoMutation();

  const {
    data: userData,
    isLoading: userIsLoading,
    isError: userIsError,
    isSuccess: userIsSuccess,
  } = useGetCoachByIdQuery(params.id!, { skip: !params.id });

  function handleFileChange(event: any) {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    changeUserProfilePhoto({ photo: formData, id: params.id })
      .unwrap()
      .then((res) => console.log(res))
      .catch((e) => console.log(e));
  }

  return (
    <div className='pointer-events-auto flex items-center gap-5 '>
      <div className='group cursor-pointer flex relative w-[100px] aspect-square rounded-full overflow-hidden justify-center items-center '>
        <AvatarImage size='xxl' source={userData.image} />

        <CanIView component={`coach.changePhoto`}>
          <label className='cursor-pointer text-white  w-full bg-black bg-opacity-80 h-full absolute top-0 right-0 left-0 bottom-0 m-auto z-10 hidden group-hover:flex justify-center items-center  '>
            <AiOutlineCamera />
            <input
              type='file'
              accept='image/*'
              onChange={handleFileChange}
              className='hidden mr-12'
            />
          </label>
        </CanIView>
      </div>
      <div className='flex flex-col space-evenly gap-1 z-10'>
        <h2 className='text-white text-2xl font-bold max-w-[15ch] truncate'>
          {/* {userData.firstName} {userData.lastName} */}
          {userData.username[0]?.toUpperCase()}
          {userData.username?.slice(1)}
        </h2>
        <p className='text-white'>
          Member since{' '}
          <span className='text-white font-bold'>{new Date(userData.createdAt).getFullYear()}</span>
        </p>
      </div>
    </div>
  );
};

export default CoachHeaderInfo;
