import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useGetSponsorByPlacementQuery } from '../../slices/sponsorApiSlice';
import { SlArrowLeft, SlArrowRight } from 'react-icons/sl';
import Slider from 'react-slick';

export function SponsorRow({
  name,
  noWrap,
  extraClass,
}: {
  name: string;
  noWrap?: Boolean;
  extraClass?: string;
}) {
  const { data, isLoading, isError } = useGetSponsorByPlacementQuery(name);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: data?.length <= 4 ? data?.length : data?.length <= 6 ? 4 : 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: 'linear',
    pauseOnHover: false,
    centerMode: false,
    variableWidth: data?.length <= 3,
    nextArrow: (
      <SlArrowRight
        style={{
          position: 'absolute',
          top: '50%',
          right: '-25px',
          transform: 'translateY(-50%)',
        }}
        color='red'
      />
    ),
    prevArrow: (
      <SlArrowLeft
        style={{
          position: 'absolute',
          top: '50%',
          left: '-25px',
          transform: 'translateY(-50%)',
        }}
        color='red'
      />
    ),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  if (isLoading) return <p>Loading...</p>;
  if (!data?.length) return <></>;

  return (
    <div className={`w-[97%] m-auto ${extraClass ? extraClass : ''}`}>
      <Slider {...settings}>
        {data?.map((d: any, i: number) => (
          <div key={i} className='rounded-lg w-[350px] !flex justify-center items-center'>
            <div
              className={`${
                data?.length <= 3 ? 'w-[300px]' : 'w-[380px]'
              } h-[150px] border-1 rounded-md flex justify-center items-center p-4`}
            >
              <img
                src={d?.image}
                alt='gallery-img'
                className='w-[250px] h-[150px] object-contain p-4'
              />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}
