import React, { useEffect, useState } from 'react';
import { Avatar, AvatarFallback, AvatarImage } from '../../../../components/ui/avatar/Avatar';
import { firstLetterToUpperCase } from '../../../../utils/utils';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { BsArrowDown, BsArrowRight } from 'react-icons/bs';
import GuardianStudentSubscription from '../subscriptions/guardian-student-subscriptions';
import { UserModel } from '../../../../features/listview/users/model/user.model';

const MyStudentCard = ({ student }: { student: UserModel }) => {
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const navigate = useNavigate();

  console.log('team name', student.team_name);
  return (
    <div className='w-full border border-gray-200 rounded-xl'>
      <div
        className={`flex gap-4 items-center p-4 cursor-pointer rounded-t-xl hover:bg-gray-100 ${
          isClicked ? 'bg-gray-100' : ''
        }`}
        onClick={() => {
          // TODO: PAYMENT: UNCOMMENT TO USE PAYMENT AGAIN
          // setIsClicked(!isClicked);
        }}
      >
        <Avatar className='h-20 w-20'>
          <AvatarImage src={student?.image} />
          <AvatarFallback>
            {firstLetterToUpperCase(student?.firstName) +
              firstLetterToUpperCase(student?.lastName || '')}
          </AvatarFallback>
        </Avatar>
        <div className='w-full flex justify-between items-center'>
          <div>
            <p className='font-semibold text-lg max-w-[30ch] truncate'>
              {student?.firstName} {student?.lastName}
            </p>
            <p className='text-sm max-w-[20ch] truncate'>{student?.username}</p>
            <div
              className='border border-gray-200 rounded-xl px-2 py-1 cursor-pointer hover:bg-gray-200 text-center'
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/users/view/${student.id}/student/overview`);
              }}
            >
              Profile
            </div>
          </div>
          <div className='w-[400px] border rounded-xl p-2'>
            <div className='flex'>
              <span className='font-semibold'>School:</span>{' '}
              <span
                className='hover:underline'
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Link to={`/schools/view/${student?.school_id}`}>
                  {' '}
                  <p className='max-w-[20ch] truncate'>{student?.school_name}</p>
                </Link>
              </span>
            </div>
            <div className='flex  cursor-pointer'>
              <span className='font-semibold'>Teams:</span>{' '}
              <p className='flex'>
                {student?.teams?.length! > 5 ? (
                  <Link to='/teams' className='hover:underline cursor-pointer'>
                    See All
                  </Link>
                ) : (
                  <>
                    [
                    {student?.teams?.map((team, index) => (
                      <>
                        <Link className='hover:underline' to={`/teams/view/${team.id}`}>
                          <p className='max-w-[15ch] truncate'>{team.teamName}</p>
                        </Link>
                        {student?.teams?.length! - 1 !== index ? ', ' : ''}
                      </>
                    ))}
                    ]
                  </>
                )}
              </p>
              {/* </p> */}
            </div>
          </div>
        </div>
        {isClicked ? <BsArrowDown /> : <BsArrowRight />}
      </div>
      <div>
        {isClicked && (
          <div className='h-[200px] p-4 overflow-auto'>
            <GuardianStudentSubscription studentId={student?.id} />
          </div>
        )}
      </div>
    </div>
  );
};

export default MyStudentCard;
