import React, { useEffect, useState } from 'react';
import { BsThreeDots } from 'react-icons/bs';
import { HiUserGroup } from 'react-icons/hi';
import DropdownItem from '../../../../../features/listview/DropDown/DropDownItem';
import { dateAndTimeFormatter } from '../../../../../utils/dateFormatter';
const defaultBg = (await import('../../../../../assets/grid-background.jpg')).default;
export function TournamentSubdivisionGridCard({ ...t }) {
  const [openList, setOpenList] = useState(null);

  return (
    <div className='relative flex flex-col rounded-xl w-[300px] aspect-square overflow-hidden bg-white border-2 border-bg-200'>
      <div
        onClick={() => setOpenList(t.id)}
        className='absolute cursor-pointer top-2 right-2 p-1 px-2 rounded-lg bg-black bg-opacity-80'
      >
        <BsThreeDots color='white' className='group' />
      </div>

      <div className={`listViewMenu--parent--grid ${openList === t.id ? 'flex' : 'hidden'}`}>
        <div
          className='listViewMenu !absolute'
          role='menu'
          aria-orientation='vertical'
          aria-labelledby='menu-button'
        >
          <div className='' role='none'>
            <DropdownItem id={t.id} setFunc={setOpenList} noView={true} />
          </div>
        </div>
      </div>

      <img
        src={t.background_image ? t.background_image : defaultBg}
        className='max-h-[50%] object-cover w-full h-full overflow-hidden'
      />
      <div className='flex p-1 flex-col justify-between h-[50%] w-full'>
        <div className='flex justify-between'>
          <h2 className='font-bold uppercase text-gray-900 ml-3 mb-4 max-w-[15ch] truncate'>{t.name}</h2>
        </div>
        <div className='flex justify-between'>
          <div className='flex flex-col text-sm self-end border-t-2 border-b-neutral-200 w-full items-center p-2'>
            <p className='text-gray-900 font-bold'>Division:</p>
            <p className='text-gray-900 max-w-[15ch] truncate'>{t.CategoryDivision.name}</p>
            {/* <h3 className="text-white">Created by: {t.user.firstName}</h3> */}
            <div className='flex flex-col border-t-2 border-neutral-200 w-full items-center p-2'>
              <h3 className='text-gray-900 font-bold'>Created at:</h3>
              <p className='text-gray-900'>{dateAndTimeFormatter((t.created_at)).split(';')[0]}</p>
            </div>
          </div>
          <div className='flex flex-col text-sm self-end'></div>
        </div>
      </div>
    </div>
  );
}
