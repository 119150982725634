import React from 'react';
import { AvatarImage } from '../../../components/avatar-image';
export function ClassroomChatParticipantRow({
  image,
  username,
}: {
  image: string;
  username: string;
}) {
  return (
    <div className='flex items-center gap-2 rounded-lg bg-gray-700 w-full p-2'>
      <AvatarImage size='lg' source={image} />
      <p className='text-white'>{username}</p>
    </div>
  );
}
