import * as yup from 'yup';
import {
  emailRules,
  emailRulesHint,
  lastnameRulesHint,
  nameRules,
  nameRulesHint,
  nameRulesWithSpecialCharacter,
} from '../../features/authentication/yupschema';
export const EditUserSchema = yup.object().shape({
  firstName: yup
    .string()
    .matches(nameRulesWithSpecialCharacter, nameRulesHint)
    .required('Required'),
  lastName: yup
    .string()
    .matches(nameRulesWithSpecialCharacter, lastnameRulesHint)
    .required('Required'),
  tags: yup.string().nullable(),
  username: yup.string().required('Required'),
  email: yup.string().matches(emailRules, emailRulesHint).required('Required!'), // roles: yup.string().required('Required'),
  status: yup.string().nullable(),
  eligibilityStatus: yup.string().nullable(),
});
