import React from 'react';
export function CoachProfileClassroomCard({
  classroom,
  coach,
}: {
  classroom: string;
  coach: string;
}) {
  return (
    <div className='flex flex-col w-[40%] items-center'>
      <div className='w-full aspect-video bg-gray-300'></div>
      <h2 className='font-bold'>{classroom}</h2>
      <p className='text-xs'>{coach}</p>
    </div>
  );
}
