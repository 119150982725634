import { log } from 'console';
import React, { useState } from 'react';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { useSearchParams } from 'react-router-dom';
import { OutsideClick } from '../../../../components/outside-click';
// import { OutsideClick } from "../outside-click";
export function SearchCoaches({
    setSearch,
    filterList,
    search,
    setQuery,
    setParam,
}: {
    setSearch: Function;
    filterList?: { title: string; property: string }[];
    search: { by: string; keyword: string };
    setQuery?: Function;
    setParam?: Function;
}) {
    const [show, setShow] = useState(false);
    // @ts-ignore
    const [searchQuery, setSearchQuery] = useState({ [filterList[0].title]: '' });
    const [searchParams, setSearchParams] = useSearchParams();
    return (
        <>
            <form onSubmit={(e) => e.preventDefault()} className='flex items-center'>
                <label htmlFor='simple-search' className='sr-only'>
                    Search
                </label>
                {/* <div className="relative w-full flex alignItems-center"> */}
                <div className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 relative flex'>
                    <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                        <svg
                            aria-hidden='true'
                            className='w-5 h-5 text-gray-500 dark:text-gray-400'
                            fill='currentColor'
                            viewBox='0 0 20 20'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                            <path
                                fillRule='evenodd'
                                d='M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z'
                                clipRule='evenodd'
                            ></path>
                        </svg>
                    </div>
                    <input
                        onChange={(e) => {
                            setSearchQuery((state: any) => ({ ...state, [search.by]: e.target.value }));
                            if (setQuery) setQuery((state: any) => ({ ...state, [search.by]: e.target.value }));
                            searchParams.set(search.by, e.target.value);
                            setSearchParams(searchParams);
                            // setSearchParams({[searchQuery]: e.target.value });
                        }}
                        type='text'
                        id='simple-search'
                        placeholder={`Search by ${filterList?.filter((f) => f.property === search.by)[0].title
                            }`}
                        className='bg-transparent h-full outline-none'
                        required
                    />
                    <RiArrowDropDownLine
                        className='cursor-pointer'
                        onClick={() => setShow((s) => !s)}
                        size={20}
                    />
                    {show && (
                        <>
                            <OutsideClick noBg setFunc={() => setShow(false)} />
                            <div className='absolute w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg left-0 top-[100%] p-1 flex flex-col gap-2 z-20'>
                                {filterList?.map((l) => (
                                    <span
                                        className={`cursor-pointer capitalize hover:font-medium ${search.by === l.property ? 'font-medium' : ''
                                            }`}
                                        key={l.property}
                                        onClick={() => {
                                            setSearchQuery((s) => ({ ...s, [search.by]: '', [l.property]: '' }));
                                            if (setQuery)
                                                setQuery((s: any) => ({ ...s, [search.by]: '', [l.property]: '' }));
                                            setSearch({ by: l.property, keyword: '' });
                                            setShow(false);
                                        }}
                                    >
                                        {/* {l.title.charAt(0).toUpperCase() + l.title.slice(1)} */}
                                        {l.title}
                                    </span>
                                ))}
                            </div>
                        </>
                    )}
                </div>
            </form>
        </>
    );
}
