import * as yup from 'yup';
import { Formik, FormikValues } from 'formik';
import { Navigate, useParams } from 'react-router-dom';
import { OutsideClick } from '../../../components/outside-click';
import {
  useGetSingleMatchInfoQuery,
  useUpdateSingleMatchInfoMutation,
} from '../../../slices/matchApiSlice';
import { SchemaMessages } from '../../../utils/constants';
import ModalContent from '../../../components/modal-content';
import { checkIfDateIsFuture, checkStartDate } from '../../../utils/utils';
import { UpdateMatchDTO } from '../../../utils/custom-types';
import useSuccessToast from '../../../hooks/use-success-toast';
import useErrorToast from '../../../hooks/use-error-toast';

const EditMatch = () => {
  const param = useParams();
  const { data, isLoading, isError } = useGetSingleMatchInfoQuery(param.id);

  const EditMatchSchema = yup.object().shape({
    match_format: yup.number().transform((v, ov) => Number(ov)),
    match_date: yup
      .mixed()
      .transform((value, originalValue) => originalValue)
      .nullable()
      .test(
        'test',
        data?.SeasonWeek === null
          ? SchemaMessages.TournametStartDate
          : SchemaMessages.SeasonWeekDate,
        (value, ctx) => {
          if (!value) return false;
          const dateMatch =
            data?.SeasonWeek === null
              ? data?.TournamentRound?.Tournament?.start_date.split('T')[0]
              : data?.SeasonWeek?.start_time.split('T')[0];
          const isAfter = checkIfDateIsFuture(dateMatch, value);
          return isAfter;
        },
      )
      .test('test', SchemaMessages.DateShouldNotBeInThePast, checkStartDate)
      .nullable(),
  });
  const formInputs = [
    {
      mainTitle: 'Match Information',
      name: 'match_date',
      label: 'Schedule',
      type: 'datetime-local',
      placeholder: 'Select or Input Date and time of the match',
      isTrue: true,
    },
    {
      mainTitle: 'Match Information',
      name: 'match_format',
      label: 'Match Format',
      type: 'number',
      placeholder: 'Number of games per match',
      isSelect: true,
      options: data.Game?.MatchFormat,
      isTrue: true,
    },
  ];
  const [updateSingleMatchInfo, { data: upData, isLoading: upIsLoading, isSuccess: upIsSuccess, isError: upIsError, error: upError }] =
    useUpdateSingleMatchInfoMutation();
  function handleSubmit(values: FormikValues) {
    console.log('values', values)
    if (!param.id) return;
    const body: UpdateMatchDTO = {
      ...values,
      match_date: new Date(values.match_date).toISOString(),
    };
    if (values.match_format) {
      body.match_format = +values.match_format;
    }
    updateSingleMatchInfo({
      id: param.id,
      body,
    });
  }


  useSuccessToast(upIsSuccess, 'Match updated successfully!')
  useErrorToast(upIsError, upError);
  if (upIsSuccess) return <Navigate to='../' />;
  if (isLoading) return <p>Loading...</p>;
  return (
    <>
      <OutsideClick />
      <Formik
        enableReinitialize
        validationSchema={EditMatchSchema}
        initialValues={{ match_date: undefined, match_format: undefined }}
        onSubmit={handleSubmit}
      >
        {(formik) => {
          return (
            <ModalContent
              extraClass='h-fit'
              formInputs={formInputs}
              isLoading={isLoading}
              name='Edit Match'
            />
          );
        }}
      </Formik>
    </>
  );
};

export default EditMatch;
