import { apiSlice } from '../api/apiSlice';

export const subDivisionApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    subExportList: builder.mutation({
      query: (data: { page: string; query: { [key: string]: any } }) => ({
        url: `/subdivisions/export/${data.page}`,
        method: 'GET',
        params: data.query,
        responseHandler: async (response) => {
          if (
            response.status === 200 &&
            response.headers.get('content-type')?.includes('text/csv')
          ) {
            window.open(
              window.URL.createObjectURL(await response.blob()),
              '_blank',
              'noopener,noreferrer',
            );
          } else {
            console.error('Unexpected content type or response status');
          }
        },
        cache: 'no-cache',
      }),
    }),
    // searchSubDivisionByName: builder.mutation({
    //   query: (name) => ({
    //     url: `/subdivisions/by-name?name=${name}`,
    //     // url: `/subdivisions/division-id/${query.id}/by-name?name=${query.name}`,
    //     method: 'GET',
    //   }),
    // }),
    searchSubDivisionByName: builder.query({
      query: (params: { subdivision?: string }) => ({
        url: '/subdivisions/by-name',
        method: 'GET',
        params,
      }),
    }),
    getAllSubDivisions: builder.query({
      query: (query) => ({
        url: `/subdivisions/all?${query.query}=${query.param}&page=${query.page ? query.page : 1}`,
        method: 'Get',
      }),
      providesTags: ['Subdivisions'],
    }),
    getAllSeasonSubDivisions: builder.query({
      query: (query) => ({
        url: `/subdivisions/all-seasons?${query.query}=${query.param}&page=${
          query.page ? query.page : 1
        }`,
        method: 'Get',
      }),
      providesTags: ['Subdivisions'],
    }),
    // createSeasonSubdivision: builder.mutation({
    //   query: (body) => ({
    //     url: '/subdivisions/season',
    //     method: 'Post',
    //     body,
    //   }),
    //   invalidatesTags: ['Subdivisions', 'Division', 'CategoryDivisions'],
    // }),

    createSubDivision: builder.mutation({
      query: (body) => ({
        url: '/subdivisions',
        method: 'Post',
        body,
      }),
      invalidatesTags: ['Subdivisions'],
    }),
    updateSubDivision: builder.mutation({
      query: ({ id, body }) => ({
        url: `/subdivisions/${id}`,
        method: 'Put',
        body,
      }),
      invalidatesTags: ['Subdivisions'],
    }),
    getSubDivisionById: builder.query({
      query: (id) => ({
        url: `subdivisions/${id}`,
        method: 'Get',
      }),
      providesTags: ['Subdivisions'],
    }),
    archiveSubDivision: builder.mutation({
      query: (id) => ({
        url: `subdivisions/archive/${id}`,
        method: 'Post',
      }),
      onQueryStarted: async (_, api) => {
        try {
          await api.queryFulfilled;
          api.dispatch(apiSlice.util.resetApiState());
        } catch (error) {
          console.log(error);
        }
      },
      invalidatesTags: ['Subdivisions', 'Unarchived'],
    }),
  }),
});
export const {
  // useCreateSeasonSubdivisionMutation,
  useCreateSubDivisionMutation,
  useGetAllSubDivisionsQuery,
  useGetSubDivisionByIdQuery,
  useUpdateSubDivisionMutation,
  useArchiveSubDivisionMutation,
  // useSearchSubDivisionByNameMutation,
  useLazySearchSubDivisionByNameQuery,
  useGetAllSeasonSubDivisionsQuery,
  useSubExportListMutation,
} = subDivisionApiSlice;
