import { createSelector, createSlice } from '@reduxjs/toolkit';
import { UserRoles } from '../utils/constants';
const initialState: {
  user: null | any;
  token: null | any;
  refreshToken: null | any;
  logOut: boolean | null;
} = { user: null, token: null, refreshToken: null, logOut: null };
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    changeTerms: (state, action) => {
      if (state.user.roles !== UserRoles.Admin) state.user.acceptedTerms = action.payload;
    },
    updateUser: (state, action) => {
      Object.assign(state.user, action.payload);
    },
    setCredentials: (state, action) => {
      const { user, accessToken, token, refreshToken } = action.payload;
      state.token = token ? token : accessToken;
      state.refreshToken = refreshToken;
      state.user = user;
    },
    refreshTokens: (state, action) => {
      const { token, refreshToken } = action.payload;
      state.token = token;
      state.refreshToken = refreshToken;
    },
    logOut: () => initialState,
    changeLogOut: (state, action) => {
      console.log('logOutttt =', action);
      state.logOut = action.payload
    },
    verifyAccount: (state) => {
      // @ts-ignore
      state.user.isVerified = 1;
    },
    changeUserData: (state, action) => {
      state.user = action.payload;
    },
    setUserIsStreaming: (state, action) => {
      if (state.user?.hasOwnProperty('is_streaming')) state.user.is_streaming = action.payload;
    },
    setUserTwitchUsername: (state, action) => {
      state.user.twitch_id = action.payload.id;
      state.user.twitch_login = action.payload.login;
    },
    addFavoriteStreamer: (state, action) => {
      state.user.favoriteStreamers.push(action.payload);
    },
    removeFavoriteStreamer: (state, action) => {
      state.user.favoriteStreamers = action.payload.slice(0);
    },
    changeUserImage: (state, action) => {
      state.user.image = action.payload;
    },
  },
});
export const {
  refreshTokens,
  changeUserImage,
  removeFavoriteStreamer,
  addFavoriteStreamer,
  setUserTwitchUsername,
  setCredentials,
  logOut,
  changeLogOut,
  verifyAccount,
  changeUserData,
  setUserIsStreaming,
  changeTerms,
  updateUser,
} = authSlice.actions;

const selectSelf = (state: any) => state.auth;

export const selectToken = createSelector(selectSelf, (state) => state.token);
export const selectRefreshToken = createSelector(selectSelf, (state) => state.refreshToken);

export const selectUserSchool = createSelector(selectSelf, (state) => state.user.school);
export const selectUserGuardianStudents = createSelector(
  selectSelf,
  (state) => state.user.guardianStudents,
);
export const selectUserTeam = createSelector(selectSelf, (state) => state.user.team);
export const selectUser = createSelector(selectSelf, (state) => state.user);
export const selectFirstName = createSelector(selectSelf, (state) => state.user.firstName);
export const selectfGames = createSelector(selectSelf, (state) => state.user.fGames);
export const selectRole = createSelector(selectSelf, (state) => state.user.roles);
export const selectIsVerified = createSelector(selectSelf, (state) => state.user.isVerified);
export const selectLogout = createSelector(selectSelf, (state) => state.logOut)

export default authSlice.reducer;
