import { useField } from 'formik';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai';
import { useLazySearchSponsorByNameQuery } from '../../slices/sponsorApiSlice';
import { useLazySearchSubDivisionByNameQuery } from '../../slices/subDivisionApiSlice';
import { useSearchTeamByNameQuery } from '../../slices/teamsApiSlice';
import { useSearchCoachByNameMutation } from '../../slices/userApiSlice';
import { useSearchGameByNameMutation } from '../../slices/gameApiSlice';
import { useLazySearchMatchFormatQuery } from '../../slices/matchFormatApiSlice';
import { useLazyGetAllOrganizationsQuery } from '../../slices/organizationApiSlice';
import Combobox from '../combobox/combobox';
import { InputProps } from '../ui/input/Input';
import { TextareaProps } from '../ui/textarea/Textarea';
import { CheckboxItem, DropdownMenuFilter } from '../ui/dropdown-menu/DropdownMenuFIlter';
import { useGetAllSchoolsQuery } from '../../slices/schoolsApiSlice';
import { SchoolModel } from '../../model/school/school-model';
import { allStates, SeasonStageTeamSelectionMethod } from '../../utils/constants';
import { TeamModel } from '../../model/team.model';
import MultiSelectComboBox from '../multi-select-combobox/MultiSelectComboBox';

type FieldValueType = string | FileList | File;

interface FormInputProps extends React.HTMLAttributes<HTMLDivElement> {
  label?: string;
  isSelect?: boolean;
  isTrue?: boolean;
  mainTitle?: string;
  options?: any[];
  fieldValue?: undefined | FieldValueType;
  setFieldValue?: React.Dispatch<React.SetStateAction<FieldValueType>>;
  isArr?: boolean;
  containsAsterik?: boolean;
  accept?: boolean;
  search?: string;
  type?: string;
  isDisabled?: boolean;
  name?: string;
  create?: string;
  setSubdivisions?: React.Dispatch<React.SetStateAction<any>>;
  teamSizeValue?: number;
  chosenTeam?: object[];
  setChosenTeam?: React.Dispatch<React.SetStateAction<object[]>>;
  chosenSponsor?: object[];
  setChosenSponsor?: React.Dispatch<React.SetStateAction<object[]>>;
  chosenGame?: object[];
  setChosenGame?: React.Dispatch<React.SetStateAction<object[]>>;
  chosenState?: object[];
  setChosenState?: React.Dispatch<React.SetStateAction<object[]>>;
  divisionId?: number;
  chosenSubDivision?: object[];
  setChosenSubDivision?: React.Dispatch<React.SetStateAction<object[]>>;
  matchFormat?: object[];
  setMatchFormat?: React.Dispatch<React.SetStateAction<object[]>>;
  noCoop?: boolean;
  onlyCoop?: boolean;
  chosenOrg?: object[];
  setChosenOrg?: React.Dispatch<React.SetStateAction<object[]>>;
  inviteOnly?: boolean;
  addTeamsToSeason?: boolean;
}

export function FormInput({
  label,
  isSelect,
  isTrue,
  mainTitle,
  options,
  fieldValue,
  setFieldValue,
  isArr,
  containsAsterik,
  accept,
  isDisabled,
  teamSizeValue,
  chosenTeam,
  setChosenTeam,
  chosenSponsor,
  setChosenSponsor,
  chosenGame,
  setChosenGame,
  chosenState,
  setChosenState,
  divisionId,
  chosenSubDivision,
  setChosenSubDivision,
  setSubdivisions,
  matchFormat,
  setMatchFormat,
  noCoop,
  onlyCoop,
  chosenOrg,
  setChosenOrg,
  inviteOnly,
  addTeamsToSeason,
  ...props
}: FormInputProps | any) {
  const [field, meta, helpers] = useField(props as any);

  // const [
  //   searchTeamByName,
  //   { data: sTeamNData, isLoading: sTeamNIsLoading, isError: sTeamNIsError },
  // ] = useLazySearchTeamByNameQuery();

  const [sTeamN, setSTeamN] = useState<string>();
  const [teamFilterState, setTeamFilterState] = useState<string[]>([]);
  useEffect(() => {
    setTeamFilterState(chosenState);
  }, [chosenState]);
  const {
    data: sTeamNData,
    isLoading: sTeamNIsLoading,
    isError: sTeamNIsError,
  } = useSearchTeamByNameQuery(
    {
      team: sTeamN,
      game: chosenGame?.toString(),
      state: teamFilterState?.join(','),
      stateRequired: props.forceState,
    },
    { skip: props?.search !== 'team' },
  );
  const [
    searchSponsorByName,
    { data: sponsorData, isLoading: sponsorIsLoading, isError: sponsorIsError },
  ] = useLazySearchSponsorByNameQuery();
  const [searchGameByName, { data: gameData, isLoading: gameIsLoading, isError: gameIsError }] =
    useSearchGameByNameMutation();
  const [searchMatchFormat, { data: mfData, isLoading: mfIsLoading, isError: mfIsError }] =
    useLazySearchMatchFormatQuery();
  const [searchCoachByName, { data: coachData, isLoading: coachIsLoading, isError: coachIsError }] =
    useSearchCoachByNameMutation();

  const [
    searchSubDivisionByName,
    { data: sSubDivisionNData, isLoading: sSubDivisionNIsLoading, isError: sSubDivisionNIsError },
  ] = useLazySearchSubDivisionByNameQuery();
  const [
    searchOrganizations,
    { data: sOrganization, isLoading: sOrganizationIsLoading, isError: sOrganizationIsError },
  ] = useLazyGetAllOrganizationsQuery();
  function handleTeamFilterStateChange(v: CheckboxItem[]) {
    setTeamFilterState(v.filter((s) => s.defaultChecked).map((s) => s.name!));
  }
  const [sponsorN, setSponsorN] = useState('');
  const [sSubDivisionN, setSSubDivisionN] = useState('');
  const [scoach, setSCoach] = useState('');
  const [studentsEmail, setStudentsEmail] = useState('');
  const [gameN, setGameN] = useState('');
  const [matchFormatGame, setMatchFormatGame] = useState('');
  const [orgN, setOrgN] = useState('');

  function handleSearchCoachByName(e: ChangeEvent<HTMLInputElement>) {
    searchCoachByName(e.target.value);
    setSCoach(e.target.value);
  }
  function handleSearchTeamByName(e: ChangeEvent<HTMLInputElement>) {
    // searchTeamByName({ team: e.target.value });
    setSTeamN(e.target.value);
  }
  function handleSearchSponsorByName(e: ChangeEvent<HTMLInputElement>) {
    searchSponsorByName({ sponsor: e.target.value });
    setSponsorN(e.target.value);
  }
  function handleSearchGameByName(e: ChangeEvent<HTMLInputElement>) {
    searchGameByName(e.target.value);
    setGameN(e.target.value);
  }
  function handleSearchMatchFormat(e: ChangeEvent<HTMLInputElement>) {
    searchMatchFormat({ game: e.target.value });
    setMatchFormatGame(e.target.value);
  }
  function handleSearchSubDivisionByName(e: ChangeEvent<HTMLInputElement>) {
    searchSubDivisionByName({ subdivision: e.target.value });
    setSSubDivisionN(e.target.value);
  }

  function handleSearchStudentWithoutGuardian(e: ChangeEvent<HTMLInputElement>) {
    // searchStudentsWithoutEmail(e.target.value);
    setStudentsEmail(e.target.value);
  }
  function handleSearchOrganizationByName(e: ChangeEvent<HTMLInputElement>) {
    searchOrganizations({ nolimit: true, name: e.target.value });
    setOrgN(e.target.value);
  }

  useEffect(() => {
    if (props.search === 'matchFormat') searchMatchFormat({ game: undefined });
    if (props.search === 'sponsor') searchSponsorByName({ sponsor: undefined });
    if (props.search === 'subdivision') searchSubDivisionByName({ subdivision: undefined });
    // if (props.search === 'team') {
    //   searchTeamByName({ team: undefined });
    // }
    if (props.search === 'organization') searchOrganizations({ nolimit: true, name: undefined });
  }, []);

  // create subdivision from division
  const [crSfrD, setCrSfrD] = useState('');

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  if (props.type === 'checkbox')
    return (
      <>
        <div className={`resetInput ${isDisabled ? 'disabled' : ''} flex-row gap-2 items-center`}>
          <input
            disabled={isDisabled}
            className='resetFormInput'
            {...field}
            {...(props as InputProps)}
          />
          <label className='resetLabel group' htmlFor={props.name}>
            {label}
          </label>
        </div>
        {meta.touched && meta.error ? <div className='error'>{meta.error} </div> : <br />}
      </>
    );
  if (props.create === 'subdivisions')
    return (
      <>
        <div
          className={`resetInput search ${
            isDisabled ? 'disabled' : ''
          } resize-y overflow-auto min-h-[120px]`}
        >
          <label className='resetLabel group' htmlFor={props.name}>
            {label}
          </label>
          <input
            disabled={isDisabled}
            className='resetFormInput'
            {...field}
            {...(props as InputProps)}
            onChange={(e) => setCrSfrD(e.target.value)}
            value={crSfrD}
            autoComplete='off'
            maxLength={60}
          />

          <div className='flex flex-wrap gap-2 max-h-[100px] overflow-auto border-b-1 py-2'>
            {crSfrD ? (
              <span
                onClick={() => {
                  setSubdivisions((s: any) => [...s, { name: crSfrD }]);
                  setCrSfrD('');
                }}
                className='group flex justify-center items-center border-2 rounded-lg gap-2 bg-white p-2 cursor-pointer w-fit hover:scale-125 transition-all duration-500'
              >
                <p>{crSfrD}</p>
                <AiOutlinePlusCircle className='transition-colors duration-[0.5s] group-hover:text-green-500' />
              </span>
            ) : (
              <></>
            )}
          </div>
          <div className='flex flex-wrap gap-2 max-h-[100px] overflow-auto py-2'>
            {props.subdivisions.map((t: any, i: number) => (
              <span
                key={i}
                onClick={() => setSubdivisions((s: any) => s.filter((a: any) => a !== t))}
                className='flex justify-center items-center border-2 rounded-lg gap-2 bg-white p-2 cursor-pointer w-fit'
              >
                <p>{t.name}</p>
                <AiOutlineMinusCircle color='red' />
              </span>
            ))}
          </div>
        </div>
        {meta.touched && meta.error ? <div className='error'>{meta.error} </div> : <br />}
      </>
    );
  if (props.search === 'organization') {
    return (
      <>
        <div className='flex p-1'>
          {sOrganization?.filter((t: any) => !Boolean(chosenOrg.find((c: any) => c.id === t.id)))
            .length ? (
            <div
              onClick={() =>
                setChosenOrg((s: any) => [
                  ...s,
                  ...sOrganization?.filter(
                    (t: any) => !Boolean(chosenOrg.find((c: any) => c.id === t.id)),
                  ),
                ])
              }
              className='group flex justify-center items-center border-2 rounded-lg gap-2 bg-white p-2 cursor-pointer w-fit hover:scale-125 transition-all duration-500'
            >
              <AiOutlinePlusCircle className='transition-colors duration-[0.5s] group-hover:text-green-500' />
              <p className='text-sm'>Add All</p>
            </div>
          ) : (
            <></>
          )}
        </div>

        <div
          className={`resetInput search ${
            isDisabled ? 'disabled' : ''
          } resize-y overflow-auto min-h-[120px]`}
        >
          <label className='resetLabel group' htmlFor={props.name}>
            {label}
          </label>
          <input
            disabled={isDisabled}
            className='resetFormInput'
            {...field}
            {...(props as InputProps)}
            onChange={handleSearchOrganizationByName}
            value={orgN}
            autoComplete='off'
            maxLength={60}
          />
          <div className='flex flex-wrap gap-2 overflow-auto border-b-1 py-2 max-h-[250px]'>
            {sOrganization !== null && sOrganization !== undefined ? (
              sOrganization
                .filter((t: any) => !Boolean(chosenOrg.find((c: any) => c.id === t.id)))
                .map((t: any) => (
                  <span
                    key={t.id}
                    onClick={() => setChosenOrg((s: any) => [...s, t])}
                    className='group flex justify-center items-center border-2 rounded-lg gap-2 bg-white p-2 cursor-pointer w-fit hover:scale-125 transition-all duration-500'
                  >
                    <p>{t.name}</p>
                    <AiOutlinePlusCircle className='transition-colors duration-[0.5s] group-hover:text-green-500' />
                  </span>
                ))
            ) : (
              <></>
            )}
          </div>
          <div className='flex flex-wrap gap-2 overflow-auto py-2 max-h-[250px]'>
            {chosenOrg.map((t: any) => (
              <span
                key={t.id}
                onClick={() => setChosenOrg((s: any) => s.filter((a: any) => a !== t))}
                className='flex justify-center items-center border-2 rounded-lg gap-2 bg-white p-2 cursor-pointer w-fit'
              >
                <p>{t.name}</p>
                <AiOutlineMinusCircle color='red' />
              </span>
            ))}
          </div>
        </div>

        {meta.touched && meta.error ? <div className='error'>{meta.error} </div> : <br />}
      </>
    );
  }
  // if (props.search === 'sponsor')
  //   return (
  //     <>
  //       <div className={`resetInput search ${isDisabled ? 'disabled' : ''}`}
  //         <label className='resetLabel group' htmlFor={props.name}>
  //           {label}
  //         </label>
  //         <input
  //           disabled={isDisabled}
  //           className='resetFormInput'
  //           {...field}
  //           {...(props as InputProps)}
  //           onChange={handleSearchSponsorByName}
  //           value={sponsorN}
  //           autoComplete='off'
  //         />
  //         <div className='flex flex-wrap gap-2 max-h-[100px] overflow-auto border-b-1  py-2'>
  //           {sponsorData !== null && sponsorData !== undefined ? (
  //             sponsorData
  //               .filter((t: any) => !Boolean(chosenSponsor.find((c: any) => c.id === t.id)))
  //               .map((t: any) => (
  //                 <span
  //                   key={t.id}
  //                   onClick={() => setChosenSponsor((s: any) => [...s, t])}
  //                   className='group flex justify-center items-center border-2 rounded-lg gap-2 bg-white p-2 cursor-pointer w-fit hover:scale-125 transition-all duration-500'
  //                 >
  //                   <p>{t.name}</p>
  //                   <AiOutlinePlusCircle className='transition-colors duration-[0.5s] group-hover:text-green-500' />
  //                 </span>
  //               ))
  //           ) : (
  //             <></>
  //           )}
  //         </div>
  //         <div className='flex flex-wrap gap-2 max-h-[100px] overflow-auto py-2'>
  //           {chosenSponsor.map((t: any) => (
  //             <span
  //               key={t.id}
  //               onClick={() => setChosenSponsor((s: any) => s.filter((a: any) => a !== t))}
  //               className='flex justify-center items-center border-2 rounded-lg gap-2 bg-white p-2 cursor-pointer w-fit'
  //             >
  //               <p>{t.name}</p>
  //               <AiOutlineMinusCircle color='red' />
  //             </span>
  //           ))}
  //         </div>
  //       </div>

  //       {meta.touched && meta.error ? <div className='error'>{meta.error} </div> : <br />}
  //     </>
  //   );
  if (props.search === 'sponsor') {
    return (
      <>
        <div className='flex p-1'>
          {sponsorData?.filter((t: any) => !Boolean(chosenSponsor.find((c: any) => c.id === t.id)))
            .length ? (
            <div
              onClick={() =>
                setChosenSponsor((s: any) => [
                  ...s,
                  ...sponsorData?.filter(
                    (t: any) => !Boolean(chosenSponsor.find((c: any) => c.id === t.id)),
                  ),
                ])
              }
              className='group flex justify-center items-center border-2 rounded-lg gap-2 bg-white p-2 cursor-pointer w-fit hover:scale-125 transition-all duration-500'
            >
              <AiOutlinePlusCircle className='transition-colors duration-[0.5s] group-hover:text-green-500' />
              <p className='text-sm'>Add All</p>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div
          className={`resetInput search ${
            isDisabled ? 'disabled' : ''
          } resize-y overflow-auto min-h-[120px]`}
        >
          <label className='resetLabel group' htmlFor={props.name}>
            {label}
          </label>
          <input
            disabled={isDisabled}
            className='resetFormInput'
            {...field}
            {...(props as InputProps)}
            onChange={handleSearchSponsorByName}
            value={sponsorN}
            autoComplete='off'
            maxLength={60}
          />
          <div className='flex flex-wrap gap-2 overflow-auto border-b-1 py-2 max-h-[250px]'>
            {sponsorData !== null && sponsorData !== undefined ? (
              sponsorData
                .filter((t: any) => !Boolean(chosenSponsor.find((c: any) => c.id === t.id)))
                .map((t: any) => (
                  <span
                    key={t.id}
                    onClick={() => setChosenSponsor((s: any) => [...s, t])}
                    className='group flex justify-center items-center border-2 rounded-lg gap-2 bg-white p-2 cursor-pointer w-fit hover:scale-125 transition-all duration-500'
                  >
                    <p>{t.name}</p>
                    <AiOutlinePlusCircle className='transition-colors duration-[0.5s] group-hover:text-green-500' />
                  </span>
                ))
            ) : (
              <></>
            )}
          </div>
          <div className='flex flex-wrap gap-2 overflow-auto py-2 max-h-[250px]'>
            {chosenSponsor.map((t: any) => (
              <span
                key={t.id}
                onClick={() => setChosenSponsor((s: any) => s.filter((a: any) => a !== t))}
                className='flex justify-center items-center border-2 rounded-lg gap-2 bg-white p-2 cursor-pointer w-fit'
              >
                <p>{t.name}</p>
                <AiOutlineMinusCircle color='red' />
              </span>
            ))}
          </div>
        </div>
        {meta.touched && meta.error ? <div className='error'>{meta.error} </div> : <br />}
      </>
    );
  }
  if (props.search === 'game')
    return (
      <>
        <div className={`resetInput search ${isDisabled ? 'disabled' : ''}`}>
          <label className='resetLabel group' htmlFor={props.name}>
            {label}
          </label>
          <input
            disabled={isDisabled}
            className='resetFormInput'
            {...field}
            {...(props as InputProps)}
            onChange={handleSearchGameByName}
            value={gameN}
            autoComplete='off'
            maxLength={60}
          />
          <div className='flex flex-wrap gap-2 max-h-[100px] overflow-auto border-b-1  py-2'>
            {gameData !== null && gameData !== undefined ? (
              gameData
                .filter((t: any) => !Boolean(chosenGame.find((c: any) => c.id === t.id)))
                .map((t: any) => (
                  <span
                    key={t.id}
                    onClick={() => setChosenGame((s: any) => [...s, t])}
                    className='group flex justify-center items-center border-2 rounded-lg gap-2 bg-white p-2 cursor-pointer w-fit hover:scale-125 transition-all duration-500'
                  >
                    <p>{t.name}</p>
                    <AiOutlinePlusCircle className='transition-colors duration-[0.5s] group-hover:text-green-500' />
                  </span>
                ))
            ) : (
              <></>
            )}
          </div>
          <div className='flex flex-wrap gap-2 max-h-[100px] overflow-auto py-2'>
            {chosenGame.map((t: any) => (
              <span
                key={t.id}
                onClick={() => setChosenGame((s: any) => s.filter((a: any) => a !== t))}
                className='flex justify-center items-center border-2 rounded-lg gap-2 bg-white p-2 cursor-pointer w-fit'
              >
                <p>{t.name}</p>
                <AiOutlineMinusCircle color='red' />
              </span>
            ))}
          </div>
        </div>

        {meta.touched && meta.error ? <div className='error'>{meta.error} </div> : <br />}
      </>
    );
  if (props.search === 'matchFormat') {
    return (
      <>
        <div className='flex p-1'>
          {mfData?.filter((t: any) => !Boolean(matchFormat.find((c: any) => c.id === t.id)))
            .length ? (
            <div
              onClick={() =>
                setMatchFormat((s: any) => [
                  ...s,
                  ...mfData?.filter(
                    (t: any) => !Boolean(matchFormat.find((c: any) => c.id === t.id)),
                  ),
                ])
              }
              className='group flex justify-center items-center border-2 rounded-lg gap-2 bg-white p-2 cursor-pointer w-fit hover:scale-125 transition-all duration-500'
            >
              <AiOutlinePlusCircle className='transition-colors duration-[0.5s] group-hover:text-green-500' />
              <p className='text-sm'>Add All</p>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div
          className={`resetInput search ${
            isDisabled ? 'disabled' : ''
          } resize-y overflow-auto min-h-[120px]`}
        >
          <label className='resetLabel group' htmlFor={props.name}>
            {label}
          </label>
          <input
            disabled={isDisabled}
            className='resetFormInput'
            {...field}
            {...(props as InputProps)}
            onChange={handleSearchMatchFormat}
            value={matchFormatGame}
            autoComplete='off'
            maxLength={60}
          />
          <div className='flex flex-wrap gap-2 overflow-auto border-b-1  py-2 max-h-[250px]'>
            {mfData !== null && mfData !== undefined ? (
              mfData
                .filter((t: any) => !Boolean(matchFormat.find((c: any) => c.id === t.id)))
                .map((t: any) => (
                  <span
                    key={t.id}
                    onClick={() => setMatchFormat((s: any) => [...s, t])}
                    className='group flex justify-center items-center border-2 rounded-lg gap-2 bg-white p-2 cursor-pointer w-fit hover:scale-125 transition-all duration-500'
                  >
                    <p>{t.name}</p>
                    <AiOutlinePlusCircle className='transition-colors duration-[0.5s] group-hover:text-green-500' />
                  </span>
                ))
            ) : (
              <></>
            )}
          </div>
          <div className='flex flex-wrap gap-2 overflow-auto py-2'>
            {matchFormat.map((t: any) => (
              <span
                key={t.id}
                onClick={() => setMatchFormat((s: any) => s.filter((a: any) => a !== t))}
                className='flex justify-center items-center border-2 rounded-lg gap-2 bg-white p-2 cursor-pointer w-fit'
              >
                <p>{t.name}</p>
                <AiOutlineMinusCircle color='red' />
              </span>
            ))}
          </div>
        </div>

        {meta.touched && meta.error ? <div className='error'>{meta.error} </div> : <br />}
      </>
    );
  }
  if (props.search === 'team') {
    return (
      <>
        <div className='flex justify-between p-1'>
          {!props.forceState && (
            <DropdownMenuFilter
              width={'w-full'}
              onChange={handleTeamFilterStateChange}
              items={allStates.map((s: any, i) => {
                return {
                  label: s.name,
                  id: i,
                  defaultChecked: teamFilterState?.includes(s.id),
                  name: s.name,
                } as CheckboxItem;
              })}
              onClear={() => setTeamFilterState([])}
              title='State'
            />
          )}
          {(teamSizeValue &&
            teamSizeValue >=
              chosenTeam +
                sTeamNData?.filter(
                  (t: any) =>
                    !Boolean(chosenTeam.find((c: any) => c.id === t.id)) &&
                    t.game_id === chosenGame,
                ).length) ||
          (!teamSizeValue &&
            sTeamNData?.filter(
              (t: any) =>
                !Boolean(chosenTeam.find((c: any) => c.id === t.id)) && t.game_id === chosenGame,
            ).length) ? (
            <div
              onClick={() =>
                setChosenTeam((s: any) => [
                  ...s,
                  ...sTeamNData?.filter(
                    (t: any) =>
                      !Boolean(chosenTeam.find((c: any) => c.id === t.id)) &&
                      t.game_id === chosenGame,
                  ),
                ])
              }
              className='group flex justify-center items-center border-2 rounded-lg gap-2 bg-white p-2 cursor-pointer w-fit hover:scale-125 transition-all duration-500'
            >
              <AiOutlinePlusCircle className='transition-colors duration-[0.5s] group-hover:text-green-500' />
              <p className='text-sm'>Add All</p>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div
          className={`resetInput search ${
            isDisabled || teamSizeValue <= chosenTeam.length || !chosenGame || !chosenState
              ? 'disabled'
              : ''
          } resize-y overflow-auto min-h-[120px]`}
        >
          <label className='resetLabel group' htmlFor={props.name}>
            {label}
          </label>
          <input
            disabled={
              isDisabled || teamSizeValue <= chosenTeam.length || !chosenGame || !chosenState
            }
            className='resetFormInput'
            {...field}
            {...(props as InputProps)}
            onChange={handleSearchTeamByName}
            value={sTeamN}
            autoComplete='off'
            maxLength={60}
          />
          <div className={`flex flex-wrap gap-2 overflow-auto border-b-1 py-2`}>
            {sTeamNData !== null && sTeamNData !== undefined ? (
              sTeamNData
                .filter(
                  (t: any) =>
                    !Boolean(chosenTeam.find((c: any) => c.id === t.id)) &&
                    t.game_id === chosenGame,
                )
                .map((t: any) => (
                  <span
                    key={t.id}
                    onClick={() => {
                      teamSizeValue
                        ? teamSizeValue > chosenTeam.length && setChosenTeam((s: any) => [...s, t])
                        : setChosenTeam((s: any) => [...s, t]);
                    }}
                    className='group flex justify-center items-center border-2 rounded-lg gap-2 bg-white p-2 cursor-pointer w-fit hover:scale-125 transition-all duration-500'
                  >
                    <p>{new TeamModel(t).getOptionName()}</p>
                    <AiOutlinePlusCircle className='transition-colors duration-[0.5s] group-hover:text-green-500' />
                  </span>
                ))
            ) : (
              <></>
            )}
          </div>
          {!chosenGame || chosenState?.length < 1 ? (
            <div className='error'>Please select the game and state!</div>
          ) : null}
          {teamSizeValue && teamSizeValue <= chosenTeam.length ? (
            <div className='error'>
              Cant add more teams, the team size limit is: {teamSizeValue}
            </div>
          ) : (
            <></>
          )}
          <div className={`flex flex-wrap gap-2 overflow-auto py-2  break-words`}>
            {chosenTeam.map((t: any) => (
              <span
                key={t.id}
                onClick={() => setChosenTeam((s: any) => s.filter((a: any) => a !== t))}
                className='flex justify-center items-center border-2 rounded-lg gap-2 bg-white p-2 cursor-pointer w-full break-words'
              >
                <p className='break-words w-full'>{new TeamModel(t).getOptionName()}</p>
                <AiOutlineMinusCircle color='red' />
              </span>
            ))}
          </div>
        </div>

        {meta.touched && meta.error ? <div className='error'>{meta.error} </div> : <br />}
      </>
    );
  }
  if (props.search === 'seasonStageTeam') {
    useEffect(() => {
      if (props.chosenTeamSelectionMethod === SeasonStageTeamSelectionMethod.ManuallySelectTeams.id)
        setChosenTeam(props.seasonStageTeams ? props.seasonStageTeams : []);
    }, [props.chosenTeamSelectionMethod, props.seasonTeams]);
    switch (props.chosenTeamSelectionMethod) {
      case SeasonStageTeamSelectionMethod.AllTeams.id:
        setChosenTeam(props.seasonTeams);
        return <></>;
      case SeasonStageTeamSelectionMethod.ManuallySelectTeams.id:
        return (
          <>
            <div
              className={`resetInput search ${
                isDisabled ? 'disabled' : ''
              } resize-y overflow-auto min-h-[120px]`}
            >
              <label className='resetLabel group' htmlFor={props.name}>
                {label}
              </label>
              <input
                disabled={isDisabled || teamSizeValue <= chosenTeam.length}
                className='resetFormInput'
                {...field}
                {...(props as InputProps)}
                onChange={handleSearchTeamByName}
                value={sTeamN}
                autoComplete='off'
                maxLength={60}
              />
              <div className={`flex flex-wrap gap-2 overflow-auto border-b-1 py-2`}>
                {props.seasonTeams !== null && props.seasonTeams !== undefined ? (
                  props.seasonTeams
                    .filter(
                      (t: any) =>
                        !Boolean(chosenTeam.find((c: any) => c.id === t.id)) &&
                        t.game_id === chosenGame,
                    )
                    .map((t: any) => (
                      <span
                        key={t.id}
                        onClick={() => {
                          setChosenTeam((s: any) => [...s, t]);
                        }}
                        className='group flex justify-center items-center border-2 rounded-lg gap-2 bg-white p-2 cursor-pointer w-fit hover:scale-125 transition-all duration-500'
                      >
                        <p>{new TeamModel(t).getOptionName()}</p>
                        <AiOutlinePlusCircle className='transition-colors duration-[0.5s] group-hover:text-green-500' />
                      </span>
                    ))
                ) : (
                  <></>
                )}
              </div>
              <div className={`flex flex-wrap gap-2 overflow-auto py-2  break-words`}>
                {chosenTeam.map((t: any) => (
                  <span
                    key={t.id}
                    onClick={() => setChosenTeam((s: any) => s.filter((a: any) => a !== t))}
                    className='flex justify-center items-center border-2 rounded-lg gap-2 bg-white p-2 cursor-pointer w-full break-words'
                  >
                    <p className='break-words w-full'>{new TeamModel(t).getOptionName()}</p>
                    <AiOutlineMinusCircle color='red' />
                  </span>
                ))}
              </div>
            </div>

            {meta.touched && meta.error ? <div className='error'>{meta.error} </div> : <br />}
          </>
        );
      case SeasonStageTeamSelectionMethod.SelectTopTeams.id:
        return (
          <div className='mb-6'>
            <div className={`resetInput ${isDisabled ? 'disabled' : ''}`}>
              <label className='resetLabel group' htmlFor={props.name}>
                Number of Teams
              </label>
              <input
                className='resetFormInput'
                {...(props as InputProps)}
                onChange={(e) => {
                  setChosenTeam(
                    props.prevSeasonStageTeams
                      ?.slice(0, Number(e.target.value))
                      .map((standing: any) => {
                        return { id: standing.team_id };
                      }),
                  );
                }}
                type='number'
              />
            </div>
          </div>
        );

      default:
        return <></>;
    }
  }
  if (props.search === 'subdivision')
    return (
      <>
        <div className='flex p-1'>
          {sSubDivisionNData?.filter(
            (sd: any) =>
              !Boolean(chosenSubDivision.find((c: any) => c.id === sd.id)) &&
              sd.division_id === divisionId,
          ).length ? (
            <div
              onClick={() =>
                setChosenSubDivision((s: any) => [
                  ...s,
                  ...sSubDivisionNData.filter(
                    (sd: any) =>
                      !Boolean(chosenSubDivision.find((c: any) => c.id === sd.id)) &&
                      sd.division_id === divisionId,
                  ),
                ])
              }
              className='group flex justify-center items-center border-2 rounded-lg gap-2 bg-white p-2 cursor-pointer w-fit hover:scale-125 transition-all duration-500'
            >
              <AiOutlinePlusCircle className='transition-colors duration-[0.5s] group-hover:text-green-500' />
              <p className='text-sm'>Add All</p>
            </div>
          ) : (
            <></>
          )}
        </div>

        <div
          className={`resetInput search ${
            isDisabled ? 'disabled' : ''
          } resize-y overflow-auto min-h-[120px]`}
        >
          <label className='resetLabel group' htmlFor={props.name}>
            {label}
          </label>
          <input
            disabled={isDisabled}
            className='resetFormInput'
            {...field}
            {...(props as InputProps)}
            onChange={handleSearchSubDivisionByName}
            value={sSubDivisionN}
            autoComplete='off'
            maxLength={60}
          />
          <div className='flex flex-wrap gap-2 overflow-auto border-b-1 py-2 max-h-[250px]'>
            {sSubDivisionNData !== null && sSubDivisionNData !== undefined ? (
              sSubDivisionNData
                .filter(
                  (sd: any) =>
                    !Boolean(chosenSubDivision.find((c: any) => c.id === sd.id)) &&
                    sd.division_id === divisionId,
                )
                .map(
                  (sd: any) => (
                    // {
                    // sd.division_id === 1 ?
                    <span
                      key={sd.id}
                      onClick={() => setChosenSubDivision((s: any) => [...s, sd])}
                      className='group flex justify-center items-center border-2 rounded-lg gap-2 bg-white p-2 cursor-pointer w-fit hover:scale-125 transition-all duration-500'
                    >
                      <p>{sd.name}</p>
                      <AiOutlinePlusCircle className='transition-colors duration-[0.5s] group-hover:text-green-500' />
                    </span>
                  ),
                  //     : <></>
                  // }
                )
            ) : (
              <></>
            )}
          </div>
          <div className='flex flex-wrap gap-2 overflow-auto py-2 max-h-[250px]'>
            {chosenSubDivision
              .filter((sd: any) => sd.division_id === divisionId)
              .map((sd: any) => (
                <span
                  key={sd.id}
                  onClick={() => setChosenSubDivision((s: any) => s.filter((a: any) => a !== sd))}
                  className='flex justify-center items-center border-2 rounded-lg gap-2 bg-white p-2 cursor-pointer w-fit'
                >
                  <p>{sd.name}</p>
                  <AiOutlineMinusCircle color='red' />
                </span>
              ))}
          </div>
        </div>

        {/* {
          setChosenSubDivision((s: any) => s.filter((a: any) => a === divisionId))
        } */}

        {meta.touched && meta.error ? <div className='error'>{meta.error} </div> : <br />}
      </>
    );
  if (props.search === 'coach')
    return (
      <>
        <div className={`resetInput search ${isDisabled ? 'disabled' : ''}`}>
          <label className='resetLabel group' htmlFor={props.name}>
            {label}
          </label>
          <input
            disabled={isDisabled}
            className='resetFormInput'
            {...field}
            {...(props as InputProps)}
            onChange={handleSearchCoachByName}
            value={scoach}
            autoComplete='off'
            maxLength={60}
          />
          <div className='flex flex-wrap gap-2 '>
            {coachData !== null && coachData !== undefined ? (
              coachData
                .filter((t: any) => !Boolean(props.chosenCoaches.find((c: any) => c.id === t.id)))
                .map((t: any) => (
                  <span
                    key={t.id}
                    onClick={() => props.setChosenCoaches((s: any) => [...s, t])}
                    className='group flex justify-center items-center border-2 rounded-lg gap-2 bg-white p-2 cursor-pointer w-fit hover:scale-125 transition-all duration-500'
                  >
                    <p>{t.firstName}</p>
                    <AiOutlinePlusCircle className='transition-colors duration-[0.5s] group-hover:text-green-500' />
                  </span>
                ))
            ) : (
              <></>
            )}
          </div>
          <div className='flex flex-wrap gap-2'>
            {props.chosenCoaches.map((t: any) => (
              <span
                key={t.id}
                onClick={() => props.setChosenCoaches((s: any) => s.filter((a: any) => a !== t))}
                className='flex justify-center items-center border-2 rounded-lg gap-2 bg-white p-2 cursor-pointer w-fit'
              >
                <p>{t.firstName}</p>
                <AiOutlineMinusCircle color='red' />
              </span>
            ))}
          </div>
        </div>

        {meta.touched && meta.error ? <div className='error'>{meta.error} </div> : <br />}
      </>
    );

  if (props.search === 'studentEmail')
    return (
      <>
        <div className={`resetInput search ${isDisabled ? 'disabled' : ''}`}>
          <label className='resetLabel group' htmlFor={props.name}>
            {label}
          </label>
          <input
            disabled={isDisabled}
            className='resetFormInput'
            {...field}
            {...(props as InputProps)}
            onChange={handleSearchStudentWithoutGuardian}
            value={studentsEmail}
            autoComplete='off'
            maxLength={60}
          />
          <div className='flex flex-wrap gap-2 max-h-[90vh] overflow-auto border-b-1 py-2'>
            {studentsEmail.length ? (
              <span
                onClick={() => {
                  const isAlreadyChosen = props.chosenStudent.some(
                    (chosen: any) => chosen.email.toLowerCase() === studentsEmail.toLowerCase(),
                  );
                  if (!isAlreadyChosen) {
                    props.setChosenStudent((s: any) => [...s, { email: studentsEmail }]);
                    setStudentsEmail('');
                  } else {
                    const updatedChosenStudents = [...props.chosenStudent];
                    updatedChosenStudents[isAlreadyChosen] = { email: studentsEmail };
                    props.setChosenStudent(updatedChosenStudents);
                    setStudentsEmail('');
                  }
                }}
                // className='group flex justify-center items-center border-2 rounded-lg gap-2 bg-white p-2 cursor-pointer w-fit hover:scale-125 transition-all duration-500'
                className={`group flex justify-center items-center rounded-lg gap-2 p-2 cursor-pointer w-fit ${
                  emailRegex.test(studentsEmail)
                    ? 'bg-white border-2 hover:scale-125 transition-all duration-500'
                    : 'border-2 border-transparent'
                }`}
              >
                {emailRegex.test(studentsEmail) && <p>{studentsEmail}</p>}
                {emailRegex.test(studentsEmail) && (
                  <AiOutlinePlusCircle className='transition-colors duration-[0.5s] group-hover:text-green-500' />
                )}
              </span>
            ) : (
              <></>
            )}
          </div>

          <div className='flex flex-wrap gap-2 h-[100px] overflow-auto py-2'>
            {props.chosenStudent.map((t: any) => (
              <span
                key={t.id}
                onClick={() => props.setChosenStudent((s: any) => s.filter((a: any) => a !== t))}
                className='flex justify-center items-center border-2 rounded-lg gap-2 bg-white p-2 cursor-pointer w-fit h-fit'
              >
                <p>{t.email}</p>
                <AiOutlineMinusCircle color='red' />
              </span>
            ))}
          </div>
        </div>

        {meta.touched && meta.error ? <div className='error'>{meta.error} </div> : <br />}
      </>
    );

  if (props.type === 'image')
    return (
      <>
        <div className={`resetInput ${isDisabled ? 'disabled' : ''}`}>
          <label className='resetLabel group' htmlFor={props.name}>
            {label}
          </label>
          <div className='flex justify-between'>
            <input
              // {...field}
              disabled={isDisabled}
              onChange={(e) => {
                isArr
                  ? setFieldValue([
                      ...fieldValue,
                      { file: e.target.files ? e.target.files[0] : null, name: props.name },
                    ])
                  : setFieldValue(e.target.files ? e.target.files[0] : null);
              }}
              type='file'
              name={props.name}
              id=''
              className='form-control flex justify-between  hover:file:bg-opacity-80 active:file:bg-opacity-80 file:cursor-pointer file:justify-between  file:bg-gray-300 file:outline-none file:border-none file:rounded-lg file:text-xs file:p-1 file:px-3 file:text-gray-800 text-gray-400 font-xs'
              accept={accept ?? ''}
            />
          </div>
        </div>
        {meta.touched && meta.error ? <div className='error'>{meta.error} </div> : <br />}
      </>
    );
  if (props.type === 'csv')
    return (
      <>
        <div className={`resetInput ${isDisabled ? 'disabled' : ''}`}>
          <label className='resetLabel group' htmlFor={props.name}>
            {label}
          </label>
          <div className='flex justify-between'>
            <input
              // {...field}
              disabled={isDisabled}
              onChange={(e) => {
                setFieldValue(e.target.files ? e.target.files[0] : null);
              }}
              type='file'
              name={props.name}
              id=''
              className='form-control flex justify-between  hover:file:bg-opacity-80 active:file:bg-opacity-80 file:cursor-pointer file:justify-between  file:bg-gray-300 file:outline-none file:border-none file:rounded-lg file:text-xs file:p-1 file:px-3 file:text-gray-800 text-gray-400 font-xs'
              accept='.csv'
            />
          </div>
        </div>
        {meta.touched && meta.error ? <div className='error'>{meta.error} </div> : <br />}
      </>
    );
  if (props.type === 'textarea') {
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
      if (textareaRef.current) {
        textareaRef.current.style.height = 'auto';
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      }
    }, [field.value]);
    return (
      <>
        <div className={`resetInput ${isDisabled ? 'disabled' : ''}`}>
          <label className='resetLabel group' htmlFor={props.name}>
            {label}
          </label>
          <textarea
            ref={textareaRef}
            className='resetFormInput'
            {...field}
            {...(props as TextareaProps)}
          />
        </div>
        {meta.touched && meta.error ? <div className='error'>{meta.error} </div> : <br />}
      </>
    );
  }
  if (props.type === 'description')
    return (
      <>
        <div className={`resetInput-textarea ${isDisabled ? 'disabled' : ''}`}>
          <label className='resetLabel group' htmlFor={props.name}>
            {label}
          </label>
          <textarea
            className='resetFormInput'
            {...field}
            {...(props as TextareaProps)}
            rows={8}
            cols={50}
            minLength={20}
            maxLength={1000}
          />
        </div>
        {meta.touched && meta.error ? <div className='error'>{meta.error} </div> : <br />}
      </>
    );
  if (props.type === 'disabled') return <></>;
  // if (props.type === 'multiSelect')
  //   return (
  //     <DropdownMenuFilter
  //       width={'w-full'}
  //       onChange={handleChosenStateChange}
  //       items={allStates.map((s: any, i) => {
  //         return {
  //           label: s.name,
  //           id: i,
  //           defaultChecked: teamFilterState?.includes(s.id),
  //           name: s.name,
  //         } as CheckboxItem;
  //       })}
  //       onClear={() => setTeamFilterState([])}
  //       title='State'
  //     />
  //   );
  if (isSelect)
    return (
      <Combobox
        className={isDisabled ? 'disabled' : ''}
        meta={meta}
        field={field}
        options={options}
        label={label}
        name={props.name}
        setFieldValue={setFieldValue ?? null}
        containsAsteriks={containsAsterik}
        props={props}
      />
    );
  if (props.isMultiSelect)
    return (
      <MultiSelectComboBox
        className={isDisabled ? 'disabled' : ''}
        meta={meta}
        field={{ ...field, multiple: true }}
        options={options}
        label={label}
        name={props.name}
        setFieldValue={setFieldValue ?? null}
        containsAsteriks={containsAsterik}
        selectedList={props.selectedValues}
        props={{ ...props, multiple: true }}
      />
    );
  // return (
  //   <>
  //     <div className={`resetInput ${isDisabled ? 'disabled' : ''}`}>
  //       {containsAsterik ? (
  //         <label
  //           className="resetLabel group after:content-['*'] after:text-red-500"
  //           htmlFor={props.name}
  //         >
  //           {label}
  //         </label>
  //       ) : (
  //         <label className='resetLabel group' htmlFor={props.name}>
  //           {label}
  //         </label>
  //       )}
  //       {setFieldValue ? (
  //         <select
  //           className='resetFormInput'
  //           {...field}
  //           {...(props as SelectProps)}
  //           onChange={(e: any) => setFieldValue(e.target.value)}
  //         >
  //           <option value='' label='Please select an option' />
  //           {options?.map((o: any) => (
  //             <option
  //               key={o.id}
  //               value={o.id}
  //               label={props.selectByUsername ? o.username : o.name}
  //             />
  //           ))}
  //         </select>
  //       ) : (
  //         <select className='resetFormInput' {...field} {...(props as SelectProps)}>
  //           <option
  //             value=''
  //             disabled={props.name === 'orderRank'}
  //             label='Please select an option'
  //           />
  //           {options?.length &&
  //             options?.map((o: any) => (
  //               <option
  //                 key={o.id}
  //                 value={o.id}
  //                 label={props.selectByUsername ? o.username : o.name}
  //               />
  //             ))}
  //         </select>
  //       )}
  //     </div>
  //     {meta.touched && meta.error ? <div className='error'>{meta.error} </div> : <br />}
  //   </>
  // );
  return (
    <>
      <div className={`resetInput ${isDisabled ? 'disabled' : ''}`}>
        <label className='resetLabel group' htmlFor={props.name}>
          {label}
        </label>
        <input
          disabled={isDisabled}
          className='resetFormInput'
          max={props.type === 'date' ? '2100-12-31' : ''}
          {...field}
          {...(props as InputProps)}
        />
      </div>

      {meta.touched && meta.error ? <div className='error'>{meta.error} </div> : <br />}
    </>
  );
}
