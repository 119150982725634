import React from 'react'
import { Navigate, useParams } from 'react-router';
import ModalUnarchive from '../archived/components/unarchive-modal';
import { useUnarchiveCategoryMutation } from '../../slices/supportCategoryApiSlice';

const UnarchiveCategoryModal = () => {
    const param = useParams();
    const [unarchive, { isLoading, isError, isSuccess }] = useUnarchiveCategoryMutation();

    const onSubmit = () => {
        unarchive(param?.id);
    };

    if (isSuccess) return <Navigate to='../' />;

    return <ModalUnarchive onSubmit={onSubmit} isLoading={isLoading} name={param.name ?? ''} />;
};

export default UnarchiveCategoryModal;