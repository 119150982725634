import React from 'react';
import CanIView from '../../../../../../components/can-i-view/can-i-view';
import { AiOutlineCamera } from 'react-icons/ai';
import { GameBox } from '../../../components/game-box';
import { TeamInfo } from '../../../components/team-info';
import { AvatarImage } from '../../../../../../components/avatar-image';
import { selectUser } from '../../../../../../slices/auth';
import { useSelector } from 'react-redux';
import { UserRoles } from '../../../../../../utils/constants';

const TeamHeader = ({ teamData, handleFileChange, isLoading, matchStats }: any) => {
  const user = useSelector(selectUser);
  const canEdit = () => {
    switch (user?.roles) {
      case UserRoles.Admin:
        return true;
      case UserRoles.Ogma:
        return user?.ogma_org?.some(
          (org: any) =>
            teamData.organizations.map((teamOrg: any) => teamOrg.id).includes(org.id) ||
            user?.ogma_org?.filter((org: any) => org.id === teamData?.School?.organization?.id)
              .length > 0,
        );
      default:
        return false;
    }
  };
  return (
    <div className='flex justify-evenly items-center gap-2 p-5 border rounded-xl'>
      <div className='group flex relative w-[100px] aspect-square rounded-full overflow-hidden justify-center items-center '>
        {isLoading ? <p>Loading ...</p> : <AvatarImage size='xxl' source={teamData.image} />}

        <CanIView component='team.changePhoto'>
          {canEdit() && (
            <label className='cursor-pointer text-white  w-full bg-black bg-opacity-80 h-full absolute top-0 right-0 left-0 bottom-0 m-auto z-10 hidden group-hover:flex justify-center items-center  '>
              <AiOutlineCamera />
              <input
                type='file'
                accept='image/*'
                onChange={handleFileChange}
                className='hidden mr-12'
              />
            </label>
          )}
        </CanIView>
      </div>
      <TeamInfo
        name={teamData.teamName}
        school={teamData.School ? teamData.School.name : '-'}
        members={teamData.User.length}
        game={teamData?.Game?.name}
      />
      <div className='flex justify-evenly flex-[3] items-center'>
        <GameBox count={matchStats.completed} description='Done' />{' '}
        <GameBox count={matchStats.wins} description='Wins' />{' '}
        <GameBox count={matchStats.losses} description='Losses' />{' '}
      </div>
    </div>
  );
};

export default TeamHeader;
