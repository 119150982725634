import { OutsideClick } from '../../components/outside-click';
import { Link, Navigate, useParams } from 'react-router-dom';
import { SubmitButton } from '../../components/submit-button';
import {
  useDisconnectChildTicketsMutation,
  useGetTicketByIdQuery,
} from '../../slices/supportTicketApiSlice';
import { useState } from 'react';

const DisconnectChildTicketModal = () => {
  interface Iticket {
    id: number;
    title: string;
  }

  const param = useParams();
  const [checkedTickets, setCheckedTickets] = useState<any[]>([]);
  const {
    data: tickets,
    error,
    isLoading: fetchingTicketDataLoading,
  } = useGetTicketByIdQuery(param.id);
  const [disconnectTickets, { isLoading: sendRequestLoading, isSuccess: disconnectTicketSuccess }] =
    useDisconnectChildTicketsMutation();
  const [err, setErr] = useState<string>('');

  const handleCheckboxChange = (ticketId: number) => {
    if (checkedTickets.some((ticket: Iticket) => ticket.id === ticketId)) {
      setCheckedTickets(checkedTickets.filter((ticket: Iticket) => ticket.id !== ticketId));
    } else {
      const ticket = { id: ticketId };
      setCheckedTickets([...checkedTickets, ticket]);
    }
  };

  const onSubmit = (value: any, e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!checkedTickets?.length) {
      setErr('Please check at least one ticket');
    } else {
      const ticketIds = checkedTickets.map((ticket: Iticket) => ({ id: ticket.id }));
      const bodyReq = {
        parentTicketId: Number(param.id),
        ticketIds: ticketIds,
      };
      disconnectTickets(bodyReq);
    }
  };

  if (tickets) {
    console.log('tickets', tickets);
  }

  if (fetchingTicketDataLoading) {
    return <p>Loading</p>;
  }

  if (disconnectTicketSuccess) {
    return <Navigate to='../' />;
  }

  return (
    <div className='fixed z-20 top-0 left-0 h-[100vh] w-[100vw] flex justify-center items-center'>
      <OutsideClick />
      {tickets?.childTickets?.length ? (
        <div className='flex flex-col items-center gap-5 z-20 p-10 bg-white rounded-xl shadow-xl'>
          <p>
            Disconnect child tickets<span className='font-bold'>{}</span>
          </p>
          <form onSubmit={(e) => onSubmit(checkedTickets, e)}>
            <div>
              {tickets?.childTickets?.map((ticket: Iticket) =>
                ticket.id === Number(param.id) ? null : (
                  <div className='max-h-20 overflow-y-auto' key={ticket?.id}>
                    <label key={ticket.id}>
                      <input
                        type='checkbox'
                        name={`checkedTickets[${ticket.id}]`}
                        checked={checkedTickets.some(
                          (checkedTicket: Iticket) => checkedTicket.id === ticket.id,
                        )}
                        onChange={() => handleCheckboxChange(ticket.id)}
                      />
                      <span className='ml-2 mb-2'>{ticket?.title}</span>
                    </label>
                  </div>
                ),
              )}
            </div>
            {err ? (
              <div className='flex justify-center my-3'>
                {err && <span className='error text-center text-[14px]'>{err}</span>}
              </div>
            ) : null}
            <div className='flex justify-center mt-4 mb-4'>
              <SubmitButton isLoading={sendRequestLoading} />
              <Link className='ml-4 btn cancel' to='../'>
                Cancel
              </Link>
            </div>
          </form>
        </div>
      ) : (
        <div className='flex flex-col items-center gap-5 z-20 p-10 bg-white rounded-xl shadow-xl'>
          <p className='text-sm'>No child tickets available to disconnect</p>
        </div>
      )}
    </div>
  );
};

export default DisconnectChildTicketModal;
