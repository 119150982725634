import React from 'react';
import { Link } from 'react-router-dom';
import { AvatarImage } from '../../../components/avatar-image';
export function StudentDashTeamRow({
  id,
  name,
  school,
  schoolId,
  image,
}: {
  id: number;
  name: string;
  school: string;
  schoolId: number;
  image?: string;
}) {
  return (
    <Link className='group hover:bg-gray-300 rounded-xl' to={`/teams/view/${id}`}>
      <div className='flex justify-between '>
        <span className='flex gap-2 items-center p-2 pl-5'>
          <AvatarImage source={image ? image : ''} size='xl' />
          <p className='max-w-[20ch] truncate'>{name}</p>
        </span>
        {/* <Link to={`/schools/view/${schoolId}`}>
        <p>{school}</p>
      </Link> */}
      </div>
    </Link>
  );
}
