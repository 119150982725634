import { FormInput } from './../../components/form-input/form-input';
import { Field, Form, Formik, useField } from 'formik';
import { Navigate, useLoaderData, useParams } from 'react-router-dom';
import logo from '../../assets/logo2.png';
import padlock2 from '../../assets/padlock2.svg';
import { useResetPasswordMutation } from '../../slices/authApiSlice';
import { ResetPasswordValidation } from './yupschema';
import { useDispatch, useSelector } from 'react-redux';
import { selectToken, setCredentials } from '../../slices/auth';
import { FaUserLock } from 'react-icons/fa';


export async function loader({ request, params }: any) {
  const baseUrl = import.meta.env.VITE_API_URL;
  let user;
  let isError = false;
  const response = await fetch(`${baseUrl}auth/validateLink`, {
    method: 'post',
    headers: new Headers({
      Authorization: `Bearer ${params.token}`,
    }),
    
  }).then((data) => (user = data));
   
  console.log(user);
  return user;
}
function ResetPasswordForm() {
  const loaderData:any = useLoaderData();
  console.log(loaderData);
  const [resetPassword, { data }] = useResetPasswordMutation();
  // const [newField, newMeta] = useField("newPassword");
  // const [confirmField, confirmMeta] = useField("confirmPassword");
  const token = useSelector(selectToken);
  const params = useParams();
  const dispatch = useDispatch();

  const handleSubmit = (data: { newPassword: string; confirmPassword: string }) => {
    resetPassword({
      newPassword: data.newPassword,
      confirmPassword: data.confirmPassword,
      token: `Bearer ${params.token}`,
    })
      .unwrap()
      .then((data) =>
        data.status > 200
          ? console.log(data)
          : // ? setFormError(data.message)
            dispatch(setCredentials({ user: data.user, accessToken: data.token })), 
      )
      .catch((error) => console.log(error));
  };
  const IsError=loaderData.response?.message;
  const statusCode=loaderData.response?.statusCode;



  if (token) return <Navigate to='/' />;
  return (
    <div className='flex flex-col min-h-[100vh] h-full w-full relative'>
      <div className='w-full h-100 flex  border-b-2  p-5 border-black'>
        <img width="196" height="49" src={logo} alt='Fenworks Logo' />
      </div>
      
          {/* @ts-ignore */}
          {(statusCode>204) ? (
            (IsError==="Token has expired" || IsError==="Token is invalid")?
            (
      
              (IsError==="Token has expired")?
              (<>
                <h2 className='font-inter text-5xl'>This link has expired.</h2>
              </>
              ):(
              <>
                <h2 className='font-inter text-5xl'>Wrong Link!</h2>
              </>
                )
                ):
                (
                  <>
                    <h2 className='font-inter text-5xl'>Wrong Link</h2>
                  </>
                )
          ) : (
            <>
            <div className='flex flex-col flex-1 w-[100vw] relative justify-center items-center '>
        <div className='flex flex-col gap-10 w-full text-center justify-center items-center max-w-[700px]'></div>
              <h2 className='font-inter text-3xl font-medium'>Reset Password</h2>
              <div className='flex flex-col gap-10 items-center w-[300px]'>
                <FaUserLock size={100} color={'#2e2e2e'} />

                {/* <img className='h-20' src={padlock2} alt='Lock' /> */}
                <Formik
                  initialValues={{
                    newPassword: '',
                    confirmPassword: '',
                  }}
                  validationSchema={ResetPasswordValidation}
                  onSubmit={handleSubmit}
                >
                  {({ isSubmitting, errors }) => (
                    <Form className='flex flex-col gap-2 w-[300px]'>
                      <FormInput
                        label='New Password'
                        placeholder='Enter new password'
                        type='password'
                        id='newPassword'
                        name='newPassword'
                      />
                      {/* {errors.newPassword && errors.newPassword} */}
                      <FormInput
                        label='Confirm Password'
                        placeholder='Re-enter password'
                        type='password'
                        id='confirmPassword'
                        name='confirmPassword'
                      />

                      {/* {errors.confirmPassword && errors.confirmPassword} */}
                      <button className='bg-red-700 text-white rounded-xl px-2 py-1' type='submit'>
                        Reset
                      </button>
                    </Form>
                  )}
                  
                </Formik>
                </div>
              </div>
              {/*  */}
            </>
          )}
        </div>
    //   </div>
    // </div>
  );
}

export default ResetPasswordForm;
