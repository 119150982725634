import { UserRoles } from '../../../utils/constants';

export const coachPermissions = {
  'coach.newCoach': [UserRoles.Admin],
  'coach.about.edit': [UserRoles.Coach],
  'coach.classrooms.new': [UserRoles.Coach, UserRoles.Admin],
  'coach.nav.trainingContent': [UserRoles.Coach, UserRoles.Admin],
  'coach.changePhoto': [UserRoles.Admin, UserRoles.Coach],
  'coach.changeBanner': [UserRoles.Admin, UserRoles.Coach],
};
