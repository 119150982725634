import { Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { FormInput } from '../../../components/form-input/form-input';
import { OutsideClick } from '../../../components/outside-click';
import { selectUser } from '../../../slices/auth';
import {
  useCreateRescheduleMutation,
  useGetCanRescheduleMatchByMatchIdQuery,
} from '../../../slices/matchApiSlice';
import {
  selectMatchData,
  selectReschedules,
  setReschedules,
  updateMatchData,
} from '../../../slices/matchSlice';
import ModalHeader from '../../../components/modal-header';
import ModalFooter from '../../../components/modal-footer';
import { MatchStatus } from '../../../utils/constants';
import { CustomError } from '../../../utils/custom-types';

export function MatchDetailsReschedule({}) {
  const param = useParams();
  const [createReschedule, { isLoading, isError, isSuccess }] = useCreateRescheduleMutation();
  const {
    data,
    isLoading: fetchingAuthLoading,
    isError: authError,
    error,
  } = useGetCanRescheduleMatchByMatchIdQuery(param?.id);

  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const matchData = useSelector(selectMatchData);
  useEffect(() => {
    console.log('user ', user);
  }, [user]);
  const formInputs = [
    {
      mainTitle: 'Proposed Time',
      name: 'reschedule_date',
      label: 'Proposed Date',
      type: 'datetime-local',
      placeholder: 'Select or Input Date and time of the match',
      isTrue: true,
    },
  ];

  const navigate = useNavigate();
  const rescheduleData = useSelector(selectReschedules);
  const onSubmit = (values: any) => {
    const data = values;
    data.new_date = new Date(values.reschedule_date).toISOString();
    delete data.reschedule_date;
    data.requested = user.id;
    data.match_id = Number(param.id);
    console.log('dataa = ', data);

    navigate(-1);
    createReschedule(data)
      .unwrap()
      .then((res: any) => {
        dispatch(setReschedules([...rescheduleData, res]));
        dispatch(updateMatchData({ status: MatchStatus.RescheduleRequested }));
      });
  };

  const initialValues: { [key: string]: string } = {};
  formInputs.forEach((input) => (initialValues[input.name] = ''));

  if (
    matchData.status !== MatchStatus.Upcoming &&
    matchData.status !== MatchStatus.UpcomingRescheduledFor &&
    matchData.status !== MatchStatus.RescheduleRequested &&
    matchData.status !== MatchStatus.Overdue
  ) {
    return <Navigate to='../' />;
  }
  if (fetchingAuthLoading) return <p>Loading...</p>;

  if (authError)
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError).data.message }} />;

  return (
    <>
      <OutsideClick />
      <Formik
        // validationSchema={EditSeasonVSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {() => {
          return (
            <Form>
              <div className='fixed pb-20 top-0 left-0 right-0 z-10 w-auto my-6 mx-auto max-w-3xl max-h-[100vh] '>
                {/*content*/}
                <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-full overflow-auto'>
                  {/*header*/}
                  <ModalHeader name='Request a Reschedule' />
                  <div className='relative p-6 flex-auto'>
                    {formInputs.map((input: any, i: number) => (
                      <React.Fragment key={i}>
                        {!formInputs[i - 1] || formInputs[i - 1]?.mainTitle !== input.mainTitle ? (
                          <>
                            <div className='flex flex-col'>
                              <div className='flex flex-wrap gap-1'>
                                {formInputs
                                  .filter((inp1: any) => inp1.mainTitle === input.mainTitle)
                                  .map((inp: any, i: any) => (
                                    <React.Fragment key={i}>
                                      <div
                                        className={inp.isTrue ? 'w-full ' : 'flex-1 min-w-[300px]'}
                                      >
                                        <FormInput {...inp} />
                                      </div>
                                    </React.Fragment>
                                  ))}
                              </div>
                            </div>

                            <div className='bg-blue-200 px-4 py-2 rounded-lg'>
                              <p>
                                Note: This will create a reschedule request. The opposing team needs
                                to accept the request before the match can be rescheduled.
                              </p>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                  {/*footer*/}
                  <ModalFooter isLoading={isLoading} />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
