import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import CustomInput from '../../authentication/CustomInput';

import { useStateContext } from '../../../contexts/ContextProvider';
import { Link, Navigate } from 'react-router-dom';
import { FormInput } from '../../../components/form-input/form-input';
import { useCreateGuardianMutation } from '../../../slices/userApiSlice';
import { SubmitButton } from '../../../components/submit-button';
import { newGuardianSchema } from './yup-schema/new-guardian-schema';
import ModalFooter from '../../../components/modal-footer';
import { allStates, gendersOptions } from '../../../utils/constants';
import { CustomError } from '../../../utils/custom-types';

const NewGuardianModal = () => {
  const { showModal, setShowModal } = useStateContext();
  const [createGuardian, { isError, data, error, isLoading, isSuccess }] =
    useCreateGuardianMutation();

  const onSubmit = (values: any, { setFieldValue }: { setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void }) => {
    const data = values;
    data.zipCode = Number(data.zipCode);
    if (data.dateOfBirth) {
      data.dateOfBirth = new Date(data.dateOfBirth).toISOString();
    }
    else {
      data.dateOfBirth = null;
    }

    createGuardian(data)
      .unwrap()
      .then((res) => console.log(res))
      .catch((error) => {
        console.log(error);
        if (values.dateOfBirth) {
          const newDate = new Date(values.dateOfBirth);
          setFieldValue('dateOfBirth', newDate.toISOString().split('T')[0]);
        }
      });
  };

  const [formInputs, setFormInputs] = useState([
    {
      mainTitle: 'Guardian Information',
      name: 'firstName',
      type: 'text',
      label: 'First Name',
      placeholder: 'Enter first name',
    },
    {
      mainTitle: 'Guardian Information',
      name: 'lastName',
      type: 'text',
      label: 'Last Name',
      placeholder: 'Enter last name',
    },
    {
      mainTitle: 'Guardian Information',
      name: 'username',
      label: 'Username',
      type: 'text',
      placeholder: 'Enter username',
    },
    {
      mainTitle: 'Guardian Information',
      name: 'city',
      type: 'text',
      label: 'City',
      placeholder: 'Enter city',
    },
    {
      mainTitle: 'Guardian Information',
      name: 'state',
      type: 'text',
      label: 'State',
      placeholder: 'Enter state',
      isSelect: true,
      options: allStates ? allStates : [],
    },
    {
      mainTitle: 'Guardian Information',
      name: 'zipCode',
      type: 'number',
      label: 'Zip Code',
      placeholder: 'Enter zip code',
    },
    {
      mainTitle: 'Guardian Information',
      name: 'phoneNumber',
      type: 'text',
      label: 'Phone Number',
      placeholder: 'Enter phone number',
    },
    {
      mainTitle: 'Guardian Information',
      name: 'gender',
      label: 'Gender',
      type: 'text',
      isSelect: true,
      options: gendersOptions,
    },
    {
      mainTitle: 'Guardian Information',
      name: 'email',
      type: 'text',
      label: 'Email Address',
      placeholder: 'Enter email address',
    },
  ]);

  const initialValues: { [key: string]: string } = {};
  formInputs.forEach((input: any) => (initialValues[input.name] = ''));

  if (isSuccess) return <Navigate to='../' />;
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={newGuardianSchema}
        onSubmit={onSubmit}
      >
        <Form>
          <div className='flex flex-col gap-2'>
            {formInputs.map((input: any, i: number) => (
              <React.Fragment key={i}>
                {!formInputs[i - 1] || formInputs[i - 1]?.mainTitle !== input.mainTitle ? (
                  <>
                    <div className='flex flex-col'>
                      <div className='mb-2'>
                        <p className='text-gray-800 font-bold pt-6'>{input.mainTitle}</p>
                        <hr />
                      </div>
                      <div className='flex flex-wrap gap-1'>
                        {formInputs
                          .filter((inp1: any) => inp1.mainTitle === input.mainTitle)
                          .map((inp: any, i: number) => (
                            <div
                              key={i}
                              className={inp.isTrue ? 'w-full ' : 'flex-1 min-w-[300px]'}
                            >
                              <FormInput {...inp} />
                            </div>
                          ))}
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </React.Fragment>
            ))}
            {/* <div
                onClick={() =>
                  setFormInputs((s) => [
                    ...s,
                    {
                      mainTitle: 'Student Information',
                      name: 'guardianStudents',
                      type: 'text',
                      label: 'Email Address',
                      placeholder: 'student email',
                      // isTrue: true,
                    },
                  ])
                }
                className='flex justify-end cursor-pointer select-none'
              >
                + Add student
              </div> */}

            {isError && <p className='text-red-300'>{(error as CustomError).data.message}</p>}
          </div>
          {/* footer*/}

          <ModalFooter isLoading={isLoading!} />
          {/* <div className='flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b'>
            <Link
              className='w-1/5 my-12 text-center py-1 bg-white text-black shadow-lg font-semibold rounded-lg'
              to='../'
            >
              Cancel
            </Link>

            <SubmitButton isLoading={isLoading} />
          </div> */}
        </Form>
      </Formik>
    </>
  );
};

export default NewGuardianModal;
