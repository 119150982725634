import { Separator } from '../../../components/ui/separator/Separator';
import { GameCard } from './components/GameCard';
import CanIView from '../../../components/can-i-view/can-i-view';
import { HeaderButton } from '../../../components/header-button';
import { useGetMostPlayedGamesQuery } from '../../../slices/gameApiSlice';

export interface Game {
  id: number;
  name: string;
  image: string;
  FavoriteGames: any[];
  active_limit: number;
  subs_limit?: number;
  team_size: any[];
  matches?: number;
}

const MostPlayedGames = ({ title = '' }) => {
  const { data: mostPlayed, isLoading, isError, error } = useGetMostPlayedGamesQuery('');
  if (isLoading) return <p>Loading...</p>;
  return (
    <div>
      <div className='flex justify-between'>
        <h2 className='text-xl font-semibold tracking-tight'>{title}</h2>

        <CanIView component='threedots'>
          <div className='flex justify-end gap-x-2 mb-4'>
            <HeaderButton type='newGame' />
            <HeaderButton type='newMatchFormat' />
          </div>
        </CanIView>
      </div>

      <Separator className='my-4' />
      <div className='relative'>
        <div className='relative flex space-x-4 mb-6'>
          {mostPlayed?.length ? (
            mostPlayed?.map((game: any) =>
              game.matches ? <GameCard key={game.id} game={game} className='w-[250px]' /> : null,
            )
          ) : (
            <p className='w-full text-center'>No data to show.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default MostPlayedGames;
