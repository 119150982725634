export function UserInfo({ name }: { name: string }) {
  return (
    <div className='flex flex-col flex-[3] '>
      <h2 className='font-bold text-white max-w-[15ch] truncate'>{name}</h2>
      {/* <div className="flex text-xs gap-3">
                {state}
            </div> */}
    </div>
  );
}
