import * as yup from 'yup';
import { ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import {
  GradeLevels,
  GradeLevelsNames,
  matchFormatNames,
  ProgramTitles,
  ManualTagPrefix,
} from './constants';
import { AnyObject } from 'yup/lib/types';
import moment, { localeData } from 'moment';
import { dateToLocal } from './dateFormatter';
export const defaultBg = (await import('../assets/grid-background.jpg')).default;

export type ParticipantType = { username: string; image: string }[];
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function firstLetterToUpperCase(string: string) {
  return string?.charAt(0).toUpperCase();
}
export function wordWithFirstLetterToUpperCase(word: string) {
  return `${firstLetterToUpperCase(word)}${word.slice(1)}`;
}

export function calculateScheduledHourLimits(startTime: string, endTime: string) {
  const currentDate = new Date();

  const [startHours, startMinutes] = startTime.split(':').map(Number);
  const [endHours, endMinutes] = endTime.split(':').map(Number);
  const startDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
    startHours,
    startMinutes,
  );
  const endDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
    endHours,
    endMinutes,
  );
  if (endDate < startDate) {
    endDate.setDate(currentDate.getDate() + 1);
  }

  return [startDate, endDate];
}
export const weekday = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
export const dayInString = (year: number, month: number, day: number) => {
  return weekday[new Date(year, month, day).getDay()];
};
export function getDaysInMonth(year: number, month: number) {
  return new Date(year, month + 1, 0).getDate();
}
export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});
export function getDuration(
  startDate: Date,
  endDate: Date,
): { hours: number; minutes: number; seconds: number } {
  const duration = endDate.getTime() - startDate.getTime();
  const hours = Math.floor(duration / (1000 * 60 * 60));
  const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((duration % (1000 * 60)) / 1000);

  return { hours, minutes, seconds };
}

export function getTimeComponents(totalSeconds: number) {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return { hr: hours, min: minutes, sec: seconds };
}

export const gradeLevels = [
  { id: GradeLevels.Kindergarten, name: GradeLevelsNames[GradeLevels.Kindergarten] },
  { id: GradeLevels.first, name: GradeLevelsNames[GradeLevels.first] },
  { id: GradeLevels.second, name: GradeLevelsNames[GradeLevels.second] },
  { id: GradeLevels.third, name: GradeLevelsNames[GradeLevels.third] },
  { id: GradeLevels.fourth, name: GradeLevelsNames[GradeLevels.fourth] },
  { id: GradeLevels.fifth, name: GradeLevelsNames[GradeLevels.fifth] },
  { id: GradeLevels.sixth, name: GradeLevelsNames[GradeLevels.sixth] },
  { id: GradeLevels.seventh, name: GradeLevelsNames[GradeLevels.seventh] },
  { id: GradeLevels.eighth, name: GradeLevelsNames[GradeLevels.eighth] },
  { id: GradeLevels.freshman, name: GradeLevelsNames[GradeLevels.freshman] },
  { id: GradeLevels.sophomore, name: GradeLevelsNames[GradeLevels.sophomore] },
  { id: GradeLevels.junior, name: GradeLevelsNames[GradeLevels.junior] },
  { id: GradeLevels.senior, name: GradeLevelsNames[GradeLevels.senior] },
  { id: GradeLevels.NotAStudent, name: GradeLevelsNames[GradeLevels.NotAStudent] },
];

interface Team {
  image: string;
  School: {
    name: string;
  };
  teamName: string;
}

interface GeneratedProps {
  aImage: string;
  aSchool: string;
  aTeam: string;
  hImage: string;
  hSchool: string;
  hTeam: string;
  setHScore: (score: number) => void;
  hScore: number;
  setAScore: (score: number) => void;
  aScore: number;
}

export function generateGameInputProps(data: {
  away_team: Team[];
  home_team: Team[];
  setHScore: (score: number) => void;
  hScore: number;
  setAScore: (score: number) => void;
  aScore: number;
}): GeneratedProps {
  return {
    aImage: data.away_team[0].image,
    aSchool: data.away_team[0].School?.name ?? '',
    aTeam: data.away_team[0].teamName,
    hImage: data.home_team[0].image,
    hSchool: data.home_team[0].School?.name ?? '',
    hTeam: data.home_team[0].teamName,
    setHScore: data.setHScore,
    hScore: data.hScore,
    setAScore: data.setAScore,
    aScore: data.aScore,
  };
}

export function checkStartDate(value: string, ctx?: yup.TestContext<AnyObject>): boolean {
  if (!value) return false;
  const momento = dateToLocal(new Date()).split('T')[0];
  const isAfter = moment(value).isAfter(momento) || moment(value).isSame(momento);
  return isAfter;
}

export function checkEndDate(
  value: string,
  ctx: yup.TestContext<AnyObject>,
  startVariable: string,
): boolean {
  if (!value) return false;
  const startDate = dateToLocal(ctx.parent[startVariable]).split('T')[0];
  return checkIfDateIsFuture(startDate, value);
}

export function checkSeasonWeekEndDate(
  value: string,
  ctx: yup.TestContext<AnyObject>,
  startVariable: string,
): boolean {
  if (!value) return true;
  const startDate = dateToLocal(ctx.parent[startVariable]).split('T')[0];
  return checkIfDateIsFuture(startDate, value);
}

export function checkIfDateIsFuture(startDate: string, endDate: string) {
  return moment(endDate).isAfter(startDate) && !moment(endDate).isSame(startDate);
}

export function checkIfDateIsTodayOrFuture(startDate: string, endDate: string) {
  return moment(endDate).isAfter(startDate) || moment(endDate).isSame(startDate);
}

type MatchFormatType = {
  id: number;
  type?: number;
  value?: number;
  name: string;
};

export function getTypeOfMatchFormat(match: {
  MatchFormat?: MatchFormatType;
  game_per_match?: string;
  SeasonWeek?: { games_per_match?: string; match_format: MatchFormatType };
}) {
  let format: MatchFormatType | undefined;
  if (match.MatchFormat) format = match.MatchFormat;
  else if (match.SeasonWeek) {
    if (match.SeasonWeek.match_format) {
      format = match.SeasonWeek.match_format;
    }
  }
  if (format) {
    if (format?.type && format.value) return matchFormatNames[format.type] + ` ${format.value}`;
  } else
    format = {
      id: 1,
      name: match.game_per_match ?? match.SeasonWeek?.games_per_match ?? '',
    };
  return format?.name;
}
export function checkStartDateAndSpecificDate(
  value: string,
  ctx?: yup.TestContext<AnyObject> | undefined,
  specificDate?: Date,
): boolean {
  if (!value) return false;

  const today = new Date();
  const selectedDate = new Date(value);

  today.setHours(0, 0, 0, 0);
  selectedDate.setHours(0, 0, 0, 0);

  if (specificDate && specificDate.getTime() > today.getTime()) {
    return (
      selectedDate.getTime() >= today.getTime() &&
      (selectedDate.getTime() >= specificDate.getTime() ||
        selectedDate.toDateString() === specificDate.toDateString())
    );
  } else {
    return selectedDate.getTime() >= today.getTime();
  }
}

export function transformEmail(email: string) {
  const atIndex = email.indexOf('@');

  if (atIndex <= 1) {
    return email;
  }

  const firstChar = email.charAt(0);
  const lastChar = email.charAt(atIndex - 1);
  const domain = email.slice(atIndex);

  const transformedEmail = `${firstChar}****${lastChar}${domain}`;

  return transformedEmail;
}

interface TeamGameId {
  game_id: number;
}

interface Schools {
  teams: TeamGameId[];
  User: { team: TeamGameId[] }[];
}

export const appendAutoTags = (
  tags: string[],
  teams: TeamGameId[],
  schools: Schools[] | null,
  droneRacingId: number,
) => {
  if (tags) {
    if (tags[0] === ManualTagPrefix) tags.shift();
    const gameIds = schools
      ? [
          ...new Set(
            (teams?.map((team) => team?.game_id) ?? [])
              .concat(schools?.flatMap((school) => school.teams?.map((team) => team.game_id)))
              .concat(
                schools
                  ?.flatMap((school) =>
                    school.User?.flatMap((user) => user.team?.map((t: any) => t.game_id)),
                  )
                  .filter((gameId) => gameId !== undefined),
              ) ?? [],
          ),
        ]
      : teams?.map((team) => team.game_id);
    if (
      gameIds.filter((id) => id !== droneRacingId).length > 0 &&
      !tags?.some(
        (item) => item.toLowerCase() === ManualTagPrefix + ProgramTitles.Esports.toLowerCase(),
      )
    )
      tags.unshift(ProgramTitles.Esports);
    if (
      gameIds.filter((id) => id === droneRacingId).length > 0 &&
      !tags?.some(
        (item) => item.toLowerCase() === ManualTagPrefix + ProgramTitles.DroneRacing.toLowerCase(),
      )
    )
      tags.unshift(ProgramTitles.DroneRacing);
  }
  return tags;
};
