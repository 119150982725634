import { FaArchive } from 'react-icons/fa';
import { Socket, io } from 'socket.io-client';
import { selectToken, selectUser } from '../../slices/auth';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../store';
import { SocketActions } from '../../utils/constants';

const DeleteChatMessage = ({
  msgId,
  handleDeleteChat,
}: {
  msgId: number;
  handleDeleteChat: (m: number) => void;
}) => {
  // const token = useSelector(selectToken);
  const param = useParams();
  const user = useSelector(selectUser);
  // const [socket, setSocket] = useState<Socket>();
  // const url = import.meta.env.VITE_CLASSROOM_CHAT;

  // useEffect(() => {
  //     if (socket) return;
  //     setSocket(io(url ?? '', { extraHeaders: { token } }));
  // }, []);
  const dispatch = useAppDispatch();
  // const handleDeleteChatMessage = () => {
  //   if (msgId) {
  //     const payload = {
  //       room: `classroom-${param.id}`,
  //       userRole: user.roles,
  //       id: msgId,
  //     };
  //     dispatch({ type: SocketActions.DeleteClassroomMessage, payload });
  //     console.log('first');
  //   }
  // };

  return (
    <>
      <div>
        <div className={'flex items-center absolute z-10 shadow-slate-100 border-2'}>
          <div
            className='w-[100px] bg-white p-2 text-center flex items-center cursor-pointer'
            onClick={() => handleDeleteChat(msgId)}
          >
            <FaArchive />
            <span className='ml-2'>Delete</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteChatMessage;
