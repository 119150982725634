import { TournamentOverviewSponsorCard } from './tournament-overview-sponsor-card';
import React from 'react';
import { dummysponsors } from '../../../utils/geti-todo';
import { useParams } from 'react-router-dom';
import { useGetSingleTournamentInfoQuery } from '../../../slices/tournamentApiSlice';
export function TournamentOverviewSponsors({ }) {
  const param = useParams();
  const { data } = useGetSingleTournamentInfoQuery(param.id);


  if (!data.sponsors.length)
    return <></>

  return (
    <div className='flex flex-col gap-4'>
      <h2 className='font-bold text-xl'>Sponsors</h2>
      <div className='flex  justify-evenly w-full flex-wrap  border border-gray-200 rounded-xl'>
        {data.sponsors.map((d: any) => (
          <TournamentOverviewSponsorCard key={d.name} image={d.image} sponsor={d.name} />
        ))}
      </div>
    </div>
  );
}
