import { useEffect, useState } from 'react';
import { BsThreeDots } from 'react-icons/bs';
import { HiUserGroup } from 'react-icons/hi';
import DropdownItem from '../../../../../../features/listview/DropDown/DropDownItem';
import CanIView from '../../../../../../components/can-i-view/can-i-view';
import { SeasonModel } from '../../../../../../model/season.model';
import { useNavigate, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../../../slices/auth';
import { UserRoles } from '../../../../../../utils/constants';
const defaultBg = (await import('../../../../../../assets/grid-background.jpg')).default;

export function SeasonGridCard({ ...t }) {
  const model = new SeasonModel(t);
  const [openList, setOpenList] = useState(null);
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  useEffect(() => {
    console.log('test openList', openList);
  }, [openList]);
  return (
    <div
      onClick={() => (user.roles === UserRoles.Admin ? null : navigate(`/seasons/view/${t.id}`))}
      className={`relative flex flex-col rounded-xl w-[300px] aspect-square overflow-hidden bg-white border-2 border-gr-200 ${
        user.roles !== UserRoles.Admin ? 'cursor-pointer' : ''
      }`}
    >
      <CanIView component='threedots'>
        <div
          onClick={() => setOpenList(t.id)}
          className='absolute cursor-pointer top-2 right-2 p-1 px-2 rounded-lg bg-black bg-opacity-80'
        >
          <BsThreeDots color='white' className='group' />
        </div>
      </CanIView>

      <div className={`listViewMenu--parent--grid ${openList === t.id ? 'flex' : 'hidden'}`}>
        <div
          className='listViewMenu !absolute'
          role='menu'
          aria-orientation='vertical'
          aria-labelledby='menu-button'
        >
          <div className='' role='none'>
            <DropdownItem justView={t.justView} season id={t.id} setFunc={setOpenList} />
          </div>
        </div>
      </div>

      <img
        src={model.image ? model.image : defaultBg}
        className='max-h-[50%] object-cover w-full h-full overflow-hidden'
      />

      <div className='flex p-1 flex-col justify-between  h-[50%] w-full'>
        <div className='flex justify-between'>
          <div className='font-bold uppercase text-gray-900 ml-3 mb-4 max-w-[15ch] truncate'>
            {model.name}
          </div>
          <div className='flex flex-col w-[40px] justify-center items-center second-gr-bg-color rounded-lg'>
            <HiUserGroup />
            <span>{model.teams ? model.teams.length : null}</span>
          </div>
        </div>
        <div className='flex justify-between items-center'>
          <div className='flex flex-col text-sm self-end border-t-2 border-neutral-200 w-full items-center p-2'>
            <div className='flex justify-between w-full'>
              <h3 className='text-gray-900 p-1 max-w-[15ch] truncate'>{model?.division}</h3>
              <h3 className='text-gray-900 p-1 max-w-[15ch] truncate'>{model?.game}</h3>
            </div>

            <div className='flex flex-col text-sm border-t-2 border-neutral-200 w-full items-center pt-2'>
              <h3 className='text-gray-900 font-bold'>Start Date:</h3>
              <p className='text-gray-900'>{model.f_startDate}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
