import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { AvatarImage } from '../../../components/avatar-image';
import { useGetSingleTournamentInfoQuery } from '../../../slices/tournamentApiSlice';
import { dummyteams } from '../../../utils/geti-todo';
import TournamentTeamCard from './tournament-team-card';
export function TournamentOverviewTeams({ }) {
  const param = useParams();
  const { data, isLoading } = useGetSingleTournamentInfoQuery(param.id);

  if (isLoading) return <p>Loading...</p>
  return (
    <div className='flex flex-col gap-4'>
      <h2 className='font-bold text-xl'>Teams</h2>
      <div className='flex flex-col p-10 gap-10 w-full border border-gray-200  rounded-xl'>
        {data.teams.length ? (
          <>
            <div className='flex flex-wrap justify-center gap-4 '>
              {data.teams.slice(0, 6).map((team: any) => (
                <TournamentTeamCard {...team} key={team.teamName} />
              ))}
            </div>
            <Link to='./teams'>
              <div className='flex text-sm  uppercase justify-center items-center'>
                <span className='main-gr-bg-color rounded-xl py-3 px-6 text-white'>View All Teams</span>
              </div>
            </Link>
          </>
        ) : (
          <>
            <p className='flex flex-wrap justify-center gap-4'>Tournament doesn't have any team!</p>

          </>
        )}
      </div>
    </div>
  );
}
