import React from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';
import { Link } from 'react-router-dom';

const StudentSubscriptionThankYou = ({ noBack }: { noBack?: boolean }) => {
  return (
    <div className='listViewContainer h-[calc(100vh-85px)] overflow-auto flex flex-col justify-evenly items-center'>
      <div className='flex flex-col h-[70%] justify-evenly items-center'>
        <AiFillCheckCircle size={150} color='green' />
        <div className='flex flex-col text-center'>
          <h2 className='font-bold text-2xl'>Thank You!</h2>
          <h2 className='font-bold text-2xl'>Your payment is completed</h2>
        </div>
        {/* <p>Transaction number #123423523</p> */}
        {noBack ? (
          <></>
        ) : (
          <Link
            to='/subscriptions/'
            className='hover:bg-opacity-80 bg-black  p-2 px-4 text-sm font-bold text-white rounded-lg'
          >
            Go back to subscriptions
          </Link>
        )}
      </div>
    </div>
  );
};

export default StudentSubscriptionThankYou;
