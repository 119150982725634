import { VscDebugStart } from 'react-icons/vsc';
import { useStartMatchMutation } from '../../../slices/matchApiSlice';

export function StartMatchButton({ id }: { id: string }) {
  const [startMatch, { isLoading: smIsLoading }] = useStartMatchMutation();
  return (
    <div
      onClick={() => startMatch(id)}
      className='main-gr-bg-color cursor-pointer rounded-md flex items-center justify-center px-4 p-1 text-xs gap-1'
    >
      <p className='text-white'>Start Game</p>
      <VscDebugStart color='white' />
    </div>
  );
}
