import { Link } from 'react-router-dom';
import { Avatar, AvatarImage, AvatarFallback } from '../../../components/ui/avatar/Avatar';
import { firstLetterToUpperCase } from '../../../utils/utils';

function TournamentTeamCard({ image = '', teamName = '', ...t }) {
  return (
    <Link
      to={`/teams/view/${t.id}`}
      className='overflow-hidden flex flex-col items-center gap-2 hover:bg-black hover:bg-opacity-10 p-2 flex-wrap break-words rounded-lg border'
    >
      <Avatar className='h-24 w-24'>
        <AvatarImage className='object-cover' src={image} />
        <AvatarFallback>{firstLetterToUpperCase(teamName)}</AvatarFallback>
      </Avatar>
      <h2 className='text-black text-sm font-bold text-center w-[100px] overflow-hidden whitespace-nowrap break-words text-ellipsis'>
        {teamName}
      </h2>
    </Link>
  );
}

export default TournamentTeamCard;
