import React, { useEffect, useState } from 'react';
import useFormContext from '../../../hooks/useFormContext';

import { TiDelete } from 'react-icons/ti';
import StudentsCSV from './StudentsCSV';
import { InviteStudentsOgmaHintMessage } from '../../../utils/messages.enum';
import DOMPurify from 'dompurify';

const InviteStudents = ({ noSkip, showTitle }: { noSkip?: boolean; showTitle?: boolean }) => {
  const [input, setInput] = useState<any>('');
  const [isKeyReleased, setIsKeyReleased] = useState(false);
  const [error, setError] = useState(false);
  const [validate, setValidate] = useState('');
  const { data, handleChange, studentInput, tags, setTags }: any = useFormContext();
  let count = 0;
  function isValidEmail(email: any) {
    return /^(?=.{1,64}@)[a-zA-Z0-9_-]+(?:\.[a-zA-Z0-9_-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,63}$/.test(
      email,
    );
  }
  const onChange = (e: any) => {
    const { value } = e.target;
    setInput(value);
    if (tags.includes(value.trim()) || !isValidEmail(value.trim())) {
      setError(true);
      setValidate('Invalid email');
    } else {
      setError(false);
      setValidate('');
    }
  };

  const onKeyDown = (e: any) => {
    const { key } = e;
    if (key !== 'Enter' && key !== ',') {
      return;
    } else {
      e.preventDefault();
    }
    const trimmedInput = input.trim();

    if (tags.includes(trimmedInput) || !isValidEmail(trimmedInput.trim())) {
      console.log('Error');
      setError(true);
      return;
    }
    setError(false);
    if (
      // key === 'Enter' &&
      trimmedInput.length
    ) {
      e.preventDefault();
      setTags((prevState: any) => [...prevState, trimmedInput]);
      setInput('');
    }
    setIsKeyReleased(false);
  };

  const onKeyUp = () => {
    setIsKeyReleased(true);
  };

  const deleteTag = (index: any) => {
    setTags((prevState: any) => prevState.filter((tag: any, i: any) => i !== index));
  };
  function clearAllTags() {
    setTags([]);
  }

  return (
    <div className='flex-1 flex flex-col max-w-[700px]'>
      <div className='flex items-center gap-2'>
        {showTitle && <h1 className='text-xl font-bold mr-5'>Invite Students</h1>}
        {!noSkip && (
          <>
            <input
              type='checkbox'
              onChange={handleChange}
              checked={data?.skipForNow}
              name='skipForNow'
              id='skipForNow'
            />
            <span className='text-sm text-gray-500'>
              Skip for now (I will invite students later)
            </span>
          </>
        )}
      </div>
      <div
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(InviteStudentsOgmaHintMessage) }}
      ></div>
      <div className='flex justify-between items-end'>
        <StudentsCSV />
        <span className='underline cursor-pointer' onClick={clearAllTags}>
          Clear all
        </span>
      </div>

      <div
        className={`flex overflow-auto flex-col h-[300px] border ${
          error ? 'border-red-500' : 'border-gray-300'
        }  px-2 color-black rounded-2xl`}
      >
        <input
          value={input}
          placeholder='Enter an email'
          onKeyDown={onKeyDown}
          onChange={onChange}
          onKeyUp={onKeyUp}
          className={`w-[100%] border-none outline-none rounded-lg px-4 py-2 text-center `}
        />
        <div className='flex flex-wrap overflow-scroll'>
          {/* TODO: height should be more flexible */}

          {tags.map((tag: any, index: any) => (
            <div
              className='h-fit flex border border-gray-500 rounded-full px-3 py-1 gap-2 m-1 select-none'
              key={index}
            >
              {tag}
              <button onClick={() => deleteTag(index)}>
                <TiDelete size={20} color='red' />
              </button>
            </div>
          ))}
        </div>
      </div>
      {validate.length >= 1 && <p className='text-red-500'>{validate}</p>}
    </div>
  );
};

export default InviteStudents;
