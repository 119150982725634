import React from 'react';
import { Link } from 'react-router-dom';
import { AvatarImage } from '../../../components/avatar-image';
import { OrganizationCoachModel } from '../../../model/organization-coach.model';
export function StudentDashCoachRow({ model }: { model?: OrganizationCoachModel }) {
  return (
    <Link to={`/coaches/view/${model?.id}`} className='hover:bg-gray-300 rounded-xl'>
      <div className='flex gap-2 items-center p-2 pl-5'>
        <AvatarImage size='xl' source='http://res.cloudinary.com/dq2phgsqb/image/upload/v1676970533/ag47dvtzsihrxlb75qu5.jpg' />
        <p className='max-w-[20ch] truncate'>{model?.fullName}</p>
      </div>
    </Link>
  );
}
