import React from 'react';
import { SchoolModel } from '../../../model/school/school-model';
import { Link } from 'react-router-dom';

const OgmaProfileSchoolCard = ({ model }: { model: SchoolModel }) => {
  return (
    <Link to={`/schools/view/${model.id}`} >
    <div className='flex flex-col w-[340px] h-[180px] rounded-lg bg-white shadow'>
      <p className='ml-4 p-1 mt-7'><strong>School:</strong> {model.name}</p>
        <p className='ml-4 p-1'><strong>City:</strong> {model.city}</p>
        <p className='ml-4 p-1'><strong>Status:</strong> {model.status_name}</p>
        <p className='ml-4 p-1'><strong>Coaching Hours Purchased:</strong> {model.coach_hours}</p>  
    </div>
    </Link>
  );
};

export default OgmaProfileSchoolCard;