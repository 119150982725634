import { Navigate, useParams } from 'react-router-dom';
import { OutsideClick } from '../../../../components/outside-click';
import { Formik } from 'formik';
import ModalContent from '../../../../components/modal-content';
import {
  useEditSeasonWeekMutation,
  useGetNewSRequiredDataQuery,
  useGetSeasonWeekByIdQuery,
  useViewSingleSeasonQuery,
} from '../../../../slices/seasonApiSlice';
import { NewWeekVSchema } from '../new-week/schema/new-week-v-schema';
import { useState } from 'react';
import { addCurrentTimeToDate, dateToLocal } from '../../../../utils/dateFormatter';
import useSuccessToast from '../../../../hooks/use-success-toast';

const EditSeasonWeek = () => {
  const param = useParams();
  const [chosenGame, setChosenGame] = useState<number>();
  const [editSeasonWeek, { isLoading: editIsLoading, isSuccess: editIsSuccess }] =
    useEditSeasonWeekMutation();
  const { data: seasonData, isLoading: seasonIsLoading } = useViewSingleSeasonQuery(param.id);
  const {
    data: seasonWeekData,
    isLoading: seasonWeekIsLoading,
    isError: seasonWeekIsError,
    error: seasonWeekError,
  } = useGetSeasonWeekByIdQuery(param.weekid);
  const {
    data: reqData,
    isLoading: reqIsLoading,
    isSuccess: reqIsSuccess,
    isError: reqIsError,
  } = useGetNewSRequiredDataQuery('');

  const formInputs = [
    {
      mainTitle: 'Week Information',
      name: 'name',
      label: 'Name',
      type: 'text',
      placeholder: 'Name',
      isTrue: true,
    },
    {
      mainTitle: 'Week Information',
      name: 'start_time',
      label: 'Start date',
      type: 'datetime-local',
      placeholder: 'Start date',
      isTrue: true,
    },
    {
      mainTitle: 'Week Information',
      name: 'end_time',
      label: 'End date',
      type: 'date',
      placeholder: 'End date',
      isTrue: true,
    },
    {
      mainTitle: 'Week Information',
      name: 'format_id',
      label: 'Match Format',
      type: 'number',
      min: 0,
      placeholder: 'Game per match',
      hidden: seasonData && seasonData?.SeasonType?.leaderboard,
      isSelect: true,
      options:
        seasonData.game_id &&
        reqData?.games?.filter((g: any) => g.id === seasonData?.game_id)[0].MatchFormat
          ? reqData?.games?.filter((g: any) => g.id === seasonData?.game_id)[0].MatchFormat
          : [],
    },
  ];
  const initialValues: { [key: string]: string } = {};
  formInputs.forEach((input) => (initialValues[input.name] = ''));
  function handleSubmit(values: any) {
    const startTimeLocalDate = new Date(values.start_time);
    const body = {
      ...values,
      start_time: new Date(startTimeLocalDate.setSeconds(0)).toISOString(),
    };
    !values.format_id ? delete body.format_id : (body.format_id = +values.format_id);
    if (values.end_time && values.end_time !== '-') {
      body.end_time = addCurrentTimeToDate(values.end_time);
    } else delete body.end_time;

    editSeasonWeek({
      id: param.weekid,
      body: body,
    });
  }

  useSuccessToast(editIsSuccess, 'Season week has been edited successfully.');
  if (editIsSuccess) return <Navigate to='../' />;
  if (seasonWeekIsLoading) return <p>Loading...</p>;
  const newSeasonWeekSchema = NewWeekVSchema(seasonData?.SeasonType?.leaderboard);
  return (
    <>
      <OutsideClick />
      <Formik
        validationSchema={newSeasonWeekSchema}
        initialValues={{
          name: seasonWeekData.name,
          start_time: dateToLocal(seasonWeekData?.start_time).split(':').slice(0, 2).join(':'),
          end_time:
            dateToLocal(seasonWeekData?.end_time)?.split('T')[0] !== '-'
              ? dateToLocal(seasonWeekData?.end_time)?.split('T')[0]
              : '',
          format_id: seasonWeekData?.format_id,
        }}
        onSubmit={handleSubmit}
      >
        {({ values, errors }) => {
          return (
            <ModalContent
              formInputs={formInputs.filter((formInput) => !formInput.hidden)}
              isLoading={editIsLoading}
              name='Edit Season Week'
            />
          );
        }}
      </Formik>
    </>
  );
};
export default EditSeasonWeek;
