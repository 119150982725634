import { useParams } from 'react-router';
import { useGetTicketCommentByIdQuery } from '../../slices/supportTicketApiSlice';
import React, { useState } from 'react';
import { NameAndAvatar } from '../../components/name-and-avatar';
import { OutsideClick } from '../../components/outside-click';
import CanIView from '../../components/can-i-view/can-i-view';
import { FaRegTrashAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const ViewTicketComments = () => {
  const param = useParams();
  const { data: commentsData, isLoading } = useGetTicketCommentByIdQuery(param.id, {
    skip: !param.id,
  });
  const [viewFullImage, setViewFullImage] = useState<number>();

  const handleImageView = (id: number) => {
    setViewFullImage(id);
  };
  if (isLoading) return <p>Loading...</p>;
  return (
    <div className='flex flex-col gap-5 mb-2'>
      {viewFullImage && <OutsideClick setFunc={setViewFullImage} />}
      {commentsData?.length
        ? commentsData.map((data: any) => (
            <React.Fragment key={data.id}>
              <div className='flex flex-col pb-0 gap-5'>
                <div className='flex items-center justify-between'>
                  {!data?.jiraUsername ? (
                    <div>
                      <NameAndAvatar
                        image=''
                        name={data?.User?.firstName}
                        hasTime={true}
                        createdAt={new Date(data?.createdAt).toDateString()}
                      />
                    </div>
                  ) : (
                    <div>
                      <NameAndAvatar
                        image=''
                        name='Support Team'
                        hasTime={true}
                        createdAt={new Date(data?.createdAt).toDateString()}
                      />
                    </div>
                  )}
                  <CanIView component='delete.comment'>
                    <div>
                      <Link to={`delete-comment/${data?.id}`}>
                        <FaRegTrashAlt color='red' className='cursor-pointer' />
                      </Link>
                    </div>
                  </CanIView>
                </div>
                <div className='flex flex-col p-2 border-1 w-full rounded bg-gray-100 gap-5'>
                  <div className='flex items-center gap-2'>
                    {data?.tag ? (
                      <span className='text-red-600 text-sm'>Unresolved reason:</span>
                    ) : null}
                    <p>{data.text}</p>
                  </div>
                  {data?.fileUrl?.includes('image') ? (
                    <div className='max-w-[300px]'>
                      <img
                        src={data?.fileUrl}
                        alt=''
                        className={
                          viewFullImage
                            ? 'fixed top-0 left-0 right-0 bottom-0 z-10 w-auto h-[90vh] m-auto'
                            : 'cursor-pointer'
                        }
                        onClick={() => handleImageView(data?.id)}
                      />
                    </div>
                  ) : data?.fileUrl?.includes('video') ? (
                    <div className='max-w-[300px]'>
                      <video src={data?.fileUrl} autoPlay controls />
                    </div>
                  ) : data?.fileUrl?.includes('pdf') ? (
                    <div className='max-w-[300px]'>
                      <Link to={data?.fileUrl} target='_blank'>
                        PDF attachment
                      </Link>
                    </div>
                  ) : null}
                </div>
              </div>
            </React.Fragment>
          ))
        : null}
    </div>
  );
};

export default ViewTicketComments;
