import { useState } from "react";
import { Team } from "../../../../../../features/listview/users/model/user.model";

export const TeamsOfSingleStudent = ({ teams }: { teams: Team[] | undefined }) => {
    const [isHovering, setIsHovering] = useState(false);
    const handleMouseOver = () => {
        setIsHovering(true);
    };
    const handleMouseOut = () => {
        setIsHovering(false);
    };
    return (
        <p className='text-xs relative border-b-2 font-bold hover:text-red-500 cursor-pointer' onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}>{teams?.length ? `(IS PART OF ${teams?.length} TEAMS)` : ''}
            {isHovering && (

                <span className='w-[140px] truncate ellipsis text-xs absolute bg-white flex flex-col items-center rounded-xl border-2 p-2 right-0 z-50' onMouseOver={handleMouseOver}>
                    <span className='font-bold w-full border-b py-1 text-center'>Teams</span>
                    {teams?.map((team, i) => <span key={i} className='border-b w-full text-center py-2 font-normal'>{team.teamName}</span>)}
                </span>
            )}
        </p>
    )
}