import LatestStudents from './LatestStudents';
import LatestSchools from './LatestSchools';
import Transactions from './Transactions';
import { UserHeader } from '../../components/user-header';
import { useSelector } from 'react-redux';
import { selectUser } from '../../slices/auth';
import { SponsorRow } from '../../components/sponsor-row/sponsor-row';
import { SponsorPlacements } from '../../utils/constants';
import { Outlet } from 'react-router';
import RecentlyDeletedUsers from './RecentlyDeletedUsers';
import MatchChatDisputes from './DashboardMatchChatDisputes';

const Dashboard = () => {
  const user = useSelector(selectUser);
  return (
    <>
      <div className='flex flex-col p-10 gap-10 '>
        <SponsorRow name={SponsorPlacements.Dashboard} />
        <UserHeader
          name={user.firstName}
          lastName={user.lastName}
          image={user.image}
          id={user.id}
          username={user.username}
        />
        <MatchChatDisputes />
        <Transactions />
        <RecentlyDeletedUsers />
        <LatestStudents />
        <LatestSchools />
      </div>
      <Outlet />
    </>
  );
};

export default Dashboard;
