import { useEffect, useState } from 'react';
import Papa from 'papaparse';
import useFormContext from '../../../hooks/useFormContext';

const StudentsCSV = () => {
  const [csvData, setCsvData] = useState<any>([]);

  const { tags, setTags }: any = useFormContext();

  function isValidEmail(email: any) {
    return /\S+@\S+\.\S+/.test(email);
  }

  useEffect(() => {
    console.log('csvData = ', csvData);
    csvData.filter((data: Record<string, any>) => {
      if (data.hasOwnProperty('email')) return data.email?.length;
      if (data.hasOwnProperty('Email')) return data['Email']?.length;
      return data?.length;
    });
    const uniqueEmails: Record<string, string>[] = Object.values(
      csvData.reduce((acc: any, email: any) => {
        const emailValue: any = Object.values(email)[0];
        acc[emailValue] = email;
        return acc;
      }, {}),
    );
    console.log('csvData unique = ', uniqueEmails);

    uniqueEmails.map((data: Record<string, string>) => {
      console.log(
        'csvData email = ',
        data.hasOwnProperty('email')
          ? data['email']
          : data.hasOwnProperty('Email')
          ? data['Email']
          : data,
      );
      const email = data.hasOwnProperty('email')
        ? data['email']
        : data.hasOwnProperty('Email')
        ? data['Email']
        : data;
      if (!tags.includes(email) && isValidEmail(email))
        setTags((prevState: any) => [...prevState, email]);
    });
  }, [csvData]);
  function handleFileUpload(event: any) {
    const file = event.target.files[0];
    Papa.parse(file, {
      header: true,
      complete: function (results: any) {
        const data = Array.isArray(results.data) ? results.data : [results.data];
        setCsvData(data);
      },
    });
  }
  return (
    <div className='overflow-hidden relative inline-block rounded-xl '>
      <button className='bg-[#33a1fd] hover:bg-indigo-dark text-white font-bold py-2 px-4 w-full inline-flex items-center'>
        <svg
          fill='#FFF'
          height='18'
          viewBox='0 0 24 24'
          width='18'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path d='M0 0h24v24H0z' fill='none' />
          <path d='M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2H5z' />
        </svg>
        <span className='ml-2 text-white'>Upload CSV file</span>
      </button>
      <input
        type='file'
        className='cursor-pointer absolute block opacity-0 pin-r pin-t z-50 top-0 left-0'
        onChange={handleFileUpload}
        name='vacancyImageFiles'
        multiple
      />
    </div>
  );
};

export default StudentsCSV;
