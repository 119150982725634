import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import g from '../../assets/guardianverification.svg';
import { FormInput } from '../../components/form-input/form-input';
import { logOut, selectIsVerified, selectToken, selectUser } from '../../slices/auth';
import { verifyAccount as sliceVerifyAccount } from '../../slices/auth';
import {
  userApiSlice,
  useSendEmailVerificationMutation,
  useVerifyAccountMutation,
} from '../../slices/userApiSlice';
import { useAppDispatch } from '../../store';
import { apiSlice } from '../../api/apiSlice';
const GuardianVerification = () => {
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const isUserVerified = useSelector(selectIsVerified);
  const token = useSelector(selectToken);
  const [verifyAccount, { data, error, isSuccess, isLoading, isError }] =
    useVerifyAccountMutation();

  const [
    sendEmailVerification,
    {
      data: emailData,
      error: emailError,
      isSuccess: isSuccessEmail,
      isLoading: isLoadingEmail,
      isError: isErrorEmail,
    },
  ] = useSendEmailVerificationMutation();
  const [err, setErr] = useState('');
  const handleSubmit = (formValue: any) => {
    setErr('');
    const { code } = formValue;
    verifyAccount(code)
      .unwrap()
      .then((res) => (res.status > 201 ? setErr('Wrong Pin') : dispatch(sliceVerifyAccount())));
  };
  if (!token || !user) return <Navigate to='/auth' />;
  if (user && isUserVerified) return <Navigate to='/' />;
  return (
    <div className='container mx-auto flex justify-center px-6 my-12 w-full xl:w-3/4 lg:w-11/12'>
      <div className='w-full lg:w-2/3 flex flex-col items-center text-center bg-white justify-center p-5 rounded-lg lg:rounded-l-none gap-5'>
        {/* <div className="flex justify-center mb-4"> */}
        <img src={g} alt='tick-logo' className='w-[70%] max-w-[300px]' />
        {/* </div> */}
        {/* <div className="flex justify-center"> */}
        <p className=' text-md font-medium text-[#15325E]'>
          Welcome{' '}
          <span className='text-red-500'>
            {user.firstName[0].toUpperCase()}
            {user.firstName.slice(1)}
          </span>
          . We just sent a verification code over to {user.email}. Please enter the code below to
          verify your email address.{' '}
        </p>{' '}
        {/* </div> */}
        <Formik initialValues={{ code: '' }} onSubmit={handleSubmit}>
          {({ isSubmitting, errors }) => (
            <Form className='flex flex-col gap-5'>
              <p className='text-red-500'>{err}</p>
              <div className='flex justify-center w-full mb-2'>
                <FormInput
                  placeholder='Enter your verification code'
                  label='Verification Code'
                  name='code'
                  id='code'
                />
              </div>

              <div>
                <p className='text-xs	text-gray-700'>
                  Please Enter valid verification code or click below to receive a new code.
                </p>
              </div>

              <div className='flex justify-center gap-10 w-full'>
                <div
                  onClick={() => {
                    dispatch(apiSlice.util.resetApiState());
                    dispatch(logOut());
                  }}
                  className='w-1/3  py-2  bg-red-700 shadow-lg text-white font-semibold rounded-lg uppercase  hover:bg-opacity-80'
                >
                  Cancel
                </div>
                <button
                  type='submit'
                  className='w-1/3  py-2 bg-red-700 shadow-lg text-white font-semibold rounded-lg  uppercase hover:bg-opacity-80'
                  disabled={isLoading}
                >
                  {isLoading ? <span className='animate-spin'>Submitting...</span> : 'Verify'}
                </button>
              </div>
            </Form>
          )}
        </Formik>
        <div className='flex justify-center'>
          {' '}
          {isLoadingEmail ? (
            <p className='text-gray-500'>Email is being sent!</p>
          ) : (
            <p className='text-gray-500'>
              {' '}
              Click{' '}
              <span
                onClick={() => sendEmailVerification('')}
                className=' cursor-pointer underline text-gray-800'
              >
                here
              </span>{' '}
              to resend the verification code
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default GuardianVerification;
