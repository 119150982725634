import { Link, Navigate, useParams } from 'react-router-dom';
import { useGetTeamByIdQuery, useUnBenchTeamByIdMutation } from '../../../../slices/teamsApiSlice';
import { OutsideClick } from '../../../../components/outside-click';
import { SubmitButton } from '../../../../components/submit-button';
import { AiOutlineClose } from 'react-icons/ai';

const UnBenchTeam = () => {
  const param = useParams();
  const {
    error,
    data: teamData,
    isSuccess: teamIsSuccess,
    isError: teamIsError,
    isLoading: teamDataLoading,
  } = useGetTeamByIdQuery({
    id: param.bId ?? param?.id,
    query: '',
  });
  const [unBenchTeam, { isLoading, isSuccess }] = useUnBenchTeamByIdMutation();

  const onSubmit = () => {
    unBenchTeam(param.bId ?? param?.id);
  };

  if (teamDataLoading) return <></>;

  if (isSuccess) {
    return <Navigate to='../' />;
  }

  return (
    <>
      <OutsideClick />
      <div className='fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-10 w-auto mx-auto max-w-2xl bg-white p-10 flex flex-col gap-10 items-center justify-center rounded-xl'>
        <div className='absolute top-2 right-2'>
          <Link to='../' className=''>
            <AiOutlineClose size={'2rem'} className='hover:rounded-full hover:bg-gray-100 p-2' />
          </Link>
        </div>
        <div className='py-4 flex flex-col justify-center items-center gap-8 break-words w-full'>
          <p className='select-none font-medium flex flex-col gap-1 break-words w-full'>
            <span className='break-words'>
              Are you sure you want to unbench
              <span className='font-semibold break-words'>{teamData?.teamName}</span>?
            </span>
          </p>
          <div className='flex gap-2'>
            <SubmitButton onSubmit={onSubmit} title='Unbench' isLoading={isLoading} />
            <Link
              to='../'
              className='p-4 px-8 text-center text-sm py-2 bg-white text-black border font-semibold rounded-lg hover:bg-gray-100'
            >
              Cancel
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default UnBenchTeam;
