import React, { useEffect, useRef, useState } from 'react';
import { AiOutlineLink } from 'react-icons/ai';
import { BsUnity } from 'react-icons/bs';
import { TwitchPlayer } from 'react-twitch-embed';
import { TbBrandUnity } from 'react-icons/tb';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectMatchData } from '../../../../../slices/matchSlice';
export function GaLiveVideoSection() {
  const embed = useRef();
  const handleReady = (e: any) => {
    embed.current = e;
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const [twProps, setTwProps] = useState<{ [key: string]: any }>({});
  useEffect(() => {
    setTwProps({
      [searchParams.get('type') === 'live' ? 'channel' : 'video']: searchParams.get('id'),
    });
    console.log('gamearena live', searchParams, twProps);
  }, []);

  const matchData = useSelector(selectMatchData);
  if (!Object.keys(twProps).length) return <p>Loading...</p>;
  return (
    <div className='flex flex-col max-w-full w-full h-[45vh] min-h-[45vh] rounded-xl bg-black overflow-hidden'>
      <TwitchPlayer
        // channel={testData ? testData.data[0].user_name : ""}
        {...twProps}
        className='max-w-full min-h-[80%] max-h-[80%] object-scale-down m-auto'
        autoplay
        muted
        onReady={handleReady}
      />
      <div className='flex-1 gap-3 flex justify-between items-center second-gr-bg-color p-3 px-4 w-full'>
        <div className='flex items-center justify-center bg-gray-400 rounded-sm p-2  aspect-square'>
          <TbBrandUnity size={30} />
          {/* <BsUnity size={30} /> */}
        </div>
        <div className='flex flex-1 flex-col h-full justify-between'>
          <h2 className='font-bold'>{`${matchData?.home_team?.[0]?.teamName} vs ${matchData?.away_team?.[0]?.teamName}`}</h2>
          <h2 className='uppercase tracking-tighter'>{matchData?.tournament_round_id !== null ? matchData?.TournamentRound?.Tournament?.name : matchData?.SeasonWeek?.Season?.name}</h2>
        </div>
        <CopyLink />{' '}
      </div>
    </div>
  );
}

function CopyLink() {
  const [clicked, setClicked] = useState(false);
  const copyLocation = () => {
    navigator.clipboard.writeText(window.document.documentURI);
    setClicked(true);
  };
  useEffect(() => {
    if (clicked) {
      setTimeout(() => setClicked(false), 2000);
    }
  }, [clicked]);
  return (
    <div
      onClick={copyLocation}
      className='hover:opacity-50 relative cursor-pointer flex items-center justify-center bg-gray-400 rounded-sm p-2  aspect-square'
    >
      <AiOutlineLink size={30} />
      <div
        className={`absolute bottom-0 left-0 top-0 right-0 bg-gray-700 rounded-sm items-center justify-center text-center text-xs text-white flex duration-300 ${
          clicked ? 'opacity-100 ' : 'opacity-0'
        }`}
      >
        Link Copied
      </div>
    </div>
  );
}
