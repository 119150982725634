import * as yup from 'yup';
import { OutsideClick } from "../outside-click";
import { Formik, FormikValues } from "formik";
import ModalContent from "../modal-content";
import { useCreateExternalSupportTicketMutation } from "../../slices/supportTicketApiSlice";
import { useState } from "react";
import { useGetAllSupportCategoryQuery } from "../../slices/supportCategoryApiSlice";
import { useGetAllSubcategoriesByCategoryIdQuery } from "../../slices/supportSubcategoryApiSlice";
import { Navigate } from "react-router";
import { ExternalSupportValidationSchema } from "../../features/authentication/yupschema";

const SupportModal = () => {
    const [createExternalTicket, { isLoading: createExternalTicketLoading, isSuccess }] = useCreateExternalSupportTicketMutation();
    const { data: categories } = useGetAllSupportCategoryQuery({
        optional: 1
    });
    const [supportCgId, setSupportCgId] = useState<string | any>(null);
    const { data: subCategoriesOfCategory } = useGetAllSubcategoriesByCategoryIdQuery(+supportCgId);
    const [avatarImage, setAvatarImage] = useState<File | any>();

    const formInputs = [
        {
            mainTitle: 'Support Information',
            name: 'supportCategoryId',
            label: 'Category',
            placeholder: 'Category',
            isTrue: true,
            isSelect: true,
            options: categories,
        },
        {
            mainTitle: 'Support Information',
            name: 'supportSubcategoryId',
            label: 'Subcategory',
            placeholder: 'Subcategory',
            isSelect: true,
            isTrue: true,
            options: supportCgId ? subCategoriesOfCategory : [],
        },
        {
            mainTitle: 'Support Information',
            name: 'email',
            label: 'Email Address',
            type: 'text',
            placeholder: 'Enter email address',
            isTrue: true,
        },
        {
            mainTitle: 'Support Information',
            name: 'subject',
            label: 'Subject',
            type: 'text',
            placeholder: 'Subject name',
            isTrue: true,
        },
        {
            mainTitle: 'Support Information',
            name: 'description',
            label: 'Describe the issues thoroughly',
            type: 'textarea',
            placeholder: 'Description',
            isTrue: true,
        },
        {
            mainTitle: 'Support Information',
            name: 'link',
            label: 'Link',
            type: 'text',
            placeholder: 'Paste website link of issue',
            isTrue: true,
        },
        {
            mainTitle: 'Support Information',
            name: 'file',
            label: 'Upload file',
            type: 'image',
            placeholder: 'Upload file',
            isTrue: true,
            accept: 'image/jpeg, image/png, image/jpg, video/mp4, video/x-m4v, video/wmv, video/webm, application/pdf'
        },
    ];

    const initialValues: { [key: string]: string } = {};
    formInputs.forEach((input) => (initialValues[input.name] = ''));

    const onSubmit = (values: FormikValues) => {
        const data = { ...values };
        data.file = avatarImage;
        const formData = new FormData();
        formData.append('subject', data.subject);
        formData.append('description', data.description);
        formData.append('email', data.email);
        formData.append('supportCategoryId', data.supportCategoryId);
        formData.append('supportSubcategoryId', data.supportSubcategoryId);
        formData.append('link', data.link);
        formData.append('file', avatarImage);
        createExternalTicket(formData);
    };

    if (isSuccess) {
        return <Navigate to="../" />;
    }

    return (
        <>
            <OutsideClick />
            <Formik
                validationSchema={ExternalSupportValidationSchema.shape({
                    image: yup
                        .mixed()
                        .nullable()
                        .test(
                            'test2',
                            'Invalid image format. Only JPG, PNG, and JPEG formats are allowed.',
                            () => {
                                const acceptedFormats = ['image/jpeg', 'image/png', 'image/jpg'];
                                if (avatarImage && !acceptedFormats.includes(avatarImage?.type ?? '')) {
                                    return false;
                                }
                                return true;
                            },
                        ),
                })}
                initialValues={initialValues}
                onSubmit={onSubmit}>
                {({ values }) => {
                    if (values) {
                        setSupportCgId(values.supportCategoryId);
                    }
                    return <ModalContent
                        setAvatarImage={setAvatarImage}
                        extraClass={'h-fit'}
                        formInputs={formInputs}
                        isLoading={createExternalTicketLoading}
                        name='New Support Ticket'
                    />
                }}
            </Formik>
        </>
    )
};

export default SupportModal;