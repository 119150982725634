import { useEffect, useMemo, useState } from 'react';
import { BsChevronDown } from 'react-icons/bs';
import { Button } from '../button/Button';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from './DropdownMenu';
export type CheckboxItem = {
  label: string;
  id: number;
  name?: string;
  defaultChecked: boolean;
  onChange: () => void;
};

type Props = {
  items: CheckboxItem[];
  onClear: () => void;
  onChange: (checkedItems: CheckboxItem[]) => void;
  title: string;
  width?: string;
};

export function DropdownMenuFilter({ items, onClear, onChange, title, width }: Props) {
  const [searchValue, setSearchValue] = useState('');
  const [count, setCount] = useState<number>(0);
  const [checkedItems, setCheckedItems] = useState(items.filter((item) => item.defaultChecked));

  useEffect(() => {
    setCount(items.filter((item) => item.defaultChecked).length);
  }, [items]);
  const list = useMemo(() => {
    return items.filter((o) => {
      return o?.label?.toLowerCase()?.includes(searchValue.toLowerCase());
    });
  }, [searchValue, items]);
  function handleSearch(value: string) {
    setSearchValue(value);
  }

  const clearAll = () => {
    setCheckedItems([]);
    setCount(0);
    onClear();
  };

  const handleItemCheckedChange = (item: any, checked: boolean) => {
    const updatedItems = [...items];
    const findIndex = updatedItems?.findIndex((el: any) => {
      if (item?.name) {
        return el.name?.toLowerCase() === item?.name?.toLowerCase();
      }
      if (item?.label) {
        return el?.label?.toLowerCase() === item?.label?.toLowerCase();
      }
    });
    updatedItems[findIndex].defaultChecked = checked;
    setCount(updatedItems.filter((item) => item.defaultChecked).length);
    onChange(updatedItems);
    if (checked) {
      updatedItems[findIndex].defaultChecked = true;
    }
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant='outline'>
          {title}
          <span className='ml-2 bg-purple-100 text-rose-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-rose-900 dark:text-rose-300'>
            {count}
          </span>
          <BsChevronDown className='ml-2 h-4 w-4 stroke-1' />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        style={{ maxHeight: 280, overflowY: 'auto', padding: 0 }}
        className={width ?? 'w-40'}
      >
        <div className='bg-white sticky top-0 left-0 right-0 shadow-sm z-10'>
          <input
            onKeyDown={(e) => e.stopPropagation()}
            type='search'
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => handleSearch(e.target.value)}
            value={searchValue}
            placeholder='Search'
            className='h-full w-full p-2 active:outline-none focus:outline-none border-b-1'
          />
        </div>

        {list?.length ? (
          list.map((item, index) => (
            <DropdownMenuCheckboxItem
              key={index}
              checked={item.defaultChecked}
              // onCheckedChange={(checked) => handleItemCheckedChange(index, checked)}
              onCheckedChange={(checked) => handleItemCheckedChange(item, checked)}
            >
              {item.label}
            </DropdownMenuCheckboxItem>
          ))
        ) : (
          <p className='p-2 text-sm'>No results found</p>
        )}
        <DropdownMenuSeparator />
        <DropdownMenuItem className='cursor-pointer sticky bottom-0 bg-white ' onClick={clearAll}>
          <p className='text-rose-600'>Clear all</p>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
