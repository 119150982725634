import { HeaderButton } from '../../components/header-button';
import { Link, Outlet, useParams } from 'react-router-dom';
import { useGetTournamentTeamsGroupedByOrgQuery } from '../../slices/tournamentApiSlice';
import CanIView from '../../components/can-i-view/can-i-view';
import TournamentOrgCard from './components/tournament-org-card';
import { SponsorRow } from '../../components/sponsor-row/sponsor-row';
import { SponsorPlacements } from '../../utils/constants';
import { useGetAllMatchesByTournamentIdQuery } from '../../slices/matchApiSlice';

function TournamentTeams() {
  const param = useParams();

  const { data, isLoading } = useGetTournamentTeamsGroupedByOrgQuery(param.id);
  const { data: matchesByTournamentId } = useGetAllMatchesByTournamentIdQuery(param.id);
  if (isLoading) return <p>Loading...</p>;
  return (
    <div className='py-4'>
      <SponsorRow name={SponsorPlacements.TournamentTeams} extraClass='p-4' />
      <CanIView component='tournament.teams.edit'>
        {matchesByTournamentId?.data[0]?.match.length == 0 ? (
          <div className='mb-5 flex justify-end'>
            <Link to='./edit'>
              <HeaderButton type={'editTeams'} />
            </Link>
          </div>
        ) : (
          <></>
        )}
      </CanIView>
      <div className='flex flex-col gap-4 container mx-auto'>
        {data?.map((org: any, i: number) => (
          <TournamentOrgCard
            orgName={org.organization}
            key={org.organization + i}
            teamData={org.data}
          />
        ))}
      </div>
      <Outlet />
    </div>
  );
}

export default TournamentTeams;
