import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import {
  useDeleteUserMutation,
  useBooleanDeleteQuery,
  useDeleteOwnUserMutation,
} from '../../slices/userApiSlice';
import ModalDeleteUser from '../../components/modal-delete';
import { CustomError } from '../../utils/custom-types';
import { useSelector } from 'react-redux';
import { selectUser } from '../../slices/auth';
import { string } from 'yup';

function DeleteUser() {
  const param = useParams();
  console.log(param)
  const user = useSelector(selectUser);
  const [deleteUser, { isLoading, isError, isSuccess }] = useDeleteUserMutation();
  const [deleteOwnUser, { isLoading: ownIsLoading, isError: ownIsError, isSuccess: ownIsSuccess }] =
    useDeleteOwnUserMutation();
  const {
    error,
    data: userData,
    isSuccess: userIsSuccess,
    isLoading:userIsLoading,
    isError: userIsError,
  } = useBooleanDeleteQuery(param.id);
  const onSubmit = () => {
    if (param.id === String(user.id)) deleteOwnUser(param.id);
    else deleteUser(param.id);
  };
  if(userIsLoading)
  return <p>Loading...</p>
  if (userIsError || (userIsSuccess && !userData))
  return <Navigate to='/went-wrong' state={{ message: (error as CustomError)?.data?.message }} />;
  if (isSuccess) return <Navigate to='../' />;
  return (
    <ModalDeleteUser
      onSubmit={onSubmit}
      isLoading={isLoading || ownIsLoading}
      name={userData?.firstName}
    />
  );
}

export default DeleteUser;
