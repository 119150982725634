import { ChangeEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { OutsideClick } from '../../../../components/outside-click';
import useFormContext from '../../../../hooks/useFormContext';
import { selectUser } from '../../../../slices/auth';
import {
  useGenerateSchoolInviteLinkMutation,
  useGetSchoolsByOrgIdQuery,
} from '../../../../slices/schoolsApiSlice';
import { useSendStudentInvitationsMutation } from '../../../../slices/userApiSlice';
import InviteStudents from '../../../ogma-subscriptions/components/InviteStudents';
import Combobox from '../../../../components/combobox/combobox';
import { SchoolModel } from '../../../../model/school/school-model';
import { Formik } from 'formik';
import { FaCopy } from 'react-icons/fa';
import { toast } from 'react-toastify';
import SchoolInvite from '../../../school-invite/school-invite';
import { Outlet } from 'react-router';

const InviteStudentsOgma = () => {
  const [eligibilityStatus, setEligibilityStatus] = useState(true);

  function handleInvite() {
    if (isDisabled) return;
    sendStudentInvitations({
      school_id: selSchool ?? 0,
      list_students: tags,
      eligibilityStatus: eligibilityStatus ? 1 : 0,
    }).then(() => setTags([]));
  }
  // set selected school, for which you are inviting users
  const [selSchool, setSelSchool] = useState<number>();

  const [
    sendStudentInvitations,
    { isLoading: stIsLoading, isSuccess: stIsSuccess, isError: stIsError },
  ] = useSendStudentInvitationsMutation();
  const user = useSelector(selectUser);
  const {
    data: schData,
    isLoading: schIsLoading,
    isError: schIsError,
  } = useGetSchoolsByOrgIdQuery(user?.organizations[0]?.id);
  const { tags, setTags }: any = useFormContext();
  const isDisabled = !tags?.length || !selSchool || stIsLoading;
  if (schIsLoading) return <p>Loading...</p>;
  return (
    <>
      <OutsideClick />

      <div className='fixed top-0 left-0 right-0 bottom-0 z-10 w-auto my-6 mx-auto max-w-3xl max-h-screen'>
        <div className='border-0 p-10 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none h-full overflow-auto gap-2'>
          <h1 className='text-xl font-bold mr-5'>Invite Students</h1>
          <h3 className='text-l font-bold mr-5'>Step 1</h3>
          <div className=''>
            <h2>Please select the school, for which you are inviting students!</h2>
            <Combobox
              setFieldValue={setSelSchool}
              label='Select a school'
              name='school'
              options={
                schData?.length
                  ? schData.map((o: any) => ({
                      id: o.id,
                      name: new SchoolModel(o).getOptionName(),
                    }))
                  : []
              }
              noFormik
            />
          </div>
          {/* <div className={!selSchool ? 'hidden' : ''}> */}
          <div className={`${!selSchool ? 'hidden' : ''}`}>
            <h3 className='text-l font-bold mr-5'>Step 2</h3>
            <p>Share this invite link. Please note the link will expire after one week.</p>
            <SchoolInvite selSchool={selSchool} />
            <div className='flex justify-center'>
              <h1 className='w-fit flex font-bold mt-4 mb-4'>~ OR ~</h1>
            </div>
            <InviteStudents noSkip />
            {stIsSuccess && (
              <p className='text-green-700 font-bold'>Invitations are sent successfully!</p>
            )}
            {stIsError && <p className='text-red-700 font-bold'>Something went wrong!</p>}
            <div className='mb-2'>
              <input
                type='checkbox'
                id='eligibilityStatus'
                name='eligibilityStatus'
                checked={eligibilityStatus}
                onChange={() => setEligibilityStatus(!eligibilityStatus)}
              />
              <label htmlFor='eligibilityStatus'>Eligible to participate</label>
            </div>
            <div className='flex flex-col'>
              <div
                onClick={handleInvite}
                className={`button select-none w-fit self-center cursor-pointer hover:bg-opacity-80 ${
                  isDisabled ? 'disabled' : ''
                }`}
              >
                Invite Students
              </div>
            </div>
          </div>
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default InviteStudentsOgma;
