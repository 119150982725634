import React from 'react';
import { AvatarImage } from '../../../components/avatar-image';
export function CoachProfileIntro({}) {
  return (
    <div className='flex justify-between main-gr-bg-color p-10 rounded-3xl w-full'>
      <div className='flex text-center'>
        <AvatarImage source='' />
        <div className='flex items-center flex-col'>
          <p className='text-white font-bold'>4</p>
          <p className='text-white text-xs'>Years Experience</p>
        </div>
      </div>
      <div className='flex text-center'>
        <AvatarImage source='' />
        <div className='flex items-center flex-col'>
          <p className='text-white font-bold'>4</p>
          <p className='text-white text-xs'>Years Experience</p>
        </div>
      </div>
    </div>
  );
}
