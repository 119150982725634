import { useEffect } from 'react';
import { toast } from 'react-toastify';

const useSuccessToast = (isSuccess: boolean, message: string) => {
    useEffect(() => {
        if (isSuccess) {
            toast.success(message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }, [isSuccess]);
};

export default useSuccessToast;
