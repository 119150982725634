import { apiSlice } from '../api/apiSlice';

export const divisionApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    divisionExportList: builder.mutation({
      query: (data: { page: string; query: { [key: string]: any } }) => ({
        url: `/divisions/export/${data.page}`,
        method: 'GET',
        params: data.query,
        responseHandler: async (response) => {
          if (
            response.status === 200 &&
            response.headers.get('content-type')?.includes('text/csv')
          ) {
            window.open(
              window.URL.createObjectURL(await response.blob()),
              '_blank',
              'noopener,noreferrer',
            );
          } else {
            console.error('Unexpected content type or response status');
          }
        },

        cache: 'no-cache',
      }),
    }),
    getAllDivisions: builder.query({
      query: (query) => ({
        url: `/divisions/all?${query.query}=${query.param}&page=${query.page ? query.page : 1}`,
        method: 'Get',
      }),
      providesTags: ['Division', 'Subdivisions'],
    }),
    createDivision: builder.mutation({
      query: (body) => ({
        url: '/divisions/new',
        method: 'Post',
        body,
      }),
      invalidatesTags: ['Division', 'SingleSeasonInfo', 'Subdivisions'],
    }),
    updateDivision: builder.mutation({
      query: ({ id, body }) => ({
        url: `/divisions/${id}`,
        method: 'Put',
        body,
      }),
      invalidatesTags: ['Division', 'Subdivisions'],
    }),
    getDivisionById: builder.query({
      query: (id) => ({
        url: `divisions/${id}`,
        method: 'Get',
      }),
      providesTags: ['Division'],
    }),
    archiveDivision: builder.mutation({
      query: (id) => ({
        url: `divisions/archive/${id}`,
        method: 'Post',
      }),
      onQueryStarted: async (_, api) => {
        try {
          await api.queryFulfilled;
          api.dispatch(apiSlice.util.resetApiState());
        } catch (error) {
          console.log(error);
        }
      },
      invalidatesTags: [
        'Division',
        'Unarchived',
        'SingleSeasonInfo',
        'Subdivisions',
        'Tournaments',
        'Seasons',
      ],
    }),
  }),
});
export const {
  useCreateDivisionMutation,
  useGetAllDivisionsQuery,
  useGetDivisionByIdQuery,
  useUpdateDivisionMutation,
  useArchiveDivisionMutation,
  useDivisionExportListMutation,
} = divisionApiSlice;
