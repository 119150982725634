import { useGetAllMatchesByDateStatusQuery } from '../../../../../slices/matchApiSlice';
import MatchScheduleView from '../../../../schedule/routes/match-schedule/components/match-schedule-view';
import { MatchScheduleModel } from '../../../../../model/match-schedule.model';
import { DateStatus, SponsorPlacements } from '../../../../../utils/constants';
import { useParams } from 'react-router';
import { SponsorRow } from '../../../../../components/sponsor-row/sponsor-row';

function TeamSchedule() {
  const param = useParams();
  const {
    data: event,
    isError,
    isSuccess,
    isLoading,
  } = useGetAllMatchesByDateStatusQuery([
    {
      name: undefined,
      date: undefined,
      page: 1,
      limit: 3,
      team: Number(param.id),
    },
    { dateStatus: DateStatus.Upcoming },
  ]);
  // name: '', // date: '',

  if (isLoading) return <p>Loading...</p>;
  return (
    <div className='flex flex-col w-full  bg-white rounded-2xl gap-5'>
      <div>
        <SponsorRow name={SponsorPlacements.TeamsSchedule} />
      </div>
      <h1 className='font-bold text-xl'>Upcoming Matches</h1>
      {event.data.map((match: any, i: number) => (
        // <GuardianDashMatchRow {...new MatchModel(match)} />
        <MatchScheduleView fullDate key={i} scheduleModel={new MatchScheduleModel(match)} />
      ))}
    </div>
  );
}

export default TeamSchedule;
