import { UserRoles } from '../../../utils/constants';

export const generalPermissions: { [key: string]: number[] } = {
  threedots: [UserRoles.Admin],
  gameresults: [UserRoles.Admin],
  editGameresults: [UserRoles.Admin],
  userRolesButton: [UserRoles.Admin],
  studentgameresults: [UserRoles.Student, UserRoles.Admin, UserRoles.Ogma],
  newUser: [UserRoles.Admin],
  gameteams: [UserRoles.Admin, UserRoles.Ogma],
  'user.switchstatus': [UserRoles.Admin, UserRoles.Ogma],
  'user.gradeLevel': [UserRoles.Student],
  exportListBtn: [UserRoles.Admin, UserRoles.Student],
  'profile.Team': [UserRoles.Admin, UserRoles.Student, UserRoles.Ogma],
  'profile.School': [UserRoles.Admin, UserRoles.Ogma],
  'admin.inviteStudents': [UserRoles.Admin],
  generateInviteLink: [UserRoles.Admin, UserRoles.Ogma],
  organizationSections: [UserRoles.Admin],
  coopTeam: [UserRoles.Admin],
  profileSupport: [
    UserRoles.Admin,
    UserRoles.Student,
    UserRoles.Ogma,
    UserRoles.Coach,
    UserRoles.Guardian,
  ],
};
