import React, { useEffect, useRef, useState } from 'react';
import { AvatarImage } from '../../../components/avatar-image';
import { useDispatch, useSelector } from 'react-redux';
import { selectClassroomChatMessages, selectMatchData } from '../../../slices/matchSlice';
import { selectUser } from '../../../slices/auth';
import { useParams } from 'react-router-dom';
import { useInitClassroomChatMessagesQuery, useLazyInitClassroomChatMessagesQuery } from '../../../slices/classroomApiSlice';
import useIntersectionObserver from '../../../hooks/use-intersection-observer';
import DeleteChatMessage from '../../../components/delete-chat-msg/DeleteChatMessage';
import { UserRoles } from '../../../utils/constants';
import { AiOutlineStop } from 'react-icons/ai';

export function ViewClassroomChatMessage({
  test,
  handleDeleteChat,
}: {
  test: Boolean;
  handleDeleteChat: (m: number) => void;
}) {
  const [fetch, setFetch] = useState(false);
  const elementRef = useRef(null);
  const allChatMessages = useSelector(selectClassroomChatMessages);
  const user = useSelector(selectUser);
  const matchData = useSelector(selectMatchData);
  const dispatch = useDispatch();

  const param = useParams();
  const [page, setPage] = useState<number>();

  const { data, isLoading, isSuccess } = useInitClassroomChatMessagesQuery({
    id: param.id,
    page,
  });
  const [chatMessageId, setChatMessageId] = useState<any>(null);

 
  const entry = useIntersectionObserver(elementRef, {});
  const isVisible = !!entry?.isIntersecting;

  const hasIOSupport = !!window.IntersectionObserver;
  useEffect(() => {
    if (isSuccess) console.log('isVisible', fetch);
    if (!isVisible || !fetch || isLoading || !data || !data.meta.next) return;
    setPage(data.meta.next);
  }, [isVisible, data?.meta, fetch]);
  useEffect(() => {
    console.log('isLoading', isLoading, fetch);
    if (isLoading) setFetch(false);
    else setTimeout(() => setFetch(true), 2000);
  }, [isLoading]);
  let x: number;
  {
    user?.team?.map((t: any, i: number) => {
      if (t.game_id === matchData.game_id) {
        x = i;
      }
    });
  }

  const handleMessageClick = (e: any, msgId: number) => {
    e.stopPropagation();
    if (chatMessageId === msgId) {
      setChatMessageId(null);
    } else {
      setChatMessageId(msgId);
    }
  };

  return (
    <div
      className='flex-1 p-2 gap-2 flex flex-col overflow-auto'
      onClick={() => setChatMessageId(null)}
    >
      {allChatMessages.map((ch: any, i: number) => (
        <React.Fragment key={i}>
          {user.roles === UserRoles.Admin ? (
            <div
              className={`flex items-center gap-2 w-full max-w-full relative ${ch.isMe || ch.user?.id === user.id || ch?.user_id === user.id ? 'justify-end' : ''
                }`}
              key={i}
            >
              <div className={`flex gap-2 ${ch.isMe || ch.user?.id === user.id || ch?.user_id === user.id ? 'order-2' : 'order1'}`}>
                <AvatarImage
                  size='xl'
                  source={ch.image ? ch.image : ch.user?.image}
                  extraClass={
                    ch.isMe || ch.user?.id === user.id || ch?.user_id === user.id ? 'order-2' : ''
                  }
                />
                {/* {chatMessageId === ch?.id && user.roles === UserRoles.Admin && (
                  <DeleteChatMessage handleDeleteChat={handleDeleteChat} msgId={ch?.id} />
                )} */}
                {chatMessageId === ch?.id && <DeleteChatMessage handleDeleteChat={handleDeleteChat} msgId={ch?.id} />}
                <div
                  className={`chat__body relative min-w-[7rem] ${ch.isMe || ch.user?.id === user.id || ch?.user_id === user.id
                    ? 'order-1 before:hidden cursor-pointer'
                    : 'after:hidden cursor-pointer'
                    } ${ch.isDeleted ? 'opacity-75' : ''} `}
                  onClick={(e) => handleMessageClick(e, ch?.id)}
                >
                  <div
                    className={`flex gap-2 ${ch.isMe || ch?.user?.id === user.id || ch?.user_id === user.id
                      ? 'justify-end'
                      : ''
                      }`}
                  >
                    <p className={`text-black font-bold `}>
                      {ch.username ? ch.username : ch.user?.username}
                    </p>
                    <span>{ch.team ? ch.team : ch.user?.team[x]?.teamName}</span>
                  </div>
                  <p className='text-black break-all'>{ch.message}</p>
                  <div className='flex justify-end text-xs'>
                    <p>{ch.time ? new Date(ch.time).toTimeString().split(' ')[0] : ch.clock}</p>
                  </div>
                  {ch.isDeleted ? (
                    <div className={`absolute z-50 bottom-1 ${ch.isMe || ch.user?.id === user.id || ch?.user_id === user.id ? '' : ''}`}>
                      <div className="group">
                        <AiOutlineStop className='text-red-500' />
                        <div className={`hidden group-hover:block absolute top-[-50px] z-50 w-40 max-w-40 h-fit bg-black opacity-75 rounded p-1 ${ch.isMe || ch.user?.id === user.id || ch?.user_id === user.id ? 'left-0' : 'left-0'}`}>
                          <span className='overflow-ellipsis whitespace-normal text-white'>This message has been deleted</span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    null
                  )}
                </div>
              </div>
            </div>
          ) : user.roles !== UserRoles.Admin && ch.isDeleted !== 1 ? (
            <div
              className={`flex gap-2 w-full max-w-full ${ch.isMe || ch.user?.id === user.id || ch?.user_id === user.id ? 'justify-end' : ''
                }`}
              key={i}
            >
              <AvatarImage
                size='xl'
                source={ch.image ? ch.image : ch.user?.image}
                extraClass={
                  ch.isMe || ch.user?.id === user.id || ch?.user_id === user.id ? 'order-2' : ''
                }
              />
              <div
                className={`chat__body ${ch.isMe || ch.user?.id === user.id || ch?.user_id === user.id
                  ? 'order-1 before:hidden cursor-pointer'
                  : 'after:hidden cursor-pointer'
                  } `}
              >
                <div
                  className={`flex gap-2 ${ch.isMe || ch?.user?.id === user.id || ch?.user_id === user.id
                    ? 'justify-end'
                    : ''
                    }`}
                >
                  <p className={`text-black font-bold `}>
                    {ch.username ? ch.username : ch.user?.username}
                  </p>
                  <span>{ch.team ? ch.team : ch.user?.team[x]?.teamName}</span>
                </div>
                <p className='text-black break-all'>{ch.message} </p>
                <div className='flex justify-end text-xs'>
                  <p>{ch.time ? new Date(ch.time).toTimeString().split(' ')[0] : ch.clock}</p>
                </div>
              </div>
            </div>
          ) : null}
        </React.Fragment>
      ))}
      {isLoading ? (
        <div>Loading</div>
      ) : (
        <div ref={elementRef} className='flex flex-1 w-full min-h-[10px]'></div>
      )}
      {!hasIOSupport && data.meta.next && <span>Load more</span>}
    </div>
  );
}
