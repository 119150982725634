import { ViewClassroomChat } from './components/view-classroom-chat';
import { ViewClassroomLessonRow } from './components/view-classroom-lesson-row';
import { ViewClassroomHeader } from './components/view-classroom-header';
import { useEffect, useRef, useState } from 'react';
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom';
import { selectToken, selectUser } from '../../slices/auth';
import { useDispatch, useSelector } from 'react-redux';
import {
  addClassroomChatMessage,
  removeClassroomChatMessage,
  selectMatchData,
} from '../../slices/matchSlice';
import { Socket, io } from 'socket.io-client';
import { useJoinClassroomQuery } from '../../slices/classroomApiSlice';
import { ClassroomModel } from '../../model/classroom.model';
import { SocketMessages, UserRoles } from '../../utils/constants';
import OtherClassrooms from './other-classrooms';
import { ParticipantType } from '../../utils/utils';

function ViewClassroom() {
  const [tempChat, setTempChat] = useState('');
  const [haveHours, setHaveHours] = useState(true);
  const param = useParams();
  const user = useSelector(selectUser);
  const matchData = useSelector(selectMatchData);
  console.log('user', user);
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const { pathname } = useLocation();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!containerRef.current) return;
    containerRef.current.scrollTo(0, 0);
  }, [pathname]);
  useEffect(() => {
    if (socket) return;
    setSocket(io(url ?? '', { extraHeaders: { token } }));
  }, []);
  const url = import.meta.env.VITE_CLASSROOM_CHAT;
  const [socket, setSocket] = useState<Socket>();
  const [chatParticipants, setChatParticipants] = useState<ParticipantType>([]);
  useEffect(() => {
    if (!socket) return;
    if (user.roles !== UserRoles.Ogma)
      socket.emit('check', {
        user_id: user.id,
        user_role: user.roles,
        classroom_id: Number(param.id),
      });
    socket.emit('join-room', { room: `classroom-${param.id}`, id: user.id });
    socket.on('participant', (msg: ParticipantType) => {
      console.log('received');
      setChatParticipants(msg);
    });
    socket.on('classroom-chat', (msg: string) => {
      const parsed = JSON.parse(msg);
      dispatch(
        addClassroomChatMessage({
          user_id: parsed.user_id,
          username: parsed.username,
          team: parsed.team,
          message: parsed.message,
          image: parsed.image,
          clock: parsed.time,
          id: parsed.id,
          isDeleted: parsed.isDeleted,
        }),
      );
    });
    socket.on('classroom-chat-delete', (msg: any) => {
      const parsed = JSON.parse(msg);
      console.log('delete msg', parsed);
      if (!parsed.badResponse) {
        dispatch(removeClassroomChatMessage({ id: parsed.id, isDeleted: parsed.isDeleted }));
      }
    });
    socket.on('check', (msg: { message: string; user?: number }) => {
      if (msg.message === 'stop' && msg.user === user.id) {
        setHaveHours(false);
        socket.close();
        socket.disconnect();
        socket.offAny();
      }
    });
    return () => {
      socket.close();
      socket.disconnect();
      socket.offAny();
    };
  }, [socket]);
  const {
    data: joinData,
    isLoading: joinLoading,
    isSuccess: joinSuccess,
    isError: joinError,
    error: joinErrorObj,
  } = useJoinClassroomQuery(param.id ?? '');

  function sendChat(message: any, team: boolean) {
    if (message) {
      let payload = {
        room: `classroom-${param.id}`,
        user_id: user.id,
        username: user.username,
        classroom_id: param.id,
        // team: user?.team?.length ? user.team[teamId].teamName : '',
        team: '',
        message,
        image: user.image ? user.image : '',
        time: new Date().toTimeString().split(' ')[0],
      };
      if (socket) socket.emit('classroom-chat', JSON.stringify(payload));
    }
  }
  const handleDeleteChatMessage = (msgId: number) => {
    if (msgId) {
      const payload = {
        room: `classroom-${param.id}`,
        userRole: user.roles,
        id: msgId,
      };
      // dispatch({ type: SocketActions.DeleteClassroomMessage, payload });
      socket?.emit(SocketMessages.EmitDeleteChatMsg, JSON.stringify(payload));
      console.log('first');
    }
  };

  if (joinLoading) return <p>Loading...</p>;
  if (joinErrorObj && 'status' in joinErrorObj && joinErrorObj.status === 402)
    return <Navigate to={`/classrooms/preview/${param.id}`} />;
  if (joinError) return <p>You don't have access here!</p>;
  if (!haveHours) return <p>You don't have scheduled hours to continue watching!</p>;
  const model = new ClassroomModel(joinData);
  return (
    <div ref={containerRef} className='listViewContainer fullPage'>
      <ViewClassroomHeader
        image={model.banner}
        title={model?.name}
        description={model.description}
      />
      <div className='container mx-auto flex flex-col gap-4'>
        <div className='grid grid-cols-3 items-center border rounded-xl shadow-lg'>
          <div className='col-span-2 flex h-full justify-center items-center border rounded-l-xl bg-black'>
            {!param.lessonid ? (
              <div className='text-white'>Please select a lesson content...</div>
            ) : (
              <Outlet />
            )}
          </div>
          <ViewClassroomChat
            sendChat={sendChat}
            tempChat={tempChat}
            setTempChat={setTempChat}
            participants={chatParticipants}
            handleDeleteChat={handleDeleteChatMessage}
          />
        </div>
        <div className='flex flex-wrap gap-4 justify-between'>
          <div className='flex flex-col gap-2 flex-1 max-w-[60%]'>
            <h2 className=' font-semibold'>Lessons</h2>
            {!model.lessons.length && <p>There are no lessons in this classroom!</p>}
            {model.lessons.map((l, i: number) => (
              <ViewClassroomLessonRow
                active={+pathname.split('/').slice(-1)[0] === l.id}
                key={i}
                banner={model.banner}
                lesson={l}
              />
            ))}
          </div>
          <OtherClassrooms
            cId={joinData.id}
            id={joinData.length ? joinData[0]?.user_id : joinData?.user_id}
            gameId={joinData.length ? joinData[0]?.game_id : joinData?.game_id}
          />
        </div>
      </div>
    </div>
  );
}

export default ViewClassroom;
