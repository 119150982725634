import { TeamSection } from './../../../../components/generate-match/team-section';
import { MatchSection } from './../../../../components/generate-match/match-section';
import * as yup from 'yup';
import { useState } from 'react';
import { OutsideClick } from '../../../../components/outside-click';
import { Navigate, useParams } from 'react-router-dom';
import {
  useGetAllTeamsByTournamentIdQuery,
  useGetAllTeamsByWeekIdQuery,
} from '../../../../slices/teamsApiSlice';
import { Formik, Form, FormikValues } from 'formik';
import { useManualGenerateMatchMutation } from '../../../../slices/matchApiSlice';
import { TeamModel } from '../../../../model/team.model';
import ModalHeader from '../../../../components/modal-header';
import ModalFooter from '../../../../components/modal-footer';
import {
  checkIfDateIsFuture,
  checkIfDateIsTodayOrFuture,
  checkStartDate,
} from '../../../../utils/utils';
import { MatchGenerationTypes, SchemaMessages } from '../../../../utils/constants';
import { Button } from '../../../../components/ui/button/Button';
import { useGenerateMatchesForWeekMutation } from '../../../../slices/seasonApiSlice';
import { toast } from 'react-toastify';
export type SelectionType<T extends 'match' | 'team'> = {
  type?: T;
  team?: T extends 'match' ? 'at' | 'ht' : T extends 'team' ? number : never;
  index?: T extends 'match' ? number : undefined;
};
type MatchTeamType = { id?: number; teamName?: string; teamSide?: 'ht' | 'at'; isBenched?: number };
export type MatchesType = { ht?: MatchTeamType | undefined; at?: MatchTeamType | undefined }[];
export const GenerateMatch = ({ type }: { type: 'season' | 'tournament' }) => {
  const [matches, setMatches] = useState<MatchesType>([{ ht: {}, at: {} }]);
  const params = useParams();
  const [selections, setSelections] = useState<SelectionType<'match' | 'team'>>();
  const { data, error, isLoading } =
    type === 'tournament'
      ? useGetAllTeamsByTournamentIdQuery(params.id, { skip: !params.id || type !== 'tournament' })
      : useGetAllTeamsByWeekIdQuery(
          {
            id: params.weekid,
            seasonId: params.id,
          },
          { skip: !params.weekid || params.weekid === null || !params.id || type !== 'season' },
        );

  const [
    manualMatchGenerator,
    { isError, isLoading: generatorIsLoading, data: matchData, isSuccess },
  ] = useManualGenerateMatchMutation();

  function handleSubmit(values: FormikValues) {
    console.log(params.weekid);

    manualMatchGenerator({
      match_date: new Date(values.match_date).toISOString(),
      matches: matches.filter((m) => m.ht || m.at),
      games_per_match: data[0].season[0].SeasonWeek[0].games_per_match,
      game_id: +data[0].season[0].SeasonWeek[0].Season.game_id,
      season_week_id: +params?.weekid!,
    });
  }

  const [generateMatchData, { data: generatedMatchData }] = useGenerateMatchesForWeekMutation();
  const handleGenerateMatchesClick = async (e: any, generationType: number) => {
    try {
      e.preventDefault();
      const generatedMatchData = await generateMatchData({
        seasonId: +params.id!,
        seasonWeekId: +params.weekid!,
        generationType: generationType,
      }).unwrap();
      const namedMatches = generatedMatchData.map((match: { ht: any; at: any }) => {
        const htModel = match.ht ? new TeamModel(match.ht) : null;
        const atModel = match.at ? new TeamModel(match.at) : null;
        const at = match.at ? { at: { ...match.at, teamName: atModel?.getOptionName() } } : {};

        return {
          ...match,
          ht: { ...match.ht, teamName: htModel?.getOptionName() },
          ...at,
        };
      });

      setMatches(namedMatches);
    } catch (error: any) {
      if (error.data.message)
        toast.error(error.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      else {
        toast.error('Could not modify seasons.', {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      return;
    }
  };

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const GenerateMatchVSchema = yup.object().shape({
    match_date: yup
      .mixed()
      .transform((value, originalValue) => originalValue)
      .test('test', SchemaMessages.SeasonWeekDate, (value, ctx) => {
        if (!value) return false;
        const dateMatch = data?.[0]?.season?.[0]?.SeasonWeek?.[0]?.start_time.split('T')[0];
        const isAfter = checkIfDateIsTodayOrFuture(dateMatch, value.split('T')[0]);
        return isAfter;
      })
      .test('test', SchemaMessages.DateShouldNotBeInThePast, checkStartDate)

      .required('Required!'),
  });

  const showBenchedForMatch = (d: any) => {};

  function handleSelection<T extends 'match' | 'team'>(
    selectProps: SelectionType<T>,
    index?: number,
  ) {
    if (selectProps.type === 'team') {
      if (selections?.type === 'team') {
        setSelections({});
        return;
      } else {
        setSelections({ team: selectProps.team, type: 'team' });
        if (!selections?.type) return;
        const teamToAdd = data.filter((t: any) => t.id === selectProps.team)?.[0];
        const model = new TeamModel(teamToAdd);
        const matchToUpdate = matches?.[selections?.index!];
        if (matchToUpdate[selections?.team === 'ht' ? 'at' : 'ht']?.id === model.id) {
          setSelections({});
          return;
        }
        const finalTeam: MatchTeamType = {
          id: teamToAdd.id,
          teamName: model.getOptionName()!,
          teamSide: selectProps?.team === 'ht' ? 'ht' : 'at',
        };
        const finalList = matches;
        finalList.splice(
          selections?.index!,
          1,
          Object.assign(matchToUpdate, { [selections?.team!]: finalTeam }),
        ),
          setMatches(finalList);
        setSelections({});
        return;
      }
    }
    if (selectProps?.type === 'match') {
      if (selections?.type === 'match') {
        setSelections({});
        return;
      } else {
        setSelections({ type: selectProps.type, index, team: selectProps.team });
        if (!selections?.type) return;
        const teamToAdd = data.filter((t: any) => t.id === selections?.team)?.[0];
        const model = new TeamModel(teamToAdd);
        const matchToUpdate = matches[index!];
        const finalTeam: MatchTeamType = {
          id: model.id!,
          teamName: model.getOptionName()!,
          teamSide: selectProps.team === 'ht' ? 'ht' : 'at',
          isBenched: model.isBenched,
        };

        if (matchToUpdate[selectProps?.team === 'ht' ? 'at' : 'ht']?.id === model.id) {
          setSelections({});
          return;
        }
        let finalList = matches;
        finalList.splice(
          index!,
          1,
          Object.assign({}, matchToUpdate, {
            [selectProps.team === 'ht' ? 'ht' : 'at']: finalTeam,
          }),
        ),
          setMatches(finalList);
        setSelections({});
      }
    }
  }
  if (isLoading) return <p>Loading...</p>;
  if (isSuccess) return <Navigate to='../' />;
  return (
    <>
      <OutsideClick />
      <Formik
        validationSchema={GenerateMatchVSchema}
        initialValues={{ match_date: undefined }}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        <Form>
          <div
            className='fixed h-fit top-0 left-0 bottom-0 right-0 m-auto z-10 
            w-auto flex items-center justify-center  container'
          >
            <div
              className='border-0 rounded-lg shadow-lg relative flex flex-col
               w-full bg-white outline-none focus:outline-none h-[90vh] '
            >
              <ModalHeader name='Generate Match for Week' />
              <div className='p-3 flex-1 min-h-0 grid grid-cols-3 overflow-auto h-full'>
                <div className='flex flex-col font-bold border-r-1 gap-2 pr-2 h-full overflow-auto'>
                  <TeamSection
                    type={type}
                    matches={matches}
                    setMatches={setMatches}
                    selections={selections}
                    handleSelection={handleSelection}
                  />
                </div>
                <div className='flex flex-col col-span-2 font-bold overflow-auto'>
                  <MatchSection
                    matches={matches}
                    setMatches={setMatches}
                    selections={selections}
                    handleSelection={handleSelection}
                  />
                  <div className='text-center border-black border-2'>
                    <h3>Generate Matches</h3>
                    <div className='flex'>
                      <Button
                        className='w-full m-2 bg-fen-blue'
                        type='button'
                        onClick={(e) =>
                          handleGenerateMatchesClick(e, MatchGenerationTypes.RoundRobin)
                        }
                        variant='outline'
                      >
                        Round Robin
                      </Button>
                      <Button
                        className='w-full  m-2 bg-fen-blue'
                        type='button'
                        onClick={(e) => handleGenerateMatchesClick(e, MatchGenerationTypes.Swiss)}
                        variant='ghost'
                      >
                        Swiss
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <ModalFooter isLoading={generatorIsLoading!} />
            </div>
          </div>
        </Form>
      </Formik>
    </>
  );
};
