import { useState } from 'react';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import { NameAndAvatar } from '../../components/name-and-avatar';
import TableContainer from '../../components/table-container/table-container';
import { TableHead } from '../../components/table-head';
import TableTd from '../../components/table-td/table-td';
import { Separator } from '../../components/ui/separator/Separator';
import DropdownItem from '../../features/listview/DropDown/DropDownItem';
import { useGetAllSponsorsQuery } from '../../slices/sponsorApiSlice';
import { HeaderButton } from '../../components/header-button';
import { UserRoles } from '../../utils/constants';
import { SponsorModel } from '../../model/sponsor.model';
import { useSelector } from 'react-redux';
import { selectUser } from '../../slices/auth';
import Pagination from '../../components/pagination';
import { dateAndTimeFormatter } from '../../utils/dateFormatter';
import { LoadingSceletonSponsors } from '../tournaments/routes/all/loading-sceleton-sponsors';

const Sponsors = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const user = useSelector(selectUser);
  const navigate = useNavigate();

  const { data, isLoading, isError, isSuccess } = useGetAllSponsorsQuery(
    searchParams.get('page') ? searchParams.get('page') : 1,
  );
  console.log('sponsors data', data);
  const initialOpenListState = {
    id: null,
    y: null,
    x: null,
  };
  const [openList, setOpenList] = useState<{ [key: string]: number | null }>(initialOpenListState);
  const [openListView, setOpenListView] = useState(false);

  function handleClick(prop: { id: number; x: number; y: number }) {
    setOpenListView(false);
    if (openList.id == prop.id) {
      setOpenList(initialOpenListState);
    } else {
      setOpenList(prop);
    }
    function handleListView() {
      setOpenList(initialOpenListState);
      setOpenListView(!openListView);
    }
  }
  function goPrevPage() {
    setSearchParams({ page: `${data.meta.prev}` });
  }
  function goNextPage() {
    setSearchParams({ page: `${data.meta.next}` });
  }
  const listView = ['name', 'Start Date', 'expiration date'];
  if (isLoading) return <LoadingSceletonSponsors />;
  return (
    <div className='listViewContainer fullPage overflow-auto flex flex-col justify-between border-1 rounded-xl'>
      <div className='flex w-full justify-between'>
        <div className='flex items-center justify-between'>
          <div className='space-y-1'>
            <h2 className='text-2xl font-semibold tracking-tight'>Sponsors</h2>
          </div>
        </div>
        <div className='flex gap-5 flex-wrap md:justify-end justify-center content-center items-center  '>
          <HeaderButton type='newSponsor' />
          <HeaderButton type='sponsorPlacement' />
        </div>
      </div>
      <div>
        <Separator className='my-4' />
      </div>
      <div className='flex-1'>
        <TableContainer>
          <TableHead tableList={['name', 'Start Date', 'expiration date']} bool={true} />
          <tbody className='bg-white divide-y divide-gray-200'>
            {data.data.map((t: any, i: number) => {
              const model: SponsorModel & { [key: string]: any } = new SponsorModel(t);
              return (
                <tr
                  onClick={(e) =>
                    user.roles === UserRoles.Admin
                      ? handleClick({ id: t.id, x: e.pageX, y: e.pageY })
                      : navigate('kl')
                  }
                  className={user.roles !== UserRoles.Admin ? 'cursor-pointer' : 'relative'}
                  key={i}
                >
                  {listView.map((o, i) =>
                    o.toLowerCase() === 'name' ? (
                      <TableTd key={i}>
                        <NameAndAvatar image={t.image} name={t.name} />
                      </TableTd>
                    ) : o.toLowerCase() === 'start date' ? (
                      <TableTd key={i}>{dateAndTimeFormatter(t.start_date).split(';')[0]}</TableTd>
                    ) : o.toLowerCase() === 'expiration date' ? (
                      <TableTd key={i}>
                        {dateAndTimeFormatter(t.expiration_date).split(';')[0]}
                      </TableTd>
                    ) : (
                      <TableTd key={i}>{t[o]}</TableTd>
                    ),
                  )}
                  {/* <TableTd
                  className="px-6 py-4 notworking-whitespace-nowrap text-right text-sm font-medium"
                  >
                    <div className='listViewMenu--parent'>
                      <div onClick={() => handleClick(t.id)}>
                        <button
                        type='button'
                        className='inline-flex w-full justify-center rounded-md bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50'
                        id='menu-button'
                        aria-expanded='true'
                        aria-haspopup='true'
                      >
                        <BsThreeDots />
                      </button>
                      </div>

                      <div className={openList === t.id ? '' : 'hidden'}>
                        <div
                          className='listViewMenu'
                          role='menu'
                          aria-orientation='vertical'
                          aria-labelledby='menu-button'
                        >
                          <div className='py-1' role='none'>
                            <DropdownItem id={t.id} noView setFunc={setOpenList} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </TableTd> */}
                  <TableTd
                  // className="px-6 py-4 notworking-whitespace-nowrap text-right text-sm font-medium"
                  >
                    <div className='listViewMenu--parent'>
                      {/* <div onClick={() => handleClick(t.id)}>
                          <button
                            type='button'
                            className='inline-flex w-full justify-center rounded-md bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50'
                            id='menu-button'
                            aria-expanded='true'
                            aria-haspopup='true'
                          >
                            <BsThreeDots />
                          </button>
                        </div> */}

                      <div
                        className={openList.id === t.id ? '' : 'hidden'}
                        style={
                          openList.id === t.id
                            ? {
                                position: 'fixed',
                                top: Number(openList.y),
                                left:
                                  Number(openList.x) < 200
                                    ? Number(openList.x) + 200
                                    : Number(openList.x),
                                zIndex: 99,
                              }
                            : {
                                left: 0,
                              }
                        }
                      >
                        <div
                          className='listViewMenu'
                          role='menu'
                          aria-orientation='vertical'
                          aria-labelledby='menu-button'
                        >
                          <div className='' role='none'>
                            <DropdownItem
                              {...(model.roleNumber === UserRoles.Admin ||
                              model.roleNumber === UserRoles.Fan
                                ? { noView: true }
                                : {
                                    viewLink: `./view/${model.id}/${model.role}`,
                                  })}
                              id={t.id}
                              setFunc={setOpenList}
                              noView
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </TableTd>
                </tr>
              );
            })}
          </tbody>
        </TableContainer>
      </div>
      <Outlet />

      <Pagination length={data?.data?.length} {...data?.meta} />
      {/* <div className='flex w-full justify-between'>
        <span>
          {data.data.length} of {data.meta.total} results
        </span>
        <div className='flex gap-2 items-center'>
          <span>
            {data.meta.currentPage} of {data.meta.lastPage}
          </span>
          <div className='cursor-pointer' onClick={data.meta.prev ? goPrevPage : () => null}>
            <BsArrowLeft color={data.meta.prev ? '#b50303' : 'grey'} />
          </div>
          <div className='cursor-pointer' onClick={data.meta.next ? goNextPage : () => null}>
            <BsArrowRight color={data.meta.next ? '#b50303' : 'grey'} />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Sponsors;
