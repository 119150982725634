import { useState } from 'react';
import { Link } from 'react-router-dom';
import { AvatarImage } from '../../../components/avatar-image';
import { useGetAllFollowedStreamersQuery } from '../../../slices/userApiSlice';

export const FollowedStreamers = ({ open = true }) => {
  // const [open, setOpen] = useState(true);
  const { data, isError, isLoading, isSuccess } = useGetAllFollowedStreamersQuery('');

  if (isLoading) return <p> Loading ...</p>;

  return (
    <ul className='pt-8'>
      {data?.map((u: any, index: number) => (
        <li
          key={index}
          className={`flex min-w-full rounded-md py-2 relative cursor-pointer hover:opacity-80 text-gray-300 text-sm items-center gap-x-4 
               `}
        >
          <Link
            className='flex w-full gap-x-3 items-center'
            to={`/gamearena/streamer/${u.id}`}
          >
            <AvatarImage source={u.image} size='xd' />
            <div className='flex w-full items-center  justify-between'>
              <span className={`${!open && 'hidden'} origin-left text-black duration-200 `}>
                {u.twitchUsername}
              </span>
              {u.isStreaming ? <div className='flex items-center gap-1'>
                <span
                  className={`${!open && 'hidden'
                    } origin-left blink-animation  h-[10px] w-[10px] bg-red-800 rounded-full`}
                ></span>
                <h2 className={`${!open && 'hidden'} text-center items-end text-black`}>LIVE</h2>
              </div> : <></>}
            </div>
          </Link>
        </li>
      ))}
    </ul>
  );
};
