import { Link, Navigate, useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { OutsideClick } from '../../components/outside-click';
import { SubmitButton } from '../../components/submit-button';
import { useDeleteSupportTicketByIdMutation, useGetTicketByIdQuery } from '../../slices/supportTicketApiSlice';

const DeleteTicket = () => {
    const param = useParams();
    const { data: ticket } = useGetTicketByIdQuery(param.id);
    const [deleteTicket, { isSuccess, isLoading }] = useDeleteSupportTicketByIdMutation();

    const onSubmit = () => {
        deleteTicket(param.id).unwrap()
            .then(resp => console.log('ticket deleted', resp))
            .catch(err => console.log('ticket couldnt be deleted', err));
    };

    if (isSuccess) {
        return <Navigate to="../" />;
    }

    return (
        <div className='fixed z-20 top-0 left-0 h-[100vh] w-[100vw] flex justify-center items-center'>
            <OutsideClick />
            <div className='flex flex-col items-center gap-5 z-20 p-10 bg-white rounded-xl shadow-xl'>
                <p>Are you sure you want to delete <span className="font-bold">{ticket?.title}</span>?</p>
                <Formik initialValues={{}} onSubmit={onSubmit}>
                    <Form className='flex gap-2'>
                        <SubmitButton title='Delete' isLoading={isLoading} />
                        <Link
                            to='../'
                            className='px-8 text-center text-sm py-2 bg-white text-black border font-semibold rounded-lg hover:bg-gray-100'
                        >
                            No
                        </Link>
                    </Form>
                </Formik>
            </div>
        </div>
    );
};

export default DeleteTicket;