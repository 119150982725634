import { apiSlice } from '../api/apiSlice';

export const seasonApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    removeTeamFromSeason: builder.mutation({
      query: (body: { season_id: number; team_id: number }) => ({
        url: '/season/remove-team',
        method: 'PUT',
        body,
      }),
      invalidatesTags: [
        'SingleSeasonInfo',
        'SeasonStanding',
        'SeasonWeek',
        'Seasons',
        'Match',
        'Team',
      ],
    }),
    submitScoreToMatch: builder.mutation({
      query: ({ id, body }: { id: string; body: FormData }) => ({
        url: `/season-week/match-submit/${id}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['SeasonWeek', 'SingleSeasonInfo', 'SeasonStanding', 'Match'],
    }),
    submitScoresByCsv: builder.mutation({
      query: ({ seasonWeekId, body }: { seasonWeekId: number; body: FormData }) => ({
        url: `/season-week/submit-csv-scores/${seasonWeekId}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['SeasonWeek', 'SingleSeasonInfo', 'SeasonStanding', 'Match'],
    }),
    canSubmitScore: builder.query({
      query: (id: number | string) => ({
        url: `/season-week/can-submit/${id}`,
        method: 'GET',
      }),
    }),
    // getSeasonWeekByMatchId: builder.query({
    //   query: (id: number | string) => ({
    //     url: `/season-week/match/${id}`,
    //     method: 'GET',
    //   }),
    //   providesTags: ['SingleSeasonInfo', 'SeasonWeek'],
    // }),
    openSeasonWeek: builder.mutation({
      query: (id: number) => ({
        url: `/season-week/open/${id}`,
        method: 'PUT',
      }),
      invalidatesTags: ['SeasonWeek', 'SingleSeasonInfo', 'SeasonStanding', 'Match'],
    }),
    closeSeasonWeek: builder.mutation({
      query: (id: number) => ({
        url: `/season-week/close/${id}`,
        method: 'PATCH',
      }),
      invalidatesTags: ['SeasonWeek', 'SingleSeasonInfo', 'SeasonStanding', 'Match'],
    }),
    publishSeasonWeek: builder.mutation({
      query: (id: number) => ({
        url: `/season-week/publish/${id}`,
        method: 'PATCH',
      }),
      invalidatesTags: ['SeasonWeek', 'SingleSeasonInfo', 'SeasonStanding', 'Match'],
    }),
    seasonExportList: builder.mutation({
      query: ({ page, query }: { page: string; query: { [key: string]: string | undefined } }) => ({
        url: `/season/export/${page}`,
        method: 'GET',
        params: query,
        responseHandler: async (response) => {
          if (
            response.status === 200 &&
            response.headers.get('content-type')?.includes('text/csv')
          ) {
            window.open(
              window.URL.createObjectURL(await response.blob()),
              '_blank',
              'noopener,noreferrer',
            );
          } else {
            console.error('Unexpected content type or response status');
          }
        },
        cache: 'no-cache',
      }),
    }),
    updateSeasonTeam: builder.mutation({
      query: ({ id, body }) => ({
        url: `/season/update/${id}/teams`,
        method: 'Put',
        body,
      }),
      invalidatesTags: ['Seasons', 'SingleSeasonInfo', 'SeasonStanding', 'Team'],
    }),
    createSeasonStage: builder.mutation({
      query: (body) => ({
        url: '/season-stage/new',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['SingleSeasonInfo', 'Seasons'],
    }),
    createSeasonWeek: builder.mutation({
      query: ({
        id,
        body,
      }: {
        id: string | undefined;
        body: {
          name: string;
          start_time: Date;
          end_time?: Date;
          format_id: number;
          num_weeks?: number;
        };
      }) => ({
        url: `/season-week/new/${id}`,
        method: 'Post',
        body,
      }),
      invalidatesTags: ['SingleSeasonInfo', 'SeasonWeek'],
    }),
    editSeasonWeek: builder.mutation({
      query: ({
        id,
        body,
      }: {
        id: string | undefined;
        body: { name: string; start_time: Date; end_time?: Date; format_id: number };
      }) => ({
        url: `/season-week/edit/${id}`,
        method: 'Put',
        body,
      }),
      invalidatesTags: ['SingleSeasonInfo', 'SeasonWeek'],
    }),
    addTeamsToSeasonWeek: builder.mutation({
      query: ({
        weekId,
        body,
      }: {
        weekId: string | undefined;
        body: { teams: { id: number; name: string }[] };
      }) => ({
        url: `/season-week/add-teams/${weekId}`,
        method: 'Put',
        body,
      }),
      invalidatesTags: ['SingleSeasonInfo', 'SeasonWeek'],
    }),
    editSeasonStage: builder.mutation({
      query: ({
        stageId,
        body,
      }: {
        stageId: string | undefined;
        body: {
          name: string;
          stage_type_id: number;
          start_time: Date;
          end_time: Date;
          season_id: number;
          teams: { id: number }[];
        };
      }) => ({
        url: `/season-stage/edit/${stageId}`,
        method: 'Put',
        body,
      }),
      invalidatesTags: ['SingleSeasonInfo', 'SeasonWeek', 'SeasonStage'],
    }),
    getCompleteSeasonStageInfo: builder.query({
      query: (stageId) => ({
        url: `/season-stage/complete/${stageId}`,
        method: 'Get',
      }),
      providesTags: ['SingleSeasonInfo', 'SeasonWeek', 'Team', 'SeasonStage'],
      keepUnusedDataFor: 1,
    }),
    completeSeasonStage: builder.mutation({
      query: ({
        stageId,
        body,
      }: {
        stageId: string | undefined;
        body: { standings: number[] };
      }) => ({
        url: `/season-stage/complete/${stageId}`,
        method: 'Put',
        body,
      }),
      invalidatesTags: ['SingleSeasonInfo', 'SeasonWeek', 'Match'],
    }),
    deleteSeasonWeek: builder.mutation({
      query: ({ id }: { id: string | undefined }) => ({
        url: `/season-week/delete/${id}`,
        method: 'Delete',
      }),
      invalidatesTags: ['SingleSeasonInfo', 'SeasonWeek', 'Match'],
    }),
    deleteSeasonStage: builder.mutation({
      query: ({ id }: { id: string | undefined }) => ({
        url: `/season-stage/delete/${id}`,
        method: 'Delete',
      }),
      invalidatesTags: ['SingleSeasonInfo', 'SeasonWeek', 'Match'],
    }),
    getSeasonWeekById: builder.query({
      query: (id) => ({
        url: `/season-week/${id}`,
        method: 'GET',
      }),
      providesTags: ['SingleSeasonInfo', 'SeasonWeek'],
    }),
    submitScoreSeasonWeek: builder.mutation({
      query: ({ matchId, body }: { matchId: number; body: { score: number } }) => ({
        url: `/season-week/submit-score/${matchId}`,
        method: 'Put',
        body,
      }),
      invalidatesTags: ['SingleSeasonInfo', 'SeasonWeek', 'Match', 'Team'],
    }),
    closeSeasonWeekMatch: builder.mutation({
      query: (id) => ({
        url: `/season-week/close-match/${id}`,
        method: 'Put',
      }),
      invalidatesTags: ['SeasonWeek', 'Match'],
    }),
    archiveSeason: builder.mutation({
      query: (id) => ({
        url: `/season/archive/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Seasons', 'Unarchived'],
    }),
    updateSeason: builder.mutation({
      query: ({ id, body }) => ({
        url: `/season/update/${id}`,
        method: 'Put',
        body,
      }),
      invalidatesTags: ['Seasons', 'SingleSeasonInfo'],
    }),
    updateSeasonBannerImage: builder.mutation({
      query: ({ id, photo }) => ({
        url: `/season/banner-photo/${id}`,
        method: 'Put',
        body: photo,
      }),
      invalidatesTags: ['SingleSeasonInfo'],
    }),
    updateSeasonProfilePhoto: builder.mutation({
      query: ({ id, photo }) => ({
        url: `/season/profile-photo/${id}`,
        method: 'Put',
        body: photo,
      }),
      invalidatesTags: ['SingleSeasonInfo'],
    }),
    viewSingleSeason: builder.query({
      query: (id) => ({
        url: `/season/info/${id}`,
        method: 'Get',
      }),
      providesTags: ['SingleSeasonInfo', 'SeasonWeek', 'Team'],
      keepUnusedDataFor: 1,
    }),
    viewSingleSeasonStage: builder.query({
      query: (id) => ({
        url: `/season-stage/info/${id}`,
        method: 'Get',
      }),
      providesTags: ['SingleSeasonInfo', 'SeasonWeek', 'Team', 'SeasonStage'],
      keepUnusedDataFor: 1,
    }),
    getNewSRequiredData: builder.query({
      query: () => ({
        url: '/season/new',
        method: 'GET',
      }),
      providesTags: ['SingleSeasonInfo'],
    }),
    createSeason: builder.mutation({
      query: (body) => ({
        url: '/season/new',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Seasons'],
    }),
    getAllSeasons: builder.query({
      query: (query) => ({
        url: `/season/all?${query.query}=${query.param}${query.showMy ? '&showMy' : ''}&page=${
          query.page ?? 1
        }`,
        method: 'Get',
      }),
      providesTags: ['Seasons'],
    }),
    getSeasonsGroupedByGame: builder.query({
      query: (page) => ({
        url: `/game/season-group?page=${page ? page : 1}`,
        method: 'Get',
      }),
    }),
    getSeasonsGroupedByDivision: builder.query({
      query: (page) => ({
        url: `/divisions/season-group?page=${page ? page : 1}`,
        method: 'Get',
      }),
    }),
    getSeasonGroupedBySub: builder.query({
      query: (page) => ({
        url: `/subdivisions/season-group?page=${page ? page : 1}`,
        method: 'Get',
      }),
    }),
    getSeasonsByDivisionId: builder.query({
      query: (query) => ({
        url: `season/division-id/${query.id}?page=${query.page}`,
        method: 'GET',
      }),
    }),
    getSeasonsByGameId: builder.query({
      query: (query) => ({
        url: `season/game-id/${query.id}?page=${query.page}`,
        method: 'GET',
      }),
    }),
    getAllSeasonsByDateStatus: builder.query({
      query: ([query, param]) => ({
        url: `/season/by-date/${param.dateStatus}`,
        params: query,
        method: 'Get',
      }),
      providesTags: ['Seasons'],
    }),
    getLastSeasonStandingByStudentId: builder.query({
      query: (id) => ({
        url: `/season/by-student/${id}`,
        method: 'Get',
      }),
      providesTags: ['Seasons', 'SeasonStanding'],
    }),
    endSeason: builder.mutation({
      query: (id) => ({
        url: `season/end-season/${id}`,
        method: 'Put',
      }),
      invalidatesTags: ['Seasons', 'SingleSeasonInfo'],
    }),
    getTeamsNotInSeason: builder.query({
      query: ({ id, page, game, name, season_id, optionName, filterStates, seasonStates }) => ({
        url: `/season/add-season-teams/${id}`,
        method: 'GET',
        params: {
          page,
          game,
          name,
          season_id,
          optionName,
          filterStates,
          seasonStates,
          // ?page=${page ? page : 1}&name=${name ? name : ''}&season_id=${season_id ? season_id : ''}
        },
      }),
      providesTags: ['Seasons', 'Team', 'SingleSeasonInfo', 'SeasonStanding'],
    }),
    generateMatchesForWeek: builder.mutation({
      query: ({
        seasonId,
        seasonWeekId,
        generationType,
      }: {
        seasonId: number;
        seasonWeekId: number;
        generationType: number;
      }) => ({
        url: `/season/generate-matches-for-week`,
        method: 'Post',
        body: { seasonId, seasonWeekId, generationType },
      }),
    }),
  }),
});

export const {
  useUpdateSeasonTeamMutation,
  useCreateSeasonWeekMutation,
  useCreateSeasonStageMutation,
  useArchiveSeasonMutation,
  useUpdateSeasonMutation,
  useEditSeasonWeekMutation,
  useAddTeamsToSeasonWeekMutation,
  useEditSeasonStageMutation,
  useGetCompleteSeasonStageInfoQuery,
  useCompleteSeasonStageMutation,
  useDeleteSeasonWeekMutation,
  useDeleteSeasonStageMutation,
  useGetSeasonWeekByIdQuery,
  useUpdateSeasonBannerImageMutation,
  useUpdateSeasonProfilePhotoMutation,
  useViewSingleSeasonQuery,
  useViewSingleSeasonStageQuery,
  useGetNewSRequiredDataQuery,
  useCreateSeasonMutation,
  useGetAllSeasonsQuery,
  useGetSeasonsGroupedByGameQuery,
  useGetSeasonsGroupedByDivisionQuery,
  useGetSeasonGroupedBySubQuery,
  useGetSeasonsByDivisionIdQuery,
  useGetSeasonsByGameIdQuery,
  useSeasonExportListMutation,
  useGetAllSeasonsByDateStatusQuery,
  useGetLastSeasonStandingByStudentIdQuery,
  useEndSeasonMutation,
  useRemoveTeamFromSeasonMutation,
  useSubmitScoreSeasonWeekMutation,
  usePublishSeasonWeekMutation,
  useOpenSeasonWeekMutation,
  useCloseSeasonWeekMatchMutation,
  // useGetSeasonWeekByMatchIdQuery,
  useCanSubmitScoreQuery,
  useSubmitScoreToMatchMutation,
  useSubmitScoresByCsvMutation,
  useCloseSeasonWeekMutation,
  useGetTeamsNotInSeasonQuery,
  useGenerateMatchesForWeekMutation,
} = seasonApiSlice;
