import React, { useState, ChangeEvent, DragEvent, useRef, useEffect } from 'react';
import { FiTrash2 } from 'react-icons/fi';
import {
  useCreateLessonMutation,
  useGetClassroomByIdQuery,
} from '../../../../slices/classroomApiSlice';
import { Link, Navigate, useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { OutsideClick } from '../../../../components/outside-click';
import { FormInput } from '../../../../components/form-input/form-input';
import ModalHeader from '../../../../components/modal-header';
import ModalFooter from '../../../../components/modal-footer';
import { NewLessonSchema } from '../schema/new-lesson-schema';

const NewLesson = () => {
  const [avatarImage, setAvatarImage] = useState<any[]>();
  const params = useParams();

  const {
    data: reqData,
    isLoading: reqIsLoading,
    isSuccess: reqIsSuccess,
    isError: reqIsError,
  } = useGetClassroomByIdQuery(params.cId);

  const [
    createLesson,
    { isError: isLessonError, isLoading: isLessonLoading, isSuccess: isLessonSuccess },
  ] = useCreateLessonMutation();

  ////////////////////

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    console.log('selectedFiles = ', selectedFiles);
  }, [selectedFiles]);

  useEffect(() => {
    console.log('fileINputRef = ', fileInputRef);
  }, [fileInputRef]);

  function handleFileSelect(event: ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    const files = event.target.files;
    if (files) {
      const fileList = Array.from(files);
      setSelectedFiles([...selectedFiles, ...fileList]);
    }
  }

  function handleFileDrop(event: DragEvent<HTMLDivElement>) {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles) {
      const fileList = Array.from(droppedFiles);
      setSelectedFiles([...selectedFiles, ...fileList]);
    }
    // Remove the visual feedback when the user drops the files
    const target = event.target as HTMLDivElement;
    target.classList.remove('bg-gray-200');
  }

  function handleFileDelete(
    // event: any,
    name: string,
  ) {
    // event.preventDefault();
    const newFiles = selectedFiles.filter((file) => file.name !== name);
    setSelectedFiles(newFiles);
  }

  const formInputs = [
    {
      mainTitle: 'Lesson Information',
      name: 'name',
      label: 'Name',
      type: 'text',
      placeholder: 'Enter lesson name',
      isTrue: true,
    },
    {
      mainTitle: 'Lesson Information',
      name: 'description',
      label: 'Description',
      type: 'description',
      placeholder: 'Start typing...',
      isTrue: true,
    },
  ];

  function handleSubmit(values: any, actions: any) {
    const data = { ...values };
    data.classroom_id = +params.cId!;

    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append('files', file);
    });
    formData.append('name', data.name);
    formData.append('description', data.description);
    formData.append('classroom_id', data.classroom_id);

    createLesson(formData).catch((e) => console.log(e));
  }

  const initialValues: { [key: string]: string } = {};
  formInputs.forEach((input) => (initialValues[input.name] = ''));

  if (isLessonSuccess) return <Navigate to='../' />;
  const content = (
    <>
      <OutsideClick />
      <Formik
        validationSchema={NewLessonSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        className='overflow-scroll'
      >
        {({ errors }) => {
          return (
            <Form>
              <div className='fixed h-fit top-0 left-0 bottom-0 right-0 m-auto z-10 w-auto flex items-center justify-center  max-w-2xl'>
                <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-[90vh]'>
                  <ModalHeader name={'Create Lesson'} />

                  <div className='relative px-3 flex-auto overflow-auto'>
                    {formInputs.map((input: any, i: number) => (
                      <React.Fragment key={i}>
                        {!formInputs[i - 1] || formInputs[i - 1]?.mainTitle !== input.mainTitle ? (
                          <>
                            <div className='flex flex-col'>
                              <div className='mb-2'>
                                <p className='text-gray-800 font-bold pt-6'>{input.mainTitle}</p>
                                <hr />
                              </div>
                              <div className='flex flex-wrap gap-1'>
                                {formInputs
                                  .filter((inp1: any) => inp1.mainTitle === input.mainTitle)
                                  .map((inp: any, i: any) => (
                                    <React.Fragment key={i}>
                                      <div
                                        className={inp.isTrue ? 'w-full' : 'flex-1 min-w-[300px]'}
                                      >
                                        <FormInput {...inp} />
                                      </div>
                                    </React.Fragment>
                                  ))}
                                <div
                                  className='flex flex-col justify-center items-center w-full h-64 bg-gray-100 border-dashed border-2 border-gray-400'
                                  onDragOver={(event) => {
                                    event.preventDefault();
                                    event.dataTransfer.dropEffect = 'copy';
                                    // Add some visual feedback to show the user that the container can be dropped onto
                                    // For example, change the background color
                                    const target = event.target as HTMLDivElement;
                                    target.classList.add('bg-gray-200');
                                  }}
                                  onDragLeave={(event) => {
                                    event.preventDefault();
                                    // Remove the visual feedback when the user leaves the container
                                    const target = event.target as HTMLDivElement;
                                    target.classList.remove('bg-gray-200');
                                  }}
                                  onDrop={handleFileDrop}
                                >
                                  <input
                                    type='file'
                                    className='hidden'
                                    onChange={handleFileSelect}
                                    multiple
                                    ref={fileInputRef}
                                    accept='image/*,application/pdf,video/*'
                                  />
                                  <button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      fileInputRef.current?.click();
                                    }}
                                  >
                                    Select Files
                                  </button>
                                  <p className='text-gray-500'>or</p>
                                  <p className='text-gray-500'>Drag and drop here</p>
                                  <div className='mt-4 flex flex-col w-full max-h-44 overflow-y-scroll'>
                                    {selectedFiles.map((file, idx) => (
                                      <div
                                        key={idx}
                                        className='mt-2 px-4 py-2 rounded-lg bg-white flex items-center justify-between shadow-md'
                                      >
                                        <p>{file.name}</p>
                                        <button
                                          className='ml-4 text-red-500 focus:outline-none'
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handleFileDelete(file.name);
                                          }}
                                        >
                                          <FiTrash2 size={20} />
                                        </button>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </React.Fragment>
                    ))}
                  </div>

                  <ModalFooter errors={Object.keys(errors).length} isLoading={isLessonLoading!} />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
  return content;
};

export default NewLesson;
