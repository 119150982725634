import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState: any = {
  twitch_token: undefined,
  header_videos: { live: [], recent: [] },
  live_videos: [],
  recent_videos: [],
  streamer_live_videos: [],
  streamer_recent_videos: [],
};
const gamearenaSlice = createSlice({
  name: 'gamearena',
  initialState,
  reducers: {
    setTwitchToken: (state, action) => {
      state.twitch_token = action.payload;
    },
    setLiveVideos: (state, action) => {
      state.live_videos = action.payload;

      if (state.header_videos.live.length) return;
      state.header_videos.live.push(...action.payload);
    },
    addToLiveVideos: (state, action) => {
      // @ts-ignore
      state.live_videos.push(...action.payload);
    },
    setRecentVideos: (state, action) => {
      state.recent_videos = action.payload;
      if (state.header_videos.recent.length) return;
      state.header_videos.recent.push(...action.payload);
    },
    addToRecentVideos: (state, action) => {
      // @ts-ignore
      state.recent_videos.push(...action.payload);
    },
    setStreamerLiveVideos: (state, action) => {
      state.streamer_live_videos = action.payload;
    },
    addToStreamerLiveVideos: (state, action) => {
      // @ts-ignore
      state.streamer_live_videos.push(...action.payload);
    },
    setStreamerRecentVideos: (state, action) => {
      state.streamer_recent_videos = action.payload;
    },
    addToStreamerRecentVideos: (state, action) => {
      // @ts-ignore
      state.streamer_recent_videos.push(...action.payload);
    },
  },
});

export const {
  addToLiveVideos,
  addToRecentVideos,
  setStreamerLiveVideos,
  setStreamerRecentVideos,
  addToStreamerLiveVideos,
  addToStreamerRecentVideos,
  setTwitchToken,
  setLiveVideos,
  setRecentVideos,
} = gamearenaSlice.actions;
const selectSelf = (state: RootState) => state.gamearena;

export const selectTwitchToken = createSelector(selectSelf, (state: any) => state.twitch_token);

export const selectLiveVideos = createSelector(selectSelf, (state: any) => state.live_videos);

export const selectRecentVideos = createSelector(selectSelf, (state: any) => state.recent_videos);

export const selectStreamerLiveVideos = createSelector(
  selectSelf,
  (state: any) => state.streamer_live_videos,
);

export const selectStreamerRecentVideos = createSelector(
  selectSelf,
  (state: any) => state.streamer_recent_videos,
);
export const selectHeaderVideos = createSelector(selectSelf, (state: any) => state.header_videos);

export default gamearenaSlice.reducer;
