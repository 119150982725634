import * as yup from 'yup';
import { nameRulesWithSpecialCharacter, usernameRules } from '../../../../features/authentication/yupschema';
export const newGamesVSchema = yup.object().shape({
  name: yup
    .string()
    .required('Required!')
    .matches(
      usernameRules,
      'Invalid game name. Only letters, numbers, and spaces are allowed, and the name should start with a letter.',
    )
    .min(2, 'Game name must have at least 2 characters.'),
  active_limit: yup
    .number()
    .positive('Active limit must be a positive number')
    .integer()
    .required('Required!'),
  // subs_limit: yup.number().positive().integer().required('Required!'), 
  // subs_limit: yup
  //   .mixed()
  //   .required('Required!')
  //   .test('is-positive-integer', 'Substitutes limit must be a positive number', (value) =>
  //     subsLimitRules.test(value),
  //   ),
});
