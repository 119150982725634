import { apiSlice } from '../api/apiSlice';

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // login
    login: builder.mutation({
      query: (credentials) => ({
        url: '/auth/sign-in',
        method: 'POST',
        body: {
          email: credentials.email,
          password: credentials.password,
        },
      }),
    }),
    signUp: builder.mutation({
      query: (data: {
        firstName: string;
        lastName: string;
        email: string;
        password: string;
        city?: string;
        state: string;
        streetAddress: string;
        zipCode: number;
        dateOfBirth: string;
        gender?: string;
        phoneNumber: string;
        username?: string;
        tags?: string;
        occupation?: string;
        roles: number;
        status?: number;
        eligibilityStatus?: number;
        isVerified: number;
      }) => ({
        url: '/auth/sign-up',
        method: 'post',
        body: data,
      }),
    }),
    // forgot password (send email)
    forgotPassword: builder.mutation({
      query: (email) => ({
        url: '/auth/forgot',
        method: 'POST',
        body: { email },
      }),
    }),

    // reset password from email token
    resetPassword: builder.mutation({
      query: (data: { newPassword: string; confirmPassword: string; token: string }) => ({
        url: '/auth/reset',
        method: 'POST',
        body: {
          newPassword: data.newPassword,
          confirmPassword: data.confirmPassword,
        },
        headers: { authorization: data.token },
      }),
    }),

    // first time change password
    firstTime: builder.mutation({
      query: (body) => ({
        url: '/auth/first-time',
        method: 'Post',
        body,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useSignUpMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useFirstTimeMutation,
} = authApiSlice;
