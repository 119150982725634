import { UserRoles } from '../../../utils/constants';

export const seasonPermissions = {
  'season.option-container': [UserRoles.Admin],
  'season.newSeason': [UserRoles.Admin],
  'season.view.matchSchedule': [UserRoles.Admin],
  'season.view.editSeasonWeek': [UserRoles.Admin],
  'season.view.deleteSeasonWeek': [UserRoles.Admin],
  'season.view.generateWeek': [UserRoles.Admin],
  'season.view.generateStage': [UserRoles.Admin],
  'season.view.editStage': [UserRoles.Admin],
  'season.view.deleteStage': [UserRoles.Admin],
  'season.export': [UserRoles.Admin, UserRoles.Ogma],
  'season.view.editTeams': [UserRoles.Admin],
  'season.view.editSeason': [UserRoles.Admin],
  'season.view.editPhoto': [UserRoles.Admin],
  'season.view.editCover': [UserRoles.Admin],
  'season.groupedSeasonsButton': [UserRoles.Admin],
  'season.generateWeek': [UserRoles.Admin],
  'season.rearrange': [UserRoles.Admin],
  'season.closeMatch': [UserRoles.Admin],
  'season.view.ogmaEditTeams': [UserRoles.Ogma],
};
