import React from 'react';
import { useState, useEffect } from 'react';

export function LatestStudensAndSchoolsLoadingSceleton() {
  return (
    <>
      <div
        className='listViewContainer w-full flex flex-col bg-white rounded-xl p-5 border-1 gap-10 '
      >
        <div
          className='w-[300px] min-h-[30px] items-center rounded-lg bg-slate-200
                 ml-2 skeleton__pulse'
        ></div>
        <div className='h-[1px] bg-slate-200 w-full skeleton__pulse'></div>
        <div className='flex gap-20 flex-wrap ml-4'>
            <div className='flex justiy items-center '>
              <div className=' bg-slate-200 flex items-center w-16 h-16 rounded-full overflow-hidden skeleton__pulse'></div>
              <div className='w-[130px] min-h-[25px] items-center rounded-lg bg-slate-200 ml-3 skeleton__pulse'></div>
            </div>

            <div className='flex justify items-center'>
              <div className=' bg-slate-200 flex items-center w-16 h-16 rounded-full overflow-hidden skeleton__pulse'></div>
              <div className='w-[130px] min-h-[25px] items-center rounded-lg bg-slate-200 ml-3 skeleton__pulse'></div>
            </div>

            <div className='flex justify items-center'>
              <div className=' bg-slate-200 flex items-center w-16 h-16 rounded-full overflow-hidden skeleton__pulse'></div>
              <div className='w-[130px] min-h-[25px] items-center rounded-lg bg-slate-200 ml-3 skeleton__pulse'></div>
            </div>

            <div className='flex justify items-center'>
              <div className=' bg-slate-200 flex items-center w-16 h-16 rounded-full overflow-hidden skeleton__pulse'></div>
              <div className='w-[130px] min-h-[25px] items-center rounded-lg bg-slate-200 ml-3 skeleton__pulse'></div>
            </div>

            <div className='flex justify items-center'>
              <div className=' bg-slate-200 flex items-center w-16 h-16 rounded-full overflow-hidden skeleton__pulse'></div>
              <div className='w-[130px] min-h-[25px] items-center rounded-lg bg-slate-200 ml-3 skeleton__pulse'></div>
            </div>
        </div>
        <div className='h-[1px] bg-slate-200 w-full mb-5 skeleton__pulse'></div>

      </div>
    </>
  );
}
