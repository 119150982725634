
import { Link } from 'react-router-dom';
import { useStateContext } from '../../../contexts/ContextProvider';
import CoachRegisterForm from './coach-register-form';

const CoachRegister = () => {
  const { modalTerms, setModalTerms } = useStateContext();
  const { modalTermsCheckbox, setModalTermsCheckbox } = useStateContext();

  return (
    <>
      <div className='leading-loose flex items-center justify-center'>
        <CoachRegisterForm />
        {/* {modalTerms && (
          <ModalTerms setModalTerms={setModalTerms} setModalTermsCheckbox={setModalTermsCheckbox} />
        )} */}
      </div>
      <p className='flex items-center justify-center'>
        <span>
          Already have an account?{' '}
          <Link to='/login'>
            <span className='underline font-medium'> Click here to login</span>
          </Link>
        </span>{' '}
      </p>
    </>
  );
};

export default CoachRegister;
