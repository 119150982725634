import { Formik } from 'formik';
import { useStateContext } from '../../../contexts/ContextProvider';
import { Navigate } from 'react-router-dom';
import { OutsideClick } from '../../../components/outside-click';
import { useCreateSchoolMutation } from '../../../slices/schoolsApiSlice';
import { useGetAllOrganizationsQuery } from '../../../slices/organizationApiSlice';
import { NewSchoolValidationSchema } from './yup-schema/new-school-yup-schema';
import { useEffect, useState } from 'react';
import { allStates } from '../../../utils/constants';
import { number } from 'yup';
import ModalContent from '../../../components/modal-content';
import { OrganizationModel } from '../../../model/organization.model';
const NewSchoolModal = () => {
  const { setShowModalSchools } = useStateContext();
  const { data: orgData } = useGetAllOrganizationsQuery({ nolimit: 'true' });
  const [enableCoach, setEnableCoach] = useState(false);
  const [validationSchema, setValidationSchema] = useState(NewSchoolValidationSchema);
  let schema = NewSchoolValidationSchema;
  const [createSchool, { isLoading, isError, isSuccess }] = useCreateSchoolMutation();
  const onSubmit = (values: any) => {
    const data = values;
    data.organization_id = +data.organization_id;
    // data.status = +values.status;
    data.zipCode = +data.zipCode;

    if (!enableCoach) {
      data.coach_enabled = false;
      data.coach_hours = 0;
      data.coach_price = 0;
    }

    createSchool(data)
      .unwrap()
      .then((res) => console.log(res));
  };
  const formInputs = [
    {
      mainTitle: 'School Information',
      name: 'name',
      label: 'School Name',
      type: 'name',
      placeholder: 'Enter school name',
      isTrue: true,
    },
    {
      mainTitle: 'School Information',
      name: 'streetAddress',
      label: 'Street Address',
      type: 'streetAddress',
      placeholder: 'Enter street address',
    },
    {
      mainTitle: 'School Information',
      name: 'city',
      label: 'City',
      type: 'city',
      placeholder: 'Enter city',
    },
    {
      mainTitle: 'School Information',
      name: 'state',
      label: 'State',
      type: 'state',
      placeholder: 'Enter state',
      isSelect: true,
      options: allStates ? allStates : [],
    },
    {
      mainTitle: 'School Information',
      name: 'zipCode',
      label: 'Zip Code',
      type: 'number',
      placeholder: 'Enter zip code',
    },

    {
      mainTitle: 'School Information',
      name: 'phoneNumber',
      label: 'Phone Number',
      type: 'phoneNumber',
      placeholder: 'Enter phone number',
    },

    {
      mainTitle: 'School Information',
      name: 'tags',
      label: 'Tags',
      type: 'tags',
      placeholder: 'Enter tags',
      isTrue: true,
    },
    {
      mainTitle: 'School Information',
      name: 'organization_id',
      label: 'Organization',
      type: 'organization',
      placeholder: 'Enter organization',
      isTrue: true,
      isSelect: true,
      options: orgData
        ? orgData.map((o: any) => {
            const model = new OrganizationModel(o);
            return { id: model.id, name: model.getOptionName() };
          })
        : [],
    },
    {
      mainTitle: 'School Information',
      type: 'number',
      min: 1,
      name: 'price',
      label: 'Price of school',
      placeholder: 'Enter price of school',
    },
    {
      mainTitle: 'School Information',
      type: 'number',
      min: 1,
      name: 'price_per_student',
      label: 'Student price',
      placeholder: 'Enter price per student',
    },
    {
      mainTitle: 'School Information',
      type: 'checkbox',
      min: 1,
      name: 'coach_enabled',
      label: 'Enable coaching hours for school?',
      placeholder: 'Enter price per coaching hours',
      isTrue: true,
    },
    {
      mainTitle: 'School Information',
      type: 'number',
      min: 1,
      name: 'coach_hours',
      label: 'Coaching hours',
      placeholder: 'Enter amount of coaching hours for this school',
      isDisabled: !enableCoach,
    },
    {
      mainTitle: 'School Information',
      type: 'number',
      min: 1,
      name: 'coach_price',
      label: 'Coaching hours fee',
      placeholder: 'Enter price per coaching hours',
      isDisabled: !enableCoach,
    },
    // {
    //   mainTitle: 'School Information',
    //   name: 'status',
    //   label: 'Status',
    //   type: 'status',
    //   placeholder: 'status',
    //   isSelect: true,
    //   options: [
    //     { id: 0, name: 'Active' },
    //     { id: 1, name: 'Disabled' },
    //   ],
    // },
  ];

  useEffect(() => {
    if (enableCoach) {
      setValidationSchema(
        validationSchema.shape({
          coach_price: number().required('Required!'),
          coach_hours: number().required('Required!'),
        }),
      );
    } else {
      setValidationSchema(NewSchoolValidationSchema);
    }
  }, [enableCoach]);

  const initialValues: { [key: string]: string } = {};
  formInputs.forEach((input) => (initialValues[input.name] = ''));

  if (isSuccess) return <Navigate to='../' />;
  return (
    <>
      <OutsideClick />
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ values, errors }) => {
          useEffect(() => {
            if (!values) return;
            if (values.coach_enabled) {
              setEnableCoach(true);
            } else {
              setEnableCoach(false);
            }
          }, [values]);
          return (
            <ModalContent
              errors={Object.keys(errors).length}
              formInputs={formInputs}
              isLoading={isLoading}
              name='New School'
            />
          );
        }}
      </Formik>
    </>
  );
};

export default NewSchoolModal;
