import { dateAndTimeFormatter, dateFormatter } from '../utils/dateFormatter';
import { LessonModel } from './lesson.model';

export class ClassroomModel {
  id;
  name: string = '';
  coach: string = '';
  description: string = '';
  createdAt: string = '';
  banner: string = '';
  profile_image: string = '';
  game_name?: string;
  lessons: LessonModel[] = [];
  constructor(data: any) {
    if (data.hasOwnProperty('Lesson')) {
      data.Lesson.forEach((l: any) => this.lessons.push(new LessonModel(l)));
    }
    if (data.hasOwnProperty('id')) this.id = data.id;
    if (data.hasOwnProperty('name')) this.name = data.name;
    if (data.hasOwnProperty('banner')) this.banner = data.banner;
    if (data.hasOwnProperty('profile_image')) this.profile_image = data.profile_image;
    if (data.hasOwnProperty('objective_descriptions'))
      this.description = data.objective_descriptions;
    if (data.hasOwnProperty('createdAt'))
      this.createdAt = dateAndTimeFormatter(data.createdAt).split(';')[0];

    if (data.hasOwnProperty('User')) {
      if (data.User?.hasOwnProperty('firstName') && data.User?.hasOwnProperty('lastName')) {
        this.coach = `${data.User.firstName} ${data.User.lastName}`;
      }
    }
    if (data.hasOwnProperty('Game')) {
      if (data?.Game?.hasOwnProperty('name')) {
        this.game_name = data?.Game?.name;
      }
    }
  }
}
