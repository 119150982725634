import React from 'react';
import { AvatarImage } from '../../../components/avatar-image';
import { UserModel } from '../../../features/listview/users/model/user.model';
export function OgmaProfileHeader({ model }: { model: UserModel }) {
  return (
    <div className='flex gap-5 p-5 rounded-lg border-1 w-fit items-center'>
      <AvatarImage size='xxl' source={model.image ?? ''} />
      <div className='flex flex-col'>
        <h3 className='font-semibold'>{model.fullName}</h3>
        <p>{model?.organization}</p>
      </div>
    </div>
  );
}
