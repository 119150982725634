export function LoadingSceletonMyAccount() {
  return (
    <div>
      <div>
        <div className=' flex flex-wrap p-6 justify-between '>
          <div className='h-[50px] w-[250px] bg-slate-200 rounded-lg skeleton__pulse'></div>
        </div>
        <div className=' flex flex-wrap p-6  '>
          <div className='h-[100px] w-[100px] bg-slate-200 rounded-full skeleton__pulse'></div>

          <div className='flex flex-wrap p-6'>
            <div className='h-[45px] w-[150px] bg-slate-200 rounded-lg skeleton__pulse'></div>
          </div>
        </div>

        <div className='flex flex-wrap p-10 justify-between gap-7'>
          <div className='h-[60px] w-[300px] bg-slate-200 rounded-lg skeleton__pulse'></div>
          <div className='h-[60px] w-[300px] bg-slate-200 rounded-lg skeleton__pulse'></div>
          <div className='h-[60px] w-[300px] bg-slate-200 rounded-lg skeleton__pulse'></div>
          <div className='h-[60px] w-[300px] bg-slate-200 rounded-lg skeleton__pulse'></div>
          <div className='h-[60px] w-[300px] bg-slate-200 rounded-lg skeleton__pulse'></div>
          <div className='h-[60px] w-[300px] bg-slate-200 rounded-lg skeleton__pulse'></div>
          <div className='h-[60px] w-[300px] bg-slate-200 rounded-lg skeleton__pulse'></div>
          <div className='h-[60px] w-[300px] bg-slate-200 rounded-lg skeleton__pulse'></div>
        </div>
      </div>
    </div>
  );
}
