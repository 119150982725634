import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import { OutsideClick } from '../../../components/outside-click';
import { useGetInvoiceByIdQuery } from '../../../slices/invoicesApiSlice';
import { InvoiceStatus } from '../../../utils/constants';
import { StudentSubscriptionCardModel } from '../../../model/student-subscription-card-model';
import { CustomError } from '../../../utils/custom-types';

const ManageStudentSubscription = ({ isGuardian }: { isGuardian?: Boolean }) => {
  const param = useParams();
  const id = param.subscriptionId ?? param.id;
  const { error, data, isLoading, isError } = useGetInvoiceByIdQuery(id);
  const [model, setModel] = useState<StudentSubscriptionCardModel & { [key: string]: any }>();
  useEffect(() => {
    if (!data) return;
    setModel(new StudentSubscriptionCardModel(data));
  }, [data]);
  const formInputs = [
    {
      mainTitle: 'Billing Information',
      name: 'firstName',
      label: 'First Name',
      type: 'text',
      placeholder: 'First Name',
      // isTrue: true,
    },
    {
      mainTitle: 'Billing Information',
      name: 'lastName',
      label: 'Last Name',
      type: 'text',
      placeholder: 'Last Name',
      // isTrue: true,
    },
    {
      mainTitle: 'Billing Information',
      name: 'street',
      label: 'Street Address',
      type: 'text',
      placeholder: 'Street Address',
      // isTrue: true,
    },
    {
      mainTitle: 'Billing Information',
      name: 'city',
      label: 'City',
      type: 'text',
      placeholder: 'City',
      // isTrue: true,
    },
    {
      mainTitle: 'Billing Information',
      name: 'state',
      label: 'State / Province',
      type: 'text',
      placeholder: 'City',
      // isTrue: true,
    },
    {
      mainTitle: 'Billing Information',
      name: 'postal',
      label: 'Postal / ZIP Code',
      type: 'number',
      placeholder: 'Postal / ZIP Code',
      // isTrue: true,
    },
    {
      mainTitle: 'Billing Information',
      name: 'email',
      label: 'Email Address',
      type: 'text',
      placeholder: 'Email Address',
      isDisabled: true,
      isTrue: true,
    },
  ];
  const infoRows = [
    { title: 'ID', prop: 'id' },
    { title: 'Season', prop: 'season' },
    { title: 'Invoice Status', prop: 'status_name' },
    { title: 'Total', prop: 'total' },
  ];
  if (isLoading || !model) return <p>Loading...</p>;
  if (isError)
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError).data.message }} />;
  return (
    <>
      <OutsideClick />
      <Formik
        // validationSchema={EditSeasonVSchema}
        initialValues={{}}
        onSubmit={() => console.log('first')}
      >
        {() => {
          return (
            <Form>
              <div className='fixed pb-20 top-0 left-0 right-0 z-10 w-auto my-6 mx-auto max-w-3xl h-[100vh] pointer-events-none '>
                <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full  bg-white outline-none focus:outline-none overflow-auto pointer-events-auto p-8 h-fit'>
                  <h2 className='font-bold text-2xl text-black'>Manage Subscription</h2>
                  <SubTitle title='Subscription Information' />
                  <div className='flex flex-col '>
                    {infoRows.map((row) => (
                      <InfoRow key={row.title} title={row.title} data={model[row.prop]} />
                    ))}
                    <div className='flex items-center justify-end gap-8'>
                      <Link
                        className='w-1/5 my-12 text-center py-1 text-base bg-white text-black shadow-lg font-semibold rounded-lg'
                        to='../'
                      >
                        Cancel
                      </Link>
                      {(model.status === InvoiceStatus.Unpaid ||
                        model.status === InvoiceStatus.Upcoming) && (
                          <Link
                            className='bg-red-700 text-white w-1/5 text-base font-semibold text-center py-1 rounded-lg'
                            to={
                              isGuardian
                                ? `/subscriptions/guardian/pay/${model.id}`
                                : `/subscriptions/student/pay/${model.id}`
                            }
                          >
                            Go to payment
                          </Link>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default ManageStudentSubscription;

function InfoRow({ title, data }: { title: string; data: string }) {
  return (
    <div className='flex border-b-2 p-2 px-6 text-sm'>
      <p className='w-[50%]'>{title}</p>
      <p className='w-[50%]'>{data}</p>
    </div>
  );
}

function SubTitle({ title }: { title: string }) {
  return <h3 className='font-bold text-xl p-2 px-4'>{title}</h3>;
}
// <div className="flex justify-between items-center">
//                   <SubTitle title="Billing Information" />
//                   {!editMode && (
//                     <p
//                       onClick={() => setEditMode(true)}
//                       className="underline text-xs font-bold cursor-pointer"
//                     >
//                       Edit billing information
//                     </p>
//                   )}
//                 </div>
// {editMode ? (
//               <>
//                 {formInputs.map((input: any, i: number) => (
//                   <React.Fragment key={i}>
//                     {!formInputs[i - 1] ||
//                     formInputs[i - 1]?.mainTitle !== input.mainTitle ? (
//                       <>
//                         <div className="flex flex-col">
//                           {/* <div className="mb-2">
//                           <p className="text-gray-800 font-bold pt-6">
//                             {input.mainTitle}
//                           </p>
//                           <hr />
//                         </div> */}
//                           <div className="flex flex-wrap gap-1">
//                             {formInputs
//                               .filter(
//                                 (inp1: any) =>
//                                   inp1.mainTitle === input.mainTitle
//                               )
//                               .map((inp: any, i: any) => (
//                                 <React.Fragment key={i}>
//                                   <div
//                                     className={
//                                       inp.isTrue
//                                         ? "w-full "
//                                         : "flex-1 min-w-[300px]"
//                                     }
//                                   >
//                                     {inp.type !== "image" ? (
//                                       <FormInput {...inp} />
//                                     ) : (
//                                       <FormInput
//                                         // setFieldValue={setAvatarImage}
//                                         {...inp}
//                                       />
//                                     )}
//                                   </div>
//                                 </React.Fragment>
//                               ))}
//                           </div>
//                         </div>
//                       </>
//                     ) : (
//                       <></>
//                     )}
//                   </React.Fragment>
//                 ))}
//                 <div className="flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b sticky bottom-0 bg-white">
//                   <div
//                     className="w-1/5 my-12 text-center py-1 bg-white text-black shadow-lg font-semibold rounded-lg cursor-pointer"
//                     onClick={() => setEditMode(false)}
//                   >
//                     Cancel
//                   </div>
//                   <SubmitButton title="Save Information" />
//                 </div>
//               </>
//             ) : (
//               <>
//                 <div className="flex flex-col ">
//                   <InfoRow
//                     title="Subscription"
//                     data="Fenworks Platform (Student)"
//                   />
//                   <InfoRow
//                     title="Subscription"
//                     data="Fenworks Platform (Student)"
//                   />
//                   <InfoRow
//                     title="Subscription"
//                     data="Fenworks Platform (Student)"
//                   />
//                   <InfoRow
//                     title="Subscription"
//                     data="Fenworks Platform (Student)"
//                   />
//                   <InfoRow
//                     title="Subscription"
//                     data="Fenworks Platform (Student)"
//                   />
//                   <InfoRow
//                     title="Subscription"
//                     data="Fenworks Platform (Student)"
//                   />
//                 </div>
//                 <div className="flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b sticky bottom-0 bg-white">
//                   <Link
//                     className="w-1/5 my-12 text-center py-1 bg-white text-black shadow-lg font-semibold rounded-lg"
//                     to="../"
//                   >
//                     Cancel
//                   </Link>
//                   <SubmitButton title="Pay" />
//                 </div>
//               </>
//             )}
