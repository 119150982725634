import { useEffect } from 'react';

interface useCloseAreYouSureProps {
  deleteIsSuccess?: boolean;
  archiveIsSuccess?: boolean;
  deleteIsError?: boolean;
  archiveIsError?: boolean;
  setChecked?: React.Dispatch<React.SetStateAction<number[]>>;
  setDeleteDialogOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setArchiveDialogOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const useCloseAreYouSure = ({
  archiveIsError,
  archiveIsSuccess,
  deleteIsError,
  deleteIsSuccess,
  setArchiveDialogOpen,
  setChecked,
  setDeleteDialogOpen,
}: useCloseAreYouSureProps) => {
  useEffect(() => {
    if (deleteIsSuccess || archiveIsError || deleteIsError || archiveIsSuccess) {
      if (setDeleteDialogOpen) setDeleteDialogOpen(false);
      if (setArchiveDialogOpen) setArchiveDialogOpen(false);
      if (setChecked) setChecked([]);
    }
  }, [deleteIsSuccess, archiveIsSuccess, deleteIsError, archiveIsError]);

  return [];
};

export default useCloseAreYouSure;
