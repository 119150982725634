import { useState } from 'react';
import { Separator } from '../../components/ui/separator/Separator';
import { DraftEditor } from '../../components/draft-editor/draft-editor';
import {
  useCreateTermsAndConditionsMutation,
  useGetLatestTermsAndConditionsQuery,
} from '../../slices/termsApiSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../slices/auth';

const TermsAndConditionsRoute = () => {
  const [saved, setSaved] = useState<string>();
  const dispatch = useDispatch();
  const [createTerm, { isLoading: isLoad }] = useCreateTermsAndConditionsMutation();
  const user = useSelector(selectUser);
  function handleSave(text: string) {
    console.log(text);
    setSaved(text);

    createTerm({
      content: text,
    });
    // .unwrap().then((res)=>{
    //   if(user.roles !== 0)
    //   dispatch(changeTerms(false))
    // }
    // );
  }

  const { data, isLoading } = useGetLatestTermsAndConditionsQuery('');

  if (isLoading) return <p>Loading</p>;
  return (
    <div className='listViewContainer fullPage border'>
      <div className='flex w-full justify-between'>
        <div className='flex items-center justify-between'>
          <div className='space-y-1'>
            <h2 className='text-2xl font-semibold tracking-tight'>Terms Of Services</h2>
          </div>
        </div>
      </div>
      <div>
        <Separator className='my-4' />
      </div>

      <DraftEditor handleSave={handleSave} text={data?.content} />
    </div>
  );
};

export default TermsAndConditionsRoute;
