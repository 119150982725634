import * as yup from 'yup';
import {
  nameRulesHint,
  zipCodeRules,
  zipCodeRulesHint,
  usPhoneRules,
  usPhoneRulesHint,
  passwordRules,
  passwordRulesHint,
  usernameRules,
  usernameRulesHint,
  cityRules,
  nameRulesWithSpecialCharacter
} from '../../yupschema';
import { ErrorMessages } from '../../../../utils/messages.enum';

export const acceptInviteCoachSchema = yup.object().shape({
  // Role: yup.string().required("Required"),
  username: yup.string().matches(usernameRules, usernameRulesHint).required('Required!'),
  firstName: yup.string().matches(nameRulesWithSpecialCharacter, nameRulesHint).required('Required!'),
  lastName: yup.string().matches(nameRulesWithSpecialCharacter, nameRulesHint).required('Required!'),
  // streetAddress: yup.string().required('Required!'),
  city: yup.string().required('Required!').matches(cityRules, ErrorMessages.CityValidation),
  state: yup.string().required('Required!'),
  zipCode: yup.string().matches(zipCodeRules, zipCodeRulesHint).required('Required!'),
  gender: yup.string().required('Required'),
  // dateOfBirth: yup
  //   .date()
  //   .max(moment().endOf('day').subtract(18, 'years'), 'You must be at least 18 years old!')
  //   .required('Required!'),

  // yearsOfExperience: yup.string().required('Required'),
  // tags: yup.string(),
  // email: yup.string().email('Please enter a valid email').required('Required'),
  phoneNumber: yup.string().matches(usPhoneRules, usPhoneRulesHint).required('Required!'),
  check: yup.bool().oneOf([true], 'Required!'),
  password: yup
    .string()
    .min(5)
    .matches(passwordRules, { message: passwordRulesHint })
    .required('Required!'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match!')
    .required('Required!'),
  // status: yup.string().required('Required'),
});
