const OrganizationOverview = () => {
  return (
    <>
      <div className='grid grid-rows-3 grid-flow-col gap-4 p-10'>
        <div className='row-span-3 col-span-2'>
          <h1 className='text-xl font-bold'>Activities</h1>
          <div className='bg-white h-[200px] rounded'></div>
        </div>
        <div className=''>
          <h1 className='text-xl font-bold'>Social Media</h1>
          <div className='bg-white h-[50px] rounded'></div>
        </div>
        <div className=''>
          <h1 className='text-xl font-bold'>Schools</h1>
          <div className='relative bg-white h-[50px] rounded'>
            <h1 className='pl-2 pt-2'>School 1</h1>
            <br />

            <button className='mr-2 mb-2 px-4 py-1 bg-blue-500 absolute bottom-0 right-0 text-white'>
              View all
            </button>
          </div>
        </div>
        <div className=''>
          <h1 className='text-xl font-bold'>Teams</h1>
          <div className='relative bg-white h-[50px] rounded'>
            <h1 className='pl-2 pt-2'>Team 1</h1>
            <br />
            <button className='mr-2 mb-2 px-4 py-1 bg-blue-500 absolute bottom-0 right-0 text-white'>
              View all
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrganizationOverview;
