import React, { useState } from 'react';
import { UnArchiveOptionCard } from './unarchive-option-card';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../slices/auth';
import { UserRoles } from '../../../utils/constants';

const UnArchiveOptionContainer = () => {
  const user = useSelector(selectUser);
  const archiveOptions = [
    {
      id: 1,
      option: 'Users',
      // description:'Unarchive users',
      to: './user',
    },
    {
      id: 2,
      option: 'Organizations',
      // description:'Unarchive organizations',
      to: './organization',
    },
    {
      id: 3,
      option: 'Schools',
      // description:'Unarchive schools',
      to: './school',
    },
    {
      id: 4,
      option: 'Teams',
      // description:'Unarchive teams',
      to: './team',
    },
    {
      id: 5,
      option: 'Tournaments',
      // description:'Unarchive tournaments',
      to: './tournament',
    },
    {
      id: 6,
      option: 'Seasons',
      // description:'Unarchive seasons',
      to: './season',
    },
    {
      id: 7,
      option: 'Category Divisions',
      // description:'Unarchive category divisions',
      to: './division',
    },
    {
      id: 8,
      option: 'Subdivisions',
      // description:'Unarchive sub divisions',
      to: './subdivision',
    },
    {
      id: 9,
      option: 'Sponsors',
      // description:'Unarchive sponsors',
      to: './sponsors',
    },
    // test
    // {
    //     id: 10,
    //     option: 'Classroom',
    //     // description:'Unarchive sponsors',
    //     to: './classroom',
    // },
    {
      id: 11,
      option: 'Categories',
      // description:'Unarchive sponsors',
      to: './category',
    },
    {
      id: 12,
      option: 'Subcategories',
      // description:'Unarchive sponsors',
      to: './subcategory',
    },
    {
      id: 13,
      option: 'Games',
      // description:'Unarchive Games',
      to: './game',
    },
  ];
  const [isActive, setIsActive] = useState(1);

  return (
    <div className='flex min-h-fit gap-5 items-center lg:items-start p-5 overflow-x-auto overflow-y-hidden flex-row bg-white'>
      {user.roles === UserRoles.Coach ? (
        <UnArchiveOptionCard isActive={true} option='Classroom' to='./classroom' />
      ) : (
        archiveOptions.map((t) => (
          <UnArchiveOptionCard isActive={t.id === isActive} key={t.option} {...t} />
        ))
      )}
    </div>
  );
};

export default UnArchiveOptionContainer;
