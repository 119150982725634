import React from 'react';
import { GaMdGames } from './ga-md-games';
import { GaMdMatchInfo } from './ga-md-match-info';
import { ViewMatchDetails } from '../../../../match/components/view-match-details';
import { MatchMemberRow } from '../../../../match/components/match-member-row';
import { MatchMemberRowAway } from '../../../../match/components/match-member-row-away';
import { useSelector } from 'react-redux';
import { selectMatchData } from '../../../../../slices/matchSlice';
import { firstBy } from 'thenby';
export function GaMatchDetails({}) {
  const matchData = useSelector(selectMatchData);

  return (
    <div className='flex gap-4'>
      <div className='flex flex-col justify-center p-8 w-full second-gr-bg-color rounded-xl gap-2'>
        <h2 className='font-bold'>Match Details</h2>
        <GaMdMatchInfo />

        <div className='flex flex-col gap-2  rounded-xl main-gr-bg-color p-8 items-center'>
          <ViewMatchDetails {...matchData} bgColor='bg-opacity-40 rounded-xl max-w-fit' />
          <div className='flex gap-10 w-full flex-wrap max-w-3xl justify-between'>
            <div className='flex flex-col gap-2'>
              {matchData.home_team?.length &&
                JSON.parse(JSON.stringify(matchData.home_team[0].User))
                  //@ts-ignore
                  .sort(
                    firstBy(
                      (a: any, b: any) =>
                        // @ts-ignore
                        (b.id === matchData.home_team[0].captain) -
                        // @ts-ignore
                        (a.id === matchData.home_team[0].captain),
                    ).thenBy((a: any, b: any) => {
                      const aIndex = matchData.home_team_active.findIndex((ht: any) => ht === a.id);
                      const bIndex = matchData.home_team_active.findIndex((ht: any) => ht === b.id);
                      return bIndex - aIndex;
                    }),
                  )
                  .map((u: any, i: number) => (
                    <React.Fragment key={i}>
                      {matchData.home_team_active.includes(u.id) ? (
                        <MatchMemberRow
                          // userList={}
                          // type="activeMember"
                          isCaptain={u.id === matchData.home_team[0].captain}
                          id={u.id}
                          gameId={matchData.home_team[0].game_id}
                          InGameName={u.InGameName.filter(
                            (u: any) => u.game_id === matchData.home_team?.[0]?.game_id,
                          )}
                          editMode={true}
                          key={i}
                          username={u.username}
                          // captain={true}
                          // {...u}
                        />
                      ) : null}
                    </React.Fragment>
                  ))}
            </div>
            <div className='flex flex-col gap-2'>
              {matchData.away_team?.length &&
                JSON.parse(JSON.stringify(matchData.away_team[0].User))
                  //@ts-ignore
                  .sort(
                    firstBy(
                      (a: any, b: any) =>
                        // @ts-ignore
                        (b.id === matchData.home_team[0].captain) -
                        // @ts-ignore
                        (a.id === matchData.home_team[0].captain),
                    ).thenBy((a: any, b: any) => {
                      const aIndex = matchData.home_team_active.findIndex((ht: any) => ht === a.id);
                      const bIndex = matchData.home_team_active.findIndex((ht: any) => ht === b.id);
                      return bIndex - aIndex;
                    }),
                  )
                  .map((u: any, i: number) => (
                    <React.Fragment key={i}>
                      {matchData.away_team_active.includes(u.id) ? (
                        <MatchMemberRowAway
                          // userList={}
                          // type="activeMember"
                          isCaptain={u.id === matchData.away_team[0].captain}
                          id={u.id}
                          gameId={matchData.away_team[0].game_id}
                          InGameName={u.InGameName.filter(
                            (u: any) => u.game_id === matchData.away_team?.[0]?.game_id,
                          )}
                          editMode={true}
                          username={u.username}
                          // captain={true}
                          // {...u}
                        />
                      ) : // < MatchMemberRow
                      //   // userList={}
                      //   // type="activeMember"
                      //   isCaptain={u.id === matchData.home_team[0].captian}
                      //   editMode={true}
                      //   key={i}
                      //   username={u.username}
                      // // captain={true}
                      // // {...u}
                      // />
                      null}
                    </React.Fragment>
                  ))}
            </div>
          </div>
        </div>
        <GaMdGames />
      </div>
      {/* <div className='second-gr-bg-color rounded-xl w-1/3'>
        <GaEventInfo
          tournamentId={matchData.tournament_id}
        // seasonsId={matchData.SeasonWeek.Season.id}
        />
      </div> */}
    </div>
  );
}
