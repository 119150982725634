import React from 'react'
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../slices/auth';
import { Link, Outlet, useSearchParams } from 'react-router-dom';
import { useGetUnarchivedQuery } from '../../../../slices/unarchiveApiSlice';
import TableContainer from '../../../../components/table-container/table-container';
import { TableHead } from '../../../../components/table-head';
import { UserRoles } from '../../../../utils/constants';
import TableTd from '../../../../components/table-td/table-td';
import Pagination from '../../../../components/pagination';
import { LoadingSceletonArchived } from '../../../tournaments/routes/all/loading-sceleton-archived';

const UnarchiveClassroom = () => {
    const user = useSelector(selectUser);
    const [searchParams, setSearchParams] = useSearchParams();
    const { data, isLoading } = useGetUnarchivedQuery({
        type: 'classroom',
        page: searchParams.get('page') ?? undefined,
    });
    const listView = [
        {
            name: 'name',
            active: true,
            prop: 'name',
        },
        {
            name: 'Coach',
            active: true,
            prop: 'coachName',
        },
        {
            name: 'Game',
            active: true,
            prop: 'game',
        }
    ];

    if (isLoading) return <LoadingSceletonArchived/>;
    return (
        <div className='listViewContainer w-full border flex-1'>
            <div className='flex flex-col flex-1 justify-between'>
                <TableContainer>
                    <TableHead
                        oneMore={user.roles === UserRoles.Admin}
                        tableList={listView
                            .map((l) => l.name)
                            .filter(
                                (t) => listView.find((d) => d.name.toLowerCase() === t.toLowerCase())?.active,
                            )}
                    />
                    <tbody className='bg-white divide-y divide-gray-200'>
                        {data?.data?.map((d: any, i: number) => (
                            <tr key={i}>
                                {listView
                                    .filter((l) => l.active)
                                    .map((o, i) => {
                                        if (o.prop === 'coachName')
                                            return (<TableTd key={i} extraClass='max-w-[15ch] text-sm text-gray-500 truncate'>
                                                {d.User.firstName} {d.User.lastName}
                                            </TableTd>)
                                        if (o.prop === 'game')
                                            return (<TableTd key={i} extraClass='max-w-[15ch] text-sm text-gray-500 truncate'>
                                                {d.Game.name}
                                            </TableTd>)
                                        return (<TableTd key={i} extraClass='max-w-[15ch] text-sm text-gray-500 truncate'>
                                            {d[o.prop]}
                                        </TableTd>)
                                    }
                                    )}
                                <TableTd>
                                    <Link to={`./unarchive/${d?.id}/${d?.name}`}>
                                        <button className='btn red '>Unarchive</button>
                                    </Link>
                                </TableTd>
                            </tr>
                        ))}
                    </tbody>
                </TableContainer>
                <Outlet />

                <Pagination length={data?.data?.length} {...data?.meta} />
            </div>
        </div>
    );
}

export default UnarchiveClassroom