import { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import {
  useGetNewInvoiceHelpDataQuery,
  useGetStudentInvoiceByIdQuery,
  useUpdateStudentInvoiceMutation,
} from '../../../../../../slices/invoicesApiSlice';
import { OutsideClick } from '../../../../../../components/outside-click';
import { Formik } from 'formik';
import { InvoiceStatus, InvoiceStatusNames } from '../../../../../../utils/constants';
import { EditStudentInvoiceSchema } from '../new/schema/edit-student-invoice-v-schema';
import ModalContent from '../../../../../../components/modal-content';
import { CustomError } from '../../../../../../utils/custom-types';
import { addCurrentTimeToDate, dateToLocal } from '../../../../../../utils/dateFormatter';

const EditStudentInvoice = () => {
  const [chooseUser, setChooseUser] = useState<number>();

  const param = useParams();
  const { data: iHelpData, isLoading: isDataLoading } = useGetNewInvoiceHelpDataQuery('');

  const {
    error,
    data: invoiceData,
    isLoading: invoiceIsLoading,
    isError: invoiceIsError,
    isSuccess: invoiceIsSuccess,
  } = useGetStudentInvoiceByIdQuery(param.id);

  const [updateInvoice, { isLoading, isSuccess, isError }] = useUpdateStudentInvoiceMutation();

  const formInputs = [
    // {
    //   mainTitle: "Billing Information",
    //   name: "type",
    //   label: "Invoice Type",
    //   type: "number",
    //   placeholder: "Select subscription plan",
    // },
    // {
    //   mainTitle: 'Invoice Information',
    //   name: 'school',
    //   label: 'School',
    //   type: 'text',
    //   placeholder: "Select organization's name",
    //   isSelect: true,
    //   options: iHelpData?.data?.school ? iHelpData?.data?.school : [],
    // },
    // {
    //   mainTitle: 'Invoice Information',
    //   name: 'user',
    //   label: 'Student',
    //   type: 'text',
    //   placeholder: 'Select a Student',
    //   isSelect: true,
    //   options:
    //     chooseUser && iHelpData?.data?.school.filter((o: any) => o.id === chooseUser)[0].User
    //       ? iHelpData?.data?.school.filter((o: any) => o.id === chooseUser)[0].User
    //       : [],
    // },
    // {
    //   mainTitle: "Invoice Information",
    //   name: "description",
    //   label: "Description",
    //   type: "textarea",
    //   placeholder: "Enter description",
    // },
    // {
    //   mainTitle: 'Invoice Information',
    //   name: 'season',
    //   label: 'Season',
    //   type: 'text',
    //   placeholder: 'Select a Season',
    //   isSelect: true,
    //   options: iHelpData?.data?.seasons ? iHelpData?.data?.seasons : [],
    // },
    {
      mainTitle: 'Invoice Information',
      name: 'start_date',
      label: 'Invoice start date',
      type: 'date',
      placeholder: 'Enter start date',
      isTrue: true,
    },
    {
      mainTitle: 'Invoice Information',
      name: 'end_date',
      label: 'Invoice end date',
      type: 'date',
      placeholder: 'Enter end date',
      isTrue: true,
    },
    {
      mainTitle: 'Invoice Information',
      name: 'total',
      label: 'Total',
      type: 'number',
      placeholder: '$1000',
      isTrue: true,
    },
    {
      mainTitle: 'Invoice Information',
      name: 'status',
      label: 'Invoice status',
      type: 'number',
      placeholder: 'Enter the status of invoice',
      isTrue: true,
      isSelect: true,
      options: [
        { id: InvoiceStatus.Upcoming, name: InvoiceStatusNames[InvoiceStatus.Upcoming] },
        { id: InvoiceStatus.Paid, name: InvoiceStatusNames[InvoiceStatus.Paid] },
        { id: InvoiceStatus.Overdue, name: InvoiceStatusNames[InvoiceStatus.Overdue] },
        { id: InvoiceStatus.Unpaid, name: InvoiceStatusNames[InvoiceStatus.Unpaid] },
        { id: InvoiceStatus.Processing, name: InvoiceStatusNames[InvoiceStatus.Processing] },
      ],
    },

    // {
    //   mainTitle: 'Invoice Information',
    //   name: 'type',
    //   label: 'Invoice type',
    //   type: 'number',
    //   placeholder: 'Check',
    //   isTrue: true,
    // },
  ];
  useEffect(() => {
    if (!invoiceData) return;
  }, [invoiceData]);

  const onSubmit = (values: any) => {
    // console.log('values',values)
    // return;
    // const data={ ...values}

    const data = { ...values };
    data.season = +data.season;
    data.user = +data.user;
    data.season = +data.season;
    data.status = +data.status;
    // data.start_date = new Date(data.start_date).toISOString();
    // data.end_date = new Date(data.end_date).toISOString();
    data.start_date = addCurrentTimeToDate(data.start_date);
    data.end_date = addCurrentTimeToDate(data.end_date);
    delete data.id;

    updateInvoice({ id: param.id, body: data })
      .unwrap()
      .then((res: any) => {})
      .catch((error) => console.log(error));
  };

  const initialValues: { [key: string]: string } = {};
  formInputs.forEach((input) => (initialValues[input.name] = ''));
  if (invoiceIsError || (invoiceIsSuccess && !invoiceData))
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError).data.message }} />;
  if (invoiceIsLoading || isLoading || isDataLoading) return <p>Loading ...</p>;
  if (isSuccess) return <Navigate to='../' />;
  return (
    <>
      <OutsideClick />
      <Formik
        validationSchema={EditStudentInvoiceSchema}
        initialValues={{
          ...invoiceData,
          start_date: invoiceData?.start_date
            ? dateToLocal(invoiceData?.start_date)?.split('T')?.[0]
            : undefined,
          end_date: invoiceData?.end_date
            ? dateToLocal(invoiceData?.end_date)?.split('T')?.[0]
            : undefined,
          season: invoiceData?.season?.id?.toString(),
          school: invoiceData?.user?.school?.id?.toString(),
          user: invoiceData?.user?.id?.toString(),
        }}
        onSubmit={onSubmit}
      >
        {(formik) => {
          useEffect(() => {
            setChooseUser(+formik.values.school);
          }, []);

          return (
            <ModalContent
              formInputs={formInputs}
              isLoading={isLoading}
              extraClass='h-fit'
              name='Edit Invoice'
            />
          );
        }}
      </Formik>
    </>
  );
};

export default EditStudentInvoice;
