import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { OutsideClick } from '../../../components/outside-click';
import ModalContent from '../../../components/modal-content';
import ModalFooter from '../../../components/modal-footer';
import { IoRemoveCircleOutline } from 'react-icons/io5';
import { AvatarImage } from '../../../components/avatar-image';
import { useGetTeamsBySeasonIdQuery } from '../../../slices/teamsApiSlice';
import { TeamModel } from '../../../model/team.model';
import { useRemoveTeamFromSeasonMutation } from '../../../slices/seasonApiSlice';

const RemoveTeamsFromSeason = () => {
  const [selectedTeam, setSelectedTeam] = useState<{ id: number; name: string } | undefined>();
  const [deleteTeam, mutationState] = useRemoveTeamFromSeasonMutation();
  const {
    isSuccess: removeSuccess,
    isError: removeError,
    isLoading: removeLoading,
  } = mutationState;
  const param = useParams();
  const { data, isLoading, isError } = useGetTeamsBySeasonIdQuery(param?.id ?? '', {
    skip: !param.id,
  });
  function openTeamDialog(id: number, name: string) {
    setSelectedTeam({ id, name });
  }
  useEffect(() => {
    if (!removeSuccess) return;
    setSelectedTeam(undefined);
  }, [removeSuccess]);
  if (isLoading) return <p>Loading...</p>;
  if (isError) return <p>Something went wrong! Try again later!</p>;
  return (
    <div className='flex flex-col flex-1 min-h-[1px]'>
      {selectedTeam ? (
        <RemoveTeamDialog
          {...selectedTeam}
          {...mutationState}
          removeTeam={deleteTeam}
          season_id={Number(param.id)}
          cancel={() => setSelectedTeam(undefined)}
        />
      ) : (
        <></>
      )}
      <div className='flex flex-1 flex-col '>
        {removeError && <p className='text-red-700'>Something went wrong! Please try again!</p>}
        {removeSuccess && (
          <p className='text-green-700'>Team was removed successfully from this season!</p>
        )}
        <div className='flex flex-wrap gap-2 p-4'>
          {data.map((d: any) => {
            const model = new TeamModel(d);
            return (
              <div
                key={model.id}
                className='relative flex flex-col p-10 rounded-lg shadow-lg items-center gap-10 w-[200px] h-fit overflow-hidden'
              >
                <IoRemoveCircleOutline
                  onClick={() => openTeamDialog(model.id, model.name ?? '')}
                  className='absolute top-1 right-1 hover:text-red-700 cursor-pointer'
                />

                <AvatarImage size='xxl' source={model.image} />

                <h2 className='truncate hover:whitespace-normal max-w-full'>{model.name}</h2>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
const RemoveTeamDialog = ({
  id,
  name,
  cancel,
  isLoading,
  isSuccess,
  removeTeam,
  season_id,
}: {
  id: number;
  name: string;
  cancel: () => void;
  isLoading: boolean;
  isSuccess: boolean;
  removeTeam: Function;
  season_id: number;
}) => {
  // if (isSuccess)  cancel();
  return (
    <>
      <OutsideClick setFunc={cancel} />
      <ModalContent extraClass='h-fit' hideFooter name='Remove Team?'>
        <>
          <div className='flex flex-col p-10 text-center items-center'>
            <p className='w-full break-words'>
              Are you sure you want to remove <b className='break-words'>"{name}"</b> from the
              season?
            </p>
          </div>
          <ModalFooter
            isLoading={isLoading}
            onCancel={cancel}
            onSubmit={() => removeTeam({ season_id, team_id: id })}
          />
        </>
      </ModalContent>
    </>
  );
};
export default RemoveTeamsFromSeason;
