import * as yup from 'yup';
import { SchemaMessages } from '../../../../../../../utils/constants';
import { checkEndDate, checkStartDate } from '../../../../../../../utils/utils';

const today = new Date();
today.setHours(0, 0, 0, 0);

export const NewTournamentVSchema = yup.object().shape({
  game_id: yup.string().required('Required!'),
  name: yup
    .string()
    .matches(
      /^[a-zA-ZÀ-ÖØ-öø-ÿÇç0-9\s-,.'"]{0,50}$/,
      'Only letters, numbers, spaces, and hyphens are allowed (maximum 50 characters)',
    )
    .required('Required!'),
  type_id: yup.string().required('Required!'),
  team_size: yup.string().required('Required!'),
  division_id: yup.string().required('Required!'),
  subdivision: yup.string().nullable(),
  start_date: yup
    .mixed()
    .required('Required!')
    .transform((v, ov) => ov)
    .test('test', SchemaMessages.DateShouldNotBeInThePast, checkStartDate),
  // .min(today, 'Start date should not be in the past')
  // .transform((value, originalValue) => {
  //   if (originalValue) {
  //     return new Date(originalValue);
  //   }
  //   return value;
  // })
  end_date: yup
    .mixed()
    .required('Required!')
    .when('start_date', {
      is: (start_date: any) => start_date != null,
      then: yup
        .mixed()
        .transform((v, ov) => ov)
        .test('test', SchemaMessages.EndDate, (v, ctx) => checkEndDate(v, ctx, 'start_date')),
    }),
  // end_date: yup
  //   .mixed()
  //   .transform((v, ov) => ov)
  //   .test('test', SchemaMessages.EndDate, (v, ctx) => checkEndDate(v, ctx, 'start_date'))
  // .min(yup.ref('start_date'), "End date can't be before start date")
  // .required('Required!'),
  information: yup.string().max(150).required('Required!'),
  teams: yup.string().nullable(),
  // image: yup.string().nullable(),
  sponsors: yup.string().nullable(),
  format_id: yup.string().required('Required!'),
});
