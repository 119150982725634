import { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router';
import { useGetSingleMatchInfoQuery } from '../../../../slices/matchApiSlice';
import {
  useCanSubmitScoreQuery,
  useSubmitScoreToMatchMutation,
} from '../../../../slices/seasonApiSlice';
import { OutsideClick } from '../../../../components/outside-click';
import { Form, Formik } from 'formik';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { AvatarImage } from '../../../../components/avatar-image';
import { Link } from 'react-router-dom';
import { SubmitButton } from '../../../../components/submit-button';
import { DragDropFile } from '../../../update-game-result/drag-drop-file/drag-drop-file';
import { toast } from 'react-toastify';

const SeasonWeekScore = () => {
  const [file, setFile] = useState<File | null>(null);
  const [hScore, setHScore] = useState(0);
  const param = useParams();
  const { data, isLoading, isError, isSuccess } = useGetSingleMatchInfoQuery(param.matchId, {
    skip: !param.matchId,
  });
  const {
    isLoading: canSubmitLoading,
    data: canSubmit,
    isError: canSubmitError,
  } = useCanSubmitScoreQuery(param?.matchId ?? '', {
    skip: !param.matchId,
  });

  const [errorMsg, setErrorMsg] = useState('');
  const [submit, { isLoading: isUploading, isSuccess: uploadSuccess }] =
    useSubmitScoreToMatchMutation();
  const handleSubmit = (values: any) => {
    if (!file) {
      setErrorMsg('Please upload an image!');
      return;
    }
    const formData = new FormData();
    formData.append('ht_result', hScore.toString());
    formData.append('image', file);
    submit({ id: param.matchId ?? '', body: formData });
    return;
  };
  useEffect(() => {
    if (file) setErrorMsg('');
  }, [file]);


  useEffect(() => {
    if (uploadSuccess) toast.success('You succesfully submitted score!', { position: 'top-right' });
  }, [uploadSuccess]);


  if (isLoading || canSubmitLoading) return <p>Loading...</p>;
  if (uploadSuccess) return <Navigate to='../' />;
  if (!canSubmit || canSubmitError) return <Navigate to='../' />;
  return (
    <>
      <OutsideClick />
      <Formik initialValues={{}} onSubmit={handleSubmit}>
        {(formik) => {
          return (
            <Form>
              <div className='fixed  top-0 left-0 right-0 z-10 w-auto my-6 mx-auto max-w-3xl max-h-[100vh] '>
                {/* content*/}
                <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-full overflow-auto'>
                  {/* header*/}
                  <div className='flex items-start justify-between mb-2 p-5 border-b border-solid border-slate-200 rounded-t sticky top-0 z-[1] bg-white'>
                    <h3 className='text-3xl font-semibold'>Edit Game Results</h3>
                  </div>
                  <div className='relative p-6 flex flex-col'>
                    <div className='flex justify-center gap-10 '>
                      <div className='flex items-center  rounded-lg second-gr-bg-color p-1 px-3  min-w-[300px] flex-1 gap-2'>
                        <AvatarImage source={data?.home_team?.[0]?.image} size='xl' />
                        <div className='flex flex-1 justify-start items-center '>
                          <h2 className='text-sm font-bold w-fit '>
                            {data?.home_team?.[0]?.teamName}
                          </h2>
                        </div>
                        <div className='flex gap-1 h-[80%] items-center p-2 border-2 bg-white rounded-xl'>
                          <AiOutlineMinus
                            onClick={() => setHScore((s) => (s > 0 ? s - 1 : s))}
                            className='cursor-pointer hover:scale-110 '
                          />
                          <input
                            onChange={(e) => setHScore(+e.target.value)}
                            type='text'
                            value={hScore}
                            className='w-[70%] text-center font-bold focus:outline-none active:outline-none'
                          />
                          <AiOutlinePlus
                            onClick={() => setHScore((s) => s + 1)}
                            className='cursor-pointer hover:scale-110 '
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='flex flex-col p-5 gap-5 drag-upload'>
                    <div className='flex flex-col p-5 gap-5 drag-upload'>
                      {/* <p className='text-red-700'>{errorMsg}</p> */}
                      <DragDropFile file={file} setFile={setFile} />
                    </div>

                    <p className='text-red-700'>{errorMsg}</p>
                  </div>
                  {/* footer*/}
                  <div className='flex items-center justify-end p-2  border-slate-200 rounded-b sticky bottom-0 bg-white'>
                    <Link
                      className='w-1/5 my-12 text-center py-1 bg-white text-black shadow-lg font-semibold rounded-lg'
                      to='../'
                    >
                      Cancel
                    </Link>
                    <SubmitButton isLoading={isUploading} />
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default SeasonWeekScore;
