import React, { useEffect, useRef, useState } from 'react';
import { StudentDashboardClassroomCard } from './student-dashboard-classroom-card';
import { useGetAllClassroomsByOrganizationIdQuery } from '../../../slices/classroomApiSlice';
import { ClassroomModel } from '../../../model/classroom.model';
import useIntersectionObserver from '../../../hooks/use-intersection-observer';
import { useSelector } from 'react-redux';
import { selectDashboardClassrooms } from '../../../slices/userSlice';
export function StudentDashboardClassrooms() {
  const [fetch, setFetch] = useState(false);
  const elementRef = useRef(null);
  const [page, setPage] = useState<number>();
  const entry = useIntersectionObserver(elementRef, {});
  const dashboardClassrooms = useSelector(selectDashboardClassrooms);
  const isVisible = !!entry?.isIntersecting;
  const hasIOSupport = !!window.IntersectionObserver;

  const { data, isLoading, isSuccess } = useGetAllClassroomsByOrganizationIdQuery({
    page,
    limit: 4,
  });
  useEffect(() => {
    if (isSuccess) console.log('isVisible', fetch);
    if (!isVisible || !fetch || isLoading || !data || !data.meta.next) return;
    setPage(data.meta.next);
  }, [isVisible, data?.meta, fetch]);

  useEffect(() => {
    console.log('isLoading', isLoading, fetch);
    if (isLoading) setFetch(false);
    else setTimeout(() => setFetch(true), 2000);
  }, [isLoading]);

  return (
    <div className='flex flex-col items-center gap-5 realtive w-full sm:w-[100%] lg:w-[30%] border-1 rounded-xl border-slate-300'>
      <h2 className='font-bold text-lg pt-5'>Your Classrooms</h2>
      <div className='flex flex-col w-full bg-white p-5 rounded-xl max-w-[100%] min-h-[300px] h-[300px] overflow-auto'>
        {isLoading && dashboardClassrooms.length
          ? 'Loading...'
          : dashboardClassrooms.map((d: any) => (
            <StudentDashboardClassroomCard key={d.id} model={new ClassroomModel(d)} />
          ))}
        {isLoading ? (
          <div>Loading</div>
        ) : (
          <div ref={elementRef} className='flex flex-1 w-full min-h-[10px]'></div>
        )}
        {!hasIOSupport && data.meta.next && <span>Load more</span>}
      </div>
    </div>
  );
}
