import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';
import {
  useCreateCommentMutation,
  useGetTicketByIdQuery,
  useUpdateSupportTicketMutation,
} from '../../slices/supportTicketApiSlice';
import { TicketApprovalStatus, TicketStatusTime } from '../../utils/constants';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { OutsideClick } from '../../components/outside-click';
import { Formik, Form, FormikHelpers } from 'formik';
import { FormInput } from '../../components/form-input/form-input';
import { SubmitButton } from '../../components/submit-button';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { BsUpload } from 'react-icons/bs';
import { ViewTicketModel } from './view-ticket-model';
import ViewTicketIdTier from './view-ticket-id-tier';
import ViewTicketComments from './view-ticket-comments';
import { useGetAllSubcategoriesByCategoryIdQuery } from '../../slices/supportSubcategoryApiSlice';
import { useGetAllSupportCategoryQuery } from '../../slices/supportCategoryApiSlice';
import { ViewTicketValidationSchema } from '../../features/authentication/yupschema';
import moment from 'moment';

const ViewTicketAdmin = () => {
  const [selectedFile, setSelectedFile] = useState<string | File | any>();
  const [fileName, setFileName] = useState<string | any>();
  const [textAreaActive, setTextAreaActive] = useState<boolean | any>();
  const param = useParams();
  const {
    data: ticketData,
    isLoading: fetchingTicket,
    isSuccess,
  } = useGetTicketByIdQuery(param.id);
  const [createComment, { isLoading: commentIsLoading, isSuccess: commentPosted }] =
    useCreateCommentMutation();
  const { data: categories, isLoading: catIsLoading } = useGetAllSupportCategoryQuery({
    optional: 1,
  });
  const [supportCgId, setSupportCgId] = useState<string | any>(null);
  const {
    data: subCategoriesOfCategory,
    isSuccess: subIsSuccess,
    isLoading: subCgLoading,
  } = useGetAllSubcategoriesByCategoryIdQuery(+supportCgId, { skip: !supportCgId });
  const [updateSupportTicket, { isLoading: updateTicketLoading, isSuccess: updateTicketSuccess }] =
    useUpdateSupportTicketMutation();
  const [model, setModel] = useState(new ViewTicketModel(ticketData));
  const [comment, setComment] = useState<string>('');
  const [err, setErr] = useState<string | any>();
  const [hideDisconnectBtn, setHideDisconnectBtn] = useState<boolean>(false);
  const [timeToResponseCounter, setTimeToResponseCounter] = useState<string | any>(null);
  const [viewFullImage, setViewFullImage] = useState<boolean>(false);
  const [timeToResolveCounter, setTimeToResolveCounter] = useState<string | any>(null);
  const [sendCommentToJira, setSendCommentToJira] = useState<boolean | any>(false);
  const [responseTimeTier1, setResponseTimeTier1] = useState<string | any>(null);
  const [timeToResolveTier1, setTimeToResolveTier1] = useState<string | any>(null);

  const notify = () => {
    toast('Success!', {
      autoClose: 3000,
      style: {
        background: 'white',
      },
      progressStyle: {
        background: 'green',
      },
    });
  };

  const handleImageView = () => {
    setViewFullImage(!viewFullImage);
  };

  useEffect(() => {
    if (!ticketData?.childTickets?.length) {
      setHideDisconnectBtn(true);
    } else {
      setHideDisconnectBtn(false);
    }
  }, [ticketData]);

  useEffect(() => {
    if (ticketData) {
      setModel(new ViewTicketModel(ticketData));
    }
  }, [ticketData]);

  const calcTime = (time: string, pauseDuration?: number) => {
    const now = moment(moment.now()).subtract(pauseDuration, 'seconds');
    const finalDiff = now.diff(moment(time), 'milliseconds');
    const formattedHours = String(Math.floor(finalDiff / (1000 * 60 * 60))).padStart(2, '0');
    const formattedMinutes = String(
      Math.floor((finalDiff % (1000 * 60 * 60)) / (1000 * 60)),
    ).padStart(2, '0');
    const formattedSeconds = String(Math.floor((finalDiff % (1000 * 60)) / 1000)).padStart(2, '0');
    return formattedHours + ':' + formattedMinutes + ':' + formattedSeconds;
  };

  useEffect(() => {
    if (ticketData?.ticketTracking) {
      const ticketT1 = ticketData?.ticketTracking?.ticketNotStartedT1;
      const whenInProgress1 = ticketData?.ticketTracking?.whenInProgress1;
      const ttrTime1 = ticketData?.ticketTracking?.ttrTime1;
      const createdInJira = ticketData?.ticketTracking?.createdInJira;
      const whenInProgress2 = ticketData?.ticketTracking?.whenInProgress2;
      const status = ticketData?.status;
      const ticketTier = ticketData?.ticketTier;
      const ack = ticketData?.ticketTracking?.ack;
      const pauseDuration = ticketData?.ticketTracking?.pauseDurationInProgress;
      const ttrTime2 = ticketData?.ticketTracking?.ttrTime2;

      if (ack) return;

      // Todo tier1
      if (!ticketTier && status === TicketStatusTime.Open && ticketT1?.length) {
        const interval = setInterval(() => {
          const resp = calcTime(ticketT1?.slice(-1)[0]);
          setResponseTimeTier1(resp);
        }, 1000);
        return () => clearInterval(interval);
      }
      // InProgress tier1
      else if (
        !ticketTier &&
        status === TicketStatusTime.InProgressT1 &&
        !ttrTime1?.length &&
        whenInProgress1?.length
      ) {
        const interval = setInterval(() => {
          const resp = calcTime(whenInProgress1?.slice(-1)[0], pauseDuration);
          setTimeToResolveTier1(resp);
        }, 1000);
        return () => clearInterval(interval);
      }
      // Todo jira tier2
      else if (ticketTier && status === TicketStatusTime.ToDo && createdInJira?.length) {
        const interval = setInterval(() => {
          const resp = calcTime(createdInJira?.slice(-1)[0]);
          setTimeToResponseCounter(resp);
        }, 1000);
        return () => clearInterval(interval);
      }
      // InProgress jira tier2
      else if (
        ticketTier &&
        status === TicketStatusTime.InProgressT2 &&
        whenInProgress2?.length &&
        !ttrTime2?.length
      ) {
        const interval = setInterval(() => {
          const resp = calcTime(whenInProgress2?.slice(-1)[0], pauseDuration);
          setTimeToResolveCounter(resp);
        }, 1000);
        return () => clearInterval(interval);
      } else {
        return () => {};
      }
    }
  }, [ticketData]);

  const priorityLevels = [
    {
      id: '1',
      name: 'P1',
    },
    {
      id: '2',
      name: 'P2',
    },
    {
      id: '3',
      name: 'P3',
    },
  ];

  const ticketStatus = [
    {
      id: '0',
      name: 'Not started',
    },
    {
      id: '3',
      name: 'In Progress',
    },
    {
      id: '1',
      name: 'Closed',
    },
    {
      id: '2',
      name: 'Archived',
    },
  ];

  const formInputs = [
    {
      name: 'fullName',
      label: 'Owner',
      placeholder: 'Owner name',
      type: 'text',
      isTrue: false,
      isDisabled: true,
    },
    {
      name: 'role',
      label: 'Role',
      placeholder: 'Role',
      type: 'text',
      isTrue: false,
      isDisabled: true,
    },
    {
      name: 'createdAt',
      label: 'Created Date',
      placeholder: 'Created date',
      type: 'text',
      isTrue: false,
      isDisabled: true,
    },
    ...(!ticketData?.ticketTier
      ? [
          {
            name: 'priorityLevel',
            label: 'Priority',
            placeholder: 'Priority level',
            type: 'text',
            isTrue: false,
            isSelect: true,
            options: priorityLevels?.map((priority: any) => ({
              id: priority.id,
              name: priority.name,
              selected: priority.id === model?.priorityLevel,
            })),
          },
        ]
      : [
          {
            name: 'priorityLevel',
            label: 'Priority',
            placeholder: 'Priority level',
            type: 'text',
            isTrue: false,
            isSelect: false,
            isDisabled: true,
          },
        ]),
    ...(!ticketData?.ticketTier
      ? [
          {
            name: 'supportCategoryId',
            label: 'Select category',
            type: 'text',
            placeholder: 'Category',
            isTrue: false,
            isSelect: true,
            options: categories?.map((category: any) => ({
              id: category.id,
              name: category.name,
              selected: category.id === ticketData?.category?.id,
            })),
          },
        ]
      : [
          {
            name: 'supportCategoryId',
            label: 'Select category',
            type: 'text',
            placeholder: 'Category',
            isTrue: false,
            isSelect: false,
            isDisabled: true,
            value: ticketData?.category?.name,
          },
        ]),
    ...(!ticketData?.ticketTier
      ? [
          {
            name: 'supportSubcategoryId',
            label: 'Subcategory',
            placeholder: 'Subcategory',
            type: 'text',
            isTrue: false,
            isSelect: true,
            options:
              supportCgId && subIsSuccess
                ? subCategoriesOfCategory?.map((subcategory: any) => ({
                    id: subcategory.id,
                    name: subcategory.name,
                    selected: subcategory.id === ticketData?.subcategory?.id,
                  }))
                : [],
          },
        ]
      : [
          {
            name: 'supportSubcategoryId',
            label: 'Subcategory',
            placeholder: 'Subcategory',
            type: 'text',
            isTrue: false,
            isSelect: false,
            isDisabled: true,
            value: ticketData?.subcategory?.name,
          },
        ]),
    ...(!ticketData?.ticketTier
      ? [
          {
            name: 'status',
            label: 'Status',
            placeholder: 'Status',
            type: 'text',
            isTrue: false,
            isSelect: true,
            options: ticketStatus,
          },
        ]
      : [
          {
            name: 'status',
            label: 'Status',
            placeholder: 'Status',
            type: 'text',
            isTrue: false,
            isSelect: false,
            isDisabled: true,
          },
        ]),
  ];

  const onSubmit = async (values: any, { setFieldValue }: FormikHelpers<any>) => {
    delete values.fullName;
    delete values.role;
    delete values.time;
    delete values.createdAt;

    const data = { ...values };
    data.supportCategoryId = +values.supportCategoryId;
    data.supportSubcategoryId = +values.supportSubcategoryId;
    data.status = !ticketData?.ticketTier ? +values.status : null;
    data.priorityLevel = +values.priorityLevel;
    const req = await updateSupportTicket({ id: param.id, body: data }).unwrap();
    if (req) {
      notify();
    }
  };

  const handleCommentFileChange = (event: React.FormEvent) => {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    if (!file) {
      setFileName('');
      setSelectedFile('');
      return;
    }
    setSelectedFile(file);
    setFileName(file?.name);
  };

  const handleFocus = () => {
    setTextAreaActive(true);
  };

  const handleBlur = () => {
    setTextAreaActive(false);
  };

  const handleSendComment = (e: React.FormEvent) => {
    e.preventDefault();
    if (comment.trim() === '') {
      setErr('Required!');
    } else {
      const formData = new FormData();
      formData.append('supportTicketId', String(param.id));
      formData.append('text', comment);
      formData.append('issueKey', String(ticketData?.issueKey));
      formData.append('file', selectedFile);
      if (sendCommentToJira) {
        formData.append('createInJira', '1');
      }
      createComment(formData);
      setFileName('');
      setSelectedFile('');
      setComment('');
      setErr('');
      setSendCommentToJira(false);
    }
  };

  if (fetchingTicket || catIsLoading || subCgLoading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <Outlet />
      <div className='flex overflow-y-auto max-w-full flex-col flex-1 rounded-xl bg-white border-1 gap-5'>
        {/* <ToastContainer /> */}
        <div className='p-6 border-b'>
          <h1 className='font-bold text-2xl break-words'>{ticketData?.title}</h1>
        </div>
        <ViewTicketIdTier
          ticketData={ticketData}
          timeToResponse={
            !ticketData?.ticketTier
              ? responseTimeTier1 || model?.slaTimeT1
              : timeToResponseCounter || model?.slaTime
          }
          timeToResolve={
            !ticketData?.ticketTier
              ? timeToResolveTier1 || model.resolveTimeT1
              : timeToResolveCounter || model?.ttrTime
          }
          closedAt={ticketData?.closedAt}
        />
        <div>
          <Formik
            initialValues={{
              fullName: model.fullName || model.email,
              role: model.role,
              createdAt: model.f_createdAt,
              priorityLevel: model.priorityLevel,
              supportCategoryId: ticketData?.category?.id,
              supportSubcategoryId: ticketData?.subcategory?.id,
              status: !ticketData?.ticketTier ? ticketData?.status : model.status,
            }}
            onSubmit={onSubmit}
            validationSchema={ViewTicketValidationSchema}
            enableReinitialize={true}
          >
            {({ values, setFieldValue }) => {
              useEffect(() => {
                if (!values.supportCategoryId) return;
                if (values.supportCategoryId !== ticketData?.category?.id)
                  setFieldValue('supportSubcategoryId', '');
                setSupportCgId(values.supportCategoryId);
              }, [values.supportCategoryId]);

              return (
                <Form>
                  <div className='grid gap-y-4 px-5'>
                    <div className='mx-auto'>
                      <div className='flex justify-center lg:justify-start flex-wrap max-w-[700px] gap-5'>
                        {formInputs.map((inp: any, i: any) => (
                          <React.Fragment key={i}>
                            <div className={inp.isTrue ? 'flex-1' : 'w-[340px]'}>
                              {inp.type !== 'image' ? (
                                <FormInput {...inp} />
                              ) : (
                                <FormInput setFieldValue={''} {...inp} />
                              )}
                            </div>
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                    {!ticketData?.ticketTier ? (
                      <div className='flex justify-end mb-3'>
                        <SubmitButton
                          title='Save'
                          isLoading={updateTicketLoading}
                          disabled={updateTicketLoading}
                        />
                      </div>
                    ) : null}
                    <div className='border bg-gray-100 rounded flex flex-col gap-2'>
                      <span className='ml-2 pt-2'>Description</span>
                      <p className='pl-2 mb-2'>{ticketData?.description}</p>
                      {ticketData?.link ? (
                        <span className='pl-2 mb-2'>
                          <Link
                            to={`${ticketData?.link}`}
                            className='border-b-1 border-[#6B7280]'
                            target='_blank'
                          >
                            Link of issue
                          </Link>
                        </span>
                      ) : null}
                    </div>
                    {viewFullImage && <OutsideClick setFunc={setViewFullImage} />}
                    {ticketData?.fileUrl?.includes('image') ? (
                      <div className='max-w-[300px]'>
                        <img
                          src={ticketData?.fileUrl}
                          alt=''
                          className={
                            viewFullImage
                              ? 'fixed top-0 left-0 right-0 bottom-0 z-10 w-auto h-[90vh] m-auto'
                              : 'cursor-pointer'
                          }
                          onClick={() => handleImageView()}
                        />
                      </div>
                    ) : ticketData?.fileUrl?.includes('video') ? (
                      <div className='max-w-[300px]'>
                        <video src={ticketData?.fileUrl} autoPlay controls />
                      </div>
                    ) : ticketData?.fileUrl?.includes('pdf') ? (
                      <div className='max-w-[300px]'>
                        <Link to={ticketData?.fileUrl} target='_blank'>
                          PDF attachment
                        </Link>
                      </div>
                    ) : null}
                    <div className='border-1 mb-3'></div>
                    <ViewTicketComments />
                    <div className='flex flex-col gap-2 comments'>
                      <div
                        className={`${textAreaActive ? 'border-red-700' : ''} flex flex-col border`}
                      >
                        <label
                          className={`${textAreaActive ? 'text-red-700' : ''} mb-2 ml-2 pt-2`}
                          htmlFor='AddComment'
                        >
                          Add comment
                        </label>
                        <textarea
                          id='AddComment'
                          name='comment'
                          className='pl-2 outline-none resize-none rounded'
                          placeholder='Start typing...'
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          value={comment}
                          onChange={(e: ChangeEvent<{ value: string }>) => {
                            setComment(e.target.value);
                          }}
                        />
                      </div>
                      {err ? <div className='error'>{err}</div> : null}
                      {ticketData?.ticketTier ? (
                        <div className='flex items-center gap-2'>
                          <input
                            type='checkbox'
                            id='sendToJira'
                            checked={sendCommentToJira}
                            onChange={(e) => setSendCommentToJira(e.target.checked)}
                          />
                          <label htmlFor='sendToJira'>Post comment to jira</label>
                        </div>
                      ) : null}
                    </div>
                    <div className='flex sm:flex-wrap sm:items-center justify-end gap-5 mb-5'>
                      <div className='flex items-center gap-2'>
                        <label
                          className='flex items-center gap-2 px-2 py-2 bg-[#D9D9D9] rounded-lg cursor-pointer'
                          onChange={handleCommentFileChange}
                          htmlFor='fileUpload'
                        >
                          <input
                            type='file'
                            name=''
                            id='fileUpload'
                            hidden
                            accept='image/jpeg, image/png, image/jpg, video/mp4, video/x-m4v, video/wmv, video/webm, application/pdf'
                          />
                          <BsUpload />
                          <span>Upload file</span>
                        </label>
                        {fileName && <span className='truncate w-32'>{fileName}</span>}
                      </div>
                      <div>
                        <button
                          className='min-w-[120px] px-2 py-2 bg-red-700 hover:bg-opacity-80 text-white font-semibold text-sm rounded-lg ml-2 button'
                          disabled={commentIsLoading}
                          onClick={handleSendComment}
                        >
                          Send
                        </button>
                      </div>
                    </div>
                  </div>
                  <div></div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
      <div className='flex items-center justify-center gap-5'>
        <div className='flex justify-self-start'>
          <div className=''>
            <Link to='../' className='flex items-center gap-2'>
              <AiOutlineArrowLeft />
              Back
            </Link>
          </div>
        </div>
        <div className='flex gap-5 mx-auto'>
          {/* {ticketData?.adminApprovalStatus === TicketApprovalStatus.AdminNotApproved && !ticketData?.ticketTier ? (
                        <Link to="approve" className="min-w-[120px] px-2  py-2 bg-red-700 hover:bg-opacity-80 text-white font-semibold text-sm rounded-lg ml-2  button">Ticket resolved</Link>
                    ) : ticketData?.whenInMonitorReview !== null && ticketData?.ticketTier && ticketData?.adminApprovalStatus === TicketApprovalStatus.AdminNotApproved ? (
                        <Link to="validate-ticket" className="min-w-[120px] px-2  py-2 bg-red-700 hover:bg-opacity-80 text-white font-semibold text-sm rounded-lg ml-2  button">Validate ticket</Link>
                    ) : (
                        null
                    )} */}
          {ticketData?.status === 6 &&
          ticketData?.ticketTier &&
          ticketData?.adminApproval === TicketApprovalStatus.AdminNotApproved ? (
            <Link
              to='validate-ticket'
              className='min-w-[120px] px-2  py-2 bg-red-700 hover:bg-opacity-80 text-white font-semibold text-sm rounded-lg ml-2  button'
            >
              Validate ticket
            </Link>
          ) : null}
          <div className='group relative'>
            <div className='hidden group-hover:block absolute z-1 bottom-[50px] text-center w-full'>
              <span className='text-sm text-red-700'>Disconnect child tickets</span>
            </div>
            {ticketData?.status === 2 && !hideDisconnectBtn ? null : ticketData?.status !== 2 &&
              !hideDisconnectBtn ? (
              <Link
                to='disconnect'
                className='min-w-[120px] max-w-[250px] bg-transparent hover:bg-opacity-80 border-1 border-red-700 text-red-700 font-semibold text-sm rounded-lg ml-2  button'
              >
                Disconnect
              </Link>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewTicketAdmin;
