import * as yup from 'yup';
import { Formik, Form } from 'formik';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, Navigate, useParams } from 'react-router-dom';
import {
  useGetDivisionByIdQuery,
  useUpdateDivisionMutation,
} from '../../../../slices/divisionApiSlice';
import { OutsideClick } from '../../../../components/outside-click';
import ModalContent from '../../../../components/modal-content';
import { EditDivisionVSchema } from './components/edit-division-v-schema';
import { CustomError } from '../../../../utils/custom-types';

function EditDivision() {
  const [avatarImage, setAvatarImage] = useState<any>();
  const param = useParams();
  // const user = useSelector(selectUser);

  const {
    error,
    data: divisionData,
    isLoading: divisionIsLoading,
    isError: divisionIsError,
    isSuccess: divisionIsSuccess,
  } = useGetDivisionByIdQuery(param.id);

  const [updateDivision, { isLoading, isError, isSuccess }] = useUpdateDivisionMutation();

  const formInputs = [
    {
      mainTitle: 'Category Division Information',
      name: 'name',
      label: 'Category Division Name',
      type: 'text',
      placeholder: 'Enter category division name',
      isTrue: true,
    },
    {
      mainTitle: 'Category Division Information',
      name: 'background_image',
      label: 'Background Image',
      type: 'image',
      placeholder: 'Upload background image',
      isTrue: true,
    },
  ];

  const onSubmit = (values: any) => {
    const data = { ...values };
    data.image = avatarImage;
    data.background_image = divisionData.background_image;

    const formData = new FormData();
    Object.entries(data).forEach(([key, value]: any[]) => {
      if (value) formData.append(key, value);
    });

    updateDivision({ id: param.id, body: formData })
      .unwrap()
      .then((res: any) => console.log(res))
      .catch((error) => console.log(error));
  };

  if (divisionIsError || (divisionIsSuccess && !divisionData))
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError).data.message }} />;
  if (divisionIsLoading) return <p>Loading...</p>;
  if (isSuccess) return <Navigate to='../' />;

  return (
    <>
      <OutsideClick />
      <Formik
        validationSchema={EditDivisionVSchema.shape({
          background_image: yup
            .mixed()
            .nullable()
            .test(
              'test2',
              'Invalid image format. Only JPG, PNG, and JPEG formats are allowed.',
              () => {
                const acceptedFormats = ['image/jpeg', 'image/png', 'image/jpg'];
                if (avatarImage && !acceptedFormats.includes(avatarImage?.type ?? '')) {
                  return false;
                }
                return true;
              },
            ),
        })}
        initialValues={divisionData}
        onSubmit={onSubmit}
      >
        {({ values, errors }) => {
          return (
            <ModalContent
              errors={Object.keys(errors).length}
              setAvatarImage={setAvatarImage}
              formInputs={formInputs}
              isLoading={isLoading}
              extraClass='max-h-[90vh]'
              name={`Edit Category Division - ${divisionData.name}`}
            />
          );
        }}
      </Formik>
    </>
  );
}

export default EditDivision;