import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useGetAllTournamentsByDateStatusQuery } from '../../../../slices/tournamentApiSlice';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import TournamentScheduleView from './components/tournament-schedule-view';
import { ScheduleModel } from '../../../../model/schedule.model';
import SearchSchedule from '../../components/search-schedule';
import Pagination from '../../../../components/pagination';
import { LoadingSceletonSchedule } from '../../../tournaments/routes/all/loading-sceleton-schedule';
import { dateToLocal, formatJustDateWithComma } from '../../../../utils/dateFormatter';
// import { LoadingSceletonSchedule } from '../../../tournaments/routes/all/loading-sceleton-security';

const TournamentScheduleByDate = ({ dateStatus }: { dateStatus?: any }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [dataGroupedByDate, setDataGroupedByDate] = useState<{ date: string; data: any }[]>([
    { date: '', data: [] },
  ]);

  const {
    data: event,
    isError,
    isSuccess,
    isLoading,
  } = useGetAllTournamentsByDateStatusQuery([
    {
      name: searchParams.get('name') ? searchParams.get('name') : '',
      date: searchParams.get('date') ? searchParams.get('date') : '',
      page: searchParams.get('page') ? searchParams.get('page') : 1,
    },
    { dateStatus: dateStatus ?? undefined },
  ]);

  useEffect(() => {
    if (event && event.data) {
      const groups = event.data.reduce((groups: any, game: any) => {
        // const date = game.start_date?.split('T')[0];
        const date = dateToLocal(game?.start_date)?.split('T')[0];
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(game);
        return groups;
      }, {});

      const groupArrays = Object.keys(groups).map((date) => {
        return {
          date,
          data: groups[date],
        };
      });
      setDataGroupedByDate((prevData) => groupArrays);
    }
  }, [event, event?.data, setDataGroupedByDate]);

  if (isLoading) return <LoadingSceletonSchedule />;
  return (
    <div className='flex flex-col justify-between flex-1 w-full h-full'>
      <div className='container flex flex-col w-full mx-auto'>
        {dataGroupedByDate.map((e: any, i: number) => (
          <div key={i}>
            <div className='p-4 font-bold'>{dateToLocal(e?.date, 'ddd MMM DD, YYYY')}</div>
            <div className='flex flex-col gap-2 '>
              {e.data.map((d: any, i: number) => (
                <Link key={i} to={`/tournaments/view/${d.id}`}>
                  <TournamentScheduleView scheduleModel={new ScheduleModel(d)} />
                </Link>
              ))}
            </div>
          </div>
        ))}
      </div>

      <Pagination length={event?.data?.length} {...event?.meta} />
    </div>
  );
};

export default TournamentScheduleByDate;
