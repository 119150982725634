import React from 'react';
import { RescheduleRequestModel } from '../../../../model/reschedule-request.model';
export function SentRescheduleRequestCard({ model }: { model: RescheduleRequestModel }) {
  return (
    <div className='flex p-2 gap-2 bg-slate-50 w-full '>
      <p className='text-xs flex flex-col items-center'>
        OGMA Status: <b>{model.admin_status_name}</b>
      </p>
      <p className='text-xs  flex flex-col items-center'>
        Opposite Captain Status: <b>{model.captain_status_name}</b>
      </p>
      <p className='text-xs  flex flex-col items-center'>
        Requested Date: <b>{model.f_new_date}</b>
      </p>
    </div>
  );
}
