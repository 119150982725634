import { Form, Formik, FormikValues } from 'formik';
import { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { OutsideClick } from '../../../../../../components/outside-click';
import {
  useUpdateSeasonTeamMutation,
  useViewSingleSeasonQuery,
} from '../../../../../../slices/seasonApiSlice';
import { CheckboxListTeamsSeason } from '../components/checkbox-list-teams-season';
import ModalHeader from '../../../../../../components/modal-header';
import ModalFooter from '../../../../../../components/modal-footer';
import { CustomError } from '../../../../../../utils/custom-types';
import { SeasonProgressStatus } from '../../../../../../utils/constants';
import { TeamModel } from '../../../../../../model/team.model';

function EditSeasonTeams() {
  const [chosenTeam, setChosenTeam] = useState<
    { id: number; image: string | null; teamName: string }[]
  >([]);
  const param = useParams();

  const [updateSeasonTeam, { isLoading, isSuccess, isError }] = useUpdateSeasonTeamMutation();
  const {
    error,
    data: seasonData,
    isLoading: seasonIsLoading,
    isError: seasonIsError,
    isSuccess: seasonIsSuccess,
  } = useViewSingleSeasonQuery(param.id);
  // = useGetTournamentByIdQuery(param.id);

  useEffect(() => {
    if (!seasonData) return;
    // setTimeout(() => {
    if (seasonData?.teams) {
      setChosenTeam((s) => {
        const currentTeamIds = s.map((team) => team.id);
        const newTeams = seasonData.teams.filter((team: any) => !currentTeamIds.includes(team.id));
        const lastArray = [
          ...s.map((t: any) => {
            const model = new TeamModel(t);
            return { id: model.id, image: model.image, teamName: model.getOptionName() };
          }),
          ...newTeams.map((t: any) => {
            const model = new TeamModel(t);
            return { id: model.id, image: model.image, teamName: model.getOptionName() };
          }),
        ];
        console.log(lastArray);
        return [...new Set(lastArray)];
      });
    }
    // }, 100);
  }, [seasonData]);
  const [type, setType] = useState(0);
  const onSubmit = (values: FormikValues) => {
    const data = { ...values };

    data.teams = [];
    chosenTeam.forEach((t) => data.teams.push({ id: t.id }));

    delete data.id;

    console.log('test update tournament', data);
    updateSeasonTeam({ id: param.id, body: data })
      .unwrap()
      .then(() => setChosenTeam([]));
    // .catch((error) => console.log(error));
  };

  const formInputs = [
    {
      mainTitle: 'Tournament Information',
      name: 'teams',
      label: 'Teams',
      type: 'text',
      placeholder: 'Enter teams',
      isTrue: true,
      search: 'team',
      setChosenTeam,
      chosenTeam,
    },
  ];

  const initialValues: { [key: string]: string } = {};
  formInputs.forEach((input) => (initialValues[input.name] = ''));

  if (seasonIsError || (seasonIsSuccess && !seasonData))
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError).data.message }} />;
  if (seasonIsLoading) return <p>Loading...</p>;
  if (isSuccess || seasonData?.status !== SeasonProgressStatus.Upcoming)
    return <Navigate to='../' />;
  return (
    <>
      <OutsideClick />
      <Formik
        // validationSchema={ }
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Form>
              <div className='fixed top-0 left-0 bottom-0 right-0 m-auto z-10 w-auto  max-w-2xl h-[90vh] max-h-[90vh]'>
                <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none h-full'>
                  <ModalHeader name={'Edit Teams'} />
                  <div className='relative px-3 flex-auto overflow-auto'>
                    <CheckboxListTeamsSeason
                      chosenTeam={chosenTeam}
                      setChosenTeam={setChosenTeam}
                    />
                  </div>
                  <ModalFooter isLoading={seasonIsLoading} />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default EditSeasonTeams;
