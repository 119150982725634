import React from 'react'
import { useDeleteStudentInGameNameByIdMutation, useGetAllGamesQuery } from '../../../../slices/gameApiSlice';
import ModalArchive from '../../../../components/modal-archive';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../slices/auth';
import { Navigate, useParams } from 'react-router';
import { OutsideClick } from '../../../../components/outside-click';
import { Link } from 'react-router-dom';
import { AiOutlineClose } from 'react-icons/ai';
import { SubmitButton } from '../../../../components/submit-button';

const DeleteGameAccount = () => {
    const user = useSelector(selectUser);
    const param = useParams();

    const [deleteInGame, { isSuccess, isLoading, isError, error }] =
        useDeleteStudentInGameNameByIdMutation();


    const { data: gamesData, isLoading: gamesLoading, error: gamesError } = useGetAllGamesQuery('');

    const onSubmit = () => {
        deleteInGame({ user_id: user.id, game_id: Number(param.id) })
            .unwrap()
            .then((res) => console.log(res))
            .catch((e) => console.log(e));
    }

    if (gamesLoading || isLoading) return <p>Loading...</p>
    if (isSuccess) return <Navigate to='../' />;
    return (
        <>
            <OutsideClick />
            <div className='fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-10 w-auto mx-auto max-w-2xl bg-white p-10 flex flex-col gap-10 items-center justify-center rounded-xl'>
                <div className='absolute top-2 right-2'>
                    <Link to='../' className=''>
                        <AiOutlineClose size={'2rem'} className='hover:rounded-full hover:bg-gray-100 p-2' />
                    </Link>
                </div>
                <div className='py-4 flex flex-col justify-center items-center gap-8'>
                    <p className='select-none font-medium'>Are you sure you want to disconnect with <span className='font-semibold'>{gamesData?.find((g: any) => g.id === Number(param.id)).name}</span>?</p>
                    <div className='flex gap-2'>
                        <SubmitButton onSubmit={onSubmit} title='Disconnect' isLoading={isLoading} />
                        <Link
                            to='../'
                            className='p-4 px-8 text-center text-sm py-2 bg-white text-black border font-semibold rounded-lg hover:bg-gray-100'
                        >
                            No
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DeleteGameAccount