import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { UserHeader } from '../../components/user-header';
import {
  selectUser,
  selectUserGuardianStudents,
  selectUserSchool,
  selectUserTeam,
} from '../../slices/auth';
import { GuardianDashStudentsRow } from './components/guardian-dash-students-row';
import { GuardianDashUpMatches } from './components/guardian-dash-up-matches';
import { Link, Outlet } from 'react-router-dom';
import { useGetGuardianStudentsByIdQuery } from '../../slices/userApiSlice';
import { SponsorRow } from '../../components/sponsor-row/sponsor-row';
import { SponsorPlacements } from '../../utils/constants';
import GuardianStudents from './components/guardian-students';

function GuardianDashboard() {
  const user = useSelector(selectUser);
  const team = useSelector(selectUserTeam);
  const school = useSelector(selectUserSchool);
  // const guardianStudents = useSelector(selectUserGuardianStudents);

  const {
    data: guardianStudents,
    isLoading,
    isError,
    error,
  } = useGetGuardianStudentsByIdQuery(user.id);

  // useEffect(() => {
  //   console.log(guardianStudents);
  // }, []);

  if (isLoading) return <p>Loading...</p>;
  return (
    <div className='flex flex-col p-10 gap-10 border-1 rounded-xl'>
      <SponsorRow name={SponsorPlacements.Dashboard} />
      <div className='flex justify-between items-center'>
        <UserHeader name={user.firstName} image={user.image} id={user.id} username={user.username} />
        <Link
          to={`/users/view/${user.id}/Guardian/overview`}
          className='text-white bg-red-700 px-2 py-1 rounded-xl'
        >
          My Profile
        </Link>
      </div>

      {/* <div className='flex justify-between flex-wrap'>
        <div className='flex flex-col items-center gap-5 realtive w-full sm:w-[100%] lg:w-[40%] border-1 rounded-xl border-slate-300'>
          <h2 className='font-bold text-center text-lg pt-5'>Activities</h2>
          <div className='flex flex-col w-full bg-white p-5 rounded-xl max-w-[100%] min-h-[300px]'>
            <p>No upcoming activities!</p>
          </div>
        </div>
        <div className='flex flex-col items-center gap-5 realtive w-full sm:w-[100%] lg:w-[40%] border-1 rounded-xl border-slate-300'>
          <h2 className='font-bold text-center text-lg pt-5'>Students</h2>
          <div className='flex flex-col w-full bg-white text-white  rounded-xl max-w-[100%] min-h-[300px]'>
            {guardianStudents &&
              guardianStudents?.map((gs: any, i: number) => (
                <GuardianDashStudentsRow
                  key={i}
                  name={gs.firstName + ' ' + gs.lastName}
                  id={gs.id}
                />
              ))}
          </div>
        </div> */}
      {guardianStudents && <GuardianStudents students={guardianStudents} />}
      <GuardianDashUpMatches />
    </div>
  );
}

export default GuardianDashboard;
