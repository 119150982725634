import React, { useEffect, useRef, useState } from 'react';
import { OtherClassroomsTab } from './components/other-classrooms-tab';
import OtherClassroomCard from './components/other-classroom-card';
import OtherClassroomCardBySameGame from './components/other-classroom-same-game-card';

const OtherClassrooms = ({ cId, id, gameId }: { cId: number; id: number; gameId: number }) => {
  const [tab, setTab] = useState(1);
  const cardType: Record<string, JSX.Element> = {
    1: <OtherClassroomCard id={id} cId={cId} />,
    2: <OtherClassroomCardBySameGame gameId={gameId} cId={cId} />,
  };
  const tabs = [
    {
      tab,
      setTab,
      title: 'Coach',
      id: 1,
    },
    {
      tab,
      setTab,
      title: 'Game',
      id: 2,
    },
  ];
  return (
    <div className='flex p-2 flex-col rounded-lg shadow-lg h-[400px] w-[500px]'>
      <h1 className='font-semibold'>Other Classrooms</h1>
      <div className='flex rounded-xl'>
        {tabs.map((t) => (
          <OtherClassroomsTab {...t} key={t.id} />
        ))}
      </div>
      <div className='flex-1 gap-2 flex flex-col w-full overflow-y-scroll border border-t-0'>
        {cardType[tab]}
      </div>
    </div>
  );
};

export default OtherClassrooms;
