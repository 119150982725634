import React from 'react';
import { useDeleteSponsorPlacementMutation } from '../../../../../slices/sponsorApiSlice';
export function SponsorPlacementRow({ name, id }: { name: string; id: number | string }) {
  const [deletePlacement, { isLoading, isError, isSuccess }] = useDeleteSponsorPlacementMutation();
  function handleDelete() {
    deletePlacement(id);
  }
  return (
    <div className='flex container justify-between gap-5 items-center'>
      <p>{name}</p>
      <span className='flex gap-5'>
        {/* <div className='btn edit'>Edit</div> */}
        <div onClick={handleDelete} className='btn red'>
          {isLoading ? 'Deleting...' : 'Delete'}
        </div>
      </span>
    </div>
  );
}
