import ModalArchive from '../../components/modal-archive';
import {
  useArchiveCategoryMutation,
  useGetSupportCategoryByIdQuery,
} from '../../slices/supportCategoryApiSlice';
import { Navigate, useParams } from 'react-router';
import { CustomError } from '../../utils/custom-types';

const ArchiveSupportCategory = () => {
  const param = useParams();
  const { data: category, isLoading, isError, error } = useGetSupportCategoryByIdQuery(param.id);
  const [archiveSupportCategory, { isSuccess }] = useArchiveCategoryMutation();

  const onSubmit = () => {
    archiveSupportCategory(param.id)
      .unwrap()
      .then((resp) => console.log('resp after archived category', resp))
      .catch((err) => console.log('err after archived category', err));
  };

  if (isLoading) {
    return <p>loading...</p>;
  }

  if (isError) {
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError).data.message }} />;
  }

  if (isSuccess) {
    return <Navigate to='../' />;
  }

  return <ModalArchive onSubmit={onSubmit} isLoading={isLoading} name={category?.name} />;
};

export default ArchiveSupportCategory;
