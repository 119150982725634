import { useEffect } from 'react';
import { OutsideClick } from '../../../components/outside-click';
import { useCloseSeasonWeekMutation } from '../../../slices/seasonApiSlice';
import { toast } from 'react-toastify';
import { AiOutlineClose } from 'react-icons/ai';
import { SubmitButton } from '../../../components/submit-button';
import { ModalMessages, ToastMessages } from '../../../utils/modal-messages';

export const CloseSeasonWeekModal = ({
  id,
  name,
  cancel,
}: {
  id: number;
  name: string;
  cancel: () => void;
}) => {
  // if (isSuccess)  cancel();
  const [trigger, { isLoading, isSuccess }] = useCloseSeasonWeekMutation();
  useEffect(() => {
    if (!isSuccess) return;
    toast.success(ToastMessages.CloseSeasonWeek, { position: 'top-right' });
    cancel();
  }, [isSuccess]);
  return (
    <>
      <OutsideClick setFunc={cancel} />
      <div className='fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-10 w-auto mx-auto max-w-2xl bg-white p-10 flex flex-col gap-10 items-center justify-center rounded-xl'>
        <div className='absolute top-2 right-2'>
          <div onClick={cancel} className='cursor-pointer'>
            <AiOutlineClose size={'2rem'} className='hover:rounded-full hover:bg-gray-100 p-2' />
          </div>
        </div>
        <div className='py-4 flex flex-col justify-center items-center gap-8'>
          <p className='select-none font-medium'>
            {/* Are you sure you want to close week <span className='font-semibold'>{name}</span>? */}
            {ModalMessages.CloseSeasonWeek(name)}
          </p>
          <div className='flex gap-2'>
            <SubmitButton onSubmit={() => trigger(id)} title='Submit' isLoading={isLoading} />
            <div
              onClick={cancel}
              className='p-4 px-8 text-center text-sm py-2 bg-white text-black border font-semibold rounded-lg hover:bg-gray-100 cursor-pointer'
            >
              Cancel
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
