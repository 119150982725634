import React, { useEffect, useState } from 'react';
import { useGetSingleMatchInfoQuery } from '../../../slices/matchApiSlice';
import { useNavigate, useParams } from 'react-router';
import * as component from '../components';
import { selectMatchData } from '../../../slices/matchSlice';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../slices/auth';
import { MatchStatus, UserRoles } from '../../../utils/constants';

const defaultBg = (await import('../../../assets/grid-background.jpg')).default;

export function ViewMatchHeader({}) {
  const matchData = useSelector(selectMatchData);
  const param = useParams();
  const { data, isLoading } = useGetSingleMatchInfoQuery(param.id);
  const navigate = useNavigate();
  function formatDateWithComma(dateString: any) {
    const date = new Date(dateString);
    const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const weekday = weekdays[date.getDay()];
    //kqyre diten a e kane krejt datat
    return `${weekday} ${month} ${day}, ${year}`;
  }

  const user = useSelector(selectUser);

  const [part, setPart] = useState<any>({
    home: false,
    away: false,
  });

  useEffect(() => {
    if (data) {
      const home_team = data?.home_team;
      const away_team = data?.away_team;

      if (user?.roles === UserRoles.Admin) {
        (home_team?.[0]?.isBenched || away_team?.[0]?.isBenched) &&
        data?.status === MatchStatus?.ByeMatch
          ? setPart((prevState: any) => ({ ...prevState, home: true, away: true }))
          : setPart((prevState: any) => ({ ...prevState, home: false, away: false }));
      }

      if (user?.roles === UserRoles.Ogma) {
        const homeOgmas = [
          home_team?.[0]?.organizations?.map((org: any) => org.user?.id),
          home_team?.[0]?.School?.organization?.user?.id,
          ...(home_team?.[0]?.organizations?.map((org: any) =>
            org.ogmas?.map((o: { id: number }) => o.id),
          ) ?? []),
          ...(home_team?.[0]?.School?.organization?.ogmas?.map((o: { id: number }) => o.id) ?? []),
        ].flat();

        const awayOgmas = [
          away_team?.[0]?.organizations?.map((org: any) => org.user?.id),
          away_team?.[0]?.School?.organization?.user?.id,
          ...(away_team?.[0]?.organizations?.map((org: any) =>
            org.ogmas?.map((o: { id: number }) => o.id),
          ) ?? []),
          ...(away_team?.[0]?.School?.organization?.ogmas?.map((o: { id: number }) => o.id) ?? []),
        ].flat();

        if (
          homeOgmas?.includes(user?.id) &&
          home_team?.[0]?.isBenched &&
          data?.status === MatchStatus?.ByeMatch
        ) {
          setPart((prevState: any) => ({ ...prevState, home: true }));
        }

        if (
          awayOgmas?.includes(user?.id) &&
          away_team?.[0]?.isBenched &&
          data?.status === MatchStatus?.ByeMatch
        ) {
          setPart((prevState: any) => ({ ...prevState, away: true }));
        }
      }

      if (user?.roles === UserRoles.Student) {
        const homePart =
          home_team?.[0]?.activeMembers?.includes(user?.id) ||
          home_team?.[0]?.subMembers?.includes(user?.id);

        const awayPart =
          away_team?.[0]?.activeMembers?.includes(user?.id) ||
          away_team?.[0]?.subMembers?.includes(user?.id);

        if (homePart && home_team?.[0]?.isBenched && data?.status === MatchStatus?.ByeMatch)
          setPart((prevState: any) => ({ ...prevState, home: true }));

        if (awayPart && away_team?.[0]?.isBenched && data?.status === MatchStatus?.ByeMatch)
          setPart((prevState: any) => ({ ...prevState, away: true }));
      }
    }

    return () => {};
  }, [data]);

  if (isLoading) return <p>Loading ...</p>;

  return (
    <div className='flex relative justify-between items-center rounded-tl-xl gap-5 rounded-tr-xl w-full  min-h-[150px] max-h-[150px] h-full bg-white overflow-hidden'>
      {!data?.season_week_id ? (
        <>
          <img
            className='object-contain absolute w-full  top-0 left-0'
            src={data?.TournamentRound?.Tournament?.banner_image ?? defaultBg}
          />
          <div className='p-10 z-10 flex flex-col bg-black bg-opacity-40 min-w-full h-[150px]'>
            <p className='text-xs font-bold text-white'>
              {data?.TournamentRound?.Tournament?.Game?.name}{' '}
            </p>
            <h2 className='font-bold text-white text-sm uppercase'>
              Tournament:{' '}
              <span
                onClick={() =>
                  navigate(`/tournaments/view/${data?.TournamentRound?.Tournament?.id}`)
                }
                className=' hover:border-b-1 hover:cursor-pointer text-white'
              >
                {data?.TournamentRound?.Tournament?.name}
              </span>
            </h2>
            <div className='flex gap-2'>
              <span className='text-white font-semibold'>
                {formatDateWithComma(
                  new Date(data?.TournamentRound?.Tournament?.start_date).toDateString(),
                )}{' '}
              </span>{' '}
              <span className='text-white font-semibold'>-</span>{' '}
              <span className='text-white font-semibold'>
                {formatDateWithComma(
                  new Date(data?.TournamentRound?.Tournament?.end_date).toDateString(),
                )}
              </span>
            </div>
            <div>
              {
                <component.MatchDetailsMatchStatus
                  status={part?.home || part?.away ? 9 : data?.status}
                />
              }
            </div>
          </div>
        </>
      ) : (
        <>
          <img
            className='object-contain absolute w-full top-0 left-0'
            src={data?.SeasonWeek?.Season?.banner_image}
          />
          <div className='p-10 z-10 flex flex-col bg-black bg-opacity-40 min-w-full h-[150px]'>
            <p className='text-xs font-bold text-white'>{data?.SeasonWeek?.Season?.Game?.name} </p>
            <h2 className='font-bold text-white text-sm uppercase'>
              Season:{' '}
              <span
                onClick={() => navigate(`/seasons/view/${data?.SeasonWeek?.Season?.id}`)}
                className=' hover:border-b-1 hover:cursor-pointer text-white'
              >
                {data?.SeasonWeek?.Season?.name}
              </span>
            </h2>
            <div className='flex gap-2'>
              <span className='text-white font-semibold'>
                {formatDateWithComma(new Date(data?.SeasonWeek?.Season?.start_date).toDateString())}
              </span>
              <span className='text-white font-semibold'>-</span>
              <span className='text-white font-semibold'>
                {formatDateWithComma(new Date(data?.SeasonWeek?.Season?.end_date).toDateString())}
              </span>
            </div>
            <div>
              {
                <component.MatchDetailsMatchStatus
                  status={part?.home || part?.away ? 9 : data?.status}
                />
              }
            </div>
          </div>
        </>
      )}
    </div>
  );
}
