import { Navigate, useParams } from 'react-router';
import { usePreviewClassroomQuery } from '../../slices/classroomApiSlice';
import { ViewClassroomHeader } from '../view-classroom/components/view-classroom-header';
import { ClassroomModel } from '../../model/classroom.model';
import { CustomError } from '../../utils/custom-types';

const PreviewClassroom = () => {
  const param = useParams();
  const { error, data, isLoading, isError, isSuccess } = usePreviewClassroomQuery(param.id ?? '');
  if (isLoading) return <p>Loading...</p>;
  if (isError)
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError).data.message }} />;

  const model = new ClassroomModel(data);
  return (
    <div className='listViewContainer fullPage'>
      <ViewClassroomHeader
        image={model.banner}
        title={model?.name}
        description={model.description}
      />
      <div className=' gap-5 flex flex-col w-full'>
        <h1>You don't have enough available hours to watch this classroom! </h1>
        {/* <h2 className='font-bold text-xs'>Lessons</h2>
        {!model.lessons.length && <p>There are no lessons in this classroom!</p>}
        {model.lessons.map((l, i: number) => (
          <div key={i}>
            <p>{l.content}</p>
            <ViewClassroomLessonRow key={i} banner={model.banner} lesson={l} preview />
          </div >
        ))} */}
      </div>
    </div>
  );
};

export default PreviewClassroom;
