import { SwitchGrade } from './../../../components/switch-grade/switch-grade';
import { useEffect, useState } from 'react';
import {
  useChangeUserProfilePhotoMutation,
  useToggleEligibilityMutation,
  useStudentProfileViewPermissionQuery,
} from '../../../slices/userApiSlice';
import { Navigate, useNavigate, useParams } from 'react-router';
import { AvatarImage } from '../../../components/avatar-image';
import { AiOutlineCamera } from 'react-icons/ai';
import { UserInfo } from './user-info';
import { FaSchool } from 'react-icons/fa';
import { GoArrowSwitch } from 'react-icons/go';
import CanIView from '../../../components/can-i-view/can-i-view';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../slices/auth';
import { UserModel } from '../../../features/listview/users/model/user.model';
import { UserRoles } from '../../../utils/constants';
import { CustomError } from '../../../utils/custom-types';

const ViewUserHeaderInfo = () => {
  const navigate = useNavigate();
  const [changeUserProfilePhoto, { isError, isLoading, isSuccess }] =
    useChangeUserProfilePhotoMutation();

  const user = useSelector(selectUser);
  const param = useParams();

  function handleFileChange(event: any) {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    changeUserProfilePhoto({ photo: formData, id: param.id });
  }

  const {
    data: userData,
    isLoading: userIsLoading,
    isError: userIsError,
    isSuccess: userIsSuccess,
    error,
    refetch,
  } = useStudentProfileViewPermissionQuery(param.id ?? user.id);
  const id = param.id ?? user.id;
  // const {
  //   isLoading: userIsLoading,
  //   data: userData,
  //   isError: userIsError,
  //   isSuccess: userIsSuccess,
  //   error,
  //   refetch,
  // } = useUserHeaderInfobyUserIdQuery(id);

  // const { data: spData, isLoading: spLoading } = useStudentProfileViewPermissionQuery(
  //   param.id ?? user.id,
  // );
  useEffect(() => {
    navigate('./overview');
  }, []);

  const [model, setModel] = useState<UserModel>();
  useEffect(() => {
    if (!userData) return;
    console.log(userData);
    setModel(new UserModel(userData));
  }, [userData?.eligibilityStatus]);
  const [toggleEligibility, { isLoading: tgIsLoading }] = useToggleEligibilityMutation();
  const canEdit = () => {
    switch (user?.roles) {
      case UserRoles.Admin:
        return true;
      case UserRoles.Ogma:
        return (
          user?.ogma_org?.filter((org: any) => org.id === userData.school.organization_id).length >
          0
        );
      case UserRoles.Student:
        return user?.id === userData?.id;
      default:
        return false;
    }
  };
  if (userIsLoading || !model) return <p>Loading ...</p>;
  if (userIsError) {
    return <Navigate to='/dashboard' state={{ message: (error as CustomError).data.message }} />;
  }
  // const model = new UserModel(userData);
  return (
    <div className='p-10 z-10 flex justify-between items-center bg-black bg-opacity-40 min-w-full h-[150px] pointer-events-none'>
      <div className='flex gap-2 items-center'>
        <div className='group flex relative w-[100px] aspect-square rounded-full overflow-hidden justify-center items-center pointer-events-auto '>
          <AvatarImage size='xxl' source={userData ? userData?.data?.image : null} />
          {canEdit() && (
            <label className='cursor-pointer text-white  w-full bg-black bg-opacity-80 h-full absolute top-0 right-0 left-0 bottom-0 m-auto z-10 hidden group-hover:flex justify-center items-center  '>
              <AiOutlineCamera />
              {!userData?.readPermission ? (
                <input
                  type='file'
                  accept='image/*'
                  onChange={handleFileChange}
                  className='hidden mr-12'
                />
              ) : null}
            </label>
          )}
        </div>
        <div>
          <UserInfo name={userData?.firstName ?? userData?.username} />
          <div className='flex flex-col'>
            {userData.school && (
              <div className='flex items-center mb-3'>
                <FaSchool />
                {/* <Link
              to={`/schools/view/${userData && userData.school.id}`}> */}
                <h3 className='ml-2 text-white'>{userData.school.name}</h3>
                {/* </Link> */}
              </div>
            )}
            <div className='flex gap-2 pointer-events-auto items-center'>
              {'eligibilityStatus' in userData ? (
                <>
                  <a
                    // className="bg-green-700 text-xs text-white py-1 px-6 rounded"
                    className={`${
                      userData?.eligibilityStatus === 1 ? 'bg-green-700' : 'bg-red-700'
                    } text-xs text-white py-1 px-6 rounded items-center`}
                  >
                    {userData?.eligibilityStatus === 1
                      ? 'Eligible to participate'
                      : 'Not Eligible to participate'}
                  </a>
                  <CanIView component='user.gradeLevel'>
                    {param?.id ? (
                      <></>
                    ) : (
                      <div className='btn edit items-center'>{model.gradeLevel}</div>
                    )}
                  </CanIView>
                </>
              ) : (
                <></>
              )}
              {user?.roles === UserRoles.Admin ||
              (user?.roles === UserRoles.Ogma && !userData?.readPermission) ? (
                <>
                  <div
                    className='btn edit items-center'
                    onClick={() => toggleEligibility(id).then(() => refetch())}
                  >
                    <GoArrowSwitch className={tgIsLoading ? 'animate-spin' : ''} />
                  </div>
                  <SwitchGrade />
                </>
              ) : null}
              {/* <CanIView component='user.switchstatus'>
                <div
                  className='btn edit items-center'
                  onClick={() => toggleEligibility(param.id ?? '')}
                >
                  <GoArrowSwitch className={tgIsLoading ? 'animate-spin' : ''} />
                </div>
              </CanIView>
              <CanIView component='user.switchstatus'>
                <SwitchGrade />
              </CanIView> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewUserHeaderInfo;
