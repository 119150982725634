import { Outlet } from 'react-router-dom';
import AvailableGames from './AvailableGames';
import MostPlayedGames from './MostPlayedGames';

const GameOverview = () => {
  return (
    <>
      <div className='m-4'>
        <MostPlayedGames title={'Most Played Games'} />
        <div className='grid lg:grid-cols-4 md:grid-cols-1 sm:grid-cols-1 lg:gap-4 md:gap-0 sm:gap-0'>
          <AvailableGames title={'Available Games'} />
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default GameOverview;
