import React from 'react';
import noNotification from '../../../assets/notification.svg';
const NoNotifications = () => {
  return (
    <>
      <div className='flex flex-col items-center gap-y-7 pt-24'>
        <img src={noNotification} alt='noNotification' />

        <h2 className='text-gray-500 font-bold text-xl'> No Notifications</h2>

        <p className='font-medium text-sm text-gray-700 '>Your notification will show up here</p>
      </div>
    </>
  );
};

export default NoNotifications;
