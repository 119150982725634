import { useEffect, useState } from 'react';
import { NameAndAvatar } from '../../../../../../components/name-and-avatar';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../../../slices/auth';
import { UserRoles } from '../../../../../../utils/constants';
export function TeamStatHeader({
  gameName,
  gameImage,
  teamData,
}: {
  gameName: any;
  gameImage: any;
  teamData: any;
}) {
  const user = useSelector(selectUser);
  const [part, setPart] = useState<boolean>(false);

  useEffect(() => {
    if (teamData) {
      if (user?.roles === UserRoles.Student) {
        const partOfTeam =
          teamData?.activeMembers?.includes(user?.id) || teamData?.subMembers?.includes(user?.id);
        if (partOfTeam) setPart(true);
      }
      if (user?.roles === UserRoles.Ogma) {
        const partOfTeam = [
          teamData?.organizations?.map((org: any) => org.user?.id),
          teamData?.School?.organization?.user?.id,
          ...(teamData?.organizations?.map((org: any) =>
            org.ogmas?.map((o: { id: number }) => o.id),
          ) ?? []),
          ...(teamData?.School?.organization?.ogmas?.map((o: { id: number }) => o.id) ?? []),
        ].flat();

        if (partOfTeam?.includes(user?.id)) setPart(true);
      }
    }
  }, []);

  return (
    <div className='flex items-center justify-between gap-2 px-4 py-4 border rounded-xl'>
      {/* <h2>Game</h2> */}
      <NameAndAvatar name={gameName} image={gameImage} />
      {user?.roles === UserRoles.Admin && teamData?.isBenched ? (
        <div>
          <span className='text-[#B50303] font-[600] font-[Inter] text-sm'>BENCHED TEAM</span>
        </div>
      ) : (user?.roles === UserRoles.Ogma || user?.roles === UserRoles.Student) &&
        teamData?.isBenched &&
        part ? (
        <div>
          <span className='text-[#B50303] font-[600] font-[Inter] text-sm'>BENCHED TEAM</span>
        </div>
      ) : null}
    </div>
  );
}
