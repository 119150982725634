import * as yup from 'yup';
import { Formik } from 'formik';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useGetNewTRequiredDataQuery } from '../../../../slices/tournamentApiSlice';
import { useCreateSubDivisionMutation } from '../../../../slices/subDivisionApiSlice';
import { selectUser } from '../../../../slices/auth';
import { OutsideClick } from '../../../../components/outside-click';
import ModalContent from '../../../../components/modal-content';
import { NewSubDivisionValidationSchema } from '../../../../features/authentication/yupschema';

function NewSubDivisions() {
  const [avatarImage, setAvatarImage] = useState<any>();
  const { data: tRequiredData, isLoading: isDataLoading } = useGetNewTRequiredDataQuery('');
  const [image, setImage] = useState<any>();

  const formInputs = [
    {
      mainTitle: 'Subdivision Information',
      name: 'division_id',
      label: 'Category Division Name',
      type: 'text',
      placeholder: 'Enter category division name',
      isTrue: true,
      isSelect: true,
      options: tRequiredData?.division ? tRequiredData.division : [],
    },
    {
      mainTitle: 'Subdivision Information',
      name: 'name',
      label: 'Subdivision Name',
      type: 'text',
      placeholder: 'Enter subdivision name',
      isTrue: true,
    },
    {
      mainTitle: 'Subdivision Information',
      name: 'background_image',
      label: 'Background Image',
      type: 'image',
      placeholder: 'Upload background image',
      isTrue: true,
    },
  ];
  const [createSubDivision, { isError, isLoading, isSuccess }] = useCreateSubDivisionMutation();
  const user = useSelector(selectUser);

  const onSubmit = (values: any) => {
    const data = values;
    data.image = avatarImage;
    data.user_id = user.id;
    data.division_id = +data.division_id;

    console.log('data=', data);


    const formData = new FormData();
    Object.entries(data).forEach(([key, value]: any[]) => {
      if (value)
        formData.append(key, value);
    });

    createSubDivision(formData)
      .unwrap()
      .then((res) => console.log(res));
  };

  const initialValues: { [key: string]: string } = {};
  formInputs.forEach((input) => (initialValues[input.name] = ''));

  if (isLoading) return <p>Loading ...</p>;
  if (isSuccess) return <Navigate to='../' />;
  return (
    <>
      <OutsideClick />
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={NewSubDivisionValidationSchema.shape({
          background_image: yup
            .mixed()
            .nullable()
            .test(
              'test2',
              'Invalid image format. Only JPG, PNG, and JPEG formats are allowed.',
              () => {
                const acceptedFormats = ['image/jpeg', 'image/png', 'image/jpg'];
                if (avatarImage && !acceptedFormats.includes(avatarImage?.type ?? '')) {
                  return false;
                }
                return true;
              },
            ),
        })}>
        {({ errors }) => {
          return <ModalContent errors={Object.keys(errors).length} setAvatarImage={setAvatarImage} formInputs={formInputs} isLoading={isLoading} extraClass='max-h-[90vh]' name="New Subdivision" />;
        }}
      </Formik>
    </>
  );
}

export default NewSubDivisions;