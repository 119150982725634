import { useEffect, useState } from 'react';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import { useSearchParams } from 'react-router-dom';

const Pagination = ({
  length,
  total,
  currentPage,
  lastPage,
  prev,
  next,
}: {
  length: number;
  total: number;
  currentPage: number;
  lastPage: number;
  prev: any;
  next: any;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState<number | null | string>(currentPage);

  function goPrevPage() {
    searchParams.set('page', prev);
    setSearchParams(searchParams);
    setValue(prev);
  }
  function goNextPage() {
    searchParams.set('page', next);
    setSearchParams(searchParams);
    setValue(next);
  }
  useEffect(() => {
    if (!value || !Number(value) || value === currentPage) return;

    const debounce = setTimeout(() => {
      if (Number(value) <= 0 || Number(value) > lastPage) {
        setValue(currentPage ?? 1);
        return;
      }

      searchParams.set('page', Number(value).toString());
      setSearchParams(searchParams);
    }, 500);
    return () => clearTimeout(debounce);
  }, [value]);

  // if (!length) return <></>

  return (
    <div className='flex p-5 w-full justify-between'>
      <span>
        {length} of {total} results
      </span>
      <div className='flex gap-2 items-center '>
        <span>
          {currentPage} of {lastPage === 0 ? 1 : lastPage}
        </span>
        <div className='cursor-pointer' onClick={prev ? goPrevPage : () => null}>
          <BsArrowLeft color={prev ? '#b50303' : 'grey'} />
        </div>
        <input
          size={value?.toString()?.length}
          type='text'
          className={`resetInput text-center w-[30px] remove-arrow`}
          value={value ?? ''}
          min={1}
          max={lastPage}
          // onClick={() => setValue(null)}
          onChange={(e) => setValue(+e.target.value)}
          onFocus={() => setValue(null)}
          // onPointerLeave={(e) => setValue(currentPage)}
          // onMouseLeave={() => setValue(currentPage)}
          onBlur={() => setValue(currentPage)}
        />

        <div className='cursor-pointer' onClick={next ? goNextPage : () => null}>
          <BsArrowRight color={next ? '#b50303' : 'grey'} />
        </div>
      </div>
    </div>
  );
};

export default Pagination;
