import React from 'react';
import { StudentDashboardClassrooms } from './components/student-dashboard-classrooms';
import { StudentDashboardCoaches } from './components/student-dashboard-coaches';
import { StudentDashTeamRow } from './components/student-dash-team-row';
import { StudentDashUpMatches } from './components/student-dash-up-matches';
import { UserHeader } from '../../components/user-header';
import { useSelector } from 'react-redux';
import { selectUser, selectUserSchool, selectUserTeam } from '../../slices/auth';
import { SponsorRow } from '../../components/sponsor-row/sponsor-row';
import { SponsorPlacements } from '../../utils/constants';
import { StudentDashLiveMatches } from './components/student-dash-live-matches';

function StudentDashboard() {
  const team = useSelector(selectUserTeam);
  const user = useSelector(selectUser);
  const school = useSelector(selectUserSchool);
  console.log('team', team);

  return (
    <>
      <div className='flex flex-col p-10 gap-10'>
        <SponsorRow name={SponsorPlacements.Dashboard} />
        <div className='flex flex-col gap-10'>
          <div className='flex flex-col gap-5'>
            <UserHeader
              lastName={user.lastName}
              name={user.firstName}
              school={school ? school.name : ''}
              schoolId={school ? school.id : ''}
              image={user.image}
              id={user.id}
              username={user.username}
            />
            <StudentDashLiveMatches />
            <StudentDashUpMatches />
          </div>
          <div className='flex justify-center gap-10 flex-wrap'>
            <div className='flex flex-col items-center gap-5 realtive w-full sm:w-[100%] lg:w-[30%] border-1 rounded-xl border-slate-300'>
              <h2 className='font-bold text-lg pt-5'>Your Teams</h2>
              <div className='flex flex-col w-full bg-white text-white  rounded-xl max-w-[100%] min-h-[300px]'>
                {team?.length !== 0 ? (
                  team?.map((t: any, i: number) => (
                    <React.Fragment key={i}>
                      <StudentDashTeamRow
                        schoolId={t.school?.id ?? 1}
                        name={t.teamName}
                        school={t.school?.name ?? ''}
                        id={t.id}
                        image={t.image}
                      />
                    </React.Fragment>
                  ))
                ) : (
                  <p className='flex  justify-center'>You are not part of any team!</p>
                )}
              </div>
            </div>
            <StudentDashboardClassrooms />
            <StudentDashboardCoaches />
          </div>
        </div>
      </div>
    </>
  );
}

export default StudentDashboard;
