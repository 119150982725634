import React from 'react'
import { ScheduleModel } from '../../../../../model/schedule.model'
import { AvatarImage } from '../../../../../components/avatar-image'



const TournamentScheduleView = ({ scheduleModel }: { scheduleModel: ScheduleModel }) => {
    return (
        <div className="grid grid-cols-5 gap-2 p-4 border rounded-xl items-center justify-between hover:cursor-pointer hover:bg-gray-100">

            <div className='flex items-center gap-2 col-span-2'>
                <AvatarImage source={scheduleModel.image ? scheduleModel.image : ''} size={'xl'} />
                <h1>{scheduleModel.name}</h1>
            </div>
            <p className='font-medium'>Team size: {scheduleModel.team_size}</p>
            <p className='font-medium'>{scheduleModel.game}</p>
            <div>
                <p className='font-medium text-right'>{scheduleModel.start_date}</p>
                <p className='font-medium text-right'>{scheduleModel.end_date}</p>
            </div>
        </div>
    )
}

export default TournamentScheduleView