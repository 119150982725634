import React from 'react';
import { useState, useEffect } from 'react';

export function ClassRoomCardLoadingSceleton() {
  return (
    <div
      className={
        'skeleton__pulse w-[300px] min-h-[300px] h-[300px] items-center rounded-lg shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] bg-slate-200'
      }
    ></div>
  );
}
