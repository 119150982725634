import { Outlet } from 'react-router';
import Pagination from '../../components/pagination';
import TableTd from '../../components/table-td/table-td';
import { TableHead } from '../../components/table-head';
import TableContainer from '../../components/table-container/table-container';
import { LoadingSceletonArchived } from '../tournaments/routes/all/loading-sceleton-archived';
import { useSelector } from 'react-redux';
import { selectUser } from '../../slices/auth';
import { Link, useSearchParams } from 'react-router-dom';
import { useMassDeleteMutation, useMassUnArchiveMutation } from '../../slices/unarchiveApiSlice';
import { UserRoles } from '../../utils/constants';
import { useGetAllSupportCategoryQuery } from '../../slices/supportCategoryApiSlice';
import React, { useState } from 'react';
import ShowAllSubCategories from './show-all-subcategories';
import AreYouSure from '../archived/routes/user/components/are-you-sure';
import useErrorToast from '../../hooks/use-error-toast';
import useSuccessToast from '../../hooks/use-success-toast';
import { dateToLocal } from '../../utils/dateFormatter';
import useCloseAreYouSure from '../../hooks/use-close-are-you-sure';

const UnArchiveCategory = () => {
  const user = useSelector(selectUser);
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, isLoading } = useGetAllSupportCategoryQuery({
    page: searchParams.get('page') ? searchParams.get('page') : 1,
    isArchived: '1',
  });
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isArchiveDialogOpen, setArchiveDialogOpen] = useState(false);

  const [checked, setChecked] = useState<number[]>([]);
  if (data) {
    console.log('unarchive categories', data);
  }
  const [
    massDelete,
    {
      isSuccess: deleteIsSuccess,
      isLoading: deleteIsLoading,
      isError: deleteIsError,
      error: deleteError,
    },
  ] = useMassDeleteMutation();
  const [
    massUnArchive,
    {
      isSuccess: unarchiveIsSuccess,
      isLoading: unarchiveIsLoading,
      isError: unarchiveIsError,
      error: unarchievError,
    },
  ] = useMassUnArchiveMutation();

  const listView = [
    {
      name: '',
      active: true,
      prop: '',
    },
    {
      name: 'Name',
      active: true,
      prop: 'name',
    },
    {
      name: 'Subcategories',
      active: true,
      prop: 'subcategories',
    },
    {
      name: 'Created Date',
      active: true,
      prop: 'createdAt',
    },
    {
      name: 'Archived Date',
      active: true,
      prop: 'updatedAt',
    },
    {
      name: 'Id',
      active: true,
      prop: 'id',
    },
  ];

  const [modelId, setModelId] = useState<any>();

  const handleShowSubCategories = (subcategories: any) => {
    setModelId(subcategories);
  };

  const handleDelete = () => {
    massDelete({
      model: 'supportCategory',
      ids: [...new Set(checked)],
    });
  };

  const handleArchive = () => {
    massUnArchive({
      model: 'supportCategory',
      ids: [...new Set(checked)],
    });
  };

  useCloseAreYouSure({
    archiveIsError: unarchiveIsError,
    archiveIsSuccess: unarchiveIsSuccess,
    deleteIsSuccess,
    deleteIsError,
    setDeleteDialogOpen,
    setArchiveDialogOpen,
    setChecked,
  });

  useSuccessToast(deleteIsSuccess || unarchiveIsSuccess, 'Success');
  useErrorToast(deleteIsError, deleteError);
  useErrorToast(unarchiveIsError, unarchievError);
  if (isLoading) return <LoadingSceletonArchived />;

  return (
    <div className='flex flex-col flex-1 gap-3  '>
      <div className=' flex justify-between gap-2 items-center  '>
        <div className='flex gap-3 items-center pl-10'>
          {data?.data?.length ? (
            <>
              <input
                type='checkbox'
                style={{ width: '15px', height: '15px' }}
                checked={data?.data?.every((i: any) => checked.includes(i.id)) && checked.length}
                onChange={() =>
                  data?.data?.every((i: any) => checked.includes(i.id))
                    ? setChecked(checked.filter((id) => !data?.data.find((d: any) => d.id === id)))
                    : setChecked([...checked, ...(data?.data?.map((item: any) => item.id) || [])])
                }
              />
              <p className='text-base font-body'>Select All</p>
            </>
          ) : (
            <></>
          )}
        </div>
        <div className='flex  items-center gap-5'>
          {checked.length && data?.data?.length ? (
            <button onClick={() => setArchiveDialogOpen(true)} className='btn red '>
              Unarchive
            </button>
          ) : (
            <></>
          )}
          {checked.length && data?.data?.length ? (
            <button onClick={() => setDeleteDialogOpen(true)} className='btn red '>
              Delete
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className='listViewContainer w-full border flex-1 overflow-x-auto'>
        <div className='flex flex-col flex-1 justify-between'>
          {modelId ? (
            <ShowAllSubCategories
              setModelId={setModelId}
              subCategories={modelId}
              title='All Subcategories'
            />
          ) : (
            <></>
          )}
          <TableContainer extraClass='overflow-auto'>
            <TableHead
              oneMore={user.roles === UserRoles.Admin}
              tableList={listView
                .map((l) => l.name)
                .filter(
                  (t) => listView.find((d) => d.name.toLowerCase() === t.toLowerCase())?.active,
                )}
            />
            <tbody className='bg-white divide-y divide-gray-200 w-full'>
              {data?.data?.map((d: any, i: number) => (
                <tr key={i}>
                  <TableTd>
                    <input
                      type='checkbox'
                      style={{ width: '15px', height: '15px' }}
                      onChange={() =>
                        checked.includes(d.id)
                          ? setChecked((s) => s.filter((id) => id !== d.id))
                          : setChecked((s) => [...s, d.id])
                      }
                      checked={checked.includes(d.id)}
                    />
                  </TableTd>
                  {listView
                    .filter((l) => l.active)
                    .map((o, i) => {
                      if (o.prop === 'subcategories') {
                        return (
                          <TableTd key={o.prop}>
                            {d?.subcategories?.length === 1 ? (
                              <p>{d?.subcategories[0]?.name}</p>
                            ) : d?.subcategories?.length > 1 ? (
                              <div onClick={() => handleShowSubCategories(d?.subcategories)}>
                                Show All
                              </div>
                            ) : (
                              <p>-</p>
                            )}
                          </TableTd>
                        );
                      } else if (o.prop === 'createdAt') {
                        return (
                          <TableTd key={o.prop} extraClass=''>
                            {dateToLocal(d[o.prop], 'MMMM D, YYYY')}
                          </TableTd>
                        );
                      } else if (o.prop === 'updatedAt') {
                        return (
                          <TableTd key={o.prop} extraClass=''>
                            {dateToLocal(d[o.prop], 'MMMM D, YYYY')}
                          </TableTd>
                        );
                      } else if (o.prop !== '') {
                        return (
                          <TableTd key={o.prop} extraClass=''>
                            {d[o.prop]}
                          </TableTd>
                        );
                      }
                      return <React.Fragment key={o.prop} />;
                    })}
                  <TableTd>
                    <Link to={`./unarchive/${d?.id}/${d?.name}`}>
                      <button className='btn red '>Unarchive</button>
                    </Link>
                  </TableTd>
                </tr>
              ))}
            </tbody>
          </TableContainer>
          <Outlet />
          <Pagination length={data?.data?.length} {...data?.meta} />
        </div>
      </div>
      {isDeleteDialogOpen && (
        <AreYouSure
          handleCloseDeleteDialog={setDeleteDialogOpen}
          handleDelete={handleDelete}
          isLoading={deleteIsLoading}
          text='delete selected categories'
        />
      )}
      {isArchiveDialogOpen && (
        <AreYouSure
          handleCloseDeleteDialog={setArchiveDialogOpen}
          handleDelete={handleArchive}
          isLoading={unarchiveIsLoading}
          text='unarchive selected categories'
        />
      )}
    </div>
  );
};

export default UnArchiveCategory;
