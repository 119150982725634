import { useParams } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import {
  useArchiveTournamentMutation,
  useGetSingleTournamentInfoQuery,
} from '../../../../../../slices/tournamentApiSlice';
import ModalArchive from '../../../../../../components/modal-archive';
import { CustomError } from '../../../../../../utils/custom-types';

function ArchiveTournament() {
  const param = useParams();
  const {
    error,
    data: tournamentData,
    isLoading: tournamentIsLoading,
    isSuccess: tournamentIsSuccess,
    isError: tournamentIsError,
  } = useGetSingleTournamentInfoQuery(param.id);
  const [archiveTournament, { data, isSuccess, isError, isLoading }] =
    useArchiveTournamentMutation();

  const onSubmit = () => {
    archiveTournament(param.id)
      .unwrap()
      .then((res) => console.log(res))
      .catch((e) => console.log(e));
  };
  if (tournamentIsError || (tournamentIsSuccess && !tournamentData))
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError).data.message }} />;
  if (tournamentIsLoading) return <p>Loading...</p>;
  if (isSuccess) return <Navigate to='../' />;
  return <ModalArchive onSubmit={onSubmit} isLoading={isLoading} name={tournamentData.name} />;
}

export default ArchiveTournament;
