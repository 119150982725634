import React, { useState } from 'react';
import LiveMatchesCard from './live-matches-card';
import { IoIosArrowDown } from 'react-icons/io';
import { useSelector } from 'react-redux';
import { selectLiveVideos } from '../../../slices/gamearenaSlice';

const GameArenaLiveMatches = () => {
  const [count, setCount] = useState(4);
  const data = useSelector(selectLiveVideos);

  return (
    <>
      <div className='flex flex-col mx-4'>
        <h1 className='font-bold gap-y-1 ml-2'>Live Matches</h1>
        <div className='grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-2'>
          {data.slice(0, count).map((tw: any) => (
            <LiveMatchesCard key={tw.id} {...tw} source={tw.thumbnail_url} />
          ))}
        </div>
        <div className='mt-10 mb-5 w-full'>
          <div className='mt-1 grid grid-cols-5 items-center text-gray-400'>
            <hr className='border-gray-400 col-span-2' />
            <button
              className='flex gap-2 justify-center items-center text-center text-sm cursor-default'
              onClick={() => setCount(count + 4)}
            >
              {count >= data.length ? (
                <div className=' '> No More To Show</div>
              ) : (
                <div className='flex items-center gap-2 cursor-pointer'>
                  Show More <IoIosArrowDown />
                </div>
              )}
            </button>
            <hr className='border-gray-400 col-span-2' />
          </div>
        </div>
      </div>
    </>
  );
};

export default GameArenaLiveMatches;
