import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router'
import UnArchiveOptionContainer from './components/archive-option-container';
import { useSelector } from 'react-redux';
import { selectUser } from '../../slices/auth';
import { UserRoles } from '../../utils/constants';

const Unarchive = () => {
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  useEffect(() => {
    if (user.roles === UserRoles.Coach) {
      navigate('./classroom')
    }
    else {
      navigate('./user');
    }
  }, []);
  return (
    <div className='listViewContainer fullPage'>
      <div className='pb-[3rem]'>
        <UnArchiveOptionContainer />
      </div>
      <Outlet />
    </div>
  )
}

export default Unarchive