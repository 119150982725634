import { FaArchive } from 'react-icons/fa';
import { Socket, io } from 'socket.io-client';
import { selectToken, selectUser } from '../../slices/auth';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../store';
import { SocketActions } from '../../utils/constants';

const DeleteTeamMessage = ({ msgId, room }: { msgId: any; room?: any }) => {
  const token = useSelector(selectToken);
  const param = useParams();
  const user = useSelector(selectUser);
  // const [socket, setSocket] = useState<Socket>();
  // const url = import.meta.env.VITE_API_URL;

  // useEffect(() => {
  //     if (socket) return;
  //     setSocket(io(url ?? '', { extraHeaders: { token } }));
  // }, []);
  const dispatch = useAppDispatch();
  const handleDeleteChatMessage = () => {
    console.log('clicking??', room);
    // room: 'm70-t3',
    if (msgId && room) {
      const payload = {
        room: `m${param.id}-t${room}`,
        userRole: user.roles,
        id: msgId,
      };
      console.log('payload for delete sent', payload);
      dispatch({ type: SocketActions.DeleteTeamMessage, payload });
      // socket?.emit('delete-chat-msg-match-team', JSON.stringify(payload));
    }
  };

  return (
    <>
      <div>
        <div className={'flex items-center absolute z-10 shadow-slate-100 border-2'}>
          <div
            className='w-[100px] bg-white p-2 text-center flex items-center cursor-pointer'
            onClick={handleDeleteChatMessage}
          >
            <FaArchive />
            <span className='ml-2'>Delete</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteTeamMessage;
