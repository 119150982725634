import { Link, Navigate, useParams } from 'react-router-dom';
import { OutsideClick } from '../../../components/outside-click';
import { AiOutlineClose } from 'react-icons/ai';
import { SubmitButton } from '../../../components/submit-button';
import { useUnForfeitMatchMutation } from '../../../slices/matchApiSlice';
import { CustomError } from '../../../utils/custom-types';

const UnforfeitMatch = () => {
  const param = useParams();
  const [unForfeitMatch, { isLoading, isSuccess, isError, error }] = useUnForfeitMatchMutation();

  const onSubmit = () => {
    return unForfeitMatch({
      id: Number(param?.id),
    });
  };

  if (isSuccess) {
    return <Navigate to='../' />;
  }

  return (
    <>
      <OutsideClick />
      <div className='fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-10 w-auto mx-auto max-w-2xl bg-white p-10 flex flex-col gap-10 items-center justify-center rounded-xl'>
        <div className='absolute top-2 right-2'>
          <Link to='../' className=''>
            <AiOutlineClose size={'2rem'} className='hover:rounded-full hover:bg-gray-100 p-2' />
          </Link>
        </div>
        <div className='py-4 flex flex-col justify-center items-center gap-8'>
          <p className='select-none font-medium text-center'>
            Are you sure that you want to unforfeit this match?
          </p>
          <div className='flex gap-2'>
            <SubmitButton onSubmit={onSubmit} title='Yes' isLoading={isLoading} />
            <Link
              to='../'
              className='p-4 px-8 text-center text-sm py-2 bg-white text-black border font-semibold rounded-lg hover:bg-gray-100'
            >
              No
            </Link>
          </div>
        </div>
        {isError ? (
          <p>{(error as CustomError)?.message ?? (error as CustomError)?.data?.message}</p>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default UnforfeitMatch;
