import React from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import {
  useArchiveClassroomMutation,
  useGetClassroomByIdQuery,
} from '../../../../slices/classroomApiSlice';
import { OutsideClick } from '../../../../components/outside-click';
import { Form, Formik } from 'formik';
import { SubmitButton } from '../../../../components/submit-button';
import ModalArchive from '../../../../components/modal-archive';
import { CustomError } from '../../../../utils/custom-types';

const ArchiveClassroom = () => {
  const params = useParams();
  const {
    error,
    data: classroomData,
    isSuccess: classroomIsSuccess,
    isLoading: classroomIsLoading,
    isError: classroomIsError,
  } = useGetClassroomByIdQuery(params.cId);
  const [archiveClassroom, { data, isSuccess, isError, isLoading }] = useArchiveClassroomMutation();
  const onSubmit = () => {
    archiveClassroom(params.cId)
      .unwrap()
      .then((res) => console.log(res))
      .catch((e) => console.log(e));
  };
  if (classroomIsError || (classroomIsSuccess && !classroomData))
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError).data.message }} />;
  if (isSuccess) return <Navigate to='../' />;
  if (classroomIsLoading) return <p>Loading...</p>;
  return <ModalArchive onSubmit={onSubmit} isLoading={isLoading} name={classroomData[0].name} />;
};

export default ArchiveClassroom;
