import { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import {
  useGetNewInvoiceHelpDataQuery,
  useGetSchoolInvoiceByIdQuery,
  useUpdateSchoolInvoiceMutation,
} from '../../../../../../slices/invoicesApiSlice';
import { OutsideClick } from '../../../../../../components/outside-click';
import { Formik } from 'formik';
import { InvoiceStatus, InvoiceStatusNames, InvoiceTypes } from '../../../../../../utils/constants';
import { editOrgInvoiceSchema } from './schema/edit-school-invoice.schema';
import ModalContent from '../../../../../../components/modal-content';
import { CustomError } from '../../../../../../utils/custom-types';
import { addCurrentTimeToDate, dateToLocal } from '../../../../../../utils/dateFormatter';

const EditSchoolInvoice = () => {
  const [chosenSchool, setChosenSchool] = useState<number>();

  const param = useParams();
  const {
    error,
    data: invoiceData,
    isLoading: invoiceIsLoading,
    isError: invoiceIsError,
    isSuccess: invoiceIsSuccess,
  } = useGetSchoolInvoiceByIdQuery(param.id);

  const { data: iHelpData, isLoading: isDataLoading } = useGetNewInvoiceHelpDataQuery('');

  const [updateInvoice, { isLoading, isSuccess, isError }] = useUpdateSchoolInvoiceMutation();

  useEffect(() => {
    if (!invoiceData) return;
  }, [invoiceData]);

  const formInputs = [
    {
      mainTitle: 'Invoice Information',
      name: 'start_date',
      label: 'Invoice date',
      type: 'date',
      placeholder: 'Enter date',
      isTrue: true,
    },
    {
      mainTitle: 'Invoice Information',
      name: 'end_date',
      label: 'Invoice due date',
      type: 'date',
      placeholder: 'Enter invoice due date',
      isTrue: true,
    },
    {
      mainTitle: 'Invoice Information',
      name: 'status',
      label: 'Invoice status',
      type: 'number',
      placeholder: 'Enter invoice status',
      isTrue: true,
      isSelect: true,
      options: [
        { id: InvoiceStatus.Upcoming, name: InvoiceStatusNames[InvoiceStatus.Upcoming] },
        { id: InvoiceStatus.Paid, name: InvoiceStatusNames[InvoiceStatus.Paid] },
        { id: InvoiceStatus.Overdue, name: InvoiceStatusNames[InvoiceStatus.Overdue] },
        { id: InvoiceStatus.Unpaid, name: InvoiceStatusNames[InvoiceStatus.Unpaid] },
        { id: InvoiceStatus.Processing, name: InvoiceStatusNames[InvoiceStatus.Processing] },
      ],
    },
    {
      mainTitle: 'Invoice Information',
      name: 'total',
      label: 'Total',
      type: 'number',
      placeholder: 'Enter total',
      isTrue: true,
    },
    {
      mainTitle: 'Invoice Information',
      name: 'coach_hours',
      label: 'Coach Hours',
      type: 'number',
      placeholder: 'Enter coach hours',
      isTrue: true,
      invoiceType: [InvoiceTypes.CoachingHours],
    },
  ];

  const onSubmit = (values: any) => {
    // console.log('values',values)
    // return;
    const data = { ...values };
    data.school = +data.school;
    // data.start_date = new Date(data.start_date).toISOString();
    // data.end_date = new Date(data.end_date).toISOString();
    data.start_date = addCurrentTimeToDate(data.start_date);
    data.end_date = addCurrentTimeToDate(data.end_date);
    data.status = +data.status;
    data.type = +data.type;
    delete data.id;

    updateInvoice({ id: param.id, body: data })
      .unwrap()
      .then((res: any) => { })
      .catch((error) => console.log(error));
  };
  const [invoiceType, setInvoiceType] = useState(9);
  useEffect(() => {
    if (!invoiceData) return;
    setInvoiceType(invoiceData.type);
  }, [invoiceData]);

  const initialValues: { [key: string]: string } = {};
  formInputs.forEach((input) => (initialValues[input.name] = ''));
  if (invoiceIsError || (invoiceIsSuccess && !invoiceData))
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError).data.message }} />;
  if (invoiceIsLoading || isLoading || isDataLoading) return <p>Loading ...</p>;
  if (isSuccess) return <Navigate to='../' />;
  return (
    <>
      <OutsideClick />
      <Formik
        validationSchema={editOrgInvoiceSchema}
        initialValues={{
          ...invoiceData,
          // organization: invoiceData.school.organization.id.toString(),
          // school: invoiceData.school.id.toString(),
          start_date: dateToLocal(invoiceData.start_date)?.split('T')[0] ?? new Date(),
          end_date: dateToLocal(invoiceData.end_date)?.split('T')[0] ?? new Date(),
        }}
        onSubmit={onSubmit}
      >
        {(formik) => {
          setChosenSchool(+formik.values.organization);
          setInvoiceType(+formik.values.type ?? invoiceData.type);
          return (
            <ModalContent
              formInputs={formInputs}
              inputFilter={(inp: any) =>
                inp.hasOwnProperty('invoiceType') ? inp?.invoiceType?.includes(invoiceType) : true
              }
              isLoading={isLoading}
              extraClass='h-fit'
              name={'Edit Invoice'}
            />
          );
        }}
      </Formik>
    </>
  );
};

export default EditSchoolInvoice;
