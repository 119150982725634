import { ResultScreenshotRow } from '../edit-match-results/component/result-screenshot-row';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { Navigate, useParams } from 'react-router-dom';
import { AvatarImage } from '../../../components/avatar-image';
import { OutsideClick } from '../../../components/outside-click';
import {
  useCloseGameMatchMutation,
  useLazyGetSingleMatchInfoQuery,
} from '../../../slices/matchApiSlice';
import ModalHeader from '../../../components/modal-header';
import ModalFooter from '../../../components/modal-footer';
import { CustomError } from '../../../utils/custom-types';

const SubmitGameResult = () => {
  const [hScore, setHScore] = useState(0);
  const [aScore, setAScore] = useState(0);
  const param = useParams();
  const [getMatchInfo, { error, data, isLoading, isError }] = useLazyGetSingleMatchInfoQuery();
  // useEffect(() => {
  //   if (!data) return;
  //   setHScore(data.ht_score);
  //   setAScore(data.at_score);
  // }, [data]);
  useEffect(() => {
    if (!data) getMatchInfo(param.id, false);
  }, []);
  const [
    closeGame,
    { error: error2, isLoading: upIsLoading, isSuccess: upIsSuccess, isError: upIsError },
  ] = useCloseGameMatchMutation();

  if (!data || isLoading) return <p>Loading...</p>;
  if (upIsSuccess || !data?.canChat) return <Navigate to='../' />;
  if (isError || upIsError) {
    const errorMessage = upIsError
      ? (error2 as CustomError).data.message
      : (error as CustomError).data.message;
    return <Navigate to='/went-wrong' state={{ message: errorMessage }} />;
  }

  return (
    <>
      <OutsideClick />
      <Formik
        initialValues={{}}
        onSubmit={() =>
          closeGame({
            id: param.gid,
            body: { ht_score: hScore, aw_score: aScore },
          })
        }
      >
        {(formik) => {
          return (
            <Form>
              <div className='fixed pb-20 top-0 left-0 right-0 z-10 w-auto my-6 mx-auto max-w-3xl max-h-[100vh] '>
                {/* content*/}
                <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-full overflow-auto'>
                  {/* header*/}
                  <ModalHeader name={'Submit Game Results'} />
                  {/* <div className='flex items-start justify-between mb-2 p-5 border-b border-solid border-slate-200 rounded-t sticky top-0 z-[1] bg-white'>
                    <h3 className='text-3xl font-semibold'>Submit Game Results</h3>
                  </div> */}
                  <div className='relative p-6 flex flex-col'>
                    <div className='flex justify-center gap-10'>
                      <div className='flex items-center justify-evenly rounded-lg bg-gray-100 border p-1 px-3 w-[300px]'>
                        <AvatarImage source={data.home_team[0].image} size='xl' />
                        <div className='flex flex-col justify-center flex-1 items-center '>
                          <h2 className='text-sm font-bold'>
                            {data.home_team[0].teamName.length >= 10
                              ? data.home_team[0].teamName.slice(0, 10) + '...'
                              : data.home_team[0].teamName}
                          </h2>
                          <h3 className='text-xs'>
                            {data.away_team[0].School?.name.length >= 10
                              ? data.away_team[0].School?.name.slice(0, 10) + '...'
                              : data.away_team[0].School?.name}
                          </h3>
                        </div>
                        <div className='flex gap-1 h-[80%] items-center p-2 border-2 bg-white rounded-xl'>
                          <AiOutlineMinus
                            onClick={() => setHScore((s) => (s > 0 ? s - 1 : s))}
                            className='cursor-pointer hover:scale-110 '
                          />
                          <input
                            onChange={(e) => setHScore(+e.target.value)}
                            type='text'
                            value={hScore}
                            className='w-[20px] text-center font-bold focus:outline-none active:outline-none'
                          />
                          <AiOutlinePlus
                            onClick={() => setHScore((s) => s + 1)}
                            className='cursor-pointer hover:scale-110 '
                          />
                        </div>
                      </div>
                      <div className='flex items-center justify-evenly rounded-lg bg-gray-100 border p-1 px-3 w-[300px]'>
                        <div className='flex gap-1 h-[80%] items-center p-2 border-2 bg-white rounded-xl'>
                          <AiOutlineMinus
                            onClick={() => setAScore((s) => (s > 0 ? s - 1 : s))}
                            className='cursor-pointer hover:scale-110 '
                          />
                          <input
                            type='text'
                            onChange={(e) => setAScore(+e.target.value)}
                            value={aScore}
                            className='w-[20px] text-center font-bold focus:outline-none active:outline-none'
                          />
                          <AiOutlinePlus
                            onClick={() => setAScore((s) => s + 1)}
                            className='cursor-pointer hover:scale-110 '
                          />{' '}
                        </div>
                        <div className='flex flex-col justify-center flex-1 items-center '>
                          <h2 className='text-sm font-bold'>
                            {data.away_team[0].teamName.length >= 10
                              ? data.away_team[0].teamName.slice(0, 10) + '...'
                              : data.away_team[0].teamName}
                          </h2>
                          <h3 className='text-xs'>
                            {data.away_team[0].School?.name.length >= 10
                              ? data.away_team[0].School?.name.slice(0, 10) + '...'
                              : data.away_team[0].School?.name}
                          </h3>
                        </div>
                        <AvatarImage source={data.away_team[0].image} size='xl' />
                      </div>
                    </div>
                  </div>
                  <div className='flex items-start justify-between mb-2 p-5 border-b border-solid border-slate-200 rounded-t sticky top-0 z-[1] bg-white'>
                    <h3 className='text-3xl font-semibold'>Match Claims</h3>
                  </div>
                  <div className='flex flex-col p-5 gap-5'>
                    {data.MatchGame.filter((mg: any) => mg.id == param.gid)[0]?.MatchGameScreenshot
                      .length ? (
                      data.MatchGame.filter(
                        (mg: any) => mg.id == param.gid,
                      )[0]?.MatchGameScreenshot.map((mg: any) => (
                        <ResultScreenshotRow key={mg.id} {...mg} username={mg.user.username} />
                      ))
                    ) : (
                      <></>
                    )}
                  </div>
                  {/* footer*/}

                  <ModalFooter isLoading={upIsLoading!} />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default SubmitGameResult;
