import { Formik, FormikValues } from 'formik';
import { OutsideClick } from '../../../components/outside-click';
import ModalContent from '../../../components/modal-content';
import { useCreateSupportCategoryMutation } from '../../../slices/supportCategoryApiSlice';
import { Navigate } from 'react-router';
import { NewSupportCategoryValidationSchema } from '../../authentication/yupschema';
import { CustomError } from '../../../utils/custom-types';

const NewCategoryModal = () => {
    const [createCategory, { isLoading, isError, error, isSuccess }] = useCreateSupportCategoryMutation();

    const formInputs = [
        {
            name: 'category',
            label: 'Category',
            placeholder: 'Category',
        },
    ];

    const initialValues: { [key: string]: string } = {};
    formInputs.forEach((input) => (initialValues[input.name] = ''));

    const onSubmit = (value: FormikValues) => {
        const requestBody = {
            name: value.category
        };
        createCategory(requestBody).unwrap().then(resp => {
            console.log('resp after submiting category modal', resp);
        }).catch(err => {
            console.log('err', err);
        });
    };

    if (isSuccess) return <Navigate to='../' />;

    return (
        <>
            <OutsideClick />
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={NewSupportCategoryValidationSchema}>
                {({ values }) => {
                    return (
                        <ModalContent
                            formInputs={formInputs}
                            isLoading={false}
                            extraClass='h-fit'
                            name="Create Category"
                            isError={isError}
                            error={isError ? (error as CustomError).data.message : ''}
                        />
                    );
                }}
            </Formik>
        </>
    );
};

export default NewCategoryModal;