import { Dropdown } from './../../../components/dropdown/dropdown';
import React, { useEffect, useState } from 'react';
import SettingsContainer from './SettingsContainer';
import { BsToggleOn, BsToggleOff } from 'react-icons/bs';
import {
  useGetUserSettingsQuery,
  useUpdateUserSettingsMutation,
} from '../../../slices/userApiSlice';
import { Navigate } from 'react-router';
import { CustomError } from '../../../utils/custom-types';

const NotificationSettings = () => {
  const { currentData } = useGetUserSettingsQuery('');
  const [optionOne, setOptionOne] = useState(false);
  const [optionTwo, setOptionTwo] = useState(false);
  const [updateUserSettings] = useUpdateUserSettingsMutation();

  let settingsList = [
    {
      title: 'Allow email notifications',
      children: [
        {
          type: 'unseenNotification',
          title: 'Remind me about unseen notifications via email.',
          active: optionOne,
        },
      ],
    },
    {
      title: 'Allow promotion notifications',
      children: [
        {
          type: 'promotionNotification',
          title: 'Remind me about new games, competitions, and more.',
          active: optionTwo,
        },
      ],
    },
  ];
  useEffect(() => {
    console.log('update', currentData);
    if (!currentData) return;
    setOptionOne(currentData.unseenNotification);
    setOptionTwo(currentData.promotionNotification);
    setOption(currentData.frequency);
    console.log('after update', settingsList, option);
  }, [currentData]);

  const optionList = ['Every day', 'Every 10 days', 'Once per month'];
  const [option, setOption] = useState(1);

  if (!currentData) return <p>Loading...</p>;

  return (
    <SettingsContainer title='Notification Settings'>
      <div className='flex flex-col justify-evenly gap-20'>
        {settingsList.map((s, i) => (
          <div key={i} className='flex flex-col'>
            <p className='font-bold'>{s.title}</p>
            {s.children.map((ch) => (
              <div key={ch.title} className='flex justify-between'>
                <p>{ch.title}</p>
                <span onClick={() => updateUserSettings({ [ch.type]: !ch.active })}>
                  {ch.active ? (
                    <BsToggleOn style={{ cursor: 'pointer' }} size={25} />
                  ) : (
                    <BsToggleOff style={{ cursor: 'pointer' }} size={25} />
                  )}
                </span>
              </div>
            ))}
          </div>
        ))}
        <div className='flex flex-col gap-10'>
          <p>Edit how often you want us to send email notifications.</p>
          <Dropdown option={option} setOption={setOption} optionsList={optionList} />
        </div>
      </div>
    </SettingsContainer>
  );
};

export default NotificationSettings;
