import { useNavigate } from "react-router";
import CanIView from "../../components/can-i-view/can-i-view";
import { useSelector } from "react-redux";
import { selectUser } from "../../slices/auth";
import { UserRoles } from "../../utils/constants";
import { dateAndTimeFormatter } from "../../utils/dateFormatter";

interface ITicketData {
    id: number,
    ticketTier: number;
    adminApprovalStatus: number;
    reworkCount: number;
    reworkCountT1: number;
    closedAt: string;
    ticketTracking: {
        ticketNotStartedT1: [] | any;
        createdInJira: [] | any;
    },
};

const ViewTicketIdTier = ({ ticketData, timeToResponse, timeToResolve, closedAt }: { ticketData?: ITicketData, timeToResponse?: string, timeToResolve?: string, closedAt?: string }) => {
    const navigate = useNavigate();
    const user = useSelector(selectUser);

    const reworkCounter = () => {
        let reworkCounter;
        if (ticketData?.ticketTracking?.ticketNotStartedT1?.length > 1 && !ticketData?.ticketTier) {
            reworkCounter = ticketData?.ticketTracking?.ticketNotStartedT1?.length - 1;
        }
        else if (ticketData?.ticketTracking?.createdInJira?.length > 1 && ticketData?.ticketTier) {
            reworkCounter = ticketData?.ticketTracking?.createdInJira?.length - 1;
        }
        else {
            reworkCounter = '0';
        }
        return reworkCounter;
    };

    return (
        <div className="flex flex-col px-5 gap-5">
            <div className="flex flex-col gap-4 flex-wrap">
                <div className="flex items-center flex-wrap gap-2 lg:mb-0">
                    <h2>Ticket ID:
                        <span className="font-bold">
                            {ticketData?.id}
                        </span>
                    </h2>
                    {user?.roles !== UserRoles.Admin ? (
                        <>
                            <span className="text-[#D9D9D9]">|</span>
                            <h2>Closed Date: <span className="font-bold">{ticketData?.closedAt ? dateAndTimeFormatter(ticketData?.closedAt)?.split(';')?.join() : '-'}</span></h2>
                        </>
                    ) : null}
                    {user?.roles === UserRoles.Admin ? (
                        <>
                            <span className="text-[#D9D9D9]">|</span>
                            <h2>Response time: <span className="font-bold">{timeToResponse}</span></h2>
                            <span className="text-[#D9D9D9]">|</span>
                            <h2>Resolve time: <span className="font-bold">{timeToResolve}</span></h2>
                            <span className="text-[#D9D9D9]">|</span>
                            <h2>Closed Date: <span className="font-bold">{closedAt ? dateAndTimeFormatter(closedAt)?.split(';')?.join() : '-'}</span></h2>
                        </>
                    ) : (
                        null
                    )}
                </div>
                <CanIView component="support.team">
                    <div className="flex items-center justify-between gap-2 mb-2">
                        <div className="">
                            <h2>Rework count:<span className="text-red-600 ml-2">{
                                reworkCounter()
                            }</span></h2>
                        </div>
                        <div className="flex items-center gap-2">
                            <h2>Support team:</h2>
                            <div className="w-[200px] border-1 border-[#D9D9D9] p-2 flex gap-2 rounded">
                                <label className='w-full flex justify-between gap-2'>
                                    <span className={`${!ticketData?.ticketTier ? 'bg-[#B50303] text-white rounded' : ''} w-[100px] p-1 text-center`}>Fenworks</span>
                                    <div className={`${ticketData?.ticketTier ? 'bg-[#B50303] rounded cursor-not-allowed' : ''} p-1 w-[100px] text-center transition-all duration-300`}>
                                        <span className={`${ticketData?.ticketTier ? 'text-white' : 'cursor-pointer'}`} onClick={() => !ticketData?.ticketTier ? navigate(`change-ticket-tier`) : null}>Telos</span>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </CanIView>
            </div >
        </div >
    )
};

export default ViewTicketIdTier;