import React from 'react';
import { Formik, Form } from 'formik';
import CustomInput from '../../authentication/CustomInput';
import { NewFan } from '../../authentication/yupschema';
import { BsThreeDots } from 'react-icons/bs';
import { useStateContext } from '../../../contexts/ContextProvider';
import { Link } from 'react-router-dom';
import { FormInput } from '../../../components/form-input/form-input';
import { SubmitButton } from '../../../components/submit-button';
import { gendersOptions } from '../../../utils/constants';

const NewFanModal = () => {
  const { showModal, setShowModal } = useStateContext();

  const onSubmit = () => {
    console.log('submitted');
  };

  const formInputs = [
    // {
    //   mainTitle: "Fan Information",
    //   name: "Role",
    //   label: "Role",
    //   placeholder: "Role"
    // },
    {
      mainTitle: 'Fan Information',
      name: 'firstName',
      label: 'First Name',
      placeholder: 'Enter first name',
    },
    {
      mainTitle: 'Fan Information',
      name: 'lastName',
      label: 'Last Name',
      placeholder: 'Enter last name',
    },
    {
      mainTitle: 'Fan Information',
      name: 'streetAddress',
      label: 'Street Address',
      placeholder: 'Enter street address',
    },
    {
      mainTitle: 'Fan Information',
      name: 'city',
      label: 'City',
      placeholder: 'Enter city',
    },
    {
      mainTitle: 'Fan Information',
      name: 'state',
      label: 'State',
      placeholder: 'Enter state',
    },
    {
      mainTitle: 'Fan Information',
      name: 'postal',
      label: 'Zip Code',
      placeholder: 'Enter zip code',
    },
    {
      mainTitle: 'Fan Information',
      name: 'gender',
      label: 'Gender',
      placeholder: 'gender',
      isSelect: true,
      options: gendersOptions,
    },
    {
      mainTitle: 'Fan Information',
      name: 'DateofBirth',
      label: 'Date of Birth',
      placeholder: 'Enter date of birth',
    },
    {
      mainTitle: 'Fan Information',
      name: 'phoneNumber',
      label: 'Phone Number',
      placeholder: 'Enter phone number',
    },
    {
      mainTitle: 'Fan Information',
      name: 'email',
      label: 'Email',
      placeholder: 'Enter email',
    },
  ];

  const initialValues: { [key: string]: string } = {};
  formInputs.forEach((input) => (initialValues[input.name] = ''));

  return (
    <>
      <Formik initialValues={initialValues} validationSchema={NewFan} onSubmit={onSubmit}>
        {({ isSubmitting, errors }) => (
          <Form>
            <div className='flex flex-col gap-2'>
              {formInputs.map((input: any, i: number) => (
                <React.Fragment key={i}>
                  {!formInputs[i - 1] || formInputs[i - 1]?.mainTitle !== input.mainTitle ? (
                    <>
                      <div className='flex flex-col'>
                        <div className='mb-2'>
                          <p className='text-gray-800 font-bold pt-6'>{input.mainTitle}</p>
                          <hr />
                        </div>
                        <div className='flex flex-wrap gap-1'>
                          {formInputs
                            .filter((inp1: any) => inp1.mainTitle === input.mainTitle)
                            .map((inp: any, i: number) => (
                              <div className={inp.isTrue ? 'w-full ' : 'flex-1 min-w-[300px]'}>
                                <FormInput {...inp} />
                              </div>
                            ))}
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </React.Fragment>
              ))}
            </div>
            {/* <div className="mt-2 mb-3">
              <h4 className="text-3xl font-semibold">Fan Information</h4>
              <hr />
            </div>

            <div className="inline-block  mb-3 w-1/2 pr-1">
              <FormInput
                label="First Name"
                name="firstName"
                type="text"
                placeholder="Enter your first name"
              />
            </div>

            <div className="inline-block mb-3 w-1/2 pr-1">
              <FormInput
                label="Last Name"
                name="lastName"
                type="text"
                placeholder="Enter your last name"
              />
            </div>
            <div className="inline-block mb-3 w-1/2 pr-1">
              <FormInput
                label="Street Address"
                name="streetAddress"
                type="text"
                placeholder="Enter street address"
              />
            </div>

            <div className="inline-block mb-3 w-1/2 pr-1">
              <FormInput
                label="City"
                name="city"
                type="text"
                placeholder="Enter city"
              />
            </div>
            <div className="inline-block mb-3  w-1/2 pr-1">
              <FormInput
                label="State/Province"
                name="state"
                type="text"
                placeholder="ST"
              />
            </div>
            <div className="inline-block mb-3 w-1/2 pr-1">
              <FormInput
                label="Postal / Zip Code"
                name="postal"
                type="text"
                placeholder="Enter Zip Code"
              />
            </div>
            <div className="inline-block mb-3 w-1/2 pr-1">
              <FormInput
                label="Gender"
                name="gender"
                type="text"
                placeholder="Select Gender"
              />
            </div>
            <div className="inline-block mb-3 w-1/2 pr-1">
              <FormInput label="Date of Birth" name="DateofBirth" type="date" />
            </div>

            <div className="inline-block mb-3 w-1/2 pr-1">
              <FormInput
                label="Email Address"
                name="email"
                type="text"
                placeholder="Enter your Email Address"
              />
            </div>
            <div className="inline-block mb-3 w-1/2 pr-1">
              <FormInput
                label="Phone Number"
                name="phoneNumber"
                type="text"
                placeholder="Enter your phone number"
              />
            </div> */}

            {/*footer*/}
            <div className='flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b'>
              {/* <button onClick={()=> setShowModal(false)} className="w-1/5 my-12  py-1 bg-white text-black shadow-lg font-semibold rounded-lg  ">
                        Cancel
                      </button> */}
              <Link
                className='w-1/5 my-12 text-center py-1 bg-white text-black shadow-lg font-semibold rounded-lg'
                to='../'
              >
                Cancel
              </Link>

              <SubmitButton />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default NewFanModal;
