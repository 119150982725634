import * as yup from 'yup';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { OutsideClick } from '../../../../components/outside-click';
import { useCreateSponsorMutation } from '../../../../slices/sponsorApiSlice';
import { NewSponsorVSchema } from './schema/new-sponsor-v-schema';
import ModalContent from '../../../../components/modal-content';
import { addCurrentTimeToDate } from '../../../../utils/dateFormatter';

const NewSponsor = () => {
  const [avatarImage, setAvatarImage] = useState<any>();
  const formInputs = [
    {
      mainTitle: 'Sponsor Information',
      name: 'name',
      label: 'Sponsor Name',
      type: 'text',
      placeholder: 'Enter sponsor name',
      isTrue: true,
    },
    {
      mainTitle: 'Sponsor Information',
      name: 'image',
      label: 'Image',
      type: 'image',
      placeholder: 'Select an image',
      isTrue: true,
    },
    {
      mainTitle: 'Sponsor Information',
      name: 'start_date',
      label: 'Start Date',
      type: 'date',
      placeholder: 'Select a starting date',
      isTrue: true,
    },
    {
      mainTitle: 'Sponsor Information',
      name: 'expiration_date',
      label: 'End Date',
      type: 'date',
      placeholder: 'Select an expiration date',
      isTrue: true,
    },
  ];
  const initialValues: { [key: string]: string } = {};
  formInputs.forEach((input) => (initialValues[input.name] = ''));
  const [createSponsor, { data, isLoading, isSuccess }] = useCreateSponsorMutation();
  function onSubmit(
    values: FormikValues,
    actions: FormikHelpers<{
      [key: string]: string;
    }>,
  ) {
    if (!avatarImage) {
      actions.setErrors({ image: 'Required!' });
      return;
    }
    const acceptedFormats = ['image/jpeg', 'image/png', 'image/jpg'];
    if (!acceptedFormats.includes(avatarImage.type)) {
      actions.setErrors({
        image: 'Invalid image format. Only JPG, PNG, and JPEG formats are allowed.',
      });
      return;
    }

    const data = { ...values };
    const formData = new FormData();

    // const inputDate1 = new Date(data.start_date);
    // const inputDate2 = new Date(data.expiration_date);
    // const currentDate = new Date();

    // inputDate1.setHours(currentDate.getHours());
    // inputDate1.setMinutes(currentDate.getMinutes());
    // inputDate1.setSeconds(currentDate.getSeconds());
    // inputDate2.setHours(currentDate.getHours());
    // inputDate2.setMinutes(currentDate.getMinutes());
    // inputDate2.setSeconds(currentDate.getSeconds());

    formData.append('image', avatarImage);
    formData.append('name', data.name);

    // formData.append('start_date', inputDate1.toISOString());
    // formData.append('expiration_date', inputDate2.toISOString());
    formData.append('start_date', addCurrentTimeToDate(data.start_date));
    formData.append('expiration_date', addCurrentTimeToDate(data.expiration_date));


    createSponsor(formData).catch((e) => console.log(e));
  }

  if (isSuccess) return <Navigate to='../' />;

  return (
    <>
      <OutsideClick />
      <Formik
        validationSchema={NewSponsorVSchema.shape({
          image: yup
            .mixed()
            .test('test', 'Required!', () => {
              if (!avatarImage) return false;
              return true;
            })
            .test(
              'test2',
              'Invalid image format. Only JPG, PNG, and JPEG formats are allowed.',
              () => {
                const acceptedFormats = ['image/jpeg', 'image/png', 'image/jpg'];
                if (!acceptedFormats.includes(avatarImage?.type ?? '')) {
                  return false;
                }
                return true;
              },
            ),
        })}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        <ModalContent
          setAvatarImage={setAvatarImage}
          extraClass={'h-fit'}
          formInputs={formInputs}
          isLoading={isLoading}
          name='New Sponsor'
        />
      </Formik>
    </>
  );
};

export default NewSponsor;
