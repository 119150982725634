import { dateAndTimeFormatter, dateToLocal } from '../utils/dateFormatter';

export class SeasonWeekModel {
  id?: number;
  name?: string;
  start_time?: string;
  f_start_time?: string;
  inp_start_time?: string;
  end_time?: string;
  f_end_time?: string;
  inp_end_time?: string;
  format_id?: number;
  match_format_id?: number;
  match_format_type?: number;
  match_format_name?: string;
  match_format_value?: number;
  isLeaderboard = false;
  status?: number;
  constructor(seasonWeek: any) {
    if (seasonWeek?.hasOwnProperty('status')) {
      this.status = seasonWeek.status;
    }
    if (seasonWeek?.hasOwnProperty('Season')) {
      if (seasonWeek.Season?.hasOwnProperty('SeasonType')) {
        if (seasonWeek.Season.SeasonType?.hasOwnProperty('leaderboard'))
          this.isLeaderboard = Boolean(seasonWeek.Season.SeasonType.leaderboard);
      }
    }
    if (seasonWeek?.hasOwnProperty('id')) this.id = seasonWeek?.id;
    if (seasonWeek?.hasOwnProperty('name')) this.name = seasonWeek?.name;
    if (seasonWeek?.hasOwnProperty('start_time')) {
      this.start_time = seasonWeek?.start_time;
      // this.f_start_time = dateAndTimeFormatter(seasonWeek?.start_time).split(';')[0];
      this.f_start_time = dateToLocal(seasonWeek?.start_time, 'MMMM D, YYYY');
      this.inp_start_time = seasonWeek?.start_time?.split('T')[0];
    }
    if (seasonWeek?.hasOwnProperty('end_time')) {
      this.end_time = seasonWeek?.end_time;
      this.f_end_time = dateToLocal(seasonWeek?.end_time, 'MMMM D, YYYY');
      this.inp_end_time = seasonWeek?.end_time?.split('T')[0];
    }
    if (seasonWeek?.hasOwnProperty('format_id')) this.format_id = seasonWeek?.format_id;
    if (seasonWeek?.hasOwnProperty('match_format')) {
      this.match_format_id = seasonWeek?.match_format?.id;
      this.match_format_type = seasonWeek?.match_format?.type;
      this.match_format_name = seasonWeek?.match_format?.name;
      this.match_format_value = seasonWeek?.match_format?.value;
    }
  }
}
