export function LoadingSceletonSponsors (){
    return (
      <div className='w-full h-full border-2 rounded-xl flex flex-col p-1'>
        <div className=' flex flex-wrap p-6 justify-between '>
          <div className='h-[50px] w-[200px] bg-slate-200 rounded-lg skeleton__pulse'></div>
          <div className='flex flex-wrap gap-3'>
            <div className='h-[45px] w-[200px] bg-slate-200 rounded-lg skeleton__pulse '></div>
            <div className='h-[45px] w-[200px] bg-slate-200 rounded-lg skeleton__pulse'></div>
          </div>
        </div>
        <div className=''>
          <div className='flex justify-between p-1 rounded-lg'>
            <div className=' h-[900px] md:w-full  bg-slate-200 rounded-lg  skeleton__pulse'></div>
          </div>
        </div>
      </div>
    );
    
 }