import { Navigate, useParams } from 'react-router';
import { useEndSeasonMutation } from '../../../../../../slices/seasonApiSlice';
import ModalEndSeason from './modal-end-season';

const EndSeason = () => {
  const param = useParams();
  const [endSeason, { isLoading, isError, isSuccess }] = useEndSeasonMutation();
  const onSubmit = () => {
    endSeason(Number(param.id));
  };
  if (isSuccess) return <Navigate to='../' />;
  return <ModalEndSeason onSubmit={onSubmit} isLoading={isLoading} name={param.name ?? ''} />;
};

export default EndSeason;
