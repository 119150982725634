import { Formik, FormikValues } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { OutsideClick } from '../../../../../../components/outside-click';
import {
  useGetNewTRequiredDataQuery,
  useGetTournamentByIdQuery,
  useUpdateTournamentMutation,
} from '../../../../../../slices/tournamentApiSlice';
import { EditTournamentVSchema } from './schema/edit-tournament-v-schema';
import ModalContent from '../../../../../../components/modal-content';
import { CustomError } from '../../../../../../utils/custom-types';
import { addCurrentTimeToDate, dateToLocal } from '../../../../../../utils/dateFormatter';

const EditTournament = () => {
  const param = useParams();
  const {
    error,
    data: tournamentData,
    isLoading: tournamentIsLoading,
    isError: tournamentIsError,
    isSuccess: tournamentIsSuccess,
  } = useGetTournamentByIdQuery(param.id);

  const [chosenTeam, setChosenTeam] = useState<object[]>([]);
  const [chosenSponsor, setChosenSponsor] = useState<object[]>([]);
  const [chosenSubDivision, setChosenSubDivision] = useState<object[]>([]);
  const [chosenCategoryDivision, setChosenCategoryDivision] = useState<number>();
  const [teamSizeValue, setTeamSizeValue] = useState<number>();

  const [updateTournament, { isLoading, isSuccess, isError }] = useUpdateTournamentMutation();
  const { data: tRequiredData, isLoading: isDataLoading } = useGetNewTRequiredDataQuery('');

  useEffect(() => {
    if (!tournamentData) return;
    if (tournamentData?.teams) {
      setChosenTeam((s) => (tournamentData.teams ? [...s, ...tournamentData.teams] : []));
    }
    if (tournamentData?.sponsors) {
      setChosenSponsor((s) => (tournamentData.sponsors ? [...s, ...tournamentData.sponsors] : []));
    }
    if (tournamentData?.subdivisions && !chosenSubDivision.length) {
      tournamentData?.subdivisions.forEach((sub: any) =>
        setChosenSubDivision((s) =>
          tournamentData?.subdivisions
            ? [...s, { ...sub, division_id: tournamentData.division_id }]
            : [],
        ),
      );
    }
    if (tournamentData?.team_size) {
      setTeamSizeValue(tournamentData?.team_size);
    }
  }, [tournamentData]);

  const onSubmit = (values: FormikValues) => {
    const data = { ...values };

    data.division_id = +data.division_id;
    // data.format_id = +data.format_id;
    // data.game_id = +data.game_id;
    data.type_id = +data.type_id;
    data.season_id = +data.season_id;
    data.team_size = +data.team_size;
    data.start_date = addCurrentTimeToDate(data.start_date);
    data.end_date = addCurrentTimeToDate(data.end_date);

    data.teams = [];
    chosenTeam.forEach((t: any) => data.teams.push({ id: t.id }));

    data.sponsors = [];
    chosenSponsor.forEach((t: any) => data.sponsors.push({ id: t.id }));

    data.subdivisions = [];
    chosenSubDivision.forEach(
      (t: any) => t.division_id === data.division_id && data.subdivisions.push({ id: t.id }),
    );
    delete data.id;

    updateTournament({ id: param.id, body: data })
      .unwrap()
      .then((res: any) => {
        setChosenTeam([]);
        setChosenSponsor([]);
      });
  };

  const didMount = useRef(0);
  useEffect(() => {
    if (didMount.current >= 2) setChosenTeam([]);
    else didMount.current++;
  }, [teamSizeValue]);

  const formInputs = [
    {
      mainTitle: 'Tournament Information',
      name: 'name',
      label: 'Tournament Name',
      type: 'text',
      placeholder: 'Enter tournament name',
      isTrue: true,
    },
    {
      mainTitle: 'Tournament Information',
      name: 'type_id',
      label: 'Tournament Type',
      type: 'text',
      placeholder: 'Please select tournament type',
      isTrue: true,
      isSelect: true,
      options: tRequiredData?.types ? tRequiredData.types : [],
    },
    {
      mainTitle: 'Tournament Information',
      name: 'division_id',
      label: 'Tournament Category Division',
      type: 'text',
      placeholder: 'Please tournament category division',
      isTrue: true,
      isSelect: true,
      options: tRequiredData?.division ? tRequiredData.division : [],
    },
    {
      mainTitle: 'Tournament Information',
      name: 'subdivisions',
      label: 'Subdivisions',
      type: 'text',
      placeholder: 'Enter subdivisions',
      isTrue: true,
      search: 'subdivision',
      divisionId: chosenCategoryDivision && chosenCategoryDivision,
      setChosenSubDivision,
      chosenSubDivision,
    },
    {
      mainTitle: 'Tournament Information',
      name: 'start_date',
      label: 'Start Date',
      type: 'date',
      placeholder: 'Enter the start date',
      isTrue: true,
    },
    {
      mainTitle: 'Tournament Information',
      name: 'end_date',
      label: 'End Date',
      type: 'date',
      placeholder: 'Please select end date',
      isTrue: true,
    },
    {
      mainTitle: 'Tournament Information',
      name: 'information',
      label: 'Information',
      type: 'textarea',
      placeholder: 'Enter information',
      isTrue: true,
    },
    {
      mainTitle: 'Tournament Information',
      name: 'sponsors',
      label: 'Sponsors',
      type: 'text',
      placeholder: 'Enter sponsors',
      isTrue: true,
      search: 'sponsor',
      setChosenSponsor,
      chosenSponsor,
    },
  ];

  if (tournamentIsError || (tournamentIsSuccess && !tournamentData))
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError).data.message }} />;
  if (tournamentIsLoading || isLoading) return <p>Loading...</p>;
  if (isSuccess) return <Navigate to='../' />;
  const editTournamentSchema = EditTournamentVSchema(tournamentData?.start_date);
  return (
    <>
      <OutsideClick />
      <Formik
        validationSchema={editTournamentSchema}
        initialValues={{
          team_size: tournamentData?.teams.length,
          division_id: tournamentData?.division_id,
          information: tournamentData?.information,
          name: tournamentData?.name,
          type_id: tournamentData?.TournamentType?.id,
          start_date: dateToLocal(tournamentData?.start_date)?.split('T')[0],
          end_date: dateToLocal(tournamentData?.end_date)?.split('T')[0],
          teams: '',
          sponsors: '',
          subdivisions: '',
        }}
        onSubmit={onSubmit}
      >
        {({ values, errors }) => {
          useEffect(() => {
            if (!values) return;
            setChosenCategoryDivision(+values.division_id);
            setTeamSizeValue(+values.team_size);
          }, [values]);
          return (
            <ModalContent
              errors={Object.keys(errors).length}
              formInputs={formInputs}
              isLoading={isLoading}
              name={`Edit Tournament - ${tournamentData.name}`}
            />
          );
        }}
      </Formik>
    </>
  );
};

export default EditTournament;
