import { useCreateCoachMutation } from '../../../slices/userApiSlice';
import { Navigate } from 'react-router-dom';
import { Formik, FormikValues } from 'formik';
import { NewCoach } from '../../authentication/yupschema';
import { OutsideClick } from '../../../components/outside-click';
import ModalContent from '../../../components/modal-content';
import { allStates, gendersOptions } from '../../../utils/constants';
import { CustomError } from '../../../utils/custom-types';

const NewCoachModal = () => {
  const [createCoach, { isLoading, isError, isSuccess, error }] = useCreateCoachMutation();

  const onSubmit = (values: FormikValues) => {
    const data = values;
    data.zipCode = Number(data.zipCode);
    // data.status = Number(data.status);
    // data.dateOfBirth = new Date(data.dateOfBirth).toISOString();
    createCoach(data)
      .unwrap()
      .then((res) => console.log(res))
      .catch((error) => console.log(error));
  };

  const formInputs = [
    {
      mainTitle: 'Coach Information',
      name: 'firstName',
      label: 'First Name',
      type: 'text',
      placeholder: 'Enter first name',
    },
    {
      mainTitle: 'Coach Information',
      name: 'lastName',
      label: 'Last Name',
      type: 'text',
      placeholder: 'Enter last name',
    },
    {
      mainTitle: 'Coach Information',
      name: 'username',
      label: 'Username',
      type: 'text',
      placeholder: 'Enter username',
    },
    {
      mainTitle: 'Coach Information',
      name: 'city',
      label: 'City',
      type: 'text',
      placeholder: 'Enter city',
    },
    {
      mainTitle: 'Coach Information',
      name: 'state',
      label: 'State',
      type: 'text',
      placeholder: 'Enter state',
      isSelect: true,
      options: allStates ? allStates : [],
    },
    {
      mainTitle: 'Coach Information',
      name: 'zipCode',
      label: 'Zip Code',
      type: 'number',
      placeholder: 'Enter zip code',
    },
    {
      mainTitle: 'Coach Information',
      name: 'phoneNumber',
      label: 'Phone Number',
      type: 'text',
      placeholder: 'Enter phone number',
    },
    {
      mainTitle: 'Coach Information',
      name: 'gender',
      label: 'Gender',
      type: 'text',
      isSelect: true,
      options: gendersOptions,
    },
    {
      mainTitle: 'Coach Information',
      name: 'email',
      label: 'Email Address',
      type: 'text',
      placeholder: 'Enter email address',
    },
  ];

  const initialValues: { [key: string]: string } = {};
  formInputs.forEach((input) => (initialValues[input.name] = ''));
  if (isSuccess) return <Navigate to='../' />;
  return (
    <>
      <OutsideClick />
      <Formik initialValues={initialValues} validationSchema={NewCoach} onSubmit={onSubmit}>
        {({ isSubmitting, errors }) => (
          <>
            <ModalContent
              errors={Object.keys(errors).length}
              formInputs={formInputs}
              isLoading={isLoading}
              name='New Coach'
              isError={isError}
              error={isError ? (error as CustomError).data.message : ''}
            />
          </>
        )}
      </Formik>
    </>
  );
};

export default NewCoachModal;
