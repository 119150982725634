import { Link, Navigate, Outlet, useParams } from 'react-router-dom';
import {
  useCreateCommentMutation,
  useGetTicketByIdQuery,
} from '../../slices/supportTicketApiSlice';
import { TicketApprovalStatus } from '../../utils/constants';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { OutsideClick } from '../../components/outside-click';
import { Formik, Form } from 'formik';
import { FormInput } from '../../components/form-input/form-input';
import { BsUpload } from 'react-icons/bs';
import ViewTicketIdTier from './view-ticket-id-tier';
import ViewTicketComments from './view-ticket-comments';
import { useGetAllSubcategoriesByCategoryIdQuery } from '../../slices/supportSubcategoryApiSlice';
import { useGetAllSupportCategoryQuery } from '../../slices/supportCategoryApiSlice';
import { ViewTicketModelUser } from './view-ticket-model-user';

const ViewTicketUser = () => {
  const [selectedFile, setSelectedFile] = useState<any>();
  const [fileName, setFileName] = useState<any>();
  const [textAreaActive, setTextAreaActive] = useState<any>();
  const param = useParams();
  const {
    data: ticketData,
    isLoading: fetchingTicketLoading,
    isError: getTicketDataError,
  } = useGetTicketByIdQuery(param.id);
  const [createComment, { isLoading: commentIsLoading, isSuccess: commentPosted }] =
    useCreateCommentMutation();
  const { data: categories } = useGetAllSupportCategoryQuery('');
  const [supportCgId, setSupportCgId] = useState<any>(null);
  const { data: subCategoriesOfCategory } = useGetAllSubcategoriesByCategoryIdQuery(+supportCgId, {
    skip: true,
  });
  const [model, setModel] = useState(new ViewTicketModelUser(ticketData));
  const [comment, setComment] = useState<string>('');
  const [err, setErr] = useState<any>();
  const [viewFullImage, setViewFullImage] = useState<boolean>(false);

  const handleImageView = () => {
    setViewFullImage(!viewFullImage);
  };

  useEffect(() => {
    if (ticketData) {
      setModel(new ViewTicketModelUser(ticketData));
    }
  }, [ticketData]);

  const formInputs = [
    {
      name: 'fullName',
      label: 'Owner',
      placeholder: 'Owner name',
      type: 'text',
      isTrue: false,
      isDisabled: true,
    },
    {
      name: 'role',
      label: 'Role',
      placeholder: 'Role',
      type: 'text',
      isTrue: false,
      isDisabled: true,
    },
    {
      name: 'createdAt',
      label: 'Created Date',
      placeholder: 'Created date',
      type: 'text',
      isTrue: false,
      isDisabled: true,
    },
    // {
    //     name: 'priorityLevel',
    //     label: 'Priority',
    //     placeholder: 'Priority level',
    //     type: 'text',
    //     isTrue: false,
    //     isDisabled: true
    // },
    {
      name: 'supportCategoryId',
      label: 'Category',
      type: 'text',
      placeholder: 'Category',
      isTrue: false,
      isDisabled: true,
    },
    {
      name: 'supportSubcategoryId',
      label: 'Subcategory',
      placeholder: 'Subcategory',
      type: 'text',
      isTrue: false,
      isDisabled: true,
    },
    {
      name: 'status',
      label: 'Status',
      placeholder: 'Status',
      type: 'text',
      isTrue: false,
      isDisabled: true,
    },
  ];

  const handleFileUpload = (event: React.FormEvent) => {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    if (!file) {
      setFileName('');
      setSelectedFile('');
      return;
    }
    setSelectedFile(file);
    setFileName(file?.name);
  };

  const handleFocus = () => {
    setTextAreaActive(true);
  };

  const handleBlur = () => {
    setTextAreaActive(false);
  };

  const handleSendComment = (e: React.FormEvent) => {
    e.preventDefault();
    if (comment.trim() === '') {
      setErr('Required!');
    } else {
      const formData = new FormData();
      formData.append('supportTicketId', String(param.id));
      formData.append('text', comment);
      formData.append('issueKey', ticketData?.issueKey);
      formData.append('file', selectedFile);
      createComment(formData);
      setFileName('');
      setComment('');
      setSelectedFile('');
      setErr('');
      setSelectedFile('');
    }
  };

  const onSubmit = () => {
    return;
  };

  if (fetchingTicketLoading) {
    return <p>Loading...</p>;
  }

  if (getTicketDataError) {
    return <Navigate to='../' />;
  }

  return (
    <>
      <Outlet />
      <div className='flex overflow-y-auto max-w-full flex-col flex-1 rounded-xl bg-white border-1 gap-5'>
        <div className='p-6 border-b'>
          <h1 className='font-bold text-2xl break-words'>{ticketData?.userTitle}</h1>
        </div>
        <ViewTicketIdTier ticketData={ticketData} />
        <div>
          <Formik
            initialValues={{
              supportSubcategoryId: ticketData?.subcategory?.name,
              supportCategoryId: ticketData?.category?.name,
              // priorityLevel: priorityLevels[ticketData?.subcategory?.priorityLevel],
              status: model.status,
              fullName: model.fullName || model.email,
              role: model.role,
              createdAt: model.f_createdAt,
              time: model.ttrTime,
            }}
            onSubmit={onSubmit}
            validationSchema={false}
            enableReinitialize={true}
          >
            {({ values }) => {
              useEffect(() => {
                if (!values) return;
                setSupportCgId(values.supportCategoryId);
              }, [values]);
              return (
                <Form>
                  <div className='grid gap-y-4 px-5'>
                    <div className='mx-auto'>
                      <div className='flex justify-center lg:justify-start flex-wrap max-w-[700px] gap-5'>
                        {formInputs.map((inp: any, i: any) => (
                          <React.Fragment key={i}>
                            <div className={inp.isTrue ? 'flex-1' : 'w-[340px]'}>
                              {inp.type !== 'image' ? (
                                <FormInput {...inp} />
                              ) : (
                                <FormInput setFieldValue={''} {...inp} />
                              )}
                            </div>
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                    <div className='border bg-gray-100 rounded flex flex-col gap-2'>
                      <span className='ml-2 pt-2'>Description</span>
                      <p className='pl-2 mb-2'>{ticketData?.description}</p>
                      {ticketData?.link ? (
                        <span className='pl-2 mb-2'>
                          <Link
                            to={`${ticketData?.link}`}
                            className='border-b-1 border-[#6B7280]'
                            target='_blank'
                          >
                            Link of issue
                          </Link>
                        </span>
                      ) : null}
                    </div>
                    {viewFullImage && <OutsideClick setFunc={setViewFullImage} />}
                    {ticketData?.fileUrl?.includes('image') ? (
                      <div className='max-w-[300px]'>
                        <img
                          src={ticketData?.fileUrl}
                          alt=''
                          className={
                            viewFullImage
                              ? 'fixed top-0 left-0 right-0 bottom-0 z-10 w-auto h-[90vh] m-auto'
                              : 'cursor-pointer'
                          }
                          onClick={() => handleImageView()}
                        />
                      </div>
                    ) : ticketData?.fileUrl?.includes('video') ? (
                      <div className='max-w-[300px]'>
                        <video src={ticketData?.fileUrl} autoPlay controls />
                      </div>
                    ) : ticketData?.fileUrl?.includes('pdf') ? (
                      <div className='max-w-[300px]'>
                        <Link to={ticketData?.fileUrl} target='_blank'>
                          PDF attachment
                        </Link>
                      </div>
                    ) : null}
                    <div className='border-1 mb-3'></div>
                    <ViewTicketComments />
                    <div className='flex flex-col gap-2 comments rounded-lg p-2'>
                      <div
                        className={`${
                          textAreaActive && ticketData?.status !== 1
                            ? 'border-red-700'
                            : 'border-[#D1DBD5]'
                        } flex flex-col rounded-lg p-2 border`}
                      >
                        <label
                          className={`${textAreaActive ? 'text-red-700' : ''} mb-2`}
                          htmlFor='AddComment'
                        >
                          Add comment
                        </label>
                        <textarea
                          id='AddComment'
                          name='comment'
                          className='outline-none resize-none'
                          placeholder='Start typing...'
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          disabled={ticketData?.status === 1}
                          value={comment}
                          onChange={(e: ChangeEvent<{ value: string }>) => {
                            setComment(e.target.value);
                          }}
                        />
                      </div>
                      {err ? <div className='error'>{err}</div> : null}
                    </div>
                    <div className='flex sm:flex-wrap sm:items-center justify-end gap-5 mb-5'>
                      <div className='flex items-center gap-2'>
                        <label
                          className={`flex items-center gap-2 px-2 py-2 bg-[#D9D9D9] rounded-lg ${
                            ticketData?.status === 1 ? 'cursor-not-allowed' : 'cursor-pointer'
                          }`}
                          onChange={handleFileUpload}
                          htmlFor='fileUpload'
                        >
                          <input
                            type='file'
                            name=''
                            disabled={ticketData?.status === 1}
                            id='fileUpload'
                            hidden
                            accept='image/jpeg, image/png, image/jpg, video/mp4, video/x-m4v, video/wmv, video/webm, application/pdf'
                          />
                          <BsUpload />
                          <span>Upload file</span>
                        </label>
                        {fileName && <span className='truncate w-32'>{fileName}</span>}
                      </div>
                      <div>
                        <button
                          className='min-w-[120px] px-2 py-2 bg-red-700 hover:bg-opacity-80 text-white font-semibold text-sm rounded-lg ml-2  button'
                          disabled={ticketData?.status === 1 ? true : commentIsLoading}
                          onClick={handleSendComment}
                        >
                          Send
                        </button>
                      </div>
                    </div>
                  </div>
                  <div></div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
      {ticketData?.userApprovalStatus ===
      TicketApprovalStatus.UserApproved ? null : ticketData?.status === 1 ? (
        <div className='flex flex-col items-center text-center justify-center gap-5'>
          <span>Your ticket has been marked as Resolved by admin</span>
          <span>
            If you think your problem has not been resolved, please click the button "unresolved" to
            reopen the ticket.
          </span>
          <span>You will be prompted to elaborate on the issue.</span>
          <Link
            to='not-approve'
            className='min-w-[120px] px-2  py-2 bg-red-700 hover:bg-opacity-80 text-white font-semibold text-sm rounded-lg ml-2  button'
          >
            Unresolved
          </Link>
        </div>
      ) : null}
    </>
  );
};

export default ViewTicketUser;
