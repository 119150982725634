import { OgmaProfileHeader } from './components/ogma-profile-header';
import { useGetUsersShortInfoQuery } from '../../slices/userApiSlice';
import { Outlet, useNavigate, useParams } from 'react-router';
import { UserModel } from '../../features/listview/users/model/user.model';
import { NavLink } from 'react-router-dom';
import { useEffect } from 'react';

const OgmaProfile = () => {
  const buttonList = [
    {
      name: 'Invoices',
      link: './invoices',
    },
    {
      name: 'Schools',
      link: './schools',
    },
  ];

  const param = useParams();
  const { data, isLoading } = useGetUsersShortInfoQuery(param.id ?? '');
  const navigate = useNavigate();
  useEffect(() => {
    navigate('./invoices');
  }, []);
  if (isLoading) return <p>Loading...</p>;
  return (
    <div className='listViewContainer fullPage border-1'>
      <OgmaProfileHeader model={new UserModel(data)} />
      <div className='flex gap-2 p-2 bg-white border-b-1 w-fit'>
        {buttonList.map((b) => (
          <NavLink key={b.link} to={b.link} className='settingsLink'>
            <div> {b.name} &nbsp;</div>
          </NavLink>
        ))}
      </div>
      <Outlet />
    </div>
  );
};

export default OgmaProfile;
