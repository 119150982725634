import moment from 'moment-timezone';

export function dateFormatter(date: string) {
  const data = new Date(date);
  const day = data.toDateString().split(' ')[0];
  const dayMonth = data.toLocaleDateString().split('/').slice(0, 2).reverse().join('/');
  return `${day} ${dayMonth}`;
}

export function scheduleHourFormatter(date: string) {
  const data = new Date(date);

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const formattedDate = data.toLocaleString('en-US', {
    timeZone: userTimeZone,
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'shortOffset',
    hour12: true,
  });

  return formattedDate;
  // return `${data.getHours()}:${data.getMinutes()}`;
}
export function formatDateWithComma(dateString: any) {
  const date = new Date(dateString);
  const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  const weekday = weekdays[date.getDay()];
  //kqyre diten a e kane krejt datat
  return `${weekday} ${month} ${day}, ${year}`;
}
export function formatJustDateWithComma(dateString: any) {
  const date = new Date(dateString);
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return ` ${month} ${day}, ${year}`;
}

export function dateAndTimeFormatter(date: string) {
  moment.locale();
  // Get the user's timezone
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // console.log('userTimeZone = ', userTimeZone);

  // Convert the UTC date and time to the user's timezone
  const utcDateTime = moment.utc(date);
  const convertedDateTime = utcDateTime.tz(userTimeZone);
  // Set the local date and time in state
  return convertedDateTime.format('MMMM D, YYYY; h:mm A z');
}
export function addCurrentTimeToDate(dateString: string) {
  const currentTime = new Date().toLocaleTimeString();
  const combinedDateTimeString = `${dateString} ${currentTime}`;
  const combinedDateTime = new Date(combinedDateTimeString).toISOString();
  return combinedDateTime;
}

export function dateToLocal(date: Date, formatType: string = '') {
  if (!date) return '-';
  moment.locale();
  // Get the user's timezone
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let test;
  if (formatType && formatType?.length) test = moment(date)?.tz(userTimeZone).format(formatType);
  else test = moment(date)?.tz(userTimeZone).format();
  return test;
}

export function timeZoneTimeFormatter(date: string) {
  moment.locale();
  // Get the user's timezone
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // console.log('userTimeZone = ', userTimeZone);

  // Convert the UTC date and time to the user's timezone
  const utcDateTime = moment.utc(date);
  const convertedDateTime = utcDateTime.tz(userTimeZone);
  // Set the local date and time in state
  return convertedDateTime.format('hh:mm A z');
}

export function timeFormatter(time: string, check?: 'new') {
  // const date = new Date();
  // const [hour, minute] = time.split('T')[1].split(':');
  // date.setHours(parseInt(hour), 10);
  // date.setMinutes(parseInt(minute), 10);

  // return new Date(date).toLocaleString('en-US', {
  //   hour: 'numeric',
  //   minute: 'numeric',
  //   hour12: true,
  // });
  let date;
  if (check) {
    date = new Date();
    const [hour, min] = time.split(':');
    date.setHours(+hour, +min);
  } else {
    date = new Date(time);
  }

  const options = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    // timeZone: 'America/New_York', // Specify the desired timezone
  };

  const timeString = date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
  return timeString;
}
