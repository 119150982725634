import * as yup from 'yup';
import { ErrorMessages } from '../../../utils/messages.enum';
import {
  nameRules,
  nameRulesHint, cityRules,
  zipCodeRules,
  zipCodeRulesHint,
  usPhoneRules,
  usPhoneRulesHint
} from '../../authentication/yupschema';
export const UpdateCoachSchema = yup.object().shape({
  // Role: yup.string().required("Required"),
  firstName: yup.string().matches(nameRules, nameRulesHint).required('Required!'),
  lastName: yup.string().matches(nameRules, nameRulesHint).required('Required!'),
  streetAddress: yup.string().required('Required!'),
  city: yup.string().matches(cityRules, ErrorMessages.CityValidation).required('Required!'),
  state: yup.string().required('Required!'),
  zipCode: yup.string().matches(zipCodeRules, zipCodeRulesHint).required('Required!'),
  // gender: yup.string().required('Required!'),
  // dateOfBirth: yup.string().required('Required!'),
  // dateOfBirth: yup
  //   .string()
  //   .required('Required!')
  //   .test('age', 'You must be at least 18 years old', function (value: any) {
  //     const today = new Date();
  //     const birthDate = new Date(value);
  //     const age = today.getFullYear() - birthDate.getFullYear();
  //     return age >= 18;
  //   }),
  // yearsOfExperience: yup.string().required('Required'),
  // tags: yup.string().required('Required!'),
  // email: yup.string().email('Enter a valid email').required('Required!'),
  // email: yup.string().required('Required!').matches(emailRules, validEmailRule),
  phoneNumber: yup.string().matches(usPhoneRules, usPhoneRulesHint).required('Required!'),
  status: yup.string().required('Required!'),
});
