import { ErrorMessage, Field, Form, Formik, FormikValues } from 'formik';
import { NewStudentSchema } from '../../authentication/yupschema';

import { Navigate } from 'react-router-dom';
import { FormInput } from '../../../components/form-input/form-input';
import { useGetAllSchoolsQuery } from '../../../slices/schoolsApiSlice';
import { useCreateStudentMutation } from '../../../slices/userApiSlice';
import ModalFooter from '../../../components/modal-footer';
import React, { useState } from 'react';
import { gendersOptions } from '../../../utils/constants';
import { gradeLevels } from '../../../utils/utils';
import { SchoolModel } from '../../../model/school/school-model';

const NewStudentModal = () => {
  const [error, setError] = useState<string>();
  const [
    createStudent,
    {
      data: studentData,
      isError: studentIsError,
      isLoading: studentIsLoading,
      isSuccess: studentIsSuccess,
    },
  ] = useCreateStudentMutation();

  const onSubmit = (
    values: FormikValues,
    {
      setFieldValue,
    }: { setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void },
  ) => {
    const data = values;
    if (!data.zipCode) {
      delete data.zipCode;
    } else data.zipCode = data?.zipCode ? +data.zipCode : '';
    data.school_id = Number(data.School);
    delete data.School;
    if (data.dateOfBirth) {
      data.dateOfBirth = new Date(values.dateOfBirth).toISOString();
    } else {
      data.dateOfBirth = null;
    }
    data.status = +data.status || 0;
    data.eligibilityStatus = data.eligibilityStatus ? 1 : 0;
    data.ageAgreement = data.ageAgreement ? 1 : 0;
    // const selectedState = allStates.find((state) => state.id === values.state);
    // console.log('selectedState',selectedState)
    // if(selectedState){
    //   data.state = selectedState.name
    // }
    console.log(data);
    createStudent(data)
      .unwrap()
      .then((res) => console.log(res))
      .catch((e) => {
        console.log(e);
        setError(e?.data?.message);
        console.log('data', data);
        if (values.dateOfBirth) {
          const newDate = new Date(values.dateOfBirth);
          setFieldValue('dateOfBirth', newDate.toISOString().split('T')[0]);
        }
      });
    // if(isError){
    //   formInputs.map((input)=>
    //   initialValues[input.name] = String(data[input.name])
    //   )
    // }
  };

  // Role: "",
  // School: "",
  // firstName: "",
  // lastName: "",
  // Personalemail: "",
  // studentEmail: "",
  // username: "",
  // gradeLevel: "",
  // status: "",
  // checkbox: "",
  // const {} = useGetAllSc;
  const { data, isError, isLoading, isFetching, isSuccess } = useGetAllSchoolsQuery({
    nolimit: 'true',
  });
  const formInputs = [
    {
      mainTitle: 'Student Information',
      name: 'School',
      label: 'School',
      type: 'text',
      placeholder: 'Select a school',
      isTrue: true,
      isSelect: true,
      options: data
        ? data.map((o: any) => {
            const model = new SchoolModel(o);
            return { id: model.id, name: model.getOptionName() };
          })
        : [],
    },
    {
      mainTitle: 'Student Information',
      name: 'firstName',
      label: 'First Name',
      type: 'text',
      placeholder: 'Enter first name',
    },
    {
      mainTitle: 'Student Information',
      name: 'lastName',
      label: 'Last Name',
      type: 'text',
      placeholder: 'Enter last name',
    },
    {
      mainTitle: 'Student Information',
      name: 'username',
      label: 'Username',
      type: 'text',
      placeholder: 'Enter username',
      // isTrue: true,
    },
    {
      mainTitle: 'Student Information',
      name: 'gradeLevel',
      label: 'Grade Level',
      type: 'text',
      placeholder: 'Ex. 6th grade ',
      isSelect: true,
      options: gradeLevels,
    },
    // {
    //   mainTitle: "Student Information",
    //   name: "Personalemail",
    //   label: "Personal Email address",
    // type:'text',
    //   placeholder: "Enter your personal email address",
    // },
    // {
    //   mainTitle: 'Student Information',
    //   name: 'streetAddress',
    //   label: 'Street Address',
    //   type: 'text',
    //   placeholder: 'Enter street address',
    // },
    // {
    //   mainTitle: 'Student Information',
    //   name: 'city',
    //   label: 'City',
    //   type: 'text',
    //   placeholder: 'Enter city',
    // },
    // {
    //   mainTitle: 'Student Information',
    //   name: 'state',
    //   label: 'State',
    //   type: 'text',
    //   placeholder: 'Enter state',
    //   isSelect: true,
    //   options: allStates ? allStates : [],
    // },
    // {
    //   mainTitle: 'Student Information',
    //   name: 'zipCode',
    //   label: 'Zip Code',
    //   type: 'number',
    //   placeholder: 'Enter zip code',
    // },
    {
      mainTitle: 'Student Information',
      name: 'gender',
      label: 'Gender',
      type: 'text',
      isSelect: true,
      options: gendersOptions,
    },
    // {
    //   mainTitle: 'Student Information',
    //   name: 'dateOfBirth',
    //   label: 'Date Of Birth',
    //   type: 'date',
    //   placeholder: 'Enter date of birth',
    // },
    {
      mainTitle: 'Student Information',
      name: 'status',
      label: 'Status',
      type: 'text',
      placeholder: 'Active',
      isSelect: true,
      options: [
        { id: 0, name: 'Disabled' },
        { id: 1, name: 'Active' },
      ],
    },
    {
      mainTitle: 'Student Information',
      name: 'email',
      label: 'Email Address',
      type: 'text',
      placeholder: 'Enter email address',
    },
    // {
    //   mainTitle: 'Student Information',
    //   name: 'phoneNumber',
    //   label: 'Phone Number',
    //   type: 'text',
    //   placeholder: 'Enter phone number (optional)',
    // },
  ];
  const initialValues: { [key: string]: string } = {};
  formInputs.forEach((input) => (initialValues[input.name] = ''));
  if (studentIsSuccess) return <Navigate to='../' />;
  return (
    <>
      <Formik
        initialValues={{ ...initialValues, eligibilityStatus: true, ageAgreement: false }}
        validationSchema={NewStudentSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, errors, values, setFieldValue }) => (
          <Form>
            <div className='flex flex-col gap-2'>
              {formInputs.map((input: any, i: number) => (
                <React.Fragment key={i}>
                  {!formInputs[i - 1] || formInputs[i - 1]?.mainTitle !== input.mainTitle ? (
                    <>
                      <div className='flex flex-col'>
                        <div className='mb-2'>
                          <p className='text-gray-800 font-bold pt-6'>{input.mainTitle}</p>
                          <hr />
                        </div>
                        <div className='flex flex-wrap gap-1'>
                          {formInputs
                            .filter((inp1: any) => inp1.mainTitle === input.mainTitle)
                            .map((inp: any, i: number) => (
                              <div
                                key={i}
                                className={inp.isTrue ? 'w-full ' : 'flex-1 min-w-[300px]'}
                              >
                                <FormInput {...inp} />
                              </div>
                            ))}
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </React.Fragment>
              ))}
            </div>
            {/* <input name="eligibilityStatus" className="mb-3" type="checkbox" />{" "} */}
            <div>
              <Field type='checkbox' id='ageAgreement' name='ageAgreement' />
              <label htmlFor='ageAgreement'>Student is 13 years old or has parental approval</label>
              <ErrorMessage name='ageAgreement' component='div' className='text-red-400 text-xs' />
            </div>
            <div className='mb-2'>
              <Field
                type='checkbox'
                id='eligibilityStatus'
                name='eligibilityStatus'
                checked={values.eligibilityStatus}
                onChange={(e: any) => {
                  setFieldValue('eligibilityStatus', e.target.checked);
                }}
              />
              <label htmlFor='eligibilityStatus'>Eligible to participate</label>
            </div>
            {/* footer*/}
            {/* <div className='flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b'>
            
              <Link
                className='w-1/5 my-12 text-center py-1 bg-white text-black shadow-lg font-semibold rounded-lg'
                to='../'
              >
                Cancel
              </Link>

              <SubmitButton isLoading={studentIsLoading} />
            </div> */}

            <ModalFooter isLoading={studentIsLoading!} emailError={error ? error : ''} />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default NewStudentModal;
