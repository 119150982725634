import * as yup from 'yup';
import { dateToLocal } from '../../../../../../../utils/dateFormatter';

const today = new Date();
today.setHours(0, 0, 0, 0);

export const EditSeasonVSchema = (startDate: any, isLeaderboard: any) => {
  const startDateObj = new Date(startDate);
  startDateObj.setDate(startDateObj.getDate() - 1);
  return yup.object().shape({
    // game_id: yup.string().required('Required!'),
    name: yup
      .string()
      .nullable()
      .matches(
        /^[a-zA-ZÀ-ÖØ-öø-ÿÇç0-9\s-,.'"]{0,50}$/,
        'Only letters, numbers, spaces, and hyphens are allowed (maximum 50 characters)',
      )
      .required('Required!'),
    // game_mode: yup.string().required('Required!'),
    // seasonCategoryId: yup.string().required('Required!'),
    division_id: yup.string().required('Required!'),
    state: yup.string().required('Required!'),
    // seasonTypeId: yup.string().required('Required!'),
    subdivision: yup.string().nullable(),
    start_date: yup
      .date()
      // .min(
      //   startDate > today ? today : startDate,
      //   'Start date should not be in the past',
      // )
      // .min(
      //   startDateObj.toISOString() > dateToLocal(today)
      //     ? dateToLocal(today)
      //     : startDateObj.toISOString(),
      //   'Start date should not be in the past',
      // )
      // .min(

      // ),
      .transform((value, originalValue) => {
        if (originalValue) {
          return new Date(originalValue);
        }
        return value;
      }),
    // .required('Required!'),
    end_date: yup
      .date()
      .min(yup.ref('start_date'), "End date can't be before start date")
      .required('Required!'),
    description: yup.string().required('Required!'),
    teams: yup.string().nullable(),
    // format_id: !Number(isLeaderboard) ? yup.string().required('Required!') : yup.string().nullable(),
  });
};
