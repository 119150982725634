import { StudentCoachHoursRequestModel } from './student-coach-hours-request.model';

export class SchoolRequestsTitleModel {
  schools: string[] = [];
  requests: StudentCoachHoursRequestModel[] = [];
  constructor(d: any[]) {
    d.forEach((d) => this.addSchoolName(d));
    d.forEach((d) => this.requests.push(new StudentCoachHoursRequestModel(d)));
  }
  addSchoolName(d: any) {
    if (d.hasOwnProperty('user')) {
      if (d?.user?.hasOwnProperty('school')) {
        if (
          d?.user?.school?.hasOwnProperty('name') &&
          d?.user?.school?.hasOwnProperty('coach_hours') &&
          d?.user?.school?.hasOwnProperty('id')
        ) {
          this.schools.push(
            JSON?.stringify({
              name: d?.user?.school?.name,
              hours: d?.user?.school?.coach_hours,
              id: d?.user?.school?.id,
            }),
          );
        }
      }
    }
  }
  getSchools() {
    return new Array(...new Set(this.schools));
  }
}
