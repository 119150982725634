import * as yup from 'yup';
import {
  nameRulesHint,
  nameRulesWithSpecialCharacter,
  passwordRules,
  passwordRulesHint,
  usPhoneRules,
  usPhoneRulesHint,
  zipCodeRules,
  zipCodeRulesHint
} from '../../yupschema';
import moment from 'moment';
import { ErrorMessages } from '../../../../utils/messages.enum';

export const fanSchema = yup.object().shape({
  firstName: yup.string().matches(nameRulesWithSpecialCharacter, nameRulesHint).required('Required!'),
  lastName: yup.string().matches(nameRulesWithSpecialCharacter, nameRulesHint).required('Required!'),
  streetAddress: yup.string().required('Required!'),
  city: yup.string().required('Required!').matches(/^[a-zA-ZÀ-ÖØ-öø-ÿÇç\s-,.'"]+$/, ErrorMessages.CityValidation),
  state: yup.string().required('Required!'),
  postal: yup.string().matches(zipCodeRules, zipCodeRulesHint).required('Required!'),
  phoneNumber: yup.string().matches(usPhoneRules, usPhoneRulesHint).required('Required!'),
  dateOfBirth: yup
    .date()
    .max(moment().endOf('day').subtract(13, 'years'), 'You must be at least 13 years old!')
    .required('Required'),
  email: yup.string().email('Please enter a valid email').required('Required!'),
  password: yup
    .string()
    .min(5)
    .matches(passwordRules, { message: passwordRulesHint })
    .required('Required!'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Required!'),
  check: yup.bool().oneOf([true], 'You must accept terms and conditions!'),
});
