import * as yup from 'yup';
export const OgmaSchoolCoachHoursSchema = yup.object().shape({
  start_time: yup.string().required('Required'),
  end_time: yup
    .string()
    .test('test', 'End time should be different from start time', (value, ctx) => {
      return value !== ctx.parent.start_time;
    })
    .required('Required'),
});
