import { AiOutlineClose } from "react-icons/ai"
import { OutsideClick } from "../../components/outside-click"
import { SubmitButton } from "../../components/submit-button"
import { useDeleteUserMutation, useGetUserByIdQuery } from "../../slices/userApiSlice";
import { Navigate, useParams } from "react-router";
import { Link } from "react-router-dom";

const OgmaInvitedUsersCancel = () => {
    const param = useParams();
    const [deleteInvitation, { isLoading, isSuccess }] = useDeleteUserMutation();
    const {data,isLoading:userLoad,isError}=useGetUserByIdQuery(param.id)

    const handleSubmit = () => {
        deleteInvitation(param?.id);
    };

    if (isSuccess||isError || !data) {
        return <Navigate to="../" />;
    };
    return (
        <>
            <OutsideClick />
            <div className='fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-10 w-auto mx-auto max-w-2xl bg-white p-10 flex flex-col gap-10 items-center justify-center rounded-xl'>
                <div className='absolute top-2 right-2'>
                    <AiOutlineClose
                        size={'2rem'}
                        className='hover:rounded-full hover:bg-gray-100 p-2 cursor-pointer'
                    />
                </div>
                <div className='py-4 flex flex-col justify-center items-center gap-8'>
                    <p className='select-none font-medium'>
                        Are you sure you want to cancel invitation link?
                    </p>
                    <div className='flex gap-2'>
                        <SubmitButton onSubmit={handleSubmit} title='Confirm' isLoading={isLoading} />
                        <Link
                            to='../'
                            className='p-4 px-8 text-center text-sm py-2 bg-white text-black border font-semibold rounded-lg hover:bg-gray-100'
                        >
                            No
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
};

export default OgmaInvitedUsersCancel;