import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { OrganizationCoachModel } from '../model/organization-coach.model';
import { ClassroomModel } from '../model/classroom.model';

interface MyState {
  notificationSettings: { [key: string]: any };
  teamUserList: Array<any>;
  dashboardCoaches: Array<OrganizationCoachModel>;
  dashboardClassrooms: Array<ClassroomModel | any>;
}
const initialState: MyState = {
  notificationSettings: {},
  teamUserList: [],
  dashboardCoaches: [],
  dashboardClassrooms: [],
};
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setNotificationSettings: (state, action) => {
      state.notificationSettings = action.payload;
    },
    setTeamUserList: (state, action) => {
      state.teamUserList = action.payload;
    },
    initDashboardCoaches: (state, action) => {
      state.dashboardCoaches = action.payload;
    },
    addDashboardCoaches: (state, action) => {
      state.dashboardCoaches.push(action.payload);
    },
    initDashboardClassrooms: (state, action) => {
      state.dashboardClassrooms = action.payload;
    },
    addDashboardClassrooms: (state, action) => {
      state.dashboardClassrooms.push(action.payload);
    },
  },
});

export const {
  setNotificationSettings,
  setTeamUserList,
  addDashboardCoaches,
  initDashboardCoaches,
  addDashboardClassrooms,
  initDashboardClassrooms,
} = userSlice.actions;
const selectSelf = (state: RootState) => state.user;
export const selectTeamUserList = createSelector(selectSelf, (state) => state.teamUserList);
export const selectDashboardCoaches = createSelector(selectSelf, (state) => state.dashboardCoaches);

export const selectDashboardClassrooms = createSelector(
  selectSelf,
  (state) => state.dashboardClassrooms,
);
export default userSlice.reducer;
