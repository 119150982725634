import { FiShare } from 'react-icons/fi';
import { MdCloudDone } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectUser } from '../../../slices/auth';
import { MatchResultCircle } from './match-result-circle';
import CanIView from '../../../components/can-i-view/can-i-view';
import { AiFillEdit } from 'react-icons/ai';
import { RiScreenshot2Line } from 'react-icons/ri';
import { useStateContext } from '../../../contexts/ContextProvider';
import { MatchGameStatus } from '../../../utils/constants';
import { MdDelete } from 'react-icons/md';

export function MatchGameRow({
  id,
  index,
  game,
  hScore,
  aScore,
  game_screenshots,
  screenshot_status,
  matchStatus,
  canEdit,
  isCompleted,
}: {
  id: number;
  index: number;
  game: string;
  hScore: number;
  aScore: number;
  game_screenshots: any;
  screenshot_status: number | boolean;
  matchStatus: boolean;
  canEdit: boolean;
  isCompleted?: boolean;
}) {
  const user = useSelector(selectUser);
  const { isScreenshoot, uploadScreenshootId } = useStateContext();

  return (
    <div
      className={`flex p-2 min-w-[1px]  flex-1  gap-justify-between items-center ${!(index % 2) ? 'bg-gray-200' : 'bg-gray-100'
        } ${canEdit ? 'justifyBetween' : 'justify-evenly'}`}
    >
      <span className='uppercase w-[50px] text-[12px] leading-tight mr-4'>{game}</span>
      <span
        className={`flex gap-[5%] ${canEdit ? 'justify-end flex-1 w-full  ' : 'w-1/2 justify-center'
          }`}
      >
        <div className='flex min-w-[50%]  justify-between'>
          <MatchResultCircle result={screenshot_status === MatchGameStatus.Closed ? hScore : '-'} />
          {screenshot_status === MatchGameStatus.Closed && hScore === aScore ? 'Tie' : ''}
          {screenshot_status === MatchGameStatus.Review ? 'Missmatch' : ''}
          <MatchResultCircle result={screenshot_status === MatchGameStatus.Closed ? aScore : '-'} />
        </div>
        {matchStatus && canEdit ? (
          <div className='flex min-w-[50%] justify-between'>
            {screenshot_status === MatchGameStatus.Closed ? (
              <>
                <Link
                  to={`./edit-game-results/${id}`}
                  className='group flex-col relative  hover:bg-slate-100 cursor-pointer p-1  h-[30px] flex items-center justify-center bg-slate-200 rounded-lg px-2'
                >
                  <FiShare color='green' />
                </Link>

                <CanIView component='editGameresults'>
                  <Link
                    to={`./update-game-results/${id}`}
                    className='group flex-col relative hover:bg-slate-100 cursor-pointer p-1  h-[30px] flex items-center justify-center bg-slate-200 rounded-lg px-2'
                  >
                    <AiFillEdit color='orange' />
                  </Link>
                </CanIView>
              </>
            ) : user.roles === 0 && isScreenshoot && uploadScreenshootId == id ? (
              <CanIView component='studentgameresults'>
                <Link
                  to={`./edit-game-results/${id}`}
                  className='group flex-col relative  hover:bg-slate-100 cursor-pointer p-1  h-[30px] flex items-center justify-center bg-slate-200 rounded-lg px-2'
                >
                  <FiShare color='green' className='animate-pulse' />
                </Link>
              </CanIView>
            ) : (
              <CanIView component='studentgameresults'>
                <Link
                  to={`./edit-game-results/${id}`}
                  className='group flex-col relative  hover:bg-slate-100 cursor-pointer p-1  h-[30px] flex items-center justify-center bg-slate-200 rounded-lg px-2'
                >
                  <FiShare color={screenshot_status === MatchGameStatus.Review ? 'green' : ''} />
                </Link>
              </CanIView>
            )}
            <CanIView component='gameresults'>
              {user.roles === 0 && game_screenshots?.length ? (
                <Link
                  to={`./submit-game-results/${id}`}
                  className={`group flex-col relative bg-gray-200 hover:bg-slate-100 cursor-pointer p-1  h-[30px] flex items-center justify-center  rounded-lg px-2`}
                >
                  <MdCloudDone color='green' />
                </Link>
              ) : user.roles === 0 && screenshot_status ? (
                <div
                  className={`group flex-col relative bg-gray-200 hover:bg-slate-100 cursor-pointer p-1  h-[30px] flex items-center justify-center  rounded-lg px-2`}
                >
                  <MdCloudDone color='green' className='animate-pulse' />
                </div>
              ) : user.roles === 0 && uploadScreenshootId.includes(id.toString()) ? (
                <Link
                  to={`./submit-game-results/${id}`}
                  className={`group flex-col relative bg-gray-200 hover:bg-slate-100 cursor-pointer p-1  h-[30px] flex items-center justify-center  rounded-lg px-2`}
                >
                  <MdCloudDone color='green' />
                </Link>
              ) : (
                <Link
                  to={`./submit-game-results/${id}`}
                  className={`group flex-col relative bg-gray-200 hover:bg-slate-100 cursor-pointer p-1  h-[30px] flex items-center justify-center  rounded-lg px-2`}
                >
                  <MdCloudDone />
                </Link>
              )}
            </CanIView>
            <CanIView component='view-screenshot'>
              <Link
                to={`./view-screenshot/${id}`}
                className='group flex-col relative hover:bg-slate-100 cursor-pointer p-1 h-[30px] flex items-center justify-center bg-slate-200 rounded-lg px-2'
              >
                <RiScreenshot2Line size={25} color=
                  {
                    game_screenshots?.length ||
                      screenshot_status === MatchGameStatus.Closed ||
                      uploadScreenshootId.includes(id?.toString()) ? 'green' : ''
                  }
                />
              </Link>
            </CanIView>
            <CanIView component='view-match.delete-match-game'>
              <Link
                to={`./delete-match-game/${id}`}
                className='group flex-col relative hover:bg-slate-100 cursor-pointer p-1 h-[30px] flex items-center justify-center bg-slate-200 rounded-lg px-2'
              >
                <MdDelete />
              </Link>
            </CanIView>
          </div>
        ) : (
          <>
            <CanIView component='editGameresults'>
              <Link
                to={`./update-game-results/${id}`}
                className='group flex-col relative hover:bg-slate-100 cursor-pointer p-1  h-[30px] flex items-center justify-center bg-slate-200 rounded-lg px-2'
              >
                <AiFillEdit color='orange' />
              </Link>
            </CanIView>
            <CanIView component='view-match.delete-match-game'>
              <Link
                to={`./delete-match-game/${id}`}
                className='group flex-col relative hover:bg-slate-100 cursor-pointer p-1  h-[30px] flex items-center justify-center bg-slate-200 rounded-lg px-2'
              >
                <MdDelete />
              </Link>
            </CanIView>
          </>
        )}
      </span>
    </div>
  );
};