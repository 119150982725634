import { UserRoles } from '../../../utils/constants';

export const viewMatchPermissions = {
  'view-match.go-live': [UserRoles.Admin, UserRoles.Ogma],
  'view-match.start-game': [UserRoles.Admin],
  'view-match.end-game': [UserRoles.Admin, UserRoles.Ogma, UserRoles.Student],
  'view-match.reschedules': [UserRoles.Student, UserRoles.Ogma],
  'view-match.reschedules.ogma-status': [UserRoles.Student],
  'view-match.reschedules.captain-status': [UserRoles.Ogma],
  'view-match.disputes': [UserRoles.Admin],
  'view-match.edit-match': [UserRoles.Admin],
  'view-match.team-chat': [UserRoles.Student],
  'view-match.forfeit': [UserRoles.Student],
  'view-match.streaming-info': [UserRoles.Ogma],
  'view-screenshot': [UserRoles.Student, UserRoles.Ogma],
  'view-match.delete-match-game': [UserRoles.Admin],
  'view-match.create-match-game': [UserRoles.Admin],
  'view-match.dbl-forfeit': [UserRoles.Admin],
  'view-match.onlyForfeit': [UserRoles.Admin, UserRoles.Ogma],
  'view-match.forfeits': [UserRoles.Admin, UserRoles.Ogma],
};
