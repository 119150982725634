import * as yup from 'yup';
import moment from 'moment';
import { ErrorMessages } from '../../utils/messages.enum';

export const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
export const passwordRulesHint =
  'At least one digit, one lowercase letter, one uppercase letter, and a minimum length of 8 characters.';
export const actualPassword = 'Password should match the current password';
// export const nameRules = /^[a-zA-Z'\-]+$/;
export const nameRules = /^[a-zA-Z -]{1,30}$/;
export const nameRulesWithSpecialCharacter = /^[a-zA-ZÀ-ÖØ-öø-ÿÇç\s-'"]{0,30}$/;
export const nameRulesHint =
  'Enter your first name (1-30 characters), using letters (uppercase or lowercase) and hyphens only.';
//  export const usernameRules = /^[\p{L}\d_\-]+$/;
export const lastnameRulesHint =
  'Enter your last name (1-30 characters), using letters (uppercase or lowercase), spaces and hyphens only.';
export const usernameRules = /^[a-zA-ZÀ-ÖØ-öø-ÿÇç0-9\s-,.'"]{3,30}$/;
export const usernameRulesHint =
  'Enter a username (3-30 characters), using letters (uppercase or lowercase), numbers, spaces, hyphens, commas, periods, or apostrophes';
export const usPhoneRules =
  /^[+]?(1\-|1\s|1|\d{3}\-|\d{3}\s|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/;
export const usPhoneRulesHint =
  'Enter a valid US phone number in various formats, including with or without a country code, hyphens, spaces, or parentheses. Examples: +1 (123) 456-7890 or 123-456-7890.';
export const zipCodeRules = /^[0-9]{5}[-\s]?(?:[0-9]{4})?$/;
export const zipCodeRulesHint =
  'Enter a valid US ZIP code in different formats, without a dash or space between the first five digits, and an optional four-digit extension. Examples: 12345 or 123456789.';
// export const emailRules = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
// export const emailRules = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
export const emailRules =
  /^(?=.{1,64}@)[a-zA-Z0-9_-]+(?:\.[a-zA-Z0-9_-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,63}$/;
export const emailRulesHint =
  "Enter a valid email address using letters, numbers, and special characters such as '.', '_', '%', '+', and '-'. Avoid typos and include the '@' symbol followed by your email provider's domain name.";
export const subsLimitRules = /^[+]?\d+$/;
export const cityRules = /^[a-zA-ZÀ-ÖØ-öø-ÿÇç\s-,.'"]+$/;
export const validEmailRule = 'Enter a valid email';

export const guardianSchema = yup.object().shape({
  firstName: yup
    .string()
    .matches(nameRulesWithSpecialCharacter, nameRulesHint)
    .required('Required!'),
  lastName: yup
    .string()
    .matches(nameRulesWithSpecialCharacter, nameRulesHint)
    .required('Required!'),
  streetAddress: yup.string().required('Required!'),
  city: yup.string().matches(cityRules, ErrorMessages.CityValidation).required('Required!'),
  state: yup.string().required('Required!'),
  postal: yup.string().matches(zipCodeRules, zipCodeRulesHint).required('Required!'),
  phoneNumber: yup.string().matches(usPhoneRules, usPhoneRulesHint).required('Required!'),
  // dateOfBirth: yup.string().required('Required'),
  dateOfBirth: yup
    .date()
    .max(moment().endOf('day').subtract(18, 'years'), 'User must be at least 18 years old!')
    .required('Required!'),
  email: yup.string().email('Enter a valid email').required('Required!'),
  studentEmail: yup.string().email('Enter a valid email').required('Required!'),
  password: yup
    .string()
    .min(5)
    .matches(passwordRules, { message: passwordRulesHint })
    .required('Required!'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Required!'),
});

export const loginSchema = yup.object().shape({
  email: yup.string().required('Required!').matches(emailRules, validEmailRule),
  password: yup
    .string()
    // .min(5)
    // .matches(passwordRules, { message: 'Your email is invalid' })
    .required('Required!'),
});

export const NewUser = yup.object().shape({
  // Role: yup.string().required("Required"),

  School: yup.string().required('Required!'),
  firstName: yup.string().matches(nameRules, nameRulesHint).required('Required!'),
  lastName: yup.string().matches(nameRules, nameRulesHint).required('Required!'),
  // Personalemail: yup
  //   .string()
  //   .email("Please enter a valid email")
  //   .required("Required"),
  email: yup.string().email('Enter a valid email').required('Required!'),
  username: yup.string().matches(usernameRules, usernameRulesHint).required('Required!'),
  gradeLevel: yup.string().required('Required!'),
  phoneNumber: yup.string().matches(usPhoneRules, usPhoneRulesHint).required('Required!'),
  state: yup.string().required('Required!'),
  city: yup
    .string()
    .required('Required!')
    .matches(cityRules, ErrorMessages.CityValidation)
    .required('Required!'),
  streetAddress: yup
    .string()
    .required('Required!')
    .matches(/^[0-9a-zA-Z\s]+$/, 'Street address must only contain letters, spaces and numbers'),
  dateOfBirth: yup
    .date()
    .max(moment().endOf('day').subtract(13, 'years'), 'User must be at least 13 years old!')
    .required('Required!'),
  zipCode: yup.string().matches(zipCodeRules, zipCodeRulesHint).required('Required!'),
  status: yup.string().required('Required!'),
  eligibilityStatus: yup.bool().oneOf([false, true], 'check'),
});

export const NewStudentSchema = yup.object().shape({
  School: yup.string().required('Required!'),
  firstName: yup
    .string()
    .matches(nameRulesWithSpecialCharacter, nameRulesHint)
    .required('Required!'),
  lastName: yup
    .string()
    .matches(nameRulesWithSpecialCharacter, nameRulesHint)
    .required('Required!'),
  email: yup.string().required('Required!').matches(emailRules, validEmailRule),
  username: yup.string().matches(usernameRules, usernameRulesHint).required('Required!'),
  gradeLevel: yup.string().required('Required!'),
  // phoneNumber: yup.string().matches(usPhoneRules, usPhoneRulesHint).required('Required!'),
  // state: yup.string(),
  // city: yup.string().matches(cityRules, ErrorMessages.CityValidation),
  // streetAddress: yup
  //   .string()
  //   .matches(/^[0-9a-zA-Z\s]+$/, 'Street address must only contain letters, spaces and numbers'),
  // dateOfBirth: yup
  //   .date()
  //   .nullable()
  //   .notRequired()
  //   .min(
  //     new Date(new Date().getFullYear() - 100, 0, 1),
  //     `User must be born on or after January 1, ${new Date(
  //       new Date().getFullYear() - 100,
  //       0,
  //       1,
  //     ).getFullYear()}`,
  //   )
  //   .max(moment().endOf('day').subtract(11, 'years'), 'User must be at least 11 years old!'),
  // zipCode: yup.string().matches(zipCodeRules, zipCodeRulesHint).nullable(),
  gender: yup.string().required('Required'),
  status: yup.string().required('Required!'),
  eligibilityStatus: yup.bool().oneOf([false, true], 'check'),
  ageAgreement: yup.bool().oneOf([true], 'Required!'),
});

export const NewOGMA = yup.object().shape({
  organizationName: yup.string().required('Required!'),
  firstName: yup.string().matches(nameRules, nameRulesHint).required('Required!'),
  lastName: yup.string().matches(nameRules, nameRulesHint).required('Required!'),
  streetAddress: yup.string().required('Required!'),
  city: yup.string().matches(cityRules, ErrorMessages.CityValidation).required('Required!'),
  state: yup.string().required('Required!'),
  zipCode: yup.string().matches(zipCodeRules, zipCodeRulesHint).required('Required!'),
  email: yup.string().email('Enter a valid email').required('Required!'),
  phoneNumber: yup.string().matches(usPhoneRules, usPhoneRulesHint).required('Required!'),
  // school: yup.string().required("Required"),
  // manager: yup.string().required("Required"),
  // tags: yup.string().required("Required"),
  // status: yup.string().required("Required"),
});
export const NewCoach = yup.object().shape({
  // Role: yup.string().required("Required"),
  firstName: yup.string().matches(nameRules, nameRulesHint).required('Required!'),
  lastName: yup.string().matches(nameRules, nameRulesHint).required('Required!'),
  username: yup.string().matches(usernameRules, usernameRulesHint).required('Required!'),
  // streetAddress: yup.string().required('Required!'),
  city: yup.string().matches(cityRules, ErrorMessages.CityValidation).required('Required!'),
  state: yup.string().required('Required!'),
  zipCode: yup.string().matches(zipCodeRules, zipCodeRulesHint).required('Required!'),
  gender: yup.string().required('Required!'),
  // dateOfBirth: yup.string().required('Required!'),
  // dateOfBirth: yup
  //   .string()
  //   .required('Required!')
  //   .test('age', 'You must be at least 18 years old', function (value: any) {
  //     const today = new Date();
  //     const birthDate = new Date(value);
  //     const age = today.getFullYear() - birthDate.getFullYear();
  //     return age >= 18;
  //   }),
  // yearsOfExperience: yup.string().required('Required'),
  // tags: yup.string().required('Required!'),
  // email: yup.string().email('Enter a valid email').required('Required!'),
  email: yup.string().required('Required!').matches(emailRules, validEmailRule),
  phoneNumber: yup.string().matches(usPhoneRules, usPhoneRulesHint).required('Required!'),
  // status: yup.string().required('Required!'),
});

export const NewFan = yup.object().shape({
  Role: yup.string().required('Required!'),
  firstName: yup.string().matches(nameRules, nameRulesHint).required('Required!'),
  lastName: yup.string().matches(nameRules, nameRulesHint).required('Required!'),
  streetAddress: yup.string().required('Required!'),
  city: yup.string().matches(cityRules, ErrorMessages.CityValidation).required('Required!'),
  state: yup.string().required('Required!'),
  postal: yup.string().matches(zipCodeRules, zipCodeRulesHint).required('Required!'),
  gender: yup.string().required('Required!'),
  dateOfBirth: yup.string().required('Required!'),
  phoneNumber: yup.string().matches(usPhoneRules, usPhoneRulesHint).required('Required!'),
  email: yup.string().email('Enter a valid email').required('Required!'),
});

export const AdminAccountSettings = yup.object().shape({
  firstName: yup.string().matches(nameRules, nameRulesHint).required('Required!'),
  lastName: yup.string().matches(nameRules, nameRulesHint).required('Required!'),
  userName: yup.string(),
  emailAddress: yup.string().matches(emailRules, emailRulesHint),
  streetAddress: yup.string().required('Required!'),
  city: yup.string().matches(cityRules, ErrorMessages.CityValidation).required('Required!'),
  state: yup.string().required('Required!'),
  zipCode: yup.string().matches(zipCodeRules, zipCodeRulesHint).required('Required!'),
  gender: yup.string().required('Required!'),
  dateOfBirth: yup
    .date()
    .max(moment().endOf('day').subtract(18, 'years'), 'User must be at least 18 years old!')
    .required('Required!'),

  phoneNumber: yup.string().matches(usPhoneRules, usPhoneRulesHint).required('Required!'),
  // email: yup.string().email('Please enter a valid email').required('Required'),
});

export const OGMAAccountSettings = yup.object().shape({
  firstName: yup.string().matches(nameRules, nameRulesHint).required('Required!'),
  lastName: yup.string().matches(nameRules, nameRulesHint).required('Required!'),
  streetAddress: yup.string().required('Required!'),
  city: yup.string().matches(cityRules, ErrorMessages.CityValidation).required('Required!'),
  state: yup.string().required('Required!'),
  postal: yup.string().matches(zipCodeRules, zipCodeRulesHint).required('Required!'),
  gender: yup.string().required('Required!'),
  DateofBirth: yup.string().required('Required!'),
  phoneNumber: yup.string().matches(usPhoneRules, usPhoneRulesHint).required('Required!'),
  email: yup.string().email('Enter a valid email').required('Required!'),
  occupation: yup.string().email('Enter a valid email').required('Required!'),
});

export const StudentAccountSettings = yup.object().shape({
  firstName: yup.string().matches(nameRules, nameRulesHint).required('Required!'),
  lastName: yup.string().matches(nameRules, nameRulesHint).required('Required!'),
  school: yup.string().required('Required!'),
  username: yup.string().matches(usernameRules, usernameRulesHint).required('Required!'),
  email: yup.string().email('Enter a valid email').required('Required!'),
  studentEmail: yup.string().required('Required!'),
});

export const CoachAccountSettings = yup.object().shape({
  firstName: yup.string().matches(nameRules, nameRulesHint).required('Required!'),
  lastName: yup.string().matches(nameRules, nameRulesHint).required('Required!'),
  streetAddress: yup.string().required('Required!'),
  city: yup.string().matches(cityRules, ErrorMessages.CityValidation).required('Required!'),
  state: yup.string().required('Required!'),
  postal: yup.string().matches(zipCodeRules, zipCodeRulesHint).required('Required!'),
  DateofBirth: yup.string().required('Required!'),
  gender: yup.string().required('Required!'),
  phoneNumber: yup.string().matches(usPhoneRules, usPhoneRulesHint).required('Required!'),
  email: yup.string().email('Enter a valid email').required('Required!'),
});

export const ChangePassword = yup.object().shape({
  password: yup.string().required('Required!'),
  newPassword: yup
    .string()
    .matches(passwordRules, { message: passwordRulesHint })
    .required('Required!'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
    .required('Required!'),
});
export const ResetPasswordValidation = yup.object().shape({
  newPassword: yup
    .string()
    .matches(passwordRules, { message: passwordRulesHint })
    .required('Required!'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
    .required('Required!'),
});
export const GuardianAccountSettings = yup.object().shape({
  firstName: yup.string().matches(nameRules, nameRulesHint).required('Required!'),
  lastName: yup.string().matches(nameRules, nameRulesHint).required('Required!'),
  streetAddress: yup.string().required('Required!'),
  city: yup.string().matches(cityRules, ErrorMessages.CityValidation).required('Required!'),
  state: yup.string().required('Required!'),
  postal: yup.string().matches(zipCodeRules, zipCodeRulesHint).required('Required!'),
  DateofBirth: yup.string().required('Required!'),
  gender: yup.string().required('Required!'),
  phoneNumber: yup.string().matches(usPhoneRules, usPhoneRulesHint).required('Required!'),
  email: yup.string().email('Enter a valid email').required('Required!'),
});

export const FanAccountSettings = yup.object().shape({
  firstName: yup.string().matches(nameRules, nameRulesHint).required('Required!'),
  lastName: yup.string().matches(nameRules, nameRulesHint).required('Required!'),
  streetAddress: yup.string().required('Required!'),
  city: yup.string().matches(cityRules, ErrorMessages.CityValidation).required('Required!'),
  state: yup.string().required('Required!'),
  postal: yup.string().matches(zipCodeRules, zipCodeRulesHint).required('Required!'),
  DateofBirth: yup.string().required('Required!'),
  gender: yup.string().required('Required!'),
  phoneNumber: yup.string().matches(usPhoneRules, usPhoneRulesHint).required('Required!'),
  email: yup.string().email('Enter a valid email').required('Required!'),
});

export const UpdateOrganization = yup.object().shape({
  name: yup
    .string()
    .matches(
      /^[a-zA-ZÀ-ÖØ-öø-ÿÇç0-9\s-,.'"]{0,50}$/,
      'Only letters, numbers, spaces, and hyphens are allowed (maximum 50 characters)',
    )
    .required('Required!'),
  street_address: yup.string().required('Required!'),
  city: yup.string().matches(cityRules, ErrorMessages.CityValidation).required('Required!'),
  state: yup
    .string()
    .required('Required!')
    .matches(
      /^[a-zA-ZÀ-ÖØ-öø-ÿÇç0-9\s-,.'"]{0,50}$/,
      'Only letters, numbers, spaces, and hyphens are allowed (maximum 50 characters)',
    ),

  // .matches(/^[a-zA-Z\s]+$/, 'State name must only contain letters and spaces'),
  zip_code: yup.string().matches(zipCodeRules, zipCodeRulesHint).required('Required!'),
  email: yup.string().matches(emailRules, emailRulesHint).required('Required!'),
  phone_number: yup.string().matches(usPhoneRules, usPhoneRulesHint).required('Required!'),
  // tags: yup.string().required('Required!'),
  status: yup.string().required('Required!'),
});

export const FirstTimeYup = yup.object().shape({
  newPassword: yup
    .string()
    .min(5)
    .matches(passwordRules, { message: passwordRulesHint })
    .required('Required!'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
    .required('Required!'),
});
export const NewTeamModal = {
  game: yup.string().required('Required!'),
  teamName: yup.string().required('Required!'),
  school: yup.string().required('Required!'),
};

export const NewCategoryDivision = yup.object().shape({
  name: yup.string().required('Required!'),
});

export const NewSupportCategoryValidationSchema = yup.object().shape({
  category: yup.string().trim().required('Required!'),
});

export const EditSupportCategoryValidationSchema = yup.object().shape({
  name: yup.string().trim().required('Required!'),
});

export const SupportSubCategoryValidationSchema = yup.object().shape({
  name: yup.string().trim().required('Required!'),
  supportCategoryId: yup.string().required('Required!'),
  priorityLevel: yup.string().required('Required!'),
  // responseLevel: yup.string().required('Required!'),
  // severityLevel: yup.string().required('Required!'),
});

export const CreateNewTicketValidationSchema = yup.object().shape({
  subject: yup.string().required('Required!'),
  supportCategoryId: yup.string().required('Required!'),
  supportSubcategoryId: yup.string().required('Required!'),
  description: yup.string().required('Required!'),
  link: yup
    .string()
    .matches(
      /^https?:\/\/(app-test\.fenworks\.com|app-staging\.fenworks\.com|app\.fenworks\.com)(\/.*)?$/,
      'Please enter a valid Fewnworks URL',
    )
    .nullable()
    .notRequired(),
});

export const NewSubDivisionValidationSchema = yup.object().shape({
  division_id: yup.string().required('Required!'),
  name: yup.string().required('Required!'),
});

export const EditSubDivisionValidationSchema = yup.object().shape({
  division_id: yup.string().required('Required!'),
  name: yup.string().required('Required!'),
});

const today = new Date();
today.setHours(0, 0, 0, 0);

export const EditSponsorValidationSchema = yup.object().shape({
  name: yup.string().trim().required('Required!'),
  // image: yup.string().required('Required!'),
  start_date: yup
    .date()
    .required('Required!')
    .min(moment('2000-01-01'), 'Selected date must be from the year 2000 and later'),
  // .min(today, 'Start date must be from today'),
  expiration_date: yup
    .date()
    .required('Required!')
    .min(yup.ref('start_date'), 'End date cannot be before start date'),
});

export const ViewTicketValidationSchema = yup.object().shape({
  priorityLevel: yup.string().required('Required!'),
  supportCategoryId: yup.string().required('Required!'),
  supportSubcategoryId: yup.string().required('Required!'),
  status: yup.string().required('Required!'),
});

export const ExternalSupportValidationSchema = yup.object().shape({
  supportCategoryId: yup.string().required('Required!'),
  supportSubcategoryId: yup.string().required('Required!'),
  email: yup.string().matches(emailRules, emailRulesHint).required('Required!'),
  subject: yup.string().required('Required!'),
  description: yup.string().required('Required!'),
  link: yup
    .string()
    .matches(
      /^https?:\/\/(app-test\.fenworks\.com|app-staging\.fenworks\.com|app\.fenworks\.com)(\/.*)?$/,
      'Please enter a valid Fewnworks URL',
    )
    .nullable()
    .notRequired(),
});

export const EditRescheduleDateValidationSchema = yup.object().shape({
  newDate: yup.date().required('Required!'),
});

export const EditGameValidationSchema = yup.object().shape({
  name: yup.string().trim().required('Required!'),
});

export const ForfeitValidationSchema = yup.object().shape({
  reason: yup.string().trim().required('Required!'),
});

export const UsernamesSettingsValidationSchema = yup.object().shape({
  game: yup.array().of(
    yup.object().shape({
      in_game_name: yup.string().required('In-Game name is required'),
    }),
  ),
});
