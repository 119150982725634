import React, { useEffect, useState } from 'react';
import padlock from '../../assets/padlock.svg';
import padlock2 from '../../assets/padlock2.svg';
import logo from '../../assets/logo2.png';

import ResetPasswordFailed from './ResetPasswordFailed';
import ResetPasswordSuccessfully from './ResetPasswordSuccessfully';

import { Form, Link, Navigate, useNavigate } from 'react-router-dom';
import WaitingEmailLink from './WaitingEmailLink';
import { useForgotPasswordMutation } from '../../slices/authApiSlice';
import { Formik, FormikErrors } from 'formik';
import ModalFooter from '../../components/modal-footer';
import * as yup from 'yup';
import { FormInput } from '../../components/form-input/form-input';
import { SubmitButton } from '../../components/submit-button';
import { Button } from '../../components/ui/button/Button';
import { AiOutlineLoading } from 'react-icons/ai';

const ResetPassword = () => {
  const [forgotPassword, { data, error, isSuccess, isLoading }] = useForgotPasswordMutation();
  const [errorMsg, setErrorMsg] = useState('');
  const [hideInviteSent, setHideInviteSent] = useState<boolean>(false);

  const handleSubmit = (values: any) => {
    const { email } = values;
    forgotPassword(email).unwrap().then(resp => console.log('resp', resp))
      .catch(err => {
        console.log('err', err);
        if (err.status === 404) {
          setErrorMsg(err?.data?.message);
        }
      });
    setHideInviteSent(false);
  };

  interface FormErrors {
    email?: string;
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      setHideInviteSent(true);
    }
    setErrorMsg('');
  };

  return (
    <div className='container mx-auto flex justify-center px-6 my-12 w-full xl:w-3/4 lg:w-11/12'>
      <div className='w-full top-0 absolute  h-100 flex  border-b-1  p-5  border-black fill-none'>
        <img width="196" height="49" src={logo} alt='Fenworks Logo' />
      </div>
      <div className='w-full lg:w-1/2 mt-10 bg-white p-5 rounded-lg lg:rounded-l-none'>
        <div className='px-8 mb-4 text-center'>
          <h3 className='pt-4 mb-2 text-2xl'>Reset Password</h3>
        </div>
        <Formik
          initialValues={{ email: '' }}
          onSubmit={handleSubmit}
          validate={values => {
            const errors: FormErrors = {};
            if (!values.email) {
              errors.email = 'Required!';
            }
            else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
              errors.email = 'Invalid email address';
            }
            return errors;
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit} className='px-8 pt-6 pb-8 mb-4 bg-white rounded'>
              <div className='flex justify-center mb-12'>
                <img src={padlock2} alt='padlock-logo' />
              </div>
              <p className='mb-4 text-sm text-gray-700 text-center'>
                Enter the email address associated with your account to reset your password
              </p>
              <div className='mb-4'>
                <label className='block mb-2 text-sm font-bold text-gray-700' form='email'>
                  Email
                </label>
                <input
                  value={values.email}
                  onChange={(e) => {
                    handleChange(e);
                    handleEmailChange(e);
                  }}
                  className='w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline'
                  id='email'
                  type='email'
                  placeholder='Enter email address'
                  name='email'
                />
              </div>
              <div className='flex flex-col gap-2'>
                {errors.email && <p className='text-center text-[#fc8181]'>{errors.email}</p>}
                {errorMsg && <p className='text-center text-[#fc8181]'>{errorMsg}</p>}
                {data?.status === 'sent' && !hideInviteSent ? (
                  <p className='text-sm text-gray-700 text-center'>
                    If this email exists in our system
                    an email should arrive shortly with a link to reset your password.
                  </p>
                ) : (
                  null
                )}
              </div>
              {/* {errors.email && <p className='text-center text-[#fc8181]'>{errors.email}</p>}
              {errorMsg && <p className='text-center text-[#fc8181]'>{errorMsg}</p>} */}
              {/* {data?.status === 'sent' ? (
                <p className='text-sm text-blue-400 text-center'>
                  If this email exists in our system
                  an email should arrive shortly with a link to reset your password.
                </p>
              ) : (
                null
              )} */}
              <div className='mb-6 text-center flex justify-center'>
                <Button
                  disabled={isLoading}
                  type='submit'
                  className='w-full my-5 py-2 bg-red-700 shadow-lg text-white font-semibold rounded-lg'
                >
                  {isLoading && <AiOutlineLoading className='mr-2 h-4 w-4 animate-spin' />}
                  Reset Password
                </Button>
              </div>
            </form>
          )}
        </Formik>
        <div className='text-center'>
          Back to{' '}
          <span className='text-blue-700'>
            <Link to='/login'>Login here!</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;