import * as yup from 'yup';
import { SchemaMessages } from '../../../../../../../utils/constants';
import { checkEndDate, checkStartDate } from '../../../../../../../utils/utils';

export const EditStageVSchema = () => {
  return yup.object().shape({
    name: yup.string().required('Required!'),
    start_time: yup
      .mixed()
      .transform((value, originalValue) => originalValue)
      .required('Required!'),
    end_time: yup
      .mixed()
      .transform((v, ov) => ov)
      .test('test', SchemaMessages.EndDate, (v, ctx) => checkEndDate(v, ctx, 'start_time')),
    stage_type_id: yup.string().required('Required!'),
    stage_team_selection_type_id: yup.number().required('Required!'),
  });
};
