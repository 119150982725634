import * as yup from 'yup';

export const EditClassroomSchema = yup.object().shape({
  name: yup
    .string()
    .min(5, 'Name must be at least 5 characters')
    .max(50, 'Name must be below 50 characters')
    .matches(/^[A-Za-z0-9\s-]{0,50}$/, 'Name must contain only letters and numbers')
    .required('Required!'),
  game_id: yup.string().required('Required!'),
  objective_descriptions: yup
    .string()
    .max(50, 'Must be below 50 characters')
    .required('Required!'),
  image: yup.string().nullable(),
});
