import { useState } from 'react';
import { SeasonsOptionCard } from './seasons-option-card';

export function SeasonOptionContainer() {
  const seasonOptions = [
    {
      id: 1,
      option: 'All Seasons',
      description: 'View all seasons',
      to: './all',
    },
    {
      id: 2,
      option: 'Category Divisions',
      description: 'View all category divisions',
      to: './division',
    },
    {
      id: 3,
      option: 'Subdivisions',
      description: 'View all subdivisions',
      to: './subdivision',
    },
  ];
  const [isActive, setIsActive] = useState(1);

  return (
    <div className='flex min-h-fit gap-5 items-center lg:items-start  p-5  flex-wrap flex-row bg-white '>
      {seasonOptions.map((t) => (
        <SeasonsOptionCard isActive={t.id === isActive} key={t.option} {...t} />
      ))}
    </div>
  );
}
