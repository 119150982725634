import { ViewMatchSchedule } from './components/view-match-schedule';
import { ViewSeasonHeader } from './components/view-season-header';
import TableContainer from '../../components/table-container/table-container';
import { TableHead } from '../../components/table-head';
import TableTd from '../../components/table-td/table-td';
import { SeasonReviewTimeline } from './components/season-review-timeline';
import {
  useViewSingleSeasonQuery,
  useViewSingleSeasonStageQuery,
} from '../../slices/seasonApiSlice';
import { Navigate, Outlet, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { SponsorRow } from '../../components/sponsor-row/sponsor-row';
import { SeasonStageTypes, SponsorPlacements, UserRoles } from '../../utils/constants';
import { CustomError } from '../../utils/custom-types';
import { useSelector } from 'react-redux';
import { selectUser } from '../../slices/auth';
import { SingleSeasonModel } from '../../model/single-season.model';
import AddGameAccount from '../games/overview/add-game/AddGameAccount';
import { ViewSeasonStage } from './components/view-season-stage';
import { useState } from 'react';

function ViewSeason() {
  const param = useParams();
  const [searchParams] = useSearchParams();
  const weekId = searchParams.get('week');
  const [seasonStageId, setSeasonStageId] = useState<number>();
  const { data: seasonStageData } = useViewSingleSeasonStageQuery(seasonStageId, {
    skip: !seasonStageId,
  });
  const { error, data, isLoading, isError, isSuccess } = useViewSingleSeasonQuery(param.id, {
    skip: !param.id,
  });
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const partOfTeam = (team: any) => {
    if (user?.roles === UserRoles?.Ogma) {
      const partOfTeam = [
        team?.organizations?.map((org: any) => org.user?.id),
        team?.School?.organization?.user?.id,
        ...(team?.organizations?.map((org: any) => org.ogmas?.map((o: { id: number }) => o.id)) ??
          []),
        ...(team?.School?.organization?.ogmas?.map((o: { id: number }) => o.id) ?? []),
      ].flat();
      if (partOfTeam?.includes(user?.id) && team?.isBenched) {
        return (
          <div className='flex justify-center items-center gap-2'>
            <div>
              <p className='text-gray-900 truncate hover:font-bold hover:underline'>
                {team?.teamName}
              </p>
            </div>
            <div>
              <span className='text-[#B50303] font-[600] font-[Inter] text-[12px]'>BENCHED</span>
            </div>
          </div>
        );
      } else {
        return <>{team?.teamName}</>;
      }
    }
    if (user?.roles === UserRoles.Student) {
      const partOfTeam =
        team?.activeMembers?.includes(user?.id) || team?.subMembers?.includes(user?.id);
      if (partOfTeam && team?.isBenched) {
        return (
          <div className='flex justify-center items-center gap-2'>
            <div>
              <p className='text-gray-900 truncate hover:font-bold hover:underline'>
                {team?.teamName}
              </p>
            </div>
            <div>
              <span className='text-[#B50303] font-[600] font-[Inter] text-[12px]'>BENCHED</span>
            </div>
          </div>
        );
      } else {
        return team?.teamName;
      }
    }
  };

  if (isLoading || !data) return <p>Loading...</p>;
  let canSeeSeason = false;
  if (data.SeasonType && user?.roles === UserRoles.Student) {
    data?.teams?.forEach((t: any) => {
      t?.User?.forEach((u: any) => {
        if (u.id === user?.id) {
          canSeeSeason = true;
        }
      });
    });
  }
  if (!data?.SeasonType) {
    return <AddGameAccount gameId={data?.game_id} />;
  }
  if (user?.roles === UserRoles.Student && !canSeeSeason) {
    return <Navigate to='/went-wrong' state={{ message: 'Not allowed to see this season' }} />;
  }

  if (isError)
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError).data?.message }} />;
  const model = new SingleSeasonModel(data);

  return (
    <div className='listViewContainer p-0 justify-between overflow-auto border-1 rounded-xl'>
      <ViewSeasonHeader />
      <div className='border-black border-[2px] rounded m-2 p-2 min-h-min max-h-[8rem] overflow-auto whitespace-pre-wrap'>
        <h3 className='w-fit mb-2 font-bold border-b-black border-b-[1px]'>Season Information</h3>
        {model.information}
      </div>
      <SponsorRow name={SponsorPlacements.SeasonsView} extraClass='p-2' />
      <div className='flex p-2 items-center'>
        <p className='text-xs font-bold'>Season Progress:</p>
        <SeasonReviewTimeline />
      </div>
      <ViewSeasonStage setSeasonStageId={setSeasonStageId} seasonStageId={seasonStageId} />
      <ViewMatchSchedule seasonStageId={seasonStageId} />
      <div className='flex flex-col p-2 gap-2'>
        <p className='text-xs  uppercase'>
          {model.SeasonType?.leaderboard ? 'Season Leaderboard' : 'Ranking'}
        </p>
        <TableContainer>
          <TableHead
            tableList={[
              'No #',
              'Team Name',
              'School',
              'Organization',
              model.SeasonType?.leaderboard ? 'Points' : 'W-L',
            ]}
          />
          <tbody className='bg-white divide-y divide-gray-200'>
            {data.standing
              .filter((d: any) =>
                seasonStageData?.stage_type_id === SeasonStageTypes.SkillSync
                  ? data?.SeasonStage.flatMap((stage: any) => stage.SeasonWeek)
                      ?.find((week: any) => week.id === Number(weekId))
                      ?.teams?.find((team: any) => team.id === d.team_id) &&
                    d.season_stage_id === seasonStageId
                  : d.season_stage_id === seasonStageId,
              )
              .map((d: any, i: number) => {
                const isMyTeam = Boolean(user?.team.filter((t: any) => t?.id === d.team_id).length);
                const isOgmaMyTeam = Boolean(
                  user?.ogma_org?.filter((o: any) => o?.name === d.team.School?.organization?.name)
                    ?.length ||
                    user?.ogma_org?.filter((o: any) =>
                      d.team.organizations?.map((org: any) => org.id).includes(o.id),
                    )?.length,
                );
                return (
                  <tr
                    className={`hover:bg-black hover:text-black hover:bg-opacity-20 cursor-pointer relative ${
                      isMyTeam || isOgmaMyTeam ? '!bg-[#33a1fd] ' : ''
                    } `}
                    key={i}
                  >
                    <TableTd>{i + 1}</TableTd>
                    <TableTd
                      extraClass={'hover:font-bold hover:underline max-w-[300px] truncate'}
                      handleClick={() => navigate(`/teams/view/${d.team_id}`)}
                    >
                      {user?.roles === UserRoles.Admin && d?.team?.isBenched ? (
                        <div className='flex justify-center items-center gap-2'>
                          <div>
                            <p className='text-gray-900 hover:font-bold hover:underline'>
                              {d.team.teamName}
                            </p>
                          </div>
                          <div>
                            <span className='text-[#B50303] font-[600] font-[Inter] text-[12px]'>
                              BENCHED
                            </span>
                          </div>
                        </div>
                      ) : user?.roles === UserRoles.Ogma || user?.roles === UserRoles.Student ? (
                        <>{partOfTeam(d?.team)}</>
                      ) : (
                        d.team.teamName
                      )}
                    </TableTd>
                    <TableTd
                      extraClass={'hover:font-bold hover:underline'}
                      handleClick={() =>
                        d.team.School ? navigate(`/schools/view/${d.team.School?.id}`) : null
                      }
                    >
                      {d.team.School?.name ?? 'CO-OP'}
                    </TableTd>
                    <TableTd>
                      {d.team.School?.organization?.name ??
                        d.team.organizations?.map((org: any) => (
                          <div key={org.name}>
                            {org.name}
                            <br />
                          </div>
                        )) ??
                        '-'}
                    </TableTd>
                    <TableTd>
                      {model.SeasonType?.leaderboard ? d.points : `${d.wins}W - ${d.losses}L`}
                    </TableTd>
                  </tr>
                );
              })}
          </tbody>
        </TableContainer>
      </div>
      <Outlet />
    </div>
  );
}

export default ViewSeason;
