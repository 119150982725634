import { AiFillSchedule } from 'react-icons/ai';
import { Link } from 'react-router-dom';

export function RescheduleButton({ }) {
  return (
    <Link to='./reschedule'>
      <div className='flex flex-col items-center gap-10 hover:cursor-pointer'>
        <div className='flex items-center px-2 gap-2 bg-red-800 uppercase text-[10px] text-white rounded-md'>
          <AiFillSchedule color='white' />
          Reschedule
        </div>
      </div>
    </Link>
  );
}