import React from 'react'
import { OutsideClick } from '../../../../components/outside-click'
import { AiOutlineClose } from 'react-icons/ai'
import { Link } from 'react-router-dom'

const CantConnect = ({ gameName }: { gameName: string; }) => {
    return (
        <>
            <OutsideClick />
            <div className='fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-10 w-auto mx-auto max-w-2xl bg-white p-10 flex flex-col gap-10 items-center justify-center rounded-xl'>
                <div className='absolute top-2 right-2'>
                    <Link to='../' className=''>
                        <AiOutlineClose size={'2rem'} className='hover:rounded-full hover:bg-gray-100 p-2' />
                    </Link>
                </div>
                <div className='py-4 flex flex-col justify-center items-center gap-8'>
                    <p>You cant connect! You are not part of a team that plays <span className='font-bold'>{gameName}</span>!</p>
                </div>
            </div>
        </>
    )
}

export default CantConnect