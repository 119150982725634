import { Navigate, useParams } from 'react-router-dom';
import {
  useArchiveSeasonMutation,
  useViewSingleSeasonQuery,
} from '../../../../../../slices/seasonApiSlice';
import ModalArchive from '../../../../../../components/modal-archive';
import { CustomError } from '../../../../../../utils/custom-types';

const ArchiveSeason = () => {
  const param = useParams();
  const {
    error,
    data: seasonData,
    isSuccess: seasonIsSuccess,
    isLoading: seasonIsLoading,
    isError: seasonIsError,
  } = useViewSingleSeasonQuery(param.id);
  const [archiveSeason, { data, isSuccess, isError, isLoading }] = useArchiveSeasonMutation();
  const onSubmit = () => {
    archiveSeason(param.id)
      .unwrap()
      .then((res) => console.log(res))
      .catch((e) => console.log(e));
  };
  if (seasonIsError || (seasonIsSuccess && !seasonData))
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError).data.message }} />;
  if (isSuccess) return <Navigate to='../' />;
  if (seasonIsLoading) return <p>Loading...</p>;
  return <ModalArchive onSubmit={onSubmit} isLoading={isLoading} name={seasonData.name} />;
};

export default ArchiveSeason;
