import { TableHead } from '../../components/table-head';
import TableContainer from '../../components/table-container/table-container';
import TableTd from '../../components/table-td/table-td';
import CanIView from '../../components/can-i-view/can-i-view';
import DropdownItem from '../../features/listview/DropDown/DropDownItem';
import { useState } from 'react';
import { Outlet } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { HeaderButton } from '../../components/header-button';
import { useGetAllSubcategoriesQuery } from '../../slices/supportSubcategoryApiSlice';
import { dateAndTimeFormatter } from '../../utils/dateFormatter';
import { useSelector } from 'react-redux';
import { selectUser } from '../../slices/auth';
import { UserRoles } from '../../utils/constants';
import Pagination from '../../components/pagination';
import { Separator } from '../../components/ui/separator/Separator';
import { LoadingSceletonSupportCategory } from '../tournaments/routes/all/loading-sceleton-support-category';

const SupportSubCategories = () => {
  const user = useSelector(selectUser);
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: subCategoryData, isLoading } = useGetAllSubcategoriesQuery({
    page: searchParams.get('page') ? searchParams.get('page') : 1,
  });

  const initialOpenListState = {
    id: null,
    x: null,
    y: null,
  };

  const [openList, setOpenList] = useState<{ [key: string]: number | null }>(initialOpenListState);

  const responseLevel = [
    {
      id: 1,
      name: 'Emergency',
    },
    {
      id: 2,
      name: 'Urgent',
    },
    {
      id: 3,
      name: 'Normal',
    },
  ];

  const severityLevel = [
    {
      id: 1,
      name: 'Outage',
    },
    {
      id: 2,
      name: 'Critical',
    },
    {
      id: 3,
      name: 'Urgent',
    },
    {
      id: 4,
      name: 'Important',
    },
    {
      id: 5,
      name: 'Monitor',
    },
    {
      id: 6,
      name: 'Informational',
    },
  ];

  const priorityLevel = [
    {
      id: 1,
      name: 'P1',
    },
    {
      id: 2,
      name: 'P2',
    },
    {
      id: 3,
      name: 'P3',
    },
  ];

  const tableHeaderColumns = [
    {
      name: 'Name',
      active: true,
      property: 'subcategory',
    },
    {
      name: 'Category',
      active: true,
      property: 'category',
    },
    // {
    //   name: 'Response level',
    //   active: true,
    //   property: 'responselevel',
    // },
    // {
    //   name: 'Severity level',
    //   active: true,
    //   property: 'severitylevel',
    // },
    {
      name: 'Priority level',
      active: true,
      property: 'prioritylevel',
    },
    {
      name: 'Created Date',
      active: true,
      property: 'createddate',
    },
    {
      name: 'Updated Date',
      active: true,
      property: 'updatedAt',
    },
    {
      name: 'Id',
      active: true,
      property: 'id',
    },
  ];
  if (isLoading) return <LoadingSceletonSupportCategory />;
  const handleClick = (prop: { id: number; x: number; y: number }) => {
    if (user?.roles === UserRoles.Admin) {
      if (openList.id == prop.id) {
        setOpenList(initialOpenListState);
      } else {
        setOpenList(prop);
      }
    }
  };

  return (
    <>
      <div className='w-full mx-auto py-8 mt-2 flex overflow-auto max-w-full flex-col flex-1 rounded-xl bg-white border-1'>
        <div className='flex justify-between items-center mb-[3rem]'>
          <h2 className='text-2xl ml-3 font-semibold tracking-tight'>Subcategory</h2>
          <div className='flex gap-5 mr-[1rem]'>
            <HeaderButton type='newSubCategorySupport' />
            {/* <CanIView component='category.newSubCategory'>
                        <Link to='./new'></Link>
                    </CanIView> */}
          </div>
        </div>
        <Separator className='my-1' />
        <div className='flex flex-col flex-1 justify-between mt-20'>
          <TableContainer>
            <TableHead oneMore tableList={tableHeaderColumns.map((table) => table.name)} />
            <tbody className='bg-white divide-y divide-gray-200'>
              {subCategoryData?.data?.map((categoryData: any, index: number) => (
                <tr
                  key={categoryData.id}
                  onClick={(e) => handleClick({ id: categoryData.id, x: e.pageX, y: e.pageY })}
                >
                  <TableTd>{categoryData.name}</TableTd>
                  <TableTd>{categoryData.category.name}</TableTd>
                  {/* <TableTd>{responseLevel[categoryData.responseLevel - 1]?.name}</TableTd>
                  <TableTd>{severityLevel[categoryData.severityLevel - 1]?.name}</TableTd> */}
                  <TableTd>{priorityLevel[categoryData.priorityLevel - 1]?.name}</TableTd>
                  <TableTd>{dateAndTimeFormatter(categoryData.createdAt).split(';')[0]}</TableTd>
                  <TableTd>{dateAndTimeFormatter(categoryData.updatedAt).split(';')[0]}</TableTd>
                  <TableTd>{categoryData.id}</TableTd>
                  <CanIView component='threedots'>
                    <TableTd>
                      {categoryData?.name.toLowerCase() !== 'other' ? (
                        <div className='listViewMenu--parent'>
                          <div
                            className={openList.id === categoryData.id ? '' : 'hidden'}
                            style={
                              openList.id === categoryData.id
                                ? {
                                    position: 'fixed',
                                    top: Number(openList.y),
                                    left:
                                      Number(openList.x) < 200
                                        ? Number(openList.x) + 200
                                        : Number(openList.x),
                                    zIndex: 99,
                                  }
                                : {
                                    left: 0,
                                  }
                            }
                          >
                            <div
                              className='listViewMenu'
                              role='menu'
                              aria-orientation='vertical'
                              aria-labelledby='menu-button'
                            >
                              <div className='' role='none'>
                                <DropdownItem
                                  id={categoryData.id}
                                  setFunc={setOpenList}
                                  editAndArchive={true}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className='listViewMenu--parent'>
                          <div
                            className={openList.id === categoryData.id ? '' : 'hidden'}
                            style={
                              openList.id === categoryData.id
                                ? {
                                    position: 'fixed',
                                    top: Number(openList.y),
                                    left:
                                      Number(openList.x) < 200
                                        ? Number(openList.x) + 200
                                        : Number(openList.x),
                                    zIndex: 99,
                                  }
                                : {
                                    left: 0,
                                  }
                            }
                          >
                            <div
                              className='listViewMenu'
                              role='menu'
                              aria-orientation='vertical'
                              aria-labelledby='menu-button'
                            >
                              <div className='' role='none'>
                                <DropdownItem
                                  id={categoryData.id}
                                  setFunc={setOpenList}
                                  justEdit={true}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </TableTd>
                  </CanIView>
                </tr>
              ))}
            </tbody>
          </TableContainer>
          <Pagination length={subCategoryData?.data?.length} {...subCategoryData?.meta} />
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default SupportSubCategories;
