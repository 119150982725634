import { Link, useNavigate } from 'react-router-dom';
import { useStateContext } from '../../../contexts/ContextProvider';
import StudentRegisterForm from './StudentRegisterForm';

type studentRegisterProps = {
  isSchoolRegistrationLink: boolean;
};

const StudentRegister = ({ isSchoolRegistrationLink }: studentRegisterProps) => {
  const { modalTerms, setModalTerms } = useStateContext();
  const { modalTermsCheckbox, setModalTermsCheckbox } = useStateContext();

  const { isLoggedIn, setIsLoggedIn } = useStateContext();
  const { copaisChecked, setCopaisChecked } = useStateContext();

  function onChangeCaptcha() {
    console.log('Captcha value:');
  }

  const navigate = useNavigate();

  const handleCopaInput = (e: any) => {
    if (e.target.checked) {
      setCopaisChecked(true);
    } else {
      setCopaisChecked(false);
    }
  };

  const onSubmit = async (values: any, actions: any) => {
    if (!copaisChecked) return;
  };

  return (
    <>
      <div className='leading-loose flex items-center justify-center  '>
        <StudentRegisterForm isSchoolRegistrationLink={isSchoolRegistrationLink} />
        {/* {modalTerms && (
          <ModalTerms setModalTerms={setModalTerms} setModalTermsCheckbox={setModalTermsCheckbox} />
        )} */}
      </div>

      <p className='flex items-center justify-center'>
        <span>
          Already have an account?{' '}
          <Link to='/login'>
            <span className='underline font-medium'> Click here to login</span>
          </Link>
        </span>{' '}
      </p>
    </>
  );
};

export default StudentRegister;
