import { useState } from 'react';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import { HeaderButton } from '../../../../components/header-button';
import { Search } from '../../../../components/search/search';
import TableContainer from '../../../../components/table-container/table-container';
import { TableHead } from '../../../../components/table-head';
import TableTd from '../../../../components/table-td/table-td';
import DropdownItem from '../../../../features/listview/DropDown/DropDownItem';
import {
  useGetAllStudentsInvoicesQuery,
  useInvoiceExportListMutation,
  useToggleDefaultStatusMutation,
} from '../../../../slices/invoicesApiSlice';
import { InvoiceModel } from '../../../ogma-school-subscriptions/models/invoice.model';
import Pagination from '../../../../components/pagination';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../slices/auth';
import { UserRoles } from '../../../../utils/constants';
import { HiSwitchHorizontal } from 'react-icons/hi';
import { LoadingSceletonLists } from '../../../tournaments/routes/all/loading-sceleton-lists';

const InvoiceStudents = () => {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const [openListView, setOpenListView] = useState(false);
  const initialOpenListState = {
    id: null,
    x: null,
    y: null,
  };
  const [openList, setOpenList] = useState<{ [key: string]: number | null }>(initialOpenListState);

  function handleClick(prop: { id: number; x: number; y: number }) {
    setOpenListView(false);
    if (openList.id == prop.id) {
      setOpenList(initialOpenListState);
    } else {
      setOpenList(prop);
    }
  }

  const configureListView = [
    // {
    //   name: 'id',
    //   prop: 'id',
    //   active: true,
    // },
    {
      name: 'name',
      prop: 'firstName',
      active: true,
    },
    {
      name: 'school',
      prop: 'schName',
      active: true,
    },
    {
      name: 'season',
      prop: 'seasonName',
      active: true,
    },
    {
      name: 'total',
      prop: 'formatedTotal',
      active: true,
    },
    {
      name: 'Participation Status',
      prop: 'defaultStatus',
      active: true,
    },
    {
      name: 'status',
      prop: 'statusName',
      active: true,
    },
    // {
    //   name: 'type',
    //   prop: 'invoiceType',
    //   active: true,
    // },
    {
      name: 'start date',
      prop: 'formatedStartDate',
      active: true,
    },
    // {
    //   name: 'end date',
    //   prop: 'formatedEndDate',
    //   active: true,
    // },
  ];

  const [view, setView] = useState(true);
  const [listView, setListView] = useState(configureListView);

  const [downloadList, { isLoading: isDownloading }] = useInvoiceExportListMutation();
  const headerButtons = [
    {
      type: 'export',
      func: () => downloadList({ type: '2', ...Object.fromEntries(searchParams.entries()) }),
    },
    {
      type: 'configureListView',
      view,
      listView,
      setListView,
    },
    {
      type: 'CreateNewRequest',
    },
  ];
  const [searchParams, setSearchParams] = useSearchParams();
  const filterList = [
    {
      title: 'name',
      property: 'name',
    },
  ];
  const [searchQuery, setSearchQuery] = useState(filterList[0].title);

  const [search, setSearch] = useState({ by: 'name', keyword: '' });

  const { isError, data, isLoading, isSuccess } = useGetAllStudentsInvoicesQuery(
    Object.fromEntries(searchParams.entries()),
  );
  const [toggleStatus, { isLoading: tgIsLoading, isError: tgIsError, data: tgData }] =
    useToggleDefaultStatusMutation();
  function handleToggle(id: number, status: number) {
    toggleStatus({ season_id: id, status });
  }
  if (isLoading) return <LoadingSceletonLists/>;
  return (
    <div className='flex overflow-auto max-w-full flex-col flex-1 rounded-xl bg-white border-1 justify-between h-full'>
      <div className='flex w-full p-5 justify-between'>
        <h2 className='text-2xl font-semibold tracking-tight'>Student Invoices</h2>
        <div className='flex gap-2 justify-center'>
          {headerButtons.map((b) => (
            <HeaderButton key={b.type} view={view} {...b} />
          ))}
        </div>
      </div>
      <div className='flex w-full justify-between p-5 gap-2 '>
        <div className='flex items-center gap-5'>
          <HeaderButton func={() => setSearchParams()} type='filter' />
        </div>
        <Search
          setQuery={setSearchQuery}
          filterList={filterList}
          search={search}
          setSearch={setSearch}
        />
      </div>
      <div className='listViewContainer w-full flex-1 justify-between '>
        <TableContainer>
          <TableHead
            tableList={listView
              .map((l) => l.name)
              .filter(
                (t) => listView.find((d) => d.name.toLowerCase() === t.toLowerCase())?.active,
              )}
          />
          <tbody className='bg-white divide-y divide-gray-200'>
            {data?.data?.length ? (
              data?.data.map((t: any, i: number) => {
                const model: { [key: string]: any } & InvoiceModel = new InvoiceModel(t);
                console.log({ model });
                return (
                  <tr
                    onClick={(e) =>
                      user.roles === UserRoles.Admin
                        ? handleClick({ id: t.id, x: e.pageX, y: e.pageY })
                        : navigate(`/invoices/view/${t.id}`)
                    }
                    className={user.roles !== UserRoles.Admin ? 'cursor-pointer' : 'relative'}
                    key={i}
                  >
                    {listView
                      .filter((l) => l.active)
                      .map((o, i) =>
                        o.name === 'status' || o.prop === 'defaultStatus' ? (
                          <TableTd key={i}>
                            <span
                              className={`invoiceStatus ${model[
                                o.prop
                              ]?.toLowerCase()} flex items-center gap-1`}
                              onClick={(e) => {
                                if (o.prop === 'defaultStatus') {
                                  e.stopPropagation();
                                  handleToggle(
                                    Number(model.seasonInfo_id),
                                    model.defaultStatus === 'Active' ? 0 : 1,
                                  );
                                }
                              }}
                            >
                              {model[o.prop]}
                              {o.prop === 'defaultStatus' && <HiSwitchHorizontal />}
                            </span>
                          </TableTd>
                        ) : o.name === 'type' ? (
                          <TableTd key={i}>
                            <span className='font-normal'>
                              {model.type === 0 ? 'Check' : model.type === 1 ? 'Square' : ''}
                            </span>
                          </TableTd>
                        ) : o.name === 'name' ? (
                          <TableTd key={i} extraClass='max-w-[15ch] truncate'>
                            {model.firstName}
                          </TableTd>
                        ) : o.name === 'school' ? (
                          <TableTd key={i} extraClass='max-w-[15ch] truncate'>
                            {model.schName}
                          </TableTd>
                        ) : o.name === 'season' ? (
                          <TableTd key={i} extraClass='max-w-[15ch] truncate'>
                            {model.seasonName}
                          </TableTd>
                        ) : (
                          <TableTd key={i}>{model[o.prop]}</TableTd>
                        ),
                      )}
                    {/* <TableTd
                    className="px-6 py-4 notworking-whitespace-nowrap text-right text-sm font-medium"
                    >
                      <div className='listViewMenu--parent'>
                        <div onClick={() => handleClick(t.id)}>
                          
                        </div>

                        <div className={openList === t.id ? '' : 'hidden'}>
                          <div
                            className='listViewMenu invoice'
                            role='menu'
                            aria-orientation='vertical'
                            aria-labelledby='menu-button'
                          >
                            <div className='py-1' role='none'>
                              <DropdownItem invoices id={t.id} setFunc={setOpenList} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </TableTd> */}
                    <TableTd>
                      <div className='listViewMenu--parent'>
                        <div
                          className={openList.id === t.id ? '' : 'hidden'}
                          style={
                            openList.id === t.id
                              ? {
                                  position: 'fixed',
                                  top: Number(openList.y),
                                  left:
                                    Number(openList.x) < 200
                                      ? Number(openList.x) + 200
                                      : Number(openList.x),
                                  zIndex: 99,
                                }
                              : {
                                  left: 0,
                                }
                          }
                        >
                          <div
                            className='listViewMenu'
                            role='menu'
                            aria-orientation='vertical'
                            aria-labelledby='menu-button'
                          >
                            <div className='' role='none'>
                              <DropdownItem id={t.id} setFunc={setOpenList} invoices />
                            </div>
                          </div>
                        </div>
                      </div>
                    </TableTd>
                  </tr>
                );
              })
            ) : (
              <></>
            )}
          </tbody>
        </TableContainer>
        <Outlet />
        <Pagination length={data?.data?.length} {...data?.meta} />
      </div>
    </div>
  );
};

export default InvoiceStudents;
