class Organization {
  user_id?: any;
  ogmas?: any[]; // You can specify the type for "ogmas" based on your data structure

  constructor(data: any) {
    if (data?.hasOwnProperty('user_id')) {
      this.user_id = data?.user_id;
    }
    if (data?.hasOwnProperty('ogmas')) {
      this.ogmas = data?.ogmas;
    }
  }
}

class School {
  id?: number;
  name?: string;
  organization?: Organization;

  constructor(data: any) {
    if (data?.hasOwnProperty('id')) {
      this.id = data?.id;
    }
    if (data?.hasOwnProperty('name')) {
      this.name = data?.name;
    }
    if (data?.hasOwnProperty('organization')) {
      this.organization = new Organization(data?.organization);
    }
  }
}

class User {
  id?: number;

  constructor(data: any) {
    if (data?.hasOwnProperty('id')) {
      this.id = data?.id;
    }
  }
}

class Team {
  User?: User[];
  id?: number;
  teamName?: string;
  image?: any; // You can specify the type for "image"
  organizations?: any[]; // You can specify the type for "organizations"
  School?: School;

  constructor(data: any) {
    if (data?.hasOwnProperty('User')) {
      this.User = data?.User.map((user: any) => new User(user));
    }
    if (data?.hasOwnProperty('id')) {
      this.id = data?.id;
    }
    if (data?.hasOwnProperty('teamName')) {
      this.teamName = data?.teamName;
    }
    if (data?.hasOwnProperty('image')) {
      this.image = data?.image;
    }
    if (data?.hasOwnProperty('organizations')) {
      this.organizations = data?.organizations;
    }
    if (data?.hasOwnProperty('School')) {
      this.School = new School(data?.School);
    }
  }
}

class MatchFormat {
  id?: number;
  type?: number;
  name?: string;
  value?: number;

  constructor(data: any) {
    if (data?.hasOwnProperty('id')) {
      this.id = data?.id;
    }
    if (data?.hasOwnProperty('type')) {
      this.type = data?.type;
    }
    if (data?.hasOwnProperty('name')) {
      this.name = data?.name;
    }
    if (data?.hasOwnProperty('value')) {
      this.value = data?.value;
    }
  }
}

class Game {
  id?: number;
  isArchived?: number;
  name?: string;
  active_limit?: number;
  subs_limit?: number;
  image?: string;
  team_size?: any[]; // You can specify the type for "team_size"
  MatchFormat?: MatchFormat[];

  constructor(data: any) {
    if (data?.hasOwnProperty('id')) {
      this.id = data?.id;
    }
    if (data?.hasOwnProperty('isArchived')) {
      this.isArchived = data?.isArchived;
    }
    if (data?.hasOwnProperty('name')) {
      this.name = data?.name;
    }
    if (data?.hasOwnProperty('active_limit')) {
      this.active_limit = data?.active_limit;
    }
    if (data?.hasOwnProperty('subs_limit')) {
      this.subs_limit = data?.subs_limit;
    }
    if (data?.hasOwnProperty('image')) {
      this.image = data?.image;
    }
    if (data?.hasOwnProperty('team_size')) {
      this.team_size = data?.team_size;
    }
    if (data?.hasOwnProperty('MatchFormat')) {
      this.MatchFormat = data?.MatchFormat.map((format: any) => new MatchFormat(format));
    }
  }
}

class Standing {
  id?: number;
  tournament_id?: any;
  team_id?: number;
  wins?: number;
  losses?: number;
  points?: number;
  season_id?: number;
  team?: Team;

  constructor(data: any) {
    if (data?.hasOwnProperty('id')) {
      this.id = data?.id;
    }
    if (data?.hasOwnProperty('tournament_id')) {
      this.tournament_id = data?.tournament_id;
    }
    if (data?.hasOwnProperty('team_id')) {
      this.team_id = data?.team_id;
    }
    if (data?.hasOwnProperty('wins')) {
      this.wins = data?.wins;
    }
    if (data?.hasOwnProperty('losses')) {
      this.losses = data?.losses;
    }
    if (data?.hasOwnProperty('points')) {
      this.points = data?.points;
    }
    if (data?.hasOwnProperty('season_id')) {
      this.season_id = data?.season_id;
    }
    if (data?.hasOwnProperty('team')) {
      this.team = new Team(data?.team);
    }
  }
}

class SeasonWeek {
  id?: number;
  name?: string;
  start_time?: string;
  end_time?: string;
  format_id?: number;
  match_format?: MatchFormat;

  constructor(data: any) {
    if (data?.hasOwnProperty('id')) {
      this.id = data?.id;
    }
    if (data?.hasOwnProperty('name')) {
      this.name = data?.name;
    }
    if (data?.hasOwnProperty('start_time')) {
      this.start_time = data?.start_time;
    }
    if (data?.hasOwnProperty('end_time')) {
      this.end_time = data?.end_time;
    }
    if (data?.hasOwnProperty('format_id')) {
      this.format_id = data?.format_id;
    }
    if (data?.hasOwnProperty('match_format')) {
      this.match_format = new MatchFormat(data?.match_format);
    }
  }
}

class SeasonType {
  id?: number;
  name?: string;
  leaderboard?: number;

  constructor(data: any) {
    if (data?.hasOwnProperty('id')) {
      this.id = data?.id;
    }
    if (data?.hasOwnProperty('name')) {
      this.name = data?.name;
    }
    if (data?.hasOwnProperty('leaderboard')) {
      this.leaderboard = data?.leaderboard;
    }
  }
}

export class SeasonStage {
  id?: number;

  constructor(data: any) {
    if (data?.hasOwnProperty('id')) {
      this.id = data?.id;
    }
  }
}

export class SingleSeasonModel {
  id?: number;
  orderRank?: number;
  image?: string;
  banner_image?: any; // You can specify the type for "banner_image"
  description?: string;
  name?: string;
  status?: number;
  created_at?: string;
  updated_at?: string;
  start_date?: string;
  end_date?: string;
  game_id?: number;
  format_id?: number;
  isArchived?: number;
  seasonTypeId?: number;
  division_id?: number;
  information?: string;
  Game?: Game;
  teams?: Team[];
  SeasonType?: SeasonType;
  subdivisions?: any[]; // You can specify the type for "subdivisions"
  SeasonWeek?: SeasonWeek[];
  standing?: Standing[];

  constructor(data: any) {
    if (data?.hasOwnProperty('id')) {
      this.id = data?.id;
    }
    if (data?.hasOwnProperty('orderRank')) {
      this.orderRank = data?.orderRank;
    }
    if (data?.hasOwnProperty('image')) {
      this.image = data?.image;
    }
    if (data?.hasOwnProperty('banner_image')) {
      this.banner_image = data?.banner_image;
    }
    if (data?.hasOwnProperty('description')) {
      this.description = data?.description;
    }
    if (data?.hasOwnProperty('name')) {
      this.name = data?.name;
    }
    if (data?.hasOwnProperty('status')) {
      this.status = data?.status;
    }
    if (data?.hasOwnProperty('created_at')) {
      this.created_at = data?.created_at;
    }
    if (data?.hasOwnProperty('updated_at')) {
      this.updated_at = data?.updated_at;
    }
    if (data?.hasOwnProperty('start_date')) {
      this.start_date = data?.start_date;
    }
    if (data?.hasOwnProperty('end_date')) {
      this.end_date = data?.end_date;
    }
    if (data?.hasOwnProperty('game_id')) {
      this.game_id = data?.game_id;
    }
    if (data?.hasOwnProperty('format_id')) {
      this.format_id = data?.format_id;
    }
    if (data?.hasOwnProperty('isArchived')) {
      this.isArchived = data?.isArchived;
    }
    if (data?.hasOwnProperty('seasonTypeId')) {
      this.seasonTypeId = data?.seasonTypeId;
    }
    if (data?.hasOwnProperty('division_id')) {
      this.division_id = data?.division_id;
    }
    if (data?.hasOwnProperty('description')) {
      this.information = data?.description;
    }
    if (data?.hasOwnProperty('Game')) {
      this.Game = new Game(data?.Game);
    }
    if (data?.hasOwnProperty('teams')) {
      this.teams = data?.teams.map((team: any) => new Team(team));
    }
    if (data?.hasOwnProperty('SeasonType')) {
      this.SeasonType = new SeasonType(data?.SeasonType);
    }
    if (data?.hasOwnProperty('subdivisions')) {
      this.subdivisions = data?.subdivisions;
    }
    if (data?.hasOwnProperty('SeasonWeek')) {
      this.SeasonWeek = data?.SeasonWeek.map((week: any) => new SeasonWeek(week));
    }
    if (data?.hasOwnProperty('standing')) {
      this.standing = data?.standing.map((standing: any) => new Standing(standing));
    }
  }
}
