import { Outlet } from 'react-router';
import Pagination from '../../components/pagination';
import TableTd from '../../components/table-td/table-td';
import { TableHead } from '../../components/table-head';
import TableContainer from '../../components/table-container/table-container';
import { LoadingSceletonArchived } from '../tournaments/routes/all/loading-sceleton-archived';
import { useSelector } from 'react-redux';
import { selectUser } from '../../slices/auth';
import { Link, useSearchParams } from 'react-router-dom';
import { useMassDeleteMutation, useMassUnArchiveMutation } from '../../slices/unarchiveApiSlice';
import { UserRoles, priorityLevels } from '../../utils/constants';
import { useGetAllSubcategoriesQuery } from '../../slices/supportSubcategoryApiSlice';
import React, { useState } from 'react';
import AreYouSure from '../archived/routes/user/components/are-you-sure';
import useErrorToast from '../../hooks/use-error-toast';
import useSuccessToast from '../../hooks/use-success-toast';
import { dateToLocal } from '../../utils/dateFormatter';
import useCloseAreYouSure from '../../hooks/use-close-are-you-sure';

const UnArchiveSubcategory = () => {
  const user = useSelector(selectUser);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isArchiveDialogOpen, setArchiveDialogOpen] = useState(false);

  const [checked, setChecked] = useState<number[]>([]);
  const { data, isLoading } = useGetAllSubcategoriesQuery({
    page: searchParams.get('page') ? searchParams.get('page') : 1,
    isArchived: '1',
  });
  const [
    massDelete,
    {
      isSuccess: deleteIsSuccess,
      isLoading: deleteIsLoading,
      isError: deleteIsError,
      error: deleteError,
    },
  ] = useMassDeleteMutation();
  const [
    massUnArchive,
    {
      isSuccess: unarchiveIsSuccess,
      isLoading: unarchiveIsLoading,
      isError: unarchiveIsError,
      error: unarchievError,
    },
  ] = useMassUnArchiveMutation();

  console.log('....', data);

  const listView = [
    {
      name: '',
      active: true,
      prop: '',
    },
    {
      name: 'Name',
      active: true,
      prop: 'name',
    },
    {
      name: 'Category',
      active: true,
      prop: 'category',
    },
    {
      name: 'Priority Level',
      active: true,
      prop: 'priorityLevel',
    },
    {
      name: 'Created Date',
      active: true,
      prop: 'createdAt',
    },
    {
      name: 'Archived Date',
      active: true,
      prop: 'updated_at',
    },
    {
      name: 'Id',
      active: true,
      prop: 'id',
    },
  ];
  const handleOpenDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleDelete = () => {
    massDelete({
      model: 'supportSubcategory',
      ids: [...new Set(checked)],
    });
  };

  const handleArchive = () => {
    let filterChecked: number[] = [];
    data?.data?.map((d: any, idx: number) => {
      if ([...new Set(checked)].some((t) => t === d.id)) {
        if (d?.category?.isArchived) return;
        filterChecked.push(d.id);
      }
    });
    massUnArchive({
      model: 'supportSubcategory',
      ids: filterChecked,
    });
  };

  useCloseAreYouSure({
    archiveIsError: unarchiveIsError,
    archiveIsSuccess: unarchiveIsSuccess,
    deleteIsSuccess,
    deleteIsError,
    setDeleteDialogOpen,
    setArchiveDialogOpen,
    setChecked,
  });

  useSuccessToast(deleteIsSuccess || unarchiveIsSuccess, 'Success');
  useErrorToast(deleteIsError, deleteError);
  useErrorToast(unarchiveIsError, unarchievError);
  if (isLoading) return <LoadingSceletonArchived />;

  return (
    <div className='flex flex-col flex-1 gap-3  '>
      <div className=' flex justify-between gap-2 items-center  '>
        <div className='flex gap-3 items-center pl-10'>
          {data?.data?.length ? (
            <>
              <input
                type='checkbox'
                style={{ width: '15px', height: '15px' }}
                checked={data?.data?.every((i: any) => checked.includes(i.id)) && checked.length}
                onClick={() =>
                  data?.data?.every((i: any) => checked.includes(i.id))
                    ? setChecked(checked.filter((id) => !data?.data.find((d: any) => d.id === id)))
                    : setChecked([...checked, ...(data?.data?.map((item: any) => item.id) || [])])
                }
              />
              <p className='text-base font-body'>Select All</p>
            </>
          ) : (
            <></>
          )}
        </div>
        <div className='flex  items-center gap-5'>
          {checked.length && data?.data?.length ? (
            <button onClick={() => setArchiveDialogOpen(true)} className='btn red '>
              Unarchive
            </button>
          ) : (
            <></>
          )}
          {checked.length && data?.data?.length ? (
            <button onClick={() => setDeleteDialogOpen(true)} className='btn red '>
              Delete
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className='listViewContainer w-full border flex-1 overflow-x-auto'>
        <div className='flex flex-col flex-1 justify-between'>
          <TableContainer extraClass='overflow-auto'>
            <TableHead
              oneMore={user.roles === UserRoles.Admin}
              tableList={listView
                .map((l) => l.name)
                .filter(
                  (t) => listView.find((d) => d.name.toLowerCase() === t.toLowerCase())?.active,
                )}
            />
            <tbody className='bg-white divide-y divide-gray-200 w-full'>
              {data?.data?.map((d: any, i: number) => (
                <tr key={i}>
                  {/* {d?.category?.isArchived  ? <td style={{ width: '15px', height: '15px' }}></td> :  */}
                  <TableTd>
                    <input
                      type='checkbox'
                      readOnly
                      style={{ width: '15px', height: '15px' }}
                      onClick={() =>
                        checked.includes(d.id)
                          ? setChecked((s) => s.filter((id) => id !== d.id))
                          : setChecked((s) => [...s, d.id])
                      }
                      checked={checked.includes(d.id)}
                    />
                  </TableTd>
                  {/* } */}
                  {listView
                    .filter((l) => l.active)
                    .map((o, i) => {
                      if (o.prop === 'priorityLevel') {
                        return (
                          <TableTd key={o.prop} extraClass=''>
                            {priorityLevels[d?.priorityLevel]}
                          </TableTd>
                        );
                      }
                      if (o.prop === 'category') {
                        return (
                          <TableTd key={o.prop} extraClass=''>
                            {d?.category?.name}
                          </TableTd>
                        );
                      }
                      if (o.prop === 'createdAt') {
                        return (
                          <TableTd key={o.prop} extraClass=''>
                            {dateToLocal(d[o.prop], 'MMMM D, YYYY')}
                          </TableTd>
                        );
                      }

                      if (o.prop === 'updated_at') {
                        return (
                          <TableTd key={o.prop} extraClass=''>
                            {dateToLocal(d?.updatedAt, 'MMMM D, YYYY')}
                          </TableTd>
                        );
                      }

                      return o.prop !== '' ? (
                        <TableTd key={o.prop}>{d[o.prop]}</TableTd>
                      ) : (
                        <React.Fragment key={o.prop} />
                      );
                    })}
                  <TableTd>
                    {d?.category?.isArchived ? (
                      <button type='button' className='btn'>
                        Can't unarchive
                      </button>
                    ) : (
                      <Link to={`./unarchive/${d?.id}/${d?.name}`}>
                        <button className='btn red '>Unarchive</button>
                      </Link>
                    )}
                    {/* <Link to={`./unarchive/${d?.id}/${d?.name}`}>
                      <button className='btn red '>Unarchive</button>
                    </Link> */}
                  </TableTd>
                </tr>
              ))}
            </tbody>
          </TableContainer>
          <Outlet />
          <Pagination length={data?.data?.length} {...data?.meta} />
        </div>
      </div>
      {isDeleteDialogOpen && (
        <AreYouSure
          handleCloseDeleteDialog={setDeleteDialogOpen}
          handleDelete={handleDelete}
          isLoading={deleteIsLoading}
          text='delete selected sub categories'
        />
      )}
      {isArchiveDialogOpen && (
        <AreYouSure
          handleCloseDeleteDialog={setArchiveDialogOpen}
          handleDelete={handleArchive}
          isLoading={unarchiveIsLoading}
          text='unarchive selected sub categories'
          note={<ArchivedNotes data={data} checked={checked} />}
        />
      )}
    </div>
  );
};

const ArchivedNotes = ({ data, checked }: any) => {
  let count = 0;
  data?.data?.map((d: any, idx: number) => {
    const dataChecked = [...new Set(checked)].some((ch: any) => ch === d.id);
    if (d?.category?.isArchived && dataChecked) count++;
  });

  return (
    <>
      {!count ? null : (
        <div className='w-full flex flex-col gap-1 border rounded-xl border-red-600 text-xs max-h-[200px] overflow-auto pb-2'>
          <p className='text-red-600 text-center font-bold mb-2 p-2 sticky top-0 bg-white'>
            NOTE - Selected subcategories that can't unarchive are:
          </p>
          <div className='flex flex-col px-2 gap-1'>
            {data?.data?.map((d: any, idx: number) => {
              const dataChecked = [...new Set(checked)].some((ch: any) => ch === d.id);
              if (d?.category?.isArchived && dataChecked)
                return (
                  <p key={idx} className='border rounded-xl p-2 text-red-600 border-red-600'>
                    {d.name}
                  </p>
                );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default UnArchiveSubcategory;
