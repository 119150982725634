import moment from 'moment';
import * as yup from 'yup';

export const NewOrganizationInvoiceSchema = yup.object().shape({
  type: yup.string().required('Required!'),
  // organization:yup.string().required("Required"),
  start_date: yup
    .date()
    .required('Required!')
    .min(moment('2000-01-01'), 'Selected date must be from the year 2000 and later'),
  end_date: yup.string().required('Required!'),
  // numOfPlayers:yup.string().required("Required"),
  // price_student: yup.string().required('Required'),
  // payment_percentage:yup.string().required("Required"),
  total: yup.string().required('Required!'),
  status: yup.string().required('Required!'),
  organization: yup.string().required('Required!'),
  school: yup.string().required('Required!'),
});
export const newOgmaInvoiceCoachHoursSchema = yup.object().shape({
  type: yup.string().required('Required!'),
  // organization:yup.string().required("Required"),
  start_date: yup
    .date()
    .required('Required!')
    .min(moment('2000-01-01'), 'Selected date must be from the year 2000 and later'),
  end_date: yup.string().required('Required!'),
  organization: yup.string().required('Required!'),
  // numOfPlayers:yup.string().required("Required"),
  // price_student: yup.string().required('Required'),
  // payment_percentage:yup.string().required("Required"),
  total: yup.string().required('Required!'),
  status: yup.string().required('Required!'),
  school: yup.string().required('Required!'),
  coachHours: yup.number().required('Required!'),
});
