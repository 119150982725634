import { apiSlice } from '../api/apiSlice';
import { TypeDeleteArchiveBodyDTO } from '../utils/custom-types';

export const unarchiveApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUnarchived: builder.query({
      query: (params: { type: string; page?: string | number; limit?: string | number }) => ({
        url: '/unarchive/all',
        method: 'GET',
        params,
      }),
      providesTags: ['Unarchived'],
    }),
    unArchive: builder.mutation({
      query: ({ id, body }) => ({
        url: `/unarchive/un/${id}`,
        method: 'post',
        body,
      }),
      onQueryStarted: async (_, api) => {
        try {
          await api.queryFulfilled;
          api.dispatch(apiSlice.util.resetApiState());
        } catch (error) {
          console.log(error);
        }
      },
      invalidatesTags: [
        'Unarchived',
        'Tournaments',
        'Organization',
        'User',
        'Seasons',
        'Sponsor',
        'Division',
        'Subdivisions',
        'Team',
        'School',
        'Classroom',
        'School',
        'SingleSponsor',
      ],
    }),
    massDelete: builder.mutation({
      query: (body: TypeDeleteArchiveBodyDTO) => ({
        url: '/unarchive/mass-delete',
        method: 'Post',
        body,
      }),
      onQueryStarted: async (_, api) => {
        try {
          await api.queryFulfilled;
          api.dispatch(apiSlice.util.resetApiState());
        } catch (error) {
          console.log(error);
        }
      },
      invalidatesTags: [
        'Unarchived',
        'Tournaments',
        'Organization',
        'User',
        'Seasons',
        'Sponsor',
        'Division',
        'School',
        'Subdivisions',
        'Team',
        'Classroom',
        'SupportCategory',
        'SupportSubCategory',
      ],
    }),
    massUnArchive: builder.mutation({
      query: (body: TypeDeleteArchiveBodyDTO) => ({
        url: '/unarchive/mass',
        method: 'Post',
        body,
      }),
      onQueryStarted: async (_, api) => {
        try {
          await api.queryFulfilled;
          api.dispatch(apiSlice.util.resetApiState());
        } catch (error) {
          console.log(error);
        }
      },
      invalidatesTags: [
        'Unarchived',
        'Tournaments',
        'Organization',
        'User',
        'Seasons',
        'Sponsor',
        'Division',
        'School',
        'Subdivisions',
        'Team',
        'Classroom',
        'SupportCategory',
        'SupportSubCategory',
        'Game',
      ],
    }),
  }),
});

export const {
  useGetUnarchivedQuery,
  useUnArchiveMutation,
  useMassDeleteMutation,
  useMassUnArchiveMutation,
} = unarchiveApiSlice;
