import React from 'react';
import { TiWarning } from 'react-icons/ti';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { OutsideClick } from '../../components/outside-click';
import Dashboard from '../admin-dashboard/AdminDashboard';

function WentWrong({ message }: { message?: string }) {
  const location = useLocation();
  const msg = message ?? location.state?.message;
  const returnTo = location.state?.returnTo;
  const navigate = useNavigate()
  return (
    <div className='w-full h-[100vh] flex justify-center items-center'>
      <div className='bg-slate-200 h-[300px] w-[300px] rounded-lg shadow-lg flex flex-col items-center justify-around'>
        <TiWarning className='text-pink-600' size={40} />
        <p className='text-center'>{msg ?? 'Something went wrong!'}</p>
        <button onClick={() => { navigate(-3) }}>Go Back</button>
      </div>
    </div>
  );
}

export default WentWrong;
