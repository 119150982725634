import { AiOutlineCamera } from 'react-icons/ai';
import { Link, useParams } from 'react-router-dom';
import { AvatarImage } from '../../../components/avatar-image';
import {
  useUpdateSeasonProfilePhotoMutation,
  useViewSingleSeasonQuery,
} from '../../../slices/seasonApiSlice';
import { TournamentHeaderButton } from '../../view-tournament/components/tournament-header-button';
import CanIView from '../../../components/can-i-view/can-i-view';
import { SeasonModel } from '../../../model/season.model';
import { SeasonProgressStatus } from '../../../utils/constants';
import { formatDateWithComma } from '../../../utils/dateFormatter';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../../../components/ui/tooltip/Tooltip';
export function ViewSeasonHeaderInfo({}) {
  const param = useParams();
  const { data, isLoading, isError, isSuccess } = useViewSingleSeasonQuery(param.id);
  const [updateSeasonProfilePhoto, { data: updateData, isLoading: updateIsLoading }] =
    useUpdateSeasonProfilePhotoMutation();
  function handleFileChange(event: any) {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    updateSeasonProfilePhoto({ photo: formData, id: param.id })
      .unwrap()
      .then((res) => console.log(res))
      .catch((e) => console.log(e));
  }
  let model;
  if (!isLoading) model = new SeasonModel(data);

  if (isLoading) return <p>Loading ...</p>;
  return (
    <>
      <div className='bg-black bg-opacity-60 border-1 border-gray-500 flex gap-5 items-center p-4  rounded-lg z-10'>
        <div className='flex gap-2 items-center'>
          <div className='group flex relative w-[100px] aspect-square rounded-full overflow-hidden justify-center items-center  '>
            {updateIsLoading ? (
              <p>Loading...</p>
            ) : (
              <AvatarImage size='xxl' source={data ? data.image : null} />
            )}
            <CanIView component='season.view.editPhoto'>
              <label className='cursor-pointer text-white  w-full bg-black bg-opacity-80 h-full absolute top-0 right-0 left-0 bottom-0 m-auto z-10 hidden group-hover:flex justify-center items-center  '>
                <AiOutlineCamera />
                <input
                  type='file'
                  accept='image/*'
                  onChange={handleFileChange}
                  className='hidden mr-12'
                />
              </label>
            </CanIView>
          </div>
          <div className='flex flex-col space-evenly gap-1'>
            <div className='flex flex-col space-evenly gap-1'>
              <div className='flex justify-between items-center gap-4'>
                <h2 className='text-white font-bold text-2xl max-w-[15ch] truncate'>{data.name}</h2>
                <p className='text-white'>{data.teams.length} Teams</p>
              </div>
              <hr />
              <div className='flex justify-between items-center  gap-4'>
                <div className='flex items-center justify-center gap-2 text-white'>
                  <span>
                    <AvatarImage source={model?.gameImage} />
                  </span>
                  {data.Game.name?.toUpperCase()}
                </div>
                {data.status === SeasonProgressStatus.Upcoming ? (
                  <span className='text-white uppercase px-2 py-1 bg-purple-700 rounded-md w-fit text-sm'>
                    upcoming
                  </span>
                ) : data.status === SeasonProgressStatus.Ongoing ? (
                  <span className='text-white uppercase px-2 py-1 bg-green-700 rounded-md w-fit text-sm'>
                    ongoing
                  </span>
                ) : data.status === SeasonProgressStatus.Completed ? (
                  <span className='text-white uppercase px-2 py-1 bg-red-700 rounded-md w-fit text-sm'>
                    completed
                  </span>
                ) : (
                  <>unkown</>
                )}
                {/* {new Date(model?.startDate).toDateString() < new Date(Date.now()).toDateString() ? (
                  <span className='text-white uppercase px-2 py-1 bg-red-700 rounded-md w-fit text-sm'>
                    upcoming
                  </span>
                ) : (
                  <span className='text-white uppercase px-2 py-1 main-gr-bg-color rounded-md w-fit text-sm'>
                    ongoing
                  </span>
                )} */}
              </div>
              <hr />
              <div className='text-white flex justify-between'>
                <span className='text-white'>
                  {formatDateWithComma(new Date(data.start_date).toDateString())}
                </span>
                -{''}
                <span className='text-white'>
                  {' '}
                  {formatDateWithComma(new Date(data.end_date).toDateString())}
                </span>
                {/* <p className='text-white text-sm uppercase'>{new Date(data.end_date).toDateString()}</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <CanIView component='season.view.ogmaEditTeams'>
        <div className=' flex gap-5 relative h-fit top-0'>
          <div className=' flex gap-5 relative h-fit top-0'>
            {data.status !== SeasonProgressStatus.Completed &&
              (data.status === SeasonProgressStatus.Upcoming ? (
                <Link to='./edit-teams'>
                  <div className='text-center justify-center rounded-lg bg-fen-navy text-white uppercase p-2 px-3 text-sm cursor-pointer'>
                    Add/Remove Teams
                  </div>
                </Link>
              ) : (
                <>
                  <>
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <span className='text-center justify-center rounded-lg bg-gray-700 text-gray-500 uppercase p-2 px-3 text-sm cursor-pointer'>
                            Add/Remove Teams
                          </span>
                        </TooltipTrigger>
                        <TooltipContent side='bottom'>
                          <div className='flex pr-4'>
                            The season has started! Please contact a Fenworks Rep for support with
                            adding/removing teams.
                          </div>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </>
                </>
              ))}
          </div>
        </div>
      </CanIView>
      <CanIView component='season.view.editSeason'>
        <div className=' flex gap-5 relative h-fit top-0'>
          {data.status === SeasonProgressStatus.Upcoming && (
            <Link to='./edit-teams'>
              <TournamentHeaderButton text='Edit teams' />
            </Link>
          )}
          {data.status == SeasonProgressStatus.Ongoing && (
            <Link to='./add-teams'>
              <TournamentHeaderButton text='Add teams' />
            </Link>
          )}

          {data.status === SeasonProgressStatus.Ongoing && (
            <Link
              className={
                'flex items-center text-white text-center rounded-lg bg-gray-700 px-3 text-sm'
              }
              to={'./remove-team'}
            >
              REMOVE TEAM
            </Link>
          )}

          {data.status !== SeasonProgressStatus.Completed && (
            <Link to='./edit-season'>
              <TournamentHeaderButton text='Edit season' />
            </Link>
          )}

          {data.status !== SeasonProgressStatus.Completed && (
            <Link to='./end-season'>
              <TournamentHeaderButton text='End season' color='bg-red-700' />
            </Link>
          )}
        </div>
      </CanIView>
    </>
  );
}
