import { OutsideClick } from '../../../../components/outside-click';
import {
  useAddOneCoachMutation,
  useGetOrgCoachesPageQuery,
  useRemoveCoachFromOrgMutation,
} from '../../../../slices/organizationApiSlice';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { OrganizationCoachModel } from '../../../../model/organization-coach.model';
import { Checkbox } from '../../../../components/ui/checkbox/Checkbox';
import { CheckedState } from '@radix-ui/react-checkbox';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../slices/auth';
import Pagination from '../../../../components/pagination';

const OrganizationAddCoaches = () => {
  const param = useParams();
  const user = useSelector(selectUser);
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, isLoading, isError, isSuccess } = useGetOrgCoachesPageQuery({
    org_id: Number(param.id ?? user.organizations[0].id),
    page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
  });
  const [addOneCoach, { isLoading: addLoading, isError: addError, isSuccess: addSuccess }] =
    useAddOneCoachMutation();
  const [
    removeCoachFromOrg,
    { isLoading: removeLoading, isError: removeError, isSuccess: removeSuccess },
  ] = useRemoveCoachFromOrgMutation();
  function handleCheck(check: CheckedState, coach_id: number) {
    if (check) {
      addOneCoach({ coach_id, org_id: Number(param.id ?? user.organizations[0].id) });
    } else {
      removeCoachFromOrg({
        coach_id,
        organization_id: Number(param.id ?? user.organizations[0].id),
      });
    }
  }
  function goPrevPage() {
    setSearchParams({ page: `${data.meta.prev}` });
  }
  function goNextPage() {
    setSearchParams({ page: `${data.meta.next}` });
  }

  return (
    <>
      <OutsideClick />
      <div className='fixed  top-0 left-0 right-0 z-10 w-auto my-6 mx-auto max-w-3xl h-fit max-h-[100vh] '>
        <div className='border-0 p-10 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none h-fit overflow-auto gap-2'>
          <h1 className='text-xl font-bold mr-5'>Manage Organization's Coaches</h1>
          {isLoading ? (
            <p>Loading...</p>
          ) : (
            <div className='flex flex-col gap-2'>
              {data.data.map((d: any) => {
                const model = new OrganizationCoachModel(d);
                return (
                  <div
                    key={model.id}
                    className='flex items-center gap-1.5 hover:bg-slate-50 cursor-pointer'
                  >
                    <Checkbox
                      checked={model.org_list.includes(
                        Number(param.id ?? user.organizations[0].id),
                      )}
                      onCheckedChange={(ch) => handleCheck(ch, model.id)}
                      id={model.id.toString()}
                    />
                    <label htmlFor={model.id.toString()} className='flex-1 cursor-pointer'>
                      {model.fullName}
                    </label>
                  </div>
                );
              })}
              <Pagination length={data.data.length} total={data.meta.total} currentPage={data.meta.currentPage} lastPage={data.meta.lastPage} prev={data.meta.prev} next={data.meta.next} />
              {/* <div className='flex p-5 w-full justify-between'>
                <span>
                  {data.data.length} of {data.meta.total} results
                </span>
                <div className='flex gap-2 items-center'>
                  <span>
                    {data.meta.currentPage} of {data.meta.lastPage}
                  </span>
                  <div
                    className='cursor-pointer'
                    onClick={data.meta.prev ? goPrevPage : () => null}
                  >
                    <BsArrowLeft color={data.meta.prev ? '#b50303' : 'grey'} />
                  </div>
                  <div
                    className='cursor-pointer'
                    onClick={data.meta.next ? goNextPage : () => null}
                  >
                    <BsArrowRight color={data.meta.next ? '#b50303' : 'grey'} />
                  </div>
                </div>
              </div> */}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default OrganizationAddCoaches;
