import { UserRoles } from '../../../utils/constants';

export const teamPermissions = {
  'team.newTeam': [UserRoles.Admin, UserRoles.Ogma],
  'team.members.add': [UserRoles.Admin, UserRoles.Ogma],
  'team.members.edit': [UserRoles.Admin, UserRoles.Ogma],
  'team.members.disband': [UserRoles.Admin],
  'team.changePhoto': [UserRoles.Admin, UserRoles.Ogma],
  'team.export': [UserRoles.Admin, UserRoles.Ogma],
  'teams.organizations': [UserRoles.Admin],
  'team.bench.button': [UserRoles.Admin],
  'team.seasonSelection.button': [UserRoles.Admin, UserRoles.Ogma],
};
