import React, { useEffect, useState } from 'react';
import { AiOutlineMinusSquare, AiOutlinePlusSquare } from 'react-icons/ai';
import { useSearchParams } from 'react-router-dom';
import { useGetTournamentGroupedBySubdivisionQuery } from '../../../../../slices/tournamentApiSlice';
import { TournamentGridCard } from './tournament-grid-card';

const TournamentGroupedBySubdivision = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, isLoading, isSuccess, isError } = useGetTournamentGroupedBySubdivisionQuery(
    searchParams.get('page') ? searchParams.get('page') : '',
  );
  const [visible, setVisible] = useState<{ [key: string]: boolean }>();
  useEffect(() => {
    if (data?.data.length)
      data.data.forEach((d: any) => setVisible((s: any) => ({ ...s, [d.id]: true })));
  }, [data]);
  function goPrevPage() {
    setSearchParams({ page: `${data.meta.prev}` });
  }
  function goNextPage() {
    setSearchParams({ page: `${data.meta.next}` });
  }
  if (isLoading) return <p>Loading...</p>;
  return (
    <div className='flex flex-col gap-5 p-5 pt-0'>
      {data.data.map((d: any) => (
        <div key={d.id} className='flex flex-col gap-5'>
          <div
            // onClick={() => setVisible((s: any) => ({ ...s, [d.id]: !s[d.id] }))}
            className='flex justify-between w-full border-b-2 border-black font-bold cursor-pointer uppercase '
          >
            <h2>{d.name}</h2>
            <span>
              {visible && visible[d.id] ? (
                <AiOutlineMinusSquare
                  size={20}
                  className='cursor-pointer'
                  onClick={() => setVisible((s: any) => ({ ...s, [d.id]: false }))}
                />
              ) : (
                <AiOutlinePlusSquare
                  size={20}
                  className='cursor-pointer'
                  onClick={() => setVisible((s: any) => ({ ...s, [d.id]: true }))}
                />
              )}
            </span>
          </div>
          <div className='flex gap-5 justify-center flex-wrap'>
            {visible &&
              visible[d.id] &&
              d.CategoryDivision.Tournament.map((t: any) => (
                <TournamentGridCard key={t.id} {...t} />
              ))}
          </div>
        </div>
      ))}
      {/* <div className='flex w-full justify-between'>
        <span>
          {data.data.length} of {data.meta.total} results
        </span>
        <div className='flex gap-2 items-center'>
          <span>
            {data.meta.currentPage} of {data.meta.lastPage}
          </span>
          <div className='cursor-pointer' onClick={data.meta.prev ? goPrevPage : () => null}>
            <BsArrowLeft color={data.meta.prev ? '#b50303' : 'grey'} />
          </div>
          <div className='cursor-pointer' onClick={data.meta.next ? goNextPage : () => null}>
            <BsArrowRight color={data.meta.next ? '#b50303' : 'grey'} />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default TournamentGroupedBySubdivision;
