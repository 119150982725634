import { useEffect, useState } from 'react';
import { AiOutlineArrowRight, AiOutlineArrowUp } from 'react-icons/ai';
import { LessonModel } from '../../../model/lesson.model';
import { Link, useLocation } from 'react-router-dom';
import { FaPlay } from 'react-icons/fa';
import ViewLesson from '../../view-lesson/view-lesson';
export function ViewClassroomLessonRow({
  banner,
  lesson,
  preview,
  active,
}: {
  banner: string;
  lesson: LessonModel;
  preview?: boolean;
  active?: boolean;
}) {
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();
  useEffect(() => {
    if (+pathname.split('/').slice(-1)[0] === lesson.id) setOpen(true);
    else {
      setOpen(false);
    }
  }, [pathname]);
  return (
    <div className='w-full   bg-gray-300 rounded-lg overflow-hidden '>
      <div
        className='w-full p-3 cursor-pointer relative flex justify-between items-center group'
        onClick={() => setOpen(!open)}
      >
        {banner.length ? (
          <img
            // src='https://i.pinimg.com/originals/a0/0a/40/a00a4035b91d21221a77fb762a2e8845.jpg'
            src={banner}
            className='w-full object-contain absolute top-0 left-0 '
          />
        ) : (
          <div className='w-full object-contain absolute top-0 left-0 '></div>
        )}
        <div className='absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-40 group-hover:bg-opacity-10'></div>
        <p className='flex items-center gap-1 z-10 text-white text-xs font-bold'>
          {active && <FaPlay color='green' />}
          {lesson.title}
        </p>
        {open ? (
          <AiOutlineArrowUp
            color='white'
            className='z-10 duration-500 group-hover:rotate-[360deg]'
          />
        ) : (
          <AiOutlineArrowRight
            color='white'
            className='z-10 duration-500 group-hover:rotate-[360deg]'
          />
        )}
      </div>
      {open && (
        <div className='flex flex-col gap-4 relative p-2 bg-black bg-opacity-80 z-[1]'>
          <div className='flex flex-col whitespace-pre-wrap break-words gap-4  p-4 border rounded-xl bg-black bg-opacity-70'>
            <p className='text-white pb-1 border-b-1 font-semibold'>Description</p>
            <p className='text-white'>{lesson.description}</p>
          </div>
          <p className='text-white font-semibold'>Content</p>
          <div className='flex flex-col gap-2'>
            {!preview &&
              lesson.content.map((l: any) => (
                <div className='flex gap-y-4 justify-between items-center border border-white rounded-xl p-2' key={l.public_id}>
                  <p className='text-white'>{l.fileName}</p>
                  {/* <ViewLesson /> */}
                  <Link to={`./l/${lesson.id}/content/${l.unique}`} className='button'>
                    Open content
                  </Link>
                </div>
              ))
            }
          </div>
          {/* {!preview && (
              <Link to={`./l/${lesson.id}`} className='button'>
                Take this lesson!
              </Link>
            )} */}
        </div>
      )}
    </div>
  );
}
