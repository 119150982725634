import React from 'react';
import { sponsorArr } from '../routes/view-tournament/tournament-standings';

export function SponsorsRow({
  extraClass,
  extraImageClass,
}: {
  extraClass?: string;
  extraImageClass?: string;
}) {
  return (
    <div className={`flex  w-full  gap-1 justify-around sponsor__container  mb-5 ${extraClass}`}>
      {sponsorArr.map((s, i: number) => (
        <img
          key={i}
          src={s}
          className={`sponsor  sponsor__rotate sponsor__blink max-h-[20px] w-[${
            100 / sponsorArr.length
          }%] ${extraImageClass}`}
        />
      ))}
    </div>
  );
}
