export function LoadingSceletonSchedule(){
    return (
      <div className='w-full h-full border-2 rounded-xl flex flex-col p-1'>
        <div>
          <div className='flex flex-col items-center align-middle -mt-6 ml-4 grid-cols-4 gap-3 skeleton__pulse p-6'>
            <div className='w-full min-h-[60px]  items-center rounded-lg shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] bg-slate-400 mt-5 '></div>
            <div className='w-full min-h-[60px]  items-center rounded-lg shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] bg-slate-400 mt-5 '></div>
            <div className='w-full min-h-[60px]  items-center rounded-lg shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] bg-slate-400 mt-5 '></div>
            <div className='w-full min-h-[60px]  items-center rounded-lg shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] bg-slate-400 mt-5 '></div>
          </div>
        </div>
      </div>
    );
};
