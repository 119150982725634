import React, { useEffect, useState } from 'react';
import { useGetAllClassroomsByUserIdQuery } from '../../../../slices/classroomApiSlice';
import { Outlet, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import TableContainer from '../../../../components/table-container/table-container';
import { TableHead } from '../../../../components/table-head';
import { filterByDropdown } from '../../../../utils/filterByDropdown';
import TableTd from '../../../../components/table-td/table-td';
import { NameAndAvatar } from '../../../../components/name-and-avatar';
import { BsArrowLeft, BsArrowRight, BsThreeDots } from 'react-icons/bs';
import DropdownItem from '../../../../features/listview/DropDown/DropDownItem';
import { Button } from '../../../../components/ui/button/Button';
import { AiFillDelete, AiOutlinePlus } from 'react-icons/ai';
import { SearchCoaches } from '../components/SearchCoaches';
import { HeaderButton } from '../../../../components/header-button';
import CanIView from '../../../../components/can-i-view/can-i-view';
import Pagination from '../../../../components/pagination';

const TrainingContent = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const param = useParams();

  const initialOpenListState = {
    id: null,
    x: null,
    y: null,
  };

  const [openList, setOpenList] = useState<{ [key: string]: number | null }>(initialOpenListState);

  const handleClick = (prop: { id: number; x: number; y: number }) => {
    if (openList.id == prop.id) {
      setOpenList(initialOpenListState);
    } else {
      setOpenList(prop);
    }
  };

  const {
    data: classroomData,
    isLoading: classroomIsLoading,
    isError: classroomIsError,
  } = useGetAllClassroomsByUserIdQuery({
    id: param.id,
    query: { name: searchParams.get('name') ?? undefined, page: searchParams.get('page') ?? 1 },
  });

  const filterList = [{ title: 'name', property: 'name' }];

  const initialState = { [filterList[0].title]: '' };
  const [searchQuery, setSearchQuery] = useState(initialState);
  const [search, setSearch] = useState({ by: 'name', keyword: '' });
  const configureListView = [
    {
      name: 'Name',
      active: true,
      property: 'name',
    },
    {
      name: 'Description',
      active: true,
      property: 'objective_descriptions',
    },
    {
      name: 'Created',
      active: true,
      property: 'createdAt',
    },
    {
      name: 'Game',
      active: true,
      property: 'Game',
    },
    {
      name: 'Number of Lessons',
      active: true,
      property: 'number_of_lessons',
    },
    {
      name: '',
      active: true,
      property: 'click_here',
    },
  ];
  const [listView, setListView] = useState(configureListView);

  if (classroomIsLoading) return <p>Loading...</p>;
  return (
    <>
      <div className='listViewContainer fullPage rounded-xl border-1'>
        <div className='flex gap-2 justify-end pt-5 pr-5'>
          <CanIView component='classroom.training.new-classroom'>
            <HeaderButton type='newClassroom' />
          </CanIView>
          <SearchCoaches
            setQuery={setSearchQuery}
            filterList={filterList}
            search={search}
            setSearch={setSearch}
          />
        </div>
        {/* <div className='flex grow gap-4'> */}
        <TableContainer>
          <TableHead
            oneMore
            tableList={
              listView.map((l) => l.name)
              // .filter(
              //   (t) => listView.find((d) => d.name.toLowerCase() === t.toLowerCase())?.active,
              // )
            }
          />
          <tbody className='bg-white divide-y divide-gray-200'>
            {classroomData.data
              .filter((c: any) => !c.isArchived)
              .filter((t: any) => t[search.by].toLowerCase().includes(search.keyword.toLowerCase()))
              .map((classroom: any) => (
                <tr
                  key={classroom.id}
                  onClick={(e) => handleClick({ id: classroom.id, x: e.pageX, y: e.pageY })}
                >
                  {filterByDropdown(classroom, listView).map((p: any) =>
                    p === 'name' ? (
                      <TableTd key={p}>
                        <NameAndAvatar
                          name={classroom.name}
                          image={classroom.profile_image}
                          isAlt={false}
                        />
                      </TableTd>
                    ) : p === 'Game' ? (
                      <TableTd key={p}>{classroom.Game.name}</TableTd>
                    ) : p === 'createdAt' ? (
                      <TableTd key={p}>{new Date(classroom[p]).toDateString()}</TableTd>
                    ) : p === 'objective_descriptions' ? (
                      <TableTd key={p} extraClass='max-w-[15ch] truncate'>
                        {classroom.objective_descriptions}
                      </TableTd>
                    ) : (
                      <TableTd key={classroom[p]}>{classroom[p]}</TableTd>
                    ),
                  )}

                  <TableTd>{classroom.Lesson.length}</TableTd>

                  <TableTd>
                    <button
                      className='bg-red-700 text-xs text-white rounded-full px-2 py-1'
                      onClick={() => navigate(`../training/lesson/${classroom.id}`)}
                    >
                      Go to Lessons
                    </button>
                  </TableTd>
                  <CanIView component='classroom.training.new-classroom'>
                    <TableTd>
                      <AiFillDelete
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`./delete/${classroom.id}`);
                        }}
                        size='25'
                        color='darkred'
                      />
                    </TableTd>
                  </CanIView>
                  <TableTd>
                    <div className='listViewMenu--parent'>
                      <div
                        className={openList.id === classroom.id ? '' : 'hidden'}
                        style={
                          openList.id === classroom.id
                            ? {
                                position: 'fixed',
                                top: Number(openList.y),
                                left:
                                  Number(openList.x) < 200
                                    ? Number(openList.x) + 200
                                    : Number(openList.x),
                                zIndex: 99,
                              }
                            : {
                                left: 0,
                              }
                        }
                      >
                        <div
                          className='listViewMenu'
                          role='menu'
                          aria-orientation='vertical'
                          aria-labelledby='menu-button'
                        >
                          <div className='' role='none'>
                            <DropdownItem
                              id={classroom.id}
                              setFunc={setOpenList}
                              classrooms={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </TableTd>
                </tr>
              ))}
          </tbody>
        </TableContainer>
        <Outlet />
        <Pagination length={classroomData?.data?.length} {...classroomData?.meta} />
      </div>
    </>
  );
};

export default TrainingContent;
