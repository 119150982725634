import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Search } from '../../../components/search/search';
import { useGetAllGamesQuery } from '../../../slices/gameApiSlice';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../slices/auth';
import { HeaderButton } from '../../../components/header-button';
import { ScrollArea, ScrollBar } from '../../../components/ui/scroll-area/ScrollArea';
import { GameCard } from './components/GameCard';
import { Error } from '../../../components/ui/error/Error';

const AvailableGames = ({ title = '' }) => {
  const filterList = [{ title: 'name', property: 'name' }];
  const [search, setSearch] = useState({ by: 'name', keyword: '' });
  const [searchQuery, setSearchQuery] = useState(filterList[0].title);
  const [searchParams, setSearchParams] = useSearchParams();
  const [onlyFavorites, setOnlyFavorites] = useState(false);

  const user = useSelector(selectUser);
  const {
    data: gameData,
    isLoading: gameIsLoading,
    error,
  } = useGetAllGamesQuery({
    query: searchQuery,
    param: searchParams.get(searchQuery) ? searchParams.get(searchQuery) : '',
    // page: searchParams.get("page") ? searchParams.get("page") : 1,
  });

  return (
    <div className='col-span-4'>
      <div className='flex justify-between items-center align-middle mb-2'>
        <h1 className='text-xl font-semibold'>{title}</h1>
        <div className='flex gap-2'>
          <span onClick={() => setOnlyFavorites(!onlyFavorites)}>
            <HeaderButton type={onlyFavorites ? 'showAll' : 'showFavorites'} />
          </span>
          <Search
            setQuery={setSearchQuery}
            filterList={filterList}
            search={search}
            setSearch={setSearch}
          />
        </div>
      </div>
      <hr />
      <div className='mt-5'>
        <ScrollArea>
          <div className='flex space-x-4 pb-4'>
            {onlyFavorites ? (
              gameData
                ?.filter(
                  (game: any) =>
                    game.FavoriteGames.length &&
                    game.FavoriteGames.filter((fg: any) => fg.userId === user.id).length,
                )
                ?.filter((g: any) =>
                  g[search.by].toLowerCase().includes(search.keyword.toLowerCase()),
                )
                ?.map((game: any) => (
                  <GameCard
                    onlyFavorites
                    key={game.id}
                    game={game}
                    userId={user.id}
                    className='w-[200px]'
                    aspectRatio={1 / 1}
                    isAvailable={true}
                  />
                ))
            ) : (
              <>
                {gameData

                  ?.filter((g: any) =>
                    g[search.by].toLowerCase().includes(search.keyword.toLowerCase()),
                  )
                  ?.map((game: any) => (
                    <GameCard
                      key={game.id}
                      game={game}
                      className='w-[200px]'
                      aspectRatio={1 / 1}
                      isAvailable
                      userId={user.id}
                      // color={`${game.FavoriteGames.length !== 0 && game.FavoriteGames.map((fg: any) => fg.userId === user.id) ? 'red' : 'white'}`}
                    />
                  ))}
              </>
            )}
            {onlyFavorites &&
              gameData
                ?.filter(
                  (game: any) =>
                    game.FavoriteGames.length !== 0 &&
                    game.FavoriteGames.filter((fg: any) => fg.userId === user.id).length,
                )
                ?.filter((g: any) =>
                  g[search.by].toLowerCase().includes(search.keyword.toLowerCase()),
                ).length === 0 && (
                <>
                  <Error
                    title='No favorite Games'
                    description='Tip: If you want to add a favorite game, simply tap on the &#10084; icon located next to the game.'
                    hideButton
                  />
                </>
              )}
          </div>
          <ScrollBar orientation='horizontal' />
        </ScrollArea>
      </div>
    </div>
  );
};

export default AvailableGames;
