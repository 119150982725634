import React from 'react';
export function MatchResultCircle({
  result,
  win,
  dblForfeit,
}: {
  result: string | number;
  win?: Boolean;
  dblForfeit?: Boolean;
}) {
  return (
    <div
      className={`flex justify-center items-center bg-white rounded-full w-[30px] h-[30px] aspect-square p-1 text-xs font-bold ${
        win ? '' : ' '
      }`}
    >
      {result}
    </div>
  );
};