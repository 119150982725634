export const SettingMyAccount = {
  success: 'Your information has been updated successfully.',
};

export enum ErrorMessages {
  NoBookHoursSchedule = 'Please specify a booking time range.',
  CityValidation = 'City can contain letters, spaces, hyphens, commas, periods, or apostrophes',
  SchoolNameValidation = 'School name can contain letters, numbers, spaces, hyphens, commas, periods, or apostrophes',
}

export const InviteStudentsOgmaHintMessage = `<p>Add a student’s email to send them an invitation to the platform. 
</br> Enter ONE student’s email then click enter. The email should appear in a bubble. 
</br>Continue until you have added all the emails. Then click <b>“Invite Students”</b></p>`;

export enum NoContentMessages {
  Classrooms = `You currently don't have access to any classrooms`,
}

export const TiesAreNotAllowed = 'Ties are not allowed!';
