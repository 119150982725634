import BannerImage from '../../../components/table-td/banner-image';
import { AiOutlineCamera } from 'react-icons/ai';
import CoachHeaderInfo from './coach-header-info';
import {
  useChangeUserBannerPhotoMutation,
  useGetCoachByIdQuery,
} from '../../../slices/userApiSlice';
import { useParams } from 'react-router-dom';
import CanIView from '../../../components/can-i-view/can-i-view';

const CoachHeader = () => {
  const params = useParams();

  const [changeUserBannerPhoto, { isError, isLoading, isSuccess }] =
    useChangeUserBannerPhotoMutation();

  const {
    data: userData,
    isLoading: userIsLoading,
    isError: userIsError,
    isSuccess: userIsSuccess,
  } = useGetCoachByIdQuery(params.id!, { skip: !params.id });

  function handleFileChange(event: any) {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    changeUserBannerPhoto({ photo: formData, id: params.id })
      .unwrap()
      .then((res) => console.log(res))
      .catch((e) => console.log(e));
  }

  return (
    <div className='flex relative justify-between  items-center rounded-tl-xl gap-5 rounded-tr-xl w-full min-h-[150px] bg-white overflow-hidden'>
      <div className='group absolute w-full h-full top-0 left-0'>
        <BannerImage source={userData.banner_image ? userData.banner_image : undefined} />
        <CanIView component={`coach.changeBanner`}>
          <label className='group-hover:opacity-100 opacity-0 flex bg-black bg-opacity-80 absolute justify-center items-center w-full h-full  text-white left-0 top-0 cursor-pointer'>
            <AiOutlineCamera size={30} />
            <input
              type='file'
              accept='image/*'
              onChange={handleFileChange}
              className='hidden mr-12'
            />
          </label>
        </CanIView>
      </div>
      <div className='p-10 flex justify-between items-center bg-black bg-opacity-40 min-w-full h-[150px] pointer-events-none'>
        <CoachHeaderInfo />
      </div>
    </div>
  );
};

export default CoachHeader;
