import { OutsideClick } from '../../components/outside-click';
import { Link, Navigate, useParams } from 'react-router-dom';
import { SubmitButton } from '../../components/submit-button';
import {
  useConnectTicketsMutation,
  useGetAllTicketsThatCanConnectWithQuery,
} from '../../slices/supportTicketApiSlice';
import { useState } from 'react';

const ConnectTicketModal = () => {
  interface Iticket {
    id: number;
    title: string;
  }

  const param = useParams();
  const [checkedTickets, setCheckedTickets] = useState<any[]>([]);
  const {
    data: tickets,
    error,
    isLoading
  } = useGetAllTicketsThatCanConnectWithQuery(param?.id);
  const [connectParentTicket, { isLoading: ticketConnectedLoading, isSuccess }] = useConnectTicketsMutation();
  const [err, setErr] = useState<string>('');
  const handleCheckboxChange = (ticketId: number) => {
    if (checkedTickets.some((ticket: Iticket) => ticket.id === ticketId)) {
      setCheckedTickets(checkedTickets.filter((ticket: Iticket) => ticket.id !== ticketId));
    } else {
      const ticket = { id: ticketId };
      setCheckedTickets([...checkedTickets, ticket]);
    }
  };

  const onSubmit = (value: any, e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!checkedTickets?.length) {
      setErr('Please check at least one ticket');
    } else {
      const ticketIds = checkedTickets.map((ticket: Iticket) => ({ id: ticket.id }));
      const bodyReq = {
        parentTicketId: Number(param.id),
        ticketIds: ticketIds,
      };
      connectParentTicket(bodyReq);
    }
  };

  if (isLoading) {
    return <p>Loading</p>;
  }

  if (isSuccess) {
    return <Navigate to='../' />;
  }

  return (
    <div className='fixed z-20 top-0 left-0 h-[100vh] w-[100vw] flex justify-center items-center'>
      <OutsideClick />
      {!tickets?.length ? (
        <div className='flex flex-col items-center gap-5 z-20 p-10 bg-white rounded-xl shadow-xl'>
          <span>No available ticket to connect</span>
        </div>
      ) : tickets?.length ? (
        <div className='flex flex-col items-center gap-5 z-20 p-10 bg-white rounded-xl shadow-xl'>
          <p>
            Connect parent ticket with other tickets<span className='font-bold'>{ }</span>
          </p>
          <form onSubmit={(e) => onSubmit(checkedTickets, e)}>
            <div>
              {tickets.map((ticket: Iticket) =>
                ticket.id === Number(param.id) ? null : (
                  <div className='max-h-20 overflow-y-auto' key={ticket?.id}>
                    <label key={ticket.id}>
                      <input
                        type='checkbox'
                        name={`checkedTickets[${ticket.id}]`}
                        checked={checkedTickets.some(
                          (checkedTicket: Iticket) => checkedTicket.id === ticket.id,
                        )}
                        onChange={() => handleCheckboxChange(ticket.id)}
                      />
                      <span className='ml-2 mb-2'>{ticket?.title}</span>
                    </label>
                  </div>
                ),
              )}
            </div>
            <div className='flex justify-center mt-4 mb-4'>
              <SubmitButton isLoading={ticketConnectedLoading} />
              <Link className='ml-4 btn cancel' to='../'>
                Cancel
              </Link>
            </div>
            <div className='flex justify-center'>
              {err && <span className='error text-center text-[14px]'>{err}</span>}
            </div>
          </form>
        </div>
      ) : null}
    </div>
  );
};

export default ConnectTicketModal;