import { useState } from 'react';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import { HeaderButton } from '../../../../components/header-button';
import { NameAndAvatar } from '../../../../components/name-and-avatar';
import { Search } from '../../../../components/search/search';
import TableContainer from '../../../../components/table-container/table-container';
import { TableHead } from '../../../../components/table-head';
import TableTd from '../../../../components/table-td/table-td';
import { Separator } from '../../../../components/ui/separator/Separator';
import DropdownItem from '../../../../features/listview/DropDown/DropDownItem';
import {
  useDivisionExportListMutation,
  useGetAllDivisionsQuery,
} from '../../../../slices/divisionApiSlice';
import { DivisionGridCard } from './components/division-grid-card';
import CanIView from '../../../../components/can-i-view/can-i-view';
import Pagination from '../../../../components/pagination';
import { UserRoles } from '../../../../utils/constants';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../slices/auth';
import { dateAndTimeFormatter } from '../../../../utils/dateFormatter';
import { LoadingSceletonLists } from '../all/loading-sceleton-lists';

const configureListView = [
  // {
  //   name: 'id',
  //   active: true,
  // },
  {
    name: 'name',
    active: true,
  },
  {
    name: 'subdivisions',
    active: true,
  },
  {
    name: 'created by',
    active: true,
  },
  {
    name: 'created',
    active: true,
  },
];

const filterList = [
  {
    title: 'name',
    property: 'name',
  },
  // {
  //   title: 'subdivision name',
  //   property: 'subdivision',
  // },
];

function CategoryDivisions() {
  const [searchParams, setSearchParams] = useSearchParams();
  // const [openList, setOpenList] = useState(null);
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const [view, setView] = useState(true);
  const [downloadList, { isLoading: isDownloading }] = useDivisionExportListMutation();
  const [listView, setListView] = useState(configureListView);
  const [search, setSearch] = useState({ by: 'name', keyword: '' });

  const initialOpenListState = {
    id: null,
    x: null,
    y: null,
  };
  const [openList, setOpenList] = useState<{ [key: string]: number | null }>(initialOpenListState);
  const [openListView, setOpenListView] = useState(false);

  function handleClick(prop: { id: number; x: number; y: number }) {
    setOpenListView(false);
    if (openList.id == prop.id) {
      setOpenList(initialOpenListState);
    } else {
      setOpenList(prop);
    }
  }

  const headerButtons = [
    {
      type: 'export',
      func: () => {
        const query: { [key: string]: string | undefined } = {};
        filterList.forEach((l) => (query[l.property] = searchParams.get(l.property) ?? undefined));
        console.log(query);
        downloadList({ page: searchParams.get('page') ?? '1', query });
      },
    },
    {
      type: 'configureListView',
      listView,
      setListView,
    },
    {
      type: 'newCategoryDivision',
    },
    {
      type: 'listView',
      func: () => setView(true),
    },
    {
      type: 'gridView',
      func: () => setView(false),
    },
  ];

  const [searchQuery, setSearchQuery] = useState(filterList[0].title);

  const { isLoading, error, data } = useGetAllDivisionsQuery({
    query: searchQuery,
    param: searchParams.get(searchQuery) ? searchParams.get(searchQuery) : '',
    page: searchParams.get('page') ? searchParams.get('page') : 1,
  });

  if (isLoading) return <LoadingSceletonLists />;
  return (
    <div className='flex overflow-auto flex-col flex-1 rounded-xl bg-white border-1'>
      <div className='flex p-5 justify-between'>
        <div className='flex items-center justify-between'>
          <div className='space-y-1'>
            <h2 className='text-2xl font-semibold tracking-tight'>Category Divisions</h2>
          </div>
        </div>
        <div className='flex gap-2 justify-center'>
          {headerButtons.map((b) => (
            <CanIView component={`tournament.division.${b.type}`} key={b.type}>
              <HeaderButton view={view} {...b} />
            </CanIView>
          ))}
        </div>
      </div>
      <Separator className='my-4' />
      <div className='flex justify-between p-5 gap-2 '>
        {/* <HeaderButton type='filter' /> */}
        <div></div>
        <Search
          setQuery={setSearchQuery}
          filterList={filterList}
          search={search}
          setSearch={setSearch}
        />
      </div>
      <div className='listViewContainer'>
        {view ? (
          <TableContainer>
            <TableHead
              oneMore
              tableList={listView
                .map((l) => l.name)
                .filter(
                  (t) => listView.find((d) => d.name.toLowerCase() === t.toLowerCase())?.active,
                )}
            />
            <tbody className='bg-white divide-y divide-gray-200'>
              {data?.data?.map((d: any, i: number) => (
                <tr
                  onClick={(e) =>
                    user.roles === UserRoles.Admin
                      ? handleClick({ id: d.id, x: e.pageX, y: e.pageY })
                      : navigate(`/tournaments/view/${d.id}`)
                  }
                  className={user.roles !== UserRoles.Admin ? 'cursor-pointer' : 'relative'}
                  key={i}
                >
                  {listView
                    .filter((l) => l.active)
                    .map((o, i) =>
                      o.name === 'image' ? (
                        <></>
                      ) : o.name === 'name' ? (
                        <TableTd key={i}>
                          <NameAndAvatar image={d['background_image']} name={d[o.name]} />
                        </TableTd>
                      ) : o.name === 'subdivisions' ? (
                        <TableTd key={i} extraClass='max-w-[15ch]'>
                          {d?.CategorySubDivision?.length === 1 ? (
                            <div className='truncate'>{d.CategorySubDivision[0].name}</div>
                          ) : d.CategorySubDivision[0] ? (
                            d.CategorySubDivision[0].name
                          ) : (
                            '-'
                          )}
                          {/* {d.CategorySubDivision[0] ? d.CategorySubDivision[0].name : '-'} */}
                          <span className='group relative select-none'>
                            {d.CategorySubDivision.slice(1).length
                              ? `, +${d.CategorySubDivision.slice(1).length} more`
                              : ''}

                            <div className='hidden absolute shadow-md  bottom-[0%] -right-[100%]   p-2 bg-white rounded-lg group-hover:flex'>
                              {d.CategorySubDivision.slice(1).map((t: any, i: number) => (
                                <p key={i}>
                                  {t.name}
                                  {i < d.CategorySubDivision.length ? ', ' : ''}
                                </p>
                              ))}
                            </div>
                          </span>
                        </TableTd>
                      ) : o.name === 'created by' ? (
                        <TableTd key={i}>{d.user.firstName}</TableTd>
                      ) : o.name === 'created' ? (
                        <TableTd key={i}>
                          {dateAndTimeFormatter(d.created_at).split(';')[0]}
                        </TableTd>
                      ) : (
                        <TableTd key={i}>{d[o.name]}</TableTd>
                      ),
                    )}
                  <TableTd>
                    <div className='listViewMenu--parent'>
                      <div
                        className={openList.id === d.id ? '' : 'hidden'}
                        style={
                          openList.id === d.id
                            ? {
                                position: 'fixed',
                                top: Number(openList.y),
                                left:
                                  Number(openList.x) < 200
                                    ? Number(openList.x) + 200
                                    : Number(openList.x),
                                zIndex: 99,
                              }
                            : {
                                left: 0,
                              }
                        }
                      >
                        <div
                          className='listViewMenu'
                          role='menu'
                          aria-orientation='vertical'
                          aria-labelledby='menu-button'
                        >
                          <div className='py-1' role='none'>
                            <DropdownItem id={d.id} noView setFunc={setOpenList} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </TableTd>
                  {/* <ThreeDots id={d.id} noView={true} extraClass='bottom-[60px]' /> */}
                </tr>
              ))}
            </tbody>
          </TableContainer>
        ) : (
          <div className='flex flex-wrap justify-center gap-5'>
            {data?.data?.map((d: any, i: number) => (
              <DivisionGridCard key={i} {...d} />
            ))}
          </div>
        )}
      </div>

      <Outlet />
      <Pagination length={data?.data?.length} {...data?.meta} />
    </div>
  );
}

export default CategoryDivisions;
