import React from 'react';
import { FaSchool } from 'react-icons/fa';
import { MdOutlineSportsEsports } from 'react-icons/md';
export function TeamInfo({
  name,
  game,
  school,
  members,
}: {
  name: string;
  game: string;
  school: string;
  members: number;
}) {
  return (
    <div className='flex flex-col flex-[3] '>
      <h2 className='font-bold text-lg max-w-[15ch] truncate'>{name}</h2>
      <span className='text-xs text-gray-500 font-extralight'>{members} members</span>
      <div className='flex items-center  text-xs font-bold gap-3'>
        <MdOutlineSportsEsports />
        <p>{game}</p>
      </div>
      <div className='flex items-center  text-xs font-bold gap-3'>
        <FaSchool />
        <p className='max-w-[15ch] truncate'>{school}</p>
      </div>
    </div>
  );
}
