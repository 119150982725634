import { OutsideClick } from '../../../components/outside-click';
import { Link, Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { AiOutlineClose } from 'react-icons/ai';
import { useUpdateMatchTeamMutation } from '../../../slices/matchApiSlice';
import { SubmitButton } from '../../../components/submit-button';

const DeleteMatchTeam = () => {
  const param = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const sourceComponent = new URLSearchParams(location.search).get('source');
  const [updateTeam, { isLoading: updateIsLoading, isSuccess }] = useUpdateMatchTeamMutation();
  function onSubmit() {
    updateTeam({ id: param.matchId, body: { side: sourceComponent, mode: 1 } });
  }
  if (isSuccess) return <Navigate to='../' />;

  return (
    <>
      <OutsideClick />
      <div className='fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-10 w-auto mx-auto max-w-2xl bg-white p-10 flex flex-col gap-10 items-center justify-center rounded-xl'>
        <div className='absolute top-2 right-2'>
          <Link to='../' className=''>
            <AiOutlineClose size={'2rem'} className='hover:rounded-full hover:bg-gray-100 p-2' />
          </Link>
        </div>
        <div className='py-4 flex flex-col justify-center items-center gap-8'>
          <p className='select-none font-medium'>
            Are you sure you want to delete this team from match with{' '}
            <span className='font-bold'>ID: {param.matchId}</span>?
          </p>
          <div className='flex gap-2'>
            <SubmitButton onSubmit={onSubmit} title='Confirm' isLoading={updateIsLoading} />
            <Link
              to='../'
              className='p-4 px-8 text-center text-sm py-2 bg-white text-black border font-semibold rounded-lg hover:bg-gray-100'
            >
              No
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteMatchTeam;
