import { TableHead } from '../../../components/table-head';
import React, { useEffect, useState } from 'react';
import 'react-dropdown/style.css';
import DropdownItem from '../DropDown/DropDownItem';
import { Link, Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import { Search } from '../../../components/search/search';
import TableContainer from '../../../components/table-container/table-container';
import { useGetAllUsersQuery, useUserExportListMutation } from '../../../slices/userApiSlice';
import TableTd from '../../../components/table-td/table-td';
import { UserModel } from './model/user.model';
import { Separator } from '../../../components/ui/separator/Separator';
import { UserRoles, userRoleNames, ProgramTitles, ManualTagPrefix } from '../../../utils/constants';
import { UserStatusNames } from '../../../utils/constants';
import { HeaderButton } from '../../../components/header-button';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../slices/auth';
import CanIView from '../../../components/can-i-view/can-i-view';
import Pagination from '../../../components/pagination';
import { LoadingSceletonUsers } from '../../../routes/tournaments/routes/all/loading-sceleton-users';
import { useSearchGameByNameMutation } from '../../../slices/gameApiSlice';
import { appendAutoTags } from '../../../utils/utils';

const configureListView = [
  {
    name: 'First Name',
    active: true,
    property: 'firstName',
  },
  {
    name: 'Last Name',
    active: true,
    property: 'lastName',
  },
  {
    name: 'Username',
    active: true,
    property: 'username',
  },
  {
    name: 'Email Address',
    active: true,
    property: 'censoredEmail',
  },
  {
    name: 'Role',
    active: true,
    property: 'role',
  },
  {
    name: 'Organization',
    active: true,
    property: 'organization',
  },
  {
    name: 'School',
    active: true,
    property: 'school_name',
  },
  {
    name: 'Tags',
    active: true,
    property: 'tags',
  },

  {
    name: 'Participation Status',
    active: true,
    property: 'status',
  },
  {
    name: 'Eligibility Status',
    active: true,
    property: 'eligibility_status',
  },

  {
    name: 'Created Date',
    active: true,
    property: 'createdAt',
  },
];

const Users = () => {
  const [download, { isLoading: isDownloading }] = useUserExportListMutation();
  const [searchParams, setSearchParams] = useSearchParams();
  const initialOpenListState = {
    id: null,
    y: null,
    x: null,
  };
  const [openList, setOpenList] = useState<{ [key: string]: number | null }>(initialOpenListState);
  const [openListView, setOpenListView] = useState(false);
  const [listView, setListView] = useState(configureListView);
  const [searchGameByName, { data: gameData, isLoading: gameIsLoading, isError: gameIsError }] =
    useSearchGameByNameMutation();
  function handleClick(prop: { id: number; x: number; y: number }) {
    setOpenListView(false);
    if (openList.id == prop.id) {
      setOpenList(initialOpenListState);
    } else {
      setOpenList(prop);
    }
  }
  function handleListView() {
    console.log('hello');
    setOpenList(initialOpenListState);
    setOpenListView(!openListView);
  }

  const headerButtons = [
    {
      type: 'export',
      view: true,
      name: 'Export List Filtered',
      func: () =>
        download({
          page: searchParams.get('page') ?? '1',
          query: {},
        }),
    },
    {
      type: 'export',
      view: true,
      name: 'Export List',
      func: () =>
        download({
          page: searchParams.get('page') ?? '1',
          query: {
            unfilter: 'ok',
          },
        }),
    },
    {
      type: 'configureListView',
      view: true,
      listView,
      setListView,
    },
    {
      type: 'newUser',
    },
  ];

  const filterList = [
    { title: 'username', property: 'username' },
    { title: 'name', property: 'name' },
    { title: 'school', property: 'school' },
    { title: 'tags', property: 'tags' },
    { title: 'email', property: 'email' },
  ];

  const [searchQuery, setSearchQuery] = useState(filterList[0].title);

  const [search, setSearch] = useState({ by: 'username', keyword: '' });

  useEffect(() => {
    console.log(Object.fromEntries(searchParams.entries()));
  }, [searchParams]);
  const userRoles: { [key: string]: string } = {
    0: 'Admin',
    1: 'OGMA',
    2: 'Student',
    3: 'Coach',
    4: 'Guardian',
    // 5: 'Fan',
  };
  const handleFilterChange = (query: string, param: string) => {
    setSearchQuery(query);
    setSearchParams({ [query]: param });
    // setSearchParams();
  };

  const { data: userData, isLoading } = useGetAllUsersQuery({
    query: searchQuery,
    params: Object.fromEntries(searchParams.entries()),
    param: searchParams.get(searchQuery) ? searchParams.get(searchQuery) : '',
    page: searchParams.get('page') ? searchParams.get('page') : 1,
  });
  const user = useSelector(selectUser);
  const navigate = useNavigate();

  useEffect(() => {
    searchGameByName(ProgramTitles.DroneRacing);
  }, [searchGameByName]);

  if (isLoading) return <LoadingSceletonUsers />;
  return (
    <div className='listViewContainer fullPage rounded-xl border-1'>
      <div className='flex w-full justify-between'>
        <div className='flex items-center justify-between'>
          <div className='space-y-1'>
            <h2 className='text-2xl font-semibold tracking-tight'>Active Users</h2>
          </div>
        </div>
        <div className='flex gap-2 justify-center flex-wrap h-0'>
          {headerButtons.map((b, i) => (
            <CanIView component={`tournament.${b.type}`} key={b.type + i}>
              <HeaderButton key={b.type} {...b} />
            </CanIView>
          ))}
        </div>
      </div>
      <div>
        <Separator className='my-5' />
      </div>
      <div className='flex flex-wrap md:justify-between justify-center gap-5 '>
        <div className='filterContainer'>
          <div className='flex gap-x-2'>
            <Link to='../invitedusers' className='button hover:bg-opacity-80 w-fit bg-fen-blue'>
              SHOW INVITED USERS
            </Link>
          </div>
          <button onClick={() => setSearchParams()} className='filterBtn'>
            Clear Filter
          </button>
          <CanIView component='userRolesButton'>
            <div className='filterBtn relative select-none cursor-pointer'>
              Role{' '}
              {searchParams.get('roles')
                ? ' - ' + userRoleNames[searchParams.get('roles') ?? '1']
                : ''}
              <div className='filterDropDown'>
                {Object.keys(userRoleNames).map((role) => (
                  <span key={role} onClick={() => handleFilterChange('roles', role)}>
                    {userRoleNames[role]}
                  </span>
                ))}
              </div>
            </div>
          </CanIView>
          <div className='filterBtn relative select-none cursor-pointer'>
            Status{' '}
            {searchParams.get('status')
              ? ' - ' + UserStatusNames[searchParams.get('status')?.slice(0, 1) ?? '1']
              : ''}
            <div className='filterDropDown'>
              <span onClick={() => handleFilterChange('status', '1')}>Active</span>
              <span onClick={() => handleFilterChange('status', '0')}>Disabled</span>
            </div>
          </div>
        </div>

        <Search
          setQuery={setSearchQuery}
          filterList={filterList}
          search={search}
          setSearch={setSearch}
        />
      </div>
      <div className='flex flex-col flex-1 justify-between'>
        <TableContainer>
          <TableHead
            tableList={listView
              .map((l) => l.name)
              .filter(
                (t) => listView.find((d) => d.name.toLowerCase() === t.toLowerCase())?.active,
              )}
            bool={true}
          />
          <tbody className='bg-white divide-y divide-gray-200'>
            {
              // !error &&
              //   userData !== undefined &&
              userData.data?.map((person: any, i: number) => {
                const model: { [key: string]: any } & UserModel = new UserModel(person);
                return (
                  <React.Fragment key={i}>
                    <tr
                      onClick={(e) =>
                        user.roles !== UserRoles.Admin && user.roles !== UserRoles.Ogma
                          ? navigate(`./view/${person.id}/Student`)
                          : handleClick({ id: person.id, x: e.pageX, y: e.pageY })
                      }
                      key={person.email}
                    >
                      {listView
                        .filter((l) => l.active)
                        .map((p) => {
                          if (p.property === 'tags') {
                            return (
                              <TableTd key={p.property}>
                                {typeof model.tags !== 'undefined' ? (
                                  appendAutoTags(
                                    (model.tags?.split(',') || ['']).map(
                                      (tag) => ManualTagPrefix + tag,
                                    ),
                                    person.team.filter((team: any) => !team.isArchived),
                                    null,
                                    gameData?.[0]?.id,
                                  ).map((t: string, i: number) => (
                                    <span
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // Non auto generated tags have a "#" prefix
                                        let isGame;
                                        [t, isGame] = t.startsWith(ManualTagPrefix)
                                          ? [t.slice(1), 'false']
                                          : [t, 'true'];
                                        setSearch((s) =>
                                          s.by === 'tags' && s.keyword === t
                                            ? { by: filterList[0].title, keyword: '' }
                                            : { by: 'tags', keyword: t },
                                        );
                                        setSearchQuery('tags');
                                        setSearchParams({
                                          tags: t,
                                          isGame,
                                        });
                                        search.by === 'tags' &&
                                          search.keyword === t &&
                                          setSearchParams();
                                      }}
                                      key={t + i}
                                      className={`tag ${(() => {
                                        const tagNoPrefix = t.startsWith(ManualTagPrefix)
                                          ? t.slice(1)
                                          : t;
                                        return search.by === 'tags' &&
                                          search.keyword === tagNoPrefix
                                          ? 'active'
                                          : '';
                                      })()}`}
                                    >
                                      {t}
                                    </span>
                                  ))
                                ) : (
                                  <>{'-'}</>
                                )}
                              </TableTd>
                            );
                          }
                          if (p.property === 'username')
                            return (
                              <TableTd key={p.property} extraClass='max-w-[15ch]'>
                                <div className='text-sm font-medium text-gray-900 truncate'>
                                  {model.username}
                                </div>
                              </TableTd>
                            );
                          if (p.property === 'organization') {
                            return (
                              <TableTd key={p.property} extraClass='max-w-[15ch]'>
                                <div className='text-sm font-medium text-gray-900 truncate'>
                                  {Array.isArray(model.organization)
                                    ? model.organization.map((org, index) => (
                                        <span key={index}>
                                          {index > 0 && ', '}
                                          {typeof org === 'object' ? JSON.stringify(org) : org}
                                        </span>
                                      ))
                                    : model.organization}
                                </div>
                              </TableTd>
                            );
                          }
                          if (p.property === 'firstName')
                            return (
                              <TableTd key={p.property} extraClass='max-w-[15ch]'>
                                <div className='text-sm font-medium text-gray-900 truncate'>
                                  {model.firstName}
                                </div>
                              </TableTd>
                            );
                          if (p.property === 'lastName')
                            return (
                              <TableTd key={p.property} extraClass='max-w-[15ch]'>
                                <div className='text-sm font-medium text-gray-900 truncate'>
                                  {model.lastName}
                                </div>
                              </TableTd>
                            );
                          if (p.property === 'school_name')
                            return (
                              <TableTd key={p.property} extraClass='max-w-[15ch]'>
                                <div className='text-sm font-medium text-gray-900 truncate'>
                                  {model.school_name}
                                </div>
                              </TableTd>
                            );

                          if (p.property.includes('status'))
                            return (
                              <TableTd key={p.property}>
                                <span
                                  className={`invoiceStatus ${model[p.property]?.toLowerCase()}`}
                                >
                                  {model[p.property]}
                                </span>
                              </TableTd>
                            );
                          return <TableTd key={p.property}>{model[p.property]}</TableTd>;
                        })}
                      <TableTd
                      // className="px-6 py-4 notworking-whitespace-nowrap text-right text-sm font-medium"
                      >
                        <div className='listViewMenu--parent'>
                          {/* <div onClick={() => handleClick(person.id)}>
                          <button
                            type='button'
                            className='inline-flex w-full justify-center rounded-md bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50'
                            id='menu-button'
                            aria-expanded='true'
                            aria-haspopup='true'
                          >
                            <BsThreeDots />
                          </button>
                        </div> */}

                          <div
                            className={openList.id === person.id ? '' : 'hidden'}
                            style={
                              openList.id === person.id
                                ? {
                                    position: 'fixed',
                                    top: Number(openList.y),
                                    left:
                                      Number(openList.x) < 200
                                        ? Number(openList.x) + 200
                                        : Number(openList.x),
                                    zIndex: 99,
                                  }
                                : {
                                    left: 0,
                                  }
                            }
                          >
                            <div
                              className='listViewMenu'
                              role='menu'
                              aria-orientation='vertical'
                              aria-labelledby='menu-button'
                            >
                              <div className='' role='none'>
                                <DropdownItem
                                  justView={user.roles === UserRoles.Ogma}
                                  {...(model.roleNumber === UserRoles.Admin ||
                                  model.roleNumber === UserRoles.Fan
                                    ? { noView: true }
                                    : {
                                        viewLink: `./view/${model.id}/${model.role}`,
                                      })}
                                  hasDelete={user.roles === UserRoles.Admin}
                                  hasEdit={user.roles === UserRoles.Admin}
                                  id={person.id}
                                  setFunc={setOpenList}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </TableTd>
                    </tr>
                  </React.Fragment>
                );
              })
            }
          </tbody>
        </TableContainer>

        {/* {showModal ? <Modal /> : null} */}
        <Outlet />
        <Pagination length={userData?.data?.length} {...userData?.meta} />
      </div>
    </div>
  );
};
export default Users;
