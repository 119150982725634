import { useParams } from 'react-router';
import { useGetSchoolsByOgmaIdQuery } from '../../slices/schoolsApiSlice';
import { SchoolModel } from '../../model/school/school-model';
import OgmaProfileSchoolCard from './components/ogma-profile-school-card';

const OgmaProfileSchools = () => {
  const param = useParams();
  const { data, isLoading } = useGetSchoolsByOgmaIdQuery(param.id ?? '');
  if (isLoading) return <p>Loading...</p>;
  return (
    <div className='flex flex-wrap gap-5'>
      {!data.length ? <p>There are no schools</p> : <></>}
      {data.map((d: any, i: number) => (
        <OgmaProfileSchoolCard key={i} model={new SchoolModel(d)} />
      ))}
    </div>
  );
};

export default OgmaProfileSchools;
