import { Formik, FormikValues } from 'formik';
import { Navigate, useParams } from 'react-router-dom';
import { OutsideClick } from '../../../components/outside-click';
import { useStateContext } from '../../../contexts/ContextProvider';
import {
  useGetOrgByIdQuery,
  useUpdateOrganizationMutation,
} from '../../../slices/organizationApiSlice';
import { UpdateOrganization } from '../../authentication/yupschema';
import ModalContent from '../../../components/modal-content';
import { CustomError, UpdateOrgInterface } from '../../../utils/custom-types';
import { allStates } from '../../../utils/constants';

const UpdateOrganizationModal = () => {
  const { showModalSchools, setShowModalSchools } = useStateContext();
  // const [showModal, setShowModal] = React.useState(false);
  const onSubmit = (values: FormikValues) => {
    const data = values;
    data.zip_code = +data.zip_code;
    data.status = +data.status;
    delete data.schools;
    if (data?.hasOwnProperty('ogmas')) {
      delete data?.ogmas;
    }
    updateOrganization({ id: Number(param.id), body: data as UpdateOrgInterface })
      .unwrap()
      .then((res) => console.log(res))
      .catch((e) => console.log(e));
  };

  const formInputs = [
    {
      label: 'Organization Name',
      mainTitle: 'Organization Information',
      name: 'name',
      placeholder: 'Enter organization name',
      type: 'text',
    },
    {
      label: 'Email Address',
      mainTitle: 'Organization Information',
      name: 'email',
      placeholder: 'Enter email address',
      type: 'text',
    },
    {
      label: 'Street Address',
      mainTitle: 'Organization Information',
      name: 'street_address',
      placeholder: 'Enter street address',
      type: 'text',
    },
    {
      label: 'City',
      mainTitle: 'Organization Information',
      name: 'city',
      placeholder: 'Enter city',
      type: 'text',
    },
    {
      label: 'State',
      mainTitle: 'Organization Information',
      name: 'state',
      placeholder: 'Enter state',
      isSelect: true,
      options: allStates ? allStates : [],
      type: 'text',
    },
    {
      label: 'Zip Code',
      mainTitle: 'Organization Information',
      name: 'zip_code',
      placeholder: 'Enter zip code',
      type: 'number',
    },
    {
      label: 'Phone Number',
      mainTitle: 'Organization Information',
      name: 'phone_number',
      placeholder: 'Enter phone number',
      type: 'text',
    },
    {
      label: 'Tags',
      mainTitle: 'Organization Information',
      name: 'tags',
      placeholder: 'Enter tags',
      type: 'text',
    },
    {
      label: 'Status',
      mainTitle: 'Organization Information',
      name: 'status',
      placeholder: 'Enter status',
      type: 'text',
      isSelect: true,
      options: [
        { id: 0, name: 'Disabled' },
        { id: 1, name: 'Active' },
      ],
    },
  ];
  const param = useParams();
  const {
    error,
    data: orgData,
    isSuccess: orgIsSuccess,
    isLoading: orgIsLoading,
    isError: orgIsError,
  } = useGetOrgByIdQuery({
    id: param.id,
    query: ''
  });
  const [updateOrganization, { isError, isSuccess, isLoading }] = useUpdateOrganizationMutation();
  if (orgIsError || (orgIsSuccess && !orgData))
    return <Navigate to='/went-wrong' state={{ message: (error as CustomError).data.message }} />;
  if (orgIsLoading) return <p>Loading...</p>;
  if (isSuccess) return <Navigate to='../' />;
  return (
    <>
      <OutsideClick />
      <Formik
        initialValues={orgData as UpdateOrgInterface}
        validationSchema={UpdateOrganization}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, errors }) => (
          <ModalContent
            errors={Object.keys(errors).length}
            formInputs={formInputs}
            // extraClass={"h-fit"}
            isLoading={isLoading}
            name={`Edit Organization - ${orgData.name}`}
          />
        )}
      </Formik>
    </>
  );
};

export default UpdateOrganizationModal;
